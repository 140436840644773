<script setup lang="ts">
import { computed, onMounted, ref, useSlots } from 'vue';

import AnimateHeight from '@/components/AnimateHeight.vue';
import HotSpot from '@/components/HotSpot/HotSpot.vue';
import ExploreNavigationModal from '@/components/Modals/HModal/ExploreNavigationModal.vue';
import { useCookies, useDelayedCSAT, useModal, useScreen } from '@/composables';
import {
  NAVIGATION_RELEASE_DATE,
  TEN_SECONDS_IN_MS,
} from '@/data/globalConstants';
import { useHotspotStore, useProfileStore } from '@/stores';
import { Cookie, CSAT_CUSTOM_IDENTIFIER } from '@/types';
import { isDateAfterSecondDate } from '@/utils/helpers';

const sidemenu = ref<HTMLElement | null>(null);

const profileStore = useProfileStore();

const slots = useSlots();
const { isLarge } = useScreen();
const { openModal } = useModal();
const { cookies } = useCookies();
const csat = useDelayedCSAT();

const hotspotStore = useHotspotStore();

const isAccountCreatedBeforeNavigationRelease = computed(() =>
  isDateAfterSecondDate(
    NAVIGATION_RELEASE_DATE,
    profileStore.account?.createdAt || '',
  ),
);

onMounted(() => {
  if (
    isLarge.value &&
    !hotspotStore.hotspots.navigationHotspot.isClosed &&
    isAccountCreatedBeforeNavigationRelease.value
  ) {
    openModal({
      component: { ExploreNavigationModal },
    });
  }
});

const csatTrigger = () => {
  if (!cookies[Cookie.FEEDBACK_CLOSED]) {
    csat.startTimer({
      location: CSAT_CUSTOM_IDENTIFIER.NEW_NAVIGATION,
      delay: TEN_SECONDS_IN_MS,
      firstStepTitle: 'How satisfied were you with the new way of navigating?',
      secondStepTitle: 'How could the new way of navigating be improved?',
    });
  }
};
</script>

<template>
  <div class="hp-sidemenu">
    <HotSpot
      v-if="!isLarge && isAccountCreatedBeforeNavigationRelease"
      class="hp-sidemenu__hot-spot"
      v-bind="hotspotStore.hotspots.navigationHotspot"
      @hot-spot-close="csatTrigger"
    />

    <AnimateHeight
      :is-expanded="!!slots.header"
      name="fadeHeight"
      mode="out-in"
      class="hp-sidemenu__header-wrapper"
    >
      <div class="hp-sidemenu__header">
        <slot name="header" />
      </div>
    </AnimateHeight>

    <div v-if="!!slots['sub-header']" class="hp-sidemenu__sub-header">
      <slot name="sub-header" />
    </div>
    <div ref="sidemenu" class="hp-sidemenu__container">
      <slot />
    </div>
    <div :class="{ 'hp-sidemenu__footer': !!slots.footer }">
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="scss">
.hp-sidemenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: var(--light);
  width: var(--hmenu-width);

  &__header {
    padding: var(--hmenu-spacing);
    height: 80px;
  }

  &__header-wrapper {
    border-bottom: 1px solid var(--gray-border);
    // Actual height is 80 but because of overflow it needs to be set higher
    height: 95px;
  }

  &__container {
    padding: var(--hmenu-spacing);
    padding-top: 0;
    height: 100%;
    overflow-y: auto;
  }

  &__sub-header {
    padding: var(--hmenu-spacing);
    padding-bottom: 0;
  }

  &__footer {
    padding: var(--hmenu-spacing);
    border-top: 1px solid var(--gray-border);
  }

  &__hot-spot:not(.s) {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    right: 0;
  }
}
</style>
