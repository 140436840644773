export namespace Pusher {
  export namespace Event {
    export enum VirtualMachine {
      SNAPSHOT_CREATE = 'virtual-machine-snapshot.create',
      SNAPSHOT_RESTORE = 'virtual-machine-snapshot.restore',
      SNAPSHOT_DELETE = 'virtual-machine-snapshot.delete',
      PUBLIC_KEY_CREATED = 'public-key.created',
      PUBLIC_KEY_REMOVE = 'public-key.remove',
      BACKUP_RESTORE = 'virtual-machine-backup.restore',
      BACKUP_CREATING = 'virtual-machine-backup.creating',
      BACKUP_CREATED = 'virtual-machine-backup.created',
      RESTART = 'virtual-machine.restart',
      RECREATED = 'virtual-machine.recreated',
      START = 'virtual-machine.start',
      NAMESERVERS_UPDATE = 'virtual-machine.name-servers.update',
      CREATED = 'virtual-machine.created',
      STOP = 'virtual-machine.stop',
      HOSTNAME_UPDATE = 'virtual-machine.hostname.update',
      TUN_TAP = 'virtual-machine.tun_tap',
      DISK_QUOTA = 'virtual-machine.disk_quota',
      NFSD = 'virtual-machine.nfsd',
      TRANSFER = 'virtual-machine.transfer',
      TRANSFERED = 'virtual-machine.transfered',
      ORDER_UPDATED = 'virtual-machine.order.updated',
      LICENSE_CREATED = 'virtual-machine.license-created',
      MONARX_INSTALLED = 'virtual-machine.monarx-installed',
      DAILY_BACKUPS = 'virtual-machine.daily-backups',
      RECOVERY = 'virtual-machine.recovery',
      STOP_RECOVERY = 'virtual-machine.stop-recovery',
      FIREWALL_SYNCED = 'virtual-machine.firewall-synced',
      CPU_LIMIT = 'virtual-machine-limit.cpu',
    }

    export enum Email {
      TRIAL_ORDER_CREATED = 'trial-order.created',
      FREE_HMAIL_CREATED = 'free-hmail-order.created',
      FREE_TITAN_CREATED = 'free-titan-order.created',
    }

    export enum CPanel {
      SSH_STATUS = 'capi.pusher_manage_shell_access',
    }

    export enum Website {
      AI_AUTO_FIX_FINISHED = 'auto.fix.finished',
    }
  }
}
