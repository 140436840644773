import { wordpressRepo } from '@/repositories';
import { setForDomain, getStateVariable } from '@/store/storeHelper';
import { errorLogger } from '@/utils/services/errorLogging';

export default {
  state: {
    initial: {
      isInstalled: null,
      loading: false,
      error: false,
      plan: null,
    },
    data: [],
  },
  getters: {
    jetpackInfo: (state, getters) =>
      getStateVariable(
        state,
        undefined,
        getters.getHostingWordpressSelectedAppData.key,
      ),
  },
  mutations: {
    SET_PLAN: (state, { data, wpKey }) => {
      setForDomain(state, wpKey, 'plan', data);
    },
    SET_JETPACK_INSTALLED: (state, { data, wpKey }) => {
      setForDomain(state, wpKey, 'isInstalled', data);
    },
    TOGGLE_JETPACK_LOADING: (state, { data, wpKey }) => {
      setForDomain(state, wpKey, 'loading', data);
    },
    SET_ERROR: (state, { data, wpKey }) => {
      setForDomain(state, wpKey, 'error', data);
    },
  },
  actions: {
    async checkJetpackAvailable({ commit, getters }) {
      const wpKey = getters.getHostingWordpressSelectedAppData.key;

      if (!getters.getHostingWordpressSelectedAppData.coreDetails) return;

      const [{ data }, error] = await wordpressRepo.runPlugin({
        plugin_name: 'jetpack',
        action: 'getInfo',
        directory:
          getters.getHostingWordpressSelectedAppData.coreDetails.directory,
        domain: getters.getHostingWordpressSelectedAppData.coreDetails.domain,
        params: {
          hosting_plan: getters.getCurrentOrder.productName,
        },
      });

      if (data) {
        commit('SET_PLAN', { data, wpKey });
        commit('SET_ERROR', { data: false, wpKey });
      }

      if (error) {
        commit('SET_ERROR', { data: true, wpKey });
      }
    },
    async checkJetpackInstall({ commit, getters }) {
      const wpKey = getters.getHostingWordpressSelectedAppData.key;
      commit('TOGGLE_JETPACK_LOADING', { data: true, wpKey });

      const [{ data }, error] = await wordpressRepo.postWordpress({
        command: 'plugin_is_installed',
        domain: getters.getHostingWordpressSelectedAppData.coreDetails.domain,
        params: {
          directory:
            getters.getHostingWordpressSelectedAppData.coreDetails.directory,
          plugin: 'jetpack',
        },
      });

      if (data) {
        commit('SET_JETPACK_INSTALLED', { data, wpKey });
        commit('SET_ERROR', { data: false, wpKey });
      }

      if (error) {
        commit('SET_ERROR', { data: true, wpKey });
        errorLogger.logError(
          new Error(
            'Failed to check Jetpack install with command plugin_is_installed',
          ),
          {
            cause: error,
          },
        );
      }

      commit('TOGGLE_JETPACK_LOADING', { data: false, wpKey });
    },
    async installJetpack({ commit, getters }) {
      const wpKey = getters.getHostingWordpressSelectedAppData.key;
      commit('TOGGLE_JETPACK_LOADING', { data: true, wpKey });
      const { availablePlan } = getters.jetpackInfo.plan;

      const [{ data }, error] = await wordpressRepo.postWpPlugin({
        domain,
        directory,
        plugin_name: 'jetpack',
        config: {
          plan: availablePlan,
        },
      });

      if (data) {
        commit('TOGGLE_JETPACK_LOADING', { data: false, wpKey });
        commit('SET_JETPACK_INSTALLED', { data: true, wpKey });
      }

      if (error) {
        commit('TOGGLE_JETPACK_LOADING', { data: false, wpKey });
      }

      commit('TOGGLE_JETPACK_LOADING', { data: false, wpKey });
    },
  },
};
