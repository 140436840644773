import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v2/auto-installer`,

  async getAutoInstaller(params) {
    return await hAsync(http.get(`${this.url}`, { params }));
  },

  async postAutoInstaller(data) {
    return await hAsync(http.post(`${this.url}`, data));
  },

  async getAutoInstallerApplication(params) {
    return await hAsync(http.get(`${this.url}/application`, { params }));
  },

  async getAppInstallations(params) {
    return await hAsync(http.get(`${this.url}/installed`, { params }));
  },

  async destroyAutoInstaller(id) {
    return await hAsync(http.delete(`${this.url}/installed/${id}`));
  },

  async patchAutoInstallerUpdate({ id, autoup, domain }) {
    return await hAsync(
      http.patch(`${this.url}/installed/${id}`, {
        params: { domain },
        autoup,
      }),
    );
  },
};
