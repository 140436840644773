import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { resellerCurrencyData } from '@/data/resellerCurrencyData';
import { hMailIntegrationRepo, profileRepo } from '@/repositories';
import type {
  IProfileAccess,
  IProfileAccount,
  IProfileContact,
  IProfileOauth,
  IProfileSettings,
  IProfilePatchRequest,
  IProfileEmailPreferencesRequest,
  IProfileReminderPreferencesRequest,
  IResellerCurrencyInfo,
  TBrandId,
  IProfile,
  HAsyncResponse,
  RequestConfig,
} from '@/types';
import { AccountStatus, TwoFactorMethod, Cookie, Email, Client } from '@/types';
import { parseJwt } from '@/utils/helpers/authHelpers';
import { appForcedLogout } from '@/utils/services/authService';
import { getBrandConfigById } from '@/utils/services/brandConfigService';

export const getCurrencyByResellerId = (resellerId: TBrandId | undefined) =>
  resellerId
    ? resellerCurrencyData[resellerId]
    : resellerCurrencyData[Client.BrandId.Hostinger.COM];

export const useProfileStore = defineStore('profileStore', () => {
  const isLoading = ref(false);
  const account = ref<IProfileAccount | null>(null);
  const contact = ref<IProfileContact | null>(null);
  const oauth = ref<IProfileOauth | null>(null);
  const settings = ref<IProfileSettings | null>(null);
  const access = ref<IProfileAccess | null>(null);
  const emailProvider = ref<Email.EmailProvider | null>(null);

  const currency = computed(
    (): IResellerCurrencyInfo =>
      getCurrencyByResellerId(account.value?.brand.id),
  );

  const isProfileLoaded = computed(
    () =>
      account.value &&
      contact.value &&
      oauth.value &&
      settings.value &&
      access.value,
  );

  const brandConfig = computed(() =>
    getBrandConfigById(account.value?.brand?.id),
  );

  const clientBrandTitle = computed(
    () => getBrandConfigById(account.value?.brand?.id)?.name || 'Hostinger',
  );

  const hasOauth = computed(() => !!oauth.value?.linked.length);

  const linkedSocialLogin = computed(() => oauth.value?.linked[0] || '');

  const isClientPendingConfirmation = computed(
    () => account.value?.status === AccountStatus.PENDING_CONFIRMATION,
  );

  const isTwoFactorMethodEmail = computed(
    () => settings.value?.twoFactorAuth.method === TwoFactorMethod.EMAIL,
  );

  const isTwoFactorMethodCode = computed(
    () => settings.value?.twoFactorAuth.method === TwoFactorMethod.GOOGLE,
  );

  const hasTwoFactorAuth = computed(
    () =>
      !!settings.value?.twoFactorAuth.method &&
      !!settings.value?.twoFactorAuth.isEnabled,
  );

  const isPhoneAdded = computed(
    () => contact.value?.phone && contact.value?.phoneCc,
  );

  const fullName = computed(() => {
    if (!contact.value?.firstName) {
      return '';
    }

    return `${contact.value.firstName} ${contact.value.lastName || ''}`.trim();
  });

  const isWebproStaffClient = computed(
    () => isPro.value && access.value?.isStaff,
  );

  const isAccessManager = computed(() => access.value?.isAccessManager);

  const isStaff = computed(() => !!access.value?.isStaff);

  const isPro = computed(() => !!account.value?.isPro);

  const clientOrAccessManagerClientId = computed(() => {
    if (!isAccessManager.value) {
      return account.value?.id;
    }
    const { access: accessJWT } =
      parseJwt(localStorage.getItem(Cookie.JWT) || '') || {};

    return accessJWT?.managerClientId;
  });

  const brandId = computed(() => account.value?.brand?.id);

  const brandDomain = computed(() => account.value?.brand?.domain);

  const country = computed(() => account.value?.brand?.country);

  const isTitanEmail = computed(
    () => emailProvider.value !== Email.EmailProvider.HOSTINGER,
  );

  const isHostingerEmail = computed(
    () => emailProvider.value === Email.EmailProvider.HOSTINGER,
  );

  const isHostingerEmployeeEmail = computed(() =>
    contact.value?.email?.includes('@hostinger.com'),
  );

  const getIsSameClientId = (clientId: string | number) =>
    String(clientId) === account.value?.id;

  const setAccount = (newAccount: IProfileAccount) => {
    const { brand } = newAccount;

    account.value = {
      ...newAccount,
      brand: {
        ...brand,
        id: brand.id.toString() as TBrandId,
      },
    };
  };

  let fetchProfilePromise: Promise<IProfile | undefined> | null = null;

  const fetchProfile = () => {
    fetchProfilePromise = new Promise(async (resolve) => {
      isLoading.value = true;

      const [{ data }, error] = await profileRepo.getProfile();

      isLoading.value = false;

      if (error || !data) {
        await appForcedLogout();

        return resolve(undefined);
      }

      setAccount(data.account);
      contact.value = data.contact;
      oauth.value = data.oauth;
      settings.value = data.settings;
      access.value = data.access;

      return resolve(data);
    });

    return fetchProfilePromise;
  };

  const waitForProfileFetch = () => {
    if (!isLoading.value) {
      return;
    }

    return fetchProfilePromise;
  };

  const fetchUserEmailProvider = async () => {
    const [{ data, error }] =
      await hMailIntegrationRepo.getUserDefaultEmailProvider();

    if (error) return;

    emailProvider.value = data.emailProvider;
  };

  const updateProfileData = async (
    data: IProfilePatchRequest,
    config?: RequestConfig,
  ): HAsyncResponse => {
    const [responseData, error] = await profileRepo.patchProfileData(
      data,
      config,
    );

    if (error) {
      return [responseData, error];
    }

    await fetchProfile();

    return [responseData, error];
  };

  const updateEmailPreferences = async (
    data: IProfileEmailPreferencesRequest,
  ): HAsyncResponse => {
    const [responseData, error] = await profileRepo.patchEmailPreferences(data);

    if (error) {
      return [responseData, error];
    }

    await fetchProfile();

    return [responseData, error];
  };

  const updateReminderPreferences = async (
    data: IProfileReminderPreferencesRequest,
  ): HAsyncResponse => {
    const [responseData, error] = await profileRepo.patchReminderPreferences(
      data,
    );

    if (error) {
      return [responseData, error];
    }

    await fetchProfile();

    return [responseData, error];
  };

  return {
    isLoading,
    isProfileLoaded,
    account,
    contact,
    oauth,
    settings,
    access,
    currency,
    clientBrandTitle,
    hasOauth,
    isClientPendingConfirmation,
    isPhoneAdded,
    isTwoFactorMethodEmail,
    isTwoFactorMethodCode,
    hasTwoFactorAuth,
    fullName,
    brandConfig,
    linkedSocialLogin,
    isWebproStaffClient,
    isPro,
    clientOrAccessManagerClientId,
    isAccessManager,
    brandId,
    brandDomain,
    country,
    isStaff,
    isTitanEmail,
    isHostingerEmail,
    emailProvider,
    isHostingerEmployeeEmail,
    getIsSameClientId,
    fetchProfile,
    waitForProfileFetch,
    fetchUserEmailProvider,
    updateProfileData,
    updateEmailPreferences,
    updateReminderPreferences,
  };
});
