import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useGlobals, useH5GWebsite } from '@/composables';
import { h5gWordPressRepo } from '@/repositories';
import type { IH5GWordpressSettings } from '@/types';

export const useH5GWordPressStore = defineStore('h5gWordPressStore', () => {
  const { t, toastr } = useGlobals();
  const { h5gWebsiteUid } = useH5GWebsite();

  const adminUrl = ref('');
  const settings = ref<IH5GWordpressSettings>();
  const isSettingsLoading = ref(false);
  const isAdminUrlLoading = ref(false);
  const isLiteSpeedLoading = ref(false);
  const isLiteSpeedCacheLoading = ref(false);

  const isLiteSpeedEnabled = computed(
    () => settings.value?.litespeedCacheEnabled ?? false,
  );

  const fetchWordPressAdminUrl = async (websiteUid?: string) => {
    isAdminUrlLoading.value = true;

    const [{ data }, error] = await h5gWordPressRepo.getWebsiteAdminUrlUrl(
      websiteUid || h5gWebsiteUid.value,
    );

    isAdminUrlLoading.value = false;

    if (!data || error) {
      return;
    }

    adminUrl.value = data;
  };

  const fetchWordpressSettings = async () => {
    isSettingsLoading.value = true;

    const [{ data }, error] = await h5gWordPressRepo.getWordpressSettings(
      h5gWebsiteUid.value,
    );

    isSettingsLoading.value = false;

    if (!data || error) {
      return;
    }

    settings.value = data;
  };

  const changeLiteSpeedStatus = async (status: boolean) => {
    isLiteSpeedLoading.value = true;

    status
      ? await h5gWordPressRepo.enableLiteSpeed(h5gWebsiteUid.value)
      : await h5gWordPressRepo.disableLiteSpeed(h5gWebsiteUid.value);

    fetchWordpressSettings();

    isLiteSpeedLoading.value = false;
  };

  const flushLiteSpeedCache = async () => {
    isLiteSpeedCacheLoading.value = true;

    const [{ data }, err] = await h5gWordPressRepo.flushLiteSpeedCache(
      h5gWebsiteUid.value,
    );

    if (data && !err) {
      toastr.s(t('Cache flushed successfully'));
    }

    isLiteSpeedCacheLoading.value = false;
  };

  const $reset = () => {
    adminUrl.value = '';
    settings.value = undefined;
    isSettingsLoading.value = false;
    isAdminUrlLoading.value = false;
    isLiteSpeedLoading.value = false;
    isLiteSpeedCacheLoading.value = false;
  };

  return {
    $reset,
    fetchWordPressAdminUrl,
    fetchWordpressSettings,
    changeLiteSpeedStatus,
    flushLiteSpeedCache,
    adminUrl,
    settings,
    isLiteSpeedEnabled,
    isSettingsLoading,
    isAdminUrlLoading,
    isLiteSpeedLoading,
    isLiteSpeedCacheLoading,
  };
});
