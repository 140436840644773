<script lang="ts" setup>
//JSON-slugs-migrated
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { computed, watch, ref } from 'vue';
import { useRoute } from 'vue-router';

import FullWidthSalesBanner from './FullWidthSalesBanner.vue';

import {
  useSaleCampaign,
  useVpsSaleCampaign,
  useGlobals,
  useBlackFridayConfig,
  useCyberWeekSaleCampaign,
} from '@/composables';
import { useCyberWeekConfig } from '@/composables/useCyberWeekConfig';
import { AmplitudeEvent, BlackFridaySale, Route, SaleTheme } from '@/types';

const {
  isEligibleForDarkMode,
  activeSale,
  isTimerVisible,
  isBlackFridayPurchaseAvailable,
  blackFridayHeadlineText,
} = useSaleCampaign();

const { isEligibleForKVMSale, isVpsSaleAvailable } = useVpsSaleCampaign();

const { isCyberWeekSaleAvailable } = useCyberWeekSaleCampaign();

const { config: blackFridayConfig } = useBlackFridayConfig();
const { config: cyberWeekConfig } = useCyberWeekConfig();
const config = isCyberWeekSaleAvailable.value
  ? cyberWeekConfig
  : blackFridayConfig;

const { amplitudeV2 } = useGlobals();
const route = useRoute();

const settings = computed(() => {
  let variant = BlackFridaySale.HOSTING_UPGRADE_SALE;
  if (isBlackFridayPurchaseAvailable.value) {
    variant = BlackFridaySale.HOSTING_PURCHASE_SALE;
  }
  if (isVpsSaleAvailable.value) {
    variant = isEligibleForKVMSale.value
      ? BlackFridaySale.VPS_KVM_SALE
      : BlackFridaySale.VPS_SALE;
  }

  const theme = isEligibleForDarkMode.value ? SaleTheme.DARK : SaleTheme.LIGHT;
  const selectedConfig = config.value[variant][theme];

  let ctaTo: string = Route.Sale.BLACK_FRIDAY;
  if (isBlackFridayPurchaseAvailable.value) {
    ctaTo = Route.Base.BUY_HOSTING;
  }
  if (isVpsSaleAvailable.value) {
    ctaTo = Route.Base.BUY_VPS;
  }

  return {
    ...selectedConfig,
    ctaTo,
    headlineText: isCyberWeekSaleAvailable.value
      ? 'v2.the.biggest.ever.cyber.week.sale'
      : blackFridayHeadlineText.value,
    showTimer: isTimerVisible.value,
    timerEndsAt: dayjs(activeSale.value?.timerEndsAt).valueOf(),
  };
});

const isBannerNonToggable = ref(false);

// using debounce for sending amplitude event due to the possibility of redirection
const sendAmplitudeEvent = debounce(() => {
  amplitudeV2(AmplitudeEvent.BlackFriday.BLACK_FRIDAY_BANNER_SHOWN);
}, 1000);

watch(
  () => route.name,
  (newVal, oldVal) => {
    if (newVal === oldVal) return;

    isBannerNonToggable.value = [
      Route.Base.BUY_VPS,
      Route.Base.BUY_HOSTING,
      Route.Vps.VPS_PLANS,
    ].includes(newVal as Route.Base);

    sendAmplitudeEvent();
  },
  { immediate: true },
);
</script>

<template>
  <FullWidthSalesBanner v-bind="settings" :non-toggable="isBannerNonToggable" />
</template>
