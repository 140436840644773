export namespace HDomains {
  export enum Promotion {
    POPULAR_IN = 'popular_in',
    AI_GENERATED = 'AI_Generated',
    AI_GENERATED_FROM_DESCRIPTION = 'AI_generated_from_description',
    DOMAINS_BUNDLE = 'domains_bundle',
  }

  export enum DomainTransferDnsOption {
    RESET_DNS = 'resetDns',
    KEEP_DNS = 'keepDns',
    KEEP_NS = 'keepNs',
  }

  export enum Registrar {
    REGISTRO_BR = 'registro_br',
    DOMREG = 'domreg',
    OPEN_PROVIDER = 'open_provider',
    REALTIME = 'realtime_register',
  }

  export enum RegistrarTitle {
    GO_DADDY = 'GoDaddy.com, LLC',
  }

  export enum WhoIsField {
    FIRST_NAME = 'firstName',
    LAST_NAME = 'lastName',
    COUNTRY_CODE = 'countryCode',
    PHONE_CC = 'phoneCc',
    PHONE_NUMBER = 'phoneNumber',
    CITY = 'city',
    ADDRESS = 'address',
    ZIP_LT = 'zipLt',
    STATE_LT = 'stateLt',
    APARTMENT_LT = 'apartmentLt',
    COMPANY = 'companyName',
    EMAIL = 'email',
  }

  export enum WhoIsFieldLabel {
    FIRST_NAME = 'First Name:',
    LAST_NAME = 'Last Name:',
    EMAIL = 'Email:',
    PHONE_NUMBER = 'Phone number:',
    ADDRESS = 'Address:',
    COMPANY = 'Company:',
    COMPANY_REGISTRATION_NO = 'Company registration number:',
  }

  export enum WhoIsEntity {
    INDIVIDUAL = 'individual',
    ORGANIZATION = 'organization',
  }

  export enum DomainAction {
    PURCHASE = 'purchase',
    CONNECT = 'connect',
    TRANSFER = 'transfer',
    WRONG_ACCOUNT = 'wrong_account',
  }

  export enum DomainStatus {
    ACTIVE = 'Active',
    REQUESTED = 'Requested',
    DELETED = 'Deleted',
    PENDING_DELETE = 'Pending Delete',
    EXPIRED = 'Expired',
    SUSPENDED = 'Suspended',
    FAILED = 'Failed',
    RESTORING = 'Restoring',
    TRANSFERRING = 'Transferring',
    UNKNOWN = 'Unknown',
  }

  export enum DomainType {
    ORDER = 'order',
    RESOURCE = 'resource',
  }

  export enum DomainMoveStatus {
    INITIATED = 'initiated',
    ACTIVATING = 'activating',
    COMPLETED = 'completed',
  }

  export enum DefaultDns {
    A = '2.15.134.3',
  }

  export enum DnsType {
    A = 'A',
    AAAA = 'AAAA',
    CNAME = 'CNAME',
    ALIAS = 'ALIAS',
    CAA = 'CAA',
    MX = 'MX',
    NS = 'NS',
    TXT = 'TXT',
    SRV = 'SRV',
  }

  export enum RedirectType {
    PERMANENT = '301',
    TEMPORARY = '302',
  }

  export enum BulkActionType {
    AUTO_RENEW = 'Auto-renew',
    PRIVACY_PROTECTION = 'Privacy protection',
    DOMAIN_LOCK = 'Domain lock',
    CHANGE_NAMESERVERS = 'Change nameservers',
    DOMAIN_FORWARDING = 'Domain forwarding',
  }

  export enum BulkActionSlug {
    DOMAIN_AUTO_RENEW_ENABLE = 'domain_auto_renew_enable',
    DOMAIN_AUTO_RENEW_DISABLE = 'domain_auto_renew_disable',
    DOMAIN_LOCK = 'domain_transfer_lock',
    DOMAIN_UNLOCK = 'domain_transfer_unlock',
    DOMAIN_PRIVACY_PROTECTION_ENABLE = 'domain_privacy_protection_enable',
    DOMAIN_PRIVACY_PROTECTION_DISABLE = 'domain_privacy_protection_disable',
    DOMAIN_NAMESERVER_UPDATE_CUSTOM = 'domain_nameserver_update',
    DOMAIN_NAMESERVER_UPDATE_DEFAULT = 'domain_nameserver_update_default',
    DOMAIN_FORWARD_CREATE = 'domain_forward_create',
    DOMAIN_FORWARD_DELETE = 'domain_forward_delete',
  }

  export enum HostingerNameservers {
    NS1 = 'ns1.dns-parking.com',
    NS2 = 'ns2.dns-parking.com',
  }

  export enum HostingerCpanelNameservers {
    NS1 = 'cdns1.main-hosting.eu',
    NS2 = 'cdns2.main-hosting.eu',
  }

  export enum TooltipText {
    AUTO_RENEW_IS_ON = 'Domain will renew automatically. Check the billing page for more information.',
    AUTO_RENEW_IS_OFF = 'Enable auto-renewal feature to secure your domain',
    RENEW_IS_IN_PROGRESS = 'Renew is in progress',
    IN_REDEMPTION_PERIOD = 'Domain expired and entered the redemption period. You can still renew the domain until {date} for an additional fee.',
    IN_GRACE_PERIOD = 'Domain expired and entered the grace period. You can still renew the domain until {date} for a regular fee.',
    IS_SUSPICIOUS = 'v2.your.domain.is.being.reviewed.contains.sensitive.keywords.24.hours',
    MANAGED_DOMAIN = 'Unavailable for client domain',
  }

  export enum BulkActionStatus {
    QUEUED = 'Queued',
    FAILED = 'Failed',
    EXECUTING = 'Executing',
    FINISHED = 'Finished',
    PROCESSING = 'Processing',
    SUCCESS = 'Success',
  }

  export enum DomainVerificationStatus {
    VERIFIED = 'Verified',
  }

  export enum DomainTransferStatus {
    ERROR = 'Error',
  }
}
export namespace HDomainResource {
  export enum ResourceType {
    DOMAIN_TRANSFER = 'domain_transfer',
    DOMAIN = 'domain',
    FREE_DOMAIN = 'free_domain',
  }

  export enum Status {
    ACTIVE = 'active',
    EXPIRED = 'expired',
    INSTALLING = 'installing',
    CANCELED = 'canceled',
    SUSPENDED = 'suspended',
    PENDING_SETUP = 'pending_setup',
    PENDING_DATA_ENTRY = 'pending_data_entry',
    PENDING_VERIFICATION = 'pending_verification',
    REQUESTED = 'requested',
    FAILED = 'failed',
  }

  export enum VisibleStatus {
    TRANSFERRING = 'transferring',
    ERROR = 'error',
    REVIEWING = 'reviewing',
    GRACE_PERIOD = 'grace_period',
    REDEMPTION_PERIOD = 'redemption_period',
    PENDING_VERIFICATION = 'pending_verification',
    REGISTERING = 'registering',
    ACTION_REQUIRED = 'action_required',
  }
}
export enum DomainType {
  MAIN = 'main',
  SUBDOMAIN = 'subdomain',
  PARKED = 'parked',
  ADDON = 'addon',
}

export const TEMPORARY_DOMAIN_TYPE = {
  WORDPRESS: 'hostingersite.com',
  BUILDER: 'builder-preview.com',
} as const;

export const COUNTRY_CODE_TLD = {
  COM_BR: '.com.br',
  CO_ID: '.co.id',
} as const;
