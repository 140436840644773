<script setup lang="ts">
import { ref, toRefs } from 'vue';

import HCheckbox from '@/components/HCheckbox.vue';
import type { IWebsiteMonitoring } from '@/types';

type Props = {
  website: IWebsiteMonitoring;
};
interface Emits {
  (eventName: 'on-change', payload: IWebsiteMonitoring): void;
}
const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const { vhost, isMonitored } = toRefs(props.website);

const isChecked = ref(isMonitored.value);
const onSelect = () => {
  isChecked.value = !isChecked.value;
  emits('on-change', { ...props.website, isMonitored: isChecked.value });
};
</script>
<template>
  <div class="website-monitoring-item" @click="onSelect">
    <HCheckbox :active="isChecked" class="h-mr-8" />
    <p class="text-dark">
      {{ vhost }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.website-monitoring-item {
  display: flex;
  align-items: center;
  &:not(:first-child) {
    margin-top: 8px;
  }
}
</style>
