import { useStore } from 'vuex';

import { useGlobals, useSubscriptions } from '@/composables';

export const useSubscriptionRenewal = () => {
  const { toastr, t } = useGlobals();
  const { dispatch } = useStore();

  const {
    getSubscriptionById,
    enableAutoRenew,
    fetchSubscriptions,
    isSubscriptionAutoRenewalEnabled,
  } = useSubscriptions();

  const showEnableAutoRenewToastr = async (data: {
    subscriptionId: string;
    title?: string;
    subtitle?: string;
    customId: string;
    actionButtonLabel?: string;
    actionButtonCallback?: () => {};
  }) => {
    const subscription = getSubscriptionById(data.subscriptionId);

    if (isSubscriptionAutoRenewalEnabled(subscription)) return;

    const hasMetadata = subscription.resource?.metadata;

    const title =
      data.title ||
      t(
        hasMetadata
          ? `Auto-renewal is disabled for your ${subscription?.resource?.metadata?.planTitle} - ${subscription?.resource?.metadata?.domain}`
          : 'Auto-renewal is disabled for your subscription',
      );

    const subtitle =
      data.subtitle ||
      t('Enable auto-renewal to ensure an uninterrupted flow of your services');

    const actionButtonLabel =
      data.actionButtonLabel || t('Enable auto-renewal');

    const actionButtonCallback = async () => {
      if (!data.actionButtonCallback) {
        return await enableAutoRenew(data.subscriptionId);
      }

      data.actionButtonCallback();

      return [null, null];
    };

    toastr.w(title, {
      text: t(subtitle),
      customId: data.customId,
      buttons: hasMetadata
        ? [
            {
              text: actionButtonLabel,
              props: { primary: true, text: true },
              callback: async () => {
                const [, error] = await actionButtonCallback();
                fetchSubscriptions();
                dispatch('billingItems/getBillingItems');
                if (!error) {
                  toastr.s(t('Auto-renewal is enabled successfully'));
                }
              },
            },
          ]
        : [],
    });
  };

  return {
    showEnableAutoRenewToastr,
  };
};
