import { computed } from 'vue';

import { useBlackFridayConfig } from './useBlackFridayConfig';

import type { SaleBannerConfig } from '@/types';
import { BlackFridaySale, SaleTheme } from '@/types';

export const useCyberWeekConfig = () => {
  const { config: blackFridayConfig } = useBlackFridayConfig();

  const darkImage = '@/images/blackFriday/bf-universal-dark.png';
  const lightImage = '@/images/blackFriday/bf-universal-light.png';
  const subheadlineText =
    'v2.last.chance.to.save.huge.on.hosting.plus.website.builder.plans';

  const hostingUpgradeConfig =
    blackFridayConfig.value[BlackFridaySale.HOSTING_UPGRADE_SALE];
  const hostingPurchaseConfig =
    blackFridayConfig.value[BlackFridaySale.HOSTING_PURCHASE_SALE];

  const vpsKvmSaleConfig =
    blackFridayConfig.value[BlackFridaySale.VPS_KVM_SALE];
  const vpsSubheadlineText = 'Last chance to get KVM 2 plan for:';

  const config = computed<SaleBannerConfig>(() => ({
    ...blackFridayConfig.value,
    [BlackFridaySale.VPS_KVM_SALE]: {
      ...vpsKvmSaleConfig,
      [SaleTheme.DARK]: {
        ...vpsKvmSaleConfig.dark,
        subheadlineText: vpsSubheadlineText,
      },
      [SaleTheme.LIGHT]: {
        ...vpsKvmSaleConfig.light,
        subheadlineText: vpsSubheadlineText,
      },
    },
    [BlackFridaySale.HOSTING_UPGRADE_SALE]: {
      ...hostingUpgradeConfig,
      [SaleTheme.DARK]: {
        ...hostingUpgradeConfig.dark,
        image: darkImage,
      },
      [SaleTheme.LIGHT]: {
        ...hostingUpgradeConfig.light,
        image: lightImage,
      },
    },
    [BlackFridaySale.HOSTING_PURCHASE_SALE]: {
      ...hostingPurchaseConfig,
      [SaleTheme.DARK]: {
        ...hostingPurchaseConfig.dark,
        image: darkImage,
        subheadlineText,
      },
      [SaleTheme.LIGHT]: {
        ...hostingPurchaseConfig.light,
        image: lightImage,
        subheadlineText,
      },
    },
  }));

  return { config };
};
