import type { CatalogItem, CatalogItemPrice } from '@/types';
import { convertToMonths } from '@/utils/helpers';
import {
  getLowestMonthlyPurchasePrice,
  getItemMonthlyPrice,
} from '@/utils/helpers/catalogHelpers';
import { mapCSCoupon } from '@/utils/mappers/csCouponMapper';

export const getCouponRequest = (enteredCoupon?: string) => {
  if (!enteredCoupon) {
    return { coupons: [] as string[], agentId: undefined };
  }

  const { coupon, agentId } = mapCSCoupon(enteredCoupon);

  return { coupons: [coupon], agentId };
};

const convertToMonthlyPurchasePricing = (item: CatalogItemPrice) => ({
  regularPrice: getItemMonthlyPrice(item, 'basePrice'),
  price: getItemMonthlyPrice(item, 'firstPeriodPrice'),
  renewalPrice: getItemMonthlyPrice(item, 'price'),
  period: convertToMonths(item.period, item.periodUnit),
});

export const getLowestMonthlyPurchasePricing = (catalogItem: CatalogItem) => {
  const lowestPrice = getLowestMonthlyPurchasePrice(catalogItem);

  return {
    ...convertToMonthlyPurchasePricing(lowestPrice),
  };
};
