import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

import { useGlobals } from '@/composables';
import { wordpressRepo } from '@/repositories';
import store from '@/store/index';
import { useProfileStore, useHostingWordPressStore } from '@/stores';
import type {
  IWordPressLitespeedLogs,
  IWordpressCompatibility,
  IWordpressComponent,
  IWordpressManagedWebsite,
  RequestConfig,
  WordPressPreset,
  WordpressRestoreRequest,
  WordpressSettings,
  WordpressUpdateLogs,
  IWordPressLitespeedPresets,
  IWordpressApplication,
} from '@/types';
import { Header, STORE_PERSISTENT_KEYS } from '@/types';
import { errorLogger } from '@/utils/services/errorLogging';

export const useWordpressStore = defineStore(
  'wordpressStore',
  () => {
    const { toastr, t } = useGlobals();
    const profileStore = useProfileStore();
    const hostingWordPressStore = useHostingWordPressStore();
    const route = useRoute();

    const wordpressApplications = ref<IWordpressApplication[]>([]);
    const isWordPressVersionValid = ref<boolean | null>(null);
    const isWordPressVersionValidLoading = ref(false);
    const wordpressSettings = ref<WordpressSettings>();
    const wordpressUpdateLogs = ref<WordpressUpdateLogs>();
    const wordpressPresets = ref<WordPressPreset[] | null>(null);
    const isLoadingWordPressWebsites = ref<boolean | null>(null);
    const managedAndOwnedWordpressWebsites = ref<
      IWordpressManagedWebsite[] | null
    >(null);
    const wordpressCompatibility = ref<IWordpressCompatibility | null>(null);
    const selectedWordpressCompatibility =
      ref<IWordpressCompatibility | null>();
    const wordpressComponentsCompatibility = ref<IWordpressComponent[]>();
    const wordPressLitespeedPresetLogs = ref<IWordPressLitespeedLogs[]>();

    const fetchWordpressList = async () => {
      const [{ data }, error] = await wordpressRepo.getWordPressList();

      if (error) {
        return [{ data }, error];
      }

      wordpressApplications.value = Object.values(data);

      return [{ data }, error];
    };

    const fetchWordpressSettings = async (params: { directory: string }) => {
      const [{ data }, error] = await wordpressRepo.getWordPressSettings({
        params,
        ...hostingWordPressStore.wordPressSubdomainConfig,
      });

      if (error) {
        return;
      }

      wordpressSettings.value = data;

      return data;
    };

    const postWordpressSettings = async (
      params: WordpressSettings,
      directory: string = '',
    ) => {
      const requestData = {
        directory,
        settings: params,
      };

      const [, error] = await wordpressRepo.postWordPressSettings(requestData, {
        ...hostingWordPressStore.wordPressSubdomainConfig,
      });

      return [, error];
    };

    const updateWordPressVersion = async ({
      version,
      directory,
      domain,
    }: {
      version: string;
      directory: string;
      domain?: string;
    }) => {
      const [data, err] = await wordpressRepo.postWordpress(
        {
          command: 'core_update',
          params: {
            directory,
            version,
          },
        },
        { headers: { [Header.DOMAIN]: domain } },
      );

      if (err) {
        errorLogger.logError(
          new Error(
            'Failed to update WordPress version with command core_update',
            {
              cause: err,
            },
          ),
        );
      }

      store.commit('SET_HOSTING_WORDPRESS_WP_VERSION', version);
      await Promise.all([
        store.dispatch('hostingWordpressWpVersionIndex'),
        store.dispatch('hostingWordpressWpUpdateVersionIndex'),
      ]);

      return [data];
    };

    const fetchWordpressUpdateLogs = async (params: {
      directory: string;
      page?: number;
      perPage?: number;
    }) => {
      const [{ data }, error] = await wordpressRepo.getWordPressUpdatesLogs(
        params,
      );

      if (error) {
        return;
      }

      wordpressUpdateLogs.value = data;

      return data;
    };

    const fetchWordpressPresets = async () => {
      const [{ data }, error] = await wordpressRepo.getWordpressCollection({
        [Header.CLIENT_ID]: profileStore.account?.id || '',
      });

      if (error) return;

      wordpressPresets.value = data;

      return data;
    };

    const postWordpressRestore = async (params: WordpressRestoreRequest) => {
      const [, error] = await wordpressRepo.postWordPressRestore(params);

      if (!error) {
        toastr.s(t('Backup restore started successfully'));
      }
    };

    const getManagedAndOwnedWordpressWebsiteByDomain = (
      targetWebsite: string,
    ) => {
      if (!managedAndOwnedWordpressWebsites.value) return undefined;

      return managedAndOwnedWordpressWebsites.value.find(
        ({ website }) => website === targetWebsite,
      );
    };

    const fetchManagedAndOwnedWordpressWebsites = async (
      params: RequestConfig,
    ) => {
      isLoadingWordPressWebsites.value = true;
      const [{ data }, err] = await wordpressRepo.getWordpressListManaged(
        params,
      );
      isLoadingWordPressWebsites.value = false;

      if (err) return;

      managedAndOwnedWordpressWebsites.value = data;
    };

    const fetchSelectedWordpressCompatibility = async ({
      wpVersion,
      directory,
    }: {
      wpVersion: string;
      directory: string;
    }) => {
      selectedWordpressCompatibility.value = null;
      const [{ data }, err] = await wordpressRepo.getWordpressCompatibility({
        wpVersion,
        directory,
      });

      if (err) return;

      selectedWordpressCompatibility.value = data;
    };

    const fetchWordpressCompatibility = async (
      directory?: string,
      domain?: string,
    ) => {
      if (!domain) return;

      const [{ data }, err] = await wordpressRepo.getWordpressCompatibility({
        directory,
        domain,
      });

      if (err) return;

      wordpressCompatibility.value = data;
    };

    const fetchWordpressComponentsCompatibility = async (
      directory?: string,
      domain?: string,
    ) => {
      if (!domain) return;

      wordpressComponentsCompatibility.value = [];

      const [{ data }, err] =
        await wordpressRepo.getWordpressComponentsCompatibility({
          directory,
          domain,
        });

      if (err) return;

      wordpressComponentsCompatibility.value = data;
    };

    const fetchIsWordPressVersionValid = async ({
      domain,
      directory,
      headers,
      isForcedCheck,
    }: {
      domain: string;
      directory: string;
      headers?: Record<string, string>;
      isForcedCheck?: boolean;
    }) => {
      isWordPressVersionValidLoading.value = true;

      const [{ data }, err] = await wordpressRepo.getIsWordPressVersionValid({
        domain,
        directory,
        headers,
        isForcedCheck,
      });

      isWordPressVersionValidLoading.value = false;

      if (err) return;

      isWordPressVersionValid.value = data;
    };

    const getWordPressLitespeedLogs = async (
      params: IWordPressLitespeedPresets,
    ) => {
      const [{ data }, error] = await wordpressRepo.getWordPressLitespeedLogs(
        params,
      );

      if (error) {
        return;
      }

      wordPressLitespeedPresetLogs.value = data.data;

      return data;
    };

    const applyWordPressLitespeedPreset = async (
      preset: string,
      directory = '',
      domain: string,
    ) => {
      const [, error] = await wordpressRepo.postWordPressLitespeedPresetApply(
        preset,
        directory,
        domain,
      );

      if (error) {
        return;
      }
    };

    const restoreWordPressLitespeedPreset = async (
      backupKey: string,
      directory = '',
      domain: string,
    ) => {
      const [, error] = await wordpressRepo.postWordPressLitespeedPresetRestore(
        backupKey,
        directory,
        domain,
      );

      if (error) {
        return;
      }
      toastr.s(t('LiteSpeed preset restored successfully'));
    };

    const fetchWordPressLitespeedPresetLogs = async (
      params = {
        directory: (route.params.directory as string) || '',
        page: 1,
        perPage: 100,
        domain: route.params.domainToManage as string,
      },
    ) => await getWordPressLitespeedLogs(params);

    return {
      wordpressSettings,
      wordpressUpdateLogs,
      wordpressPresets,
      fetchSelectedWordpressCompatibility,
      managedAndOwnedWordpressWebsites,
      wordpressCompatibility,
      selectedWordpressCompatibility,
      isLoadingWordPressWebsites,
      wordpressComponentsCompatibility,
      wordpressApplications,
      fetchWordpressSettings,
      postWordpressSettings,
      updateWordPressVersion,
      isWordPressVersionValid,
      isWordPressVersionValidLoading,
      fetchWordpressList,
      fetchWordpressUpdateLogs,
      fetchIsWordPressVersionValid,
      fetchWordpressPresets,
      postWordpressRestore,
      fetchManagedAndOwnedWordpressWebsites,
      getManagedAndOwnedWordpressWebsiteByDomain,
      fetchWordpressCompatibility,
      fetchWordpressComponentsCompatibility,
      getWordPressLitespeedLogs,
      applyWordPressLitespeedPreset,
      wordPressLitespeedPresetLogs,
      restoreWordPressLitespeedPreset,
      fetchWordPressLitespeedPresetLogs,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.WORDPRESS_STORE },
  },
);
