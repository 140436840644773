import { computed } from 'vue';

import {
  HIcon,
  HostingerProPanel,
  Referral,
  type ReferralsListItem,
} from '@/types';

const DENIAL_REASON_MAP = {
  [Referral.RewardDenialReason.INVALID_PRODUCT]:
    'The client you referred bought a service other than hosting or VPS.',
  [Referral.RewardDenialReason.INVALID_PERIOD]:
    "The client you referred bought a plan that's shorter than a year.",
  [Referral.RewardDenialReason.SUBSCRIPTION_CANCELED]:
    'The client you referred cancelled their subscription.',
  [Referral.RewardDenialReason.NOT_FIRST_HOSTING_PURCHASE]:
    'The client you referred already had bought hosting before.',
};

export const useEarningsStatusMapper = (item: ReferralsListItem) => {
  const text = computed(() => {
    switch (item.status) {
      case HostingerProPanel.ProRewardStatus.APPROVED:
        return 'Approved';
      case HostingerProPanel.ProRewardStatus.DENIED:
        return 'Declined';
      case HostingerProPanel.ProRewardStatus.QUALIFIED:
        return 'Qualified';
      default:
        return 'Pending';
    }
  });

  const iconMetadata = computed(() => {
    switch (item.status) {
      case HostingerProPanel.ProRewardStatus.APPROVED:
        return {
          icon: HIcon.ICON_CHECK_CIRCLE_LIGHT,
          'icon-name': 'Approved',
          color: 'success',
        };
      case HostingerProPanel.ProRewardStatus.DENIED:
        return {
          icon: HIcon.ICON_HIGHLIGHT_OFF_OUTLINE,
          'icon-name': 'Declined',
          color: 'danger',
        };
      case HostingerProPanel.ProRewardStatus.QUALIFIED:
        return {
          icon: HIcon.ICON_CHECK_CIRCLE_LIGHT,
          'icon-name': 'Qualified',
          color: 'warning-dark',
        };
      default:
        return {
          icon: HIcon.ICON_UPDATE,
          'icon-name': 'Pending',
          color: 'gray',
        };
    }
  });

  const tooltip = computed(() => {
    switch (item.status) {
      case HostingerProPanel.ProRewardStatus.APPROVED:
        return '';
      case HostingerProPanel.ProRewardStatus.DENIED:
        return item.denialReason && DENIAL_REASON_MAP[item.denialReason]
          ? DENIAL_REASON_MAP[item.denialReason]
          : 'This could happen for several reasons: the wrong product was purchased, the invoice was not paid, or a refund was requested';
      case HostingerProPanel.ProRewardStatus.QUALIFIED:
        return "The client has bought a hosting or a VPS plan, but 45 days haven't passed.";
      default:
        return "The referral has been sent, but the client hasn't bought a plan yet.";
    }
  });

  return { text, iconMetadata, tooltip };
};
