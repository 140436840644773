import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import {
  useProPanelRoutes,
  useProfile,
  useReferralsPromotion,
} from '@/composables';
import {
  useCatalogStore,
  useProfileStore,
  useProsumerCartStore,
} from '@/stores';
import type { TBrandId } from '@/types';
import { Service, HBilling, Client, Referral } from '@/types';
import { isCurrentTimeWithinDates } from '@/utils/helpers';
import { monthsPerPeriodUnit } from '@/utils/services/chargebeeCurrencyService';
import currency from '@/utils/services/currencyService';

interface CalculateHostingPurchaseBonusParams {
  price: number;
  tax: number;
  catalogItemId?: string;
  commissionPercentage?: number;
}

const ONE_WEEK_IN_DAYS = 7;

// TODO rename to useReferToPurchase
export const useWebproPurchase = () => {
  const { isProPanelRoute } = useProPanelRoutes();
  const { accountDaysRegistered } = useProfile();
  const prosumerCartStore = useProsumerCartStore();
  const catalogStore = useCatalogStore();
  const profileStore = useProfileStore();
  const { activePromotionByTime } = useReferralsPromotion();

  const { isPurchaseForClient } = storeToRefs(prosumerCartStore);

  const isAllPlansPromotionActive = computed(
    () =>
      activePromotionByTime.value &&
      activePromotionByTime.value.key ===
        Referral.PromotionalTopBannerKey.ALL_PLANS,
  );

  const isEligibleForCommission = (periodUnit: string, period: number) => {
    const isOneMonthPurchase =
      monthsPerPeriodUnit(periodUnit) * Number(period) === 1;

    return !isOneMonthPurchase;
  };

  const getCommissionPercentage = (catalogItemId = '') => {
    const isEligibleBrandForSpecialOffer =
      checkBrandEligibilityForSpecialOffer();

    if (!isEligibleBrandForSpecialOffer) {
      return Referral.Rates.INITIAL_REFERRAL_RATE;
    }

    if (
      checkIsVpsAndCloudOfferActive(catalogItemId) ||
      isAllPlansPromotionActive.value
    ) {
      return Referral.Rates.SPECIAL_OFFER_COMMISSION;
    }

    return Referral.Rates.INITIAL_REFERRAL_RATE;
  };

  const checkIsVpsAndCloudOfferActive = (catalogItemId = '') => {
    const { category, subcategory } =
      catalogStore.getCatalogItemById(catalogItemId) || {};

    const isVpsAndCloudOfferActive = isCurrentTimeWithinDates({
      startsAt: '2024-08-19T09:00:00Z',
      endsAt: '2024-09-01T23:59:59Z',
    });

    const isAccountCreatedMoreThan7DaysAgo =
      accountDaysRegistered.value >= ONE_WEEK_IN_DAYS;

    const isEligibleForSpecialOffer = checkEligibilityForSpecialOffer(
      category,
      subcategory,
    );

    return (
      isVpsAndCloudOfferActive &&
      isAccountCreatedMoreThan7DaysAgo &&
      isEligibleForSpecialOffer
    );
  };

  const checkEligibilityForSpecialOffer = (
    category: string = '',
    subcategory: string = '',
  ) => {
    const isVps = category === HBilling.CatalogCategory.VPS;
    const isCloudHosting =
      category === HBilling.CatalogCategory.HOSTING &&
      subcategory === HBilling.CatalogSubcategory.CLOUD;

    return isVps || isCloudHosting;
  };

  const checkBrandEligibilityForSpecialOffer = () => {
    const ineligibleBrandIds: TBrandId[] = [
      Client.BrandId.Hostinger.RU,
      Client.BrandId.Other.WEBLINK,
      Client.BrandId.Other.HOSTMANIA,
      Client.BrandId.Other.HOSTING24,
      Client.BrandId.Other.NG_CO_ID,
    ];

    return !ineligibleBrandIds.includes(profileStore.brandId as TBrandId);
  };

  const calculateHostingPurchaseBonus = ({
    price,
    tax,
    catalogItemId,
    commissionPercentage,
  }: CalculateHostingPurchaseBonusParams) => {
    const finalAmount = price - tax;
    const discount = finalAmount * Referral.Rates.DISCOUNT_RATE;
    const finalAmountAfterDiscount = finalAmount - discount;
    const percentage =
      commissionPercentage || getCommissionPercentage(catalogItemId);
    const referralAmount = (finalAmountAfterDiscount / 100) * percentage;

    return currency.format(referralAmount);
  };

  const getProPurchaseModalData = (product: Service.Type) => {
    if (product === Service.Type.VPS) {
      return {
        isPurchaseForClient: isPurchaseForClient.value && isProPanelRoute.value,
        isReferralCommissionSnackbarVisible: true,
      };
    }
  };

  return {
    isEligibleForCommission,
    calculateHostingPurchaseBonus,
    getProPurchaseModalData,
    getCommissionPercentage,
    checkBrandEligibilityForSpecialOffer,
  };
};
