import { defineAsyncComponent } from 'vue';
import type { RouteRecordRaw } from 'vue-router';

import googleWorkspaceGuard from '@/guards/emails/googleWorkspaceGuard';
import googleWorkspaceSetupGuard from '@/guards/emails/googleWorkspaceSetupGuard';
import hostingEmails from '@/router/routes/emails/hostingEmails';
import titanEmailRoutes from '@/router/routes/emails/titanEmailRoutes';
import { Route } from '@/types';

export default [
  {
    path: '/emails',
    meta: {
      title: 'Emails',
    },
    children: [
      {
        path: '',
        name: Route.Email.EMAILS,
        meta: {
          title: 'Emails',
          sideMenuExists: false,
        },
        component: () => import('@/views/Emails/EmailsList.vue'),
      },
      {
        path: '/google-workspace/setup/:orderId',
        name: Route.Email.GOOGLE_WORKSPACE_SETUP,
        meta: {
          title: 'Finish your Google Workspace setup',
          sideMenuExists: false,
          showSubheader: false,
        },
        beforeEnter: (to, from, next) =>
          googleWorkspaceSetupGuard(to, from, next),
        component: () =>
          import('@/views/Emails/GoogleWorkspace/GoogleWorkspaceSetup.vue'),
      },
      {
        path: '/migrate-gsuite/setup/:orderId',
        name: Route.Email.MIGRATE_GSUITE,
        meta: {
          sideMenuExists: false,
          showSubheader: false,
        },
        beforeEnter: (to, from, next) =>
          googleWorkspaceSetupGuard(to, from, next),
        component: () =>
          import('@/views/Emails/GoogleWorkspace/GoogleGsuiteMigration.vue'),
      },
      {
        path: '/google-workspace/:domain?/:orderId?',
        name: Route.Email.GOOGLE_WORKSPACE_MANAGEMENT,
        meta: {
          title: 'Google Workspace',
          subHeaderComponent: defineAsyncComponent(
            () =>
              import(
                '@/components/Emails/GoogleWorkspace/GoogleWorkspaceLogo.vue'
              ),
          ),
          breadcrumbsTitle: {
            title: '@{domain}',
            translate: false,
          },
          breadcrumbs: ['emails'],
          sideMenuExists: false,
        },
        beforeEnter: (to, from, next) => googleWorkspaceGuard(to, from, next),
        component: () =>
          import(
            '@/views/Emails/GoogleWorkspace/GoogleWorkspaceManagement.vue'
          ),
      },
      ...titanEmailRoutes,
      ...hostingEmails,
    ],
  },
] as RouteRecordRaw[];
