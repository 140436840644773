import dayjs from 'dayjs';

import {
  Email,
  Service,
  AmplitudeEvent,
  AmplitudeLocation,
  HRESOURCES_TYPE,
} from '@/types';
import { getPercentage } from '@/utils/helpers';
import { getMonthlyPrice } from '@/utils/services/chargebeeCurrencyService';

export const isRenewDateValid = ({ billingDate, period, unit }) => {
  const now = dayjs();
  const nextBilling = dayjs(billingDate);
  const fromToday = now.add(period, unit);

  return dayjs(nextBilling).isBefore(fromToday);
};

export const isFreshInvoice = (date, invoiceLifeInMinutes = 5) => {
  const now = dayjs();
  const invoiceDate = dayjs(date);
  const invoiceDatePlusLifeInMinutes = invoiceDate.add(
    invoiceLifeInMinutes,
    'minute',
  );

  return invoiceDatePlusLifeInMinutes.isAfter(now);
};

export const downloadFile = (data, fileName, type = 'text/plain') => {
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.href = window.URL.createObjectURL(new Blob([data], { type }));
  a.setAttribute('download', fileName);
  a.click();
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

export const getTaxRateFromPricingDetails = (pricingDetails) =>
  pricingDetails.items?.[0]?.taxRate;

export const isInvoicePdfAvailable = (invoiceCreated) => {
  const birthOfhInvoicingInProd = dayjs(1658733336);
  const created = dayjs(invoiceCreated);

  return dayjs(created).isAfter(birthOfhInvoicingInProd);
};

export const transformProductIdToMonths = (productId) => {
  const period = productId.split('-').reverse()[0];
  if (period.includes('m')) {
    return Number(period.replace('m', ''));
  }

  if (period.includes('y')) {
    return Number(period.replace('y', '')) * 12;
  }
};

export const trackBillingAutoRenewalDisable = (item, amplitudeV2) => {
  if (item?.service === 'domain') {
    amplitudeV2(AmplitudeEvent.Domain.BILLING_AUTO_RENEW_DISABLED, {
      location: AmplitudeLocation.Base.DASHBOARD,
      subscriptionId: item.order_id,
      productName: item.domain,
      productGroup: 'Domain',
      productSubgroup:
        item.service === 'domain' ? 'Domain' : 'Domain - Privacy',
    });
  }

  try {
    window.hj('trigger', 'action_hbilling-disable_renewal');
  } catch {
    //  do nothing
  }
};

export const getServiceType = (resourceType) => {
  const type = resourceType.toLowerCase();

  if (type === HRESOURCES_TYPE.DOMAIN) {
    return Service.Type.DOMAIN;
  }

  if (type === HRESOURCES_TYPE.HOSTING) {
    return Service.Type.HOSTING;
  }

  if (
    [
      HRESOURCES_TYPE.EMAIL,
      HRESOURCES_TYPE.GOOGLE_WORKSPACE,
      HRESOURCES_TYPE.TITAN_MAIL,
      Email.Product.HOSTINGER_MAIL,
      Email.Product.HMAIL,
      Email.Product.FLOCK_MAIL,
      Email.Product.GOOGLE_GSUITE,
    ].includes(type)
  ) {
    return Service.Type.EMAIL;
  }

  return null;
};

export const transformToMonolithBillingPrices = (prices) =>
  prices.map((price) => {
    const old = getMonthlyPrice(
      price.period,
      price.periodUnit,
      price.basePrice,
    );

    const newPrice = getMonthlyPrice(
      price.period,
      price.periodUnit,
      price.upgradePrice || price.firstPeriodPrice,
    );

    const discount = price.basePrice - price.upgradePrice;

    return {
      ...price,
      chargebee: true,
      old,
      new: newPrice,
      discount: getPercentage(discount, price.basePrice, true),
    };
  });

export const transformToMonolithBilling = (plan, isCurrent = false) => ({
  ...plan,
  planName: plan.name,
  name: plan.externalName,
  title: plan.externalName,
  upgradePerks: plan.metadata.features,
  upgradePricing: transformToMonolithBillingPrices(plan.prices),
  isCurrent,
});
