import { domainsRepo } from '@/repositories';
import { router } from '@/router';
import { getStateVariable, setForDomain } from '@/store/storeHelper';

const initial = {
  parkedDomains: [],
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
    parkedDomainsUpdated: null,
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_PARKED_DOMAINS(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'parkedDomains', data);
    },
    SET_PARKED_DOMAINS_IS_POINTING(state, { domainName, isPointing }) {
      const parkedDomains = getStateVariable(state, 'parkedDomains');
      const parkedDomain = parkedDomains.find(
        (domain) => domain.parkedDomain === domainName,
      );
      if (parkedDomain) parkedDomain.nsPointing = isPointing;
      setForDomain(state, null, 'parkedDomains', [...parkedDomains]);
    },
    SET_PARKED_DOMAINS_LOADED(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'loaded', data);
    },
  },
  getters: {
    getParkedDomains: (state) => getStateVariable(state, 'parkedDomains'),
    getHostingDomainsParkedDomainsLoaded: (state) =>
      getStateVariable(state, 'loaded'),
  },
  actions: {
    async hostingDomainsParkedDomainsIndex(context) {
      const [{ data, requestDomain }, err] =
        await domainsRepo.getParkedDomains();

      if (!err) {
        context.dispatch('hostingOrdersDetailsIndex');
        context.commit('SET_PARKED_DOMAINS', { data, requestDomain });
        context.commit('SET_PARKED_DOMAINS_LOADED', {
          data: true,
          requestDomain,
        });
      }
    },
    async hostingDomainsParkedDomainsStore(context, { parkDomain, emailType }) {
      const [{ data }, err] = await domainsRepo.addParkedDomain({
        parkDomain,
        emailType,
      });
      if (data) context.dispatch('hostingDomainsParkedDomainsIndex');

      return [{ data }, err];
    },
    async hostingDomainsParkedDomainsDestroy(context, domain) {
      const [{ data }, err] = await domainsRepo.deleteParkedDomain(domain);
      context.dispatch('hostingDomainsParkedDomainsIndex');

      return [{ data }, err];
    },
    async hostingDomainsParkedDomainsCheckIsPointing(context, domain) {
      const mainDomain = router.currentRoute.value.params.domain;
      const params = context.getters.getAccountHttpParams(mainDomain);

      if (domain.isIp) return;
      const [{ data }, err] = await domainsRepo.isDomainPointing({
        domain: domain.parkedDomain,
        username: params.username,
        orderId: params.orderId,
      });

      if (!err) {
        context.commit('SET_PARKED_DOMAINS_IS_POINTING', {
          domainName: domain.parkedDomain,
          isPointing: data,
        });
      }
    },
  },
};
