import type { IHostingAccount } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

type HostingWebsiteAccountIdentifier = {
  orderId: IHostingAccount['orderId'];
};

export default {
  url: ({ orderId }: HostingWebsiteAccountIdentifier) =>
    `${process.env.VITE_API_WH_API}/api/hapi/v1/orders/${orderId}`,

  async renameOrder(
    accountIdentifier: HostingWebsiteAccountIdentifier,
    customPlanName: string,
  ) {
    return await hAsync(
      http.patch(`${this.url(accountIdentifier)}/rename`, {
        name: customPlanName,
      }),
    );
  },
};
