import type { IDatabase } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v2/databases`,

  async getDatabases(onlyAssigned = 0, domain = '') {
    return await hAsync<IDatabase[]>(
      http.get(this.url, {
        params: { onlyAssigned },
        domain,
      }),
    );
  },
  async getRemoteDatabases() {
    return await hAsync(http.get(`${this.url}/remote-connections`));
  },
  // @ts-ignore
  async createDatabase(request) {
    return await hAsync(
      http.post(this.url, request, {
        whitelistedStatusCodes: [400],
      }),
    );
  },
  // @ts-ignore
  async setDatabaseDomain(request) {
    return await hAsync(http.patch(`${this.url}/vhost`, request));
  },
  // @ts-ignore
  async createRemoteDatabase(request) {
    return await hAsync(
      http.post(`${this.url}/remote-connections`, request, {
        whitelistedStatusCodes: [422],
      }),
    );
  },
  // @ts-ignore
  async deleteDatabase(dbName) {
    return await hAsync(http.delete(this.url, { params: { dbName } }));
  },
  // @ts-ignore
  async deleteRemoteDatabase(params) {
    return await hAsync(
      http.delete(`${this.url}/remote-connections`, { params }),
    );
  },
  // @ts-ignore
  async repairDatabase(request) {
    return await hAsync(http.patch(`${this.url}/repair`, request));
  },
  // @ts-ignore
  async backupDatabase(dbName) {
    return await hAsync(http.patch(`${this.url}/backup`, { dbName }));
  },
  // @ts-ignore
  async updatePasswordDatabase(request) {
    return await hAsync(http.patch(`${this.url}/password`, request));
  },
  // @ts-ignore
  async updatePermissionsDatabase(request) {
    return await hAsync(http.patch(`${this.url}/permissions`, request));
  },
  // @ts-ignore
  async getMyAdminLinkDatabase(dbName, domain) {
    return await hAsync(
      http.get(`${this.url}/phpmyadmin-link`, { params: { dbName }, domain }),
    );
  },
};
