<template>
  <div class="h-portlet" :class="classes" @click="$emit('click')">
    <HPortletHead
      v-if="showPortletHead"
      :no-head-wrap="noHeadWrap"
      :full-width-title="fullWidthTitle"
      :body-class="headBodyClass"
      :tools-class="headToolsClass"
      :tool-position="toolPosition"
      :no-head-padding-x="noHeadPaddingX"
      :class="{
        'h-portlet__head--collapsible-arrow': collapsible && !noArrow,
        'border-bottom-0': !$slots.default,
        'hover-transitions': hover,
      }"
      no-head-margin
      @portlet-head-clicked="collapse"
    >
      <template #tools>
        <slot name="tools" />
        <a
          v-if="collapsible && !noArrow"
          v-tooltip.right="{
            content: collapsed ? 'Show' : 'Hide',
          }"
          href
          class="h-portlet__head-collapse-icon"
          @click.prevent="collapse"
        >
          <HpIcon
            primary
            icon="ic-keyboard-arrow-down"
            class="align-baseline cursor-pointer"
          />
        </a>
      </template>
      <template v-if="$slots.title" #title>
        <slot name="title" />
      </template>
      <template v-if="$slots.subtitle" #subtitle>
        <slot name="subtitle" />
      </template>
      <template v-if="$slots.icon" #icon>
        <slot name="icon" />
      </template>
      <template v-if="$slots.status" #status>
        <slot name="status" />
      </template>
    </HPortletHead>

    <div class="h-portlet__body position-relative" :class="hPortletBodyClasses">
      <div
        v-if="loader || disabledLoader"
        :class="{
          'position-relative u-padding-2rem': noBodyPadding && !disabledLoader,
          'disabled-view': disabledLoader,
          'd-flex justify-content-center align-items-center': loader,
        }"
      >
        <div>
          <CircleLoader />
        </div>
      </div>
      <slot v-if="!loader" />
    </div>
    <div v-if="$slots.footer" class="h-portlet__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import HPortletHead from './HPortletHead.vue';

import CircleLoader from '../Loaders/CircleLoader';

export default {
  components: { CircleLoader, HPortletHead },
  name: 'HPortlet',
  props: {
    fullHeight: {
      type: Boolean,
      required: false,
    },
    noHeadMargin: Boolean,
    noHeadPaddingX: Boolean,
    headBodyClass: String,
    headToolsClass: String,
    noBodyPadding: Boolean,
    hover: Boolean,
    noBodyTopPadding: Boolean,
    noBorderRadius: Boolean,
    noBorder: Boolean,
    noBackground: Boolean,
    noMargin: Boolean,
    fullWidthTitle: Boolean,
    dashboard: Boolean,
    loader: Boolean,
    removeLoader: Boolean,
    noHeadLine: Boolean,
    disabledLoader: Boolean,
    bodyClasses: Object,
    cardClasses: Object,
    opened: {
      type: Boolean,
      default: true,
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
    noArrow: {
      type: Boolean,
      default: false,
    },
    noHeadWrap: Boolean,
    toolPosition: {
      type: String,
      default: 'right',
    },
  },
  emits: ['click'],
  data() {
    return {
      collapsed: !this.opened,
    };
  },
  computed: {
    showPortletHead() {
      return (
        !!this.$slots.title ||
        this.$slots.tools ||
        this.$slots.icon ||
        this.$slots.status
      );
    },
    classes() {
      return {
        'h-portlet--full-height': this.fullHeight,
        'h-portlet--no-background': this.noBackground,
        'd-none': this.removeLoader,
        'h-portlet--no-head-line': this.noHeadLine,
        'h-portlet--dashboard': this.dashboard,
        'h-portlet--collapsible': this.collapsible,
        'h-portlet--collapsed': this.collapsed,
        'h-portlet--no-margin': this.noMargin,
        'h-portlet--no-border-radius': this.noBorderRadius,
        'h-portlet--no-border': this.noBorder,
        ...this.cardClasses,
      };
    },
    hPortletBodyClasses() {
      return {
        'h-portlet__body--no-top-padding': this.noBodyTopPadding,
        'h-portlet__body--collapsed': this.collapsed,
        'h-portlet__body--no-padding':
          ((this.$slots.title || this.$slots.subtitle) &&
            !this.$slots.default) ||
          this.noBodyPadding,
        ...this.bodyClasses,
      };
    },
  },
  methods: {
    collapse() {
      if (this.collapsible) {
        this.collapsed = !this.collapsed;
      }
    },
  },
  watch: {
    opened(newValue) {
      this.collapsed = !newValue;
    },
  },
};
</script>

<style lang="scss">
@import '../../sass/components/portlet';
@import '../../sass/components/disabled-view';
@import '../../sass/pages/hosting';

.hover-transitions:hover {
  transition-duration: 0.6s;

  background-color: var(--primary-dark-hover);
}
</style>
