import type { App } from 'vue';
import type { Router } from 'vue-router';

import { amplitudePlugin } from './amplitudeV2';
import './dayjs.plugin';
import forter from './forter';

export const initAllPlugins = (context: {
  app: App<Element>;
  router: Router;
  store: any;
}) => {
  const pluginContext = {
    ...context,
    currentRouteRef: context.router.currentRoute,
  };
  amplitudePlugin(pluginContext);
  forter();
};
