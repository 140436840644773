import type { IntercomOpenTicket } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  integrationsUrl: `${process.env.VITE_API_INTEGRATIONS}/api/direct`,

  async getTickets() {
    return await hAsync<IntercomOpenTicket[]>(
      http.get(`${this.integrationsUrl}/intercom/tickets`),
    );
  },
};
