import { computed } from 'vue';

import { useVpsSaleCampaign } from '@/composables';
import type { SaleBannerConfig } from '@/types';
import { BlackFridaySale, SaleTheme } from '@/types';
import { getAsset } from '@/utils/helpers/assetsHelpers';

export const useBlackFridayConfig = () => {
  const { getVpsSalePrice, isHostingResourceAvailable } = useVpsSaleCampaign();

  const kvmSalePrice = computed(() => getVpsSalePrice('kvm2'));

  const config = computed<SaleBannerConfig>(() => ({
    [BlackFridaySale.VPS_KVM_SALE]: {
      [SaleTheme.DARK]: {
        image: '@/images/blackFriday/bf-universal-dark.png',
        backgroundColor: 'black',
        backgroundImage: `url(${getAsset(
          '@/images/blackFriday/bf-sticky-banner-dark.webp',
        )}`,
        ctaText: isHostingResourceAvailable.value
          ? 'Get VPS Deal'
          : 'Claim Deal',
        markColor: 'meteorite',
        subheadlineText: 'Get KVM 2 Plan for only:',
        textColor: 'light',
        timerLabelColor: 'light',
        timerThemeColor: 'dark-light',
        toggleBtnColor: 'light',
        price: {
          new: kvmSalePrice.value?.new,
          old: kvmSalePrice.value?.old,
        },
      },
      [SaleTheme.LIGHT]: {
        image: '@/images/blackFriday/bf-universal-light.png',
        backgroundColor: 'primary-light',
        backgroundImage: `url(${getAsset(
          '@/images/blackFriday/bf-sticky-banner-light.webp',
        )})`,
        ctaText: isHostingResourceAvailable.value
          ? 'Get VPS Deal'
          : 'Claim Deal',
        markColor: 'primary',
        subheadlineText: 'Get KVM 2 Plan for only:',
        textColor: 'meteorite-dark',
        timerLabelColor: 'meteorite-dark',
        timerThemeColor: 'meteorite-light',
        toggleBtnColor: 'meteorite-dark',
        price: {
          new: kvmSalePrice.value?.new,
          old: kvmSalePrice.value?.old,
        },
      },
    },
    [BlackFridaySale.VPS_SALE]: {
      [SaleTheme.DARK]: {
        image: '@/images/blackFriday/63-dark.png',
        backgroundColor: 'black',
        backgroundImage: `url(${getAsset(
          '@/images/blackFriday/bf-sticky-banner-dark.webp',
        )}`,
        ctaText: isHostingResourceAvailable.value
          ? 'Get VPS Deal'
          : 'Claim Deal',
        markColor: 'meteorite',
        subheadlineText: 'Up to 63% off VPS Hosting',
        textColor: 'light',
        timerLabelColor: 'light',
        timerThemeColor: 'dark-light',
        toggleBtnColor: 'light',
      },
      [SaleTheme.LIGHT]: {
        image: '@/images/blackFriday/63-light.png',
        backgroundColor: 'primary-light',
        backgroundImage: `url(${getAsset(
          '@/images/blackFriday/bf-sticky-banner-light.webp',
        )})`,
        ctaText: isHostingResourceAvailable.value
          ? 'Get VPS Deal'
          : 'Claim Deal',
        markColor: 'primary',
        subheadlineText: 'Up to 63% off VPS Hosting',
        textColor: 'meteorite-dark',
        timerLabelColor: 'meteorite-dark',
        timerThemeColor: 'meteorite-light',
        toggleBtnColor: 'meteorite-dark',
      },
    },
    [BlackFridaySale.HOSTING_UPGRADE_SALE]: {
      [SaleTheme.DARK]: {
        image: '@/images/blackFriday/77-dark.png',
        backgroundColor: 'black',
        backgroundImage: `url(${getAsset(
          '@/images/blackFriday/bf-sticky-banner-dark.webp',
        )}`,
        ctaText: 'Unlock offer',
        markColor: 'meteorite',
        subheadlineText: 'Upgrade to a better plan for a fraction of the price',
        textColor: 'light',
        timerLabelColor: 'light',
        timerThemeColor: 'dark-light',
        toggleBtnColor: 'light',
        moneyBackBadge: { color: 'light' },
      },
      [SaleTheme.LIGHT]: {
        image: '@/images/blackFriday/77-light.png',
        backgroundColor: 'primary-light',
        backgroundImage: `url(${getAsset(
          '@/images/blackFriday/bf-sticky-banner-light.webp',
        )})`,
        ctaText: 'Unlock offer',
        markColor: 'primary',
        subheadlineText: 'Upgrade to a better plan for a fraction of the price',
        textColor: 'meteorite-dark',
        timerLabelColor: 'meteorite-dark',
        timerThemeColor: 'meteorite-light',
        toggleBtnColor: 'meteorite-dark',
        moneyBackBadge: { color: 'primary' },
      },
    },
    [BlackFridaySale.HOSTING_PURCHASE_SALE]: {
      [SaleTheme.DARK]: {
        image: '@/images/blackFriday/bf-universal-dark.png',
        backgroundColor: 'black',
        backgroundImage: `url(${getAsset(
          '@/images/blackFriday/bf-sticky-banner-dark.webp',
        )}`,
        ctaText: 'Claim Deal',
        markColor: 'meteorite',
        subheadlineText: 'Save huge on Hosting + Website Builder',
        textColor: 'light',
        timerLabelColor: 'light',
        timerThemeColor: 'dark-light',
        toggleBtnColor: 'light',
        moneyBackBadge: { color: 'light' },
      },
      [SaleTheme.LIGHT]: {
        image: '@/images/blackFriday/bf-universal-light.png',
        backgroundColor: 'primary-light',
        backgroundImage: `url(${getAsset(
          '@/images/blackFriday/bf-sticky-banner-light.webp',
        )})`,
        ctaText: 'Claim Deal',
        markColor: 'primary',
        subheadlineText: 'Save huge on Hosting + Website Builder',
        textColor: 'meteorite-dark',
        timerLabelColor: 'meteorite-dark',
        timerThemeColor: 'meteorite-light',
        toggleBtnColor: 'meteorite-dark',
        moneyBackBadge: { color: 'primary' },
      },
    },
  }));

  return {
    config,
  };
};
