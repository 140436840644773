import type {
  NavigationGuard,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';

import { useFeatureFlag } from '@/composables';
import { FeatureFlag, Route } from '@/types';

const resourcesPinotGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { isFeatureEnabled: isResourcesUsingPinot } = useFeatureFlag(
    FeatureFlag.ID.RESOURCES_PINOT,
  );

  if (isResourcesUsingPinot.value) {
    return next({ name: Route.HostingOrder.ORDER_USAGE_V2, params: to.params });
  }

  next();
};

export default resourcesPinotGuard;
