<script setup lang="ts">
import { onMounted, ref, onBeforeUnmount, nextTick } from 'vue';

import HLabelV2 from '@/components/HLabelV2.vue';
import { useGlobals } from '@/composables';
import type { SideMenuRoute, SideMenuBackButton, CloseButton } from '@/types';
import { HIcon } from '@/types';

type Props = {
  routes?: SideMenuRoute[];
  currentRoute: string;
  feedback?: boolean;
  feedbackOpen?: boolean;
  backButton?: SideMenuBackButton;
  sideMenuFixed?: boolean;
  noPadding?: boolean;
  closeButton?: CloseButton;
  isFullHeight?: boolean;
  sideMenuComponentFile?: boolean;
  feedbackDisabled?: boolean;
};

interface Emits {
  (eventName: 'on-back', payload: SideMenuBackButton): void;
  (eventName: 'navigate-to-route', payload: SideMenuRoute): void;
  (eventName: 'on-close'): void;
  (eventName: 'toggle-feedback'): void;
}

const emit = defineEmits<Emits>();
const props = defineProps<Props>();
const sidemenu = ref<HTMLElement | null>(null);
const isFeedbackButtonFixed = ref(true);
const { t } = useGlobals();

const isActive = (route: SideMenuRoute) => props.currentRoute === route.name;

const getInitialExpandedItem = () =>
  props.routes?.findIndex(
    (route) => !!route.routes?.find((innerRoute) => isActive(innerRoute)),
  );
const isExpanded = (index: number) => expandedItem.value === index;
const expandedItem = ref(getInitialExpandedItem());

const onRouteClick = (route: SideMenuRoute) => {
  emit('navigate-to-route', route);
};

const toggleExpanded = (e: Event, index: number) => {
  e.stopImmediatePropagation();
  expandedItem.value === index
    ? (expandedItem.value = -1)
    : (expandedItem.value = index);
};

onMounted(async () => {
  await nextTick();
  getFeedbackButtonType();
  window.addEventListener('resize', getFeedbackButtonType);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', getFeedbackButtonType);
});

const getFeedbackButtonType = () => {
  const headerOffset = 150;
  isFeedbackButtonFixed.value =
    window.innerHeight - (sidemenu.value?.clientHeight || 0) > headerOffset;
};
</script>

<template>
  <div>
    <div v-if="closeButton" class="hp-sidemenu__close">
      <a class="hp-sidemenu__close-btn" @click="emit('on-close')">
        <span class="hp-sidemenu__close-btn__text">{{ closeButton.text }}</span>
        <HpIcon primary static-view-box :icon="HIcon.ICON_CLOSE" />
      </a>
    </div>
    <div
      ref="sidemenu"
      class="hp-sidemenu-holder"
      :class="[
        {
          'hp-sidemenu-holder--no-padding': noPadding,
        },
      ]"
    >
      <div
        :class="[
          {
            'hp-sidemenu--fixed': sideMenuFixed,
            'hp-sidemenu--no-padding': noPadding,
          },
        ]"
      >
        <div
          v-if="backButton"
          :id="backButton.id"
          class="hp-sidemenu__back-container hp-sidemenu__item"
          @click="backButton && emit('on-back', backButton)"
        >
          <div class="hp-sidemenu__item-link hp-sidemenu__item-link-back">
            <HpIcon gray :icon="HIcon.ICON_ARROW_BACK" static-view-box />
            <span>{{ t(backButton.text) }}</span>
          </div>
        </div>
        <div
          v-if="sideMenuComponentFile"
          class="hp-sidemenu__slot"
          :class="{ 'hp-sidemenu__slot--full-height': isFullHeight }"
        >
          <slot />
        </div>
        <nav>
          <ul class="hp-sidemenu__list">
            <template v-for="(route, routeKey) in routes" :key="routeKey">
              <li class="hp-sidemenu__item">
                <div
                  :id="route?.id"
                  class="hp-sidemenu__item-link"
                  :class="[
                    {
                      'hp-sidemenu__item-link--active':
                        isActive(route) && !route.routes,
                    },
                  ]"
                  @click="
                    route.routes
                      ? toggleExpanded($event, routeKey)
                      : onRouteClick(route)
                  "
                >
                  <div>
                    <HpIcon
                      v-if="route.routes"
                      :key="route.icon"
                      class="h-ml-0"
                      gray
                      :icon="
                        isExpanded(routeKey)
                          ? HIcon.ICON_ARROW_DROP_DOWN
                          : HIcon.ICON_ARROW_DROP_RIGHT
                      "
                    />
                    <!-- @vue-ignore -->
                    <HpIcon
                      v-if="route.icon"
                      :key="route.icon"
                      class="hp-sidemenu__item-link-icon"
                      :icon="(route.icon as any)"
                    />
                  </div>
                  {{ t(route.title) }}
                  <HLabelV2
                    v-if="!!route.labelContent"
                    theme="danger"
                    class="hp-sidemenu__item-label"
                  >
                    {{ t(route.labelContent) }}
                  </HLabelV2>
                </div>
              </li>
              <template v-if="route.routes && isExpanded(routeKey)">
                <ul
                  v-for="nestedRoute in route.routes"
                  :key="nestedRoute.title"
                  class="hp-sidemenu__nested-routes"
                >
                  <li
                    :class="{
                      'hp-sidemenu__nested-routes-item--active':
                        isActive(nestedRoute),
                    }"
                    @click="onRouteClick(nestedRoute)"
                  >
                    {{ t(nestedRoute.title) }}
                  </li>
                </ul>
              </template>
            </template>
          </ul>
        </nav>
        <div
          v-if="feedback"
          class="hp-sidemenu__footer hp-sidemenu__item-link"
          :class="{
            'hp-sidemenu__footer--fixed': isFeedbackButtonFixed,
            'hp-sidemenu__item-link--active': !feedbackDisabled && feedbackOpen,
            'hp-sidemenu__item-link--disabled': feedbackDisabled,
          }"
          @click="!feedbackDisabled && emit('toggle-feedback')"
        >
          <div>
            <HpIcon
              class="hp-sidemenu__item-link-icon"
              :icon="HIcon.ICON_CHAT"
            />
          </div>
          <span
            v-tooltip.top="
              feedbackDisabled && {
                content:
                  'We have received your last feedback. Feedback can be left once an hour.',
                size: 200,
              }
            "
          >
            {{ t('Give feedback') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$hp-sidemenu-width: 280px;

:host(hp-sidemenu) {
  min-height: 100%;
  width: $hp-sidemenu-width;
  min-width: $hp-sidemenu-width;
  background: var(--light);
  border-right: 1px solid var(--gray-border);
  transition: all 0.5s;
  overflow-y: auto;

  @media only screen and (max-width: 992px) {
    padding: 40px 24px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    margin-left: -$hp-sidemenu-width;
    border-right: none !important;
    z-index: var(--z-index-3);
  }
}

.hp-sidemenu-holder {
  padding: 32px 24px;

  @media only screen and (max-width: 992px) {
    padding: 8px;
    box-shadow: none;
  }

  &--no-padding {
    padding: unset;
  }
}

.hp-sidemenu {
  margin-left: 0;

  &--no-padding {
    padding: 32px 32px 32px 0;

    > nav {
      width: 100%;
    }
  }

  &__item-label {
    margin-left: 8px;
  }

  &__nested-routes {
    list-style: none;
    padding-left: 0;
    min-width: max-content;

    li {
      cursor: pointer;
      border-radius: 0 32px 32px 0;
      padding: 8px 0 8px 80px;
      color: var(--gray);

      @media (max-width: 768px) {
        padding: 12px 16px 12px 16px;
      }

      &:hover {
        background-color: hsla(220, 9%, 87%, 0.24);
      }
    }
  }

  &__nested-routes-item {
    &--active,
    &--active:hover {
      &:not(.s) {
        background-color: var(--primary-light);
        color: var(--primary);
      }
    }
  }

  &__footer {
    position: relative;
    width: 100%;

    &--fixed {
      position: fixed;
      bottom: 32px;
      left: 0;
    }

    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

  &--fixed {
    position: fixed;
    display: flex;
    height: fill;
    flex-direction: column;

    @media only screen and (max-width: 992px) {
      flex-direction: row;
    }

    .hp-sidemenu__footer {
      position: static;
      margin-top: auto;
      padding: 14px 32px;

      @media only screen and (max-width: 992px) {
        display: none;
      }
    }
  }

  &__slot {
    max-width: 247px;
    word-break: break-all;

    &--full-height {
      height: 100%;
    }
  }

  &__close {
    display: flex;
    justify-content: flex-end;
    max-width: 247px;
    margin-bottom: 8px;
    @media only screen and (min-width: 992px) {
      display: none;
    }
    &-btn {
      &,
      &:visited,
      &:hover,
      &:active,
      &:focus,
      &:active:hover {
        text-decoration: none;
      }
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        text-decoration: none;
      }

      &__text {
        color: var(--primary);
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        margin-right: 8px;
      }
    }
  }

  &__list {
    margin: unset;
    list-style: none;
    padding: 0;
    min-width: 247px;
  }

  &__item-link {
    display: flex;
    align-items: center;
    padding: 16px 32px;
    font-weight: bold;
    max-width: 247px;
    cursor: pointer;

    color: var(--gray);
    &-back {
      width: 247px;
    }

    &--active {
      color: var(--primary);
      background: var(--primary-light);

      @media only screen and (max-width: 992px) {
        background-color: transparent;
      }
    }

    &--active,
    &:hover {
      border-radius: 0 24px 24px 0;
    }

    &:hover {
      text-decoration: none;
      color: var(--primary);
      background: var(--gray-light);

      @media only screen and (max-width: 992px) {
        background-color: transparent;
      }
    }

    &--disabled:hover {
      color: var(--gray);
      background: none;
      cursor: not-allowed;
    }

    &--active:hover {
      color: var(--primary);

      @media only screen and (min-width: 992px) {
        background: var(--primary-light);
      }

      svg {
        fill: var(--primary);
      }
    }
    > div {
      display: flex;
      align-items: center;
    }
  }

  &__back-container {
    border-bottom: 1px solid var(--gray-border);
    color: var(--secondaryText);

    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 24px;
    margin-right: -32px;
    padding-bottom: 8px;
    cursor: pointer;

    span {
      margin-left: 8px;
    }

    @media only screen and (max-width: 992px) {
      border-bottom: none;

      .hp-sidemenu__item-link {
        display: flex;
        max-width: 247px;
        &-back {
          width: unset;
        }
        span {
          display: none;
        }
        &--active &-icon {
          fill: var(--primary);
          color: var(--primary);
        }
      }
    }
  }

  &__item-link-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;

    fill: var(--gray);
    color: var(--gray);
  }

  &__item-link--active &__item-link-icon {
    fill: var(--primary);
    color: var(--primary);
  }

  @media only screen and (max-width: 992px) {
    &-holder--no-padding {
      background: var(--light);
      position: fixed;
      width: 100%;
      min-height: unset;
      display: flex;
      padding: 0;
      margin: 0;
      overflow-x: auto;
      height: 48px;
      left: 0;
      top: var(--marginless-header-height);

      @media only screen and (max-width: 576px) {
        top: var(--marginless-header-height);
      }

      -webkit-overflow-scrolling: touch;
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;
      .hp-sidemenu--no-padding {
        position: relative;
        display: flex;
        left: 0;
        overflow: visible;
        padding: unset;
        width: 100%;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__list {
      display: flex;
      height: 48px;
      margin: 0;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 0;
      height: 48px;
      margin: 0 auto;
      padding: 0 8px;
    }

    &__item-link {
      display: flex;
      white-space: nowrap;
      align-items: center;
      padding: 8px;
      font-weight: bold;
    }

    &__footer {
      display: none;
    }

    &__item-link-icon {
      display: none;
    }
  }

  .white-space-no-wrap {
    white-space: nowrap;
  }
}
</style>
