import axios from 'axios';
import dayjs from 'dayjs';

import { autoInstallerRepo, wordpressRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { decodeUrl } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';

const initial = {
  applications: [],
  categories: [],
  installedApplications: [],
  installedApplicationsLoaded: false,
  languages: {},
  loaded: false,
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setAutoInstallerApplicationLanguages(state, { data, requestDomain }) {
      const applications = Object.values(data);

      setForDomain(state, requestDomain, applications);

      setForDomain(state, requestDomain, 'applications', applications);
    },

    setAutoInstallerInstalledApplications(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'installedApplications', data || []);
    },

    setAutoInstallerInstalledApplicationsLoaded(
      state,
      { data, requestDomain },
    ) {
      setForDomain(state, requestDomain, 'installedApplicationsLoaded', data);
    },

    setAutoInstallerWordpressLanguages(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'languages', data);
    },

    setAutoInstallerApplicationsLoaded(state, { data, requestDomain }) {
      setForDomain(state, requestDomain, 'loaded', data);
    },

    removeAutoInstallerInstalledApplication(state, id) {
      state.installedApplications = state.installedApplications?.filter(
        (application) => application.id !== id,
      );
    },

    removeAutoInstallerWordpressApplication(state, directory) {
      state.installedApplications = state.installedApplications?.filter(
        (application) => application.directory !== directory,
      );
    },
  },

  getters: {
    getAutoInstallerApplications: (state) =>
      getStateVariable(state, 'applications'),

    getAutoInstallerInstalledApplications: (state) =>
      getStateVariable(state, 'installedApplications'),

    getAutoInstallerApplicationCategories: (state) =>
      getStateVariable(state, 'categories'),

    getAutoInstallerApplicationLoaded: (state) =>
      getStateVariable(state, 'loaded'),

    getAutoInstallerInstalledApplicationLoaded: (state) =>
      getStateVariable(state, 'installedApplicationsLoaded'),

    getAutoInstallerWordpressLanguages: (state) =>
      getStateVariable(state, 'languages'),
  },

  actions: {
    async autoInstallerApplicationsIndex({ commit }) {
      const [{ data, requestDomain }, error] =
        await autoInstallerRepo.getAutoInstaller();
      if (!error) {
        commit('setAutoInstallerApplicationLanguages', {
          data,
          requestDomain,
        });
        commit('setAutoInstallerInstalledApplicationsLoaded', {
          data: true,
          requestDomain,
        });
        commit('setAutoInstallerWordpressLanguages', {
          data: data.languages,
          requestDomain,
        });
        commit('setAutoInstallerApplicationsLoaded', {
          data: true,
          requestDomain,
        });
      }

      return [data, error];
    },

    async autoInstallerInstalledApplicationsIndex(context) {
      const [
        { data: wpInstallations, requestDomain: requestDomainWpInstallation },
      ] = await context.dispatch('hostingWordpressCoreDetailsIndex');

      const [{ data, requestDomainAutoInstallation }, error] =
        await autoInstallerRepo.getAppInstallations();

      const values = [data, wpInstallations];

      let wordPressApps;
      let requestDomain;
      let autoinstallerApps;

      if (values && !error) {
        wordPressApps = values[1] || [];
        autoinstallerApps = values[0] || [];
        requestDomain =
          requestDomainWpInstallation || requestDomainAutoInstallation;

        checkIncompleteItems(autoinstallerApps, context);
        autoinstallerApps.map((app) => {
          app.url = decodeUrl(app.url);
        });
        wordPressApps.map((app) => {
          app.url = decodeUrl(app.url);
        });
      }

      context.commit('setAutoInstallerInstalledApplications', {
        data: [...autoinstallerApps, ...wordPressApps],
        requestDomain,
      });

      return values;
    },

    async autoInstallerInstallNewApplication(context, modalInstall) {
      const CancelToken = axios.CancelToken;
      let cancel;

      if (modalInstall.application !== 'wordpress') {
        setTimeout(() => {
          cancel();
        }, 60000);
      }

      await autoInstallerRepo.postAutoInstaller(modalInstall, {
        timeout: 120000,
        cancelToken: new CancelToken((c) => (cancel = c)),
      });

      context.dispatch('autoInstallerInstalledApplicationsIndex');
    },
    async autoInstallerInstallNewWordpress({ dispatch }, modalInstall) {
      const domain = modalInstall.domain;
      delete modalInstall.domain;

      const [, err] = await wordpressRepo.postWordpress(
        {
          command: 'core_install',
          domain,
          params: modalInstall,
        },
        { hideToastr: true },
      );

      if (err) {
        errorLogger.logError(new Error('Failed to auto install WordPress'), {
          cause: err,
        });
      }

      dispatch('autoInstallerInstalledApplicationsIndex', true);

      return [, err];
    },
    async autoInstallerDeleteInstalledApplication({ commit, dispatch }, id) {
      const [{ data }] = await autoInstallerRepo.destroyAutoInstaller(id);

      if (data) {
        commit('removeAutoInstallerInstalledApplication', id);
      }
      dispatch('autoInstallerInstalledApplicationsIndex');
    },

    async autoInstallerDeleteWordpressApplication(
      context,
      { directory, domain, options },
    ) {
      const [{ data }, err] = await wordpressRepo.postWordpress({
        command: 'core_remove',
        domain,
        params: {
          directory: directory ?? '',
          removeFiles: !!options.removeFiles,
          removeDatabase: !!options.removeDatabase,
        },
      });

      if (data) {
        context.dispatch('hostingWordpressUpdateSelectedDir');
        context.dispatch('getHostingSections');
        context.commit('removeAutoInstallerWordpressApplication', directory);
      }
      if (err) {
        errorLogger.logError(
          new Error('WordPress AutoInstaller failed to delete application', {
            cause: err,
          }),
        );
      }

      context.dispatch('autoInstallerInstalledApplicationsIndex');
    },
  },
};

const checkIncompleteItems = (items, context) => {
  if (items.find((item) => item.incomplete === '1' && !checkFailed(item))) {
    setTimeout(
      () => context.dispatch('autoInstallerInstalledApplicationsIndex'),
      5000,
    );
  }
};

const checkFailed = (item) =>
  !item.time || dayjs().diff(dayjs.unix(item.time), 'minutes') >= 6;
