import { defineStore } from 'pinia';
import { ref } from 'vue';

import { STORE_PERSISTENT_KEYS } from '@/types';
import type { IEmailStatusState } from '@/types';

export const useEmailVerificationStore = defineStore(
  'emailVerificationStore',
  () => {
    const TIME_15_MIN = 15 * 60 * 1000;
    const emailVerificationStatusData = ref<IEmailStatusState[]>([]);
    const emailVerificationSendList = ref<
      { domain: string; timestamp: number }[]
    >([]);

    const addToEmailVerificationSendList = (domain: string) => {
      emailVerificationSendList.value = emailVerificationSendList.value.filter(
        (item) => item.domain !== domain,
      );

      emailVerificationSendList.value.push({
        domain,
        timestamp: new Date().getTime(),
      });
    };

    const isSendingEmailVerificationAllowed = (domain: string) => {
      const currentTimeStamp = new Date().getTime();

      const domainEmailVerificationSendData =
        emailVerificationSendList.value.find((item) => item.domain === domain);

      if (!domainEmailVerificationSendData) {
        return true;
      }

      return (
        currentTimeStamp >
        domainEmailVerificationSendData.timestamp + TIME_15_MIN
      );
    };

    const filterOutAllowedToSendEmailVerifications = () =>
      (emailVerificationSendList.value = emailVerificationSendList.value.filter(
        ({ timestamp }) => {
          const currentTimeStamp = new Date().getTime();

          return currentTimeStamp < timestamp + TIME_15_MIN;
        },
      ));

    const setHDomainsEmailVerificationData = (
      data: Partial<IEmailStatusState>,
    ) => {
      const index = emailVerificationStatusData.value.findIndex(
        (item) => item.domain === data.domain,
      );

      if (index > -1) {
        const currentDomainStatusState =
          emailVerificationStatusData.value[index];
        emailVerificationStatusData.value[index] = {
          ...currentDomainStatusState,
          ...data,
        };
      } else {
        const initialEmailStatusState: IEmailStatusState = {
          domain: '',
          timeStatusChecked: null,
          timeCheckStatusClicked: null,
          timeBannerActivated: null,
          bannerTimePeriod: null,
          isBannerActivated: false,
          ...data,
        };

        emailVerificationStatusData.value.push(initialEmailStatusState);
      }
    };

    const setTimeEmailStatusChecked = ({
      domain,
      timeStatusChecked,
    }: {
      domain: string;
      timeStatusChecked: number;
    }) => {
      setHDomainsEmailVerificationData({
        domain,
        timeStatusChecked,
      });
    };

    const resetTimeCheckStatusClicked = (domain: string) => {
      setHDomainsEmailVerificationData({
        domain,
        timeCheckStatusClicked: new Date().getTime(),
      });
    };

    const setEmailBannerIsActivated = ({
      domain,
      isBannerActivated,
    }: {
      domain: string;
      isBannerActivated: boolean;
    }) => {
      setHDomainsEmailVerificationData({
        domain,
        isBannerActivated,
      });
    };

    const removeDomainsEmailVerificationData = (domain: string) =>
      (emailVerificationStatusData.value =
        emailVerificationStatusData.value.filter(
          (item) => item.domain !== domain,
        ));

    const reloadEmailStatusBannerTimer = (domain: string) => {
      setHDomainsEmailVerificationData({
        domain,
        timeBannerActivated: new Date().getTime(),
        bannerTimePeriod: TIME_15_MIN,
      });
    };

    const getDomainEmailVerificationStatusData = (
      domain: string,
    ): IEmailStatusState =>
      emailVerificationStatusData.value.find(
        (item) => item.domain === domain,
      ) || {
        domain: '',
        timeStatusChecked: null,
        timeCheckStatusClicked: null,
        timeBannerActivated: null,
        bannerTimePeriod: null,
        isBannerActivated: false,
      };

    const getIsEmailVerificationRecentlyChecked = (domain: string) => {
      const currentTimeStamp = new Date().getTime();

      const ONE_MINUTE = 60 * 1000;

      const domainEmailVerificationStatusData =
        getDomainEmailVerificationStatusData(domain);
      const timeCheckStatusLastClicked =
        domainEmailVerificationStatusData.timeCheckStatusClicked;

      if (!timeCheckStatusLastClicked) {
        return false;
      }

      return currentTimeStamp <= timeCheckStatusLastClicked + ONE_MINUTE;
    };

    return {
      emailVerificationStatusData,
      setHDomainsEmailVerificationData,
      getDomainEmailVerificationStatusData,
      removeDomainsEmailVerificationData,
      reloadEmailStatusBannerTimer,
      setTimeEmailStatusChecked,
      resetTimeCheckStatusClicked,
      setEmailBannerIsActivated,
      getIsEmailVerificationRecentlyChecked,
      addToEmailVerificationSendList,
      isSendingEmailVerificationAllowed,
      filterOutAllowedToSendEmailVerifications,
      emailVerificationSendList,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.EMAIL_VERIFICATION },
  },
);
