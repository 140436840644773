import type { Ref } from 'vue';
import { computed } from 'vue';

import { HDomains } from '@/types';

const actionsNotAvailableForManagedDomains = [
  HDomains.BulkActionSlug.DOMAIN_AUTO_RENEW_ENABLE,
  HDomains.BulkActionSlug.DOMAIN_AUTO_RENEW_DISABLE,
  HDomains.BulkActionSlug.DOMAIN_LOCK,
  HDomains.BulkActionSlug.DOMAIN_UNLOCK,
];

export const useManagedDomainBulkActionFlow = (
  actionItemsList: Ref<
    { domain: string; subscriptionId: string; isManagedDomain?: boolean }[]
  >,
  selectedActionSlug: Ref<HDomains.BulkActionSlug | ''>,
) => {
  const domainsList = computed(() =>
    actionItemsList.value.map(({ domain }) => domain),
  );

  const managedClientsDomains = computed(() =>
    actionItemsList.value
      .filter(({ isManagedDomain }) => isManagedDomain)
      .map(({ domain }) => domain),
  );

  const isBulkActionAllowedForManagedDomain = computed(
    () =>
      !!selectedActionSlug.value &&
      !actionsNotAvailableForManagedDomains.includes(selectedActionSlug.value),
  );

  const isOnlyManagedDomainsSelected = computed(
    () =>
      !!(
        actionItemsList.value.length &&
        actionItemsList.value.every(({ isManagedDomain }) => isManagedDomain)
      ),
  );

  const domainListAllowedForBulkAction = computed(() =>
    domainsList.value.filter((domain) => {
      if (isBulkActionAllowedForManagedDomain.value) return true;

      return !managedClientsDomains.value.includes(domain);
    }),
  );

  const domainListNotAllowedForBulkAction = computed(() =>
    domainsList.value.filter(
      (domain) => !domainListAllowedForBulkAction.value.includes(domain),
    ),
  );

  const hasManagedDomainsSelected = computed(() =>
    actionItemsList.value.some(({ isManagedDomain }) => isManagedDomain),
  );

  const shouldUseWebproEndpoint = computed(
    () =>
      hasManagedDomainsSelected.value &&
      isBulkActionAllowedForManagedDomain.value,
  );

  return {
    managedClientsDomains,
    isBulkActionAllowedForManagedDomain,
    isOnlyManagedDomainsSelected,
    domainListAllowedForBulkAction,
    domainListNotAllowedForBulkAction,
    hasManagedDomainsSelected,
    shouldUseWebproEndpoint,
  };
};
