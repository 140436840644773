import cookies from 'js-cookie';
import type { RouteRecordRaw } from 'vue-router';

import { Cookie, Route } from '@/types';

export default [
  {
    path: '/addons',
    meta: {
      title: 'WordPress add-ons',
      breadcrumbs: null,
      hideBackButton: true,
    },
    component: () => import('@/views/Addons/AddonsWrapper.vue'),
    beforeEnter: (to, from, next) => {
      cookies.get(Cookie.ADDONS_ENABLED) === '1'
        ? next()
        : next({ name: Route.Base.HOME });
    },
    children: [
      {
        name: Route.Addons.BASE_PATH,
        path: '',
        component: () => import('@/views/Addons/Addons.vue'),
      },
      {
        path: 'dashboard',
        name: Route.Addons.DASHBOARD,
        component: () => import('@/views/Addons/AddonsDashboard.vue'),
        children: [
          {
            path: 'presets',
            name: Route.Addons.PRESETS,
            meta: {
              createRoute: Route.Addons.PRESET_CREATE,
              editRoute: Route.Addons.PRESET_EDIT,
            },
            component: () =>
              import(
                '@/views/DeveloperTools/WordPressPresets/WordPressPresets.vue'
              ),
          },
          {
            path: 'ownership-transfer',
            name: Route.Addons.OWNERSHIP_TRANSFER,
            component: () =>
              import(
                '@/views/DeveloperTools/OwnershipTransfer/OwnershipTransfer.vue'
              ),
          },
          {
            path: 'reports',
            name: Route.Addons.REPORTS,
            component: () =>
              import('@/views/DeveloperTools/Reports/ReportsDashboard.vue'),
          },
          {
            path: 'monitoring',
            name: Route.Addons.MONITORING,
            component: () =>
              import('@/views/DeveloperTools/Monitoring/Monitoring.vue'),
          },
        ],
      },
      {
        path: 'presets/create',
        meta: {
          title: 'Create preset',
          hideTitle: false,
          showSubheader: true,
          breadcrumbs: false,
          hideBackButton: false,
          backArrowPath: {
            name: Route.Addons.PRESETS,
          },
          previewRoute: Route.Addons.PRESET_PREVIEW,
        },
        name: Route.Addons.PRESET_CREATE,
        component: () =>
          import(
            '@/views/DeveloperTools/WordPressPresets/WordPressPresetSetup.vue'
          ),
      },
      {
        path: 'presets/edit/:presetId',
        name: Route.Addons.PRESET_EDIT,
        meta: {
          title: 'Edit preset',
          hideTitle: false,
          showSubheader: true,
          breadcrumbs: false,
          hideBackButton: false,
          backArrowPath: {
            name: Route.Addons.PRESETS,
          },
          previewRoute: Route.Addons.PRESET_PREVIEW,
        },
        component: () =>
          import(
            '@/views/DeveloperTools/WordPressPresets/WordPressPresetSetup.vue'
          ),
      },
      {
        path: 'create-report',
        name: Route.Addons.CREATE_REPORT,
        meta: {
          hideBackButton: false,
          title: 'Create report',
          breadcrumbs: false,
          backArrowPath: {
            name: Route.Addons.REPORTS,
          },
        },

        component: () =>
          import('@/views/DeveloperTools/Reports/CreateReport.vue'),
      },
    ],
  },
  {
    path: '/addons/presets/template-preview/:slug',
    name: Route.Addons.PRESET_PREVIEW,
    meta: {
      title: 'Preview Template',
      header: false,
      showSubheader: false,
      fullHeight: true,
      fullscreen: true,
      createRoute: Route.Addons.PRESET_CREATE,
      editRoute: Route.Addons.PRESET_EDIT,
    },
    component: () =>
      import(
        '@/views/DeveloperTools/WordPressPresets/WordPressThemePreview.vue'
      ),
  },
] as RouteRecordRaw[];
