import dayjs from 'dayjs';
import { computed } from 'vue';

import RequiredPasswordChangeModalV2 from '@/components/Modals/HModal/RequiredPasswordChangeModalV2.vue';
import UpdatePersonalInformationV2 from '@/components/Modals/HModal/UpdatePersonalInformationV2.vue';
import { useModal, useGlobals } from '@/composables';
import { profileInformationV2ModalDataById } from '@/data/profile/profileInformationV2';
import { useProfileStore } from '@/stores';
import type {
  ProfileInformationModalKeyValue,
  ProfileInformationV2Values,
} from '@/types';
import {
  PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY,
  PROFILE_INFORMATION_MODAL_SCHEMA_KEY,
  SessionStorage,
} from '@/types';
import { toTitleCase, DEFAULT_DATE_FORMAT } from '@/utils/helpers';

export const useProfile = () => {
  const { openModal } = useModal();
  const { toastr, t } = useGlobals();
  const profileStore = useProfileStore();

  const openUpdateEmailModal = () => {
    openUpdateProfileInformationModal(
      PROFILE_INFORMATION_MODAL_SCHEMA_KEY.EMAIL,
      {
        [PROFILE_INFORMATION_FORM_V2_SCHEMA_KEY.EMAIL]:
          profileStore.contact?.email || '',
      },
    );
  };

  const openUpdateAddressModal = async () => {
    openUpdateProfileInformationModal(
      PROFILE_INFORMATION_MODAL_SCHEMA_KEY.ADDRESS,
    );
  };

  const openUnableToUnlinkSocialToaster = () => {
    toastr.e(t('We are unable to unlink your social login'), {
      html: t(
        'Your account is linked to {socialLogin} social login. To unlink your social login, please create a password.',
        { socialLogin: toTitleCase(profileStore.linkedSocialLogin.value) },
      ),
      buttons: [
        {
          text: t('Add password'),
          props: {
            primary: true,
            text: true,
          },
          callback: () => {
            openModal({
              component: { RequiredPasswordChangeModalV2 },
              data: { isCloseable: true },
            });
          },
        },
      ],
      timeout: Math.max(),
    });
  };

  const openUpdateProfileInformationModal = (
    modalId: ProfileInformationModalKeyValue,
    values?: Partial<ProfileInformationV2Values>,
    onSuccess?: () => void,
  ) => {
    const modalData = profileInformationV2ModalDataById(
      modalId,
      values || (profileStore.contact as unknown as ProfileInformationV2Values),
    );

    openModal({
      component: { UpdatePersonalInformationV2 },
      steps: [
        {
          title: modalData.title,
          subtitle: modalData?.subtitle,
        },
      ],
      data: {
        id: modalId,
        formSchema: modalData.formSchema,
        modalData,
        onSuccess,
      },
    });
  };

  const openUpdateNameAndAddressModal = async () => {
    openUpdateProfileInformationModal(
      PROFILE_INFORMATION_MODAL_SCHEMA_KEY.NAME_ADDRESS,
      {
        firstName: profileStore.contact?.firstName || '',
        lastName: profileStore.contact?.lastName || '',
        countryCode: profileStore.contact?.countryCode || '',
        state: profileStore.contact?.state || '',
        address: profileStore.contact?.address || '',
        city: profileStore.contact?.city || '',
        zip: profileStore.contact?.zip || '',
      },
    );

    sessionStorage.setItem(SessionStorage.NAME_ADDRESS_MODAL_SHOWN, 'true');
  };

  const accountDaysRegistered = computed(() => {
    const datePattern = DEFAULT_DATE_FORMAT;

    const currentDate = dayjs(dayjs().format(datePattern), datePattern);
    const createdAt = dayjs(profileStore.account?.createdAt, datePattern);

    return currentDate.diff(createdAt, 'd');
  });

  return {
    openUpdateEmailModal,
    openUpdateProfileInformationModal,
    openUnableToUnlinkSocialToaster,
    openUpdateAddressModal,
    accountDaysRegistered,
    openUpdateNameAndAddressModal,
  };
};
