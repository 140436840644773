import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { handleCommonErrors } from './httpErrorHandlers';

import { errorLogger } from '../errorLogging';

import { Header } from '@/types';
import { getJwtToken } from '@/utils/helpers/authHelpers';
import { appForcedLogout } from '@/utils/services/authService';

const axiosInstance = axios.create({
  timeout: 150000,
  headers: {
    common: {
      Accept: 'application/json;charset=utf-8',
      'Content-Type': 'application/json',
      'Strict-Transport-Security': 'max-age=7776000; includeSubDomains',
      'X-XSS-Protection': '1; mode=block',
      'X-Content-Type-Options': 'nosniff',
    },
  },
});

// REQUEST INTERCEPTOR - auth header
axiosInstance.interceptors.request.use(async (req) => {
  const tokenFromRequest = req.headers[Header.AUTHORIZATION];

  if (tokenFromRequest) {
    return req;
  }

  const cachedToken = getJwtToken();

  if (!cachedToken) {
    await appForcedLogout();

    return req;
  }

  req.headers[Header.AUTHORIZATION] = cachedToken;

  return req;
});

// REQUEST INTERCEPTOR - uuid header
axiosInstance.interceptors.request.use((req) => {
  const uuid = uuidv4();

  req.headers = req.headers ?? {};

  req.headers[Header.CORRELATION_ID] = uuid;

  return req;
});

// RESPONSE INTERCEPTOR
axiosInstance.interceptors.response.use(
  (res) => res.data,
  (error) => {
    try {
      errorLogger.addAxiosErrorBreadcrumb(error);

      handleCommonErrors(error);

      const responseConfig = error.response?.config;
      const requestUrl = responseConfig?.url || error?.config?.url;

      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        error: error?.response?.data?.error,
        response: error?.response,
        url: requestUrl,
      });
    } catch (error) {
      errorLogger.logError(
        new Error('Error in auth error response interceptor', { cause: error }),
      );
    }
  },
);

export const authHttp = axiosInstance;
