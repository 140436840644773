import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { hMailIntegrationRepo } from '@/repositories';
import type {
  IEmailDNSRecordConfig,
  IEmailDNSRecordOverview,
  IEmailDNSStatusOverview,
  Email,
} from '@/types';
import { EMAIL_DOMAIN_RECORD_STATUS } from '@/types';

export const DNS_RECORD_DEFAULT_CONFIG: IEmailDNSRecordConfig = {
  actual: [],
  suggested: [],
  status: 'Incorrect',
};
export const DNS_STATUS_DEFAULT_CONFIG: IEmailDNSStatusOverview = {
  domain: '',
  isPointing: false,
  mx: DNS_RECORD_DEFAULT_CONFIG,
  provider: '',
};

export const useEmailStore = defineStore('emailsStore', () => {
  const domain = ref<IEmailDNSRecordOverview>({
    domain: '',
    provider: '',
    mx: DNS_RECORD_DEFAULT_CONFIG,
    spf: DNS_RECORD_DEFAULT_CONFIG,
    dkim: DNS_RECORD_DEFAULT_CONFIG,
    dmarc: DNS_RECORD_DEFAULT_CONFIG,
    autoconfig: DNS_RECORD_DEFAULT_CONFIG,
  });

  const dnsStatus = ref<IEmailDNSStatusOverview>({
    domain: '',
    isPointing: false,
    mx: DNS_RECORD_DEFAULT_CONFIG,
    provider: '',
  });

  const loading = ref({
    domain: false,
  });

  const loaded = ref({
    domain: false,
  });

  const isAllStatusesActive = computed(() =>
    [
      domain.value.mx,
      domain.value.spf,
      domain.value.dkim,
      domain.value.dmarc,
    ].every(({ status }) => status === EMAIL_DOMAIN_RECORD_STATUS.CORRECT),
  );

  const fetchDomainData = async (domainToCheck: string) => {
    loading.value.domain = true;

    const [
      [{ data: statusData }, statusError],
      [{ data: recordsData }, recordError],
    ] = await Promise.all([
      hMailIntegrationRepo.getDnsStatus(domainToCheck),
      hMailIntegrationRepo.getDnsRecords(domainToCheck),
    ]);

    loading.value.domain = false;

    if (statusError || recordError) {
      return [{ recordsData, statusData }, statusError || recordError];
    }
    dnsStatus.value = statusData;
    domain.value = recordsData;
    loaded.value.domain = true;
  };

  const getDomainRecordStatusByType = (type: Email.RecordType) =>
    domain.value[type].status;

  return {
    loading,
    loaded,
    domain,
    dnsStatus,
    isAllStatusesActive,
    fetchDomainData,
    getDomainRecordStatusByType,
  };
});
