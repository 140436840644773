<template>
  <div>
    <CvcIFrame
      v-if="isCvcRefreshVisible"
      :data="cvcIframeData"
      @success="onCvcRefreshSuccess"
      @cancel="isCvcRefreshed = true"
    />
    <template v-else-if="!payment.loading">
      <PurchasePeriods
        :prices="prices"
        is-static-option
        @change="handlePurchasePeriodChange(prices[0])"
      />
      <PurchaseDetails
        :pricing-details="pricingDetails"
        :edit-loading="paymentEditLoading"
        :loading="estimation.loading"
        :error="estimation.error"
        :show-renew="data.showRenew"
        has-coupon
        @edit-payment="handleEditPayment"
      >
        <template #coupon>
          <PurchaseCoupon
            :loading="estimation.couponLoading"
            :error="estimation.couponError"
            :active-coupon="activeCoupon"
            @on-apply="onCouponApply"
            @on-remove="onCouponRemove"
          />
        </template>
      </PurchaseDetails>
      <p v-if="isRenewalPriceShown" class="h-mb-8">
        {{ $t('Renews at {price}/yr', { price: data.renewalPrice }) }}
      </p>
      <PurchaseButtons
        :loading="
          estimation.loading || paymentEditLoading || cvcIframeData.isLoading
        "
        :error="estimation.error"
        :credit-pay="isCreditPay"
        :has-coupon="!!activeCoupon"
        @on-success="goToPayment(billingRequest, 'purchase')"
      />
    </template>
    <PurchaseProcessing
      v-else
      :completed="payment.completed"
      :error="payment.error"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PurchaseCoupon from '@/components/Modals/Parts/Coupon/PurchaseCoupon.vue';
import CvcIFrame from '@/components/Modals/Parts/CvcIFrame.vue';
import PurchaseButtons from '@/components/Modals/Parts/PurchaseButtons.vue';
import PurchaseDetails from '@/components/Modals/Parts/PurchaseDetails.vue';
import PurchasePeriods from '@/components/Modals/Parts/PurchasePeriods.vue';
import PurchaseProcessing from '@/components/Modals/Parts/PurchaseProcessing.vue';
import { usePurchaseModal, useCVCRefresh } from '@/composables';
import { hBillingRepo } from '@/repositories';
import { useCatalogStore, useProfileStore } from '@/stores';
import creditsMixin from '@/utils/mixins/hbilling/creditsMixin';
import modalsMixin from '@/utils/mixins/modalsMixin';
import newPurchaseMixin from '@/utils/mixins/newPurchaseMixin';

const PURCHASE_ACTION = 'purchase';

export default {
  mixins: [modalsMixin, newPurchaseMixin, creditsMixin],
  components: {
    PurchaseDetails,
    PurchaseProcessing,
    PurchasePeriods,
    PurchaseButtons,
    PurchaseCoupon,
    CvcIFrame,
  },
  data: () => ({
    pricingDetails: {},
    prices: [],
    item: {},
    selectedPlanPeriod: {},
  }),
  setup() {
    const {
      cvcIframeData,
      isCvcRefreshVisible,
      isCVCRefreshNeeded,
      getCvcIframe,
      isCvcRefreshed,
      addIpToWhitelist,
    } = useCVCRefresh();

    const {
      estimation,
      billingAddress,
      activeCoupon,
      setEstimationLoadingState,
      setEstimationErrorState,
      getCouponRequest,
    } = usePurchaseModal();

    const catalogStore = useCatalogStore();
    const profileStore = useProfileStore();

    return {
      estimation,
      billingAddress,
      activeCoupon,
      catalogStore,
      profileStore,
      setEstimationLoadingState,
      setEstimationErrorState,
      getCouponRequest,
      isCvcRefreshVisible,
      isCVCRefreshNeeded,
      getCvcIframe,
      cvcIframeData,
      isCvcRefreshed,
      addIpToWhitelist,
    };
  },
  created() {
    this.item = this.catalogStore.getCatalogItemById(this.data.itemId);

    this.prices = this.data.addon
      ? this.catalogStore.getAddon(this.data.itemId, this.data.addon).prices
      : this.item?.prices;

    this.prices = [{ ...this.prices[0], planName: this.data.planName }];
  },
  methods: {
    async goToPayment(request, type) {
      if (this.isCVCRefreshNeeded) {
        this.isCvcRefreshed = false;
        await this.getCvcIframe();

        return;
      }
      await this.paymentRequest(request, type);
    },
    onCvcRefreshSuccess() {
      this.isCvcRefreshed = true;
      this.paymentRequest(this.billingRequest, 'purchase');
    },
    async paymentRequest(payload, type) {
      this.goToNextStep();

      this.data.onOfferChosen?.();

      const [{ data }] = await this.completePayment(payload, type, false, true);

      if (
        this.data.onSuccess &&
        !this.payment.error &&
        (this.paymentMethod || this.isCreditPay) &&
        this.payment.completed
      ) {
        this.data.onSuccess(data);
      }
    },
    handleEditPayment() {
      this.data.onOfferChosen?.();
      this.editPayment(
        { ...this.billingRequest, methodId: undefined },
        PURCHASE_ACTION,
      );
    },
    handlePurchasePeriodChange(period) {
      this.selectedPlanPeriod = period;

      this.getPricingEstimate();
    },
    async onCouponApply(coupon) {
      const success = await this.getPricingEstimate(coupon);
      if (!success) return;

      this.activeCoupon = coupon;
    },
    async onCouponRemove() {
      this.activeCoupon = '';
      this.getPricingEstimate();
    },
    async getPricingEstimate(coupon) {
      const activeCoupon = coupon || this.activeCoupon;
      const period = this.selectedPlanPeriod;

      this.setEstimationLoadingState(true, coupon);

      const request = {
        ...this.getCouponRequest(activeCoupon),
        items: [
          this.data.addon === 'domain_transfer'
            ? this.getDomainTransferRequestBodyItem()
            : {
                product: period.id,
                quantity: '1',
              },
        ],
      };

      const [{ data }, err] = await hBillingRepo.estimatePricing(
        request,
        null,
        {
          hideToastr: coupon,
        },
      );

      if (err) {
        this.setEstimationLoadingState(false, coupon);
        this.setEstimationErrorState(true, coupon, err);

        return;
      }

      this.pricingDetails = {
        ...data,
        id: period.id,
        period: {
          price: period.price,
          periodUnit: period.periodUnit,
          period: period.period,
        },
      };

      this.setEstimationLoadingState(false, coupon);

      return true;
    },
    getShortestPeriodPrice(prices) {
      return prices?.reduce((prev, curr) =>
        prev.period < curr.period ? prev : curr,
      );
    },
    getDomainTransferRequestBodyItem() {
      const shortestPeriodPrice = this.getShortestPeriodPrice(
        this.item?.prices,
      );
      const transferAddonData = this.item?.addons?.find((addon) =>
        addon.itemId.includes('domaintransfer'),
      );

      return {
        product: shortestPeriodPrice?.id,
        itemId: shortestPeriodPrice?.id,
        quantity: '1',
        addons: [
          {
            product: transferAddonData?.prices?.[0]?.id,
            quantity: '1',
          },
        ],
        metadata: {
          domain: this.data.domain,
        },
      };
    },
  },
  computed: {
    isRenewalPriceShown() {
      return (
        this.data.renewalPrice &&
        !this.estimation.loading &&
        !this.estimation.error
      );
    },
    billingRequest() {
      const req = {
        customerId: this.profileStore.account?.id,
        currency: this.pricingDetails.currencyCode,
        ...this.getCouponRequest(this.activeCoupon),
        billingAddress: this.billingAddress,
        items: [
          this.data.addon === 'domain_transfer'
            ? this.getDomainTransferRequestBodyItem()
            : {
                itemId: this.pricingDetails.id,
                quantity: '1',
              },
        ],
        redirectReturn: this.data.redirect,
        redirectCancel: this.data.redirect,
        transactionDetails: {
          metadata: {
            cvcRefreshRequired: this.isCvcRefreshed,
          },
        },
      };

      if (this.paymentMethod) req.methodId = this.paymentMethod.id;

      return req;
    },
    ...mapGetters(['getCurrentOrder']),
  },
};
</script>
