import type { NavigationGuard } from 'vue-router';

import { hDomainsRepo } from '@/repositories';
import { Route, HDomains } from '@/types';
import { toASCII } from '@/utils/helpers';

const domainRegisterGuard: NavigationGuard = async (to, from, next) => {
  const domain = to.params.domain;
  const [{ data }, err] = await hDomainsRepo.getIncomingDomainMoveStatus(
    toASCII(domain as string),
  );
  if (
    err ||
    data?.domain !== domain ||
    data?.status !== HDomains.DomainMoveStatus.INITIATED
  ) {
    next({ name: Route.Base.HOME });

    return;
  }

  next();
};

export default domainRegisterGuard;
