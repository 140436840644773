import { Header } from '@/types';
import type { IAiTroubleshootAnalyzeResponse } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const URL_ANALYSE_V4 = `${process.env.VITE_API_REST}/v4/website/analyse-problems`;
const URL_APPLY_FIX_V4 = `${process.env.VITE_API_REST}/v4/website/apply-problem-fix`;
const URL_APPLY_AUTO_FIX_V3 = `${process.env.VITE_API_REST}/v3/website/apply-auto-fix`;

export default {
  async fetchTroubleshootAnalyze(
    domainToManage: string,
    directory: string = '',
  ) {
    return await hAsync<IAiTroubleshootAnalyzeResponse>(
      http.get(URL_ANALYSE_V4, {
        params: {
          directory,
        },
        transformRequest: [
          (data, headers) => {
            headers[Header.DOMAIN] = domainToManage;

            return data;
          },
        ],
      }),
    );
  },

  async postTroubleshootAction(
    checkUuid: string,
    directory: string = '',
    domainToManage?: string,
  ) {
    const payload = {
      checkUuid,
      directory,
    };

    const headers = {
      'Content-Type': 'application/json',
    };

    return await hAsync<boolean>(
      http.post(URL_APPLY_FIX_V4, payload, {
        headers,
        transformRequest: [
          (data, headers) => {
            headers[Header.DOMAIN] = domainToManage;
            const transformedData = JSON.stringify(data);

            return transformedData;
          },
        ],
      }),
    );
  },

  async postTroubleshootAutoFix(
    httpStatusCode: string = '',
    directory: string = '',
    domainToManage?: string,
  ) {
    const payload = {
      directory,
      httpStatusCode,
    };

    const headers = {
      'Content-Type': 'application/json',
    };

    return await hAsync<boolean>(
      http.post(URL_APPLY_AUTO_FIX_V3, payload, {
        headers,
        transformRequest: [
          (data, headers) => {
            headers[Header.DOMAIN] = domainToManage;
            const transformedData = JSON.stringify(data);

            return transformedData;
          },
        ],
      }),
    );
  },
};
