import type { NavigationGuard } from 'vue-router';

import store from '@/store/index';
import { Route, Email } from '@/types';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

const googleWorkspaceGuard: NavigationGuard = async (to, from, next) => {
  if (to.params.orderId) return next();

  const loaded = store.state.emails.loaded;

  if (!loaded) await store.dispatch('emails/fetchEmails');

  const currentEmail = store.getters['emails/getPremiumMail'](
    Email.EmailProvider.GOOGLE,
    to.params.domain,
  );

  if (currentEmail) {
    return next();
  }

  toastr.e(i18n.t('Order not found'));

  return next({ name: Route.Email.EMAILS });
};

export default googleWorkspaceGuard;
