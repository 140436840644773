import type { NavigationGuard } from 'vue-router';

import store from '@/store/index';
import { Email, Route } from '@/types';
import { getOriginRoute, isTopPlan } from '@/utils/helpers/emailsHelper';

export const emailIsUpgradingGuard: (
  onAfter?: NavigationGuard,
) => NavigationGuard = (onAfter) => async (to, from, next) => {
  const loaded = store.state.emails.loaded;

  if (!loaded) await store.dispatch('emails/fetchEmails');

  const domain = to.params.domain as string;

  const emailDetails = store.getters['emails/getPremiumMail'](
    Email.EmailProvider.HOSTINGER,
    domain,
  );

  if (!emailDetails) {
    return next({ name: Route.Email.EMAILS });
  }

  const hmailOrder = store.getters['emails/getEmailOrderByResellerOrderId'](
    emailDetails.orderId,
  );

  if (hmailOrder.pendingUpgrade || isTopPlan(hmailOrder.plan)) {
    next(getOriginRoute(to, domain));

    return;
  }

  if (onAfter) {
    return onAfter(to, from, next);
  }

  return next();
};
