import type { NavigationGuard } from 'vue-router';

import store from '@/store/index';
import type { EmailItem } from '@/types';
import { RedirectMutations, Route, Email } from '@/types';

const emailsUpsellGuard: NavigationGuard = async (to, from, next) => {
  const emailOrder = getCurrentEmailOrderByDomain(to.params.domain as string);

  if (!emailOrder) return next();

  const isUpgrading = store.getters['emails/getEmailOrderByResellerOrderId'](
    emailOrder.orderId,
  )?.pendingUpgrade;

  if (emailOrder.plan.title?.toLowerCase()?.includes('free') && !isUpgrading) {
    await store.dispatch('fetchEmailDomainAndPlanDetails', {
      domain: emailOrder.domain,
      orderId: emailOrder.orderId,
    });
    const showUpsell =
      store.getters.getEmailsDetails?.data?.domain?.showPremiumPlanNudge;
    if (showUpsell) {
      store.commit(RedirectMutations.SET_REDIRECT, {
        id: emailOrder.orderId,
        source: from.name,
      });
      next({
        name: Route.Email.UPSELL,
        params: {
          domain: emailOrder.domain,
        },
        query: to.query,
      });
    }
  }

  next();
};

const getCurrentEmailOrderByDomain = (domainParam: string) => {
  const emails = store.state.emails.emails as EmailItem[];

  const hostingerEmail = emails
    .filter(({ provider }) => provider === Email.EmailProvider.HOSTINGER)
    .find(({ domain }: { domain: string }) => domain === domainParam);

  if (hostingerEmail) return hostingerEmail;

  return emails?.find(
    ({ domain }: { domain: string }) => domain === domainParam,
  );
};

export default emailsUpsellGuard;
