import type { ModalObject } from '@/types';

export default [
  {
    name: 'FtpChangePasswordModal',
    type: 'HModalFancy',
    subtype: 'Hosting',
    props: {
      title: 'Change FTP account password',
      imgSrc: 'modal/modal-password.svg',
    },
  },
  {
    name: 'FtpDeleteAccountModal',
    type: 'HModalFancy',
    subtype: 'Hosting',
    props: {
      title: 'Delete FTP Account?',
      imgSrc: 'modal/modal-trash.svg',
    },
  },
  {
    name: 'BFHostingUpgradeModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        title: 'Discover the Best Deals',
        subtitle: 'Choose your hosting plan to find offers',
        hideX: true,
      },
      {},
    ],
  },
  {
    name: 'UpgradeForFeatureModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'WordpressAutoUpdatesModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'WordpressUpdateRestoreModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'WordpressComponentsCompatibilityListModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: false,
      },
    ],
  },
  {
    name: 'WordpressComponentsCompatibilityModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: false,
      },
    ],
  },
  {
    name: 'WordpressUpdateDetailsModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'DiscoveredPathModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'ChangeHostingPlanModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'NoCacheModeModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'WordpressCompatibilityChangePhpVersionModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'ConfirmUpdateModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'PluginCompatibilityModal',
    type: 'HModal',
    subtype: 'Hosting',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'PluginUpdateModal',
    type: 'HModal',
    subtype: 'Hosting',
  },
  {
    name: 'BulkUpdatesList',
    type: 'HModal',
    subtype: 'Hosting',
  },

  {
    name: 'WordPressTemplateDetailsModal',
    type: 'HModal',
    subtype: 'Hosting',
  },
] as ModalObject[];
