import type { IHostingSurveyAnswer, ISurveyAnswer } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const URL = `${process.env.VITE_API_SURVEYS}/api/jwt/v1/survey`;

const ONBOARDING_SURVEY_IDENTIFIER = 'hosting_onboarding';

export default {
  async getSurveyResults() {
    return await hAsync<{ answers: IHostingSurveyAnswer[] }>(
      http.get(`${URL}/response/${ONBOARDING_SURVEY_IDENTIFIER}`),
    );
  },

  async postSurveyResponse({ answers }: { answers: ISurveyAnswer[] }) {
    return await hAsync<{ answers: IHostingSurveyAnswer[] }>(
      http.post(`${URL}/response`, {
        surveyIdentifier: ONBOARDING_SURVEY_IDENTIFIER,
        answers,
      }),
    );
  },

  async showSurveyAnswers() {
    return await hAsync<{ answers: IHostingSurveyAnswer[] }>(
      http.get(`${URL}/show`, {
        params: {
          identifier: ONBOARDING_SURVEY_IDENTIFIER,
        },
      }),
    );
  },

  async getIsEligibleForSurvey(orderId: string) {
    return await hAsync<{ isEligible: boolean }>(
      http.get(`${URL}/eligibility`, {
        params: { surveyIdentifier: ONBOARDING_SURVEY_IDENTIFIER, orderId },
      }),
    );
  },
};
