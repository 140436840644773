import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { Website } from '@/types';

type ActiveTag = {
  name: string;
  isOwnershipTag: boolean;
};

export const useWebsitesFilterStore = defineStore('websitesFilterStore', () => {
  const websiteSearch = ref('');
  const ownershipFilter = ref(Website.Filter.ALL);
  const isFavouriteActive = ref(false);
  const activeTags = ref<ActiveTag[]>([]);

  const setSearch = (value: string) => {
    websiteSearch.value = value;
  };

  const setOwnershipFilter = (value: Website.Filter) => {
    ownershipFilter.value = value;
  };

  const toggleFavouriteActive = () => {
    isFavouriteActive.value = !isFavouriteActive.value;
  };

  const activeNonOwnershipTags = computed(() =>
    activeTags.value.filter(({ isOwnershipTag }) => !isOwnershipTag),
  );

  const getOwnershipFilterByTags = (tags: string[]) => {
    const hasManaged = tags.includes(Website.Filter.MANAGED);
    const hasOwned = tags.includes(Website.Filter.OWNED);
    if (hasManaged && hasOwned) {
      return Website.Filter.ALL;
    }
    if (hasManaged) {
      return Website.Filter.MANAGED;
    }
    if (hasOwned) {
      return Website.Filter.OWNED;
    }

    return Website.Filter.ALL;
  };

  const setActiveTags = (tags: ActiveTag[]) => {
    activeTags.value = tags;
    ownershipFilter.value = getOwnershipFilterByTags(
      tags
        .filter(({ isOwnershipTag }) => isOwnershipTag)
        .map(({ name }) => name),
    );
  };

  const isWebsiteSearchActive = computed(() => !!websiteSearch.value);

  const isOnlyFilteredByFavourites = computed(
    () =>
      isFavouriteActive.value &&
      websiteSearch.value === '' &&
      ownershipFilter.value === Website.Filter.ALL,
  );

  const isOwnerFilterSelected = computed(() =>
    [Website.Filter.OWNED, Website.Filter.MANAGED].includes(
      ownershipFilter.value,
    ),
  );

  const hasBothOwnershipFiltersSelected = computed(
    () =>
      activeTags.value.filter(({ isOwnershipTag }) => isOwnershipTag).length ===
      2,
  );

  const activeTagsForFiltering = computed(() =>
    // Do not filter by tag is My websites and Client websites are selected
    hasBothOwnershipFiltersSelected.value
      ? []
      : activeTags.value
          .filter(({ isOwnershipTag }) => !isOwnershipTag)
          .map(({ name }) => name),
  );

  const isAnyFiltersOrSearchApplied = computed(
    () =>
      isWebsiteSearchActive.value ||
      ownershipFilter.value !== Website.Filter.ALL ||
      isFavouriteActive.value ||
      activeTags.value.length > 0,
  );

  const isFavouritesOrOwnershipFiltersApplied = computed(
    () =>
      ownershipFilter.value !== Website.Filter.ALL || isFavouriteActive.value,
  );

  const isSearchOrFavouritesFilterApplied = computed(
    () => isWebsiteSearchActive.value || isFavouriteActive.value,
  );

  const hasActiveTags = computed(() => activeTags.value.length > 0);

  const $reset = () => {
    websiteSearch.value = '';
    ownershipFilter.value = Website.Filter.ALL;
    isFavouriteActive.value = false;
    activeTags.value = [];
  };

  return {
    websiteSearch,
    ownershipFilter,
    isFavouriteActive,
    activeTags,
    activeNonOwnershipTags,
    setSearch,
    setOwnershipFilter,
    toggleFavouriteActive,
    setActiveTags,
    isWebsiteSearchActive,
    isOnlyFilteredByFavourites,
    isOwnerFilterSelected,
    hasActiveTags,
    isAnyFiltersOrSearchApplied,
    isFavouritesOrOwnershipFiltersApplied,
    isSearchOrFavouritesFilterApplied,
    activeTagsForFiltering,
    $reset,
  };
});
