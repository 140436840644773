import type { NavigationGuard } from 'vue-router';

import { useDkimStore } from '@/stores/email/dkimStore';
import { Route } from '@/types';

const emailDkimVerifyGuard: NavigationGuard = async (to, from, next) => {
  const domainResourceId = to.params.domainResourceId as string;
  const dkimStore = useDkimStore();

  const dkimRecords =
    dkimStore.getDkimRecordsByDomainResourceId(domainResourceId);

  if (dkimRecords?.length) return next();

  const response = await dkimStore.fetchDkimRecords(domainResourceId);

  if (!response.data.length) {
    return next({
      name: Route.Email.CUSTOM_DKIM,
      params: {
        domain: to.params.domain,
      },
    });
  }

  return next();
};

export default emailDkimVerifyGuard;
