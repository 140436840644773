import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_H_SSO}/v1/email`,
  getVerifyResend() {
    return hAsync(http.get(`${this.url}/verify/resend`));
  },
  patchChangeInitiate(data: {
    email: string;
    twoFactorAuthCode?: string;
    currentPassword?: string;
  }) {
    return hAsync(
      http.patch(`${this.url}/change/initiate`, data, {
        whitelistedStatusCodes: [400],
      }),
    );
  },
};
