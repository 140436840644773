<template>
  <div v-if="!loading" class="ns-flow">
    <div v-if="currentStep === 0">
      <div class="ns-flow__illustration-wrapper">
        <HImage
          :src="imgSrcOpeningIllustration"
          :alt="$t('Start domain nameserver change')"
          class="ns-flow__illustration"
        />
      </div>
      <h1 class="ns-flow__title">
        <Trans :translate-params="{ domain: usedDomain }">
          Let’s activate
          <span class="ns-flow__domain">{domain}</span>
        </Trans>
      </h1>
      <Trans
        tag="p"
        class="ns-flow__subtitle"
        :translate-params="{ brand: profileStore.clientBrandTitle }"
      >
        To activate your website, change domain nameservers to {brand}
      </Trans>
      <div v-if="providerData" class="ns-flow__description">
        <div class="d-inline-block">
          <Trans
            :translate-params="{
              provider: providerData.registrar,
              domain: usedDomain,
            }"
          >
            Your domain
            <span class="ns-flow__domain">{domain}</span>
            is registered at {provider}
          </Trans>
        </div>
      </div>
      <div v-if="!providerData" class="ns-flow__description-no-provider">
        <div v-trans class="ns-flow__description--center">
          Select your domain provider
        </div>
        <div class="ns-flow__provider-form">
          <HFormFieldDeprecated
            name="provider"
            :schema="provider"
            @change="onProviderChange"
          />
          <HFormFieldDeprecated
            v-if="isSelectedOther"
            name="other"
            :schema="other"
            @change="onOtherProviderChange"
          />
        </div>
      </div>
    </div>
    <div v-else-if="currentStep === 1">
      <h1 class="ns-flow__title">
        <Trans :translate-params="{ domain: usedDomain }">
          Change nameservers of
          <span class="ns-flow__domain">{domain}</span>
        </Trans>
      </h1>
      <Trans
        tag="p"
        class="ns-flow__subtitle"
        :translate-params="{ brand: profileStore.clientBrandTitle }"
      >
        For your website to work, change domain nameservers to {brand}
      </Trans>
      <div v-if="videoUrl" class="ns-flow__instructions">
        <div :key="videoUrl" class="ns-flow__instructions-video">
          <iframe
            ref="instructions-video"
            title="Change Nameservers"
            frameBorder="0"
            allowfullscreen
            :src="videoUrl"
          />
        </div>
        <div class="ns-flow__instructions-steps">
          <div class="ns-flow__instructions-step">
            <div class="ns-flow__instructions-step-header">
              <div class="ns-flow__instructions-step-icon">1</div>
              <span
                class="ns-flow__instructions-step-title"
                :class="{
                  'ns-flow__instructions-step--active': providerInstructions,
                }"
                @click="openInstructions"
              >
                {{ loginToProviderText }}
                <HpIcon
                  v-if="providerInstructions"
                  view-box="0 0 36 36"
                  icon="ic-open-in-new"
                  primary
                />
              </span>
            </div>
            <div class="ns-flow__instructions-step-body">
              <Trans>Find old nameservers</Trans>
              <div
                v-if="providerData"
                class="ns-flow__instructions-step-old-ns"
              >
                <span
                  v-for="nameserver in providerData.nameservers"
                  :key="nameserver"
                >
                  {{ nameserver }}
                </span>
              </div>
              <div v-else class="ns-flow__instructions-step-old-ns">
                <span v-for="index in 2" :key="index">
                  ns{{ index }}.{{
                    selectedProvider === 'other'
                      ? 'yourprovider'
                      : selectedProvider
                  }}.com
                </span>
              </div>
            </div>
          </div>
          <div class="ns-flow__instructions-step">
            <div class="ns-flow__instructions-step-header">
              <div class="ns-flow__instructions-step-icon">2</div>
              <Trans
                class="ns-flow__instructions-step-title"
                :translate-params="{ brand: profileStore.clientBrandTitle }"
              >
                Change to {brand} Nameservers
              </Trans>
            </div>
            <div class="ns-flow__instructions-step-body">
              <div class="ns-flow__instructions-step-new-ns">
                <div @click="copyString('ns1.dns-parking.com')">
                  <span>ns1.dns-parking.com</span>
                  <HImage
                    :alt="$t('copy to clipboard')"
                    src="@/images/copy.svg"
                    class="ns-flow__copy"
                  />
                </div>
                <div @click="copyString('ns2.dns-parking.com')">
                  <span>ns2.dns-parking.com</span>
                  <HImage
                    :alt="$t('copy to clipboard')"
                    src="@/images/copy.svg"
                    class="ns-flow__copy"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="ns-flow__illustration-wrapper">
        <HImage
          :src="imgSrcClosingIllustration"
          :alt="$t('Domain nameserver change initiated')"
          class="ns-flow__illustration"
        />
      </div>
      <h1 class="ns-flow__title">
        <Trans>All set!</Trans>
      </h1>
      <div class="ns-flow__subtitle">
        <Trans tag="p">
          Nameservers require up to 24 hours to work worldwide.
        </Trans>
        <div class="d-inline-block">
          <Trans :translate-params="{ domain: usedDomain, link }" tag="p">
            Use
            <a href="{link}" target="_blank">this link</a>
            to check the nameserver status of
            <span class="ns-flow__domain font-weight-bold">{domain}</span>
          </Trans>
        </div>
      </div>
    </div>
    <div
      class="ns-flow__footer"
      :class="{ 'ns-flow__footer--reverse': currentStep === 1 }"
    >
      <div class="ns-flow__actions">
        <HButtonV1
          v-if="currentStep === 0"
          :id="`hpanel_tracking-DomainNsFlowModal-completed--modal-let_s_start_button`"
          :disabled="!providerData && !selectedProvider"
          primary
          @click="goToNextStep"
        >
          Let's start
        </HButtonV1>
        <HButtonV1
          v-if="currentStep === 1"
          :id="`hpanel_tracking-DomainNsFlowModal-completed--modal-back_button`"
          primary
          text
          @click="goToPreviousStep"
        >
          Back
        </HButtonV1>
        <HButtonV1
          v-if="currentStep === 1"
          :id="`hpanel_tracking-DomainNsFlowModal-completed--modal-continue_button`"
          primary
          @click="goToNextStep"
        >
          Continue
        </HButtonV1>
        <HButtonV1
          v-if="currentStep === 2"
          :id="`hpanel_tracking-DomainNsFlowModal-completed--modal-finish_button`"
          primary
          @click="onFinish"
        >
          Finish
        </HButtonV1>
      </div>
      <div class="ns-flow__stepper">
        <div
          v-for="step in 3"
          :key="step"
          class="ns-flow__stepper-indicator"
          :class="{
            'ns-flow__stepper-indicator--active': step - 1 <= currentStep,
          }"
          @click="goToStep(step - 1)"
        />
      </div>
    </div>
  </div>
  <HDomainNsFlowModalSkeleton v-else />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import HFormFieldDeprecated from '@/components/HFormDeprecated/HFormFieldDeprecated';
import HDomainNsFlowModalSkeleton from '@/components/Loaders/SkeletonCompositions/HDomainNsFlowModalSkeleton';
import { useEntri, useBrands } from '@/composables';
import { domainsRepo } from '@/repositories';
import { useProfileStore } from '@/stores';
import { Entri, HDnsActions } from '@/types';
import { toUnicode, toASCII } from '@/utils/helpers';
import { checkAutomaticConnectionAvailability } from '@/utils/helpers/entriHelper';
import modalsMixin from '@/utils/mixins/modalsMixin';
import { copyString } from '@/utils/services/copyService';
import { i18n } from '@/utils/services/i18nService';

export default {
  components: { HDomainNsFlowModalSkeleton, HFormFieldDeprecated },
  mixins: [modalsMixin],
  data() {
    return {
      providerData: null,
      loading: false,
      selectedProvider: null,
      otherProvider: null,
      videoUrl: null,
      loginLink: null,
      providerTitle: null,
      provider: {
        type: 'select',
        noValue: true,
        placeholder: 'Select provider',
        values: [
          { label: 'GoDaddy', value: 'godaddy' },
          { label: 'Hostgator', value: 'hostgator' },
          { label: 'Namecheap', value: 'namecheap' },
          { label: 'Name.com', value: 'namecom' },
          { label: i18n.t('Other'), value: 'other' },
        ],
      },
      other: {
        type: 'input',
        label: i18n.t('Domain provider (Optional)'),
      },
    };
  },
  created() {
    this.loadProviderData();
  },
  computed: {
    orderId() {
      return this.$route.params.order_id || this.data?.orderId;
    },
    usedDomain() {
      return (
        toUnicode(this.getExistingDomainName) || toUnicode(this.data?.domain)
      );
    },
    link() {
      return `https://www.whatsmydns.net/#NS/${this.getExistingDomainName}`;
    },
    isSelectedOther() {
      return this.selectedProvider === 'other';
    },
    providerInstructions() {
      return this.providerData?.providerUrl || this.loginLink;
    },
    loginToProviderText() {
      return this.providerInstructions || this.loginLink
        ? this.$t('Login to {provider}', {
            provider: this.providerData?.registrar || this.providerTitle,
          })
        : this.$t('Login to your provider');
    },
    imgSrcOpeningIllustration() {
      return this.isFromNiagahoster
        ? '@/images/niagahoster/domains/illustration-opening.svg'
        : '@/images/domains/Illstration-opening.svg';
    },
    imgSrcClosingIllustration() {
      return this.isFromNiagahoster
        ? '@/images/niagahoster/domains/Illustration-closing.svg'
        : '@/images/domains/Illustration-closing.svg';
    },
    ...mapGetters(['getExistingDomainName']),
  },
  setup() {
    const { onEntriStart } = useEntri();
    const { isFromNiagahoster } = useBrands();
    const profileStore = useProfileStore();

    return {
      profileStore,
      onEntriStart,
      isFromNiagahoster,
    };
  },
  methods: {
    copyString,
    async checkEntriConnection() {
      const clientId = this.orderId;
      const domain = this.usedDomain;
      const serviceType = Entri.ServiceType.WEB_HOSTING;

      const { config, isAutomaticSetupAvailable, provider } =
        await checkAutomaticConnectionAvailability({
          serviceType,
          domain,
          clientId,
        });

      if (isAutomaticSetupAvailable) {
        this.close();

        await this.onEntriStart({
          config,
          provider,
          domain,
          clientId,
          serviceType,
        });
      }
    },
    async loadProviderData() {
      this.loading = true;

      if (this.data?.isEntriCheck) {
        await this.checkEntriConnection();
      }

      if (this.usedDomain) {
        const [{ data }] = await domainsRepo.getDomainProviderData(
          toASCII(this.usedDomain),
        );
        this[HDnsActions.FETCH_DNS_PROVIDER_DATA](toASCII(this.usedDomain));

        const providersData = data.shift();
        if (providersData) this.providerData = providersData;
      }
      this.setVideoUrl();
      this.loading = false;
    },
    openInstructions() {
      if (!this.providerInstructions) return;
      window.open(this.providerInstructions, '_blank');
    },
    async onFinish() {
      if (this.otherProvider) {
        domainsRepo.storeOtherDomainProvider(
          toASCII(this.usedDomain),
          this.otherProvider,
        );
      }
      if (this.data?.onSuccess) this.data.onSuccess();
      this.close();
    },
    goToStep(step) {
      if (step > this.currentStep) return;
      this.goToPreviousStep(step);
    },
    setLinkData(providerTitle) {
      const links = {
        godaddy: 'https://sso.godaddy.com/',
        hostgator: 'https://www.hostgator.com/',
        namecheap: 'https://www.namecheap.com/myaccount/login-signup',
        namecom: 'https://www.name.com/account/login',
      };
      this.loginLink = links[this.selectedProvider];
      this.providerTitle = providerTitle;
    },
    setVideoUrl() {
      const ytUrl = 'https://www.youtube.com/embed';
      const videos = {
        godaddy: 'suV9BQhrgds',
        hostgator: 'DpnjXqPvbtQ',
        namecheap: 'lTUZIFM8Zvk',
        namecom: 'EouIGN0mEKo',
        other: 'UAsYeTL8cZc',
      };
      this.videoUrl =
        this.providerData?.instructionUrl?.replace('watch?v=', 'embed/') ||
        `${ytUrl}/${videos[this.selectedProvider]}` ||
        `${ytUrl}/${videos.other}`;
    },
    onProviderChange(provider) {
      this.selectedProvider = provider.value;
      this.setVideoUrl();
      this.setLinkData(provider.label);
    },
    onOtherProviderChange(input) {
      this.otherProvider = input.value;
      this.setVideoUrl();
    },
    ...mapActions([HDnsActions.FETCH_DNS_PROVIDER_DATA]),
  },
};
</script>

<style lang="scss">
@import '../../../sass/components/ns-change-modal';

.ns-flow__copy {
  width: 19px;
  height: auto;
}
</style>
