import type { IDnsDeleteFilter, IDnsSnapshot, IHDnsZone } from '@/types';
import { hAsync, toASCII } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: process.env.VITE_API_HDNS_V2,
  async getDomainDNSProvider(domain: string) {
    return await hAsync(
      http.get(`${this.url}/v1/dns-provider/discover`, {
        params: { domain },
        hideToastr: true,
        whitelistedStatusCodes: [404],
      }),
    );
  },
  async getEntriJWT() {
    return await hAsync<{
      token: string;
    }>(http.get(`${this.url}/v1/entri/jwt`));
  },
  async getDNSRecordConfiguration(domain: string) {
    return await hAsync<{
      variables: {
        ip: string;
      };
    }>(
      http.get(`${this.url}/v1/domain-connect/template/service-data`, {
        params: { domain },
      }),
    );
  },
  async getDomainDnsZone(domain: string) {
    return await hAsync<IHDnsZone[]>(
      http.get(`${this.url}/v1/direct/zone/resource-records`, {
        params: { domain },
      }),
    );
  },

  async resetDomainDnsRecords(domain: string) {
    return await hAsync(
      http.post(`${this.url}/v1/direct/zone/reset`, {
        domain,
      }),
    );
  },

  async migrateDomainDnsRecordZone(domain: string) {
    return hAsync(http.post(`${this.url}/v1/direct/zone/migrate`, { domain }));
  },

  async addDomainDnsRecord(
    domain: string,
    dnsData: { [key: string]: any }[],
    overwrite = false,
  ) {
    return await hAsync(
      http.patch(
        `${this.url}/v1/direct/zone/resource-records`,
        {
          domain,
          resourceRecords: dnsData,
          overwrite,
        },
        {
          whitelistedErrorCodes: [422, 4008],
        },
      ),
    );
  },

  async deleteDomainDnsRecord(domain: string, filters: IDnsDeleteFilter[]) {
    return await hAsync(
      http.delete(`${this.url}/v1/direct/zone/resource-records`, {
        data: {
          domain,
          filters,
        },
      }),
    );
  },

  async updateDomainDnsRecord(
    domain: string,
    resource: IHDnsZone[],
    overwrite = true,
  ) {
    return await hAsync(
      http.patch(
        `${this.url}/v1/direct/zone/resource-records`,
        {
          domain,
          resourceRecords: resource,
          overwrite,
        },
        {
          whitelistedErrorCodes: [422, 4008],
        },
      ),
    );
  },

  async resetEmailDns(domain: string) {
    return hAsync<void>(
      http.post(`${this.url}/v1/direct/email/reset-records`, {
        domain: toASCII(domain),
      }),
    );
  },

  // --------- Snapshots ---------

  async getDnsSnapshots(domain: string) {
    return hAsync<IDnsSnapshot[]>(
      http.get(`${this.url}/v1/direct/dns-snapshots`, {
        params: {
          domain,
        },
      }),
    );
  },

  async getDnsSnapshot(domain: string, snapshotId: string | number) {
    return hAsync<IDnsSnapshot>(
      http.get(`${this.url}/v1/direct/dns-snapshots/get`, {
        params: {
          domain,
          snapshotId,
        },
      }),
    );
  },

  async restoreDnsSnapshot(domain: string, snapshotId: string | number) {
    return hAsync<boolean>(
      http.post(`${this.url}/v1/direct/dns-snapshots`, {
        domain,
        snapshotId,
      }),
    );
  },

  // --------- --------- ---------
};
