<template>
  <div>
    <h2 v-trans class="h-mb-8">Add payment method</h2>
    <p v-trans>
      You don't have an active payment method. Add one to enable auto-renewal
      for your services.
    </p>
    <div class="payment-method-actions">
      <HButtonV1 no-margin primary text @click="close">Cancel</HButtonV1>
      <HButtonV1 no-margin primary :loading="loading" @click="addPaymentMethod">
        Add payment method
      </HButtonV1>
    </div>
  </div>
</template>

<script>
import { hBillingRepo } from '@/repositories';
import modalsMixin from '@/utils/mixins/modalsMixin';

export default {
  name: 'BillingAddPaymentMethodModal',
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async addPaymentMethod() {
      this.loading = true;
      const [{ data }, err] = await hBillingRepo.addPaymentMethod();

      if (!err && data?.redirectLink) location.href = data.redirectLink;

      this.loading = false;
    },
  },
  mixins: [modalsMixin],
};
</script>

<style scoped lang="scss">
.payment-method-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  @media (max-width: 576px) {
    flex-wrap: wrap-reverse;

    & > button:nth-child(2) {
      margin-bottom: 16px;
    }
  }
}
</style>
