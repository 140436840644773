import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v3/client-cache`,

  async postClientCacheWarmup() {
    return await hAsync<boolean>(http.post(`${this.url}/warmup`));
  },
};
