import { defineStore } from 'pinia';
import { ref } from 'vue';

import { hDomainsRepo } from '@/repositories';
import type { IHDomainsAction, HDomainsActionStatus } from '@/types';
import { STORE_PERSISTENT_KEYS, HDOMAINS_ACTION_STATUS } from '@/types';
import { retryAsyncFunction } from '@/utils/helpers';

export const useHDomainsActionsStore = defineStore(
  'hDomainsActionsStore',
  () => {
    const actions = ref<IHDomainsAction[]>([]);
    const isActionsPollingInProgress = ref(false);

    const getHDomainsActionsByType = (type: string) =>
      actions.value.filter(({ action }) => action === type);

    const getIsActionInProgressExist = () =>
      actions.value.some(({ status }) =>
        (
          [
            HDOMAINS_ACTION_STATUS.IN_PROGRESS,
            HDOMAINS_ACTION_STATUS.RETRYING,
            HDOMAINS_ACTION_STATUS.EXECUTING,
            HDOMAINS_ACTION_STATUS.QUEUED,
          ] as HDomainsActionStatus[]
        ).includes(status),
      );

    const getIsFinishedActionsExist = () =>
      actions.value.some(
        ({ status }) => status === HDOMAINS_ACTION_STATUS.FINISHED,
      );

    const removeHDomainsAction = (actionId: number) => {
      actions.value = actions.value.filter(({ id }) => id !== actionId);
    };

    const fetchHDomainsActions = async (domain: string) => {
      const [{ data }, error] = await hDomainsRepo.getDomainActions(domain);

      if (error) {
        return;
      }

      actions.value = data;
    };

    const pollFetchHDomainsActions = async (domain: string) => {
      isActionsPollingInProgress.value = true;
      await fetchHDomainsActions(domain);
      await retryAsyncFunction({
        triesCount: 20,
        timeoutInMilliseconds: 5000,
        getIsRetryNeeded: getIsActionInProgressExist,
        functionToRetry: () => fetchHDomainsActions(domain),
      });
      isActionsPollingInProgress.value = false;

      deleteAllFailedHDomainsActions(domain);
    };

    const deleteHDomainsAction = async (actionId: number, domain: string) => {
      removeHDomainsAction(actionId);
      hDomainsRepo.deleteDomainAction(domain, actionId);
    };

    const deleteAllFailedHDomainsActions = async (domain: string) => {
      const failedActions = getHDomainsActionsByType(
        HDOMAINS_ACTION_STATUS.FAILED,
      );
      failedActions.map(({ id }) => deleteHDomainsAction(id, domain));
    };

    return {
      actions,
      getHDomainsActionsByType,
      pollFetchHDomainsActions,
      getIsActionInProgressExist,
      deleteHDomainsAction,
      isActionsPollingInProgress,
      getIsFinishedActionsExist,
      fetchHDomainsActions,
      removeHDomainsAction,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.H_DOMAINS_ACTIONS_STORE },
  },
);
