import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { serverMaintenanceRepo } from '@/repositories';
import type { IServerMaintenance } from '@/types';

export const useServerMaintenanceStore = defineStore(
  'serverMaintenanceStore',
  () => {
    const activeMaintenances = ref<IServerMaintenance[]>([]);
    const scheduledMaintenances = ref<IServerMaintenance[]>([]);

    const getOrderActiveMaintenances = (orderId: string) =>
      activeMaintenances.value.filter((maintenance) =>
        maintenance.orders.includes(orderId),
      );

    const getOrderScheduledMaintenances = (orderId: string) =>
      scheduledMaintenances.value.filter(({ orders }) =>
        orders.includes(orderId),
      );

    const latestMaintenanceBanner = computed(() => {
      const maintenances = [
        ...activeMaintenances.value,
        ...scheduledMaintenances.value,
      ];

      return maintenances.reduce((latest, maintenance) => {
        if (
          new Date(latest.scheduledFor) > new Date(maintenance.scheduledFor)
        ) {
          return maintenance;
        }

        return latest;
      }, maintenances[0]);
    });

    const fetchServerMaintenances = async () => {
      const [{ data }, error] =
        await serverMaintenanceRepo.getServerMaintenanceIndex();

      if (error) return;

      activeMaintenances.value = data.activeMaintenances || [];
      scheduledMaintenances.value = data.scheduledMaintenances || [];
    };

    return {
      latestMaintenanceBanner,
      activeMaintenances,
      scheduledMaintenances,
      fetchServerMaintenances,
      getOrderActiveMaintenances,
      getOrderScheduledMaintenances,
    };
  },
);
