import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import PlanBoostModal from '@/components/Modals/HModal/PlanBoostModal.vue';
import { useGlobals, useModal } from '@/composables';
import type { AccountPlanBoost, HostingCardOrder } from '@/types';
import { Hosting } from '@/types';
import {
  DEFAULT_DATE_TIME_FORMAT,
  toLocalTime,
  isHostingPlanUpgradable,
} from '@/utils/helpers';

interface PlanBoostModalParams {
  order?: HostingCardOrder;
  onSuccess?: Function;
  skipBoostIndex?: boolean;
}
const formatTimeValue = (value: number) => {
  if (value < 0) return '00';
  if (value.toString().length <= 1) return `0${value}`;

  return value;
};
const hiddenBoostHostingIds = ref<string[]>([]);

const getBoostTimeLeft = (date: Date) => {
  const now = Math.trunc(Date.now() / 1000);
  const boostedAt = toLocalTime(date, DEFAULT_DATE_TIME_FORMAT);
  // 24 hours boost
  const boostedUntil = Math.trunc(
    parseInt(dayjs(boostedAt).add(24, 'hours').format('x')) / 1000,
  );

  return boostedUntil - now;
};

export const usePlanBoost = () => {
  const store = useStore();
  const { t } = useGlobals();
  const { openModal } = useModal();

  const orderDetails = computed(() =>
    isEmpty(store.getters.getHostingAccountDetails)
      ? store.getters.currentOrderDetails
      : store.getters.getHostingAccountDetails,
  );

  const isBoostActivated = computed(
    () => orderDetails.value?.planBoost?.activated,
  );

  const isBoostAllowed = computed(
    () =>
      isHostingPlanUpgradable(orderDetails.value.plan) &&
      !orderDetails.value?.planBoost?.activated &&
      orderDetails.value?.planBoost?.canBeBoosted,
  );
  const planBoostTooltipText = computed(() =>
    isBoostAllowed.value
      ? t('Boost hosting plan')
      : t('Hosting plan boosting is available once per month'),
  );

  const getOrderBoostStatus = (order: HostingCardOrder) =>
    store.getters.getBoostedOrders?.find(
      (accountOrder: AccountPlanBoost) =>
        accountOrder.orderId === order.serviceId,
    );

  const getPlanBoostAllowed = (order: HostingCardOrder) => {
    const currentOrderBoostData = getOrderBoostStatus(order);

    return (
      !!order?.websites?.length &&
      !currentOrderBoostData?.activated &&
      currentOrderBoostData?.canBeBoosted &&
      isHostingPlanUpgradable(order?.productName as Hosting.Plan) &&
      order?.title !== Hosting.NonUpgradeablePlanTitle.CLOUD_ENTERPRISE
    );
  };
  const openPlanBoostModal = (params: PlanBoostModalParams) => {
    let canBeOpened = isBoostAllowed.value;

    if (params?.order?.id) {
      canBeOpened = getPlanBoostAllowed(params?.order);
    }

    if (canBeOpened) {
      openModal({
        component: { PlanBoostModal },
        data: {
          skipBoostIndex: true,
          domain: params?.order?.websites[0]?.domain,
          onSuccess: params?.onSuccess,
        },
      });
    }
  };

  const setPlanBoost = (orderId: string) => {
    // Since backend cannot update boost data immediately we are using this function
    const modifiedBoostedOrders = [...store.getters.getBoostedOrders];
    const index = modifiedBoostedOrders?.findIndex(
      (order: AccountPlanBoost) => order.orderId === orderId,
    );
    const modifiedItem = {
      activated: true,
      boostedAt: dayjs
        .utc()
        .add(23, 'hours')
        .add(59, 'minutes')
        .format(DEFAULT_DATE_TIME_FORMAT),
      canBeBoosted: false,
    };
    modifiedBoostedOrders[index] = {
      ...modifiedBoostedOrders[index],
      ...modifiedItem,
    };
    hiddenBoostHostingIds.value = [...hiddenBoostHostingIds.value, orderId];
    store.commit('SET_BOOSTED_ORDERS', modifiedBoostedOrders);
  };
  const getPlanBoostTimeLeft = (date: Date) => {
    const hours = Math.trunc(getBoostTimeLeft(date) / 60 / 60) % 24;
    const minutes = Math.trunc(getBoostTimeLeft(date) / 60) % 60;

    return `${formatTimeValue(hours)}:${formatTimeValue(minutes)}`;
  };
  const getIsCurrentlyBoosted = (order: HostingCardOrder) => {
    const currentOrderBoostData = store.getters.getBoostedOrders?.find(
      (accountOrder: AccountPlanBoost) =>
        accountOrder.orderId === order.serviceId,
    );

    const isPresent = getBoostTimeLeft(currentOrderBoostData?.boostedAt) > 0;

    return currentOrderBoostData?.boostedAt && isPresent;
  };

  return {
    isBoostActivated,
    getPlanBoostTimeLeft,
    getPlanBoostAllowed,
    getOrderBoostStatus,
    getIsCurrentlyBoosted,
    isBoostAllowed,
    planBoostTooltipText,
    setPlanBoost,
    openPlanBoostModal,
    hiddenBoostHostingIds,
  };
};
