import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { referralRepo } from '@/repositories';
import { useProfileStore } from '@/stores';
import type {
  ClientReferrals,
  ReferralsListItem,
  HAsyncError,
  IUserReferralData,
  ReferralClientInfo,
  RequestConfig,
  IReferralReward,
} from '@/types';
import { Route, HostingerProPanel } from '@/types';

export const useReferralStore = defineStore('referralStore', () => {
  const profileStore = useProfileStore();

  const userReferralData = ref<IUserReferralData | null>(null);
  const proUserReferralData = ref<ReferralClientInfo | null>(null);
  const userReferrals = ref<ClientReferrals | null>(null);
  const proUserReferrals = ref<ClientReferrals | null>(null);
  const isLoading = ref(false);
  const isFormSubmitting = ref(false);
  const payPalList = ref<string[]>([]);
  const proPayPalList = ref<string[]>([]);
  const error = ref<HAsyncError | null>(null);
  const referralsRoute = ref<{ name: Route.Referral } | null>(null);
  const isProUserReferralsDataLoaded = ref(false);
  const payouts = ref<IReferralReward[] | null>(null);

  const getPaypalList = computed(() =>
    payPalList.value.map((item) => ({ label: item, value: item })),
  );

  const hasAccessToReferralDashboard = computed(() => {
    if (profileStore.isAccessManager) return false;

    if (userReferralData.value && !userReferralData.value?.disabled) {
      return true;
    }

    return false;
  });

  const hasAtLeastOneQualifiedReferral = computed(() =>
    (userReferrals.value?.list || []).some(({ status }: ReferralsListItem) =>
      [
        HostingerProPanel.ProRewardStatus.APPROVED,
        HostingerProPanel.ProRewardStatus.QUALIFIED,
      ].includes(status),
    ),
  );

  const referralsCount = computed(
    () => userReferrals.value?.totals.totalReferrals || 0,
  );

  const totalCommissionsEarned = computed(
    () => userReferralData.value?.rewardsIssuedAmount || 0,
  );

  const approvedCommissionsAmount = computed(
    () => userReferralData.value?.rewardsApprovedAmount || 0,
  );

  const payoutType = computed(() => userReferralData.value?.payoutInfo?.type);

  const isReferralRockProgramMember = computed(
    () =>
      !!(userReferralData.value && Object.keys(userReferralData.value).length),
  );

  const isMemberDisabled = computed(() => userReferralData.value?.disabled);

  const fetchEmails = async () => {
    const [{ data }, requestError] = await referralRepo.getPaypalEmails();

    if (!requestError) {
      payPalList.value = data;
    }

    return [{ data }, requestError];
  };

  const fetchReferralRockMemberData = async (hideToastr?: boolean) => {
    isLoading.value = true;

    const [{ data }, requestError] = await referralRepo.getReferralClientInfo(
      hideToastr,
    );

    isLoading.value = false;

    if (requestError) {
      error.value = requestError;

      return;
    }

    if (Array.isArray(data) && !data.length) {
      userReferralData.value = null;

      return;
    }

    userReferralData.value = data;
  };

  const fetchReferralClientInfo = async (hideToastr?: boolean) => {
    isLoading.value = true;

    const [{ data }, requestError] = await referralRepo.getReferralClientInfo(
      hideToastr,
    );

    if (data) {
      await fetchClientReferrals();
    }

    isLoading.value = false;

    if (requestError) {
      isLoading.value = false;
      error.value = requestError;

      return;
    }

    if (Array.isArray(data) && !data.length) {
      userReferralData.value = null;

      return;
    }

    userReferralData.value = data;
  };

  const fetchClientReferrals = async (requestConfig?: RequestConfig) => {
    const [{ data }, requestError] = await referralRepo.getClientReferrals(
      requestConfig,
    );

    if (requestError) {
      isLoading.value = false;
      error.value = requestError;

      return;
    }

    userReferrals.value = data;
  };

  const fetchPaypalEmailList = async () => {
    const [{ data }, requestError] = await referralRepo.getPaypalEmails();

    if (requestError) {
      isLoading.value = false;
      error.value = requestError;

      return;
    }

    payPalList.value = data;
  };

  const fetchUserReferralData = async (requestConfig?: RequestConfig) => {
    isLoading.value = true;

    await Promise.all([
      fetchReferralClientInfo(requestConfig?.hideToastr),
      fetchPaypalEmailList(),
    ]);

    isLoading.value = false;
  };

  const addEmail = async (email: string) => {
    const [, requestError] = await referralRepo.addPaypalEmail({ email });

    if (requestError) {
      error.value = requestError;

      return;
    }

    await fetchUserReferralData();
  };

  const setEmail = async (email: string) => {
    const [, requestError] = await referralRepo.setPaypalEmails({ email });

    if (!requestError) {
      await fetchUserReferralData();
    }
  };

  const fetchPaypalList = async () => {
    const response = await referralRepo.getRegistrationPaypalEmails();
    const [{ data }, requestError] = response;

    if (requestError) {
      error.value = requestError;

      return;
    }

    if (!Array.isArray(data)) {
      payPalList.value = data.result;

      return data.result;
    }

    payPalList.value = data || [];

    return data;
  };

  const fetchReferralAccessData = async () => {
    if (hasAccessToReferralDashboard.value) {
      referralsRoute.value = {
        name: Route.Referral.REFERRALS,
      };

      return;
    }

    let [{ data }] = await referralRepo.getRegistrationPaypalEmails();

    if (!Array.isArray(data)) {
      data = data.result;
    }

    if (data) {
      referralsRoute.value = {
        name: Route.Referral.WELCOME_TO_REFERRALS,
      };

      return;
    }

    referralsRoute.value = null;
  };

  const fetchProUserReferralData = async (params: { hideToastr: boolean }) => {
    const [
      [{ data: proClientInfoData }, proClientInfoError],
      [{ data: proClientReferralsData }, proClientReferralsError],
      [proPaypalListResponse, proPayPalListError],
    ] = await Promise.all([
      referralRepo.getReferralProClientInfo(params?.hideToastr),
      referralRepo.getProClientReferrals(params?.hideToastr),
      referralRepo.getProPaypalEmails(),
    ]);

    const hasErrors = [
      proClientInfoError,
      proClientReferralsError,
      proPayPalListError,
    ].some(Boolean);

    if (!hasErrors) {
      proUserReferralData.value = proClientInfoData;
      proUserReferrals.value = proClientReferralsData;
      proPayPalList.value = proPaypalListResponse.data;
      isProUserReferralsDataLoaded.value = true;
    }
  };

  const fetchPayoutsData = async () => {
    const [{ data }, error] = await referralRepo.getRewards();

    if (error) {
      return;
    }

    payouts.value = data;
  };

  const $reset = () => {
    userReferralData.value = null;
    proUserReferralData.value = null;
    userReferrals.value = null;
    proUserReferrals.value = null;
    isLoading.value = false;
    isFormSubmitting.value = false;
    payPalList.value = [];
    proPayPalList.value = [];
    error.value = null;
    referralsRoute.value = null;
    isProUserReferralsDataLoaded.value = false;
  };

  return {
    userReferralData,
    proUserReferralData,
    userReferrals,
    proUserReferrals,
    isLoading,
    isFormSubmitting,
    payPalList,
    proPayPalList,
    error,
    referralsRoute,
    isProUserReferralsDataLoaded,
    getPaypalList,
    hasAccessToReferralDashboard,
    hasAtLeastOneQualifiedReferral,
    isReferralRockProgramMember,
    payouts,
    referralsCount,
    totalCommissionsEarned,
    approvedCommissionsAmount,
    payoutType,
    isMemberDisabled,
    fetchEmails,
    addEmail,
    setEmail,
    fetchPaypalList,
    fetchReferralAccessData,
    fetchUserReferralData,
    fetchProUserReferralData,
    fetchReferralClientInfo,
    fetchReferralRockMemberData,
    fetchClientReferrals,
    fetchPayoutsData,
    $reset,
  };
});
