import { useRoute } from 'vue-router';

import { websiteBuilderRepo, websiteRepo } from '@/repositories';
import { Website } from '@/types';

export const useAddDomain = () => {
  const route = useRoute();

  const getConnectDomainRequest = () => {
    const isBuilderWebsite = route.query.websiteType === Website.Type.BUILDER;

    return isBuilderWebsite
      ? websiteBuilderRepo.changeWebsiteDomain.bind(websiteBuilderRepo)
      : websiteRepo.changeWebsiteDomain.bind(websiteRepo);
  };

  return {
    getConnectDomainRequest,
  };
};
