import type { INpsAnswer, SNOOZE_DAYS } from '@/types';
import { SURVEY_TYPE } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_SURVEYS}/api/jwt/v1/survey`,

  async getNpsEligible(surveyIdentifier: SURVEY_TYPE = SURVEY_TYPE.NPS) {
    return await hAsync<{ isEligible: boolean }>(
      http.get(`${this.url}/eligibility`, {
        params: {
          surveyIdentifier,
        },
        hideNetworkError: true,
      }),
    );
  },
  async createNps(answers: INpsAnswer[]) {
    return await hAsync(
      http.post(`${this.url}/response`, {
        answers,
        surveyIdentifier: SURVEY_TYPE.NPS,
      }),
    );
  },
  async closeNps(surveyIdentifier: SURVEY_TYPE, snoozeDays: SNOOZE_DAYS) {
    return await hAsync(
      http.post(`${this.url}/snooze`, {
        surveyIdentifier,
        snoozeDays,
      }),
    );
  },

  async createNpsManual(answers: INpsAnswer[]) {
    return await hAsync(
      http.post(`${this.url}/response`, {
        answers,
        surveyIdentifier: SURVEY_TYPE.CSAT,
      }),
    );
  },
};
