import type { RouteRecordRaw } from 'vue-router';

import { MIGRATIONS_ONBOARDING_STEPS, Route } from '@/types';

const meta = {
  isPageWrapperV2: true,
};

export default [
  {
    path: '/migrations-onboarding/:domain?',
    name: Route.MigrationsOnboarding.BASE_PATH,
    meta,
    component: () =>
      import('@/views/Hosting/MigrationsOnboarding/MigrationsOnboarding.vue'),
    redirect: { name: MIGRATIONS_ONBOARDING_STEPS.WEBSITE_TO_MIGRATE_NAME },

    children: [
      {
        path: 'website-to-migrate',
        name: MIGRATIONS_ONBOARDING_STEPS.WEBSITE_TO_MIGRATE_NAME,
        meta: {
          ...meta,
          title: 'What is the name of the website you want to migrate?',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsEnterWebsite.vue'
          ),
      },

      {
        path: 'how-to-migrate-wordpress-website',
        name: MIGRATIONS_ONBOARDING_STEPS.HOW_TO_MIGRATE_WORDPRESS_WEBSITE,
        meta: {
          ...meta,
          title: 'How do you want to migrate your website?',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsMigrateWordPressWebsite.vue'
          ),
      },
      {
        path: 'select-website-platform',
        name: MIGRATIONS_ONBOARDING_STEPS.SELECT_WEBSITE_PLATFORM,
        meta: {
          ...meta,
          title: 'What platform is your website using right now?',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsSelectPlatform.vue'
          ),
      },
      {
        path: 'migrations-requirements',
        name: MIGRATIONS_ONBOARDING_STEPS.MIGRATIONS_REQUIREMENTS,
        meta: {
          ...meta,
          title: 'Migration requirements',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsRequirements.vue'
          ),
      },
      {
        path: 'upload-backups',
        name: MIGRATIONS_ONBOARDING_STEPS.UPLOAD_BACKUPS,
        meta: {
          ...meta,
          title: 'Upload your backups using drag&drop or select files.',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsUploadBackups.vue'
          ),
      },
      {
        path: 'enter-cpanel-details',
        name: MIGRATIONS_ONBOARDING_STEPS.ENTER_CPANEL_DETAILS,
        meta: {
          ...meta,
          title: 'Enter your WordPress Admin login details',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsEnterCpanelDetails.vue'
          ),
      },
      {
        path: 'enter-other-panel-details',
        name: MIGRATIONS_ONBOARDING_STEPS.ENTER_OTHER_CONTROL_PANEL_MIGRATION_DETAILS,
        meta: {
          ...meta,
          title: 'Enter your panel login information',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsEnterOtherPanelDetails.vue'
          ),
      },
      {
        path: 'enter-wordpress-details',
        name: MIGRATIONS_ONBOARDING_STEPS.ENTER_WORDPRESS_DETAILS,
        meta: {
          ...meta,
          title: 'Enter your WordPress Admin login details',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsEnterWordPressDetails.vue'
          ),
      },
      {
        path: 'where-to-migrate-website',
        name: MIGRATIONS_ONBOARDING_STEPS.WHERE_TO_MIGRATE_WEBSITE,
        meta: {
          ...meta,
          title: 'Where do you want to migrate your website?',
        },
        component: () =>
          import(
            '@/views/Hosting/MigrationsOnboarding/MigrationsWhereToMigrate.vue'
          ),
      },
      {
        path: 'summary',
        name: MIGRATIONS_ONBOARDING_STEPS.SUMMARY,
        meta: {
          ...meta,
          title: 'Let’s wrap this up!',
        },
        component: () =>
          import('@/views/Hosting/MigrationsOnboarding/MigrationsSummary.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
