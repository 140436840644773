// CSAT measures how satisfied a customer is with specific area or flow
// NPS measures overall customer loyalty towards our brand

import { defineStore } from 'pinia';
import { ref } from 'vue';

import { npsRepo } from '@/repositories';
import { useFrontendSettingsStore } from '@/stores';
import { CSAT_CUSTOM_IDENTIFIER, SURVEY_TYPE } from '@/types';
import type { INpsAnswer, IFeedbackProps } from '@/types';
import { timeout, mapKeyValue } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';

// The percentage of users that will see the CSAT survey
const CSAT_DISPLAY_PROBABILITIES = {
  [CSAT_CUSTOM_IDENTIFIER.WEBSITES_LIST]: 0.2,
  [CSAT_CUSTOM_IDENTIFIER.DOMAINS_OVERVIEW]: 0.2,
  [CSAT_CUSTOM_IDENTIFIER.WEBSITE_DASHBOARD]: 0.2,
  [CSAT_CUSTOM_IDENTIFIER.NEW_NAVIGATION]: 0.2,
  default: 1,
};

export const useFeedbackStore = defineStore('feedbackStore', () => {
  const frontendSettingsStore = useFrontendSettingsStore();

  const isNPSVisible = ref(false);
  const isNPSEligibilityLoaded = ref(false);
  const isCSATLoaded = ref(false);

  const isCSATVisible = ref(false);

  const expirationTime = ref(0);
  const sessionCSATDisplayProbability = ref(1);
  const feedbackProps = ref<IFeedbackProps>({});

  const resetFeedbackProps = () => {
    feedbackProps.value = {} as IFeedbackProps;
  };

  const openCSATFeedback = async (props: IFeedbackProps) => {
    if (!isWithinCSATDisplayProbability(props.location)) {
      return;
    }

    await fetchCSATData();

    if (!isCSATVisible.value) return;

    // Wait for the components to be mounted after redirect
    await timeout(1000);

    feedbackProps.value = props;
    frontendSettingsStore.toggleFeedbackOpen(true);
  };

  const fetchNPSData = async () => {
    const [{ data }, err] = await npsRepo.getNpsEligible();

    errorLogger.addBreadcrumb({
      name: 'Survey: NPS eligibility',
      data: { data, err },
    });

    if (err || !data) return;

    isNPSVisible.value = data.isEligible;
    isNPSEligibilityLoaded.value = true;
  };

  const fetchCSATData = async () => {
    const [{ data }, err] = await npsRepo.getNpsEligible(SURVEY_TYPE.CSAT);
    isCSATLoaded.value = true;

    errorLogger.addBreadcrumb({
      name: 'Survey: CSAT eligibility',
      data: { data, err },
    });

    if (err || !data) return;

    isCSATVisible.value = data.isEligible;
  };

  const submitNPS = async (answers: INpsAnswer[]) => {
    errorLogger.addBreadcrumb({
      name: 'Survey: submit NPS answers',
      data: {
        isNpsEligible: isNPSVisible,
        isCsatVisible: isCSATVisible,
      },
    });

    return await npsRepo.createNps(answers);
  };

  const submitCSAT = async (answers: INpsAnswer[]) => {
    errorLogger.addBreadcrumb({
      name: 'Survey: submit CSAT answers',
      data: {
        isNpsEligible: isNPSVisible,
        isCsatVisible: isCSATVisible,
      },
    });

    const [{ data }, err] = await npsRepo.createNpsManual(answers);

    isCSATVisible.value = false;

    return [{ data }, err];
  };

  const isWithinCSATDisplayProbability = (location?: string) => {
    if (!location || !sessionCSATDisplayProbability.value) return true;

    const thresholdProbability = mapKeyValue(
      CSAT_DISPLAY_PROBABILITIES,
      location,
    );

    return sessionCSATDisplayProbability.value <= thresholdProbability;
  };

  const $reset = () => {
    isNPSVisible.value = false;
    isNPSEligibilityLoaded.value = false;
    isCSATLoaded.value = false;
    isCSATVisible.value = false;
    expirationTime.value = 0;
    feedbackProps.value = {} as IFeedbackProps;
    sessionCSATDisplayProbability.value = 1;
  };

  return {
    isNPSVisible,
    isCSATVisible,
    isCSATLoaded,
    isNPSEligibilityLoaded,
    expirationTime,
    feedbackProps,
    sessionCSATDisplayProbability,
    fetchNPSData,
    openCSATFeedback,
    resetFeedbackProps,
    fetchCSATData,
    submitCSAT,
    submitNPS,
    $reset,
  };
});
