import cookies from 'js-cookie';
import { storeToRefs } from 'pinia';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { ICON_BRAND_MAP } from '@/data/imageBrandMaps';
import { KB_URL_DATA } from '@/data/knowledgeBaseUrlData';
import { useProfileStore } from '@/stores';
import { Client, type TBrandId } from '@/types';
import { mapKeyValue } from '@/utils/helpers';
import { getAsset } from '@/utils/helpers/assetsHelpers';
import { getBrandConfigById } from '@/utils/services/brandConfigService';

const isDevelopment =
  process.env.NODE_ENV === 'development' ||
  (process.env.NODE_ENV === 'production' &&
    process.env.VITE_MODE === 'staging');

const ENGLISH_SPEAKING_BRAND_IDS = [
  Client.BrandId.Hostinger.COM,
  Client.BrandId.Hostinger.IN,
  Client.BrandId.Hostinger.CO_UK,
];

export const useBrands = () => {
  const profileStore = useProfileStore();
  const { brandDomain, brandId } = storeToRefs(profileStore);

  const otherBrands: TBrandId[] = [
    Client.BrandId.Other.HOSTING24,
    Client.BrandId.Other.HOSTMANIA,
    Client.BrandId.Other.WEBLINK,
    Client.BrandId.Other.NG_CO_ID,
  ];

  const isFromAnotherBrand = computed(() => {
    if (!profileStore.account?.brand.id) return false;

    return otherBrands.includes(profileStore.account.brand.id);
  });

  const hasBrand = (brands: TBrandId[]) => {
    if (!profileStore.account?.brand.id) return false;

    const profileBrandId = String(profileStore.account.brand.id);

    return brands.includes(profileBrandId as TBrandId);
  };

  const isFromNiagahoster = computed(() =>
    hasBrand([Client.BrandId.Other.NG_CO_ID]),
  );

  const brandConfiguration = computed(() =>
    getBrandConfigById(profileStore.account?.brand.id),
  );

  const brand = computed(() => {
    if (isFromNiagahoster.value) {
      return Client.Brand.NG_CO_ID;
    }

    return Client.Brand.HOSTINGER;
  });

  const externalUrl = computed(() => mapKeyValue(EXTERNAL_URL, brand.value));

  const articleLink = computed(() => mapKeyValue(KB_URL_DATA, brand.value));
  const customIcon = (
    param: any,
  ): ComputedRef<{
    icon?: string;
    iconSvg?: string;
  }> =>
    computed(() => {
      const brandIcon = mapKeyValue(ICON_BRAND_MAP, brand.value)?.[param];

      return {
        title: isFromNiagahoster.value ? '' : brandIcon,
        iconSvg: isFromNiagahoster.value ? getAsset(brandIcon) : '',
      };
    });

  const brandCountry = computed(() => profileStore.account?.brand.country);

  const isIntercomProPanelEnabled = computed(
    () => !!cookies.get('pro-panel-intercom'),
  );

  const displayYoutubeVideos = computed(() => {
    const resellersForRelease = ['.co.uk', '.com'].map(
      (reseller) => `hostinger${reseller}`,
    );

    return (
      resellersForRelease.includes(brandDomain.value || '') ||
      !!cookies.get('youtube-videos') ||
      isDevelopment
    );
  });

  const isBrazilReseller = computed(() =>
    hasBrand([Client.BrandId.Hostinger.COM_BR]),
  );

  const isPakistanReseller = computed(() =>
    hasBrand([Client.BrandId.Hostinger.PK]),
  );

  const isArgentinaReseller = computed(() =>
    hasBrand([Client.BrandId.Hostinger.COM_AR]),
  );

  const isPhilippinesReseller = computed(() =>
    hasBrand([Client.BrandId.Hostinger.PH]),
  );

  const isComReseller = computed(() =>
    hasBrand([Client.BrandId.Hostinger.COM]),
  );

  const isIndianBrand = computed(() => hasBrand([Client.BrandId.Hostinger.IN]));

  const isCoUkBrand = computed(() =>
    hasBrand([Client.BrandId.Hostinger.CO_UK]),
  );

  const isEnglishSpeakingBrand = computed(() =>
    hasBrand(ENGLISH_SPEAKING_BRAND_IDS),
  );

  const canPurchaseVpsLicense = computed(() => {
    if (!brandId.value) return isDevelopment;

    const resellersForRelease: string[] = [
      Client.BrandId.Hostinger.COM,
      Client.BrandId.Hostinger.LT,
      Client.BrandId.Hostinger.ES,
      Client.BrandId.Hostinger.FR,
      Client.BrandId.Hostinger.IT,
      Client.BrandId.Hostinger.PT,
      Client.BrandId.Hostinger.DE,
      Client.BrandId.Hostinger.NL,
      Client.BrandId.Hostinger.GR,
      Client.BrandId.Hostinger.SK,
      Client.BrandId.Hostinger.LV,
      Client.BrandId.Hostinger.EE,
      Client.BrandId.Hostinger.FI,
      Client.BrandId.Hostinger.HR,
      Client.BrandId.Other.NG_CO_ID,
    ];

    return (
      resellersForRelease.includes(brandId.value.toString()) || isDevelopment
    );
  });

  return {
    brand,
    brandCountry,
    hasBrand,
    brandConfiguration,
    otherBrands,
    isFromAnotherBrand,
    isFromNiagahoster,
    externalUrl,
    articleLink,
    customIcon,
    isIntercomProPanelEnabled,
    displayYoutubeVideos,
    isIndianBrand,
    isEnglishSpeakingBrand,
    isBrazilReseller,
    isPakistanReseller,
    isArgentinaReseller,
    isPhilippinesReseller,
    isComReseller,
    isCoUkBrand,
    canPurchaseVpsLicense,
  };
};

export const EXTERNAL_URL = {
  default: {
    privacyPolicy: 'https://www.hostinger.com/privacy-policy',
    termsOfService:
      'https://www.hostinger.com/universal-terms-of-service-agreement',
  },
  niagahoster: {
    privacyPolicy: 'https://www.niagahoster.co.id/kebijakan-privasi',
    termsOfService:
      'https://www.niagahoster.co.id/perjanjian-ketentuan-layanan-universal',
  },
};
