import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
  useGlobals,
  useH5GOnboarding,
  useOnboardingWordPressThemesV2,
} from '@/composables';
import { surveyRepo } from '@/repositories';
import {
  useHostingerProStore,
  useOnboardingDataCenterStore,
  useOnboardingSourceStore,
  useOnboardingStore,
  useProfileStore,
  useSubscriptionsStore,
  useOnboardingWordPressThemesStore,
} from '@/stores';
import type { OnboardingStep, OnboardingScenario } from '@/types';
import {
  AmplitudeEvent,
  CREATE_OR_MIGRATE,
  ONBOARDING_STEPS,
  Onboarding,
  WEBSITE_TYPE_TITLE_MAP,
  BILLING_ANALYTICS_DATA_KEY,
  SOURCE,
  ONBOARDING_SCENARIO,
  PLATFORM,
  WORDPRESS_HOSTING_BUY_PAGES,
  WEBSITE_BUILDER_BUY_PAGES,
  H5G_ONBOARDING_STEPS,
  WORDPRESS_APPEARANCE_TYPE,
  WORDPRESS_VISUAL_SELECT,
} from '@/types';
import { toASCII, mapKeyValue } from '@/utils/helpers';

export const useOnboardingV2 = () => {
  const onboardingStore = useOnboardingStore();
  const profileStore = useProfileStore();
  const onboardingDCStore = useOnboardingDataCenterStore();
  const subscriptionStore = useSubscriptionsStore();
  const { fetchAndPreloadWordPressThemes } = useOnboardingWordPressThemesV2();
  const router = useRouter();
  const route = useRoute();
  const { amplitudeV2, t } = useGlobals();
  const onboardingSourceStore = useOnboardingSourceStore();
  const onboardingWordPressThemeStore = useOnboardingWordPressThemesStore();
  const { isAccessManager } = storeToRefs(useProfileStore());
  const hostingerProStore = useHostingerProStore();
  const { canUsePresetsInOnboarding } = storeToRefs(hostingerProStore);
  const { isH5GFeatureEnabled } = useH5GOnboarding();

  const isLoadingOnboarding = ref(false);

  const subscriptionId = route?.params.order_id as string;
  const onboardingScenario = route?.params.scenario as
    | OnboardingScenario
    | undefined;

  const websiteTypeTitle = computed(() => {
    if (onboardingStore.state.wordpressPresets) {
      return onboardingStore.state.wordpressPresets;
    }

    const type = onboardingStore.state.typeOfWebsite || 'other';

    if (type === 'other') {
      return t('Website');
    }

    return t(WEBSITE_TYPE_TITLE_MAP[type]);
  });

  const isH5GOnboarding = computed(
    () => !!route?.params?.h5gId && isH5GFeatureEnabled.value,
  );

  const isBuilderPlatformSelected = computed(
    () => onboardingStore.state.platformSelect === PLATFORM.BUILDER,
  );

  const isWordPressPlatformSelected = computed(
    () => onboardingStore.state.platformSelect === PLATFORM.WORDPRESS,
  );

  const isCreationSource000Webhost = computed(
    () =>
      subscriptionStore.subscriptionOrder?.metadata.creationSource ===
      SOURCE.WEBHOST,
  );

  const is000WebhostMigration = computed(
    () => isMigrationSelected.value && isCreationSource000Webhost.value,
  );

  const hasSelectedWordPressAppearance = computed(() => {
    const { wordpressThemeSelect, wordPressPreviewAstraTemplate } =
      onboardingStore.state;

    const hasWordPressThemeSelected = !!wordpressThemeSelect;

    const hasWordPressAstraTemplate = !!wordPressPreviewAstraTemplate?.id;

    return (
      isWordPressPlatformSelected.value &&
      (hasWordPressThemeSelected || hasWordPressAstraTemplate)
    );
  });

  const wordPressAppearanceType = computed(() => {
    const { wordPressVisualSelect } = onboardingStore.state;
    if (wordPressVisualSelect === WORDPRESS_VISUAL_SELECT.PRE_BUILT_WEBSITE) {
      return WORDPRESS_APPEARANCE_TYPE.STARTER_TEMPLATE;
    }

    return WORDPRESS_APPEARANCE_TYPE.THEME;
  });

  const selectedPlatformByRedirect = computed(() => {
    if (!subscriptionStore.subscriptionOrder) {
      return null;
    }

    const redirectedFrom =
      subscriptionStore.subscriptionOrder.analyticsData?.find(
        ({ key }) => key === BILLING_ANALYTICS_DATA_KEY.PAGE_NAME,
      )?.value;

    if (WORDPRESS_HOSTING_BUY_PAGES.includes(redirectedFrom || '')) {
      amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
        platformSelected: Onboarding.Platform.WORDPRESS,
        subscriptionId,
        preselectedAutomatically: true,
        ...(!isAccessManager.value && {
          isWebpro: onboardingStore.isIdentifiedPro,
        }),
      });

      return Onboarding.Platform.WORDPRESS;
    }

    if (WEBSITE_BUILDER_BUY_PAGES.includes(redirectedFrom || '')) {
      amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
        platformSelected: Onboarding.Platform.BUILDER,
        subscriptionId,
        preselectedAutomatically: true,
        ...(!isAccessManager.value && {
          isWebpro: onboardingStore.isIdentifiedPro,
        }),
      });

      return Onboarding.Platform.BUILDER;
    }

    return null;
  });

  const isMigrationSelected = computed(
    () => onboardingStore.state.createOrMigrate === CREATE_OR_MIGRATE.MIGRATE,
  );

  const handleQuickInstallMigrationScenario = async () => {
    onboardingStore.setState(
      ONBOARDING_STEPS.CREATE_OR_MIGRATE,
      CREATE_OR_MIGRATE.MIGRATE,
    );
    onboardingStore.setIsEligibleForSurvey(false);
    await onboardingStore.goToStep(ONBOARDING_STEPS.DOMAIN);
  };

  const handleQuickInstallMigrationAddonScenario = async () => {
    onboardingStore.setIsAddon(true);
    onboardingStore.setState(
      ONBOARDING_STEPS.CREATE_OR_MIGRATE,
      CREATE_OR_MIGRATE.MIGRATE,
    );
    onboardingStore.setIsEligibleForSurvey(false);
    await onboardingStore.goToStep(ONBOARDING_STEPS.DOMAIN);
  };

  const handlePreselectedWordPressScenario = async (
    isEligibleForSurvey: boolean,
  ) => {
    onboardingStore.setState(
      ONBOARDING_STEPS.PLATFORM_SELECT,
      PLATFORM.WORDPRESS,
    );

    amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
      platformSelected: Onboarding.Platform.WORDPRESS,
      preselectedAutomatically: true,
      subscriptionId,
    });

    if (isEligibleForSurvey && !profileStore.isAccessManager) {
      await onboardingStore.goToStep(ONBOARDING_STEPS.SURVEY_FOR_WHO);
    } else {
      await onboardingStore.goToStep(ONBOARDING_STEPS.CREATE_OR_MIGRATE);
    }
  };

  const handleQuickInstallWordPressAddonScenario = async (
    isEligibleForSurvey: boolean,
  ) => {
    onboardingStore.setIsAddon(true);
    onboardingStore.setState(
      ONBOARDING_STEPS.PLATFORM_SELECT,
      PLATFORM.WORDPRESS,
    );

    amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
      platformSelected: Onboarding.Platform.WORDPRESS,
      preselectedAutomatically: true,
      subscriptionId,
    });

    if (isEligibleForSurvey && !profileStore.isAccessManager) {
      await onboardingStore.goToStep(ONBOARDING_STEPS.SURVEY_FOR_WHO);
    } else {
      await onboardingStore.goToStep(ONBOARDING_STEPS.WORDPRESS_ADMIN_SETUP);
    }
  };

  const handleQuickInstallEmptyWebsiteAddonScenario = async (
    isEligibleForSurvey: boolean,
  ) => {
    onboardingStore.setIsAddon(true);

    amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
      platformSelected: Onboarding.Platform.NONE,
      preselectedAutomatically: true,
      subscriptionId,
    });

    if (isEligibleForSurvey && !profileStore.isAccessManager) {
      await onboardingStore.goToStep(ONBOARDING_STEPS.SURVEY_FOR_WHO);
    } else {
      await onboardingStore.goToStep(ONBOARDING_STEPS.DOMAIN);
    }
  };

  const handleQuickInstallEmptyWebsiteSetupScenario = async (
    isEligibleForSurvey: boolean,
  ) => {
    amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
      platformSelected: Onboarding.Platform.NONE,
      preselectedAutomatically: true,
      subscriptionId,
    });
    if (isEligibleForSurvey && !profileStore.isAccessManager) {
      await onboardingStore.goToStep(ONBOARDING_STEPS.SURVEY_FOR_WHO);
    } else {
      await onboardingStore.goToStep(ONBOARDING_STEPS.DOMAIN);
    }
  };

  const handleQuickInstallWordPressSetupScenario = async (
    isEligibleForSurvey: boolean,
  ) => {
    onboardingStore.setState(
      ONBOARDING_STEPS.PLATFORM_SELECT,
      PLATFORM.WORDPRESS,
    );
    amplitudeV2(AmplitudeEvent.Onboarding.PLATFORM_SELECTED, {
      platformSelected: Onboarding.Platform.WORDPRESS,
      preselectedAutomatically: true,
      subscriptionId,
    });
    if (isEligibleForSurvey && !profileStore.isAccessManager) {
      await onboardingStore.goToStep(ONBOARDING_STEPS.SURVEY_FOR_WHO);
    } else {
      await onboardingStore.goToStep(ONBOARDING_STEPS.WORDPRESS_ADMIN_SETUP);
    }
  };

  const handleDefaultScenario = async (isEligibleForSurvey: boolean) => {
    if (isH5GOnboarding.value) {
      await onboardingStore.goToStep(H5G_ONBOARDING_STEPS.H5G_AGREE);
    } else if (isEligibleForSurvey && !profileStore.isAccessManager) {
      await onboardingStore.goToStep(ONBOARDING_STEPS.SURVEY_FOR_WHO);
    } else {
      await onboardingStore.goToStep(ONBOARDING_STEPS.CREATE_OR_MIGRATE);
    }
  };

  const handleDefaultAddonScenario = async (isEligibleForSurvey: boolean) => {
    onboardingStore.setIsAddon(true);

    return handleDefaultScenario(isEligibleForSurvey);
  };

  const fetchInitialData = async () => {
    if (isH5GOnboarding.value) return;
    //initial data
    await Promise.all([
      onboardingStore.fetchWordPressTemplates(),
      fetchAndPreloadWordPressThemes(),
      onboardingWordPressThemeStore.fetchWordPressThemes({
        subscriptionId,
      }),
      onboardingDCStore.fetchOnboardingDataCenters(subscriptionId),
    ]);
  };

  const fetchRequiredDataAndSetInitialStep = async () => {
    isLoadingOnboarding.value = true;

    if (isCreationSource000Webhost.value) {
      await onboardingSourceStore.fetchSourceListWithRetry();
    }

    if (canUsePresetsInOnboarding.value) {
      await hostingerProStore.fetchPresets();
    }

    const NON_SURVEY_NEEDED_SCENARIOS_HANDLER_MAP = {
      [ONBOARDING_SCENARIO.QUICK_INSTALL_MIGRATION]:
        handleQuickInstallMigrationScenario,
      [ONBOARDING_SCENARIO.QUICK_INSTALL_MIGRATION_ADDON]:
        handleQuickInstallMigrationAddonScenario,
      default: undefined,
    };
    const nonSurveyNeededScenarioHandler =
      onboardingScenario &&
      mapKeyValue(NON_SURVEY_NEEDED_SCENARIOS_HANDLER_MAP, onboardingScenario);

    if (nonSurveyNeededScenarioHandler) {
      await nonSurveyNeededScenarioHandler();
      onboardingStore.removeAllCompletedSteps();
      isLoadingOnboarding.value = false;

      return;
    }

    const isEligible = await getSurveyEligibility();
    const SURVEY_NEEDED_SCENARIOS_HANDLER_MAP = {
      [ONBOARDING_SCENARIO.QUICK_INSTALL_WORDPRESS_ADDON]:
        handleQuickInstallWordPressAddonScenario,
      [ONBOARDING_SCENARIO.QUICK_INSTALL_WORDPRESS_SETUP]:
        handleQuickInstallWordPressSetupScenario,
      [ONBOARDING_SCENARIO.QUICK_INSTALL_EMPTY_WEBSITE_ADDON]:
        handleQuickInstallEmptyWebsiteAddonScenario,
      [ONBOARDING_SCENARIO.QUICK_INSTALL_EMPTY_WEBSITE_SETUP]:
        handleQuickInstallEmptyWebsiteSetupScenario,
      [ONBOARDING_SCENARIO.SETUP_WORDPRESS_PRESELECTED]:
        handlePreselectedWordPressScenario,
      [ONBOARDING_SCENARIO.ADDON]: handleDefaultAddonScenario,
      default: handleDefaultScenario,
    };
    const surveyNeededScenarioHandler = mapKeyValue(
      SURVEY_NEEDED_SCENARIOS_HANDLER_MAP,
      onboardingScenario || 'default',
    );

    await surveyNeededScenarioHandler(isEligible);
    onboardingStore.setIsEligibleForSurvey(isEligible);
    onboardingStore.removeAllCompletedSteps();
    isLoadingOnboarding.value = false;
  };

  const getSurveyEligibility = async (): Promise<boolean> => {
    const response = await surveyRepo.getIsEligibleForSurvey(subscriptionId);

    const [{ data }] = response;

    return data?.isEligible || false;
  };

  const goToTemplatePreview = () => {
    const redirectRouteData = {
      name: ONBOARDING_STEPS.WORDPRESS_PREVIEW_ASTRA_TEMPLATE,
    };
    router.push(redirectRouteData);

    onboardingStore.addCompletedStep(onboardingStore.currentStep);

    onboardingStore.setStep(ONBOARDING_STEPS.WORDPRESS_PREVIEW_ASTRA_TEMPLATE);
  };

  const goToDomainRegistration = (
    domain: string,
    nextStep: OnboardingStep,
    { isClaimable = false }: { isClaimable?: boolean } = {},
  ) => {
    const redirectRouteData = {
      name: isH5GOnboarding.value
        ? H5G_ONBOARDING_STEPS.H5G_DOMAIN_REGISTRATION
        : ONBOARDING_STEPS.DOMAIN_REGISTRATION,
      params: { domain: toASCII(domain) },
      query: {
        redirect: nextStep,
        order_id: subscriptionId,
        isClaimable: isClaimable ? '1' : '0',
      },
    };

    router.push(redirectRouteData);
    onboardingStore.addCompletedStep(onboardingStore.currentStep);
    onboardingStore.setStep(nextStep);
  };

  const isAddonOnboarding = computed(() => onboardingStore.isAddon);

  const nextStepAfterPluginOrPresetSetup = computed(() => {
    const { wordPressPreviewAstraTemplate } = onboardingStore.state;

    const isPreBuiltWebsiteSelected = !!wordPressPreviewAstraTemplate?.id;

    const shouldRedirectToAIContentGeneration =
      subscriptionStore.getIsSubscriptionHostingPlanBusinessOrAbove(
        subscriptionId,
      ) && !isPreBuiltWebsiteSelected;

    if (shouldRedirectToAIContentGeneration) {
      return ONBOARDING_STEPS.WORDPRESS_AI_CONTENT_GENERATION;
    }

    return ONBOARDING_STEPS.DOMAIN;
  });

  return {
    subscriptionId,
    websiteTypeTitle,
    selectedPlatformByRedirect,
    isLoadingOnboarding,
    isMigrationSelected,
    hasSelectedWordPressAppearance,
    isCreationSource000Webhost,
    wordPressAppearanceType,
    is000WebhostMigration,
    isH5GOnboarding,
    isWordPressPlatformSelected,
    isBuilderPlatformSelected,
    goToTemplatePreview,
    fetchRequiredDataAndSetInitialStep,
    fetchInitialData,
    goToDomainRegistration,
    isAddonOnboarding,
    nextStepAfterPluginOrPresetSetup,
  };
};
