import type { RouteRecordRaw } from 'vue-router';

import proPanelRegistrationGuard from '@/guards/proPanelRegistrationGuard';
import { Route } from '@/types';

const isMobileScreen = window.innerWidth < 768;

const baseMetaData = {
  sideMenuFixed: !isMobileScreen,
  sideMenuExists: true,
  header: 'simple-header',
  isSideMenuFullHeight: true,
  sideMenuComponent: 'hostingerProSidebar',
};

const ProVpsRoutes = [
  {
    path: '/hostinger-pro/vps',
    name: Route.HostingerPro.PRO_VPS,
    component: () => import('@/views/HostingerPro/VpsList/VpsWrapper.vue'),
    children: [
      {
        path: '',
        name: Route.HostingerPro.PRO_VPS_LIST,
        meta: {
          title: 'VPS',
          ...baseMetaData,
        },
        beforeEnter: (to, from, next) =>
          proPanelRegistrationGuard(to, from, next),
        component: () => import('@/views/HostingerPro/VpsList/VpsList.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
export default ProVpsRoutes;
