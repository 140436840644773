import { sslRepo } from '@/repositories';
import {
  getStateVariable,
  setForDomain,
  getCurrentDomain,
} from '@/store/storeHelper';
import { toASCII } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';

const initial = {
  isLoading: false,
  sslDetails: {
    custom: [],
    lifetime: [],
    sslInstalled: [],
    comodo: [],
  },
  unlimitedSslEnabled: false,
  installedSslStatuses: [],
};

export default {
  state: {
    initial,
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_SSL_DETAILS(state, { requestDomain, data }) {
      data.lifetime.forEach((ssl, index) => {
        ssl.id = ssl.id || `free_ssl_${index}`;
        ssl.status = ssl.status || 'active';
      });
      data.comodo.forEach((ssl, index) => {
        ssl.id = ssl.id || `comodo_ssl_${index}`;
        ssl.status = ssl.status || 'active';
        ssl.expiresAt = ssl.expiresAt || ssl.sslInfo?.validity?.to;
      });
      data.custom.forEach((ssl, index) => {
        ssl.id = ssl.id || `custom_ssl_${index}`;
        ssl.status = ssl.status || 'active';
        ssl.expiresAt = ssl.expiresAt || ssl?.sslInfo?.validity?.to;
      });

      setForDomain(state, requestDomain, 'sslDetails', data);
    },
    SET_INSTALLED_SSL_STATUS(state, { requestDomain, sslId, isHttpsForced }) {
      let statuses = getStateVariable(state, 'installedSslStatuses');
      statuses = statuses.filter((item) => item.sslId !== sslId);

      setForDomain(state, requestDomain, 'installedSslStatuses', [
        ...statuses,
        {
          sslId,
          isHttpsForced,
        },
      ]);
    },
    RESET_INSTALLED_SSL_STATUSES(state, requestDomain) {
      setForDomain(state, requestDomain, 'installedSslStatuses', []);
    },
    SET_UNLIMITED_SSL_ENABLED(state, { requestDomain, isEnabled }) {
      setForDomain(state, requestDomain, 'unlimitedSslEnabled', isEnabled);
    },
    SET_IS_LOADING(state, { requestDomain, isLoading }) {
      setForDomain(state, requestDomain, 'isLoading', isLoading);
    },
  },
  getters: {
    getAdvancedSslsDetails: (state) => getStateVariable(state, 'sslDetails'),
    getAdvancedSslIsHttpsForced: (state) => (id) => {
      const statuses = getStateVariable(state, 'installedSslStatuses');

      return statuses.find(({ sslId }) => sslId === id)?.isHttpsForced;
    },
    getAdvancedInstalledSsls: (state) => {
      const details = getStateVariable(state, 'sslDetails');

      return [...details.custom, ...details.lifetime].filter(
        ({ sslManage }) => sslManage,
      );
    },
    getIsUnlimitedSslEnabled: (state) =>
      getStateVariable(state, 'unlimitedSslEnabled'),
    getAdvancedSslsDetailsIsLoading: (state) =>
      getStateVariable(state, 'isLoading'),
  },
  actions: {
    async advancedSslDetailsIndex({ dispatch, commit, getters }) {
      const domain = getCurrentDomain();
      try {
        commit('SET_IS_LOADING', {
          requestDomain: domain,
          isLoading: true,
        });
        const [{ data }] = await sslRepo.getSsls();
        commit('SET_SSL_DETAILS', {
          requestDomain: domain,
          data,
        });
        commit('RESET_INSTALLED_SSL_STATUSES', domain);

        const forceCheckCalls = getters.getAdvancedInstalledSsls.map((ssl) =>
          dispatch('forceHttpsStatusShow', {
            ssl,
          }),
        );

        await Promise.allSettled(forceCheckCalls);
      } catch (error) {
        errorLogger.logError(error);
      } finally {
        commit('SET_IS_LOADING', {
          requestDomain: domain,
          isLoading: false,
        });
      }
    },
    async advancedCustomSslStore(context, data) {
      return await sslRepo.setAdvancedCustomSsl(data);
    },
    async forceHttpsStatusShow({ commit }, { ssl }) {
      const [{ data }] = await sslRepo.getForceHttpsStatus({
        sslDomain: ssl.domain,
      });

      commit('SET_INSTALLED_SSL_STATUS', {
        requestDomain: getCurrentDomain(),
        sslId: ssl.id,
        isHttpsForced: data,
      });
    },
    async sslHttpsForceStore({ commit }, payload) {
      const [{ data }, err] = await sslRepo.setForceHttps(payload);

      commit('SET_INSTALLED_SSL_STATUS', {
        requestDomain: getCurrentDomain(),
        sslId: payload.id,
        isHttpsForced: true,
      });

      return [{ data }, err];
    },
    async sslHttpsForceDestroy({ commit }, payload) {
      const [{ data }, err] = await sslRepo.unsetForceHttps({
        sslDomain: toASCII(payload.sslDomain) || toASCII(payload.domain),
      });

      commit('SET_INSTALLED_SSL_STATUS', {
        requestDomain: getCurrentDomain(),
        sslId: payload.id,
        isHttpsForced: false,
      });

      return [{ data }, err];
    },
    async getUnlimitedSslState({ commit }) {
      const requestDomain = getCurrentDomain();
      const [{ data }] = await sslRepo.getIsUnlimitedEnabled();
      commit('SET_UNLIMITED_SSL_ENABLED', { requestDomain, isEnabled: data });
    },
  },
};
