<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';

import HCircleLoader from '@/components/HCircleLoader.vue';
import HModalActions from '@/components/HModals/HModalActions.vue';
import WebsiteMonitoringItem from '@/components/Profile/AccountInformation/WebsiteMonitoringItem.vue';
import { useGlobals, useModal, useWebsiteMonitoring } from '@/composables';
import { useWebsiteMonitoringStore } from '@/stores';
import type { IWebsiteMonitoring } from '@/types';
import { AmplitudeEvent, Route } from '@/types';
import { hToastrService as toastr } from '@/utils/services/hToastrService';

const { closeModal } = useModal();
const { t, amplitudeV2 } = useGlobals();
const router = useRouter();
const websiteMonitoringStore = useWebsiteMonitoringStore();
const { websites, isLoading } = storeToRefs(websiteMonitoringStore);
const {
  websitesToUpdate,
  onSelectWebsite,
  updateMonitoringValues,
  resetWebsitesToUpdate,
  isAllWebsitesBeingDisabled,
} = useWebsiteMonitoring();

const isUpdating = ref(false);

const websiteList = computed(() => {
  const list = websites.value || [];

  return list.map((website) => {
    const updatedWebsite = websitesToUpdate.value.find(
      ({ vhost }) => vhost === website.vhost,
    );

    if (updatedWebsite) return updatedWebsite;

    return website;
  });
});

const preselectedWebsitesList = computed(() =>
  (websites.value || []).map((website) => ({
    ...website,
    isMonitored: true,
  })),
);

const amplitudePayload = computed(() => ({
  selectedWebsites: websiteList.value.filter(({ isMonitored }) => isMonitored)
    .length,
  deselectedWebsites: websiteList.value.filter(
    ({ isMonitored }) => !isMonitored,
  ).length,
}));

const onCheckboxSelect = (website: IWebsiteMonitoring) => {
  onSelectWebsite(website);
};

const selectAllWebsites = () => {
  preselectedWebsitesList.value.forEach(onSelectWebsite);
};

const submitWebsiteMonitoring = async () => {
  isUpdating.value = true;
  const isSuccessful = await updateMonitoringValues();
  isUpdating.value = false;

  amplitudeV2(
    AmplitudeEvent.Hosting.AI_TROUBLESHOOTER_MONITORING_SAVE,
    amplitudePayload.value,
  );

  closeModal();

  if (isSuccessful && !isAllWebsitesBeingDisabled.value) {
    toastr.s(t('Email notifications were successfully enabled'), {
      text: t('Access and edit the setting at any time'),
      buttons: [
        {
          text: t('Settings'),
          props: {
            primary: true,
            text: true,
          },
          callback: () => {
            router.push({
              name: Route.Profile.PERSONAL_INFORMATION,
            });
          },
        },
      ],
    });
  }
  resetWebsitesToUpdate();
  await websiteMonitoringStore.fetchWebsitesMonitoringStatus();
};

const onSkip = () => {
  amplitudeV2(
    AmplitudeEvent.Hosting.AI_TROUBLESHOOTER_MONITORING_SKIP,
    amplitudePayload.value,
  );

  closeModal();
};

watch(websites, (websites) => {
  if (websites !== null) {
    selectAllWebsites();
  }
});

(async () => {
  websiteMonitoringStore.$reset();
  await websiteMonitoringStore.fetchWebsitesMonitoringStatus();
})();
</script>

<template>
  <div class="monitoring-modal">
    <p class="h-mb-24">
      {{
        t(
          'Recommended as a solution to manual website monitoring. Don’t miss any issues related to performance and security. You can always change this later.',
        )
      }}
    </p>
    <HCircleLoader
      v-if="isLoading"
      class="monitoring-modal__loader"
      size="medium"
    />
    <div v-else class="monitoring-modal__websites">
      <WebsiteMonitoringItem
        v-for="website in preselectedWebsitesList"
        :key="website.vhost"
        :website="website"
        @on-change="onCheckboxSelect"
      />
    </div>
    <HModalActions no-padding close-text="Skip" class="h-mt-24" @close="onSkip">
      <HButton
        :is-disabled="isLoading"
        :is-loading="isUpdating"
        @click="submitWebsiteMonitoring"
      >
        {{ t('Save changes') }}
      </HButton>
    </HModalActions>
  </div>
</template>

<style lang="scss" scoped>
.monitoring-modal {
  &__loader {
    margin: 8px auto;
  }
  &__websites {
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid var(--gray-border);
    max-height: 168px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 4px;
      margin: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--gray-border);
      border-radius: 4px;
      border: 4px solid rgba(0, 0, 0, 0);
    }
  }
}
</style>
