<!-- TODO: Use correct naming -->
<!-- This component is not necessarily used for Black Friday, but for all past sales -->
<!-- The most recent Black Friday banner is in components/Header/Banners/BlackFridayBanner -->
<!-- Keeping this component as it might be needed for other sales -->
<script setup lang="ts">
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import CountdownTimer from '@/components/CountdownTimer.vue';
import { useGlobals, useSaleCampaign } from '@/composables';
import { bannerData } from '@/data/sale/bannerData';
import { Route, AmplitudeEvent } from '@/types';

const store = useStore();
const route = useRoute();

const { t, amplitudeV2 } = useGlobals();

const {
  isTimerVisible,
  activeSale,
  redirectToOffer,
  isSaleOfferAvailable,
  timerEndDate,
} = useSaleCampaign();

const getOrdersLoaded = computed(() => store.getters.getOrdersLoaded);

const showInCurrentRoute = computed(
  () =>
    (route.name === Route.Base.HOME && store.getters.getInitialLoad) ||
    (route.name === Route.Base.HOSTING && getOrdersLoaded.value),
);

const saleData = computed(() =>
  bannerData.find(({ name }) => name === activeSale.value?.name),
);

const showBanner = computed(
  () =>
    !!(
      isSaleOfferAvailable.value &&
      showInCurrentRoute.value &&
      saleData.value
    ),
);

const style = computed(() => saleData.value?.style);

watch(
  () => route.name,
  (newVal, oldVal) => {
    if (newVal === oldVal || !showBanner.value) return;

    amplitudeV2(AmplitudeEvent.BlackFriday.BLACK_FRIDAY_BANNER_SHOWN);
  },
  { immediate: true },
);
</script>

<template>
  <div
    v-if="showBanner"
    class="sale-banner"
    :style="{
      backgroundImage: style?.backgroundImage,
    }"
    :class="[
      {
        'sale-banner--has-timer': isTimerVisible,
      },
    ]"
  >
    <div class="sale-banner__content">
      <div
        class="sale-banner__text"
        :class="{ 'sale-banner__text--wide': !isTimerVisible }"
      >
        <h1 v-trans class="h-mb-8 sale-banner__title">
          {{ saleData?.title }}
        </h1>
        <Trans
          v-if="saleData?.subtitle"
          class="text-body-2 sale-banner__subtitle"
        >
          {{ saleData.subtitle }}
        </Trans>
      </div>

      <div v-if="isTimerVisible" class="sale-banner__timer">
        <Trans class="h-mb-4 text-body-3 sale-banner__timer-text">
          Deal ends in:
        </Trans>
        <!-- @vue-ignore -->
        <CountdownTimer :date="timerEndDate" :font-colors="style.timer" />
      </div>
      <div class="sale-banner__action">
        <!-- @vue-ignore -->
        <HButton
          v-qa-generate
          :color="style.buttonColor"
          @click="redirectToOffer"
        >
          {{
            // @ts-ignore
            t(saleData.button)
          }}
        </HButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@mixin background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: top;
}
.sale-banner {
  min-height: 136px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 24px;
  align-items: center;
  padding: v-bind('style?.bannerPadding');
  background-color: v-bind('style?.backgroundColor');
  border: v-bind('style?.border');
  @include background;

  &__text,
  &__title,
  &__subtitle,
  &__timer-text {
    color: v-bind('style?.color');
  }

  &__content {
    display: flex;
    align-items: center;
    flex-basis: 50%;
    & > * {
      flex: 0 0 33%;
    }
  }

  &__text {
    min-width: 264px;
    height: 100%;
    &--wide {
      flex: 0 0 66%;
    }
  }

  &__timer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: -8px 8px -4px;
  }

  &__action {
    display: flex;
    justify-content: end;
    margin-left: -12px;
  }

  @media (max-width: 937px) {
    padding: 24px 12px;
    background-image: unset !important;
    &__content {
      flex-direction: column;
    }
    &__timer,
    &__action {
      margin-top: 24px;
    }
    &__text {
      text-align: center;
    }
  }
}
</style>
