import { objectEntries } from '@vueuse/shared';
import { ref, computed } from 'vue';

import type {
  WhoIsRegistrationErrors,
  WhoIsDetails,
  ResponseError,
} from '@/types';
import {
  getRegistroBrErrorText,
  openRegistroBrErrorModal,
} from '@/utils/helpers/domainsHelper';
import {
  snakeToCamel,
  snakeToCamelObj,
} from '@/utils/services/namingConventionsService';

const registrationErrors = ref<WhoIsRegistrationErrors>({});

export const useWhoIsRegistrationErrors = () => {
  const isError = computed(
    () => !!Object.keys(registrationErrors.value).length,
  );

  const handleRegistroBrError = () => {
    openRegistroBrErrorModal();
    registrationErrors.value.vatPersonBr = getRegistroBrErrorText();
    registrationErrors.value.vatCompanyBr = getRegistroBrErrorText();
  };

  const setBackendRegistrationErrors = ({
    error,
  }: {
    error: ResponseError;
  }) => {
    const validationMessages = error?.validationMessages || {};
    registrationErrors.value = { ...validationMessages };
  };

  const resetRegistrationErrors = () => (registrationErrors.value = {});

  const removeRegistrationError = (
    currentValues: WhoIsDetails,
    previousValues: WhoIsDetails,
  ) => {
    const changedValueKey =
      objectEntries(currentValues).find(
        ([key, value]) => value !== previousValues[key],
      )?.[0] || '';

    const changedValueKeyCamelCase = snakeToCamel(changedValueKey);

    delete registrationErrors.value[
      changedValueKeyCamelCase as keyof WhoIsRegistrationErrors
    ];
  };

  const getFieldError = (fieldName: string) => {
    const camelCaseFieldName = snakeToCamel(fieldName);
    const camelCaseErrorObject = snakeToCamelObj(registrationErrors.value);
    const text =
      camelCaseErrorObject[
        camelCaseFieldName as keyof typeof camelCaseErrorObject
      ];

    return text ? { text } : null;
  };

  return {
    isError,
    handleRegistroBrError,
    setBackendRegistrationErrors,
    resetRegistrationErrors,
    removeRegistrationError,
    registrationErrors,
    getFieldError,
  };
};
