<script lang="ts" setup>
import type { FieldSchema, FormValues } from '@hostinger/hcomponents';
import { computed, ref } from 'vue';

import HModalActions from '@/components/HModals/HModalActions.vue';
import { useGlobals, useModal } from '@/composables';
import { emailRepo, twoFactorRepo } from '@/repositories';
import { useProfileStore } from '@/stores';
import type { ModalEmits } from '@/types';
import { FieldType } from '@/types';

const FORM_SUBMIT_ID = 'verify-personal-information-change-form';

type Props = {
  data: {
    email?: string;
    submitData: {
      email?: string;
      [key: string]: string | undefined;
    };
  };
};

interface Emits extends ModalEmits {}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const { closeModal, goToNextStep, isFirstStep, isSecondStep } = useModal(emits);
const { toastr, t } = useGlobals();
const profileStore = useProfileStore();

const formValues = ref<{ currentPassword: string; twoFactorAuthCode?: string }>(
  { currentPassword: '' },
);

const isLoadingSubmit = ref(false);

const isEmailChange = computed(() => !!props.data.submitData.email);
const userCurrentEmail = computed(() => profileStore.contact?.email);

const confirmText = computed(() => {
  if (profileStore.settings?.twoFactorAuth.isEnabled && isFirstStep.value) {
    return t('Next');
  }

  if (isEmailChange.value) {
    return t('Send verification');
  }

  return t('Confirm');
});

const formSchema = computed(
  (): Record<'currentPassword' | 'twoFactorAuthCode', FieldSchema> => ({
    currentPassword: {
      type: FieldType.password,
      label: 'Password',
      validation: {
        required: true,
        simplePassword: true,
      },
      custom: { hideOptionalLabel: true },
    },
    twoFactorAuthCode: {
      type: FieldType.text,
      label: 'Enter code',
      custom: { hideOptionalLabel: true },
      validation: {
        required: true,
        twoFactorAuthentication: true,
      },
    },
  }),
);

const showSuccessMessage = () => {
  if (isEmailChange.value) {
    toastr.s(t('Verification email sent successfully'), {
      text: t('A verification email has been sent to {email}', {
        email: userCurrentEmail.value || props.data.submitData.email,
      }),
    });

    return;
  }

  toastr.s(t('Data has been updated successfully'));
};

const onSuccess = () => {
  showSuccessMessage();
  closeModal();
};

const updateProfile = async () => {
  if (isEmailChange.value) {
    const [, emailError] = await emailRepo.patchChangeInitiate({
      email: props.data.submitData.email || '',
      ...formValues.value,
    });

    if (emailError) {
      throw emailError;
    }

    return;
  }

  const [, updateError] = await profileStore.updateProfileData({
    ...props.data.submitData,
    ...formValues.value,
  });

  if (updateError) {
    throw updateError;
  }
};

const handleSubmitPasswordSubmit = async () => {
  if (profileStore.settings?.twoFactorAuth.isEnabled) {
    return goToNextStep(1);
  }

  try {
    isLoadingSubmit.value = true;

    await updateProfile();

    onSuccess();
  } catch {
    isLoadingSubmit.value = false;
  }
};

const handleTwoFactorSubmit = async () => {
  isLoadingSubmit.value = true;

  try {
    await updateProfile();

    onSuccess();
  } catch {
    isLoadingSubmit.value = false;
  }
};

const handleChange = ({ values }: FormValues) => {
  formValues.value = {
    ...formValues.value,
    ...values,
  };
};

if (profileStore.isTwoFactorMethodEmail) {
  twoFactorRepo.patchSendEmailCode();
}

if (!profileStore.account?.isPasswordSet) {
  goToNextStep(1);
}
</script>

<template>
  <HForm
    v-if="isFirstStep"
    :submit-id="FORM_SUBMIT_ID"
    @on-submit="handleSubmitPasswordSubmit"
    @on-change="handleChange($event)"
  >
    <HFormField name="currentPassword" :schema="formSchema.currentPassword" />
  </HForm>

  <HForm
    v-if="isSecondStep"
    :submit-id="FORM_SUBMIT_ID"
    @on-submit="handleTwoFactorSubmit"
    @on-change="handleChange($event)"
  >
    <HFormField
      name="twoFactorAuthCode"
      :schema="formSchema.twoFactorAuthCode"
    />
  </HForm>

  <HModalActions no-padding @close="closeModal">
    <HButton
      :id="FORM_SUBMIT_ID"
      v-qa-generate
      h-form-submit
      :is-loading="isLoadingSubmit"
    >
      {{ confirmText }}
    </HButton>
  </HModalActions>
</template>
