import { useAccessList } from '@/composables/useAccessList';
import { useProfileStore } from '@/stores';
import type { IHDomainResource } from '@/types';
import { stripClientIdToNumber } from '@/utils/helpers';

// This hook is used for managed domains only and does not include your own domains
export const useManagedDomains = () => {
  const profileStore = useProfileStore();

  const { getCustomerAccessObjectByClientId } = useAccessList();

  const getDomainOwnerEmail = (domainResource: IHDomainResource) => {
    if (!getIsManagedDomain(domainResource)) {
      return profileStore.contact?.email;
    }

    const clientId = domainResource.customerCustomId;
    const customerObject = getCustomerAccessObjectByClientId(clientId);

    return customerObject?.email;
  };

  const getIsManagedDomain = (domain: IHDomainResource) =>
    stripClientIdToNumber(profileStore.account?.id || '') !==
    stripClientIdToNumber(domain.customerCustomId);

  return {
    getDomainOwnerEmail,
    getIsManagedDomain,
  };
};
