import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import {
  useH5GDatabasesStore,
  useH5GFileManagerStore,
  useH5GWebsitesStore,
  useH5GWordPressStore,
} from '@/stores';
import { Route, type IH5GWebsite } from '@/types';
import { isPreviewDomain } from '@/utils/helpers/domainsHelper';

export const useH5GWebsite = (websiteUid?: string) => {
  const route = useRoute();
  const router = useRouter();
  const h5gFileManagerStore = useH5GFileManagerStore();
  const h5gWebsitesStore = useH5GWebsitesStore();
  const h5gWordPressStore = useH5GWordPressStore();
  const h5gDatabasesStore = useH5GDatabasesStore();

  const isPhpMyAdminUrlLoading = ref(false);
  const isAdminUrlLoading = ref(false);

  const h5gWebsiteUid = computed(
    () => websiteUid || (route.params.websiteUid as string),
  );

  const websiteDetails = computed(() =>
    h5gWebsitesStore.websitesList.data.find(
      (website: IH5GWebsite) => website.uid === h5gWebsiteUid.value,
    ),
  );

  const h5gWebsiteOrderId = computed(
    () => websiteDetails.value?.user?.order?.id,
  );

  const wordpressVersion = computed(
    () => websiteDetails.value?.flavor?.split('-')?.[1] || '-',
  );

  const domain = computed(() => websiteDetails.value?.domains?.[0]?.fqdn);

  const isPreviewDomainUsed = computed(() =>
    isPreviewDomain(domain.value || ''),
  );

  const isWebsiteCopyInProgress = computed(() =>
    websiteDetails.value?.processes?.some(
      (process) =>
        process.type === 'wordpress_duplication' &&
        process.status === 'running',
    ),
  );

  const getWebsiteDomainDetails = (selectedDomain?: string) => {
    if (!selectedDomain && !domain.value && !websiteDetails.value) {
      return;
    }

    return websiteDetails.value?.domains.find(
      ({ fqdn }) => fqdn === selectedDomain || fqdn === domain.value,
    );
  };

  const isDomainSslInstalled = (domain?: string) => {
    if (isPreviewDomainUsed.value) return true;

    const domainDetails = getWebsiteDomainDetails(domain);

    if (!domainDetails) return false;

    const { sslCert, customSslCert } = domainDetails;

    const isSslActive = sslCert && !sslCert?.expired;
    const isCustomSslActive = customSslCert && !customSslCert?.expired;

    return isSslActive || isCustomSslActive;
  };

  const openFileManager = async () => {
    if (h5gFileManagerStore.isFileManagerUrlLoading) return;

    await h5gFileManagerStore.fetchFileManagerUrl(h5gWebsiteUid.value);

    if (!h5gFileManagerStore.websiteFileManagerUrl) return;

    window.open(h5gFileManagerStore.websiteFileManagerUrl, '_blank');
  };

  const openWordPressAdmin = async () => {
    if (isAdminUrlLoading.value) return;

    isAdminUrlLoading.value = true;

    await h5gWordPressStore.fetchWordPressAdminUrl(h5gWebsiteUid.value);

    if (h5gWordPressStore.adminUrl) {
      window.open(h5gWordPressStore.adminUrl, '_blank');
    }

    isAdminUrlLoading.value = false;
  };

  const openDNSZoneEditor = () => {
    router.push({
      name: Route.Domain.DOMAIN_DNS,
      params: {
        domain: domain.value,
      },
    });
  };

  const openPhpMyAdmin = async (database?: string) => {
    if (isPhpMyAdminUrlLoading.value) return;

    isPhpMyAdminUrlLoading.value = true;

    await h5gDatabasesStore.fetchPhpMyAdminUrl(h5gWebsiteUid.value, database);

    if (h5gDatabasesStore.phpMyAdminUrl) {
      window.open(h5gDatabasesStore.phpMyAdminUrl, '_blank');
    }

    isPhpMyAdminUrlLoading.value = false;
  };

  const mappedH5GOrders = computed(() =>
    h5gWebsitesStore.ordersList.data.map((h5gOrder) => ({
      title: h5gOrder.plan.name,
      plan: h5gOrder.plan.name,
      isH5G: true,
      hasAccount: false,
      orderId: '',
      h5gOrderId: h5gOrder.id, // remove when order id is unique
      username: '',
      limits: {
        addons: {
          limit: 0,
          count: 0,
        },
      },
      status: h5gOrder.status,
      domain: '',
      websites: null,
    })),
  );

  return {
    domain,
    isPreviewDomainUsed,
    h5gWebsiteUid,
    h5gWebsiteOrderId,
    websiteDetails,
    wordpressVersion,
    isPhpMyAdminUrlLoading,
    isAdminUrlLoading,
    isWebsiteCopyInProgress,
    mappedH5GOrders,
    openFileManager,
    openWordPressAdmin,
    openDNSZoneEditor,
    openPhpMyAdmin,
    getWebsiteDomainDetails,
    isDomainSslInstalled,
  };
};
