import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import {
  useH5GOnboarding,
  useResourceFilters,
  useEmailServices,
  useGlobals,
} from '@/composables';
import {
  accountRepo,
  helpRepo,
  intercomChatbotProxyRepo,
} from '@/repositories';
import {
  useH5GWebsitesStore,
  useLanguageStore,
  useProfileStore,
  useResourcesStore,
  useVpsServerStore,
  useWebsitesStore,
} from '@/stores';
import {
  ALWAYS_VISIBLE_CHAT_BRAND_IDS,
  CRM_LINK_BASE,
  HelpPage,
  HRESOURCES_TYPE,
  IntercomTicketStates,
} from '@/types';
import type {
  AlwaysVisibleChatBrandId,
  IntercomOpenTicket,
  IntercomTicket,
  HelpPrecheckResource,
  HelpPrecheckVPSResource,
  HelpPrecheckMailboxResource,
  HelpPrecheckDomain,
  HelpBillingInvoice,
} from '@/types';
import { mapKeyValue, toUnicode } from '@/utils/helpers';

export const ID_DIVIDER_SYMBOL = '-';

export const useHelpStore = defineStore('helpStore', () => {
  const { activeHostingWebsites } = storeToRefs(useResourcesStore());
  const profileStore = useProfileStore();
  const languageStore = useLanguageStore();
  const h5gWebsitesStore = useH5GWebsitesStore();
  const resourcesStore = useResourcesStore();
  const vpsServerStore = useVpsServerStore();
  const emailServices = useEmailServices();
  const { isH5GFeatureEnabled } = useH5GOnboarding();
  const helpTickets = ref<IntercomOpenTicket[]>([]);
  const fullTopicId = ref<string | null>(null);
  const isPrecheckFailed = ref(false);
  const isPrecheckRunning = ref(false);
  const selectedHelpWebsite = ref<HelpPrecheckResource | null>(null);
  const selectedHelpDomain = ref<HelpPrecheckDomain | null>(null);
  const selectedHelpVPS = ref<HelpPrecheckVPSResource | null>(null);
  const selectedHelpMailbox = ref<HelpPrecheckMailboxResource | null>(null);
  const selectedHelpInvoice = ref<HelpBillingInvoice | null>(null);
  const intercomTickets = ref<IntercomTicket[] | null>(null);
  const isIntercomTicketsLoaded = ref(false);
  const isSubmittingTicket = ref(false);
  const { t } = useGlobals();

  const getSelectHelpWebsiteOptions = async (): Promise<
    HelpPrecheckResource[]
  > => {
    if (!resourcesStore.isLoaded) {
      await resourcesStore.fetchResources();
    }

    const finalOptions: HelpPrecheckResource[] = [];

    const hostingResources = resourcesStore.resources.filter(
      (resource) => resource.type === HRESOURCES_TYPE.HOSTING,
    );
    hostingResources.forEach((hostingResource) => {
      hostingResource.items.forEach((item) => {
        finalOptions.push({
          domainName: toUnicode(item.domain),
          resourceId: hostingResource.id?.toString(),
        });
      });
    });

    return finalOptions;
  };

  const getSelectHelpVPSOptions = async (): Promise<
    HelpPrecheckVPSResource[]
  > => {
    if (!vpsServerStore.servers.length) {
      await vpsServerStore.fetchServers();
    }

    return vpsServerStore.servers.map((server) => ({
      name: `${
        server.ipv4?.[0]?.address
          ? server.ipv4?.[0]?.address
          : t('Pending setup')
      } - ${server.hostname}`,
      vps: server,
    }));
  };

  const getSelectHelpMailboxOptions = async (): Promise<
    HelpPrecheckMailboxResource[]
  > => {
    const response = await emailServices.fetchData({});
    const emails = response.data;

    return emails.map((email) => ({ name: email.domain, email }));
  };

  const fetchHelpTickets = async () => {
    const [{ data }] = await helpRepo.getTickets();

    if (data) {
      helpTickets.value = data;
    }

    return helpTickets.value;
  };

  const fetchChatbotMetadata = async () => {
    if (!profileStore.account?.brand) {
      return;
    }

    if (!h5gWebsitesStore.ordersList.total && isH5GFeatureEnabled.value) {
      await h5gWebsitesStore.fetchH5GOrders();
    }

    const urlObj = new URL(window.location.href);
    urlObj.searchParams.delete('jwt');
    const urlWithoutJwt = urlObj.toString();

    const websiteStore = useWebsitesStore();
    await websiteStore.fetchWebsitesList();

    if (selectedHelpWebsite.value) {
      await websiteStore.fetchOrGetWebsiteByDomain(
        selectedHelpWebsite.value.domainName,
      );
      const websiteFromStore = websiteStore.websites.find(
        (website) =>
          website.resource.id.toString() ===
          selectedHelpWebsite.value?.resourceId,
      );

      let serverHostname: string | undefined;
      if (
        websiteFromStore?.username &&
        websiteFromStore?.resource?.referenceId
      ) {
        const [account] = await accountRepo.getAccountDetails({
          username: websiteFromStore.username,
          orderId: websiteFromStore.resource.referenceId,
        });
        serverHostname = account?.data?.server?.hostname;
      }

      selectedHelpWebsite.value = {
        ...selectedHelpWebsite.value,
        website: websiteFromStore,
        serverHostname,
      };
    }

    const metadata = {
      client_brand_country: profileStore.account?.brand.country,
      current_location_url: urlWithoutJwt,
      team_label: teamLabelByTopic.value,
      brand: profileStore.account.brand.id,
      selected_topic: topicId.value,
      selected_website: selectedHelpWebsite.value,
      selected_domain: selectedHelpDomain.value,
      selected_vps: selectedHelpVPS.value,
      selected_mailbox: selectedHelpMailbox.value,
      selected_invoice: selectedHelpInvoice.value,
      beta_user: !!h5gWebsitesStore.ordersList.total,
      language: {
        language: languageStore.currentLanguage,
        locale: languageStore.currentLocale,
      },
    };

    await intercomChatbotProxyRepo.initializeChatbotMetadata(metadata);
  };

  const setFullTopicId = (topicId: string | null) => {
    fullTopicId.value = topicId;
  };

  const setSelectedHelpWebsite = (resource: HelpPrecheckResource | null) => {
    selectedHelpWebsite.value = resource;
  };

  const setSelectedHelpDomain = (resource: HelpPrecheckDomain | null) => {
    selectedHelpDomain.value = resource;
  };

  const setSelectedHelpVPS = (resource: HelpPrecheckVPSResource | null) => {
    selectedHelpVPS.value = resource;
  };

  const setSelectedHelpMailbox = (
    resource: HelpPrecheckMailboxResource | null,
  ) => {
    selectedHelpMailbox.value = resource;
  };

  const isSelectedHelpWebsiteHosting = computed(
    () =>
      !!activeHostingWebsites.value.find(
        (website) => website.domain === selectedHelpWebsite.value?.domainName,
      ),
  );

  const topicId = computed(
    () => fullTopicId.value?.split(ID_DIVIDER_SYMBOL)[0] || '',
  );

  const subtopicId = computed(
    () => fullTopicId.value?.split(ID_DIVIDER_SYMBOL)[1] || '',
  );

  const clientIntercomSettings = computed(() => {
    const { hasActivePrioritySupport } = useResourceFilters();

    const clientInfo = profileStore.isAccessManager
      ? {
          name: `${profileStore.access?.manager?.firstName} ${
            profileStore.access?.manager?.lastName || ''
          }`,
          email: profileStore.access?.manager?.email || '',
          userId: profileStore.access?.manager?.id,
          crmLink: `${CRM_LINK_BASE}${profileStore.access?.manager?.id}`,
        }
      : {
          name: `${profileStore.contact?.firstName} ${
            profileStore.contact?.lastName || ''
          }`,
          email: profileStore.contact?.email || '',
          userId: profileStore.account?.id,
          crmLink: `${CRM_LINK_BASE}${profileStore.account?.id}`,
        };

    return {
      appId: profileStore.settings?.intercom.app.id,
      enabled: profileStore.settings?.intercom.app.isEnabled,
      data: {
        appId: profileStore.settings?.intercom.app.id,
        userHash: profileStore.settings?.intercom.user.hash,
        isPriority: hasActivePrioritySupport.value,
        createdAt: profileStore.account?.createdAt,
        reseller: profileStore.account?.brand.domain,
        isGoldenCustomer: profileStore.account?.isPro,
        chargebeeEnabled: profileStore.account?.isChargebee,
        isAccessManager: profileStore.isAccessManager,
        ...clientInfo,
      },
    };
  });

  const teamLabelByTopic = computed(() => {
    const accountTeamSubIds = [
      HelpPage.SubtopicId.INSTALL_APP,
      HelpPage.SubtopicId.ADD_DOMAIN,
      HelpPage.SubtopicId.BACKUP,
      HelpPage.SubtopicId.HOSTING_DETAILS,
      HelpPage.SubtopicId.RESTORE,
      HelpPage.SubtopicId.TRANSFER_WEBSITE,
    ] as const;
    const domainTeamSubIds = [HelpPage.SubtopicId.TRANSFER_DOMAIN] as const;

    if (
      topicId.value === HelpPage.TopicId.HOSTING_SETUP &&
      accountTeamSubIds.includes(subtopicId.value)
    ) {
      return HelpPage.TeamLabels.ACCOUNT;
    }

    if (
      topicId.value === HelpPage.TopicId.HOSTING_SETUP &&
      domainTeamSubIds.includes(subtopicId.value)
    ) {
      return HelpPage.TeamLabels.DOMAIN;
    }

    const TOPIC_ID_TO_TEAM_LABEL_MAP = {
      [HelpPage.TopicId.HOSTING_MANAGE]: HelpPage.TeamLabels.ACCOUNT,
      [HelpPage.TopicId.SSL]: HelpPage.TeamLabels.ACCOUNT,
      [HelpPage.TopicId.DOMAIN]: HelpPage.TeamLabels.DOMAIN,
      [HelpPage.TopicId.HOSTING_SETUP]: HelpPage.TeamLabels.WEBSITE,
      [HelpPage.TopicId.WEBSITE_ISSUES]: HelpPage.TeamLabels.WEBSITE,
      [HelpPage.TopicId.VPS]: HelpPage.TeamLabels.WEBSITE,
      [HelpPage.TopicId.BILLING]: HelpPage.TeamLabels.BILLING,
      [HelpPage.TopicId.EMAIL]: HelpPage.TeamLabels.EMAIL,
      [HelpPage.TopicId.WEBSITE_BUILDER]: HelpPage.TeamLabels.WEBSITE_BUILDER,
      default: '',
    };

    return mapKeyValue(TOPIC_ID_TO_TEAM_LABEL_MAP, topicId.value);
  });

  const isSupportMessageAlwaysVisible = computed(() => {
    const isAlwaysVisibleForBrand = ALWAYS_VISIBLE_CHAT_BRAND_IDS.includes(
      profileStore.brandId?.toString() as AlwaysVisibleChatBrandId,
    );

    return isAlwaysVisibleForBrand || profileStore.isPro;
  });

  const isOneOfSpecificTopics = computed(() => {
    const SPECIFIC_TOPICS = [
      'cant_access',
      '500_error',
      '503_error',
      '403_error',
    ];

    return (
      topicId.value === HelpPage.TopicId.WEBSITE_ISSUES &&
      SPECIFIC_TOPICS.includes(subtopicId.value)
    );
  });

  const fetchIntercomTickets = async () => {
    const [{ data }, err] = await intercomChatbotProxyRepo.getTickets();

    if (err) {
      return;
    }

    intercomTickets.value = data.sort(
      (a: IntercomTicket, b: IntercomTicket) => {
        if (a.ticketState === IntercomTicketStates.WAITING_ON_CUSTOMER) {
          return -1;
        }

        if (b.ticketState === IntercomTicketStates.WAITING_ON_CUSTOMER) {
          return 1;
        }

        if (a.ticketState === IntercomTicketStates.RESOLVED) {
          return 1;
        }

        if (b.ticketState === IntercomTicketStates.RESOLVED) {
          return -1;
        }

        return b.updatedAt - a.updatedAt;
      },
    );
    isIntercomTicketsLoaded.value = true;
  };

  const setSelectedHelpInvoice = (invoice: HelpBillingInvoice) => {
    selectedHelpInvoice.value = invoice;
  };

  const getExistingTicket = (
    domainName: string,
    ticketTypeId: number,
  ): IntercomTicket | null =>
    intercomTickets.value?.find(
      (ticket) =>
        ticket.type === ticketTypeId.toString() &&
        ticket.domainName === domainName &&
        ticket.ticketState !== IntercomTicketStates.RESOLVED,
    ) ?? null;

  return {
    clientIntercomSettings,
    isPrecheckFailed,
    isPrecheckRunning,
    isSupportMessageAlwaysVisible,
    isOneOfSpecificTopics,
    helpTickets,
    selectedHelpWebsite,
    selectedHelpDomain,
    setSelectedHelpWebsite,
    setSelectedHelpDomain,
    selectedHelpInvoice,
    setSelectedHelpInvoice,
    selectedHelpVPS,
    setSelectedHelpVPS,
    selectedHelpMailbox,
    setSelectedHelpMailbox,
    isSelectedHelpWebsiteHosting,
    topicId,
    fullTopicId,
    subtopicId,
    teamLabelByTopic,
    fetchHelpTickets,
    fetchChatbotMetadata,
    setFullTopicId,
    getSelectHelpWebsiteOptions,
    getSelectHelpVPSOptions,
    getSelectHelpMailboxOptions,
    fetchIntercomTickets,
    intercomTickets,
    isIntercomTicketsLoaded,
    getExistingTicket,
    isSubmittingTicket,
  };
});
