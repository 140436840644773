import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { hVpsRepo } from '@/repositories';
import { useVpsServerStore } from '@/stores';
import type { IDataCenter, ServerOS, VpsServer } from '@/types';
import { HVps, STORE_PERSISTENT_KEYS } from '@/types';

interface IOnboardingData {
  dataCenter: IDataCenter | null;
  osType: string;
  template: ServerOS | null;
  hostname: string;
  rootPassword: string;
  osPassword: string;
  sshKey: {
    name: string;
    sshKey: string;
  };
  vmId: string | number | null;
  setupInitiated: boolean;
  domain: string;
  installMonarx: boolean;
}

export const useVpsOnboardingStore = defineStore(
  'vpsOnboarding',
  () => {
    const serverStore = useVpsServerStore();

    const initialData = {
      dataCenter: null,
      osType: '',
      template: null,
      hostname: '',
      rootPassword: '',
      osPassword: '',
      sshKey: {
        name: '',
        sshKey: '',
      },
      vmId: null,
      setupInitiated: false,
      domain: '',
      installMonarx: false,
    };

    const orderId = ref('');
    const onboardingData = ref<IOnboardingData[]>([]);

    const domainToFind = (domain: string) =>
      onboardingData.value.findIndex(({ domain: d }) => d === domain);

    const updateExistingDomainState = (
      domainIndex: number,
      param: string,
      data: object,
    ) => {
      const domainState = onboardingData.value[domainIndex];
      onboardingData.value[domainIndex] = { ...domainState, [param]: data };
    };

    const createNewDomainState = (
      requestDomain: string,
      param: string,
      data: object,
    ) => {
      const newDomainState = {
        ...cloneDeep(initialData),
        domain: requestDomain,
        [param]: data,
      };
      onboardingData.value.push(newDomainState);
    };

    const setOnboardingData = (
      requestDomain: string,
      param: string,
      data: any,
    ) => {
      const domainIndex = domainToFind(requestDomain);

      if (domainIndex !== -1) {
        updateExistingDomainState(domainIndex, param, data);
      } else {
        createNewDomainState(requestDomain, param, data);
      }
    };

    const getOnboardingData = <T extends keyof IOnboardingData>(
      requestDomain: string,
      param?: T,
    ) => {
      const domainState = onboardingData.value.find(
        ({ domain }) => domain === requestDomain,
      );

      if (param) {
        return domainState ? domainState[param] : cloneDeep(initialData[param]);
      }

      return domainState || cloneDeep(initialData);
    };

    const setOrderId = (newOrderId: string) => {
      orderId.value = newOrderId;
    };

    const setState = (key: string, value: any) =>
      setOnboardingData(orderId.value, key, value);

    const currentOnboarding = computed(
      () => getOnboardingData(orderId.value) as IOnboardingData,
    );

    const isOnboardingCompleted = computed(() => {
      const vmState = serverStore.servers.find(
        ({ id }: VpsServer) => id === currentOnboarding.value.vmId,
      )?.state;

      return vmState === HVps.ServerState.RUNNING;
    });

    const serverSetup = async ({
      serverId,
      details,
    }: {
      serverId: string;
      details: object;
    }) => {
      const [{ data }, error] = await hVpsRepo.setup(serverId, details, {});

      if (error) {
        serverStore.setServerLock(Number(serverId), false);

        throw new Error(JSON.stringify(error));
      }

      serverStore.setServerLock(Number(serverId), true);
      serverStore.setUpdatingServer({
        serverId: Number(serverId),
        duration: data.template.installTime,
        isCreating: true,
      });
    };

    return {
      orderId,
      onboardingData,
      isOnboardingCompleted,
      currentOnboarding,
      setState,
      setOrderId,
      serverSetup,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.VPS_ONBOARDING },
  },
);
