<script lang="ts" setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue';

import HModal from '@/components/HModals/HModal.vue';
import HModalFancy from '@/components/HModals/HModalFancy.vue';
import HModalInputs from '@/components/HModals/HModalInputs.vue';
import { useModalsStore } from '@/stores';

const modalsStore = useModalsStore();

const currentComponent = ref();

const currentStep = computed(() => modalsStore.currentStep);
const currentData = computed(() => modalsStore.currentData);

const currentModal = computed(() => modalsStore.currentModal);

const stepsCount = computed(() => currentModal.value?.steps?.length);

const close = () => {
  onCloseCallback();
  modalsStore.closeModal();
};

const nextStep = (step: number) => {
  modalsStore.nextStep(step);
};

const previousStep = (step: number) => {
  modalsStore.previousStep(step);
};

const onCloseCallback = () => {
  currentData.value && currentData.value.onClose && currentData.value.onClose();
};

watch(
  () => currentModal.value,
  (newModal, oldModal) => {
    if (newModal?.name === oldModal?.name) return;
    if (newModal) {
      currentComponent.value = defineAsyncComponent(() =>
        newModal.subtype
          ? import(
              `@/components/Modals/${newModal.type}/${newModal.subtype}/${newModal.name}.vue`
            )
          : import(`@/components/Modals/${newModal.type}/${newModal.name}.vue`),
      );
    } else {
      currentComponent.value = undefined;
    }
  },
);
</script>

<template>
  <div v-if="currentModal">
    <transition name="fade">
      <HModal
        v-if="currentModal.type === 'HModal'"
        v-bind="{
          ...currentModal.props,
          currentStep: currentStep,
          stepsCount: stepsCount,
          showStepsIndicator: currentModal.showStepsIndicator,
          show: !!currentModal,
          modalClosingType:
            currentModal.closingType || modalsStore.currentModalClosingType,
        }"
        @close="close()"
        @previous-step="previousStep($event)"
      >
        <Component
          :is="currentComponent"
          :current-step="currentStep"
          :data="currentData"
          @close="close()"
          @next-step="nextStep($event)"
          @previous-step="previousStep($event)"
        />
      </HModal>
      <HModalFancy
        v-else-if="currentModal.type === 'HModalFancy'"
        v-bind="{
          ...currentModal.props,
          currentStep: currentStep,
          stepsCount: stepsCount,
          showStepsIndicator: currentModal.showStepsIndicator,
          show: !!currentModal,
          modalClosingType:
            currentModal.closingType || modalsStore.currentModalClosingType,
        }"
        @close="close()"
        @previous-step="previousStep($event)"
      >
        <Component
          :is="currentComponent"
          :current-step="currentStep"
          :data="currentData"
          @close="close()"
          @next-step="nextStep($event)"
          @previous-step="previousStep($event)"
        />
      </HModalFancy>
      <HModalInputs
        v-else
        v-bind="{
          ...currentModal.props,
          currentStep: currentStep,
          stepsCount: stepsCount,
          showStepsIndicator: currentModal.showStepsIndicator,
          show: !!currentModal,
          modalClosingType:
            currentModal.closingType || modalsStore.currentModalClosingType,
        }"
        @close="close()"
        @previous-step="previousStep($event)"
      >
        <Component
          :is="currentComponent"
          :current-step="currentStep"
          :data="currentData"
          @close="close()"
          @next-step="nextStep($event)"
          @previous-step="previousStep($event)"
        />
      </HModalInputs>
    </transition>
  </div>
</template>
