<template>
  <div>
    <template v-if="!payment.loading">
      <h3 class="h-mb-0" data-qa="renew-modal-title">
        {{ data.title }}
      </h3>
      <p class="h-mb-16" data-qa="renew-modal-subtitle">
        {{ data.subtitle }}
      </p>
    </template>
    <template v-if="!payment.loading">
      <div class="line-item">
        <div>
          <p v-trans>Subscription ID</p>
          <strong data-qa="renew-modal-subscription">
            {{ data.subscriptionId }}
          </strong>
        </div>

        <div>
          <p v-trans>Service</p>
          <div
            v-for="(service, index) in services"
            :key="index"
            data-qa="renew-modal-service"
          >
            {{ service }}
          </div>
        </div>
      </div>
      <HSnackbar v-if="data.warningMessage" variant="warning" class="h-mb-16">
        {{ data.warningMessage }}
      </HSnackbar>
    </template>
    <ModalSkeletonLoader v-if="isModalContentLoading" :period-count="1" />
    <template v-else>
      <CvcIFrame
        v-if="isCvcRefreshVisible"
        :data="cvcIframeData"
        @success="onCvcRefreshSuccess"
        @cancel="isCvcRefreshed = true"
      />
      <template v-else-if="!payment.loading">
        <PurchasePeriods
          v-if="prices.length"
          :pre-selected-period="data.itemPriceId"
          :prices="prices"
          :has-mailbox="isEmail"
          :is-domain-purchase="isDomainService"
          :is-yearly="isDomainService"
          :label-color="data.labelColor"
          :is-estimation-loading="estimation.loading"
          @change="handlePurchasePeriodChange"
        />
        <PurchaseDetails
          :is-payment-disabled="isPaymentDisabled"
          :pricing-details="pricingDetails"
          :loading="estimation.loading"
          :edit-loading="paymentEditLoading"
          :error="estimation.error"
          :total-tooltip-text="totalTooltipText"
          :payment-type="paymentType"
          is-renew
          has-coupon
          :credits-hint="
            $t(
              'Unused time will be converted to credits and deducted, including excess payments',
            )
          "
          :is-renewal-fee-shown="data.reactivate && isDomainService"
          @edit-payment="handleEditPayment"
        >
          <template #coupon>
            <PurchaseCoupon
              :loading="estimation.couponLoading"
              :error="estimation.couponError"
              :active-coupon="activeCoupon"
              @on-apply="onCouponApply"
              @on-remove="onCouponRemove"
            />
          </template>
        </PurchaseDetails>
        <PurchaseButtons
          :loading="
            estimation.loading || paymentEditLoading || cvcIframeData.isLoading
          "
          :error="estimation.error"
          :credit-pay="isCreditPay"
          :has-coupon="!!activeCoupon"
          @on-success="goToPayment"
        />
      </template>
      <PurchaseProcessing
        v-else
        :completed="payment.completed"
        :error="payment.error"
      />
    </template>
  </div>
</template>

<script>
import { watchEffect, ref, computed } from 'vue';
import { mapGetters } from 'vuex';

import ModalSkeletonLoader from '@/components/Loaders/SkeletonCompositions/ModalSkeletonLoader.vue';
import PurchaseCoupon from '@/components/Modals/Parts/Coupon/PurchaseCoupon.vue';
import CvcIFrame from '@/components/Modals/Parts/CvcIFrame.vue';
import PurchaseButtons from '@/components/Modals/Parts/PurchaseButtons.vue';
import PurchaseDetails from '@/components/Modals/Parts/PurchaseDetails.vue';
import PurchasePeriods from '@/components/Modals/Parts/PurchasePeriods.vue';
import PurchaseProcessing from '@/components/Modals/Parts/PurchaseProcessing.vue';
import {
  useSubscriptionRenewal,
  usePurchaseModal,
  useCVCRefresh,
  useSubscriptions,
  useLoading,
  useDomainPurchase,
  useModal,
} from '@/composables';
import { hBillingRepo } from '@/repositories';
import { useCatalogStore } from '@/stores';
import {
  Service,
  AmplitudeEvent,
  HBillingSubscriptionResourceType,
} from '@/types';
import { getItemIdFromItemPriceId, isDefined } from '@/utils/helpers';
import { showAutorenewalEnabledToastr } from '@/utils/helpers/domainsHelper';
import creditsMixin from '@/utils/mixins/hbilling/creditsMixin';
import modalsMixin from '@/utils/mixins/modalsMixin';
import newPurchaseMixin from '@/utils/mixins/newPurchaseMixin';

export default {
  mixins: [modalsMixin, newPurchaseMixin, creditsMixin],
  components: {
    PurchaseDetails,
    ModalSkeletonLoader,
    PurchaseProcessing,
    PurchaseButtons,
    PurchasePeriods,
    PurchaseCoupon,
    CvcIFrame,
  },
  async created() {
    const isRenewPurchaseAvailable = await this.checkIsRenewPurchaseAvailable();

    if (!isRenewPurchaseAvailable) {
      this.closeModal();

      return;
    }

    this.getPricingEstimate();
  },
  setup(props) {
    const {
      cvcIframeData,
      isCvcRefreshVisible,
      isCVCRefreshNeeded,
      getCvcIframe,
      isCvcRefreshed,
      addIpToWhitelist,
    } = useCVCRefresh();

    const { showEnableAutoRenewToastr } = useSubscriptionRenewal();

    const catalogStore = useCatalogStore();

    const { closeModal } = useModal();

    const {
      estimation,
      billingAddress,
      activeCoupon,
      setEstimationLoadingState,
      setEstimationErrorState,
      getCouponRequest,
    } = usePurchaseModal();
    const { checkCanDomainBeRenewed } = useDomainPurchase();

    const pricesLoader = useLoading();
    const prices = ref([]);
    const selectedItemPriceId = ref(props.data.itemPriceId);
    const isCheckingDomain = ref(false);

    const subscriptions = useSubscriptions();

    const subscription = computed(() =>
      subscriptions.getSubscriptionById(props.data.subscriptionId),
    );

    const renewalPriceIds = computed(() => {
      if (!subscription.value) {
        return [];
      }

      return subscription.value.renewPrices.filter((price) => price.enabled);
    });

    const isReactivationWithPeriods = computed(
      () =>
        props.data.reactivate && props.data.serviceType !== Service.Type.DOMAIN,
    );

    const isRenewal = computed(() => !props.data.reactivate);

    const isModalContentLoading = computed(
      () => pricesLoader.isLoading.value || isCheckingDomain.value,
    );

    const domain = computed(
      () =>
        subscription.value?.metaData?.domain ||
        subscription.value?.cfSubscriptionParams?.domain ||
        '',
    );

    const isDomainService = computed(
      () => props.data.serviceType === Service.Type.DOMAIN,
    );

    const isEmail = computed(() =>
      [
        HBillingSubscriptionResourceType.EMAIL,
        HBillingSubscriptionResourceType.GOOGLE_WORKSPACE,
        HBillingSubscriptionResourceType.TITAN_MAIL,
      ].includes(subscription.value?.resourceType),
    );

    const quantity = computed(() =>
      isEmail.value ? subscription.value?.items[0].quantity : 1,
    );

    const getTotalRenewalPriceFromRenewalPrices = (lockedPriceId) => {
      const renewalPricePerUnit = subscription.value?.renewPrices.find(
        ({ itemPriceId }) => itemPriceId === lockedPriceId,
      )?.price;

      if (isDefined(renewalPricePerUnit)) {
        return renewalPricePerUnit * quantity.value;
      }
    };

    watchEffect(async () => {
      if (isRenewal.value) {
        pricesLoader.startLoading();

        const catalogPrices = await Promise.all(
          renewalPriceIds.value
            .map(async ({ itemPriceId }) => {
              const priceFromCatalog = catalogStore.getPriceById(itemPriceId);

              if (!priceFromCatalog) {
                // Load archived catalog item
                const [{ data }, error] = await hBillingRepo.getItemByPrice(
                  itemPriceId,
                  {
                    includeArchived: 1,
                  },
                );

                if (data && !error) {
                  return data.prices.find((price) => price.id === itemPriceId);
                }
              }

              return priceFromCatalog;
            })
            .filter(Boolean), // Remove undefined values to prevent runtime errors
        );

        const lockedPriceId =
          subscription.value?.scheduledChanges?.items[0]?.itemPriceId ||
          subscription.value?.items[0]?.itemPriceId;

        const lockedRenewalPrice =
          subscription.value?.scheduledChanges?.renewalPrice ??
          getTotalRenewalPriceFromRenewalPrices(lockedPriceId) ??
          subscription.value?.renewalPrice;

        const pricingWithLockedPrice = catalogPrices.map((price) => {
          if (isDefined(lockedRenewalPrice) && price.id === lockedPriceId) {
            const quantityAdjustedPrice = lockedRenewalPrice / quantity.value;

            return {
              ...price,
              price: quantityAdjustedPrice,
              basePrice: quantityAdjustedPrice,
            };
          }

          return price;
        });

        pricesLoader.stopLoading();

        prices.value = pricingWithLockedPrice;

        return;
      }

      if (isReactivationWithPeriods.value) {
        pricesLoader.startLoading();
        const queryParams = props.data.itemPriceId.includes('cpanelreseller')
          ? { includeArchived: 1 } // include price options that are not offered anymore but still available for reactivation
          : {};

        const [{ data }, err] = await hBillingRepo.getItemByPrice(
          props.data.itemPriceId,
          queryParams,
        );

        pricesLoader.stopLoading();

        if (data && !err) {
          const subscription = subscriptions.getSubscriptionById(
            props.data.subscriptionId,
          );

          const renewalPriceForCurrentPeriod = subscription?.renewPrices.find(
            ({ itemPriceId }) => itemPriceId === props.data.itemPriceId,
          );

          // renewal price for current period must be taken from subscription
          const adjustedPrices = data.prices.map((price) => {
            if (
              renewalPriceForCurrentPeriod &&
              props.data.itemPriceId === price.id
            ) {
              return {
                ...price,
                price: renewalPriceForCurrentPeriod.price,
              };
            }

            return price;
          });

          prices.value = adjustedPrices;

          return;
        }
      }

      prices.value = [];
    });

    const checkDomainRenewability = async () => {
      if (!domain.value) {
        return false;
      }

      isCheckingDomain.value = true;

      const canBeRenewed = await checkCanDomainBeRenewed(domain.value);

      isCheckingDomain.value = false;

      return canBeRenewed;
    };

    const estimateReactivation = async (request) =>
      await hBillingRepo.estimateReactivation(request.subscriptionId, request, {
        hideToastr: true,
      });

    return {
      isModalContentLoading,
      pricesLoader,
      prices,
      selectedItemPriceId,
      activeCoupon,
      catalogStore,
      getCouponRequest,
      estimateReactivation,
      showEnableAutoRenewToastr,
      estimation,
      billingAddress,
      setEstimationLoadingState,
      setEstimationErrorState,
      isCvcRefreshVisible,
      isCVCRefreshNeeded,
      getCvcIframe,
      cvcIframeData,
      isCvcRefreshed,
      addIpToWhitelist,
      Service,
      isDomainService,
      isEmail,
      checkDomainRenewability,
      closeModal,
    };
  },
  data: () => ({
    pricingDetails: {},
    loadedEstimations: [],
  }),
  methods: {
    async checkIsRenewPurchaseAvailable() {
      if (!this.isDomainService || this.data.reactivate) return true;

      return await this.checkDomainRenewability();
    },
    async onCouponApply(coupon) {
      const success = await this.getPricingEstimate(coupon);
      if (!success) return;

      this.activeCoupon = coupon;
    },
    async onCouponRemove() {
      this.activeCoupon = '';
      this.getPricingEstimate();
    },
    async retryAfterCouponError() {
      this.activeCoupon = '';
      this.estimation.couponError =
        'The coupon entered cannot be used for the selected period';
      this.getPricingEstimate(null, true);
    },
    async goToPayment() {
      if (this.isCVCRefreshNeeded) {
        this.isCvcRefreshed = false;
        await this.getCvcIframe();

        return;
      }

      await this.paymentCall();
    },
    onCvcRefreshSuccess() {
      this.isCvcRefreshed = true;
      this.paymentCall();
    },
    async fireOfferChosenAmplitude() {
      await this.$amplitudeV2(AmplitudeEvent.Billing.RENEW_OFFER_CHOSEN, {
        serviceArray: this.services,
        mainService: this.getItemName(this.data.itemId),
        itemId: this.data.itemId,
        source: this.data.amplitudeSource,
      });
    },
    async handleEditPayment() {
      if (this.isPaymentDisabled) {
        return;
      }
      await this.fireOfferChosenAmplitude();
      this.editPayment(
        { ...this.billingRequest, methodId: undefined },
        this.paymentType,
      );
    },
    async paymentCall() {
      await this.fireOfferChosenAmplitude();

      const [, error] = await this.completePayment(
        this.billingRequest,
        this.paymentType,
      );

      if (
        this.data.onSuccess &&
        !this.payment.error &&
        (this.paymentMethod || this.isCreditPay) &&
        this.payment.completed &&
        !error
      ) {
        this.addIpToWhitelist();

        this.data.onSuccess();

        if (this.paymentType === 'reactivate' && this.hasActivePaymentMethods) {
          showAutorenewalEnabledToastr(this.data.service);

          return;
        }

        this.showEnableAutoRenewToastr({
          subscriptionId: this.billingRequest.subscriptionId,
        });
      }
    },
    async getPricingEstimate(coupon, retryAfterCouponError) {
      const activeCoupon = coupon || this.activeCoupon;
      const subscriptionId = this.data.subscriptionId;
      const currentId = `${this.selectedItemPriceId}${subscriptionId}`;

      this.setEstimationLoadingState(true, coupon);
      if (!retryAfterCouponError) this.setEstimationErrorState(false, coupon);

      const alreadyLoadedEstimation = this.loadedEstimations.find(
        ({ id, coupon }) => id === currentId && coupon === activeCoupon,
      );

      if (alreadyLoadedEstimation) {
        this.pricingDetails = alreadyLoadedEstimation.pricing;
        this.setEstimationLoadingState(false, coupon);

        return true;
      }

      const requestBase = {
        subscriptionId,
        ...this.getCouponRequest(activeCoupon),
      };

      const request = this.data.isChargeType
        ? { ...requestBase, chargeItemPriceId: this.selectedItemPriceId }
        : { ...requestBase, itemPriceId: this.selectedItemPriceId };

      const [{ data }, err] = this.data.reactivate
        ? await this.estimateReactivation(request)
        : await hBillingRepo.estimatePlanRenew(request, true);

      if (err) {
        if (this.activeCoupon) {
          this.retryAfterCouponError();

          return;
        }

        this.setEstimationLoadingState(false, coupon);
        this.setEstimationErrorState(true, coupon, err);

        return;
      }

      this.pricingDetails = data;

      this.loadedEstimations.push({
        id: currentId,
        pricing: this.pricingDetails,
        coupon: activeCoupon,
      });

      this.setEstimationLoadingState(false, coupon);

      this.$amplitudeV2(AmplitudeEvent.Billing.RENEW_OFFER_SHOWN, {
        serviceArray: this.services,
        mainService: this.getItemName(this.data.itemId),
        itemId: this.data.itemId,
        source: this.data.amplitudeSource,
        location: this.data.amplitudeLocation,
      });

      return true;
    },
    isIcannLineItem(item) {
      if (!item) return false;

      return item.split('-').includes('icann');
    },
    getItemName(itemId) {
      return this.catalogStore.getCatalogItemById(itemId)?.externalName;
    },
    handlePurchasePeriodChange(period) {
      if (period?.id && this.selectedItemPriceId !== period?.id) {
        this.selectedItemPriceId = period?.id;
        this.getPricingEstimate();
      }
    },
  },
  computed: {
    isPaymentDisabled() {
      return this.$permissions.paymentDisabled() || this.estimation.loading;
    },
    paymentType() {
      return this.data.reactivate ? 'reactivate' : 'renew';
    },
    totalTooltipText() {
      return this.data.totalTooltipText || '';
    },
    billingRequest() {
      const baseRequest = {
        subscriptionId: this.data.subscriptionId,
        redirectReturn: this.data.redirect,
        redirectCancel: this.data.redirect,
        ...this.getCouponRequest(this.activeCoupon),
        transactionDetails: {
          metadata: {
            cvcRefreshRequired: this.isCvcRefreshed,
          },
        },
      };

      return {
        ...baseRequest,
        ...(this.paymentMethod ? { methodId: this.paymentMethod.id } : {}),
        ...(this.data.isChargeType
          ? { chargeItemPriceId: this.selectedItemPriceId }
          : { itemPriceId: this.selectedItemPriceId }),
      };
    },

    services() {
      return (
        this.pricingDetails?.items
          ?.filter((service) => !this.isIcannLineItem(service.product))
          ?.map((service) =>
            this.getItemName(getItemIdFromItemPriceId(service.product)),
          ) || []
      );
    },
    ...mapGetters('paymentMethods', ['hasActivePaymentMethods']),
  },
};
</script>

<style lang="scss" scoped>
.line-item {
  display: grid;
  grid-template-columns: minmax(auto, 1fr) minmax(auto, 1fr);
  margin: 8px 0;
  grid-gap: 8px;
  > div:not(:last-of-type) {
    padding-right: 8px;
  }
  @media (max-width: 700px) {
    display: block;
    > div {
      margin: 8px 0;
      padding-right: 0;
    }
  }
}
</style>
