<template>
  <div v-if="date" class="timer">
    <div class="timer__value">
      <div class="timer__card" :class="customCardClass">
        {{ days }}
      </div>
      <Trans v-if="displayTimeLabels" :class="labelClass" class="text-body-3">
        v2.days
      </Trans>
    </div>
    <span :class="customSeparatorClass">:</span>
    <div class="timer__value">
      <div class="timer__card" :class="customCardClass">
        {{ hours }}
      </div>
      <Trans v-if="displayTimeLabels" :class="labelClass" class="text-body-3">
        v2.hours
      </Trans>
    </div>
    <span :class="customSeparatorClass">:</span>
    <div class="timer__value">
      <div class="timer__card" :class="customCardClass">
        {{ minutes }}
      </div>
      <Trans v-if="displayTimeLabels" :class="labelClass" class="text-body-3">
        v2.minutes
      </Trans>
    </div>
    <span :class="customSeparatorClass">:</span>
    <div class="timer__value">
      <div class="timer__card" :class="customCardClass">
        {{ seconds }}
      </div>
      <Trans v-if="displayTimeLabels" :class="labelClass" class="text-body-3">
        v2.seconds
      </Trans>
    </div>
  </div>
</template>

<script>
// JSON-slugs-migrated
export default {
  props: {
    date: Number,
    offset: { type: Number, default: 0 },
    theme: {
      type: String,
      default: 'light',
    },
    displayTimeLabels: { type: Boolean, default: true },
    fontColors: {
      type: Object,
      default: () => ({ field: 'dark', label: 'light' }),
    },
    cardClass: String,
    separatorClass: String,
  },
  data() {
    return {
      now: Math.trunc((Date.now() + this.offset) / 1000),
    };
  },
  mounted() {
    window.setInterval(() => {
      this.now = Math.trunc((Date.now() + this.offset) / 1000);
    }, 1000);
  },
  computed: {
    customSeparatorClass() {
      const separatorColorClass =
        `timer__separator timer__separator--${this.fontColors.label}` ||
        `timer__separator timer__separator--${this.theme}`;

      return [separatorColorClass, this.separatorClass];
    },

    customCardClass() {
      return [
        `timer__card--${this.fontColors.theme || this.theme}`,
        this.cardClass,
      ];
    },

    labelClass() {
      return (
        `timer__label--${this.fontColors.label}` ||
        `timer__label--${this.theme}`
      );
    },
    dateInMs() {
      return Math.trunc(this.date / 1000);
    },
    seconds() {
      const seconds = (this.dateInMs - this.now) % 60;

      return this.formatValue(seconds);
    },
    minutes() {
      const minutes = Math.trunc((this.dateInMs - this.now) / 60) % 60;

      return this.formatValue(minutes);
    },
    hours() {
      const hours = Math.trunc((this.dateInMs - this.now) / 60 / 60) % 24;

      return this.formatValue(hours);
    },
    days() {
      const days = Math.trunc((this.dateInMs - this.now) / 60 / 60 / 24);

      return this.formatValue(days);
    },
  },
  methods: {
    formatValue(value) {
      if (value < 0) return '00';
      if (value.toString().length <= 1) return `0${value}`;

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.timer {
  display: flex;

  & > span {
    margin: 16px 4px 0;
    font-size: 18px;
    line-height: 24px;
    user-select: none;
  }

  &__value {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;

    div:not(.timer__card) {
      color: var(--meteorite-dark);
    }
  }

  &__card {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    width: 56px;
    height: 56px;
    padding: 15px 12px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    &--light {
      background: var(--light);
    }

    &--dark {
      background: rgba(255, 255, 255, 0.1);
      color: var(--light);
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    &--primary-chart {
      background: var(--primary-chart);
      color: var(--light);
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    &--primary-light {
      background: var(--primary-timer);
      color: var(--light);
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
    &--dark-light {
      background: var(--dark);
      color: var(--light);
    }
    &--black-light {
      background: var(--black-timer);
      color: var(--light);
      border: 1px solid rgba(255, 255, 255, 0.1);
    }

    &--meteorite-dark {
      background: var(--light);
      color: var(--meteorite-dark);
      border: 1px solid var(--meteorite-dark);
    }

    &--meteorite-light {
      background: rgba(179, 158, 243, 0.3);
      box-shadow: none;
      color: var(--meteorite-dark);
    }

    &--white-blue {
      background: var(--white-blue);
      color: var(--meteorite-dark);
    }

    &--light-meteorite {
      background: var(--light);
      color: var(--meteorite-dark);
    }

    &--light-meteorite-dark {
      background: #dacffb;
      color: var(--meteorite-dark);
    }

    &--light-blue {
      background: rgba(255, 255, 255, 0.2);
      color: var(--light);
    }

    &--primary-dark {
      border: 1px solid var(--light);
      background: var(--primary-dark);
      color: var(--light);
    }

    &--primary-dark-no-border {
      background: var(--primary-dark);
      color: var(--light);
    }

    &--primary {
      background: var(--primary);
      color: var(--light);
      border: 1px solid var(--light);
    }

    &--primary-charts {
      background: var(--primary-charts);
      color: var(--light);
    }

    &--meteorite-base-light-bordered {
      background: #8c85ff;
      color: var(--light);
      border: 1px solid var(--light);
    }
  }

  &__separator--primary {
    color: var(--primary) !important;
  }

  &__separator--secondary {
    color: var(--secondary) !important;
  }

  &__separator--success {
    color: var(--success) !important;
  }

  &__separator--info {
    color: var(--primary-hostinger) !important;
  }

  &__separator--warning {
    color: var(--warning) !important;
  }

  &__separator--warning-regular {
    color: var(--warning-regular) !important;
  }

  &__separator--danger {
    color: var(--danger) !important;
  }

  &__separator--light {
    color: var(--light) !important;
  }

  &__separator--dark {
    color: var(--dark) !important;
  }

  &__separator--black {
    color: var(--dark) !important;
  }

  &__separator--gray {
    color: var(--gray) !important;
  }

  &__separator--gray-light {
    color: var(--gray-light) !important;
  }

  &__separator--header-bg {
    color: var(--header-bg) !important;
  }

  &__separator--danger-light {
    color: var(--danger-light) !important;
  }

  &__separator--success-dark {
    color: var(--success-dark) !important;
  }

  &__separator--success-light {
    color: var(--success-light) !important;
  }

  &__separator--warning-light {
    color: var(--warning-light) !important;
  }

  &__separator--warning-dark {
    color: var(--warning-dark) !important;
  }

  &__separator--meteorite-dark {
    color: var(--meteorite-dark) !important;
  }

  &__label--primary {
    color: var(--primary) !important;
  }

  &__label--secondary {
    color: var(--secondary) !important;
  }

  &__label--success {
    color: var(--success) !important;
  }

  &__label--info {
    color: var(--primary-hostinger) !important;
  }

  &__label--warning {
    color: var(--warning) !important;
  }

  &__label--warning-regular {
    color: var(--warning-regular) !important;
  }

  &__label--dark-blue {
    color: var(--dark-blue) !important;
  }

  &__label--danger {
    color: var(--danger) !important;
  }

  &__label--light {
    color: var(--light) !important;
  }

  &__label--dark {
    color: var(--dark) !important;
  }

  &__label--black {
    color: var(--dark) !important;
  }

  &__label--gray {
    color: var(--gray) !important;
  }

  &__label--gray-light {
    color: var(--gray-light) !important;
  }

  &__label--header-bg {
    color: var(--header-bg) !important;
  }

  &__label--danger-light {
    color: var(--danger-light) !important;
  }

  &__label--success-dark {
    color: var(--success-dark) !important;
  }

  &__label--success-light {
    color: var(--success-light) !important;
  }

  &__label--warning-light {
    color: var(--warning-light) !important;
  }

  &__label--warning-dark {
    color: var(--warning-dark) !important;
  }

  &__label--meteorite-dark {
    color: var(--meteorite-dark) !important;
  }
}
</style>
