import type { NavigationGuard } from 'vue-router';

import { useMenu, useResourceFilters, useResourceMapper } from '@/composables';
import store from '@/store';
import { useFrontendSettingsStore, useWebsitesStore } from '@/stores';
import { Route } from '@/types';

export const websitesGuard: NavigationGuard = async (to, from, next) => {
  if (store.state.redirect.params.skipGuard) return next();

  const { getMenuItemById } = useMenu();
  const websiteStore = useWebsitesStore();
  const frontendSettingsStore = useFrontendSettingsStore();

  const { isP2PUser } = useResourceFilters();
  const {
    getResourcesByTypesAndStates,
    isSubscriptionsAndResourcesLoaded,
    fetchResourcesAndSubscriptions,
  } = useResourceMapper();

  if (!getMenuItemById('websites')) return next(Route.Base.HOME);

  const promises = [];

  if (websiteStore.hasAtLeastOneWebsite === null) {
    promises.push(websiteStore.fetchOneWebsite());
  }

  if (!isSubscriptionsAndResourcesLoaded.value) {
    promises.push(fetchResourcesAndSubscriptions());
  }

  if (promises.length) {
    frontendSettingsStore.setState('pageLoading', true);
    await Promise.all(promises);
    frontendSettingsStore.setState('pageLoading', false);
  }

  const hasManageableHosting = !!getResourcesByTypesAndStates({
    types: ['hosting'],
    states: ['active', 'pending_setup', 'updating'],
  }).length;

  if (
    !hasManageableHosting &&
    !websiteStore.hasAtLeastOneWebsite &&
    !isP2PUser.value
  ) {
    return next({ name: Route.Base.BUY_HOSTING });
  }

  next();
};
