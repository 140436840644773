import type { ModalObject } from '@/types';

export default [
  {
    name: 'RemoveClientModal',
    type: 'HModal',
    subtype: 'HostingerPro',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'DeletePresetModal',
    type: 'HModal',
    subtype: 'HostingerPro',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'OverviewCommissionModal',
    type: 'HModal',
    subtype: 'HostingerPro',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'ProHostingPurchaseModal',
    type: 'HModal',
    subtype: 'HostingerPro',
    steps: [
      {
        hideX: true,
      },
    ],
  },

  {
    name: 'EditWebsitesListModal',
    type: 'HModal',
    subtype: 'HostingerPro',
    steps: [{ hideX: true }],
  },
  {
    name: 'WebsitesUpdateModal',
    type: 'HModal',
    subtype: 'HostingerPro',
    steps: [
      {
        hideX: true,
      },
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'ProAddPaymentMethodModal',
    type: 'HModal',
    subtype: 'HostingerPro',
    steps: [
      {
        hideX: true,
      },
    ],
  },

  {
    name: 'ProPlanSelectionModal',
    type: 'HModal',
    subtype: 'HostingerPro',
    steps: [
      {
        title: 'Select a Hosting Plan',
        subtitle:
          'Select to which hosting plan you would like to add a new website',
        persistent: true,
        hideX: true,
      },
    ],
  },
  {
    name: 'WordpressPluginModal',
    type: 'HModal',
    subtype: 'HostingerPro',
    steps: [
      {
        hideX: true,
      },
    ],
  },
  {
    name: 'ProPanelMergeNotificationModal',
    type: 'HModal',
    subtype: 'HostingerPro',
    closingType: 'input',
    steps: [
      {
        title: 'All Pro Panel features are moving to hPanel',
        hideX: true,
      },
    ],
  },
] as ModalObject[];
