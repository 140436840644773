import { titanDomainSelectGuard } from '@/guards/emails/titanDomainSelectGuard';
import { Route } from '@/types';

export default [
  {
    path: '/titan-email-onboarding/:orderId/:resourceId?',
    name: Route.Email.TITAN_ONBOARDING_START,
    meta: {
      title: "Let's Go",
      showSubheader: false,
      fullscreen: true,
      isOnboarding: true,
      header: 'onboarding',
    },
    redirect: () => ({
      name: Route.Email.TITAN_ONBOARDING_DOMAIN_SELECT,
    }),
    component: () =>
      import('@/views/Emails/TitanEmails/TitanOnboardingWrapper.vue'),
    children: [
      {
        path: 'domain-select',
        name: Route.Email.TITAN_ONBOARDING_DOMAIN_SELECT,
        meta: {
          headerBackButton: false,
          fullscreen: true,
          isOnboarding: true,
          showSubheader: false,
          breadcrumbs: false,
        },
        beforeEnter: titanDomainSelectGuard,
        component: () =>
          import('@/views/Emails/TitanEmails/TitanOnboardingDomainSelect.vue'),
      },
      {
        path: 'domain-buy',
        name: Route.Email.TITAN_ONBOARDING_DOMAIN_BUY,
        meta: {
          title: 'Buy a Domain',
          headerBackButton: true,
          fullscreen: true,
          isOnboarding: true,
          showSubheader: false,
          breadcrumbs: false,
          backRoute: Route.Email.TITAN_ONBOARDING_DOMAIN_SELECT,
        },
        beforeEnter: titanDomainSelectGuard,
        component: () =>
          import('@/views/Emails/TitanEmails/TitanOnboardingDomainBuy.vue'),
      },
      {
        path: 'existing-domain',
        name: Route.Email.TITAN_ONBOARDING_DOMAIN_EXISTING,
        meta: {
          title: 'Use an Existing Domain',
          headerBackButton: true,
          isSubtitleVisible: false,
          isExistingDomain: true,
          fullscreen: true,
          isOnboarding: true,
          showSubheader: false,
          breadcrumbs: false,
          backRoute: Route.Email.TITAN_ONBOARDING_DOMAIN_SELECT,
        },
        beforeEnter: titanDomainSelectGuard,
        component: () =>
          import('@/views/Emails/TitanEmails/TitanOnboardingDomainBuy.vue'),
      },
      {
        path: 'finish/:domain',
        name: Route.Email.TITAN_ONBOARDING_FINISH,
        meta: {
          headerBackButton: false,
          fullscreen: true,
          isOnboarding: true,
          showSubheader: false,
          breadcrumbs: false,
        },
        beforeEnter: titanDomainSelectGuard,
        component: () =>
          import('@/views/Emails/TitanEmails/TitanOnboardingFinish.vue'),
      },
    ],
  },
];
