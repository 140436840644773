export const HOTSPOT_PLACEMENT = {
  TOP: 'top',
  RIGHT: 'right',
  BOTTOM: 'bottom',
  LEFT: 'left',
} as const;

export const HOTSPOT = {
  PRO_WEBSITES_LIST_FILTER: 'proWebsitesListFilter',
  ACCESS_LIST_PRO_PANEL: 'accessListProPanel',
  MARKETPLACE_HOTSPOT: 'marketplaceHotspot',
  TWO_FACTOR_HOTSPOT: 'twoFactorHotspot',
  TWO_FACTOR_HOTSPOT_LEADING: 'twoFactorLeadingHotspot',
  VC_TERMINAL_HOTSPOT: 'vcTerminalHotspot',
  NO_CACHE_HOTSPOT: 'noCacheHotspot',
  DOMAIN_FROM_DESCRIPTION_HOTSPOT: 'domainFromDescriptionHotspot',
  WEBSITES_FAVOURITES_HOTSPOT: 'websitesFavouritesHotspot',
  NAVIGATION_HOTSPOT: 'navigationHotspot',
} as const;

export type HotspotPlacement =
  (typeof HOTSPOT_PLACEMENT)[keyof typeof HOTSPOT_PLACEMENT];

export type HotspotId = (typeof HOTSPOT)[keyof typeof HOTSPOT];

export interface HotspotConfig {
  id: HotspotId;
  title?: string;
  description?: string;
  imgPath?: string;
  videoPath?: string;
  videoPosterPath?: string;
  placement?: HotspotPlacement;
  isClickable?: boolean;
  isClosed?: boolean;
  skipRegistration?: boolean;
  variant?: 'pulsing' | 'static';
  isImmediate?: boolean;
  isIndicator?: boolean;
  isRegistered?: boolean;
  actionTitle?: string;
  priority: number;
  indicatorPosition?: {
    top: number;
    left: number;
  };
}
