<script setup lang="ts">
import {
  useFloating,
  autoPlacement,
  shift,
  offset,
  arrow,
  limitShift,
  autoUpdate,
} from '@floating-ui/vue';
import { onClickOutside } from '@vueuse/core';
import { ref, computed, watch, onMounted } from 'vue';

import HVideo from '@/components/HVideo.vue';
import { useGlobals } from '@/composables';
import { useHotspotStore } from '@/stores';
import type { HotspotConfig, HotspotPlacement } from '@/types';

interface Props extends HotspotConfig {}

interface Emits {
  (eventName: 'hot-spot-close'): void;
}

const HOTSPOT_OFFSET_PADDING = {
  top: 80,
  left: 20,
  right: 20,
  bottom: 20,
};

const props = withDefaults(defineProps<Props>(), {
  placement: 'right',
  isClickable: true,
});

const emit = defineEmits<Emits>();

const hotspotStore = useHotspotStore();
const { t } = useGlobals();

const isActive = ref(false);

const hotSpotTooltip = ref<HTMLElement | null>(null);
const hotSpotBody = ref<HTMLElement | null>(null);
const floatingArrow = ref<HTMLElement | null>(null);

const { floatingStyles, middlewareData, placement } = useFloating(
  hotSpotTooltip,
  hotSpotBody,
  {
    middleware: [
      autoPlacement({
        padding: HOTSPOT_OFFSET_PADDING,
        alignment: 'start',
        allowedPlacements: ['bottom', 'top', 'left', 'right'],
      }),
      shift({
        limiter: limitShift(),
        padding: HOTSPOT_OFFSET_PADDING,
      }),
      offset(10),
      arrow({ element: floatingArrow, padding: 5 }),
    ],
    placement: props.placement,
    whileElementsMounted: autoUpdate,
  },
);

const getArrowPosition = (
  placement: HotspotPlacement,
  x?: number,
  y?: number,
) => {
  if (placement === 'top') {
    return {
      left: `${x}px`,
      bottom: `${-10}px`,
      top: 'unset',
    };
  }

  if (placement === 'bottom') {
    return {
      left: `${x}px`,
      top: `${-10}px`,
      bottom: 'unset',
    };
  }

  if (placement === 'left') {
    return {
      right: `${-10}px`,
      top: `${y}px`,
      left: 'unset',
    };
  }

  if (placement === 'right') {
    return {
      left: `${-10}px`,
      top: `${y}px`,
      right: 'unset',
    };
  }
};

watch(
  [middlewareData, placement],
  ([newData, newPlacement]) => {
    if (newData.arrow && floatingArrow.value) {
      const { x, y, centerOffset } = newData.arrow;

      if (centerOffset !== 0) {
        Object.assign(floatingArrow.value.style, { opacity: 0 });

        return;
      }

      const calculatedPosition = getArrowPosition(
        newPlacement as HotspotPlacement,
        x,
        y,
      );

      Object.assign(floatingArrow.value.style, {
        ...calculatedPosition,
        opacity: 1,
      });
    }
  },
  { deep: true },
);

const isHotspotVisible = computed(() =>
  hotspotStore.currentlyVisibleHotspots.find(({ id }) => id === props.id),
);

const toggleTooltip = () => {
  if (!props.isClickable) {
    return;
  }

  isActive.value = !isActive.value;

  if (!isActive.value) {
    hotspotStore.closeHotspot(props.id);
  }
};

if (!props.skipRegistration) {
  hotspotStore.registerHotspot(props.id);
}

onMounted(() => {
  if (props.isImmediate && !props.isClosed) {
    toggleTooltip();
  }
});

onClickOutside(hotSpotBody, () => {
  if (isActive.value) {
    toggleTooltip();
  }
});

const handleClose = () => {
  emit('hot-spot-close');
  toggleTooltip();
};
</script>

<template>
  <div
    ref="hotSpotTooltip"
    class="hotspot-tooltip"
    :class="{
      'hotspot-tooltip--active': isActive,
      'hotspot-tooltip--hide': !isHotspotVisible,
    }"
    @click="toggleTooltip"
  >
    <slot />
  </div>

  <div
    v-if="isActive"
    id="hideOptions"
    ref="hotSpotBody"
    class="hotspot-tooltip-body"
    :class="{
      'hotspot-tooltip-body--active': isActive,
    }"
    :style="floatingStyles"
  >
    <div ref="floatingArrow" class="hotspot-tooltip-body__arrow" />
    <div class="hotspot-tooltip__text">
      <p v-if="title" class="text-heading-2 hotspot-tooltip__title">
        {{ title }}
      </p>

      <slot v-if="$slots.description" name="description" />
      <p v-else class="text-dark">{{ description }}</p>

      <HImage
        v-if="imgPath"
        class="hotspot-tooltip__image"
        :src="`@/images/${imgPath}.svg`"
      />

      <HVideo
        v-if="videoPath && videoPosterPath"
        autoplay
        class="hotspot-tooltip__video"
        loop
        muted
        playsinline
        :poster="videoPosterPath"
        :src="videoPath"
        type="video/mp4"
      />
    </div>
    <div class="hotspot-tooltip__actions">
      <HButton @click="handleClose">
        {{ actionTitle || t('Got it!') }}
      </HButton>
    </div>
  </div>
</template>

<style lang="scss">
.hotspot-tooltip {
  position: relative;
  display: inline-block;

  &--hide {
    display: none;
  }

  &__text {
    margin-bottom: 16px;
  }
  &__title {
    margin-bottom: 8px;
  }
  &__image {
    height: 25px;
  }

  &__video {
    width: 100%;
    height: auto;
    border-radius: 8px;
    border: 1px solid var(--meteorite-light);
    margin-top: 16px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    margin-bottom: 16px;
  }
}

.hotspot-tooltip-body {
  position: absolute;
  text-align: left;
  padding: 24px 32px;
  width: 490px;
  border-radius: 8px;
  background-color: var(--ghost-white);
  box-shadow: 0px 0px 49px rgba(29, 30, 32, 0.16);
  z-index: var(--z-index-3);
  visibility: visible;

  &__arrow {
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: var(--ghost-white);
    transform: rotate(45deg);
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
}

@media (max-width: 576px) {
  .hotspot-tooltip-body {
    width: 90vw;
  }
}
</style>
