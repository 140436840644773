import type {
  DomainValidation,
  PostCopyWebsite,
  IWebsiteBackup,
  PartialHeaders,
} from '@/types';
import { Header } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  //@ts-ignore
  url: (v) => `${process.env.VITE_API_REST}/${v}`,

  //@ts-ignore
  async markWebsiteGuideSteps(domain, guideSteps) {
    return await hAsync(
      http.patch(
        `${this.url('v3')}/domains/additional-data`,
        {
          key: 'guide_steps',
          additionalData: guideSteps,
        },
        { domain },
      ),
    );
  },

  //@ts-ignore
  async postHostingAddons(data) {
    return await hAsync(
      http.post(
        `${this.url('v3')}/domains/addons`,
        { domain: data.reqDomain },
        {
          domain: data.domain,
        },
      ),
    );
  },

  //@ts-ignore
  async submitAddonOnboarding(request, config) {
    return await hAsync(
      http.post(`${this.url('v3')}/domains/addons/onboarding`, request, {
        headers: {
          [Header.USERNAME]: config.username,
          [Header.ORDER_ID]: config.orderId,
          [Header.DOMAIN]: config.domain,
        },
        noHostingHeaders: true,
      }),
    );
  },

  async validateDomain(domain: string) {
    return await hAsync<DomainValidation>(
      http.post(
        `${this.url('v3')}/domains/validate`,
        { domain },
        { hideToastr: true, whitelistedStatusCodes: [432, 422] },
      ),
    );
  },

  //@ts-ignore
  async getVisitorsCount() {
    return await hAsync(
      http.get(`${this.url('v2')}/visitor-count`, {
        hideToastr: true,
      }),
    );
  },

  async postCopyWebsite({
    targetDomain,
    domain,
  }: PostCopyWebsite): Promise<any> {
    return await hAsync(
      http.post(`${this.url('v3')}/website-copy/copy`, {
        targetDomain,
        domain,
      }),
    );
  },

  //@ts-ignore
  async getCopyWebsiteList() {
    return await hAsync(http.get(`${this.url('v3')}/website-copy/list`));
  },

  async getWebsiteHttpStatus(params?: {
    domain: string;
    username?: string;
    directory?: string;
    orderId?: number;
  }) {
    if (params) {
      return await hAsync<{ code: number; message: string }>(
        http.post(`${this.url('v3')}/website/http-status`, params),
      );
    }

    return await hAsync<{ code: number; message: string }>(
      http.get(`${this.url('v3')}/website/http-status`, {
        hostingHeaderRequired: true,
      }),
    );
  },

  async getWebsiteBackups(params?: { domain: string; directory?: string }) {
    return await hAsync<IWebsiteBackup>(
      http.get(`${this.url('v3')}/website/backups`, { params }),
    );
  },

  async getCachelessModeStatus() {
    return await hAsync<boolean>(
      http.get(`${this.url('v3')}/website/cacheless-mode-status`),
    );
  },

  async postCachelessModeEnable() {
    return await hAsync<boolean>(
      http.post(`${this.url('v3')}/website/cacheless-mode-enable`),
    );
  },

  async postCachelessModeDisable() {
    return await hAsync<boolean>(
      http.post(`${this.url('v3')}/website/cacheless-mode-disable`),
    );
  },

  async addFavourite(headers: PartialHeaders) {
    return await hAsync<boolean>(
      http.post(
        `${this.url('v3')}/website/favourite`,
        {},
        {
          noHostingHeaders: true,
          headers,
        },
      ),
    );
  },

  async removeFavourite(headers: PartialHeaders) {
    return await hAsync<boolean>(
      http.delete(`${this.url('v3')}/website/favourite`, {
        noHostingHeaders: true,
        headers,
      }),
    );
  },
};
