import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { useGlobals } from './useGlobals';

import { hDomainsRepo } from '@/repositories';
import { AmplitudeEvent, AmplitudeLocation } from '@/types';
import {
  getSplittedDomainParts,
  toUnicode,
  toASCII,
  removeLeadingDot,
} from '@/utils/helpers';
import { getAllowedDomainCharCount } from '@/utils/services/domains/hDomains';

export const useDomain = () => {
  const route = useRoute();
  const { amplitudeV2 } = useGlobals();

  const TEMPORARY_DOMAIN_HOST = '.hostingersite';

  const domain = computed((): string =>
    toUnicode(route.params.domain as string),
  );

  const isDomainTemporary = computed(() =>
    domain.value.includes(TEMPORARY_DOMAIN_HOST),
  );

  const selectedDomain = computed(() => domainToManage.value || domain.value);

  const domainToManage = computed(() =>
    toUnicode(route.params.domainToManage as string),
  );

  const domainEncoded = computed(() => toASCII(route?.params.domain as string));

  const domainLengthValidator = (domain: string) => {
    const allowedChars = getAllowedDomainCharCount(domain);

    return domain.length > allowedChars
      ? { text: `Domain name has more than ${allowedChars} characters` }
      : null;
  };

  const checkIsDomainAvailable = async (sld: string, tld: string) => {
    const formattedTld = removeLeadingDot(tld);

    const [{ data: domainAvailabilityData }, err] =
      await hDomainsRepo.getDomainAvailability(sld, [formattedTld]);

    if (!err && !Object.values(domainAvailabilityData)[0]) {
      return {
        err: 'This domain is already registered. Try searching for some other domain.',
        isAvailable: false,
      };
    }

    return {
      err: err ?? null,
      isAvailable:
        domainAvailabilityData && !!Object.values(domainAvailabilityData)[0],
    };
  };

  const getIsDomainAvailable = async (domain: string) => {
    const [sld, tld] = getSplittedDomainParts(domain);

    const { isAvailable } = await checkIsDomainAvailable(sld, tld);

    return isAvailable;
  };
  const getIsIdnDomain = (domain: string) => domain !== toUnicode(domain);

  const goToDomain = (domain: string, sendAmplitude?: boolean) => {
    window.open(`http://${domain}`, '_blank');

    if (!sendAmplitude) return;

    amplitudeV2(AmplitudeEvent.Hosting.ACCESS_WEBSITE_ENTER, {
      location: AmplitudeLocation.Base.DASHBOARD,
    });
  };

  return {
    domain,
    domainEncoded,
    domainToManage,
    selectedDomain,
    domainLengthValidator,
    checkIsDomainAvailable,
    getIsDomainAvailable,
    getIsIdnDomain,
    goToDomain,
    isDomainTemporary,
  };
};
