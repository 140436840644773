<script lang="ts" setup>
import { computed } from 'vue';

import HDrawer from '@/components/HDrawer.vue';
import Navigation from '@/components/Navigation.vue';
import { useScreen, useNavigationExperiment } from '@/composables';
import { useFrontendSettingsStore, useHPanelControlsStore } from '@/stores';

type Props = {
  computedHeaderAndBannerHeight: string;
};

defineProps<Props>();

const hpanelControlsStore = useHPanelControlsStore();
const frontendSettingsStore = useFrontendSettingsStore();

const { isLarge } = useScreen();
const { isNavigationExperimentActive } = useNavigationExperiment();

const isHeaderWithNavigation = computed(
  () =>
    !['simple-header', 'onboarding', false].includes(
      frontendSettingsStore.state.header,
    ),
);

const isNavigationVisible = computed(
  () => isNavigationExperimentActive.value && isHeaderWithNavigation.value,
);
</script>

<template>
  <div v-if="!isNavigationExperimentActive" id="side-menu-v2" />

  <Navigation
    v-if="isNavigationVisible && !isLarge"
    :computed-header-and-banner-height="computedHeaderAndBannerHeight"
  />

  <Teleport to="#app">
    <HDrawer
      v-if="isLarge && isNavigationVisible"
      :is-active="hpanelControlsStore.isMobileNavigationOpen"
      position="left"
      show-logo
      @on-close="hpanelControlsStore.toggleMobileNavigation"
    >
      <Navigation
        :computed-header-and-banner-height="computedHeaderAndBannerHeight"
      />
    </HDrawer>
  </Teleport>
</template>
