<script lang="ts" setup>
import { computed } from 'vue';

import { useTimer } from '@/composables';
import { mapKeyValue } from '@/utils/helpers';
import { wrapInCssVar } from '@/utils/helpers/cssHelpers';

type Props = {
  dateInUnix: number;
  variant: string;
  isSpacious?: boolean;
};
const props = defineProps<Props>();

const { days, hours, minutes, seconds } = useTimer(props.dateInUnix);

const timerStyling = computed(() => {
  const TIMER_STYLE_MAP = {
    meteorite: {
      color: wrapInCssVar('primary'),
      backgroundColor: '#ddd3fc',
    },
    meteoriteDark: {
      color: wrapInCssVar('meteorite-dark'),
      backgroundColor: wrapInCssVar('meteorite-light'),
    },
    white: {
      color: wrapInCssVar('white'),
      backgroundColor: wrapInCssVar('primary'),
    },
    // Not used anywhere, can be changed
    default: {
      color: wrapInCssVar('primary'),
      backgroundColor: '#ddd3fc',
    },
  };

  return mapKeyValue(TIMER_STYLE_MAP, props.variant);
});
</script>

<template>
  <div class="timer" :class="{ 'timer--spacious': props.isSpacious }">
    <span class="text-bold-1">
      {{ `${days}:${hours}:${minutes}:${seconds}` }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.timer {
  width: 100%;
  padding: 4px 16px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  background-color: v-bind('timerStyling.backgroundColor');
  color: v-bind('timerStyling.color');

  span {
    min-width: 100px;
    text-align: center;
    color: inherit;
  }

  &--spacious {
    padding: 12px;
    letter-spacing: 1px;
  }
}
</style>
