import { defineStore } from 'pinia';
import { ref } from 'vue';

import { hDomainsRepo } from '@/repositories';
import { useProfileStore, useWebsitesStore } from '@/stores';
import type { IHDomainResource, ProWebsiteBase } from '@/types';
import { HDomainResource } from '@/types';

export const useCombinedDomainsStore = defineStore(
  'combinedDomainsStore',
  () => {
    const websitesStore = useWebsitesStore();
    const profileStore = useProfileStore();
    const combinedDomainsList = ref<IHDomainResource[]>([]);
    const managedExternalDomains = ref<ProWebsiteBase[]>([]);
    const domainResourcesList = ref<IHDomainResource[]>([]);

    const fetchCombinedDomainsList = async () => {
      const [{ data }, err] = await hDomainsRepo.getProResources({
        resourceType: HDomainResource.ResourceType.DOMAIN,
      });

      if (!data || err) return;
      combinedDomainsList.value = data;
    };

    const fetchCombinedResourcesList = async () => {
      const [{ data }, err] = await hDomainsRepo.getProResources();
      if (!data || err) return;
      domainResourcesList.value = data;
    };
    const getWebsitesList = async () => {
      if (websitesStore.websites.length) return;

      await websitesStore.fetchWebsitesList();
    };
    const getCombinedResourcesList = async () => {
      if (domainResourcesList.value?.length) return;

      await fetchCombinedResourcesList();
    };

    const fetchManagedExternalDomains = async () => {
      await Promise.all([getWebsitesList(), getCombinedResourcesList()]);

      managedExternalDomains.value = websitesStore.websites.filter(
        (websiteObject) =>
          !domainResourcesList.value?.find(
            (item) => item.title === websiteObject.website,
          ) && profileStore.account?.id !== websiteObject.clientId,
      );
    };

    return {
      combinedDomainsList,
      fetchCombinedDomainsList,
      managedExternalDomains,
      fetchManagedExternalDomains,
    };
  },
  {
    persist: { key: 'combined-domains-store' },
  },
);
