export interface HCdnState {
  isLoading: boolean;
  cdns: Array<CdnDataModel>;
  cdnOptions: CdnOptionsDataModel | null;
  cdnDetails: CdnDataModel | null;
  cdnPerformanceData: CdnPerformanceDataModel | null;
}
export interface PerformanceMetric {
  bare: number;
  cdn: number;
  difference: number;
}

export interface CdnPerformanceDataModel {
  cumulativeLayoutShift?: PerformanceMetric;
  maxPotentialFid?: PerformanceMetric;
  largestContentfulPaint?: PerformanceMetric;
  firstContentfulPaint?: PerformanceMetric;
  firstMeaningfulPaint?: PerformanceMetric;
  interactive?: PerformanceMetric;
  serverResponseTime?: PerformanceMetric;
  speedIndex?: PerformanceMetric;
  totalBlockingTime?: PerformanceMetric;
  performance?: PerformanceMetric;
}

export interface CdnDataModel {
  vhost: string;
  bypassCache: boolean;
  latestTls?: false;
  forceHttps?: false;
  vhostOptions?: CdnOptionsDataModel;
}

export interface CdnOptionsDataModel {
  domain?: string;
  imgOptEnabled?: boolean;
  imgOptWebp?: boolean;
  imgOptQuality?: number;
  imgOptMobileQuality?: number;
  imgOptWidth?: number;
  imgOptMobileWidth?: number;
  minifyCss?: boolean;
  minifyJs?: boolean;
  withToastr?: boolean;
  wafEnabled?: boolean;
  wafDefaultPolicy?: string;
  wafJsChallengeEnabled?: boolean;
}

export interface TotalAndPercentageModel {
  total: number;
  percentage: number;
}

export interface CdnCacheStatusesGraphModel {
  minutes: number;
  hours: number;
  hit: number;
  miss: number;
  dynamic: number;
  bypass: number;
  revalidated: number;
}

export interface TrafficTlsModel {
  'tlsv1.2': TotalAndPercentageModel;
  'tlsv1.3': TotalAndPercentageModel;
  plain: TotalAndPercentageModel;
}

export interface CdnCacheStatusesModel {
  hit: TotalAndPercentageModel;
  miss: TotalAndPercentageModel;
  dynamic: TotalAndPercentageModel;
  bypass: TotalAndPercentageModel;
  revalidated: TotalAndPercentageModel;
}

export interface CdnStatusCodeGraphModel {
  minutes: number;
  hours: number;
  '2xx': number;
  '3xx': number;
  '4xx': number;
  '429': number;
  '444': number;
  '5xx': number;
  total: number;
}

export interface CdnStatusCodeModel {
  '2xx': TotalAndPercentageModel;
  '3xx': TotalAndPercentageModel;
  '4xx': TotalAndPercentageModel;
  '429': TotalAndPercentageModel;
  '444': TotalAndPercentageModel;
  '5xx': TotalAndPercentageModel;
}

export enum HCdnMutations {
  SET_CDNS_DATA = 'SET_CDN_DATA',
  SET_CDN_OPTIONS_DATA = 'SET_CDN_OPTIONS_DATA',
  SET_CDN_DETAILS_DATA = 'SET_CDN_DETAILS_DATA',
  SET_CDNS_LOADING = 'SET_CDNS_LOADING',
  SET_CDN_PERFORMANCE_DATA = 'SET_CDN_PERFORMANCE_DATA',
}

export enum HCdnActions {
  FETCH_CDNS_DATA = 'fetchCdnsData',
  FETCH_CDN_OPTIONS_DATA = 'fetchCdnOptionsData',
  FETCH_CDN_DETAILS_DATA = 'fetchCdnDetailsData',
  FETCH_CDN_PERFORMANCE_DATA = 'fetchCdnPerformanceData',
  RESET_CDN_DETAILS_OPTIONS_DATA = 'resetCdnDetailsOptionsData',
  CHANGE_CDN_STATUS = 'changeCdnStatus',
  CHANGE_DEV_STATUS = 'changeDevStatus',
  CHANGE_LATEST_TLS_STATUS = 'changeLatestTlsStatus',
  FLUSH_CACHE = 'fluchCache',
  UPDATE_CDN_OPTION = 'updateCdnOptions',
  UPDATE_CDN_COUNTRY_DENY_LIST = 'updateCdnCountryDenyList',
  UPDATE_CDN_IP_DENY_LIST = 'updateCdnIpDenyList',
}

export enum HCdnGetters {
  GET_CDNS_DATA = 'getCdnsData',
  GET_CDN_OPTIONS_DATA = 'getCdnOptionsData',
  GET_CDN_DETAILS_DATA = 'getCdnDetailsData',
  GET_CDNS_LOADING = 'getCdnsLoading',
  GET_CDN_PERFORMANCE_DATA = 'getCdnPerformanceData',
}
