import type {
  CdnDataModel,
  CdnOptionsDataModel,
  CdnCacheStatusesModel,
  CdnStatusCodeModel,
  CdnStatusCodeGraphModel,
  TrafficTlsModel,
  CdnPerformanceDataModel,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const URL = `${process.env.VITE_API_REST}/v3/cdn`;

export default {
  async getCdnList() {
    return await hAsync<CdnDataModel[]>(http.get(URL, { hideToastr: true }));
  },

  async getCdnDetails(params: { domain: string }) {
    return await hAsync<CdnDataModel[]>(http.get(`${URL}/details`, { params }));
  },

  async getCdnOptions(params: { domain: string }) {
    return await hAsync<CdnDataModel[]>(
      http.get(`${URL}/vhost-options`, { params }),
    );
  },

  async changeCdnStatus(req: { domain: string; enable: boolean }) {
    if (req.enable) {
      return await hAsync<boolean>(
        http.post(`${URL}/enable`, req, {
          whitelistedStatusCodes: [400],
        }),
      );
    }

    return await hAsync<boolean>(http.post(`${URL}/disable`, req));
  },

  async setDevMode(req: { domain: string; enabled: boolean }) {
    return await hAsync<boolean>(http.post(`${URL}/cache-bypass`, req));
  },

  async flushCache(req: { domain: string }) {
    return await hAsync<boolean>(http.post(`${URL}/purge`, req));
  },

  async updateCdnOption(req: CdnOptionsDataModel) {
    return await hAsync<boolean>(
      http.put(`${URL}/vhost-options`, req, { plain: true }),
    );
  },

  async postLatestTLS(req: { domain: string; enabled: boolean }) {
    return await hAsync<boolean>(http.post(`${URL}/latest-tls`, req));
  },

  async postBlockIp(req: { domain: string; ips: string[] }) {
    return await hAsync<boolean>(http.post(`${URL}/waf/ips/deny`, req));
  },

  async postBlockCountries(req: { domain: string; countries: string[] }) {
    return await hAsync<boolean>(http.post(`${URL}/waf/countries/deny`, req));
  },

  async getCacheStatusesGraph(params: { timeframe: string }) {
    return await hAsync<[]>(
      http.get(`${URL}/metrics/cache-statuses-graph`, { params }),
    );
  },

  async getCacheStatuses(params: { timeframe: string }) {
    return await hAsync<CdnCacheStatusesModel>(
      http.get(`${URL}/metrics/cache-statuses`, { params }),
    );
  },

  async getStatusCodeGraph(params: { timeframe: string }) {
    return await hAsync<CdnStatusCodeGraphModel[]>(
      http.get(`${URL}/metrics/status-code-graph`, { params }),
    );
  },

  async getStatusCode(params: { timeframe: string }) {
    return await hAsync<CdnStatusCodeModel>(
      http.get(`${URL}/metrics/status-code`, { params }),
    );
  },

  async getTrafficTls(params: { timeframe: string }) {
    return await hAsync<TrafficTlsModel>(
      http.get(`${URL}/metrics/traffic-tls`, { params }),
    );
  },

  async getCdnPerformance(params: { domain: string }) {
    return await hAsync<CdnPerformanceDataModel>(
      http.get(`${URL}/performance`, { params }),
    );
  },
};
