<script setup lang="ts">
import type {
  FieldError,
  FieldSchema,
  FormValues,
} from '@hostinger/hcomponents';
import { computed, ref, watch } from 'vue';

import { FieldType } from '@/types';

type Props = {
  loading: boolean;
  error: string;
  activeCoupon: string;
};

interface Emits {
  (eventName: 'on-apply', coupon: string): void;
}

const emit = defineEmits<Emits>();
const props = defineProps<Props>();

const isError = ref(props.error);

const coupon = ref(props.activeCoupon);

const couponSchema: FieldSchema = {
  type: FieldType.text,
  label: 'Enter a coupon code',
  validation: { required: false, maxlength: 120 },
  custom: {
    hideOptionalLabel: true,
  },
};

const couponError = computed<FieldError | undefined>(() =>
  isError.value ? { text: isError.value } : undefined,
);

const couponChange = ({ values }: FormValues) => {
  const isSameValue = coupon.value === values.coupon;

  if (!isSameValue) isError.value = '';
  coupon.value = values.coupon;
};

const applyCoupon = () => {
  const value = coupon.value.trim();

  if (!value || isError.value) return;

  emit('on-apply', value);
};

watch(
  () => props.error,
  (value) => (isError.value = value),
);
</script>

<template>
  <HForm @on-change="couponChange($event)" @on-submit="applyCoupon()">
    <div class="coupon">
      <HFormField
        class="coupon__input"
        name="coupon"
        :schema="couponSchema"
        :custom-error="coupon ? couponError : undefined"
        data-qa="coupon-input"
      />

      <HButtonV1
        class="coupon__button"
        lg
        no-margin
        primary
        data-qa="apply-coupon-button"
        :loading="loading"
        :disabled="!!isError || !coupon"
        @click="applyCoupon"
      >
        Apply
      </HButtonV1>
    </div>
  </HForm>
</template>

<style lang="scss" scoped>
.coupon {
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px;

  &__input,
  &__button {
    margin: 0;
    align-self: start;
  }

  &__button {
    width: auto;
    flex-grow: 0;

    :deep(.trans) {
      word-break: keep-all;
    }
  }
}
</style>
