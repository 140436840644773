import { computed, ref } from 'vue';

import TransferLockedErrorModal from '@/components/Modals/HModal/HDomains/TransferLockedErrorModal.vue';
import {
  useGlobals,
  useDomain,
  useTransferValidation,
  useModal,
} from '@/composables';
import { hDomainsRepo } from '@/repositories';
import { useHDomainResourceStore, useDomainManagementStore } from '@/stores';
import type { TransferOverviewBannerActionType } from '@/types';
import {
  DOMAIN_TRANSFER_INFO_STATUS,
  MESSAGE_SLUG,
  ExternalUrl,
  TRANSFER_OVERVIEW_BANNER_ACTION_TYPE,
} from '@/types';
import {
  mapKeyValue,
  urlRegexp,
  getSldTld,
  transformEveryWordToUnicode,
} from '@/utils/helpers';
import {
  getHowToTransferUkDomainArticle,
  getServiceProviderForBrArticle,
} from '@/utils/services/supportArticleService';

interface ITransferOverviewBannerAction {
  type: TransferOverviewBannerActionType;
  text: string;
  onClick?: () => void;
  onAction?: () => void;
  props?: Record<string, any>;
}

interface IStatusContent {
  message: string;
  stepBannerHeaderText?: string;
  stepBannerContent?: string;
  stepBannerContentActions?: ITransferOverviewBannerAction[];
}

const FIVE_MINUTES = 5 * 60 * 1000;

const currentRegistrar = ref('');
const isRetryDisabled = ref(false);

export const useDomainTransferManagement = () => {
  const { t, toastr } = useGlobals();
  const { domainEncoded, domain } = useDomain();
  const { getHDomainTransferResourceByDomain } = useHDomainResourceStore();
  const {
    getDomainTransferData,
    fetchTransferData,
    getDomainTransferRetriedDate,
    setDomainTransferRetriedDate,
  } = useDomainManagementStore();
  const { checkIsDomainTransferable, isLockedError, transferableErrorMessage } =
    useTransferValidation();
  const { openModal } = useModal();

  const isRetryingTransfer = ref(false);

  const hDomainResource = computed(() =>
    getHDomainTransferResourceByDomain(domainEncoded.value),
  );

  const transferData = computed(() =>
    getDomainTransferData(domainEncoded.value),
  );

  const transferStatus = computed(() => {
    if (!transferData.value) {
      return '';
    }

    return transferData.value.status;
  });

  const transferMessageSlug = computed(() => {
    if (!transferData.value) {
      return '';
    }

    return transferData.value.messageSlug;
  });

  const isDomainTransferring = computed(
    () => transferStatus.value === DOMAIN_TRANSFER_INFO_STATUS.TRANSFERRING,
  );

  const isTransferCompleted = computed(
    () => transferStatus.value === DOMAIN_TRANSFER_INFO_STATUS.COMPLETED,
  );

  const isTransferError = computed(
    () => transferStatus.value === DOMAIN_TRANSFER_INFO_STATUS.ERROR,
  );

  const isTransferRetryable = computed(
    () => isTransferError.value && transferData.value?.isRetryable,
  );

  const howToTransferBrDomainAction = computed<ITransferOverviewBannerAction>(
    () => ({
      type: TRANSFER_OVERVIEW_BANNER_ACTION_TYPE.LINK,
      text: t('How to transfer a .BR domain to Hostinger?'),
      props: {
        variant: 'bold',
        iconPrepend: 'icon-launch',
        'data-qa': 'how-to-transfer-br-domain-link',
      },
      onClick: () => {
        window.open(getServiceProviderForBrArticle(), '_blank');
      },
    }),
  );

  const retryAction = computed<ITransferOverviewBannerAction>(() => ({
    type: TRANSFER_OVERVIEW_BANNER_ACTION_TYPE.BUTTON,
    text: t('Retry'),
    onClick: retryTransfer,
    props: {
      'data-qa': 'retry-transfer-button',
      iconPrepend: 'icon-sync',
      isLoading: isRetryingTransfer.value,
      isDisabled: isRetryDisabled.value,
    },
  }));

  const dkHostmasterAction = computed<ITransferOverviewBannerAction>(() => ({
    type: TRANSFER_OVERVIEW_BANNER_ACTION_TYPE.LINK,
    text: t('Log in to self-service panel at DK Hostmaster'),
    props: {
      variant: 'bold',
      iconPrepend: 'icon-launch',
      'data-qa': 'how-to-login-to-dk-hostmaster-link',
    },
    onClick: () => {
      window.open(ExternalUrl.Registry.HOSTMASTER, '_blank');
    },
  }));

  const transferOverviewContent = computed(() => {
    if (isDomainTransferring.value) {
      return transferringStatusContent.value;
    }

    if (isTransferError.value) {
      return {
        ...errorStatusContent.value,
        stepBannerContentActions: [
          ...(errorStatusContent.value.stepBannerContentActions || []),
          ...(isTransferRetryable.value ? [retryAction.value] : []),
        ],
      };
    }

    if (isTransferCompleted.value) {
      return {
        message: t(
          'The domain <strong>{domain}</strong> was transferred to Hostinger! Soon it will appear in your Domains page.',
          { domain: domain.value },
        ),
      } as IStatusContent;
    }

    throw new Error('Transfer status is not recognized');
  });

  const errorStatusContent = computed<IStatusContent>(() =>
    mapKeyValue(
      {
        [MESSAGE_SLUG.REJECTED]: {
          message: t('Your transfer request was denied.'),
          stepBannerHeaderText: t(
            'Contact your domain provider and retry the transfer',
          ),
          stepBannerContent: t(
            'Get in touch with your current domain provider in order to approve the transfer. After that, come back here to retry the transfer.',
          ),
        },
        [MESSAGE_SLUG.REGISTRO_BR_PROVIDER_AND_ORGANIZATION_MISMATCH]: {
          message: t(
            'The domain provider has denied the transfer due to incorrect provider and organization.',
          ),
          stepBannerHeaderText: t(
            'Update provider and organization at Registro.br',
          ),
          stepBannerContent: `${t(
            'To proceed with the transfer you must change the provider and organization to <strong>HSTDOMAINS(127)</strong>.',
          )}<br><br>${t(
            'After setting the correct <strong>provider and organization</strong>, please click <strong>Retry</strong> to proceed with the transfer.',
          )}`,
          stepBannerContentActions: [howToTransferBrDomainAction.value],
        },
        [MESSAGE_SLUG.REGISTRO_BR_ORGANIZATION_MISMATCH]: {
          message: t(
            'The domain provider has denied the transfer due to incorrect organization.',
          ),
          stepBannerHeaderText: t('Update organization at Registro.br'),
          stepBannerContent: `${t(
            'To proceed with the transfer you must change the provider and organization to <strong>HSTDOMAINS(127)</strong>.',
          )}<br><br>${t(
            'After setting the correct <strong>organization</strong>, please click <strong>Retry</strong> to proceed with the transfer',
          )}`,
          stepBannerContentActions: [howToTransferBrDomainAction.value],
        },
        [MESSAGE_SLUG.FAILED]: {
          message: t(
            'The domain provider has denied the transfer of this domain or email to transfer the domain was not approved.',
          ),
          stepBannerHeaderText: t(
            'Verify your email address to complete transfer',
          ),
          stepBannerContent: t(
            'Email was sent to verify this transfer. Once you verify the email, click <strong>Retry</strong> to transfer your domain.',
          ),
        },
        [MESSAGE_SLUG.REGISTRO_BR_PROVIDER_MISMATCH]: {
          message: t(
            'The domain provider has denied the transfer due to incorrect provider.',
          ),
          stepBannerHeaderText: t('Update provider at Registro.br'),
          stepBannerContent: `${t(
            'To proceed with the transfer you must change the provider to <strong>HSTDOMAINS(127)</strong>.',
          )}<br><br>${t(
            'After setting the correct <strong>provider</strong>, please click <strong>Retry</strong> to proceed with the transfer',
          )}`,
          stepBannerContentActions: [howToTransferBrDomainAction.value],
        },
        [MESSAGE_SLUG.REJECTED_BY_REGISTRY]: {
          message: t(
            'Your current domain provider has cancelled or denied the request to transfer this domain.',
          ),
          stepBannerHeaderText: t(
            'Contact your domain provider and retry the transfer',
          ),
          stepBannerContent: t(
            'Get in touch with your current domain provider in order to approve the transfer. After that, come back here to retry the transfer.',
          ),
        },
        [MESSAGE_SLUG.INVALID_ATTRIBUTE_VALUE]: {
          message: t(
            'The transfer has failed due to data mismatch at the current provider',
          ),
          stepBannerHeaderText: t('Next steps to transfer your domain'),
          stepBannerContent: t(
            'Contact our Customer Success team for help in transferring this domain.',
          ),
        },
        [MESSAGE_SLUG.SPECIFYING_CONTACTS_NOT_ALLOWED]: {
          message: t('The transfer request was denied by the registry.'),
          stepBannerHeaderText: t('Next steps to transfer your domain'),
          stepBannerContent: `
            <span>${t('To fix this issue you must:')}</span><br>
            <ul>
              <li>${t(
                'Login to <strong>self-service panel</strong> at DK Hostmaster',
              )}</li>
              <li>${t('Click on "Cancel Registrar"')}</li>
            </ul><br>
            <span>${t(
              'After completing these steps, click <strong>Retry</strong> to proceed with transfer.',
            )}</span>
          `,
          stepBannerContentActions: [dkHostmasterAction.value],
        },
        [MESSAGE_SLUG.OBJECT_STATUS_PROHIBITS_OPERATION]: {
          message: t(
            'The transfer request was denied by the current registrar',
          ),
          stepBannerHeaderText: t(
            'Contact your domain provider and retry the transfer',
          ),
          stepBannerContent: t(
            'To proceed with the transfer, <strong>contact your previous provider</strong> to resolve issues with the transfer and then click <strong>Retry</strong>.',
          ),
        },
        [MESSAGE_SLUG.WAITING_FOR_APPROVAL]: {
          message: t(
            'The transfer request was denied by the current registrar',
          ),
          stepBannerHeaderText: t('To speed up the domain transfer, you can:'),
          stepBannerContent: t(
            'Verify your email address. Look out for an email sent to you by the current provider <strong>or</strong> contact your domain provider and ask to manually approve the transfer.',
          ),
        },
        [MESSAGE_SLUG.PROCESS_TIMED_OUT]: {
          message: t('The transfer was unsuccessful due to a timeout.'),
          stepBannerHeaderText: t('Retry to transfer your domain'),
          stepBannerContent: t(
            'The transfer request has timed out. Click <strong>Retry</strong> to transfer your domain.',
          ),
        },
        [MESSAGE_SLUG._60_DAYS_LOCK]: {
          message: t(
            'The transfer was unsuccessful as 60 days have not passed since registering or transferring the domain.',
          ),
          stepBannerHeaderText: t('To speed up the domain transfer, you can:'),
          stepBannerContent: `
            <span>${t(
              'Upon registering, transferring or changing the contact details of a domain, it is put under 60 days lock, per ICANN policy. During this period your domain can not be unlocked and transferred away to a different registrar.',
            )}</span><br><br>
            <span>${t(
              'After 60 days have passed, click <strong>Retry</strong> to proceed with the transfer.',
            )}</span>
          `,
        },
        [MESSAGE_SLUG.AUTH_CODE_INCORRECT]: {
          message: t(
            'The transfer was unsuccessful as authorization code is incorrect.',
          ),
          stepBannerHeaderText: t(
            'Domain Secret Key/Authorization code (EPP) is incorrect',
          ),
          stepBannerContent: t(
            `To transfer your domain update your authorization code. You can get it from your current provider`,
          ),
          stepBannerContentActions: [
            {
              type: TRANSFER_OVERVIEW_BANNER_ACTION_TYPE.EPP_INPUT,
              onAction: retryTransfer,
              props: {
                isLoading: isRetryingTransfer.value,
                class: {
                  'w-100': true,
                  'h-pr-24-lg': true,
                },
              },
            },
          ],
        },
        default: {
          message: t(
            'Your transfer of <strong>{domain}</strong> was unsuccessful with the error:',
            { domain: domain.value },
          ),
          stepBannerHeaderText: transformEveryWordToUnicode(
            transferData.value?.message || '',
          ),
          stepBannerContent: t(
            'Check your email for any additional information sent by your previous provider <strong>or</strong> get in touch with our Customer Success team for additional help.',
          ),
        },
      },
      transferMessageSlug.value,
    ),
  );

  const transferringStatusContent = computed<IStatusContent>(() =>
    mapKeyValue(
      {
        [MESSAGE_SLUG.UPLOAD_DOCUMENT]: {
          stepBannerHeaderText: t('Upload a document to verify your identity'),
          stepBannerContent: t(
            'To transfer your domain, we must verify your identity first. The document verification process takes 1-3 working days.',
          ),
          stepBannerContentActions: [
            {
              type: TRANSFER_OVERVIEW_BANNER_ACTION_TYPE.BUTTON,
              text: t('Upload document'),
              onClick: openUploadPage,
              props: {
                'data-qa': 'upload-document-button',
              },
            },
          ],
        },
        [MESSAGE_SLUG.WAITING_FOR_APPROVAL]: {
          message: t(
            'The domain is currently transferring from your previous provider to Hostinger.',
          ),
          stepBannerHeaderText: t('To speed up the domain transfer, you can:'),
          stepBannerContent: t(
            'Verify your email address. Look out for an email sent to you by the current provider <strong>or</strong> contact your domain provider and ask to manually approve the transfer.',
          ),
        },
        [MESSAGE_SLUG.UPDATE_IPS_TAG]: {
          stepBannerHeaderText: t('Update IPS tag at current provider'),
          stepBannerContent: t(
            'At your current provider change your IPS tag to <strong>YOURSRS-NL</strong> in order to complete the transfer.',
          ),
          stepBannerContentActions: [
            {
              type: TRANSFER_OVERVIEW_BANNER_ACTION_TYPE.LINK,
              text: t('How to transfer a .uk domain to Hostinger'),
              props: {
                variant: 'bold',
                iconPrepend: 'icon-launch',
                'data-qa': 'how-to-transfer-uk-domain-link',
              },
              onClick: () => {
                window.open(getHowToTransferUkDomainArticle(), '_blank');
              },
            },
          ],
        },
        [MESSAGE_SLUG.VERIFY_EMAIL]: {
          stepBannerHeaderText: t(
            'Verify your email address to complete transfer',
          ),
          stepBannerContent: t(
            'To instantly complete the transfer of <strong>{domain}</strong>, verify your email address. Look out for an email sent to you by the current provider.',
            { domain: domain.value },
          ),
        },
        default: {
          message: t(
            'The domain is currently transferring from your previous provider to Hostinger.',
          ),
        },
      },
      transferMessageSlug.value,
    ),
  );

  const registrarText = computed(() => {
    if (isTransferCompleted.value || !currentRegistrar.value) {
      return '';
    }

    return t('Transferring from {registrar}', {
      registrar: currentRegistrar.value || t('Unknown'),
    });
  });

  const checkIsRetryTransferDisabled = () => {
    if (!hDomainResource.value) {
      isRetryDisabled.value = false;

      return;
    }

    const retryTimestamp = getDomainTransferRetriedDate(
      hDomainResource.value.id,
    );

    if (!retryTimestamp) {
      isRetryDisabled.value = false;

      return;
    }

    const currentTimestamp = new Date().getTime();
    const differenceInMilliseconds = currentTimestamp - retryTimestamp;

    if (differenceInMilliseconds > FIVE_MINUTES) {
      isRetryDisabled.value = false;

      return;
    }

    isRetryDisabled.value = true;

    setTimeout(() => {
      isRetryDisabled.value = false;
    }, FIVE_MINUTES - differenceInMilliseconds);
  };

  const openUploadPage = () => {
    const message = transferData.value?.message;
    const uploadUrl = message?.match(urlRegexp)?.[0];

    if (!uploadUrl) return;

    window.open(uploadUrl, '_blank');
  };

  const retryTransfer = async (additionalDetails?: Record<string, any>) => {
    isRetryingTransfer.value = true;

    const isTransferAvailable = await checkIsTransferAvailable();

    if (!isTransferAvailable || !transferData.value) {
      isRetryingTransfer.value = false;

      return;
    }

    const domainContacts = {
      owner: transferData.value.ownerWhoisId,
      admin: transferData.value.adminWhoisId,
      billing: transferData.value.billingWhoisId,
      tech: transferData.value.techWhoisId,
    };

    const [domain, tld] = getSldTld(domainEncoded.value, {
      omitDot: true,
    });

    const [{ data }, err] = await hDomainsRepo.transferDomain({
      tld,
      domain,
      domainContacts,
      additionalDetails: {
        ...(transferData.value.additionalDetails || {}),
        ...(additionalDetails || {}),
      },
    });

    if (!err && hDomainResource.value) {
      await fetchTransferData(domainEncoded.value);

      setDomainTransferRetriedDate(hDomainResource.value.id);

      checkIsRetryTransferDisabled();
    }

    if (!err && data.status === DOMAIN_TRANSFER_INFO_STATUS.ERROR) {
      toastr.e(
        t(
          'Initiating the domain transfer again was unsuccessful. Follow the instructions and try again.',
        ),
      );
    }

    isRetryingTransfer.value = false;
  };

  const checkIsTransferAvailable = async () => {
    await checkIsDomainTransferable(domainEncoded.value, {
      isCustomerResourceCheckSkipped: true,
    });

    if (isLockedError.value) {
      openModal({
        component: { TransferLockedErrorModal },
      });

      return false;
    }

    if (transferableErrorMessage.value) {
      toastr.e(t(transferableErrorMessage.value));

      return false;
    }

    return true;
  };

  const fetchAndSetCurrentRegistrar = async () => {
    currentRegistrar.value = '';

    const [{ data }] = await hDomainsRepo.lookupDomain(domainEncoded.value);

    if (!data?.registrar) {
      return;
    }

    currentRegistrar.value = data.registrar;
  };

  return {
    transferOverviewContent,
    registrarText,
    transferData,
    transferStatus,
    isTransferCompleted,
    hDomainResource,
    fetchAndSetCurrentRegistrar,
    currentRegistrar,
    isTransferError,
    isDomainTransferring,
    isRetryDisabled,
    checkIsRetryTransferDisabled,
  };
};
