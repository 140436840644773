import { NEXT_BEST_ACTION } from '@hostinger/hdomains-status';
import { storeToRefs } from 'pinia';
import { mapGetters } from 'vuex';

import { useDomainsStatusStore, useProfileStore } from '@/stores';
import { Route } from '@/types';
import { kebabToReadable } from '@/utils/helpers';

export default {
  computed: {
    showNotRegistered() {
      return this.domainInactiveStatus === 'not-registered';
    },
    showNotPointing() {
      return ['inactive', 'not-pointing'].includes(this.domainInactiveStatus);
    },
    domain() {
      if (
        this.$route.name?.includes('wordpress') ||
        this.$route.name === Route.HostingWordpress.MANAGE_STAGING_DASHBOARD
      ) {
        return this.getHostingWordpressSelectedAppData.coreDetails?.domain;
      }
    },
    domainInactiveStatus() {
      const { getDomainStatus } = useDomainsStatusStore();
      const { isLoading: domainStatusIsLoading } = storeToRefs(
        useDomainsStatusStore(),
      );

      if (!this.getDomainInfoLoaded || domainStatusIsLoading.value) {
        return '';
      }

      const { nextBestAction, isDomainPointing, isInPropagationTime } =
        getDomainStatus(this.domain)?.domainStatus ?? {};

      if (
        [
          NEXT_BEST_ACTION.RENEW_DOMAIN,
          NEXT_BEST_ACTION.RESTORE_DOMAIN,
        ].includes(nextBestAction)
      ) {
        return 'not-paid';
      }

      if (NEXT_BEST_ACTION.BUY_DOMAIN === nextBestAction) {
        return 'not-registered';
      }

      if (this.isNewDomain && !isDomainPointing) {
        return 'inactive';
      }

      if (
        (!isDomainPointing &&
          ![
            NEXT_BEST_ACTION.BUY_DOMAIN,
            NEXT_BEST_ACTION.RESTORE_DOMAIN,
            NEXT_BEST_ACTION.FINISH_REGISTRATION,
            NEXT_BEST_ACTION.RENEW_DOMAIN,
          ].includes(nextBestAction)) ||
        (nextBestAction === NEXT_BEST_ACTION.CONNECT_DOMAIN &&
          isInPropagationTime)
      ) {
        return 'not-pointing';
      }

      return '';
    },
    inactiveTooltipText() {
      const profileStore = useProfileStore();

      const { domainInactiveStatus } = this;
      const statusText = this.$t(kebabToReadable(domainInactiveStatus));
      const brandSuffix =
        domainInactiveStatus === 'not-pointing' ||
        domainInactiveStatus === 'not-registered'
          ? profileStore.clientBrandTitle
          : '';

      return this.$t(
        'This feature is not available if domain is {statusText} to {brandSuffix}',
        { statusText, brandSuffix },
      );
    },
    inTransferTooltip() {
      return 'Upgrade is not available while domain is in transfer';
    },
    ...mapGetters([
      'isNewDomain',
      'getHostingWordpressSelectedAppData',
      'isDomainRegistered',
      'getDomainInfoLoaded',
      'domainInvoiceLink',
      'domainRegistrationUrl',
      'getHostingWordpressWpUpsell',
      'getDomainIsPointing',
      'getDomainIsPointingLoaded',
    ]),
  },
};
