<template>
  <HpIcon
    v-if="statusIcon"
    v-bind="{
      [statusIcon.color]: true,
      icon: statusIcon.name,
    }"
  />
</template>

<script>
export default {
  name: 'StatusIcon',
  props: {
    status: {
      type: String,
      required: true,
    },
    customClass: String,
  },
  computed: {
    statusIcon() {
      const status = this.status.toLowerCase();

      const statusIcons = [
        {
          name: 'IcCheckOutline',
          color: 'success',
          applicableFor: [
            'ok',
            'paid',
            'active',
            'running',
            'sent',
            'success',
            'created',
            'finished',
            'refunded',
            'verified',
            'completed',
            'access',
            'delivered',
            'restore_completed',
          ],
        },
        {
          name: 'IcTimelapse',
          color: 'warning-dark',
          applicableFor: [
            'installing',
            'transfering',
            'transferring',
            'creating',
            'requested',
            'starting',
            'started',
            'destroying',
            'in_progress',
            'delivering',
            'deferred',
            'waiting',
            'upgrade_is_being_processed',
            'maintenance',
          ],
        },
        {
          name: 'icon-error',
          color: 'warning-dark',
          applicableFor: [
            'pending',
            'pending_setup',
            'pending_data_entry',
            'pending_email_confirmation',
            'pending_verification',
            'pending_confirmation',
            'pending_invitation',
            'suspended',
            'unsuspending',
            'suspending',
            'verification',
            'stopping',
            'stopping_recovery',
            'initial',
            'unpaid',
            'redemption_period',
            'unverified',
            'action_required',
            'pending_email_verification',
            'reviewing',
            'warning',
            'setup_not_finished',
            'partially delivered',
            'verifying',
            'registering',
          ],
        },
        {
          name: 'IcPauseCircle',
          color: 'warning-dark',
          applicableFor: [
            'expire',
            'expired',
            'archived',
            'stopped',
            'grace_period',
            'inactive',
            'delayed',
            'pending_payment',
          ],
        },
        {
          name: 'IcSettingsBackupRestore',
          color: 'warning-dark',
          applicableFor: [
            'restoring',
            'recovery',
            'recovering',
            'recreating',
            'recovery',
            'restore_in_progress',
          ],
        },
        {
          name: 'IcCancel',
          color: 'danger',
          applicableFor: [
            'password_mismatch',
            'failed',
            'error',
            'not_found',
            'conflict',
            'missing',
            'verification_failed',
            'fail_install',
            'rejected',
            'reject',
            'bounced',
            'soft reject',
            'restore_failed',
          ],
        },
        {
          name: 'IcMinusCircle',
          color: 'danger',
          applicableFor: ['destroyed', 'canceled', 'cancel'],
        },
        {
          name: 'IcInfo',
          color: 'gray',
          applicableFor: ['info'],
        },
        {
          name: 'IcTimelapse',
          color: 'gray',
          applicableFor: ['request_start'],
        },
      ];

      return statusIcons.find(({ applicableFor }) =>
        applicableFor.includes(status),
      );
    },
  },
};
</script>
