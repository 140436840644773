import { hDomainsStatus } from '@hostinger/hdomains-status';
import axios from 'axios';
import cookies from 'js-cookie';
import { isObject, isArray } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { handleCommonErrors } from './httpErrorHandlers';

import store from '@/store';
import { useProfileStore } from '@/stores';
import type { RequestConfig, HAxiosInstance } from '@/types';
import { Header, Cookie } from '@/types';
import { isFlatErrorResponse } from '@/utils/helpers';
import { getJwtToken } from '@/utils/helpers/authHelpers';
import {
  appForcedLogout,
  checkAndWaitForTokenRefresh,
  refreshToken,
  setAuthToken,
} from '@/utils/services/authService';
import { errorLogger } from '@/utils/services/errorLogging';
import logAxiosError from '@/utils/services/errorLogging/logAxiosError';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import cacheService from '@/utils/services/http/httpCacheService';
import { $t } from '@/utils/services/i18nService';
import {
  snakeToCamelObj,
  camelToSnakeObj,
} from '@/utils/services/namingConventionsService';

export const EXTENDED_TIMEOUT = 150000;
export const OPTIMIZED_TIMEOUT = 60000;

export const axiosInstance: HAxiosInstance = axios.create({
  headers: {
    common: {
      Accept: 'application/json;charset=utf-8',
      'Content-Type': 'application/json',
      'Strict-Transport-Security': 'max-age=7776000; includeSubDomains',
      'X-XSS-Protection': '1; mode=block',
      'X-Content-Type-Options': 'nosniff',
    },
  },
});

const isIgnoredRoute = (route: string): boolean => {
  const cases = [
    /v1\/wordpress(?!\/core\/install)/gim,
    /v1\/ssl\/activate/gim,
    /v1\/ssl\/lifetime-ssl/gim,
    /v1\/domains\/management\/domains\/domain/gim,
    /v1\/emails\/titan/gim,
    /v1\/cloudflare\/activate/gim,
    /v1\/emails\/google-gsuite\/setup/gim,
    /v1\/domains\/management\/dns\//gim,
  ];

  return !!cases.find((stringCase) => {
    const regexp = new RegExp(stringCase);

    return regexp.test(route);
  });
};

const isExcludedError = (message: string): boolean => {
  const cases = [
    /Your\sWordpress\sinstallation\sis\sinvalid/gim,
    'Missing param `order_id`',
    'Transfer info is not found.',
    'Wordpress is already installed in this directory',
    'Domain is not owned by the same user',
    'Patchstack is already installed.',
    'Failed to retrieve Domain details',
    'Domain must be a valid value',
    'Domain is reserved and can not be registered',
    'Domain/Subdomain is already in use.',
    'Domain can not start with www.',
    'Domain is not yet registered.',
    'IDN is not supported for this domain extension.',
    'Token has expired.',
    'Something went wrong',
    'Cannot find order_id',
    'Domain does not exist',
    'Provided domain does not exist for this username',
    'Username not provided',
    'DNS resource record is not valid or conflicts with another resource record',
  ];

  return !!cases.find((stringCase) => {
    const regexp = new RegExp(stringCase);

    return regexp.test(message);
  });
};

const showErrorMessage = (errorMessage: string): void => {
  if (isArray(errorMessage)) {
    errorMessage.forEach((message) => {
      if (isExcludedError(message)) return;
      // @ts-ignore
      toastr.e($t(message));
    });

    return;
  }

  if (isObject(errorMessage)) {
    (Object.values(errorMessage) as string[]).forEach((message: string) => {
      if (isExcludedError(message)) return;

      if (isArray(message)) {
        message.forEach((msg) => {
          if (isExcludedError(msg)) return;
          // @ts-ignore
          toastr.e($t(msg));
        });

        return;
      }

      // @ts-ignore
      toastr.e($t(message));
    });

    return;
  }

  if (isExcludedError(errorMessage)) return;
  // @ts-ignore
  toastr.e($t(errorMessage));
};

//REQUEST INTERCEPTOR - auth token
axiosInstance.interceptors.request.use(async (req) => {
  await checkAndWaitForTokenRefresh();

  const token = getJwtToken();

  if (!token) {
    await appForcedLogout();

    return req;
  }

  if (req.headers) {
    req.headers.Authorization = token;
  }

  return req;
});

// REQUEST INTERCEPTOR - camel to snake
axiosInstance.interceptors.request.use((req) => {
  if (req.plain) return req;

  if (req.data) {
    req.data = camelToSnakeObj(req.data);
  }

  if (req.params) {
    req.params = camelToSnakeObj(req.params);
  }

  return req;
});

// REQUEST INTERCEPTOR - global hosting order headers
axiosInstance.interceptors.request.use((req) => {
  if (req.noHostingHeaders) return req;

  const currentAccount =
    (!req.domain
      ? store.getters.getCurrentAccount
      : store.getters.getCurrentAccountWithDomain(req.domain)) ||
    store.getters.getFirstActiveAccountByOrder(req?.params?.orderId);

  const { username, referenceId, domain } = currentAccount || {};

  if (req.hostingHeaderRequired && !currentAccount) {
    throw new Error('hostingHeadersNotFound');
  }
  if (username && !req.headers[Header.USERNAME]) {
    req.headers[Header.USERNAME] = username;
  }
  if (referenceId && !req.headers[Header.ORDER_ID]) {
    req.headers[Header.ORDER_ID] = referenceId;
  }
  if (domain && !req.headers[Header.DOMAIN]) {
    req.headers[Header.DOMAIN] = domain;
  }

  return req;
});

// REQUEST INTERCEPTOR - add Idempotency-Key
axiosInstance.interceptors.request.use((req) => {
  if (req.withIdempotencyKey) {
    req.headers[Header.IDEMPOTENCY_KEY] = cacheService.generateKey(
      req.url as string,
      {
        params: req.params,
        data: req.data,
        method: req.method,
        headers: req.headers,
      },
    );
  }

  return req;
});

// REQUEST INTERCEPTOR - uuid header
axiosInstance.interceptors.request.use((req) => {
  const uuid = uuidv4();

  req.headers = req.headers ?? {};

  req.headers[Header.CORRELATION_ID] = uuid;

  return req;
});

// REQUEST INTERCEPTOR - google analytics
axiosInstance.interceptors.request.use((req) => {
  const gaCookie = cookies.get(Cookie.GA_ID);
  if (!gaCookie) return req;

  if (req.hBillingGaTracking) {
    req.data = {
      ...req.data,
      metadata: {
        ...req.data.metadata,
        analyticsData: [
          {
            key: Cookie.GA_ID,
            value: gaCookie,
          },
        ],
      },
    };

    return req;
  }

  req.params = {
    ...req.params,
    gaid: gaCookie,
  };

  return req;
});

const getErrorResponseInterceptor = async (error: any) => {
  const profileStore = useProfileStore();

  try {
    if (error.message === 'hostingHeadersNotFound') {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        error: error.message,
      });
    }
    const responseConfig = error.response?.config;
    const status = error.response?.status;

    const requestUrl = responseConfig?.url || error?.config?.url;

    const hideToastr = responseConfig?.hideToastr;

    const errorResponseData = error.response?.data;
    const dataError = errorResponseData?.error;
    const message =
      dataError?.inputs || dataError?.validation_messages || dataError?.message;
    const httpMethod = error?.config?.method;
    const isAccessManager = profileStore.isAccessManager ?? false;
    const isForbiddenGetRequest = status === 403 && httpMethod === 'get';
    const isErrorVisibleForClientManager =
      (isAccessManager && !isForbiddenGetRequest) || !isAccessManager;

    if (axios.isCancel(error)) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        error: error.message,
        url: requestUrl,
        isCanceledRequest: true,
      });
    }

    handleCommonErrors(error);

    const originalRequest = error.config;

    if (status === 401 && !originalRequest._retry) {
      const tokenFromRequest = originalRequest.headers[Header.AUTHORIZATION];

      // Initiate token refresh. Waiting for it
      // to complete is handled in request interceptor.
      refreshToken(tokenFromRequest);

      // Mark original request with retry flag
      // so it would be retried only once to
      // prevent infinite token refresh loop.
      originalRequest._retry = true;

      return axiosInstance(originalRequest);
    }

    if (
      !isIgnoredRoute(requestUrl) &&
      message &&
      !hideToastr &&
      isErrorVisibleForClientManager
    ) {
      showErrorMessage(message);
    }

    const shouldIgnoreLogForAccessManager =
      isAccessManager && isForbiddenGetRequest;

    if (!shouldIgnoreLogForAccessManager) {
      logAxiosError(error);
    }

    const finalError = isFlatErrorResponse(errorResponseData)
      ? {
          message: errorResponseData.error,
          code: errorResponseData.code,
          params: errorResponseData.params,
        }
      : dataError;

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      error: finalError,
      response: error?.response,
      url: requestUrl,
    });
  } catch (error) {
    errorLogger.logError(
      new Error('Error in errorResponseInterceptor', { cause: error }),
    );
  }
};

// RESPONSE INTERCEPTOR

axiosInstance.interceptors.response.use((res) => {
  const domain = res.config.headers[Header.DOMAIN];

  if (res.config.withTokenUpdate) {
    setAuthToken(res.data.data.token);
  }

  return snakeToCamelObj({
    data: typeof res.data.data !== 'undefined' ? res.data.data : res.data,
    links: res.data.links,
    next_offset: res.data.next_offset,
    meta: res.data.meta,
    request_domain: domain,
    is_replay: res.headers[Header.IS_REPLAY] === 'true',
  });
}, getErrorResponseInterceptor);

hDomainsStatus.setAxiosInstance(axiosInstance);
hDomainsStatus.setConfig({
  WEB_HOSTING_API_URL: process.env.VITE_API_REST!,
  HDOMAINS_API_URL: process.env.VITE_API_DOMAINS!,
  PREVIEW_DOMAIN: [
    process.env.VITE_BUILDER_PREVIEW_URL!,
    process.env.VITE_WORDPRESS_PREVIEW_URL!,
  ],
  AXIOS_REQUEST_CONFIG: {
    hideToastr: true,
  },
});

const httpService = {
  get: async (url: string, config?: RequestConfig) => {
    if (!config?.cache && !config?.cacheOnly && !config?.overrideCache) {
      return axiosInstance.get(url, config);
    }

    if (config?.overrideCache) {
      const data = await axiosInstance.get(url, config);

      await cacheService.storeHttpCall(url, config, data);

      return data;
    }

    try {
      const cachedRequest = await cacheService.getHttpCall(url, config);

      if (cachedRequest) return cachedRequest.data;
      if (config?.cacheOnly) return cachedRequest || { data: null };

      const data = await axiosInstance.get(url, config);

      await cacheService.storeHttpCall(url, config, data);

      return data;
    } catch (error) {
      return axiosInstance.get(url, config);
    }
  },
  post: (url: string, data?: any, config?: RequestConfig) =>
    axiosInstance.post(url, data, config),
  put: (url: string, data?: any, config?: RequestConfig) =>
    axiosInstance.put(url, data, config),
  patch: (url: string, data?: any, config?: RequestConfig) =>
    axiosInstance.patch(url, data, config),
  delete: (url: string, config?: RequestConfig) =>
    axiosInstance.delete(url, config),
  request: (config: RequestConfig) => axiosInstance(config),
};

export default httpService;
