<script lang="ts" setup>
import { computed } from 'vue';

type Props = {
  detail?: {
    text: string;
    info: string;
    tooltip?: string;
    bold?: boolean;
    largerFont?: boolean;
    includeCoupon?: boolean;
  };
};

const props = defineProps<Props>();

const dataQa = computed(
  () =>
    `purchase-detail-${(props.detail?.text || '')
      .replace(' & ', ' ')
      .replace(/\s/g, '-')
      .toLowerCase()}`,
);
</script>

<template>
  <li class="details-item">
    <div class="details-item__left">
      <h4
        v-trans
        class="details-item__text"
        :class="{ 'text-bold-1': detail?.largerFont }"
      >
        {{ detail?.text }}
      </h4>
      <!-- @vue-ignore -->
      <HpIcon
        v-if="detail?.tooltip"
        v-tooltip.bottom="detail?.tooltip"
        :icon="('info-gray' as any)"
        view-box="0 0 16 16"
        :width="16"
        :height="16"
        class="details-item__icon"
      />
    </div>
    <div class="details-item__right">
      <p
        class="details-item__price"
        :class="{
          'details-item__price--bold': detail?.bold,
          'text-bold-1': detail?.largerFont,
        }"
        :data-qa="dataQa"
      >
        {{ detail?.info }}
      </p>
    </div>
  </li>
</template>

<style lang="scss" scoped>
.details-item {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  &__right {
    position: relative;
  }

  &__loader {
    right: 16px;
  }

  &__text {
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 0;
  }

  &__icon {
    cursor: pointer;
  }

  &__price {
    color: var(--dark);

    &--bold {
      font-weight: 700;
    }
  }
}
</style>
