import { router } from '@/router';
import store from '@/store';

export const getCurrentOrderDetails = () => {
  const currentDomain = router.currentRoute.value.params.domain;
  const currentAccount = !currentDomain
    ? store.getters.getCurrentAccount
    : store.getters.getCurrentAccountWithDomain(currentDomain);

  const { username, referenceId, domain } = currentAccount || {};

  return {
    username,
    orderId: referenceId,
    domain,
  };
};
