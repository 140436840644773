import { computed } from 'vue';
import { useRoute, type RouteRecordName } from 'vue-router';

import {
  useBrands,
  useExperiment,
  useGlobals,
  useProfile,
} from '@/composables';
import { useProfileStore, useReferralStore } from '@/stores';
import { Country, Experiment, HIcon, Referral, Route } from '@/types';
import { isCurrentTimeWithinDates, isDateInPast } from '@/utils/helpers';

const REFERRALS_HOME_BUTTON_CHANGES_START_DATE = '2024-08-19T09:00:00Z';

const REFERRAL_ROUTES = [
  Route.Referral.REFFER_AND_EARN,
  Route.Referral.MY_REFERRALS,
] as RouteRecordName[];

export const useReferralsPromotion = () => {
  const { t } = useGlobals();
  const route = useRoute();
  const { accountDaysRegistered } = useProfile();
  const { isFromAnotherBrand } = useBrands();
  const { country } = useProfileStore();
  const referralsStore = useReferralStore();
  const profileStore = useProfileStore();
  const { isExperimentActive } = useExperiment(
    Experiment.ID.WEBPRO_REFERRALS_BANNERS_COPY,
  );

  const promotions = computed(() => [
    {
      title: t(
        isExperimentActive.value
          ? "We've paid out 1.4M to referral members so far! Get up to {openTag}400 USD{closeTag} for each friend you  refer to Cloud & VPS plans."
          : 'Earn up to {openTag}400 USD{closeTag} for each friend you refer to a Cloud or VPS plan by September 1.',
        {
          openTag: '<strong>',
          closeTag: '</strong>',
        },
      ),
      placements: [
        Route.Websites.WEBSITE_DASHBOARD,
        Route.Websites.BUILDER_DASHBOARD,
        Route.Base.SERVERS,
      ],
      startDate: '2024-08-19T09:00:00Z',
      endDate: '2024-09-01T23:59:59Z',
      key: Referral.PromotionalTopBannerKey.CLOUD_VPS,
      backgroundColor: 'meteorite-light',
      textColor: 'primary-dark',
      noTimer: true,
      buttonColor: 'primary-dark',
      closeButtonColor: 'primary-charts',
      icon: {
        name: HIcon.ICON_BADGE_DISCOUNT,
        color: 'primary-dark',
      },
    },
    {
      title: t(
        'Earn up to {openTag}400 USD{closeTag} per referral. Your referral will get a 20% discount too.',
        {
          openTag: '<strong>',
          closeTag: '</strong>',
        },
      ),
      placements: [
        Route.Websites.WEBSITE_DASHBOARD,
        Route.Websites.BUILDER_DASHBOARD,
        Route.Base.SERVERS,
      ],
      startDate: '2024-08-05T09:00:00Z',
      endDate: '2024-08-18T23:59:59Z',
      key: Referral.PromotionalTopBannerKey.ALL_PLANS,
      backgroundColor: 'meteorite-dark',
      textColor: 'white',
      timerVariant: 'white',
      icon: {
        name: HIcon.ICON_BADGE_DISCOUNT,
        color: 'white',
      },
      closeButtonColor: 'white',
    },
  ]);

  const isInReferralsRoute = computed(() =>
    REFERRAL_ROUTES.includes(route.name as RouteRecordName),
  );

  const isInRoute = (promotion: (typeof promotions.value)[number]) => {
    if (isInReferralsRoute.value) {
      return true;
    }

    const placements = promotion.placements as RouteRecordName[];

    return placements.includes(route.name as RouteRecordName);
  };

  const activePromotion = computed(() => {
    const isNonEligibleBrand =
      isFromAnotherBrand.value || country === Country.Code.RU;

    if (
      accountDaysRegistered.value < 7 ||
      isNonEligibleBrand ||
      referralsStore.isMemberDisabled ||
      profileStore.isAccessManager
    ) {
      return null;
    }

    const promotion = promotions.value.find(
      (promotion) =>
        isCurrentTimeWithinDates({
          startsAt: promotion.startDate,
          endsAt: promotion.endDate,
        }) && isInRoute(promotion),
    );

    if (!promotion) {
      return null;
    }

    return {
      ...promotion,
      isCloseButtonVisible: !isInReferralsRoute.value,
      isButtonVisible: !isInReferralsRoute.value,
    };
  });

  const activePromotionByTime = computed(() =>
    promotions.value.find((promotion) =>
      isCurrentTimeWithinDates({
        startsAt: promotion.startDate,
        endsAt: promotion.endDate,
      }),
    ),
  );

  const isHomeButtonChangesActive = computed(() =>
    isDateInPast(REFERRALS_HOME_BUTTON_CHANGES_START_DATE),
  );

  return {
    activePromotion,
    isInReferralsRoute,
    activePromotionByTime,
    isHomeButtonChangesActive,
  };
};
