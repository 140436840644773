//JSON-slugs-migrated
import type { IHResourceItem } from '@/types';
import type { ResourceWithSubscription } from '@/utils/mappers/resourcesMapper';

export const WIDGET_TYPE = {
  PLACEHOLDER: 'placeholder',
  REFERRAL_COMMISSION_PAYOUT: 'referralCommissionPayout',
  WEBSITE_MALWARE_SCANNER: 'websiteMalwareScanner',
  WEBSITE_PERFORMANCE_SCORE: 'websitePerformanceScore',
  WEBSITE_TOP_COUNTRY_VISITORS: 'websiteTopCountryVisitors',
  WEBSITE_NETWORK_PERFORMANCE: 'websiteNetworkPerformance',
  HOSTING_RESOURCE_USAGE: 'hostingResourceUsage',
  HOSTING_DISK_USAGE: 'hostingDiskUsage',
  HOSTING_INODES_USAGE: 'hostingInodesUsage',
  HOSTING_LATEST_WEBSITES: 'hostingLatestWebsites',
  LATEST_DOMAINS: 'latestDomains',
  EMAIL_LATEST_EMAIL_PLANS: 'emailLatestEmailPlans',
} as const;

/**
 * Available Hosting Resource Widget Types
 */
export const HOSTING_RESOURCE_WIDGET_TYPES = [
  WIDGET_TYPE.WEBSITE_MALWARE_SCANNER,
  WIDGET_TYPE.WEBSITE_PERFORMANCE_SCORE,
  WIDGET_TYPE.WEBSITE_NETWORK_PERFORMANCE,
  WIDGET_TYPE.WEBSITE_TOP_COUNTRY_VISITORS,
  WIDGET_TYPE.HOSTING_RESOURCE_USAGE,
  WIDGET_TYPE.HOSTING_DISK_USAGE,
  WIDGET_TYPE.HOSTING_INODES_USAGE,
  WIDGET_TYPE.HOSTING_LATEST_WEBSITES,
] as const;

/**
 * Available Website Widget Types
 */
export const WEBSITE_WIDGETS = [
  WIDGET_TYPE.WEBSITE_MALWARE_SCANNER,
  WIDGET_TYPE.WEBSITE_NETWORK_PERFORMANCE,
  WIDGET_TYPE.WEBSITE_PERFORMANCE_SCORE,
  WIDGET_TYPE.WEBSITE_TOP_COUNTRY_VISITORS,
] as const;

export const BUILDER_WIDGETS = [WIDGET_TYPE.HOSTING_LATEST_WEBSITES] as const;

/**
 * Widget types that can be added only once
 */
export const SINGLE_WIDGET_TYPES = [
  WIDGET_TYPE.REFERRAL_COMMISSION_PAYOUT,
  WIDGET_TYPE.LATEST_DOMAINS,
  WIDGET_TYPE.EMAIL_LATEST_EMAIL_PLANS,
] as const;

export const WIDGET_CONFIGURATION = {
  [WIDGET_TYPE.REFERRAL_COMMISSION_PAYOUT]: {
    type: WIDGET_TYPE.REFERRAL_COMMISSION_PAYOUT,
    component: 'CustomWidgetCommissionPayout',
    title: 'v2.referrals.commission.payout',
    previewFooterTitle: 'v2.referrals.commission.payout',
  },
  [WIDGET_TYPE.HOSTING_RESOURCE_USAGE]: {
    type: WIDGET_TYPE.HOSTING_RESOURCE_USAGE,
    component: 'CustomWidgetResourceUsage',
    title: 'v2.resources.usage',
    previewFooterTitle: 'v2.hosting.plans.resources.usage',
  },
  [WIDGET_TYPE.WEBSITE_NETWORK_PERFORMANCE]: {
    type: WIDGET_TYPE.WEBSITE_NETWORK_PERFORMANCE,
    component: 'CustomWidgetNetworkPerformance',
    title: 'v2.network.performance',
    previewFooterTitle: 'v2.websites.network.performance',
  },
  [WIDGET_TYPE.WEBSITE_PERFORMANCE_SCORE]: {
    type: WIDGET_TYPE.WEBSITE_PERFORMANCE_SCORE,
    component: 'CustomWidgetPerformanceScore',
    title: 'v2.performance.score',
    previewFooterTitle: 'v2.websites.performance.score',
  },
  [WIDGET_TYPE.WEBSITE_TOP_COUNTRY_VISITORS]: {
    type: WIDGET_TYPE.WEBSITE_TOP_COUNTRY_VISITORS,
    component: 'CustomWidgetVisitors',
    title: 'v2.top.visitors.by.country',
    previewFooterTitle: 'v2.websites.top.visitors.by.country',
    selectedWebsite: 'domain-1.com',
  },
  [WIDGET_TYPE.HOSTING_DISK_USAGE]: {
    type: WIDGET_TYPE.HOSTING_DISK_USAGE,
    component: 'CustomWidgetDiskUsage',
    title: 'v2.disk.usage',
    previewFooterTitle: 'v2.hosting.plans.disk.usage',
  },
  [WIDGET_TYPE.HOSTING_INODES_USAGE]: {
    type: WIDGET_TYPE.HOSTING_INODES_USAGE,
    component: 'CustomWidgetInodesUsage',
    title: 'v2.inodes',
    previewFooterTitle: 'v2.hosting.plans.inodes',
  },
  [WIDGET_TYPE.WEBSITE_MALWARE_SCANNER]: {
    type: WIDGET_TYPE.WEBSITE_MALWARE_SCANNER,
    component: 'CustomWidgetMalwareScanner',
    title: 'v2.security.status',
    previewFooterTitle: 'v2.websites.security.status',
  },
  [WIDGET_TYPE.HOSTING_LATEST_WEBSITES]: {
    type: WIDGET_TYPE.HOSTING_LATEST_WEBSITES,
    component: 'CustomWidgetWebsitesListByHosting',
    title: 'v2.latest.websites',
    previewFooterTitle: 'v2.hosting.plans.latest.websites',
  },
  [WIDGET_TYPE.LATEST_DOMAINS]: {
    type: WIDGET_TYPE.LATEST_DOMAINS,
    component: 'CustomWidgetLatestDomains',
    title: 'v2.my.latest.domains',
    previewFooterTitle: 'v2.all.domains',
  },
  [WIDGET_TYPE.EMAIL_LATEST_EMAIL_PLANS]: {
    type: WIDGET_TYPE.EMAIL_LATEST_EMAIL_PLANS,
    component: 'CustomWidgetLatestEmailPlans',
    title: 'v2.latest.email.plans',
    previewFooterTitle: 'v2.all.email.plans',
  },
} as const;

export type WidgetType = (typeof WIDGET_TYPE)[keyof typeof WIDGET_TYPE];
export type WidgetWithoutPlaceholderType = Exclude<WidgetType, 'placeholder'>;

export type IWidgetConfiguration =
  (typeof WIDGET_CONFIGURATION)[keyof typeof WIDGET_CONFIGURATION];

export interface IWidget {
  type: WidgetType;
  component: IWidgetConfiguration['component'];
  title: IWidgetConfiguration['title'];
  previewFooterTitle: IWidgetConfiguration['previewFooterTitle'];
  indexPosition: number;
  domainName?: string | null;
  resourceId?: string | null;
}

export type IWidgetWithoutIndex = Omit<IWidget, 'indexPosition'>;

export interface IWidgetResponse {
  id: number;
  type: WidgetWithoutPlaceholderType;
  indexPosition: number;
  domainName: string | null;
  resourceId: string | null;
  customerId: string;
  createdAt: string;
  updatedAt: string;
}

export interface IWidgetRequest {
  type: WidgetWithoutPlaceholderType;
  indexPosition: number;
  resourceId?: string;
  domainName?: string;
}

export interface WidgetProps {
  widget: IWidget;
  isPreview?: boolean;
  isEditMode?: boolean;
}

export type ResourceOrItem = ResourceWithSubscription | IHResourceItem;

export const MAX_WIDGET_COUNT = 6 as const;
export const MAX_WIDGETS_PER_ROW = 3 as const;
