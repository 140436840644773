import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { hResourcesRepo } from '@/repositories';
import type { ProWebsiteResource } from '@/types';
import {
  HRESOURCES_STATE,
  HRESOURCES_TYPE,
  STORE_PERSISTENT_KEYS,
} from '@/types';
import { errorLogger } from '@/utils/services/errorLogging';

export const useManagedResourcesStore = defineStore(
  'managedResourcesStore',
  () => {
    const managedResources = ref<ProWebsiteResource[]>([]);
    const isManagedResourcesLoading = ref(false);
    const isLoaded = ref(false);

    const getManagedPendingHostingResources = computed(() =>
      managedResources.value.filter(
        (resource: ProWebsiteResource) =>
          resource.type === HRESOURCES_TYPE.HOSTING &&
          resource.state === HRESOURCES_STATE.PENDING,
      ),
    );

    const getManagedResourceBySubscriptionId = (subscriptionId: string) =>
      managedResources.value.find(
        (resource) => resource.chargebeeSubscriptionId === subscriptionId,
      );

    const getIsDomainManaged = (domain: string) =>
      managedResources.value.some(
        ({ type, title }) =>
          type === HRESOURCES_TYPE.DOMAIN && title === domain,
      );

    const fetchManagedResources = async (
      { requestData, requestConfig } = {
        requestData: {},
        requestConfig: { overrideCache: true },
      },
    ) => {
      isManagedResourcesLoading.value = true;
      const [{ data }, error] =
        await hResourcesRepo.getProPendingSetupResources(
          requestData,
          requestConfig,
        );

      if (error) {
        isManagedResourcesLoading.value = false;
        errorLogger.logError(error, 'get pro pending setup resources error');

        return [{ data }, error];
      }

      managedResources.value = data;
      isManagedResourcesLoading.value = false;
      isLoaded.value = true;

      return [{ data }, error];
    };

    return {
      managedResources,
      isManagedResourcesLoading,
      isLoaded,
      getManagedPendingHostingResources,
      getManagedResourceBySubscriptionId,
      fetchManagedResources,
      getIsDomainManaged,
    };
  },
  { persist: { key: STORE_PERSISTENT_KEYS.MANAGED_RESOURCES_STORE } },
);
