import type { ModalObject } from '@/types';

const imgSrc = 'mailbox.svg';

export default [
  {
    name: 'ClaimFreeEmailServiceModal',
    type: 'HModal',
    props: {
      title: 'Set Up New Plan',
      subtitle: 'Choose the domain you want to set up on your free email plan',
      hideX: true,
    },
  },
  {
    name: 'EmailsCreateForwarder',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      title: 'Create a Forwarder',
      subtitle:
        'Take total control over your inboxes by redirecting emails to your chosen forwarding address',
      imgSrc,
    },
  },
  {
    name: 'EmailsCreateCatchAllSingle',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      title: 'Set email account as catch-all email?',
      imgSrc,
    },
  },
  {
    name: 'EmailsCreateCatchAll',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      title: 'Create a Catch-all Email Account',
      subtitle:
        'Choose a domain and an email account to create your catch-all email account',
      imgSrc,
    },
  },
  {
    name: 'EmailsAccountSettings',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      title: 'Email Account Settings',
      imgSrc,
    },
  },
  {
    name: 'EmailsCreateAccount',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      imgSrc,
    },
  },
  {
    name: 'EmailsDeleteForwarder',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      title: 'Delete forwarder?',
      imgSrc,
    },
  },
  {
    name: 'EmailsDeleteCatchAll',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      title: 'Delete catch-all?',
      imgSrc,
    },
  },
  {
    name: 'EmailsDeleteAlias',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      title: 'Delete alias?',
      imgSrc,
    },
  },
  {
    name: 'EmailsDeleteAccount',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      title: 'Delete email account?',
      imgSrc,
    },
  },
  {
    name: 'EmailsDeleteAutoReply',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      title: 'Delete Automatic Reply?',
      imgSrc,
    },
  },
  {
    name: 'EmailsCreateAlias',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      title: 'Create Alias',
      subtitle: 'Create an Alias for your email account',
      imgSrc,
    },
  },
  {
    name: 'EmailsPlanDetails',
    type: 'HModal',
  },
  {
    name: 'EmailsIOSConfig',
    type: 'HModal',
    closingType: 'input',
    steps: [
      {
        title: 'Automatic Apple device setup',
        subtitle:
          'To receive the auto-configuration link, type the display name and choose the email account you want to configure',
        hideX: true,
        xl: true,
      },
      {
        title: 'Auto-configuration is ready!',
        subtitle: 'To set up Apple automatically follow the steps below.',
        hideX: true,
        xl: true,
      },
    ],
  },
  {
    name: 'EmailsEditForwarder',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      title: 'Edit Forwarder Settings',
      imgSrc,
    },
  },
  {
    name: 'EmailsChangePassword',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      title: 'Change email account password',
      imgSrc,
    },
  },
  {
    name: 'EmailsManageDomainSettings',
    type: 'HModalFancy',
    subtype: 'Emails',
    props: {
      title: 'Manage Domain Settings',
      imgSrc,
    },
  },
  {
    name: 'EmailsRemoveDkimRecordModal',
    type: 'HModal',
    closingType: 'notification',
    props: {
      hideX: true,
      title: 'Remove DKIM Record',
    },
  },
  {
    name: 'EmailsResetDnsModal',
    type: 'HModal',
    closingType: 'notification',
    props: {
      hideX: true,
    },
  },
  {
    name: 'EmailsDkimRecordDetailsModal',
    type: 'HModal',
    closingType: 'input',
    props: {
      hideX: true,
      title: 'DKIM Details',
    },
  },
  {
    name: 'EmailsTrialModal',
    type: 'HModal',
    closingType: 'payment',
    props: {
      hideX: true,
      title: 'Start a Free 30 Days Trial',
      subtitle: 'Check the information regarding your free email trial plan',
    },
  },
  {
    name: 'EmailsTrialLimitReachedModal',
    type: 'HModal',
    closingType: 'input',
    props: {
      hideX: true,
    },
  },
  {
    name: 'EmailsFixDnsRecordsModal',
    type: 'HModal',
    closingType: 'input',
    props: {
      hideX: true,
    },
  },
  {
    name: 'EmailsTrialUpgradePlanModal',
    type: 'HModal',
    closingType: 'input',
    props: {
      hideX: true,
    },
  },
  {
    name: 'EmailsClaimDealModal',
    type: 'HModal',
    closingType: 'input',
    props: {
      hideX: true,
      title: 'Choose an Email Plan',
    },
  },
  {
    name: 'EmailDisplayNameModal',
    type: 'HModal',
    subtype: 'Emails',
    props: {
      title: 'Edit Display Name',
      noHeaderMargin: true,
      hideX: true,
    },
  },
  {
    name: 'AdditionalAccountsServiceSelector',
    type: 'HModal',
    subtype: 'Emails',
    props: {
      title: 'Get an additional mailbox',
      subtitle: 'Select which domain you want to add a new email mailbox to.',
      hideX: true,
    },
  },
] as ModalObject[];
