import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useGlobals, useWebproPurchase } from '@/composables';
import { router } from '@/router';
import type {
  PricingDetails,
  PricingPeriod,
  ProsumerCart,
  PurchaseType,
} from '@/types';
import { AccountAccess, Route, Service } from '@/types';
import { toASCII } from '@/utils/helpers';
import { monthsPerPeriodUnit } from '@/utils/services/chargebeeCurrencyService';
import currency from '@/utils/services/currencyService';

export const useProsumerCartStore = defineStore(
  'prosumerCart',
  () => {
    const { t } = useGlobals();
    const { calculateHostingPurchaseBonus } = useWebproPurchase();

    const cartData = ref<ProsumerCart | null>(null);
    const productPriceId = ref<string>('');
    const productId = ref<string>('');
    const productCategory = ref<PurchaseType | null>(null);
    const productPricing = ref<PricingDetails | null>(null);
    const discountedProductPricing = ref<PricingDetails | null>(null);
    const productPeriod = ref<PricingPeriod>({
      price: 0,
      period: 0,
      periodUnit: 'month',
    });
    const billingRequest = ref<any>({});
    const isPurchaseForClient = ref(false);
    const recipientEmail = ref('');
    const discountedPriceFormatted = ref('');
    const commissionAmountFormatted = ref('');
    const selectedAccessLevel = ref(AccountAccess.AccessLevel.MANAGE);

    const setCartData = (data: ProsumerCart) => {
      cartData.value = data;
      const { id, category, pricingDetails } = data;
      productPriceId.value = id;
      productCategory.value = category;
      productPricing.value = pricingDetails;
      discountedProductPricing.value = data.discountedPricingDetails || null;
      productPeriod.value = pricingDetails?.period;
      productId.value = data?.productId || '';
      commissionAmountFormatted.value = data?.commissionAmountFormatted || '';
      discountedPriceFormatted.value = data?.discountedPriceFormatted || '';
    };

    const productTitle = computed(() => {
      if (productCategory.value === Service.Type.DOMAIN) {
        return cartData.value?.domain || '';
      }

      return cartData.value?.externalName?.split('(')[0] || '';
    });

    const isCartEmpty = computed(() => !cartData.value);

    const setBillingRequestData = (data: any) => {
      const redirectReturnLink = router.resolve({
        name: Route.Domain.REGISTER_DOMAIN_RESOURCE,
        params: { domain: toASCII(productTitle.value) },
        query: {
          redirect: 'pro_panel_domains',
          isProsumer: 1,
        },
      });
      billingRequest.value = {
        ...data,
        redirectReturn: `${window.origin}${redirectReturnLink.fullPath}`,
      };
    };

    const setAccessLevelSelection = (level: AccountAccess.AccessLevel) => {
      selectedAccessLevel.value = level;
    };

    const hasPaymentOption = computed(
      () => productCategory.value === Service.Type.DOMAIN,
    );

    const hasCommission = computed(() => {
      const { period, periodUnit } = productPeriod.value;
      const isOneMonthPurchase =
        monthsPerPeriodUnit(periodUnit) * Number(period) === 1;

      return (
        !isOneMonthPurchase && cartData.value?.category !== Service.Type.DOMAIN
      );
    });

    const planDuration = computed(() => {
      const { period, periodUnit } = productPeriod.value;
      const modifiedPeriodUnit =
        Number(period) > 1 ? `${periodUnit}s` : periodUnit;

      return t('{period} {periodUnit}', {
        period,
        periodUnit: modifiedPeriodUnit,
      });
    });

    const totalPrice = computed(() => {
      if (discountedPriceFormatted.value) return discountedPriceFormatted.value;
      const price = discountedProductPricing.value || productPricing.value;
      if (!price) return null;

      if ('finalAmount' in price) {
        const finalAmount = price.finalAmount;

        return currency.format(finalAmount / 100);
      }

      return null;
    });

    const commission = computed(() => {
      if (commissionAmountFormatted.value) {
        return commissionAmountFormatted.value;
      }
      const price = productPricing.value;
      if (!price) return '';

      return calculateHostingPurchaseBonus({
        price: price.finalAmount,
        tax: price.totalTax,
        catalogItemId: productId.value,
      });
    });

    const $reset = () => {
      cartData.value = null;
      productPriceId.value = '';
      productCategory.value = null;
      productPricing.value = null;
      productPeriod.value = {
        price: 0,
        period: 0,
        periodUnit: 'month',
      };
      billingRequest.value = {};
      isPurchaseForClient.value = false;
      recipientEmail.value = '';
      selectedAccessLevel.value = AccountAccess.AccessLevel.MANAGE;
    };

    return {
      cartData,
      isCartEmpty,
      productPeriod,
      hasCommission,
      planDuration,
      totalPrice,
      commission,
      productTitle,
      productCategory,
      productPriceId,
      hasPaymentOption,
      productPricing,
      billingRequest,
      isPurchaseForClient,
      recipientEmail,
      productId,
      selectedAccessLevel,
      setAccessLevelSelection,
      setCartData,
      setBillingRequestData,
      $reset,
    };
  },
  {
    persist: true,
  },
);
