<script setup lang="ts">
import type { FieldSchema } from '@hostinger/hcomponents';

import HModalActions from '@/components/HModals/HModalActions.vue';
import { useGlobals } from '@/composables';
import { FieldType } from '@/types';

const REASONS = [
  'I am planning to renew services manually',
  'I am switching to another provider',
  `I don't intend to renew the domain`,
  'Other (please explain below)',
];

type Props = {
  data: {
    onSubmit: () => void;
  };
};

const props = defineProps<Props>();

interface Emits {
  (eventName: 'close'): void;
}

const emits = defineEmits<Emits>();

const { t } = useGlobals();

const tellUsMoreSchema: FieldSchema = {
  type: FieldType.textarea,
  label: 'Tell us more',
  rows: 3,
  validation: { maxKeywords: 500 },
};

const reasonSchema: FieldSchema = {
  type: FieldType.select,
  label: 'Select a reason',
  values: REASONS.map((reason) => ({ label: t(reason), value: reason })),
  validation: {
    required: true,
  },
};
</script>

<template>
  <h2 v-trans class="h-mb-8">Turn-Off Auto Renewal</h2>
  <p v-trans class="h-mb-16">
    Tell us the reason why you want to turn off auto-renewal to further improve
    our product
  </p>
  <HForm @on-submit="props.data.onSubmit">
    <HFormField name="reason" :schema="reasonSchema" class="h-mb-16" />
    <HFormField class="h-mb-24" name="tellUsMore" :schema="tellUsMoreSchema" />
    <HModalActions
      close-text="Cancel"
      mobile-reverse
      no-padding
      @close="emits('close')"
    >
      <HButton v-qa-generate class="h-mb-8 h-mb-0-md" h-form-submit>
        {{ t('Continue') }}
      </HButton>
    </HModalActions>
  </HForm>
</template>
