import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useGlobals, useSalesCallModal } from '@/composables';
import { useSubscriptions } from '@/composables/useSubscriptions';
import { useUpgradeOrRenewExperiment } from '@/composables/useUpgradeOrRenewExperiment';
import billingModalList from '@/data/modals/billingModalList';
import cPanelModalList from '@/data/modals/cPanelModalList';
import domainModalList from '@/data/modals/domainModalList';
import emailsModalList from '@/data/modals/emailsModalList';
import hbillingModalList from '@/data/modals/hbillingModalList';
import hostingModalList from '@/data/modals/hostingModalList';
import hostingerProModalList from '@/data/modals/hostingerProModalList';
import hrefundsModalList from '@/data/modals/hrefundsModalList';
import modalList from '@/data/modals/modalList';
import onboardingModalList from '@/data/modals/onboardingModalList';
import personalInformationModalList from '@/data/modals/personalInformationModalList';
import profileModalList from '@/data/modals/profileModalList';
import purchaseModalList from '@/data/modals/purchaseModalList';
import websitesModalList from '@/data/modals/websitesModalList';
import {
  AmplitudeEvent,
  type ModalObject,
  type ModalObjectProps,
  type ModalObjectSteps,
  type ModalProps,
} from '@/types';
import {
  setModalOpenZIndexes,
  setModalCloseZIndexes,
  capitalise,
} from '@/utils/helpers';
import { snakeToCamel } from '@/utils/services/namingConventionsService';

const ALL_MODALS = [
  ...modalList,
  ...profileModalList,
  ...emailsModalList,
  ...domainModalList,
  ...billingModalList,
  ...websitesModalList,
  ...onboardingModalList,
  ...purchaseModalList,
  ...personalInformationModalList,
  ...hbillingModalList,
  ...hostingModalList,
  ...cPanelModalList,
  ...hrefundsModalList,
  ...hostingerProModalList,
];
const RENEW_MODAL = 'RenewModal';
const RENEW_MODAL_V2 = 'RenewModalV2';
const UPGRADE_OR_RENEW_MODAL = 'UpgradeOrRenewModal';
const HOSTING = 'hosting';

const shouldInjectRenewModalV2 = (name: string, data: ModalProps['data']) =>
  data?.serviceType === HOSTING && name === RENEW_MODAL;

export const useModalsStore = defineStore('modalsStore', () => {
  const currentModalName = ref();
  const currentModalClosingType = ref<ModalObject['closingType']>();
  const currentStep = ref(0);
  const currentData = ref<Record<string, any>>();
  const currentSubtype = ref<string>();
  const currentProps = ref<ModalObjectProps>();
  const stepsData = ref<ModalObjectSteps>();
  const { getSubscriptionById } = useSubscriptions();

  const getSubtypeModal = (currentModal: ModalObject): ModalObject => {
    if (!currentModal.subtypes || !currentSubtype.value) return currentModal;

    if (!currentModal?.subtypes[currentSubtype.value]) return currentModal;

    return currentModal.subtypes[currentSubtype.value].steps
      ? {
          ...currentModal,
          props: {
            ...currentModal.subtypes[currentSubtype.value].steps[
              currentStep.value
            ],
          },
        }
      : {
          ...currentModal,
          props: currentModal.subtypes[currentSubtype.value],
        };
  };

  const getModal = (currentModal: ModalObject) =>
    currentModal.steps
      ? {
          ...currentModal,
          props: {
            ...currentModal.steps[currentStep.value],
          },
        }
      : currentModal;

  const currentModal = computed((): ModalObject | undefined => {
    const foundModal = ALL_MODALS.find(
      (modal) => modal.name === currentModalName.value,
    );

    if (!foundModal) {
      return undefined;
    }

    const modal = foundModal.subtypes
      ? getSubtypeModal(foundModal)
      : getModal(foundModal);

    if (!stepsData.value) return modal;

    return { ...modal, props: stepsData.value[currentStep.value] };
  });

  const setModalData = (data: Record<string, any>) => {
    currentData.value = {
      ...currentData.value,
      ...data,
    };
  };

  const openModal = ({ component, data, subtype, steps }: any) => {
    const [componentName] = Object.keys(component || {});
    setModalOpenZIndexes();

    let subscription;

    if (data?.subscriptionId) {
      subscription = getSubscriptionById(data.subscriptionId);
    }

    const { amplitudeV2 } = useGlobals();

    const { eligibleForUpgradeOrRenew, isUpgradeOrRenewConditionsPassed } =
      useUpgradeOrRenewExperiment();

    currentModalName.value = shouldInjectRenewModalV2(componentName, data)
      ? RENEW_MODAL_V2
      : capitalise(snakeToCamel(componentName));

    currentStep.value = 0;
    currentData.value = data;
    currentSubtype.value = subtype;
    stepsData.value = steps;

    if (
      subscription &&
      [RENEW_MODAL_V2, RENEW_MODAL].includes(currentModalName.value) &&
      isUpgradeOrRenewConditionsPassed(subscription) &&
      !data?.skipUpgradeOrRenewModal
    ) {
      amplitudeV2(AmplitudeEvent.Billing.BILLING_RENEW_ENTER, {
        plan: subscription?.cfSubscriptionParams?.plan,
      });
    }

    if (
      subscription &&
      [RENEW_MODAL_V2, RENEW_MODAL].includes(currentModalName.value) &&
      !data?.skipUpgradeOrRenewModal &&
      eligibleForUpgradeOrRenew(subscription)
    ) {
      currentModalName.value = UPGRADE_OR_RENEW_MODAL;
      stepsData.value = [{ hideX: true, xl: true }];
    }
  };

  const nextStep = (step: number) => {
    if (step !== undefined) {
      currentStep.value = step;

      return;
    }

    currentStep.value = currentStep.value + 1;
  };

  const previousStep = (step: number) => {
    if (step !== undefined) {
      currentStep.value = step;

      return;
    }

    if (step === 0) {
      currentStep.value = 0;

      return;
    }

    currentStep.value = currentStep.value - 1;
  };

  const closeModal = () => {
    setModalCloseZIndexes();
    const { shouldShowSalesCallCTA } = useSalesCallModal();

    currentModalName.value = undefined;
    currentModalClosingType.value = undefined;
    currentData.value = undefined;
    currentSubtype.value = undefined;
    currentStep.value = 0;
    stepsData.value = undefined;

    if (shouldShowSalesCallCTA.value) {
      currentModalName.value = 'SalesCallModal';
      shouldShowSalesCallCTA.value = false;
    }
  };

  const setModalClosingType = (type: any) => {
    if (!currentModalClosingType.value) currentModalClosingType.value = type;
  };

  const updateModalStep = ({ step, data }: { step: number; data: any }) => {
    if (!stepsData.value) return;

    stepsData.value[step] = data;
  };

  return {
    currentModalName,
    currentModalClosingType,
    currentStep,
    currentData,
    currentSubtype,
    currentProps,
    currentModal,
    modals: ALL_MODALS,
    openModal,
    nextStep,
    previousStep,
    closeModal,
    setModalClosingType,
    updateModalStep,
    setModalData,
  };
});
