import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';

import type {
  ICreateReportContactDetails,
  ICreateReportParams,
  ICreateReportStructureDetails,
} from '@/types';

export const useCreateReportStore = defineStore('createReportStore', () => {
  const domain = ref('');
  const details = reactive<ICreateReportParams>({
    title: '',
    introMessage: '',
    contactDetails: {
      companyName: '',
      email: '',
      phone: '',
      websiteAddress: '',
    },
    structure: {},
    deliverToEmail: undefined,
  });

  const setDomain = (targetDomain: string) => {
    domain.value = targetDomain;
  };

  const set = <K extends keyof ICreateReportParams>(
    field: K,
    value: ICreateReportParams[K],
  ) => {
    details[field] = value;
  };

  const setContactDetails = <K extends keyof ICreateReportContactDetails>(
    field: K,
    value: ICreateReportContactDetails[K],
  ) => {
    details.contactDetails[field] = value;
  };

  const setStructureDetails = <K extends keyof ICreateReportStructureDetails>(
    field: K,
    value: ICreateReportStructureDetails[K],
  ) => {
    if (!details.structure) details.structure = {};
    details.structure[field] = value;
  };

  const resetContactDetails = () => {
    details.contactDetails = {
      companyName: '',
      email: '',
      phone: '',
      websiteAddress: '',
    };
  };

  const $reset = () => {
    domain.value = '';
    details.title = '';
    details.introMessage = '';
    details.structure = {};
    details.deliverToEmail = undefined;
    resetContactDetails();
  };

  return {
    domain,
    details,
    setStructureDetails,
    set,
    setContactDetails,
    setDomain,
    resetContactDetails,
    $reset,
  };
});
