import { cdnRepo } from '@/repositories';
import type {
  HCdnState,
  CdnDataModel,
  CdnOptionsDataModel,
  CommitFunction,
  DispatchFunction,
} from '@/types';
import { HCdnMutations, HCdnGetters, HCdnActions } from '@/types';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

const state: HCdnState = {
  isLoading: false,
  cdns: [],
  cdnOptions: null,
  cdnDetails: null,
  cdnPerformanceData: null,
};

const mutations = {
  [HCdnMutations.SET_CDNS_DATA](state: HCdnState, data: CdnDataModel[]) {
    state.cdns = data;
  },
  [HCdnMutations.SET_CDN_OPTIONS_DATA](
    state: HCdnState,
    data: CdnOptionsDataModel,
  ) {
    state.cdnOptions = data;
  },
  [HCdnMutations.SET_CDN_DETAILS_DATA](state: HCdnState, data: CdnDataModel) {
    state.cdnDetails = data;
  },
  [HCdnMutations.SET_CDN_PERFORMANCE_DATA](state: HCdnState, data: any) {
    state.cdnPerformanceData = data;
  },
};

const getters = {
  [HCdnGetters.GET_CDNS_DATA]: (state: HCdnState) => state.cdns,
  [HCdnGetters.GET_CDN_OPTIONS_DATA]: (state: HCdnState) => state.cdnOptions,
  [HCdnGetters.GET_CDN_DETAILS_DATA]: (state: HCdnState) => state.cdnDetails,
  [HCdnGetters.GET_CDNS_LOADING]: (state: HCdnState) => state.isLoading,
  [HCdnGetters.GET_CDN_PERFORMANCE_DATA]: (state: HCdnState) =>
    state.cdnPerformanceData,
};

const actions = {
  async [HCdnActions.FETCH_CDNS_DATA]({ commit }: CommitFunction) {
    const [{ data }, err] = await cdnRepo.getCdnList();

    if (!data || err) return;

    commit(HCdnMutations.SET_CDNS_DATA, data);
  },

  async [HCdnActions.RESET_CDN_DETAILS_OPTIONS_DATA]({
    commit,
  }: CommitFunction) {
    commit(HCdnMutations.SET_CDN_DETAILS_DATA, null);
    commit(HCdnMutations.SET_CDN_OPTIONS_DATA, null);
  },
  async [HCdnActions.FETCH_CDN_DETAILS_DATA](
    { commit }: CommitFunction,
    { domain }: { domain: string },
  ) {
    const [{ data }, err] = await cdnRepo.getCdnDetails({ domain });
    if (!data || err) return;

    commit(HCdnMutations.SET_CDN_DETAILS_DATA, data);
  },

  async [HCdnActions.FETCH_CDN_OPTIONS_DATA](
    { commit }: CommitFunction,
    { domain }: { domain: string },
  ) {
    const [{ data }, err] = await cdnRepo.getCdnOptions({ domain });
    if (!data || err) return;

    commit(HCdnMutations.SET_CDN_OPTIONS_DATA, data);
  },

  async [HCdnActions.CHANGE_CDN_STATUS](
    { dispatch }: DispatchFunction,
    { domain, enable }: { domain: string; enable: boolean },
  ) {
    const [{ data }, err] = await cdnRepo.changeCdnStatus({ domain, enable });

    if (!data || err) return;

    dispatch(HCdnActions.FETCH_CDNS_DATA);
    toastr.s(i18n.t(`CDN is ${enable ? 'enabled' : 'disabled'} successfully`));
  },

  async [HCdnActions.FETCH_CDN_PERFORMANCE_DATA](
    { commit }: CommitFunction,
    { domain }: { domain: string },
  ) {
    const [{ data }, err] = await cdnRepo.getCdnPerformance({ domain });

    if (!data || err) {
      return commit(HCdnMutations.SET_CDN_PERFORMANCE_DATA, null);
    }

    commit(HCdnMutations.SET_CDN_PERFORMANCE_DATA, data);
  },

  async [HCdnActions.CHANGE_LATEST_TLS_STATUS](
    { dispatch }: DispatchFunction,
    { domain, enabled }: { domain: string; enabled: boolean },
  ) {
    const [{ data }, err] = await cdnRepo.postLatestTLS({ domain, enabled });

    if (!data || err) return;

    await dispatch(HCdnActions.FETCH_CDN_DETAILS_DATA, {
      domain,
    });
    toastr.s(
      i18n.t(`TLS 1.3 is ${enabled ? 'enabled' : 'disabled'} successfully`),
    );
  },

  async [HCdnActions.CHANGE_DEV_STATUS](
    { dispatch }: DispatchFunction,
    { domain, enabled }: { domain: string; enabled: boolean },
  ) {
    const [{ data }, err] = await cdnRepo.setDevMode({ domain, enabled });

    if (!data || err) return;

    dispatch(HCdnActions.FETCH_CDNS_DATA);

    toastr.s(i18n.t(`Development mode is ${enabled ? 'enabled' : 'disabled'}`));
  },

  async [HCdnActions.FLUSH_CACHE](
    _: HCdnState,
    { domain }: { domain: string },
  ) {
    const [{ data }, err] = await cdnRepo.flushCache({ domain });

    if (!data || err) return;

    toastr.s(i18n.t('Cache is successfully purged'));
  },

  async [HCdnActions.UPDATE_CDN_OPTION](
    { dispatch }: DispatchFunction,
    params: CdnOptionsDataModel,
  ) {
    const [{ data }, err] = await cdnRepo.updateCdnOption(params);
    if (!data || err) return;
    await dispatch(HCdnActions.FETCH_CDN_OPTIONS_DATA, {
      domain: params.domain,
    });

    if (params.withToastr) {
      toastr.s(
        i18n.t('Smart image optimisation settings are saved successfully'),
      );
    }
  },

  async [HCdnActions.UPDATE_CDN_COUNTRY_DENY_LIST](
    { dispatch }: DispatchFunction,
    params: { domain: string; blockList: string[] },
  ) {
    const [{ data }, err] = await cdnRepo.postBlockCountries({
      domain: params.domain,
      countries: params.blockList,
    });
    if (!data || err) return;

    await dispatch(HCdnActions.UPDATE_CDN_OPTION, {
      domain: params.domain,
      wafEnabled: true,
      wafDefaultPolicy: 'allow',
    });

    await dispatch(HCdnActions.FETCH_CDN_DETAILS_DATA, {
      domain: params.domain,
    });
  },

  async [HCdnActions.UPDATE_CDN_IP_DENY_LIST](
    { dispatch }: DispatchFunction,
    params: { domain: string; blockList: string[] },
  ) {
    const [{ data }, err] = await cdnRepo.postBlockIp({
      domain: params.domain,
      ips: params.blockList,
    });
    if (!data || err) return;

    await dispatch(HCdnActions.UPDATE_CDN_OPTION, {
      domain: params.domain,
      wafEnabled: true,
      wafDefaultPolicy: 'allow',
    });

    await dispatch(HCdnActions.FETCH_CDN_DETAILS_DATA, {
      domain: params.domain,
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
