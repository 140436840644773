import { hMailIntegrationRepo } from '@/repositories';
import { toASCII } from '@/utils/helpers';

export default {
  state: {
    flockOrders: [],
    loading: false,
  },
  mutations: {
    SET_FLOCK_ORDERS(state, data) {
      state.flockOrders = data;
    },
    SET_FLOCK_ORDER(state, data) {
      state.flockOrders = state.flockOrders.filter(
        ({ domain }) => domain !== data.domain,
      );

      state.flockOrders.push(data);
    },
    SET_FLOCK_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  getters: {
    getFlockDetails: (state) => (findDomain) =>
      state.flockOrders.find(
        ({ domain }) =>
          toASCII(domain?.toLowerCase()) === toASCII(findDomain?.toLowerCase()),
      ),
    getFlockEmailAccounts: (state, getters) => (findDomain) => {
      const flockOrder = getters.getFlockDetails(findDomain);

      return flockOrder?.emailAccounts?.accounts || [];
    },
    getFlockBillingDetails: (state, getters) => (findDomain) => {
      const flockOrder = getters.getFlockDetails(findDomain);

      return flockOrder?.billingDetails || {};
    },
    getFlockOrderDetails: (state, getters) => (findDomain) => {
      const flockOrder = getters.getFlockDetails(findDomain);

      if (!flockOrder) return {};

      return {
        planTitle: flockOrder?.flockOrder?.planTitle,
        expiryDate: flockOrder?.expiresAt,
        orderId: flockOrder?.orderId,
        isRenewable: flockOrder?.isRenewable,
        status: flockOrder?.status?.order,
        serviceStatus: flockOrder?.status?.service,
      };
    },
    getFlockOrderToUpsell: (state) => (findDomain) =>
      state.flockOrders.find(
        ({ domain, titanChosen }) =>
          titanChosen === false &&
          toASCII(domain?.toLowerCase()) === toASCII(findDomain?.toLowerCase()),
      ),
  },
  actions: {
    async getFlockOrders({ commit }) {
      commit('SET_FLOCK_LOADING', true);

      const [{ data }, err] = await hMailIntegrationRepo.getTitanOrders();

      if (!err) {
        commit('SET_FLOCK_ORDERS', data);
      }

      commit('SET_FLOCK_LOADING', false);

      return [{ data }, err];
    },
    async flockOrderIndex({ commit }, { orderId }) {
      commit('SET_FLOCK_LOADING', true);

      const [{ data }, err] = await hMailIntegrationRepo.getTitanOrder(orderId);

      if (!err) {
        commit('SET_FLOCK_ORDER', {
          ...data,
          domain: data?.flockOrder?.domainName,
          orderId,
        });
      }

      commit('SET_FLOCK_LOADING', false);

      return [{ data }, err];
    },
  },
};
