export const TLDS = {
  IN: 'in',
  COM: 'com',
  FR: 'fr',
  CO_UK: 'co.uk',
  UK: 'uk',
  ORG: 'org',
  NET: 'net',
  INFO: 'info',
  LIFE: 'life',
  LIVE: 'live',
  PRO: 'pro',
  LT: 'lt',
  BE: 'be',
  ES: 'es',
  COM_ES: 'com.es',
  ORG_ES: 'org.es',
  NOM_ES: 'nom.es',
  AM: 'am',
  BR: 'br',
  DK: 'dk',
  FI: 'fi',
  LU: 'lu',
  PK: 'pk',
  IT: 'it',
} as const;

export type TldsType = (typeof TLDS)[keyof typeof TLDS];
