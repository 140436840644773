<script setup lang="ts">
import { computed, useSlots } from 'vue';

import CircleLoader from '@/components/Loaders/CircleLoader.vue';

type Props = {
  collapse?: boolean;
  disabled?: boolean;
  disabledFooter?: boolean;
  hovered?: boolean;
  primary?: boolean;
  noHover?: boolean;
  noBodyPadding?: boolean;
  noHeaderPadding?: boolean;
  noBorder?: boolean;
  noSubHeaderPadding?: boolean;
  noSubHeaderBorder?: boolean;
  noBodyBorder?: boolean;
  loader?: boolean;
  disabledLoader?: boolean;
  footerTextAlign?: string;
  bodyClasses?: string;
};
const slots = useSlots();
const props = withDefaults(defineProps<Props>(), {
  footerTextAlign: 'right',
});

const emit = defineEmits<{
  click: [];
  'on-footer-click': [];
}>();

const classes = computed(() => ({
  'h-card--no-header': !slots.header,
  'h-card--no-body': !slots.body,
  'h-card--collapse': props.collapse,
  'h-card--disabled': props.disabled,
  'h-card--no-hover': props.noHover,
  'h-card--no-body-padding': props.noBodyPadding,
  'h-card--no-header-padding': props.noHeaderPadding,
  'h-card--no-sub-header-padding': props.noSubHeaderPadding,
  'h-card--no-sub-header-border': props.noSubHeaderBorder,
  'h-card--no-body-border': props.noBodyBorder,
}));
</script>
<template>
  <div
    class="h-card"
    :class="[
      classes,
      {
        'h-card--no-border': noBorder,
        'h-card--primary': primary,
      },
    ]"
    @click="emit('click')"
  >
    <div v-if="slots.header" class="h-card__header" :class="classes">
      <slot name="header" />
    </div>

    <div
      v-if="slots.subheader"
      class="h-card__sub-header"
      :class="[classes, { 'h-card__sub-header--no-border-bottom': slots.body }]"
    >
      <slot name="subheader" />
    </div>
    <div v-if="slots.body" class="h-card__body" :class="bodyClasses">
      <div
        v-if="loader || disabledLoader"
        :class="{
          'h-card--disabled': disabledLoader,
          'h-card--loading': loader,
        }"
      >
        <CircleLoader />
      </div>
      <slot v-if="!loader" name="body" />
    </div>
    <div
      v-if="slots.footer"
      class="h-card__footer"
      :class="[
        `h-card__footer-text-${footerTextAlign}`,
        { 'h-card__footer--disabled': disabledFooter },
        { 'h-card__footer--hovered': hovered },
      ]"
      @click="!disabledFooter && emit('on-footer-click')"
    >
      <slot name="footer" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.h-card {
  background: var(--light);
  border: 1px solid var(--gray-border);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;

  &__header,
  &__sub-header,
  &__body,
  &__footer {
    padding: 16px 24px;

    &--no-padding {
      padding: 0;
    }
  }

  &--no-border {
    border: none;
  }
  &--primary {
    border-color: var(--primary);
  }
  &__sub-header {
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: var(--gray-border);

    &--no-border-bottom {
      border-bottom: none;
    }

    &--no-border-top {
      border-bottom: none;
    }
  }

  &__header {
    font-weight: bold;
  }

  &--no-header-padding {
    .h-card__header {
      padding: 0;
    }
  }

  &--no-sub-header-padding {
    .h-card__sub-header {
      padding: 0;
    }
  }

  &--no-body-padding {
    .h-card__body {
      padding: 0;
    }
  }

  &__body {
    flex-grow: 1;
    border-top: 1px solid;
    border-color: var(--gray-border);
  }

  &__footer {
    padding: 16px;
    cursor: pointer;
    text-align: right;
    border-top: 1px solid var(--gray-border);

    &-text-left {
      text-align: left;
    }

    &--hovered:not(.h-card__footer--disabled):hover {
      background-color: var(--primary-light);
    }

    &--disabled {
      cursor: not-allowed;

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      div {
        color: var(--gray) !important;
      }
    }
  }

  &--collapse &__body,
  &--collapse &__footer {
    display: none;
  }

  &--no-header &__body {
    border-top: none;
  }

  &--disabled {
    color: var(--gray) !important;
  }

  &--warning-light {
    text-align: left;
    background: var(--warning-light);
  }

  &--loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--no-sub-header-border {
    .h-card__sub-header {
      border: none;
    }
  }

  &--no-body-border {
    .h-card__body {
      border: none;
    }
  }
}

.h-card--no-hover {
  .h-card__footer {
    cursor: default;
    &:hover {
      background-color: inherit !important;
    }
  }
}
</style>
