import {
  getStateVariable,
  setForDomain,
  currentOrderId,
} from '@/store/storeHelper';
import { useResourcesStore } from '@/stores';
import {
  HDomainResource,
  HRESOURCES_TYPE,
  HRESOURCES_STATE,
  HRESOURCE_ITEM_TYPE,
  HRESOURCE_ITEM_STATE,
} from '@/types';
import { toASCII, getDomainParts } from '@/utils/helpers';

const initial = {
  chosenOption: '',
  newDomainName: '',
  temporaryDomainName: '',
  existingDomainName: '',
  invoices: [],
  availableClientDomainsDetails: [],
  availableClientDomains: [],
  eligibleFreeDomain: false,
  availableClientDomainsLoaded: false,
};
export default {
  state: {
    initial,
    domainAdditionalFields: [],
    domainCountries: [],
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    setNewDomainName(state, data) {
      setForDomain(state, currentOrderId(), 'newDomainName', data);
    },
    setTemporaryDomainName(state, data) {
      setForDomain(state, currentOrderId(), 'temporaryDomainName', data);
    },
    setExistingDomainName(state, data) {
      setForDomain(state, currentOrderId(), 'existingDomainName', data);
    },
    SET_AVAILABLE_DOMAINS_OLD_BILLING(state, data) {
      const availableDomains = data?.map(({ domain }) => domain);

      setForDomain(
        state,
        currentOrderId(),
        'availableClientDomains',
        availableDomains,
      );

      setForDomain(
        state,
        currentOrderId(),
        'availableClientDomainsDetails',
        data,
      );
    },
    SET_AVAILABLE_DOMAINS(state, data) {
      const availableDomains = data
        ?.filter(
          ({ status }) =>
            ![
              HDomainResource.Status.EXPIRED,
              HDomainResource.Status.SUSPENDED,
            ].includes(status),
        )
        .map(({ title }) => title);

      setForDomain(
        state,
        currentOrderId(),
        'availableClientDomains',
        availableDomains,
      );

      const availableDomainsDetails = data.map((item) => {
        const [domainSld, domainTld] = getDomainParts(item.title);

        return {
          ...item,
          domain: item.title,
          domainSld,
          domainTld,
        };
      });

      setForDomain(
        state,
        currentOrderId(),
        'availableClientDomainsDetails',
        availableDomainsDetails,
      );
    },
    SET_ELIGIBLE_FREE_DOMAIN(state, data) {
      setForDomain(state, currentOrderId(), 'eligibleFreeDomain', data);
    },
    SET_AVAILABLE_DOMAINS_LOADED(state, data) {
      setForDomain(
        state,
        currentOrderId(),
        'availableClientDomainsLoaded',
        data,
      );
    },
    addInvoice(state, data) {
      let filledInvoices = getStateVariable(
        state,
        'invoices',
        currentOrderId(),
      );
      filledInvoices = filledInvoices.filter(
        ({ service }) => service !== data.service,
      );

      const asciiData = {
        ...data,
        domain_name: toASCII(data.domain_name),
        domain_sld: toASCII(data.domain_sld),
      };
      (filledInvoices || []).push(asciiData);
      setForDomain(state, currentOrderId(), 'invoices', filledInvoices);
    },
    REMOVE_DOMAIN_INVOICE(state, data) {
      let filledInvoices = getStateVariable(
        state,
        'invoices',
        currentOrderId(),
      );
      filledInvoices = filledInvoices.filter(
        ({ domain_name }) => domain_name !== data,
      );
      setForDomain(state, currentOrderId(), 'invoices', filledInvoices);
    },
    setDomainCountries(state, data) {
      state.domainCountries = data;
    },
  },
  getters: {
    getNewDomainName: (state) =>
      getStateVariable(state, 'newDomainName', currentOrderId()),
    getTemporaryDomainName: (state) =>
      getStateVariable(state, 'temporaryDomainName', currentOrderId()),
    getExistingDomainName: (state) =>
      getStateVariable(state, 'existingDomainName', currentOrderId()),
    getAvailableClientDomains: (state) =>
      getStateVariable(state, 'availableClientDomains', currentOrderId()),
    getAvailableClientDomainsDetails:
      (state) =>
      (find_domain = null) => {
        const allData = getStateVariable(
          state,
          'availableClientDomainsDetails',
          currentOrderId(),
        );

        if (find_domain) {
          return allData.find(({ domain }) => domain === find_domain);
        }

        return allData;
      },
    getAvailableClientDomainsLoaded: (state) =>
      getStateVariable(state, 'availableClientDomainsLoaded', currentOrderId()),
    getOnboardingInvoice: (state) =>
      getStateVariable(state, 'invoices', currentOrderId()),
    isDomainActive: (state) => (searchDomain) => {
      const domainDetails = getStateVariable(
        state,
        'availableClientDomainsDetails',
        currentOrderId(),
      );
      const domainInfo = domainDetails.find(
        ({ domain }) => searchDomain === domain,
      );

      return [true, 'active'].includes(domainInfo?.active);
    },
    getDomainCountries(state) {
      return state.domainCountries;
    },
    getEligibleFreeDomain: (state) =>
      getStateVariable(state, 'eligibleFreeDomain', currentOrderId()),
  },
  actions: {
    async availableClientDomains({ commit, dispatch, getters }, orderId) {
      await getAvailableDomains({ commit, dispatch, getters }, orderId);
    },
  },
};

const getAvailableDomains = async ({ commit, dispatch, getters }) => {
  const { resources } = useResourcesStore();

  const domainResources = resources.filter(
    ({ type, state }) =>
      type === HRESOURCES_TYPE.DOMAIN &&
      [HRESOURCES_STATE.ACTIVE, HRESOURCES_STATE.PENDING].includes(state),
  );

  let availableHBillingResDomains = domainResources?.filter(
    ({ status }) =>
      ![
        HDomainResource.Status.EXPIRED,
        HDomainResource.Status.SUSPENDED,
        HDomainResource.Status.FAILED,
      ].includes(status),
  );

  const hostingDomains = [];

  const hostingResources = resources.filter(
    ({ type }) => type === HRESOURCES_TYPE.HOSTING,
  );

  hostingResources.forEach((hosting) => {
    hosting.items.forEach(({ type, state, domain }) => {
      if (
        type === HRESOURCE_ITEM_TYPE.WEBSITE &&
        state === HRESOURCE_ITEM_STATE.ACTIVE
      ) {
        hostingDomains.push(domain);
      }
    });
  });

  const cpanelHostingResources = resources.filter(({ type }) =>
    [
      HRESOURCES_TYPE.CPANEL_HOSTING,
      HRESOURCES_TYPE.CPANEL_RESELLER_HOSTING,
    ].includes(type),
  );

  const cpanelDomains = cpanelHostingResources.map(({ title }) => title);

  const allUsedDomains = [...hostingDomains, ...cpanelDomains];

  availableHBillingResDomains = availableHBillingResDomains
    .filter(({ title }) => !allUsedDomains.includes(title))
    .map((domain) => ({ ...domain, status: domain.state })); // Status is used in various places. This just for safety

  commit('SET_AVAILABLE_DOMAINS', availableHBillingResDomains);
  commit('SET_AVAILABLE_DOMAINS_LOADED', true);

  await dispatch('freeDomain/fetchFreeDomainStatus');
  commit('SET_ELIGIBLE_FREE_DOMAIN', getters['freeDomain/getIsEligible']);
};
