import type { RouteRecordRaw } from 'vue-router';

import proPanelRegistrationGuard from '@/guards/proPanelRegistrationGuard';
import clientPurchaseOnboardingRoutes from '@/router/routes/hostingerPro/clientPurchaseOnboarding';
import hostingSetupOnboarding from '@/router/routes/hostingerPro/hostingSetupOnboarding';
import proVpsRoutes from '@/router/routes/hostingerPro/proVpsRoutes';
import { Route } from '@/types';

const isMobileScreen = window.innerWidth < 768;

const baseMetaData = {
  sideMenuExists: true,
  sideMenuFixed: !isMobileScreen,
  header: 'simple-header',
  isSideMenuFullHeight: true,
  sideMenuComponent: 'hostingerProSidebar',
};
const hostingerProRoutes = [
  {
    path: '/hostinger-pro',
    meta: {
      title: 'Hostinger Pro',
    },
    component: () => import('@/views/Hosting/ProPanelWrapper.vue'),
    beforeEnter: (to, from, next) => proPanelRegistrationGuard(to, from, next),
    children: [
      {
        path: 'get-started',
        name: Route.HostingerPro.GET_STARTED,
        meta: {
          showSubheader: false,
          fullHeight: true,
          header: 'simple-header',
        },
        component: () => import('@/views/HostingerPro/GetStarted.vue'),
      },
      {
        path: 'get-help',
        name: Route.HostingerPro.GET_HELP,
        meta: {
          title: 'Help Center',
          ...baseMetaData,
        },
        component: () => import('@/views/HostingerPro/GetHelpPage.vue'),
      },
      {
        path: 'clients-list',
        name: Route.HostingerPro.CLIENTS_LIST,
        meta: {
          title: 'Clients',
          ...baseMetaData,
        },
        component: () => import('@/views/HostingerPro/ClientsList.vue'),
      },
      {
        path: 'emails',
        name: Route.HostingerPro.EMAILS_LIST,
        meta: {
          title: 'Emails',
          ...baseMetaData,
        },
        component: () =>
          import('@/views/HostingerPro/EmailsList/EmailsList.vue'),
      },

      {
        path: 'websites',
        name: Route.HostingerPro.WEBSITES_LIST,
        meta: {
          title: 'Websites',
          ...baseMetaData,
        },
        component: () =>
          import('@/views/HostingerPro/WebsitesList/ProWebsitesView.vue'),
      },
      {
        path: 'domains',
        name: Route.HostingerPro.DOMAINS,
        meta: {
          title: 'Domains',
          ...baseMetaData,
        },
        component: () =>
          import('@/views/HostingerPro/DomainsList/DomainsList.vue'),
      },

      {
        path: 'partner-benefits',
        name: Route.HostingerPro.PARTNER_BENEFITS,
        meta: {
          title: 'Partner benefits',
          ...baseMetaData,
        },
        component: () => import('@/views/HostingerPro/PartnerBenefits.vue'),
        beforeEnter: (to, from, next) =>
          proPanelRegistrationGuard(to, from, next),
      },
    ],
  },

  ...clientPurchaseOnboardingRoutes,
  ...hostingSetupOnboarding,
  ...proVpsRoutes,
] as RouteRecordRaw[];
export default hostingerProRoutes;
