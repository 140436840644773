<script setup lang="ts">
import { FieldType, type FormValues } from '@hostinger/hcomponents';
import { useDebounceFn } from '@vueuse/core';
import cookies from 'js-cookie';
import { computed, ref, useSlots, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import HMenu from '@/components/HMenuV2/HMenu.vue';
import HMenuBackItem from '@/components/HMenuV2/HMenuBackItem.vue';
import HMenuFeedback from '@/components/HMenuV2/HMenuFeedback.vue';
import HMenuList from '@/components/HMenuV2/HMenuList.vue';
import HpSidemenu from '@/components/HpSidemenu/HpSidemenu.vue';
import { useGlobals, useNavigationExperiment } from '@/composables';
import {
  useFeedbackStore,
  useFrontendSettingsStore,
  useHPanelControlsStore,
  useProfileStore,
} from '@/stores';
import type {
  SideMenuBackButton,
  SideMenuRoute,
  NavigationMenuItem,
  IFeedbackProps,
} from '@/types';
import { AmplitudeEvent, Cookie, HIcon, Route } from '@/types';
import { handleSidemenuAmplitude } from '@/utils/services/amplitudeHelpers';

type Props = {
  routes?: SideMenuRoute[];
  navigationItems?: NavigationMenuItem[];
  backButton?: SideMenuBackButton;
  backNavigationItem?: NavigationMenuItem;
  noPadding?: boolean;
  hasFeedback?: boolean;
  feedbackData?: IFeedbackProps;
  isSearchable?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  noPadding: true,
  hasFeedback: true,
  isSearchable: false,
});

const router = useRouter();
const route = useRoute();
const slots = useSlots();
const frontendSettingsStore = useFrontendSettingsStore();
const hpanelControlsStore = useHPanelControlsStore();
const profileStore = useProfileStore();
const feedbackStore = useFeedbackStore();

const search = ref<string>('');

const startsWithSearchString = (title?: string) =>
  title?.toLowerCase().startsWith(t(search.value).toLowerCase());

const filteredNavigationItems = computed(() => {
  if (!search.value) {
    return props.navigationItems ?? [];
  }

  const newNavItems: NavigationMenuItem[] = [];
  props.navigationItems?.forEach((navigationItem) => {
    const children = startsWithSearchString(navigationItem.title)
      ? navigationItem.children
      : navigationItem.children?.filter(({ title }) =>
          startsWithSearchString(title),
        );

    const parentWithFilteredChildren = {
      ...navigationItem,
      children,
    };

    if (
      parentWithFilteredChildren.children?.length ||
      (startsWithSearchString(parentWithFilteredChildren?.title) &&
        parentWithFilteredChildren?.to)
    ) {
      newNavItems.push(parentWithFilteredChildren);
    }
  });

  return newNavItems ?? [];
});

const isMultiExpand = ref(false);

const { isNavigationExperimentActive } = useNavigationExperiment();

const { t, amplitudeV2 } = useGlobals();

const currentRouteName = computed(() => route.name as string);

const feedbackOpen = computed(() => frontendSettingsStore.state.feedbackOpen);
const isSideMenuFullHeight = computed(
  () => frontendSettingsStore.state.isSideMenuFullHeight,
);

const showFeedbackButton = computed(
  () =>
    // Cookie.DISABLED_FEEDBACK is used by auto-tests
    !cookies.get(Cookie.DISABLED_FEEDBACK) &&
    props.hasFeedback &&
    !profileStore.isStaff &&
    !profileStore.isAccessManager &&
    feedbackStore.isCSATVisible,
);

const searchSchema = computed(() => ({
  type: FieldType.text,
  icon: { left: HIcon.ICON_SEARCH },
  label: t('Search'),
  skipDisplayReq: true,
  custom: {
    hideOptionalLabel: true,
  },
}));

const sidemenuRef = ref<HTMLElement | undefined>(undefined);

const navigateToRoute = ({ name, link }: SideMenuRoute) => {
  handleSidemenuAmplitude(name, amplitudeV2, route.name as string);

  if (link) {
    window.open(link, '__blank');

    return;
  }

  router.push({ name });
};

const openFeedback = () => {
  feedbackStore.openCSATFeedback(props.feedbackData || ({} as IFeedbackProps));
  handleMenuSelect();
};

const navigateBack = (route: SideMenuBackButton) => {
  router.push({ name: route.route || Route.Base.HOME });
};

const handleMenuSelect = () => {
  if (!hpanelControlsStore.isMobileNavigationOpen) {
    return;
  }

  hpanelControlsStore.toggleMobileNavigation();
};

const handleNavigationSearch = useDebounceFn((formValues: FormValues) => {
  search.value = formValues.values.search;
}, 200);

watch(
  () => search.value,
  (value) => {
    isMultiExpand.value = !!value;

    amplitudeV2(AmplitudeEvent.SideBar.SEARCHED, {
      searchInput: value,
    });
  },
);

(() => {
  if (!feedbackStore.isCSATLoaded) {
    feedbackStore.fetchCSATData();
  }
})();
</script>

<template>
  <div
    :class="{
      'side-menu': !isNavigationExperimentActive,
      'side-menu-v2': isNavigationExperimentActive,
    }"
  >
    <HMenu v-if="isNavigationExperimentActive" ref="sidemenuRef">
      <template
        v-if="slots.header || hpanelControlsStore.hasSecondLevelNavigation"
        #header
      >
        <slot name="header">
          <HMenuBackItem
            :navigation-item="{
              slug: 'overview',
              title: t('Main menu'),
            }"
            @click="hpanelControlsStore.setNavigationLevel(1)"
          />
        </slot>
      </template>

      <template v-if="slots['sub-header'] || isSearchable" #sub-header>
        <slot name="sub-header" />
        <HForm v-if="isSearchable" @on-change="handleNavigationSearch">
          <HFormField
            class="h-mb-0"
            :class="{
              'h-mt-16': isSearchable && slots['sub-header'],
            }"
            name="search"
            :schema="searchSchema"
          />
        </HForm>
      </template>

      <HMenuList
        v-if="filteredNavigationItems"
        :is-multi-expand="isMultiExpand"
        :navigation-items="filteredNavigationItems"
        :current-route="currentRouteName"
        @click="handleMenuSelect"
      />
      <template v-if="showFeedbackButton" #footer>
        <HMenuFeedback
          :feedback-open="feedbackOpen"
          :menu="sidemenuRef"
          @toggle-feedback="openFeedback"
        />
      </template>
    </HMenu>
    <HpSidemenu
      v-else-if="routes"
      :routes="routes"
      :back-button="backButton"
      :no-padding="noPadding"
      :current-route="currentRouteName"
      :is-full-height="isSideMenuFullHeight"
      :feedback="showFeedbackButton"
      :feedback-open="feedbackOpen"
      :side-menu-component-file="true"
      @toggle-feedback="openFeedback"
      @on-back="navigateBack($event)"
      @navigate-to-route="navigateToRoute($event)"
    >
      <slot />
    </HpSidemenu>
  </div>
</template>

<style lang="scss" scoped>
hp-sidemenu {
  background-color: var(--light);

  @media only screen and (max-width: 992px) {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.26) !important;
  }
}

.side-menu {
  &-v2 {
    height: 100%;
  }

  @media only screen and (max-width: 992px) {
    margin-top: 48px;
  }
}
</style>
