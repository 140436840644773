import { computed, defineAsyncComponent } from 'vue';

import {
  useGlobals,
  useResourceMapper,
  useResourceFilters,
  useHpanelHostingTabV2Experiment,
} from '@/composables';
import {
  useFrontendSettingsStore,
  useHotspotStore,
  useProfileStore,
  useAppStore,
} from '@/stores';
import type { MenuItemId, MenuItem } from '@/types';
import { Route, HRESOURCES_STATE, ACCESS_LEVEL } from '@/types';
import { AmplitudeLocation } from '@/types/enums/amplitudeEventEnum';
import { kebabToCamel } from '@/utils/services/namingConventionsService';

export const useMenu = () => {
  const { isHpanelHostingTabV2ExperimentActive } =
    useHpanelHostingTabV2Experiment();

  const { t } = useGlobals();
  const hotspotStore = useHotspotStore();
  const { getResourcesByTypesAndStates, resourcesWithSubscriptions } =
    useResourceMapper();
  const { hasManageableHosting } = useResourceFilters();
  const frontendSettingsStore = useFrontendSettingsStore();
  const profileStore = useProfileStore();
  const appStore = useAppStore();

  const hasBillingAccess = computed(() => {
    const accessLevel = profileStore.access?.level;

    return !accessLevel || accessLevel === ACCESS_LEVEL.PURCHASE;
  });

  const pendingResourcesCount = computed(
    () =>
      getResourcesByTypesAndStates({ states: [HRESOURCES_STATE.PENDING] })
        .length,
  );

  const dueInvoicesCount = computed(() =>
    resourcesWithSubscriptions.value.reduce(
      (acc, resource) => acc + resource.subscription?.dueInvoicesCount || 0,
      0,
    ),
  );

  const getNotificationCountValue = (count: number) =>
    count > 9 ? '9+' : count;

  const menuItems = computed(
    () =>
      [
        {
          id: 'home',
          title: t('Home'),
          route: {
            name: Route.Base.HOME,
            query: { amplitudeLocation: AmplitudeLocation.Action.HOME_BUTTON },
          },
          notifications: getNotificationCountValue(pendingResourcesCount.value),
          activeRoutes: [Route.Base.HOME],
        },
        {
          id: 'websites',
          title: t('Websites'),
          route: { name: Route.Base.WEBSITES },
          activeRoutes: [Route.Base.WEBSITES, Route.Base.BUY_HOSTING],
        },
        {
          id: 'hosting',
          title: t('Hosting'),
          route: { name: Route.Base.HOSTING },
          activeRoutes: [Route.Base.HOSTING, Route.Base.HOSTING_V2],
          isHidden:
            !appStore.isAppLoaded ||
            isHpanelHostingTabV2ExperimentActive.value ||
            !hasManageableHosting.value,
        },
        {
          id: 'emails',
          title: t('Emails'),
          route: { name: Route.Email.EMAILS },
          activeRoutes: [Route.Email.EMAILS],
        },
        {
          id: 'my-domains',
          title: t('Domains'),
          route: { name: Route.Domain.MY_DOMAINS },
          activeRoutes: [
            Route.Domain.MY_DOMAINS,
            Route.Domain.DOMAIN_CHECKER,
            Route.Domain.TRANSFER_DOMAIN,
            Route.Domain.DOMAIN_MANAGEMENT,
            Route.Domain.REGISTER_DOMAIN_RESOURCE,
          ],
        },
        {
          id: 'servers',
          title: t('VPS'),
          route: { name: Route.Base.SERVERS },
          activeRoutes: [
            Route.Base.SERVERS,
            Route.Vps.VPS_PLANS,
            Route.Base.BUY_VPS,
            Route.Vps.SERVER_OVERVIEW,
          ],
        },
        {
          id: 'billing',
          title: t('Billing'),
          route: { name: Route.HBilling.SUBSCRIPTIONS },
          notifications: getNotificationCountValue(dueInvoicesCount.value),
          activeRoutes: [Route.HBilling.SUBSCRIPTIONS],
          isHidden: !hasBillingAccess.value,
        },
        {
          activeRoutes: [Route.Base.MARKETPLACE],
          id: 'store-mobile',
          hotspot: hotspotStore.hotspots.marketplaceHotspot,
          route: { name: Route.Base.MARKETPLACE },
          showInHeaderMenuMobileOnly: true,
          title: t('Marketplace'),
        },
        {
          activeRoutes: [Route.Base.HELP],
          id: 'help-mobile',
          route: { name: Route.Base.HELP },
          showInHeaderMenuMobileOnly: true,
          title: t('Help'),
        },
      ].filter((item) => !item.isHidden) as MenuItem[],
  );

  const headerComponent = computed(() => {
    let header: string = '';

    switch (frontendSettingsStore.state.header) {
      case 'onboarding':
        header = 'h-onboarding-header';
        break;
      case 'template-preview':
        header = 'h-template-preview-header';
        break;
      case 'cart-header':
        header = 'h-cart-header';
        break;
      case 'simple-header':
        header = 'simple-header';
        break;
      default:
        header = 'h-header';
    }

    return defineAsyncComponent(
      () => import(`@/components/Headers/${kebabToCamel(header, true)}.vue`),
    );
  });

  const getMenuItemById = (id: MenuItemId) =>
    menuItems.value.find((item) => item.id === id);

  return { menuItems, headerComponent, getMenuItemById };
};
