<template>
  <div
    v-if="isSidebarVisible"
    ref="sidemenu"
    class="side-menu"
    :class="[{ 'side-menu--hosting': isHosting }]"
  >
    <hp-sidemenu
      :sideMenuFixed.prop="frontendSettingsStore.state.sideMenuFixed"
      :routes.prop="routes"
      :currentRoute.prop="changeRoute(routerCurrent.name)"
      :backButton.prop="backButton"
      :isFullHeight.prop="frontendSettingsStore.state.isSideMenuFullHeight"
      :noPadding.prop="noPadding"
      :feedback.prop="showFeedbackButton"
      :feedbackOpen.prop="frontendSettingsStore.state.feedbackOpen"
      :sideMenuComponentFile.prop="sideMenuComponentFile"
      :closeButton.prop="closeButtonText"
      class="side-menu-shadow"
      :class="[
        {
          'side-menu--hosting__wrapper': isOpen,
        },
      ]"
      @on-back="navigateBack($event.detail[0])"
      @navigate-to-route="navigateToRoute($event.detail[0])"
      @on-close="frontendSettingsStore.toggleAsideLeftShow(false)"
      @toggle-feedback="openFeedback"
    >
      <Component :is="sideMenuComponentFile" v-if="sideMenuComponentFile" />
    </hp-sidemenu>
  </div>
</template>

<script>
import cookies from 'js-cookie';
import { isString, isObject } from 'lodash';
import { storeToRefs } from 'pinia';
import { defineAsyncComponent } from 'vue';
import { mapGetters } from 'vuex';

import { useScreen, useNavigationExperiment } from '@/composables';
import {
  useFeedbackStore,
  useFrontendSettingsStore,
  useHDomainResourceStore,
  useProfileStore,
} from '@/stores';
import { Cookie, Route } from '@/types';
import { handleSidemenuAmplitude } from '@/utils/services/amplitudeHelpers';
import { kebabToCamel } from '@/utils/services/namingConventionsService';
import RouteParser from '@/utils/services/navigation/RouteParser';

export default {
  props: {
    isOpen: Boolean,
  },
  emits: ['sidemenu-height'],
  mounted() {
    this.observeSidemenuHeight();
    this.feedbackStore.fetchCSATData();
  },
  data() {
    return {
      routerCurrent: this.$router.currentRoute,
      sidemenuHeightObserver: null,
    };
  },
  setup() {
    const { isLarge } = useScreen();
    const feedbackStore = useFeedbackStore();
    const { transferInList } = storeToRefs(useHDomainResourceStore());
    const frontendSettingsStore = useFrontendSettingsStore();
    const { isNavigationExperimentActive } = useNavigationExperiment();
    const profileStore = useProfileStore();

    return {
      frontendSettingsStore,
      isNavigationExperimentActive,
      profileStore,
      isLarge,
      feedbackStore,
      transferInList,
    };
  },
  beforeUnmount() {
    this.sidemenuHeightObserver?.unobserve(this.$refs.sidemenu);
    this.$emit('sidemenu-height', 0);
  },
  methods: {
    changeRoute(route) {
      switch (route) {
        case 'server-details':
          return 'server-overview';
        case 'two-factor-auth':
          return 'security';
        case 'account_sharing':
          return 'account_access';
        default:
          return route;
      }
    },
    openFeedback() {
      this.frontendSettingsStore.toggleFeedbackOpen();
    },
    createRouteIds(routes) {
      const routesWithIds = routes.map((route) =>
        Object.assign(route, {
          id: `hpanel_tracking_${this.currentRoute}${route.name}`,
        }),
      );

      return routesWithIds;
    },
    setRouteLabel(routes) {
      return routes.map((route) => {
        if (route.name === Route.Domain.TRANSFERS) {
          const transfersCount = this.transferInList.length;
          route.labelContent = transfersCount ? String(transfersCount) : '';
        }

        return route;
      });
    },
    navigateToRoute({ name, link }) {
      handleSidemenuAmplitude(name, this.$amplitudeV2);

      if (link) {
        const url = typeof link === 'function' ? link() : link;
        window.open(url, '__blank');

        return;
      }

      this.$router.push({ name });
    },
    navigateBack({ route }) {
      this.$router.push({ name: route });
    },
    observeSidemenuHeight() {
      if (!this.isSidebarVisible) {
        return;
      }

      this.sidemenuHeightObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          const newHeight = entry.contentRect.height;
          this.$emit('sidemenu-height', this.isLarge ? newHeight : 0);
        }
      });
      if (!this.$refs.sidemenu) {
        return;
      }
      this.sidemenuHeightObserver.observe(this.$refs.sidemenu);
    },
  },
  computed: {
    ...mapGetters(['getAccountAllowManage']),
    isSidebarVisible() {
      return (
        !this.hostingSideMenuDisabled &&
        !this.domainSideMenuDisabled &&
        !this.billingSideMenuDisabled &&
        !this.profileSidebarDisabled &&
        !this.cPanelSideMenuDisabled &&
        !this.h5gSidebarDisabled &&
        !this.emailSideMenuDisabled
      );
    },
    showFeedbackButton() {
      return (
        // Cookie.DISABLED_FEEDBACK is used by auto-tests
        !cookies.get(Cookie.DISABLED_FEEDBACK) &&
        !this.profileStore.isAccessManager &&
        this.feedbackStore.isCSATVisible &&
        !this.profileStore.isStaff &&
        this.frontendSettingsStore.state.feedback
      );
    },
    closeButtonText() {
      return this.isOpen ? { text: this.$t('Close') } : false;
    },
    hostingSideMenuDisabled() {
      return (
        (this.isNavigationExperimentActive &&
          this.frontendSettingsStore.state.sideMenuComponent ===
            'SectionSideMenu') ||
        (this.frontendSettingsStore.state.sideMenuComponent ===
          'SectionSideMenu' &&
          !this.getAccountAllowManage(this.$route.params.domain))
      );
    },
    cPanelSideMenuDisabled() {
      return (
        this.frontendSettingsStore.state.sideMenuComponent?.component ===
          'CPanelSidebarMenu' && this.isNavigationExperimentActive
      );
    },
    h5gSidebarDisabled() {
      return (
        this.frontendSettingsStore.state.sideMenuComponent === 'H5GSidebar' &&
        this.isNavigationExperimentActive
      );
    },
    domainSideMenuDisabled() {
      return (
        [
          'domains-side',
          'TransferSidebar',
          'domain-management',
          'external-domain',
        ]?.includes(this.frontendSettingsStore.state.sideMenuComponent?.menu) &&
        this.isNavigationExperimentActive
      );
    },
    billingSideMenuDisabled() {
      return (
        this.frontendSettingsStore.state.sideMenuComponent?.menu ===
          'billing' && this.isNavigationExperimentActive
      );
    },
    emailSideMenuDisabled() {
      return (
        this.frontendSettingsStore.state.sideMenuComponent?.menu ===
          'hosting-emails' && this.isNavigationExperimentActive
      );
    },
    profileSidebarDisabled() {
      return (
        this.frontendSettingsStore.state.sideMenuComponent?.component ===
          'ProfileSidebar' && this.isNavigationExperimentActive
      );
    },
    noPadding() {
      return !!this.sideMenuComponentConfig?.menu;
    },
    isHosting() {
      const hostingRoutes = ['/websites/'];

      return hostingRoutes.find((route) =>
        this.routerCurrent.path.includes(route),
      );
    },
    currentRoute() {
      return `${this.routerCurrent.name}_`;
    },
    backButton() {
      let back = this.frontendSettingsStore.state.sideMenuBack;
      if (back) {
        back = {
          text: this.$t(this.frontendSettingsStore.state.sideMenuBack.text),
          route: this.frontendSettingsStore.state.sideMenuBack.route,
          id: `hpanel_tracking_${this.currentRoute}back-to_${this.frontendSettingsStore.state.sideMenuBack.route}`,
        };
      }

      return back;
    },
    sideMenuComponentFile() {
      const componentName = isString(
        this.frontendSettingsStore.state.sideMenuComponent,
      )
        ? this.frontendSettingsStore.state.sideMenuComponent
        : this.sideMenuComponentConfig?.component;

      return componentName && isString(componentName)
        ? defineAsyncComponent(() =>
            import(
              `@/components/SideMenu/${kebabToCamel(componentName, true)}.vue`
            ),
          )
        : '';
    },
    routes() {
      if (!this.sideMenuComponentConfig?.menu) {
        return [];
      }

      const validRoutes = RouteParser.getByMenu(
        this.sideMenuComponentConfig.menu,
      ).flatMap((route) => {
        if (
          this.frontendSettingsStore.state.sideMenuHiddenLinks?.includes(
            route.name,
          ) ||
          route.meta?.hideChargeBee
        ) {
          return [];
        }

        return [
          {
            name: route.name,
            title: this.$t(route.meta?.title),
            link: route.meta?.menuLink,
            icon: route.meta?.icon,
            warning: route.meta?.warning,
            labelContent: route.meta?.labelContent,
            labelStyles: route.meta?.labelStyles,
          },
        ];
      });

      return this.setRouteLabel(this.createRouteIds(validRoutes));
    },
    sideMenuComponentConfig() {
      if (isObject(this.frontendSettingsStore.state.sideMenuComponent)) {
        return this.frontendSettingsStore.state.sideMenuComponent;
      }

      return null;
    },
  },
  watch: {
    $route() {
      this.routerCurrent = this.$router.currentRoute;
    },
  },
};
</script>

<style lang="scss" scoped>
.side-menu {
  display: flex;
  @media only screen and (max-width: 992px) {
    position: relative;
    height: 48px;
  }
}
.side-menu--hosting {
  @media only screen and (max-width: 992px) {
    height: unset;
  }
}
.side-menu--hosting__wrapper {
  @media only screen and (max-width: 992px) {
    margin-left: -22px;
    z-index: var(--z-index-10);
  }
}
</style>
