import { databasesRepo, serverInfoRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { toLocalTime } from '@/utils/helpers';

export default {
  state: {
    initial: {
      databases: [],
      mysqlServerInfo: {},
      loaded: false,
      serverInfoLoaded: false,
      databasesLoading: false,
    },
    data: [],
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_DATABASES_DATA(state, data) {
      setForDomain(state, null, 'databases', data);
      setForDomain(state, null, 'loaded', true);
    },
    DELETE_DATABASES(state, dbName) {
      let databases = getStateVariable(state, 'databases');
      databases = databases.filter((db) => db.dbName !== dbName);
      setForDomain(state, null, 'databases', databases);
    },
    SET_MYSQL_DATABASE_SERVER_INFO(state, data) {
      setForDomain(state, null, 'mysqlServerInfo', data);
    },
    SET_DATABASES_LOADING(state, data) {
      setForDomain(state, null, 'databasesLoading', data);
    },
  },
  getters: {
    getSqlDatabasesList: (state) =>
      getStateVariable(state, 'databases').sort(
        (a, b) => toLocalTime(b.createdAt, 'x') - toLocalTime(a.createdAt, 'x'),
      ),
    getDatabaseLoadingStatus: (state) =>
      getStateVariable(state, 'databasesLoading'),
    getSqlDbState: (state) => getStateVariable(state),
    getMaxLimitDatabase: (state) => {
      let max = '0';
      getStateVariable(state, 'databases').forEach(({ mysqlMaxSize }) => {
        const maxSize = Number(mysqlMaxSize);
        if (max < maxSize) max = maxSize;
      });

      return max;
    },
    getFullLimitReachedDatabases: (state) =>
      getStateVariable(state, 'databases')
        .map(({ diskUsage, mysqlMaxSize, dbName, dbUser, password }) => {
          if (diskUsage >= mysqlMaxSize) return { dbName, dbUser, password };
        })
        .filter((value) => value),
    getPartialLimitReachedDatabases: (state) =>
      getStateVariable(state, 'databases')
        .map(({ diskUsage, mysqlMaxSize, dbName, dbUser, password }) => {
          if (diskUsage >= mysqlMaxSize * 0.8 && diskUsage < mysqlMaxSize) {
            return { dbName, dbUser, password };
          }
        })
        .filter((value) => value),
  },
  actions: {
    async fetchSqlDatabases({ commit, dispatch }) {
      dispatch('fetchMysqlDatabaseServerInfo');

      const [{ data }, err] = await databasesRepo.getDatabases();
      commit('SET_DATABASES_DATA', !err ? data : []);
    },
    async setDatabaseDomain({ commit, dispatch }, request) {
      commit('SET_DATABASES_LOADING', true);
      const [, err] = await databasesRepo.setDatabaseDomain(request);

      await dispatch('fetchSqlDatabases');

      commit('SET_DATABASES_LOADING', false);

      return [err];
    },
    async fetchMysqlDatabaseServerInfo({ commit, rootGetters }) {
      const serverId = rootGetters.getCurrentAccountServerID;

      const [{ data }, err] = await serverInfoRepo.getMySqlServerInfo(serverId);

      commit('SET_MYSQL_DATABASE_SERVER_INFO', !err ? data : {});
    },
    async createMySQLDatabase({ dispatch }, request) {
      const response = await databasesRepo.createDatabase(request);
      dispatch('fetchSqlDatabases');

      return response;
    },
    async deleteMySQLDatabase({ commit }, dbName) {
      const [data, err] = await databasesRepo.deleteDatabase(dbName);
      if (!err) commit('DELETE_DATABASES', dbName);

      return [{ data }, err];
    },
  },
};
