import dayjs from 'dayjs';
import { useStore } from 'vuex';

import VpsRestoreCanceledPlanModal from '@/components/Modals/HModal/Hvps/VpsRestoreCanceledPlanModal.vue';
import RenewModal from '@/components/Modals/HModal/Purchase/RenewModal.vue';
import { useGlobals, useModal, useSubscriptions, useVps } from '@/composables';
import {
  useVpsBackupStore,
  useVpsResourceStore,
  useVpsServerStore,
} from '@/stores';
import { AmplitudeLocation } from '@/types';
import { DEFAULT_DATE_FORMAT } from '@/utils/helpers';

const EXPIRATION_THRESHOLD = 14;

export const useVpsSubscriptions = () => {
  const backupStore = useVpsBackupStore();
  const resourceStore = useVpsResourceStore();
  const serverStore = useVpsServerStore();

  const store = useStore();
  const { openModal } = useModal();
  const { t } = useGlobals();
  const { canOrderBeReactivated, getItemPriceId } = useSubscriptions();
  const { isVpsDestroyed } = useVps();

  const getResourceExpiration = (orderId: string) =>
    resourceStore.getVpsExpiration(orderId);

  const getSubscriptionExpiration = (orderId: string) =>
    resourceStore.getVpsResource(orderId)?.metadata?.expiresAt;

  const getSubscriptionByOrderId = (orderId: string) =>
    resourceStore.getVpsResource(orderId)?.subscription;

  const getLastRenewDate = (expirationDate: string) =>
    dayjs(expirationDate).add(21, 'day');

  const getLastRestoreDate = (expirationDate: string) =>
    dayjs(getLastRenewDate(expirationDate)).add(14, 'day');

  const getRemainingRestoreDays = (expirationDate: string) => {
    const lastRestoreDate = getLastRestoreDate(expirationDate);
    const remainingDays = lastRestoreDate.diff(dayjs(), 'day');

    return remainingDays === 0 ? 1 : remainingDays;
  };

  const canVpsBeRenewed = (orderId: string) => {
    const subscription = getSubscriptionByOrderId(orderId);

    const isRenewOrReactivateValid =
      subscription?.canRenew ||
      (subscription?.canReactivate && !isVpsDestroyed(orderId));
    if (!isRenewOrReactivateValid) return false;

    const expirationDate = getSubscriptionExpiration(orderId);
    if (!expirationDate) return false;

    const isExpiringSoon =
      dayjs(expirationDate).diff(dayjs(), 'day') <= EXPIRATION_THRESHOLD;
    if (isExpiringSoon) return true;

    return (
      dayjs().isAfter(expirationDate) &&
      dayjs().isBefore(getLastRenewDate(expirationDate))
    );
  };

  const canVpsBeRestored = (orderId: string) => {
    const canReactivate = getSubscriptionByOrderId(orderId)?.canReactivate;
    if (!canReactivate) return false;

    const expirationDate = getResourceExpiration(orderId);

    const isSuspendedPeriodEnd = dayjs().isAfter(
      getLastRenewDate(expirationDate),
    );
    const isBackupDestroyed = dayjs().isAfter(
      getLastRestoreDate(expirationDate),
    );

    return (
      isSuspendedPeriodEnd && isVpsDestroyed(orderId) && !isBackupDestroyed
    );
  };

  const openVpsRenewModal = (
    orderId: string,
    orderTitle: string,
    data?: { onSuccess?: () => void },
  ) => {
    const vpsResource = resourceStore.getVpsResource(orderId);
    if (!vpsResource) return;

    openModal({
      component: { RenewModal },
      data: {
        title: t('Renew your {service} plan', {
          service: orderTitle,
        }),
        subtitle: t(`Choose a billing period and finish the renew process`),
        itemPriceId: getItemPriceId(vpsResource?.subscription),
        subscriptionId: vpsResource?.subscription?.id,
        reactivate: canOrderBeReactivated(orderId),
        redirect: `${window.origin}`,
        onSuccess: () => {
          store.dispatch('billingItems/getBillingItems');
          data?.onSuccess?.();
        },
        amplitudeSource: AmplitudeLocation.Base.DASHBOARD,
      },
      steps: [
        {
          hideX: true,
        },
      ],
    });
  };

  const openVpsRestoreModal = async (orderId: string) => {
    const server = serverStore.getServerByOrderId(orderId);
    if (!server) return;

    let serverBackups = backupStore.getServerBackups(server.id);
    if (!serverBackups.length) {
      serverBackups = await backupStore.fetchServerBackups({
        serverId: String(server.id),
        cached: true,
      });
    }
    const lastBackup = serverBackups[0];
    const expirationDate = getResourceExpiration(orderId);

    openModal({
      component: { VpsRestoreCanceledPlanModal },
      data: {
        orderId,
        expirationDate: getLastRenewDate(expirationDate),
        lastBackupDate: dayjs(lastBackup?.createdAt).format(
          DEFAULT_DATE_FORMAT,
        ),
      },
    });
  };

  const isOptimalPlan = (orderId: string) => {
    const vpsResource = resourceStore.getVpsResource(orderId);

    const productName = getItemPriceId(vpsResource?.subscription);
    const OPTIMAL_PLAN = [
      'vps-16',
      'kvm8',
      'cyberpanel16',
      'minecraftwolf',
      'kvmminecraftwolf',
    ];

    return OPTIMAL_PLAN.some((plan) => productName?.includes(plan));
  };

  const isVpsExpired = (orderId: string) => {
    const expirationDate = getSubscriptionExpiration(orderId);

    return dayjs().isAfter(expirationDate);
  };

  return {
    getSubscriptionByOrderId,
    getResourceExpiration,
    getSubscriptionExpiration,
    getLastRestoreDate,
    getRemainingRestoreDays,
    canVpsBeRenewed,
    canVpsBeRestored,
    openVpsRenewModal,
    openVpsRestoreModal,
    isOptimalPlan,
    isVpsExpired,
  };
};
