import { isEmpty } from 'lodash';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useOnboardingStatusCheck } from './useOnboardingStatusCheck';

import {
  useActionScheduling,
  useExperiment,
  useGlobals,
  useLoading,
  useOnboarding,
} from '@/composables';
import { useOnboardingStore } from '@/stores';
import type { OnboardingDomainTag } from '@/types';
import {
  ActionToSchedule,
  AmplitudeEvent,
  Experiment,
  ONBOARDING_DOMAIN_TAG,
  Onboarding,
  Route,
} from '@/types';
import { toUnicode } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';
import { addRefetch } from '@/utils/services/localStorageRefetchService';

const HOURS_2_IN_MS = 1000 * 60 * 60 * 2;

const AMPLITUDE_DOMAIN_TYPE_MAP: { [key: string]: OnboardingDomainTag } = {
  buy_domain: ONBOARDING_DOMAIN_TAG.PURCHASE,
  purchased_domain: ONBOARDING_DOMAIN_TAG.OWNED,
  existing_domain: ONBOARDING_DOMAIN_TAG.REGISTERED_ELSEWHERE,
  free_domain: ONBOARDING_DOMAIN_TAG.FREE,
  temporary_domain: ONBOARDING_DOMAIN_TAG.TEMPORARY,
};

export const useOnboardingCompleted = () => {
  const store = useStore();
  const onboardingStore = useOnboardingStore();
  const route = useRoute();
  const router = useRouter();
  const { amplitudeV2 } = useGlobals();

  const {
    nextStep,
    isAddonOnboarding,
    shouldSkipOnboardingOverview,
    isWebsiteBuilderSelected,
    createWordPressPresetFromSelection,
  } = useOnboarding();

  const { checkOnboardingStatusOnce, isOnboardingStatusCompleted } =
    useOnboardingStatusCheck();

  const { isExperimentActive: isWpPresetsExperimentActive } = useExperiment(
    Experiment.ID.WEBPRO_WP_PRESETS,
  );

  const {
    isLoading: isLoadingOnboardingComplete,
    setIsLoading: setIsLoadingOnboardingComplete,
  } = useLoading();

  const { scheduleAction } = useActionScheduling();

  const hasDatacenters = computed(() => !isEmpty(store.getters.getDatacenters));

  const shouldCreateFreeDomainInvoice = computed(
    () =>
      store.getters.getFreeDomainValue &&
      !store.getters.isFreeDomainInvoiceCreated,
  );

  const shouldScheduleAiContentFeedbackRequest = computed(
    () => !!store.getters.getAiGenerateContent,
  );

  const checkIfOnboardingCompleteFailed = (error: any | null) => {
    if (error) {
      return router.push({ name: Route.Base.HOME });
    }

    nextStep(Onboarding.Step.ONBOARDING_COMPLETED);
  };

  const checkIfAbleToCompleteInstantly = async () => {
    sendCompletedAmplitude();

    if (isWebsiteBuilderSelected.value) {
      return nextStep(Onboarding.Step.ONBOARDING_COMPLETED);
    }

    const [{ data }] = await checkOnboardingStatusOnce();

    if (isOnboardingStatusCompleted(data.status) && isAddonOnboarding.value) {
      const [, error] = await store.dispatch('getOnboardingCompleteData', {
        orderId: route.params.order_id,
      });
      addRefetch('fetchHostingOrders');

      checkIfOnboardingCompleteFailed(error);
    } else {
      nextStep(Onboarding.Step.BEING_BUILT);
    }
  };

  const getOnboardingDataCenter = async () => {
    const primaryDataCenter = store.getters.getLatencies[0]?.value;
    if (primaryDataCenter) {
      return primaryDataCenter;
    }
    await store.dispatch('onboardingDatacentersIndex', route.params.order_id);
  };

  const handleOnboardingSetup = async () => {
    if (isWpPresetsExperimentActive.value) {
      await createWordPressPresetFromSelection();
    }

    const [, error] = isAddonOnboarding.value
      ? await store.dispatch('submitAddonOnboarding')
      : await store.dispatch('submitOnboarding');

    if (error) {
      throw Error('Submit onboarding error');
    }

    await checkIfAbleToCompleteInstantly();
  };

  const sendCompletedAmplitude = () => {
    const selectedDomainType =
      AMPLITUDE_DOMAIN_TYPE_MAP[store.getters.getOnboardingDomainSelectType];

    const params = {
      selectedDomain: toUnicode(store.getters.getOnboardingDomainName),
      selectedPlatform: store.getters.getSelectedCmsName,
      isAddon: Number(isAddonOnboarding.value),
      isDemo: Number(store.getters.getIsFromBuilderDemo),
      subscriptionId: route.params.order_id,
      selectedDomainType,
      websiteType: onboardingStore.state.typeOfWebsite,
    };

    amplitudeV2(AmplitudeEvent.Onboarding.SUMMARY_FINISHED, params);
  };

  const handleCpanelOnboardingSetup = async () => {
    store.commit('setAccountCreateSubmitted', true);

    const [, error] = await store.dispatch('submitCpanelOnboarding');

    if (!error) {
      await checkOnboardingStatusOnce();

      await Promise.all([store.dispatch('fetchHostingOrders')]);

      nextStep(Onboarding.Step.ONBOARDING_CPANEL_GETTING_READY);
    }

    if (error) {
      throw Error(error);
    }
  };

  const completeOnboarding = async () => {
    setIsLoadingOnboardingComplete(true);

    try {
      if (!isAddonOnboarding.value && !hasDatacenters.value) {
        await getOnboardingDataCenter();
      }

      if (store.getters.getIsCpanelOnboarding) {
        return await handleCpanelOnboardingSetup();
      }

      if (shouldCreateFreeDomainInvoice.value) {
        return await store.dispatch('createFreeDomainInvoice');
      }

      await handleOnboardingSetup();

      if (shouldScheduleAiContentFeedbackRequest.value) {
        scheduleAction({
          actionId: ActionToSchedule.REQUEST_WP_AI_CONTENT_FEEDBACK,
          timeoutInMs: HOURS_2_IN_MS,
        });
      }
    } catch (error) {
      errorLogger.logError(
        new Error('Onboarding completion failed', { cause: error }),
      );
      setIsLoadingOnboardingComplete(false);
    }
  };

  const onboardingSelectedAppNameSubmit = async (
    domainName = '',
    goToOverview = true,
  ) => {
    const isNextStepCpanelPassword =
      goToOverview && store.getters.getIsCpanelOnboarding;

    const domain = domainName || store.getters.getOnboardingDomainName;

    store.commit('setOnboardingDomainName', domain);

    if (isNextStepCpanelPassword) {
      return nextStep(Onboarding.Step.CREATE_CPANEL_PASSWORD);
    }

    if (shouldSkipOnboardingOverview.value) {
      return await completeOnboarding();
    }

    nextStep(Onboarding.Step.OVERVIEW);
  };

  return {
    isLoadingOnboardingComplete,
    checkOnboardingStatusOnce,
    completeOnboarding,
    onboardingSelectedAppNameSubmit,
    checkIfOnboardingCompleteFailed,
    isOnboardingStatusCompleted,
  };
};
