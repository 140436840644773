import type { RouteRecordRaw } from 'vue-router';

import { Route } from '@/types';

const billingRoutes = [
  {
    path: '/billing',
    meta: {
      title: 'Billing',
      hideChargeBee: true,
    },
    component: () => import('../../views/Billing/BillingWrapper.vue'),
    children: [
      {
        path: '',
        name: Route.Billing.INDEX,
        redirect: { name: Route.HBilling.SUBSCRIPTIONS },
      },
      {
        path: 'unpaid',
        name: Route.Billing.UNPAID,
        redirect: { name: Route.HBilling.PAYMENT_HISTORY },
      },
      {
        path: 'services',
        name: Route.Billing.SERVICES,
        redirect: { name: Route.HBilling.SUBSCRIPTIONS },
      },
      {
        path: 'payment-history',
        name: Route.Billing.PAYMENT_HISTORY,
        redirect: { name: Route.HBilling.PAYMENT_HISTORY },
      },
      {
        path: 'payment-method',
        name: Route.Billing.PAYMENT_METHODS,
        redirect: { name: Route.HBilling.PAYMENT_METHODS },
      },
      {
        path: 'payment-method/:id',
        name: Route.Billing.PAYMENT_METHOD_DETAILS,
        redirect: { name: Route.HBilling.PAYMENT_METHOD_DETAILS },
      },
      {
        path: 'disable-auto-renewal',
        name: Route.Billing.DISABLE_AUTO_RENEWAL,
        meta: {
          title: '',
          breadcrumbs: false,
          hideChargeBee: true,
        },
        component: () =>
          import('../../views/Billing/BillingDisableAutoRenewal.vue'),
      },
      {
        path: 'survey',
        name: Route.Billing.DISABLE_AUTO_RENEWAL_SURVEY,
        meta: {
          title: '',
          breadcrumbs: false,
          hideChargeBee: true,
        },
        component: () =>
          import('@/views/Billing/BillingDisableAutoRenewalSurvey.vue'),
      },
    ],
  },
] as RouteRecordRaw[];

export default billingRoutes;
