<template>
  <div ref="payInvoiceModal">
    <template v-if="!payment.loading">
      <h3 v-trans class="h-mb-0">Payment Summary</h3>
      <p v-trans class="h-mb-16">
        Review payment details and complete the payment
      </p>
    </template>
    <ModalSkeletonLoader v-if="loading" :period-count="1" />
    <template v-else>
      <template v-if="!payment.loading">
        <PurchaseLineItems :options="lineItems" />
        <PurchaseDetails
          :pricing-details="pricingDetails"
          :edit-loading="paymentEditLoading"
          @edit-payment="
            editPayment(
              {
                invoiceId: invoiceToPay.id,
              },
              'invoice',
            )
          "
        />
        <PurchaseButtons
          :loading="paymentEditLoading"
          :is-confirm="!paymentMethod"
          @on-success="proceedPayment"
          @on-close="goToPreviousStep"
        />
      </template>
      <PurchaseProcessing
        v-else
        :completed="payment.completed"
        :error="payment.error"
        :height="modalHeight"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ModalSkeletonLoader from '@/components/Loaders/SkeletonCompositions/ModalSkeletonLoader.vue';
import PurchaseButtons from '@/components/Modals/Parts/PurchaseButtons.vue';
import PurchaseDetails from '@/components/Modals/Parts/PurchaseDetails.vue';
import PurchaseProcessing from '@/components/Modals/Parts/PurchaseProcessing.vue';
import PurchaseLineItems from '@/components/PowerStoreNew/PurchaseLineItems.vue';
import { toLocalTime } from '@/utils/helpers';
import modalsMixin from '@/utils/mixins/modalsMixin';
import newPurchaseMixin from '@/utils/mixins/newPurchaseMixin';

export default {
  mixins: [modalsMixin, newPurchaseMixin],
  components: {
    PurchaseDetails,
    PurchaseProcessing,
    PurchaseButtons,
    PurchaseLineItems,
    ModalSkeletonLoader,
  },
  async created() {
    this.loading = true;
    if (!this.getDefaultPaymentMethod) {
      await this.billingGetPaymentMethods();
    }
    if (!this.invoiceToPay) {
      await this.getSubscriptionInvoices({
        subscriptionId: this.data.subscriptionId,
      });
      this.invoiceToPay = this.getUnpaidSubscriptionInvoice(
        this.data.subscriptionId,
      );
    }
    this.loading = false;
  },
  mounted() {
    setTimeout(() => {
      this.modalHeight =
        this.$refs.payInvoiceModal.getBoundingClientRect().height;
    }, 1000);
  },
  data() {
    return {
      loading: false,
      invoiceToPay: null,
      paymentFailed: false,
      modalHeight: 0,
    };
  },
  computed: {
    pricingDetails() {
      return {
        totalTax: this.invoiceToPay?.tax,
        total: this.invoiceToPay?.total,
        finalAmount: this.invoiceToPay?.amountDue,
        amountPaid: this.invoiceToPay?.amountPaid,
        currencyCode: this.invoiceToPay?.currencyCode,
        expiresAt: this.expiresAt,
        creditsApplied: this.appliedCredits,
        subTotal: this.invoiceToPay?.subTotal,
        totalDiscount: this.totalDiscount,
      };
    },
    appliedCredits() {
      return this.invoiceToPay?.appliedCredits.reduce(
        (acc, item) => acc + item.appliedAmount,
        0,
      );
    },
    totalDiscount() {
      return this.invoiceToPay?.discounts.reduce(
        (acc, item) => acc + item.amount,
        0,
      );
    },
    expiresAt() {
      return toLocalTime(this.invoiceToPay?.dueDate);
    },
    lineItems() {
      return this.invoiceToPay?.lineItems;
    },
    defaultPaymentMethodId() {
      return this.getDefaultPaymentMethod?.id;
    },
    ...mapGetters('paymentMethods', ['getDefaultPaymentMethod']),
    ...mapGetters('subscriptions', [
      'getSubscriptionById',
      'getUnpaidSubscriptionInvoice',
    ]),
  },
  methods: {
    async proceedPayment() {
      await this.completePayment(
        {
          invoiceId: this.invoiceToPay.id,
          methodId: this.defaultPaymentMethodId,
        },
        'invoice',
      );

      if (this.data.onSuccess && !this.payment.error && this.paymentMethod) {
        this.data.onSuccess();
      }
    },
    ...mapActions('paymentMethods', ['billingGetPaymentMethods']),
    ...mapActions('subscriptions', ['getSubscriptionInvoices']),
  },
};
</script>
