import type { ComputedRef } from 'vue';
import { computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import XRegExp from 'xregexp';

import DomainAddedModal from '@/components/Modals/HModal/DomainAddedModal.vue';
import OneTimePurchaseModal from '@/components/Modals/HModal/Purchase/OneTimePurchaseModal.vue';
import RenewModal from '@/components/Modals/HModal/Purchase/RenewModal.vue';
import {
  useGlobals,
  useOnboardingDomain,
  useModal,
  useAddDomain,
  useBrands,
  useResourceMapper,
  useOnboardingV2,
  useDomainPricing,
} from '@/composables';
import { hDomainsRepo } from '@/repositories';
import {
  useCatalogStore,
  useHostingSetupStore,
  useDomainCheckerStore,
  useOnboardingStore,
  useSubscriptionsStore,
} from '@/stores';
import type {
  SubscriptionPaymentResponse,
  IDomainPurchaseAmplitudeEventProperties,
  CatalogItem,
  TldsType,
  OnboardingStep,
} from '@/types';
import {
  AmplitudeLocation,
  AmplitudeEvent,
  Onboarding,
  Route,
  HBilling,
  HDomainResource,
  TLD_PURCHASE_PERIODS,
  TLDS,
  PeriodUnit,
} from '@/types';
import {
  toASCII,
  getSplittedDomainParts,
  removeLeadingDot,
  timeout,
  toUnicode,
  capitalize,
  sanitizeUrl,
  getSldTld,
} from '@/utils/helpers';
import {
  isTldInTldList,
  processDomainName,
} from '@/utils/helpers/domainsHelper';
import { getAllowedDomainCharCount } from '@/utils/services/domains/hDomains';

const RENEW_FAILED_PREFIX = 'Renewal failed:';

export const useDomainPurchase = ({
  amplitudeLocation,
}: {
  amplitudeLocation?: AmplitudeLocation.Base;
} = {}) => {
  const { openModal } = useModal();
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const { getCatalogItemsBySubcategory, getCatalogItemId } = useCatalogStore();
  const {
    fetchDomainAvailability,
    fetchAvailableMoreOptions,
    setDomainAvailability,
  } = useDomainCheckerStore();
  const { getDomainRenewalPrice } = useDomainPricing();

  const { amplitudeV2, t, toastr } = useGlobals();
  const hostingSetupStore = useHostingSetupStore();
  const { goToStep } = useOnboardingStore();
  const { goToDomainRegistration } = useOnboardingV2();
  const { onOnboardingNewDomainPurchase } = useOnboardingDomain();
  const { getConnectDomainRequest } = useAddDomain();
  const { brand } = useBrands();
  const { getSubscriptionById } = useSubscriptionsStore();

  const { getResourcesByIdempotencyKey } = useResourceMapper();

  const searchedDomain = ref('');
  const amplitudeEventProperties = ref<IDomainPurchaseAmplitudeEventProperties>(
    {
      search_phrase: '',
      tld: '',
      sld: '',
    },
  );

  const availableTldList: ComputedRef<string[]> = computed(() => {
    const domains: CatalogItem[] = getCatalogItemsBySubcategory(
      HBilling.CatalogSubcategory.DOMAIN,
    );

    return domains
      .flatMap((domain) => {
        const plan = domain?.metadata?.plan || '';

        if (!plan) return [];

        return plan.replace('.', '');
      })
      .sort();
  });

  const domainPurchaseOnboardingDetails = (
    domain: string,
    onSuccessCallback: any,
  ) => {
    const [domainSld, domainTld] = getSplittedDomainParts(domain);

    const onSuccess = ({
      items,
    }: Partial<SubscriptionPaymentResponse> = {}) => {
      amplitudeV2(AmplitudeEvent.Domain.PURCHASE_COMPLETED_ONBOARDING, {
        search_phrase: domain,
        tld: domainTld,
        sld: domainSld,
      });

      const orderId = items?.[0]?.chargebeeSubscriptionId || '';
      openModal({
        component: { DomainAddedModal },
        data: {
          onSuccess: (isOneClick: boolean) => {
            onOnboardingNewDomainPurchase(orderId, domain, isOneClick);

            onSuccessCallback();
          },
        },
      });
    };

    const onPaymentSuccess = (invoiceLink: string) => {
      amplitudeV2(AmplitudeEvent.Domain.PURCHASE_COMPLETED_ONBOARDING, {
        search_phrase: domain,
        tld: domainTld,
        sld: domainSld,
      });

      openModal({
        component: { DomainAddedModal },
        data: {
          onSuccess: () => {
            store.commit('SET_ONBOARDING_PURCHASE_INVOICE', invoiceLink);

            onOnboardingNewDomainPurchase('', domain, false);

            onSuccessCallback();
          },
        },
      });
    };

    const currentOnboardingOrder = getResourcesByIdempotencyKey(
      route.params.order_id as string,
    );

    const orderPeriod = `${currentOnboardingOrder?.subscription.billingPeriod}${
      currentOnboardingOrder?.subscription.billingPeriodUnit === PeriodUnit.YEAR
        ? 'y'
        : 'm'
    }`;

    return {
      onSuccess,
      onPaymentSuccess,
      isOnboarding: true,
      topLabel: {
        text: 'Matches your hosting period',
        type: 'period',
        value: orderPeriod,
      },
      purchaseDomainType: 'onboarding',
      snackBarText:
        'You do not have an active payment method. Complete this payment after the website setup.',
    };
  };

  const domainPurchaseOnboardingDetailsV2 = (
    domain: string,
    nextStep: OnboardingStep,
  ) => {
    const [domainSld, domainTld] = getSplittedDomainParts(domain);

    const submitAmplitudeEvent = () => {
      amplitudeV2(AmplitudeEvent.Domain.OFFER_CHOSEN, {
        tld: removeLeadingDot(domainTld),
        sld: domainSld,
        location: AmplitudeLocation.Base.HOSTING_ONBOARDING_V2,
      });
    };

    const onSuccess = () => {
      submitAmplitudeEvent();

      openModal({
        component: { DomainAddedModal },
        data: {
          onSuccess: () => {
            goToDomainRegistration(domain, nextStep);
          },
        },
      });
    };

    const onPaymentSuccess = () => {
      submitAmplitudeEvent();

      openModal({
        component: { DomainAddedModal },
        data: {
          onSuccess: () => {
            goToStep(nextStep);
          },
        },
      });
    };

    const currentOnboardingOrder = getResourcesByIdempotencyKey(
      route.params.order_id as string,
    );

    const orderPeriod = `${currentOnboardingOrder?.subscription.billingPeriod}${
      currentOnboardingOrder?.subscription.billingPeriodUnit === PeriodUnit.YEAR
        ? 'y'
        : 'm'
    }`;

    return {
      onSuccess,
      onPaymentSuccess,
      isOnboarding: true,
      topLabel: {
        text: 'Matches your hosting period',
        type: 'period',
        value: orderPeriod,
      },
      purchaseDomainType: 'onboarding',
      snackBarText:
        'You do not have an active payment method. Complete this payment after the website setup.',
    };
  };

  const domainPurchaseProsumerHostingSetupDetails = (domain: string) => {
    const onSuccess = async () => {
      await timeout(3000);
      hostingSetupStore.selectedDomain = domain;

      router.push({
        name: Route.HostingerPro.PRO_REGISTER_DOMAIN_RESOURCE,
        params: { domain: toASCII(domain), ...route.params },
        query: {
          redirect: 'pro_panel_hosting_overview',
          isProClient: 1,
          orderId: route.params.orderId,
          ...route.query,
        },
      });
    };

    const redirectReturnLink = router.resolve({
      name: Route.HostingerPro.PRO_REGISTER_DOMAIN_RESOURCE,
      params: { domain: toASCII(domain), ...route.params },
      query: {
        redirect: 'pro_panel_hosting_overview',
        isProClient: 1,
        orderId: route.params.orderId,
        ...route.query,
      },
    });

    return {
      onSuccess,
      redirectReturn: `${window.origin}${redirectReturnLink.fullPath}`,
      redirectCancel: `${window.origin}/domains`,
      purchaseProcessingProps: {
        successContinue: {
          subtitle: 'Redirecting to domain registration',
          actionText: '',
        },
        showCloseButtonOnSuccess: false,
      },
    };
  };

  const domainPurchaseWebsiteBuilderDetails = (domain: string) => {
    const [sld, tld] = getSplittedDomainParts(domain);
    const redirectUrl = sanitizeUrl({
      input: route.query.redirectUrl,
      isUrlString: true,
    });

    const domainRegistrationRouteDetails = {
      name: Route.Domain.ADD_DOMAIN_REGISTER,
      query: {
        sld,
        tld,
        redirectUrl,
      },
    };

    const redirectReturnLink = router.resolve(domainRegistrationRouteDetails);

    return {
      onSuccess: async () => {
        await timeout(3000);

        const [, err] = await getConnectDomainRequest()(
          route.params.domain as string,
          toASCII(domain),
        );

        if (!err) router.push(domainRegistrationRouteDetails);
      },
      redirectReturn: `${window.origin}${redirectReturnLink.fullPath}`,
      redirectCancel: `${window.origin}/domains`,
      purchaseDomainType: Onboarding.Type.BUILDER_DOMAIN_ONBOARDING,
    };
  };
  const getTldFromDomain = (value: string) => {
    const lowercasedValue = value?.toLowerCase();

    if (lowercasedValue?.includes('.')) {
      const start = lowercasedValue.indexOf('.');
      const end = lowercasedValue?.includes('/')
        ? lowercasedValue.indexOf('/')
        : lowercasedValue.length;

      return lowercasedValue.substring(start, end);
    }

    return lowercasedValue;
  };

  const getIsValidTld = (value: string, selectedTld?: string) => {
    const lowercasedValue = value?.toLowerCase();
    const tld = lowercasedValue?.includes('.') ? getTldFromDomain(value) : null;

    if (selectedTld) {
      const regex = `^\\${availableTldList.value?.join('$|^\\')}`;

      return isTldInTldList(availableTldList.value, selectedTld, regex);
    }

    if (!tld) return true;

    return isTldInTldList(availableTldList.value, tld);
  };

  const domainSearchAmplitudeEvent = (domain: string) => {
    let [sld, tld] = getSplittedDomainParts(domain);

    tld = tld.substring(1).replaceAll(' ', '');
    sld = sld.replaceAll(' ', '');

    if (!domain.includes('.')) {
      tld = 'com';
      sld = domain;
    }

    amplitudeEventProperties.value = {
      search_phrase: domain,
      tld,
      sld,
      ...(amplitudeLocation && { location: amplitudeLocation }),
    };

    amplitudeV2(
      AmplitudeEvent.Domain.OFFER_SHOWN,
      amplitudeEventProperties.value,
    );
  };

  const handlePurchaseDomainSearch = async (domain: string) => {
    const processedDomain = processDomainName(domain);
    const isTldValid = getIsValidTld(processedDomain);
    const [sld, tld] = getSplittedDomainParts(processedDomain, {
      withDefaults: true,
    });

    if (isTldValid) {
      fetchDomainAvailability({ domain });
    } else {
      setDomainAvailability({
        domain,
        isAvailable: false,
        restriction: null,
      });
    }

    fetchAvailableMoreOptions({
      domain: toASCII(sld),
      excludedTld: removeLeadingDot(tld),
    });

    searchedDomain.value = processedDomain;
    domainSearchAmplitudeEvent(processedDomain);
  };

  const openDomainTransferPurchaseModal = (
    domain: string,
    onOfferChosen?: Function,
  ) => {
    const [, tld] = getSldTld(domain, { omitDot: true });

    const punycodeDomain = toASCII(domain);
    const readableDomain = toUnicode(domain);
    const renewalPrice = getDomainRenewalPrice(tld);

    const redirectRouteData = {
      name: Route.Domain.TRANSFER,
      params: { domain: punycodeDomain },
    };

    openModal({
      component: { OneTimePurchaseModal },
      data: {
        itemId: getCatalogItemId(`domain-${tld.replaceAll('.', '')}`),
        addon: 'domain_transfer',
        planName: 'Domain transfer',
        redirect: `${window.origin}${router.resolve(redirectRouteData).path}`,
        domain: punycodeDomain,
        renewalPrice,
        onSuccess: ({ items }: any) => {
          store.dispatch('fetchHostingOrders');
          const subscriptionId = items[0].chargebeeSubscriptionId;

          router.push({
            name: Route.Domain.TRANSFER,
            params: {
              domain: punycodeDomain,
              subscriptionId,
            },
          });
        },
        onOfferChosen,
      },
      steps: [
        {
          title: t('{domain} Domain Transfer', {
            domain: readableDomain,
          }),
          subtitle: 'Your domain matches transfer requirements.',
          hideX: true,
        },
        {
          hideX: true,
        },
      ],
    });
  };

  const openDomainPurchaseModal = <T extends { title?: string } | {}>(
    domain: string,
    customData: T = {} as T,
  ) => {
    const punycodeDomain = toASCII(domain);
    const { title, subtitle } = getPurchaseModalHeaders(domain);
    const redirectRouteData = {
      name: Route.Domain.REGISTER_DOMAIN_RESOURCE,
      params: { domain: punycodeDomain },
      query: {
        instant: '1',
      },
    };

    openModal({
      component: { DomainPurchaseModal: {} },
      data: {
        itemIds: [getDomainItemId(domain)],
        domains: [punycodeDomain],
        redirectReturn: `${window.origin}${
          router.resolve(redirectRouteData).fullPath
        }`,
        redirectCancel: `${window.origin}/domains`,
        timeout: 3000,
        onSuccess: () => {
          store.dispatch('fetchHostingOrders');
          router.push(redirectRouteData);
        },
        ...customData,
      },
      steps: [
        {
          title: 'title' in customData ? customData.title : title,
          subtitle,
          hideX: true,
          noTrans: true,
        },
        {
          hideX: true,
        },
      ],
    });
  };

  const openDomainBundlePurchaseModal = <T extends { title?: string } | {}>(
    domains: string[],
    customData: T = {} as T,
  ) => {
    const mainDomain = domains[0];
    const mainDomainPunycode = toASCII(mainDomain);
    const punycodedDomains = domains.map(toASCII);
    const tlds = domains.flatMap((domain) => getSldTld(domain)[1]);
    const { title, subtitle } = getPurchaseDomainBundleModalHeaders(mainDomain);

    const redirectRouteData = {
      name: Route.Domain.REGISTER_DOMAIN_RESOURCE,
      params: { domain: mainDomainPunycode },
    };

    openModal({
      component: { DomainPurchaseModal: {} },
      data: {
        bundleItemIds: domains.map((domain) => getDomainItemId(domain)),
        bundleDomains: punycodedDomains,
        itemIds: domains.map(getDomainItemId),
        domains: punycodedDomains,
        tlds,
        isBundle: true,
        redirectReturn: `${window.origin}${
          router.resolve(redirectRouteData).path
        }`,
        redirectCancel: `${window.origin}/domains`,
        timeout: 3000,
        onSuccess: () => {
          store.dispatch('fetchHostingOrders');
          router.push(redirectRouteData);
        },
        ...customData,
      },
      steps: [
        {
          title: 'title' in customData ? customData.title : title,
          subtitle,
          hideX: true,
          noTrans: true,
        },
        {
          hideX: true,
        },
      ],
    });
  };

  const openDomainPurchaseOnboardingModal = (
    domain: string,
    nextStep: OnboardingStep,
  ) => {
    openDomainPurchaseModal(
      domain,
      domainPurchaseOnboardingDetailsV2(domain, nextStep),
    );
  };

  const getPurchaseModalHeaders = (domain: string) => {
    const unicodeDomain = toUnicode(domain);
    const [, tld] = getSplittedDomainParts(domain);
    const { getDomainPricingByTld } = useCatalogStore();
    const isSinglePeriod = getDomainPricingByTld(tld)?.length === 1;

    let title = `${t('Choose billing period')} - ${unicodeDomain}`;
    let subtitle = t('Choose a billing period and finish the checkout');

    if (isSinglePeriod) {
      title = `${t(
        'Review payment details and complete the payment',
      )} - ${unicodeDomain}`;
      subtitle = '';
    }

    return { title, subtitle };
  };

  const getPurchaseDomainBundleModalHeaders = (domain: string) => {
    const unicodeDomain = toUnicode(domain);

    const title = `${t('Complete payment - {domain} brand package', {
      domain: unicodeDomain,
    })}`;
    const subtitle = t('Review payment details and finish the payment process');

    return { title, subtitle };
  };

  const getDomainItemId = (domain: string) => {
    const tld = getSplittedDomainParts(domain)[1];

    return getCatalogItemId(`domain-${tld.replaceAll('.', '')}`);
  };

  const checkIfTldSupported = (tld: string): boolean => {
    if (!tld) return false;

    const formattedTld = tld.startsWith('.') ? tld.substring(1) : tld;

    return availableTldList.value.some(
      (availableTld) => availableTld === formattedTld,
    );
  };

  const checkIsDomainInRedemptionPeriod = async (domain: string) => {
    const [{ data }] = await hDomainsRepo.getResources({
      title: domain,
      resourceType: HDomainResource.ResourceType.DOMAIN,
    });

    if (!data) return false;

    const domainResource = data?.find(({ title }) => title === domain);

    return !!domainResource?.additionalDetails?.isRedemptionPeriod;
  };

  const openRecreateDomainFromRedemptionModal = ({
    domain,
    subscriptionId,
    amplitudeSource,
    onSuccess,
  }: {
    domain: string;
    subscriptionId: string;
    amplitudeSource?: string;
    onSuccess: () => void;
  }) => {
    const unicodeDomain = toUnicode(domain);
    const subscription = getSubscriptionById(subscriptionId);

    const billingItem = subscription?.items.find(
      (entry) => entry.resourceType === 'domain' && entry.itemType === 'plan',
    );
    if (!billingItem) return;

    const { getDomainRedemptionPeriodCatalogPriceId } = useCatalogStore();
    const itemPriceId = getDomainRedemptionPeriodCatalogPriceId(
      billingItem.itemId || '',
    );
    if (!itemPriceId) return;

    openModal({
      component: { RenewModal },
      data: {
        title: t('Reactivate Your Domain - {domain}', {
          domain: unicodeDomain,
        }),
        subtitle: t('Review your selected invoice and proceed to checkout'),
        itemPriceId,
        itemId: billingItem.itemId,
        subscriptionId,
        redirect: `${window.origin}`,
        reactivate: true,
        isChargeType: true,
        quantity: 1,
        serviceType: HDomainResource.ResourceType.DOMAIN,
        service: unicodeDomain,
        amplitudeSource,
        onSuccess,
      },
      steps: [
        {
          hideX: true,
        },
      ],
    });
  };

  const checkCanDomainBeRenewed = async (domain: string) => {
    const [{ data }, error] = await hDomainsRepo.getDomainRenewInformation(
      domain,
      true,
      {
        hideToastr: true,
        overrideCache: true,
      },
    );

    if (error) {
      toastr.e(
        t('Domain is no longer at {brand}', {
          brand: capitalize(brand.value),
        }),
      );

      return false;
    }

    const canRenew = data?.isRenewable?.isRenewable;
    const cannotRenewReason = data?.isRenewable?.reason;

    if (canRenew) return true;

    if (cannotRenewReason) {
      toastr.e(`${t(RENEW_FAILED_PREFIX)} ${t(cannotRenewReason)}`);
    }

    return false;
  };

  const validateSldLength = (
    originalDomain: string,
    cleanDomain: string = originalDomain,
  ) => {
    const MAX_DOMAIN_CHAR_COUNT = getAllowedDomainCharCount(cleanDomain);
    const MIN_DOMAIN_CHAR_COUNT = 3;

    const [cleanSld, cleanTld] = getSldTld(cleanDomain);
    const [, originalTld] = getSldTld(originalDomain);

    if (cleanSld.length > MAX_DOMAIN_CHAR_COUNT) {
      return {
        text: 'Domain name must be under {count} characters long',
        params: { count: MAX_DOMAIN_CHAR_COUNT },
      };
    }

    if (cleanSld.length < MIN_DOMAIN_CHAR_COUNT || (originalTld && !cleanTld)) {
      return {
        text: 'Domain name must be at least {count} characters long',
        params: { count: MIN_DOMAIN_CHAR_COUNT },
      };
    }

    return null;
  };

  const validateDomainChars = (domain: string) => {
    /**
     * Allowed characters:
     * - Unicode alphabetic characters (includes a-z, A-Z, and characters from other scripts)
     * - Unicode numeric characters (includes 0-9 and numeric characters from other scripts)
     * - . (dot)
     * - Unicode mark characters (includes accents and other diacritics)
     * - - (dash)
     */

    const regex = XRegExp('^[\\p{Alphabetic}\\p{Number}.\\p{Mark}-]+$');

    if (!regex.test(domain)) {
      return {
        text: 'Domains with special characters are not allowed',
      };
    }

    return null;
  };

  const getDomainSearchValidation = (
    domain: string,
    options?: {
      isTldRequired?: boolean;
      shouldCheckTldAvailability?: boolean;
    },
  ) => ({
    validateDomainLength: {
      minLength: 3,
      maxLength: getAllowedDomainCharCount(domain),
    },
    custom: (domain: string) => {
      const ERROR_MESSAGES = {
        invalid: 'Invalid domain name',
        unsupported: 'We do not support this domain extension',
      };

      const isDomainStartOrEndWithDash = /^[-]|[-]$/.test(domain);

      if (isDomainStartOrEndWithDash) {
        return { text: ERROR_MESSAGES.invalid };
      }

      const domainParts = domain.trim().split('.').filter(Boolean);
      const domainPartsCount = domainParts.length;

      if (domainPartsCount === 1 && options?.isTldRequired) {
        return { text: ERROR_MESSAGES.invalid };
      }

      if (domainPartsCount === 1) {
        return;
      }

      const extractedTLD =
        domainParts.length > 2
          ? domainParts.slice(-2).join('.')
          : domainParts.slice(-1).join('.');

      const tldRegex = /^[a-zA-Z0-9.-]+$/;

      const isTldAvailabilityRequired = !!options?.shouldCheckTldAvailability;
      const isTldAvailable = availableTldList.value.includes(extractedTLD);

      if (isTldAvailabilityRequired && !isTldAvailable) {
        return { text: ERROR_MESSAGES.unsupported };
      }

      if (!tldRegex.test(extractedTLD)) {
        return { text: ERROR_MESSAGES.invalid };
      }
    },
  });

  const getDefaultTldPricingPeriod = (tld: TldsType) => {
    const twoYearsDefaultPeriodTlds = [
      TLDS.IN,
      TLDS.COM,
      TLDS.FR,
      TLDS.CO_UK,
      TLDS.ORG,
      TLDS.NET,
      TLDS.INFO,
      TLDS.LIFE,
      TLDS.LIVE,
      TLDS.PRO,
    ] as TldsType[];

    if (twoYearsDefaultPeriodTlds.includes(tld)) {
      return TLD_PURCHASE_PERIODS.YEAR_2;
    }

    return TLD_PURCHASE_PERIODS.YEAR_1;
  };

  const getDomainPurchaseConditionTooltip = (period: number) => {
    if (period > 1) {
      return {
        content: 'Minimum {period} year registration',
        variables: {
          period,
        },
      };
    }

    return null;
  };

  const getSldWithValidTld = (domain: string) => {
    let sld = '';
    let tld = '';

    [sld, tld] = getSldTld(toASCII(domain), {
      omitDot: true,
    });

    if (!availableTldList.value.includes(tld)) {
      [sld, tld] = getSldTld(toASCII(tld), {
        omitDot: true,
      });
    }

    if (!availableTldList.value.includes(tld)) return {};

    return {
      sld,
      tld,
    };
  };

  return {
    availableTldList,
    searchedDomain,
    amplitudeEventProperties,
    openDomainBundlePurchaseModal,
    domainPurchaseOnboardingDetails,
    openDomainPurchaseOnboardingModal,
    domainPurchaseProsumerHostingSetupDetails,
    domainPurchaseWebsiteBuilderDetails,
    getIsValidTld,
    getTldFromDomain,
    handlePurchaseDomainSearch,
    openDomainTransferPurchaseModal,
    openDomainPurchaseModal,
    checkIfTldSupported,
    openRecreateDomainFromRedemptionModal,
    checkIsDomainInRedemptionPeriod,
    checkCanDomainBeRenewed,
    getDomainSearchValidation,
    getDefaultTldPricingPeriod,
    getDomainPurchaseConditionTooltip,
    validateSldLength,
    getSldWithValidTld,
    validateDomainChars,
  };
};
