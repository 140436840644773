import cookies from 'js-cookie';
import { mapGetters, mapActions } from 'vuex';

import { useModal, useRedirects } from '@/composables';
import { getThreeDsVerifyErrors } from '@/data/billing/paymentData';
import { hBillingRepo, storedPaymentsRepo } from '@/repositories';
import { useProfileStore } from '@/stores';
import { Payment, Cookie, HBilling } from '@/types';
import { assignLocationAsync, timeout, mapKeyValue } from '@/utils/helpers';
import { snakeToCamelObj } from '@/utils/services/namingConventionsService';
import { initProcessout } from '@/utils/services/processout';
import { getRiskJsToken } from '@/utils/services/riskJs';

const PURCHASE_REDIRECT_FLOW_MAP = {
  [HBilling.CatalogCategory.HOSTING]: 'onboarding-hosting',
  [HBilling.CatalogCategory.VPS]: 'onboarding-vps',
  [HBilling.CatalogCategory.EMAIL]: 'onboarding-email',
  [HBilling.CatalogEmailGoogle]: 'onboarding-email-google',
  default: null,
};

export default {
  data: () => ({
    payment: {
      loading: false,
      completed: false,
      error: false,
    },
    paymentEditLoading: false,
  }),
  async created() {
    if (!this.paymentMethod) {
      this.loading = true;
      await this.billingGetPaymentMethods();
      this.loading = false;
    }
  },
  methods: {
    async completePayment(
      request,
      type,
      ignoreRedirect,
      notClosable,
      timeoutMs,
      category,
    ) {
      this.payment.loading = true;

      const profileStore = useProfileStore();
      const isOneClickPay = !!this.paymentMethod;

      if (
        profileStore.account?.hasRiskIndicators &&
        isOneClickPay &&
        !cookies.get(Cookie.E2E_TEST_BYPASS_RISK_INDICATOR)
      ) {
        return await this.editPayment(request, type);
      }

      const [{ data }, err] = await this.getPurchaseMethod(request, type);

      // if fully paid by credits
      if (!err && data.status === 'completed') {
        this.payment.completed = true;
        if (!notClosable) this.closeModalTimer();

        return new Promise((resolve) => {
          resolve([{ data }, err]);
        });
      }

      //if user has payment method, but is razorpay
      if (!err && data.paymentLink && this.isRazorpay) {
        this.handleRedirectFlow(category);
        await assignLocationAsync(data.paymentLink);
      }

      // if user has no payment method
      if (!err && data.paymentLink && !this.paymentMethod && !ignoreRedirect) {
        this.handleRedirectFlow(category);
        await assignLocationAsync(data.paymentLink);
      }

      // Option to add timeout before executing successful payment for some async API edge cases (hDomains)
      if (timeoutMs && this.paymentMethod) await timeout(timeoutMs);

      if (err) {
        this.payment.error = err?.message || err;
        this.payment.completed = true;
        if (!notClosable) this.closeModalTimer();

        return await this.getOrder(data?.orderToken);
      }

      // Verify payment if 3ds is required
      if (this.threeDsPubKey) {
        const [, error] = await this.process3dsPayment(data);
        if (error) {
          this.payment.error = {
            error: {
              message: error,
            },
          };
          this.payment.completed = true;

          return await this.getOrder(data?.orderToken);
        }
      }

      // call orders
      const [{ data: orderData }, orderErr] = await this.getOrder(
        data.orderToken,
      );

      if (orderData.paymentLink && !ignoreRedirect) {
        this.handleRedirectFlow(category);
        window.location = orderData.paymentLink;
      }
      if (this.paymentMethod) this.payment.completed = true;
      if (!notClosable) this.closeModalTimer();

      return new Promise((resolve) => {
        resolve([{ data: orderData }, orderErr]);
      });
    },
    async editPayment(request, type, category) {
      this.paymentEditLoading = true;

      this.data.onPaymentContinue?.();

      const [{ data }, err] = await this.getPurchaseOption(request, type);

      if (err) {
        this.payment.error = true;
        this.paymentEditLoading = false;

        return [{ data }, err];
      }

      if (data.paymentLink) {
        this.handleRedirectFlow(category);
        window.location.assign(data.paymentLink);
      }

      this.paymentEditLoading = false;

      return [{ data }, err];
    },
    async getPurchaseMethod(request, type) {
      if (this.isRazorpay) {
        return this.getPurchaseOption(request, type);
      }

      const requestData = { ...request };
      const isOneClickPay = !!this.paymentMethod;
      const riskJsPublicKey = this.paymentMethod?.keys?.riskJsPublicKey;

      if (isOneClickPay && riskJsPublicKey) {
        const riskjsToken = await getRiskJsToken(riskJsPublicKey);
        if (riskjsToken) {
          requestData.riskjsToken = riskjsToken;
        }
      }

      switch (type) {
        case 'purchase-addon':
          return hBillingRepo.purchaseAddonForPlan(requestData, isOneClickPay);
        case 'purchase':
          return await hBillingRepo.purchasePlan(requestData, isOneClickPay);
        case 'upgrade':
          return await hBillingRepo.upgradePlan(requestData, isOneClickPay);
        case 'renew':
          return await hBillingRepo.renewPlan(requestData, isOneClickPay);
        case 'reactivate':
          return await hBillingRepo.reactivatePlan(requestData, isOneClickPay);
        case 'invoice':
          return await hBillingRepo.payInvoice(requestData, isOneClickPay);
        default:
          break;
      }
    },
    async getPurchaseOption(request, type) {
      switch (type) {
        case 'purchase-addon':
          return hBillingRepo.purchaseAddonForPlan(request, false);
        case 'purchase':
          return await hBillingRepo.purchasePlan(request, false);
        case 'upgrade':
          return await hBillingRepo.upgradePlan(request, false);
        case 'renew':
          return await hBillingRepo.renewPlan(request, false);
        case 'reactivate':
          return await hBillingRepo.reactivatePlan(request, false);
        case 'invoice':
          return await hBillingRepo.getOrderInvoicePaymentLink(request);
        default:
          return await hBillingRepo.upgradePlan(request, false);
      }
    },
    async verify3dsPayment(paymentToken) {
      const [, err] = await storedPaymentsRepo.verifyPayment({
        paymentToken,
      });
      if (err) {
        return (
          getThreeDsVerifyErrors(err?.error.code) || err?.error.message || ''
        );
      }

      return false;
    },
    async process3dsPayment(data) {
      await initProcessout();

      return new Promise((resolve) => {
        // eslint-disable-next-line no-undef
        const client = new ProcessOut.ProcessOut(this.threeDsPubKey);
        if (!data.transactionDetails.invoiceId) {
          resolve([true, null]); // In case if user pays with credits and have payment method
        }
        client.makeCardPayment(
          data.transactionDetails.invoiceId,
          this.cardToken,
          {
            authorize_only: false,
          },
          async () => {
            const err = await this.verify3dsPayment(data.paymentToken);
            if (!err) {
              resolve([true, null]);
            } else {
              resolve([false, err]);
            }
          },
          async (err) => {
            resolve([false, err.message]);
          },
        );
      });
    },
    async getOrder(orderToken) {
      if (!orderToken) return [{}, true];

      let tries = 10;
      let completed = false;
      let orderResponse = [];

      return new Promise((resolve) => {
        const pingOrders = setInterval(async () => {
          if (tries === 0 || completed) {
            clearInterval(pingOrders);
            if (!completed) {
              orderResponse = await this.billingGetOrderByToken(orderToken);
            }
            resolve(orderResponse);

            return;
          }
          const order = this.getOrderByToken(orderToken);
          orderResponse = [{ data: order }, false];
          if (order?.status === 'completed') {
            completed = true;
          }
          tries = tries - 1;
        }, 1000);
      });
    },
    closeModalTimer() {
      const { closeModal } = useModal();
      setTimeout(() => {
        this.$emit('close');
        closeModal();
      }, 3000);
    },
    handleRedirectFlow(category) {
      const redirectFlowType = mapKeyValue(
        PURCHASE_REDIRECT_FLOW_MAP,
        category,
      );
      if (!redirectFlowType) {
        return;
      }
      const { setRedirectFlow } = useRedirects();
      setRedirectFlow(redirectFlowType);
    },
    ...mapActions('paymentMethods', ['billingGetPaymentMethods']),
    ...mapActions('orders', ['billingGetOrderByToken']),
  },
  computed: {
    threeDsPubKey() {
      return snakeToCamelObj(this.paymentMethod)?.features?.processoutJsSdk
        ?.publicKey;
    },
    isRazorpay() {
      return (
        this.paymentMethod?.merchantAccount === Payment.MerchantAccount.RAZORPAY
      );
    },
    cardToken() {
      return this.paymentMethod?.token;
    },
    ...mapGetters('paymentMethods', {
      paymentMethod: 'getDefaultPaymentMethod',
    }),
    ...mapGetters('orders', ['getOrderByToken']),
  },
};
