<script setup lang="ts">
import { computed } from 'vue';

import HotSpotTooltip from '@/components/HotSpot/HotSpotTooltip.vue';
import type { HotspotConfig } from '@/types';

interface Emits {
  (eventName: 'hot-spot-close', payload: any): void;
}
interface Props extends HotspotConfig {}

const emit = defineEmits<Emits>();
const props = withDefaults(defineProps<Props>(), {
  placement: 'right',
  isClickable: true,
  indicatorPosition: () => ({
    top: -12,
    left: -12,
  }),
  variant: 'pulsing',
  isIndicator: true,
});

const indicatorPositionPx = computed(() => ({
  top: `${props.indicatorPosition.top}px`,
  left: `${props.indicatorPosition.left}px`,
}));
</script>

<template>
  <div class="hot-spot__wrapper">
    <slot />
    <div class="hot-spot__inner-wrapper">
      <!-- @vue-ignore -->
      <HotSpotTooltip v-bind="props" @hot-spot-close="emit('hot-spot-close')">
        <template v-if="$slots.description" #description>
          <slot name="description" />
        </template>
        <div v-if="isIndicator" class="hot-spot">
          <div
            class="hot-spot__indicator"
            :class="`hot-spot__indicator--${variant}`"
          />
        </div>
      </HotSpotTooltip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hot-spot {
  &__inner-wrapper {
    position: absolute;
    top: v-bind('indicatorPositionPx.top');
    right: v-bind('indicatorPositionPx.left');
  }

  &__wrapper {
    position: relative;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;

  &__indicator {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      background-color: var(--danger);
    }

    &--pulsing {
      background-color: rgba(252, 81, 133, 0.3);
      width: 28px;
      height: 28px;
      animation: 1.25s linear infinite pulse;
    }
    &--static {
      background-color: var(--danger);
      width: 8px;
      height: 8px;
      outline: 1px solid var(--white);
      &::before {
        width: 8px;
        height: 8px;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
