<script setup lang="ts">
import type { FieldSchema, FormValues } from '@hostinger/hcomponents';
import { ref, computed } from 'vue';

import HModalActions from '@/components/HModals/HModalActions.vue';
import HRadio from '@/components/HRadio.vue';
import { useHRadio, useGlobals } from '@/composables';
import { HDomains } from '@/types';

const FORM_ID = 'bulk-action-select';

const { t } = useGlobals();

type Props = {
  data: {
    title: string;
    subtitle: string;
    infoText?: string;
    options: {
      id: string;
      label?: string;
      isActive?: boolean;
    }[];
    textOptions?: (FieldSchema & { name: string })[];
    onContinue: Function;
  };
};

const props = defineProps<Props>();

const selectableOptions = computed(() => props.data.options);

const { values: bulkActionOptionsValues, setActiveValue: setBulkActionOption } =
  useHRadio(selectableOptions);

setBulkActionOption(
  props.data.options?.find(({ isActive }) => !!isActive)?.id as string,
);

interface Emits {
  (eventName: 'close'): void;
}

const emits = defineEmits<Emits>();

const isLoading = ref(false);

const selectedRadioOption = computed(() =>
  bulkActionOptionsValues.value.find((option) => option.isActive),
);

const isTextOptionsFormVisible = computed(
  () =>
    selectedRadioOption.value?.id ===
    HDomains.BulkActionSlug.DOMAIN_NAMESERVER_UPDATE_CUSTOM,
);

const isInfoTextVisible = computed(
  () =>
    selectedRadioOption.value?.id ===
      HDomains.BulkActionSlug.DOMAIN_AUTO_RENEW_DISABLE && props.data.infoText,
);

const handleContinueClick = async ({ values }: FormValues) => {
  isLoading.value = true;

  await props.data.onContinue({
    bulkActionType: selectedRadioOption?.value?.id,
    bulkActionPayload: values || {},
  });

  isLoading.value = false;
};
</script>

<template>
  <h2 v-trans class="h-mb-8">{{ props.data.title }}</h2>
  <p v-trans class="h-mb-16">{{ props.data.subtitle }}</p>
  <HRadio
    v-for="value in bulkActionOptionsValues"
    :id="value.id"
    :key="value.id"
    :label="t(value.label)"
    :is-active="value.isActive"
    class="h-mt-8"
    @click="setBulkActionOption"
  />
  <div v-show="isTextOptionsFormVisible" class="h-mt-12">
    <HForm :submit-id="FORM_ID" @on-submit="handleContinueClick">
      <HFormField
        v-for="option in props.data.textOptions"
        :key="option.label"
        :name="option.name"
        :schema="option"
        class="h-mb-8"
      />
    </HForm>
  </div>
  <HSnackbar v-if="isInfoTextVisible" class="h-mt-16" variant="warning">
    <Trans>{{ props.data.infoText }}</Trans>
  </HSnackbar>

  <HModalActions
    class="h-mt-24"
    no-padding
    mobile-reverse
    close-text="Cancel"
    @close="emits('close')"
  >
    <!-- @vue-ignore -->
    <HButton
      v-show="!isTextOptionsFormVisible"
      v-qa-generate
      class="h-mb-8 h-mb-0-md"
      :is-loading="isLoading"
      @click="handleContinueClick"
    >
      {{ t('Continue') }}
    </HButton>
    <HButton
      v-show="isTextOptionsFormVisible"
      :id="FORM_ID"
      v-qa-generate
      class="h-mb-8 h-mb-0-md"
      :is-loading="isLoading"
    >
      {{ t('Continue') }}
    </HButton>
  </HModalActions>
</template>
