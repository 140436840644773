import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { wordpressRepo } from '@/repositories';
import type { IAvailableWordPressPlugin } from '@/types';
import { Hosting, Header } from '@/types';

export const useWordPressPluginsStore = defineStore(
  'wordPressPluginsStore',
  () => {
    const pluginsData = ref<IAvailableWordPressPlugin[]>([]);
    const isPluginsDataLoading = ref(false);
    const store = useStore();

    const route = useRoute();

    const isAIPluginAvailable = computed(
      () =>
        !!pluginsData.value.find(
          (plugin) =>
            plugin.slug === Hosting.WordPressPluginName.HOSTINGER_AI_ASSISTANT,
        ),
    );

    const removeAffiliatePlugin = (plugins: IAvailableWordPressPlugin[]) =>
      (pluginsData.value = plugins.filter(
        (plugin) =>
          plugin.slug !==
          Hosting.WordPressPluginName.HOSTINGER_AFFILIATE_PLUGIN,
      ));

    const fetchWordPressPlugins = async (directory: string) => {
      isPluginsDataLoading.value = true;

      const { domain, domainToManage } = route.params;

      const currentAccount = store.getters.getCurrentAccountWithDomain(
        route.params.domain as string,
      );

      if (!currentAccount) return;

      const { username, referenceId } = currentAccount;

      const [{ data }, err] = await wordpressRepo.getAvailablePlugins({
        directory: directory || '',
        headers: {
          [Header.DOMAIN]: (domainToManage || domain) as string,
          [Header.ORDER_ID]: referenceId,
          [Header.USERNAME]: username,
        },
      });

      isPluginsDataLoading.value = false;

      if (err) return;

      pluginsData.value = data;
    };

    return {
      fetchWordPressPlugins,
      pluginsData,
      isAIPluginAvailable,
      isPluginsDataLoading,
      removeAffiliatePlugin,
    };
  },
);
