import { getLocationParams } from '@/utils/getLocationParams';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const PAYMENTS_API_URL = `${process.env.VITE_API_SSO}/v1/payments`;

export default {
  async generatePurchaseInvoice(params) {
    return await hAsync(
      http.post(`${PAYMENTS_API_URL}/invoice`, {
        ...params,
        ...getLocationParams(),
      }),
    );
  },

  instantPay(data) {
    return hAsync(
      http.post(`${PAYMENTS_API_URL}/instant-pay`, {
        ...data,
        forterToken: window.forterToken,
        ...getLocationParams(),
      }),
    );
  },

  instantInvoicePay(invoiceId) {
    return hAsync(
      http.post(`${PAYMENTS_API_URL}/invoice/instant-pay`, {
        invoiceId,
        forterToken: window.forterToken,
        ...getLocationParams(),
      }),
    );
  },
};
