import type {
  NavigationGuard,
  RouteLocationNormalized,
  NavigationGuardNext,
  RouteRecordName,
} from 'vue-router';

import {
  useHpanelHostingTabV2Experiment,
  useHostingOrders,
} from '@/composables';
import { Route } from '@/types';

const hostingPageGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { isHpanelHostingTabV2ExperimentActive } =
    useHpanelHostingTabV2Experiment();

  if (!isHpanelHostingTabV2ExperimentActive.value) {
    return next();
  }

  const { activeHostingOrders } = useHostingOrders();

  const currentHostingOrder = activeHostingOrders.value?.find(
    (hostingOrder) => hostingOrder.id === to.params.id,
  );

  const routesMap: Record<RouteRecordName, RouteRecordName> = {
    [Route.Hosting.DETAILS_V2]: Route.HostingOrder.DETAILS,
    [Route.Hosting.RESOURCES_USAGE_V2]: Route.HostingOrder.ORDER_USAGE,
    [Route.Hosting.HOSTING_UPGRADE_V2]: Route.Order.ORDER_UPGRADE,
  };
  const routeToName = routesMap[to.name ?? ''];

  if (currentHostingOrder?.hostname && routeToName) {
    return next({
      name: routeToName,
      params: { domain: currentHostingOrder.hostname },
    });
  }

  return next({ name: Route.Base.WEBSITES });
};

export default hostingPageGuard;
