import type {
  ClientReferrals,
  ReferralClientInfo,
  CartInviteInfo,
  BankAccountRequestParams,
  CartStatusItem,
  IUserReferralData,
  RequestConfig,
  IReferralReward,
  IReferralCartUrl,
  ILastReferredPlan,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_INTEGRATIONS}/api/direct/referral-rock`,
  //@ts-ignore
  async getIsProMember() {
    return await hAsync<ReferralClientInfo>(
      http.get(`${this.url}/pro/member`, { hideToastr: true }),
    );
  },
  //@ts-ignore
  async registerProMember() {
    return await hAsync(http.post(`${this.url}/pro/register`));
  },
  async getReferralClientInfo(hideToastr?: boolean) {
    return await hAsync<IUserReferralData>(
      http.get(`${this.url}/member`, {
        hideToastr,
        whitelistedErrorCodes: [2008],
      }),
    );
  },
  async getClientReferrals(config?: RequestConfig) {
    return await hAsync<ClientReferrals>(
      http.get(`${this.url}/referrals`, config),
    );
  },

  async postProPaypalEmail(paypalEmail: string) {
    return await hAsync<boolean>(
      http.post(`${this.url}/pro/paypal-email`, { paypalEmail }),
    );
  },

  async getPaypalEmails() {
    return await hAsync<string[]>(http.get(`${this.url}/paypal-emails`));
  },

  //@ts-ignore
  async addPaypalEmail(data) {
    return await hAsync(
      http.post(`${this.url}/paypal-email`, { paypal_email: data.email }),
    );
  },

  async setPaypalEmails(data: { email: string }) {
    return await hAsync(
      http.patch(`${this.url}/paypal-email`, { paypal_email: data.email }),
    );
  },

  async deletePaypal() {
    return await hAsync(http.delete(`${this.url}/paypal-email`));
  },

  async deleteBankAccount() {
    return await hAsync(http.delete(`${this.url}/bank-account`));
  },

  async getRegistrationPaypalEmails() {
    return await hAsync<string[] | { result: string[] }>(
      http.get(`${this.url}/register`),
    );
  },

  //@ts-ignore
  async setRegistrationPaypalEmail(data) {
    return await hAsync(http.post(`${this.url}/register`, data));
  },

  //@ts-ignore
  async setInviteEmail(data) {
    return await hAsync(http.post(`${this.url}/referral/invite`, data));
  },
  // Pro Space Referrals
  async getReferralProClientInfo(hideToastr: boolean) {
    return await hAsync<ReferralClientInfo>(
      http.get(`${this.url}/pro/member`, { hideToastr }),
    );
  },
  async getProClientReferrals(hideToastr = false) {
    return await hAsync<ClientReferrals>(
      http.get(`${this.url}/pro/referrals`, {
        hideToastr,
        whitelistedErrorCodes: [2008],
      }),
    );
  },

  async getProPaypalEmails() {
    return await hAsync<string[]>(http.get(`${this.url}/pro/paypal-emails`));
  },

  async postProBankAccount(params: BankAccountRequestParams) {
    return hAsync<boolean>(http.post(`${this.url}/pro/bank-account`, params));
  },

  //@ts-ignore
  addBankAccount(data) {
    return hAsync(http.post(`${this.url}/bank-account`, data));
  },

  async postToProReferralRock(data: { referralCustomerId: string }) {
    return await hAsync(http.post(`${this.url}/pro/referral`, data));
  },

  async postToProReferralRockByEmail(data: { email: string }) {
    return await hAsync(http.post(`${this.url}/pro/referral/by-email`, data));
  },

  async postRegularReferralRockCartInvite(data: CartInviteInfo) {
    return await hAsync(http.post(`${this.url}/referral/cart/invite`, data));
  },
  async postReferralRockCartInvite(data: CartInviteInfo) {
    return await hAsync(
      http.post(`${this.url}/pro/v2/referral/cart/invite`, data),
    );
  },
  async getProCartStatus() {
    return await hAsync<CartStatusItem[]>(
      http.get(`${this.url}/pro/referral/cart/invite`),
    );
  },

  async getRewards() {
    return await hAsync<IReferralReward[]>(http.get(`${this.url}/rewards`));
  },

  async generateCartLink(itemPriceId: string) {
    return await hAsync<IReferralCartUrl>(
      http.post(`${this.url}/referral/cart/generate`, {
        itemPriceId,
        quantity: '1',
      }),
    );
  },

  async getLastReferredPlan() {
    return await hAsync<ILastReferredPlan>(
      http.get(`${this.url}/member/last-referred-plan`),
    );
  },
};
