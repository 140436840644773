import { ref } from 'vue';

import { hMailIntegrationRepo } from '@/repositories';
import { useProfileStore, useResourcesStore } from '@/stores';
import { EMAIL_SERVICE_STATUS } from '@/types';
import type {
  EmailListItem,
  EmailServiceListItem,
  EmailServiceListParams,
  EmailsServiceStatus,
} from '@/types';
import { Email } from '@/types/enums/emailEnum';
import { isCanceledRequestError } from '@/utils/helpers';

const resolveStatus = (status: EmailsServiceStatus) => {
  const mapping = {
    [EMAIL_SERVICE_STATUS.INITIAL]: Email.OrderStatus.PENDING_DATA_ENTRY,
    [EMAIL_SERVICE_STATUS.ACTIVE]: Email.OrderStatus.ACTIVE,
    [EMAIL_SERVICE_STATUS.PENDING_SETUP]: Email.OrderStatus.PENDING_DATA_ENTRY,
    [EMAIL_SERVICE_STATUS.FAILED]: Email.OrderStatus.FAILED,
    [EMAIL_SERVICE_STATUS.SUSPENDED]: Email.OrderStatus.SUSPENDED,
    [EMAIL_SERVICE_STATUS.ACTIVATING]: Email.OrderStatus.IN_PROGRESS,
    [EMAIL_SERVICE_STATUS.CANCELED]: Email.OrderStatus.CANCELED,
    [EMAIL_SERVICE_STATUS.DELETED]: Email.OrderStatus.CANCELED,
  };

  return mapping[status] ?? status;
};

const resolveServiceName = (provider: Email.EmailProvider) => {
  const mapping = {
    [Email.EmailProvider.TITAN]: Email.Product.FLOCK_MAIL,
    [Email.EmailProvider.GOOGLE]: Email.Product.GOOGLE_GSUITE,
    [Email.EmailProvider.HOSTINGER]: Email.Product.HMAIL,
  };

  return mapping[provider] ?? provider;
};

export const useEmailServices = () => {
  const resourceStore = useResourcesStore();
  const profileStore = useProfileStore();

  const isLoading = ref(false);
  const searchAbortController = ref<AbortController | null>(null);

  const mapToEmailsListItem = (data: EmailServiceListItem): EmailListItem => {
    const resourceId = data.resourceId;
    const resource = resourceStore.getResourceById(resourceId);

    return {
      id: data.id,
      title: data.plan.title,
      serviceName: resolveServiceName(data.provider),
      status: resolveStatus(data.status),
      emailPlan: '',
      serviceId: resourceId,
      hresourceId: resourceId,
      referenceId: Number(resource?.referenceId),
      orderId: data.orderId,
      ctaText: '',
      domain: data.domain ?? '',
      expiresAt: data.expiresAt,
      emailLimit: data.seats,
      setupMessage: '',
      suspensionReason: resource?.reason ?? '',
      titanChosen: data.metadata.titanChosen,
      customerEmail: data.customerEmail,
      customerId: data.customerId,
      isOwnEmail: profileStore.getIsSameClientId(data.customerId),
      planKey: data.plan.name,
    };
  };

  const getServerSortKey = (key?: string) => {
    if (key === 'name') {
      return 'domain';
    }

    return key;
  };

  const fetchData = async (params: EmailServiceListParams) => {
    if (searchAbortController.value) {
      searchAbortController.value.abort();
    }
    const controller = new AbortController();
    searchAbortController.value = controller;
    isLoading.value = true;
    const [response, err] = await hMailIntegrationRepo.getAllServices(
      {
        ...params,
        search: params.search?.trim() || undefined,
        sortBy: getServerSortKey(params.sortBy),
        sortDir: params.sortDir?.toUpperCase(),
      },
      { signal: controller.signal },
    );

    if (response.data || !isCanceledRequestError(err)) {
      isLoading.value = false;
    }

    if (err) {
      return {
        data: [],
        meta: { total: 0 },
        error: err,
      };
    }

    return {
      ...response,
      data: response.data.map(mapToEmailsListItem),
    };
  };

  return {
    isLoading,
    fetchData,
  };
};
