import { useExperiment } from '@/composables/useExperiment';
import { Experiment } from '@/types';

export const useNavigationExperiment = () => {
  const { isExperimentActive: isNavigationExperimentActive } = useExperiment(
    Experiment.ID.HPANEL_NAVIGATION_CHANGES,
  );

  return {
    isNavigationExperimentActive,
  };
};
