import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { ref, watch, nextTick, computed } from 'vue';

import BillingAddPaymentMethodModal from '@/components/Modals/HModal/Billing/BillingAddPaymentMethodModal.vue';
import BulkActionProgressModal from '@/components/Modals/HModal/HDomains/BulkActionProgressModal.vue';
import ConfirmBulkActionModal from '@/components/Modals/HModal/HDomains/ConfirmBulkActionModal.vue';
import DomainAutoRenewBulkActionModal from '@/components/Modals/HModal/HDomains/DomainAutoRenewBulkActionModal.vue';
import DomainForwardingBulkActionModal from '@/components/Modals/HModal/HDomains/DomainForwardingBulkActionModal.vue';
import SelectBulkActionModal from '@/components/Modals/HModal/HDomains/SelectBulkActionModal.vue';
import {
  useModal,
  useGlobals,
  useSubscriptions,
  usePaymentMethod,
} from '@/composables';
import { useManagedDomainBulkActionFlow } from '@/composables/hDomains/useManagedDomainBulkActionFlow';
import { hDomainsRepo } from '@/repositories';
import { useVHostsStore } from '@/stores';
import { useDomainBulkActionsStore } from '@/stores/domainBulkActionsStore';
import type {
  IHDomainActionValidation,
  IDomainBulkActionInputValues,
  Vhost,
  IDomainBulkAction,
  IDomainBulkActionPayload,
} from '@/types';
import { HDomains, AmplitudeEvent } from '@/types';
import { readableToSnake } from '@/utils/helpers';
import { getModalDetails } from '@/utils/helpers/hDomains/domainBulkActionDetailsHelper';
import { errorLogger } from '@/utils/services/errorLogging';

const ENABLE_ACTIONS = [
  HDomains.BulkActionSlug.DOMAIN_LOCK,
  HDomains.BulkActionSlug.DOMAIN_PRIVACY_PROTECTION_ENABLE,
  HDomains.BulkActionSlug.DOMAIN_AUTO_RENEW_ENABLE,
];

const DISABLE_ACTIONS = [
  HDomains.BulkActionSlug.DOMAIN_UNLOCK,
  HDomains.BulkActionSlug.DOMAIN_PRIVACY_PROTECTION_DISABLE,
  HDomains.BulkActionSlug.DOMAIN_AUTO_RENEW_DISABLE,
];

const ACTION_TYPE = {
  ENABLE: 'enable',
  DISABLE: 'disable',
};

export const useDomainBulkActionFlow = (
  actionItemsList: Ref<
    { domain: string; subscriptionId: string; isManagedDomain?: boolean }[]
  >,
) => {
  const domainBulkActionsStore = useDomainBulkActionsStore();
  const { getSubscriptionById, isSubscriptionAutoRenewalEnabled } =
    useSubscriptions();
  const { openModal } = useModal();
  const { amplitudeV2, t } = useGlobals();
  const { defaultPaymentMethod } = usePaymentMethod();
  const { internalDomains } = storeToRefs(useVHostsStore());

  const validatedDomains = ref<IHDomainActionValidation | null>(null);
  const selectedActionSlug = ref<HDomains.BulkActionSlug | ''>('');
  const bulkActionType = ref<HDomains.BulkActionType | ''>('');
  const selectedActionInputValues = ref<IDomainBulkActionInputValues>({});

  const {
    isBulkActionAllowedForManagedDomain,
    isOnlyManagedDomainsSelected,
    domainListNotAllowedForBulkAction,
    domainListAllowedForBulkAction,
    shouldUseWebproEndpoint,
    hasManagedDomainsSelected,
  } = useManagedDomainBulkActionFlow(actionItemsList, selectedActionSlug);

  const domainsList = computed(() =>
    actionItemsList.value.map(({ domain }) => domain),
  );

  const isDomainForwardCreateAction = (actionSlug: HDomains.BulkActionSlug) =>
    actionSlug === HDomains.BulkActionSlug.DOMAIN_FORWARD_CREATE;

  const isDomainAutoRenewEnableAction = (actionSlug: HDomains.BulkActionSlug) =>
    actionSlug === HDomains.BulkActionSlug.DOMAIN_AUTO_RENEW_ENABLE;

  const isDomainAutoRenewDisableAction = (
    actionSlug: HDomains.BulkActionSlug,
  ) => actionSlug === HDomains.BulkActionSlug.DOMAIN_AUTO_RENEW_DISABLE;
  const handleDomainBulkActionClick = (
    actionTitle: HDomains.BulkActionType,
  ) => {
    bulkActionType.value = actionTitle;
    selectedActionSlug.value = '';
    selectedActionInputValues.value = {};
    openDomainBulkActionModal();
  };

  const getBulkActionModalDetails = () =>
    getModalDetails(
      bulkActionType.value,
      selectedActionSlug.value,
      selectedActionInputValues.value,
    );

  const openDomainBulkActionModal = () => {
    const { primaryTitle, subtitle, options, textOptions, infoText } =
      getBulkActionModalDetails();

    openModal({
      component: { SelectBulkActionModal },
      data: {
        title: primaryTitle,
        subtitle,
        options,
        textOptions,
        infoText,
        onContinue: async ({
          bulkActionType,
          bulkActionPayload,
        }: {
          bulkActionType: HDomains.BulkActionSlug;
          bulkActionPayload: IDomainBulkActionInputValues;
        }) => {
          selectedActionSlug.value = bulkActionType;
          selectedActionInputValues.value = bulkActionPayload;
          dispatchActionChosenAmplitudeEvent();

          if (isDomainForwardCreateAction(bulkActionType)) {
            openDomainForwardingBulkActionModal();

            return;
          }

          if (isDomainAutoRenewDisableAction(bulkActionType)) {
            openDomainAutoRenewBulkActionModal();

            return;
          }

          if (
            isDomainAutoRenewEnableAction(bulkActionType) &&
            !defaultPaymentMethod.value
          ) {
            openAddPaymentMethodModal();

            return;
          }

          await nextTick();
          await checkBulkActionAvailability();
        },
      },
    });
  };

  const checkBulkActionAvailability = async () => {
    if (validatedDomains.value) {
      openBulkActionConfirmModal();

      return;
    }

    const [{ data }, error] = await hDomainsRepo.postBulkActionAvailability(
      getFinalBulkActionSubmitDetails(domainListAllowedForBulkAction.value),
      shouldUseWebproEndpoint.value,
    );

    if (error) {
      errorLogger.logError(
        new Error('bulk action validation error', { cause: error }),
      );

      return;
    }

    validatedDomains.value = setValidatedDomains(data);

    openBulkActionConfirmModal();
  };

  const getUnavailableList = () => {
    const unprocessableDomains = validatedDomains.value?.unprocessable || [];

    if (isBulkActionAllowedForManagedDomain.value) {
      return unprocessableDomains;
    }

    return [
      ...unprocessableDomains,
      ...domainListNotAllowedForBulkAction.value.map(
        (domain) => `${domain} - ${t('unavailable for client domain')}`,
      ),
    ];
  };

  const openBulkActionConfirmModal = () => {
    const { secondaryTitle, primaryTitle, subtitle, confirmInfoText } =
      getBulkActionModalDetails();

    openModal({
      component: { ConfirmBulkActionModal },
      steps: [
        {
          hideX: true,
          goBackText: primaryTitle,
          goBackCustom: () => openDomainBulkActionModal(),
        },
      ],
      data: {
        title: secondaryTitle,
        subtitle,
        completedList: validatedDomains.value?.skipped,
        availableList: validatedDomains.value?.processing,
        unavailableList: getUnavailableList(),
        confirmInfoText,
        onGoBack: openBulkActionConfirmModal,
        onConfirm: () => {
          initiateDomainBulkAction();
          dispatchActionConfirmedAmplitudeEvent();
        },
      },
    });
  };

  const openDomainForwardingBulkActionModal = () => {
    openModal({
      component: { DomainForwardingBulkActionModal },
      data: {
        onSubmit: (inputValues: IDomainBulkActionInputValues) => {
          selectedActionInputValues.value = inputValues;
          checkBulkActionAvailability();
        },
      },
    });
  };

  const openDomainAutoRenewBulkActionModal = () => {
    openModal({
      component: { DomainAutoRenewBulkActionModal },
      data: {
        onSubmit: () => {
          checkBulkActionAvailability();
        },
      },
    });
  };

  const openAddPaymentMethodModal = () => {
    openModal({ component: { BillingAddPaymentMethodModal } });
  };

  const getAmplitudeActionParam = () => {
    const actionMapping = {
      [HDomains.BulkActionType.DOMAIN_LOCK]: [
        HDomains.BulkActionSlug.DOMAIN_LOCK,
        HDomains.BulkActionSlug.DOMAIN_UNLOCK,
      ],
      [HDomains.BulkActionType.CHANGE_NAMESERVERS]: [
        HDomains.BulkActionSlug.DOMAIN_NAMESERVER_UPDATE_DEFAULT,
        HDomains.BulkActionSlug.DOMAIN_NAMESERVER_UPDATE_CUSTOM,
      ],
      [HDomains.BulkActionType.PRIVACY_PROTECTION]: [
        HDomains.BulkActionSlug.DOMAIN_PRIVACY_PROTECTION_ENABLE,
        HDomains.BulkActionSlug.DOMAIN_PRIVACY_PROTECTION_DISABLE,
      ],
      [HDomains.BulkActionType.DOMAIN_FORWARDING]: [
        HDomains.BulkActionSlug.DOMAIN_FORWARD_CREATE,
        HDomains.BulkActionSlug.DOMAIN_FORWARD_DELETE,
      ],
      [HDomains.BulkActionType.AUTO_RENEW]: [
        HDomains.BulkActionSlug.DOMAIN_AUTO_RENEW_ENABLE,
        HDomains.BulkActionSlug.DOMAIN_AUTO_RENEW_DISABLE,
      ],
    };

    const action =
      Object.keys(actionMapping).find((key: string) =>
        actionMapping[key as HDomains.BulkActionType].includes(
          selectedActionSlug.value as HDomains.BulkActionSlug,
        ),
      ) || '';

    return readableToSnake(action);
  };

  const dispatchActionConfirmedAmplitudeEvent = () => {
    amplitudeV2(AmplitudeEvent.Domain.DOMAIN_ACTION_BAR_CONFIRMED, {
      action: getAmplitudeActionParam(),
      domainsAvailableForAction: validatedDomains.value?.processing?.length,
      isOwnService: Number(!hasManagedDomainsSelected.value),
    });
  };

  const getActionType = () => {
    const selectedValue = selectedActionSlug.value as HDomains.BulkActionSlug;

    if (ENABLE_ACTIONS.includes(selectedValue)) {
      return ACTION_TYPE.ENABLE;
    } else if (DISABLE_ACTIONS.includes(selectedValue)) {
      return ACTION_TYPE.DISABLE;
    }
  };

  const dispatchActionChosenAmplitudeEvent = () => {
    amplitudeV2(AmplitudeEvent.Domain.DOMAIN_ACTION_TYPE_CHOSEN, {
      action: getAmplitudeActionParam(),
      domainsSelected: domainsList.value.length,
      actionType: getActionType(),
    });
  };

  const initiateDomainBulkAction = async () => {
    const domainsToProcess = validatedDomains.value?.processing || [];
    if (!domainsToProcess.length) return;

    await domainBulkActionsStore.deleteBulkAction();
    const [, error] = await domainBulkActionsStore.createBulkAction(
      getFinalBulkActionSubmitDetails(domainsToProcess),
      shouldUseWebproEndpoint.value,
    );

    if (!error) {
      openModal({ component: { BulkActionProgressModal } });

      domainBulkActionsStore.fetchBulkActionsUntilCompleted();
    }
  };

  const getFinalBulkActionSubmitDetails = (
    domainsList: string[],
  ): IDomainBulkAction => {
    const submitDetails = {
      domains: domainsList,
      action: selectedActionSlug.value,
      payload: prepareActionPayload(domainsList),
    };

    if (
      selectedActionSlug.value ===
      HDomains.BulkActionSlug.DOMAIN_NAMESERVER_UPDATE_DEFAULT
    ) {
      return {
        ...submitDetails,
        action: HDomains.BulkActionSlug.DOMAIN_NAMESERVER_UPDATE_CUSTOM,
        payload: [
          HDomains.HostingerNameservers.NS1,
          HDomains.HostingerNameservers.NS2,
        ],
      };
    }

    return submitDetails;
  };

  const prepareActionPayload = (
    domainsList: string[],
  ): IDomainBulkActionPayload => {
    if (bulkActionType.value === HDomains.BulkActionType.DOMAIN_FORWARDING) {
      return {
        redirectType: selectedActionInputValues.value.redirectType!,
        redirectUrl: selectedActionInputValues.value.redirectUrl!,
      };
    }

    if (bulkActionType.value === HDomains.BulkActionType.AUTO_RENEW) {
      return actionItemsList.value.filter(({ domain }) =>
        domainsList.includes(domain),
      );
    }

    const payloadValues = Object.values(selectedActionInputValues.value);

    if (payloadValues.length) {
      return payloadValues.filter((item) => !!item);
    }

    return [];
  };

  const filterDomains = (currentList: string[], domainsToFilter: string[]) =>
    currentList.filter((domain) => !domainsToFilter.includes(domain));

  const mergeDomains = (currentList: string[], domainsToAdd: string[]) => [
    ...currentList,
    ...domainsToAdd.filter((domain) => !currentList.includes(domain)),
  ];

  const setValidatedDomains = (data: IHDomainActionValidation) => {
    const action = selectedActionSlug.value as HDomains.BulkActionSlug;
    const isEnableAutoRenewAction = isDomainAutoRenewEnableAction(action);
    const isDisableAutoRenewAction = isDomainAutoRenewDisableAction(action);
    const isForwardCreateAction = isDomainForwardCreateAction(action);

    if (isForwardCreateAction) {
      const unprocessableDomains = internalDomains.value.flatMap(
        ({ vhost }: Vhost) => (domainsList.value.includes(vhost) ? vhost : []),
      );

      return {
        ...data,
        processing: filterDomains(data.processing, unprocessableDomains),
        unprocessable: mergeDomains(data.unprocessable, unprocessableDomains),
      };
    }

    if (isEnableAutoRenewAction || isDisableAutoRenewAction) {
      const skippedDomains = actionItemsList.value.flatMap(
        ({ domain, subscriptionId }) => {
          const subscription = getSubscriptionById(subscriptionId);
          const isAutoRenewEnabled =
            isSubscriptionAutoRenewalEnabled(subscription);

          return isEnableAutoRenewAction === isAutoRenewEnabled ? domain : [];
        },
      );

      return {
        ...data,
        skipped: mergeDomains(data.skipped, skippedDomains),
        processing: filterDomains(data.processing, skippedDomains),
        unprocessable: filterDomains(data.unprocessable, skippedDomains),
      };
    }

    return data;
  };

  watch(
    [() => domainsList.value, () => selectedActionSlug.value],
    () => {
      validatedDomains.value = null;
    },
    { deep: true },
  );

  return {
    handleDomainBulkActionClick,
    isOnlyManagedDomainsSelected,
    hasManagedDomainsSelected,
  };
};
