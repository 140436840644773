import { hMailIntegrationRepo, hostingEmailsRepo } from '@/repositories';
import { getStateVariable, setForDomain } from '@/store/storeHelper';
import { Email } from '@/types';
import { toASCII } from '@/utils/helpers';

export default {
  state: {
    initial: {
      emailAccounts: {
        loaded: false,
        data: [],
      },
      emailInfo: {
        loaded: false,
        data: {},
      },
      details: {
        loaded: false,
        loading: false,
        data: {},
      },
      aliases: {
        loaded: false,
        data: [],
      },
      forwards: {
        loaded: false,
        data: {},
      },
      autoReply: {
        loaded: false,
        data: [],
      },
      accessLogs: {
        loaded: false,
        data: [],
        total: 0,
        error: null,
      },
      currentOrderId: {
        data: null,
      },
      actionLogs: {
        loaded: false,
        data: [],
        total: null,
        error: null,
      },
      deliveryLogs: {
        loaded: false,
        data: [],
        total: 0,
        error: null,
      },
    },
    data: [],
  },
  getters: {
    getEmailAccounts: (state) => getStateVariable(state, 'emailAccounts'),
    getEmailsDetails: (state) => getStateVariable(state, 'details'),
    getAliases: (state) => getStateVariable(state, 'aliases'),
    getAliasGroups: (state) => {
      const aliases = getStateVariable(state, 'aliases');

      return aliases.data.reduce(
        (acc, item) => ({
          ...acc,
          [item.addressTo]: acc[item.addressTo]
            ? [...acc[item.addressTo], item]
            : [item],
        }),
        {},
      );
    },
    getForwarders: (state) => getStateVariable(state, 'forwards'),
    getAutoReply: (state) => getStateVariable(state, 'autoReply'),
    getEmailCurrentOrderId: (state) =>
      getStateVariable(state, 'currentOrderId'),
    getCurrentAutoReply: (state) => (email) => {
      const autoReply = getStateVariable(state, 'autoReply');

      return autoReply.data.find((item) => item.email === email) || {};
    },
    getEmailAccessLogs: (state) => getStateVariable(state, 'accessLogs'),
    getEmailActionLogs: (state) => getStateVariable(state, 'actionLogs'),
    getEmailDeliveryLogs: (state) => getStateVariable(state, 'deliveryLogs'),
    getEmailInfo: (state) => getStateVariable(state, 'emailInfo'),
  },
  mutations: {
    domainChange: (state) => (state.data = [...state.data]),
    SET_LOADED_DATA: (state, { type, data }) => {
      const items = getStateVariable(state, type) || state.initial[type];
      items.loaded = true;
      items.data = data;
      setForDomain(state, null, type, items);
    },
    SET_EMAILS_DATA_LOADING: (state, type) => {
      const items = getStateVariable(state, type) || state.initial[type];
      items.loaded = false;
      setForDomain(state, null, type, items);
    },
    SET_EMAIL_DETAILS_LOADING: (state, payload) => {
      const details = getStateVariable(state, 'details');
      details.loading = payload;
      setForDomain(state, null, 'details', details);
    },
    SET_EMAIL_ORDER_ID: (state, payload) => {
      const domain = payload.domain || null;
      setForDomain(state, domain, 'currentOrderId', payload.currentId);
    },
    DELETE_EMAILS_ACCOUNT: (state, domain) => {
      const accounts = getStateVariable(state, 'emailAccounts');
      accounts.data = accounts.data.filter((acc) => acc.account !== domain);
      setForDomain(state, null, 'emailAccounts', accounts);
    },
    SET_ACCOUNT_COUNT: (state, increment) => {
      const details = getStateVariable(state, 'details');

      details.data.domain.accountCount = increment
        ? parseInt(details.data.domain.accountCount) + 1
        : parseInt(details.data.domain.accountCount) - 1;

      setForDomain(state, null, 'details', details);
    },
    UPDATE_ACCOUNT_DETAILS: (state, { account, type, increment }) => {
      const accounts = getStateVariable(state, 'emailAccounts');
      const currentAccount = accounts.data.find(
        (acc) => acc.account === account,
      );

      if (!currentAccount) {
        return;
      }

      if (type !== 'isCatchall') {
        currentAccount[type] = increment
          ? currentAccount[type] + 1
          : currentAccount[type] - 1;
      } else {
        currentAccount[type] = increment;
      }

      setForDomain(state, null, 'emailAccounts', accounts);
    },
    SET_EMAIL_LOGS: (state, { data, type }) => {
      setForDomain(state, null, type, {
        data: data?.data || [],
        total: data?.total || 0,
      });
    },
    SET_EMAIL_INFO: (state, { data, domain, type }) => {
      setForDomain(state, domain, type, data);
    },
  },
  actions: {
    async fetchHostingEmailsAccounts({ commit }, { domain, orderId, reload }) {
      if (reload) commit('SET_EMAILS_DATA_LOADING', 'emailAccounts');
      const [{ data }] = await hostingEmailsRepo.getEmailAccounts(
        domain,
        orderId,
      );
      commit('SET_LOADED_DATA', { type: 'emailAccounts', data });
    },
    async fetchEmailDomainAndPlanDetails({ commit }, { domain, orderId }) {
      commit('SET_EMAIL_DETAILS_LOADING', true);

      const [domainDetails] = await hostingEmailsRepo.getDomainDetails(
        domain,
        orderId,
      );
      const [planDetails] = await hostingEmailsRepo.getPlanDetails(
        domainDetails?.data.planName,
        domainDetails?.data.planRevision,
        domain,
        orderId,
      );

      commit('SET_LOADED_DATA', {
        type: 'details',
        data: {
          domain: domainDetails.data,
          plan: planDetails.data,
        },
      });
    },
    async fetchEmailDetails(
      { commit, dispatch, getters, rootGetters },
      { domain, reload, orderId, reloadAccounts = true },
    ) {
      if (!reload && getters.getEmailsDetails.loaded) return;

      commit('SET_EMAIL_DETAILS_LOADING', true);
      const { id } = rootGetters['emails/getPremiumMail'](
        Email.EmailProvider.HOSTINGER,
        domain,
      );

      await Promise.all([
        id
          ? dispatch('fetchEmailDomainAndPlanDetails', {
              domain,
              orderId: id,
            })
          : null,
        dispatch('fetchHostingEmailsAccounts', {
          domain,
          orderId,
          reload: reloadAccounts,
        }),
      ]);

      commit('SET_EMAIL_DETAILS_LOADING', false);
    },
    async fetchAliases({ commit }, { domainResourceId, reload }) {
      if (reload) commit('SET_EMAILS_DATA_LOADING', 'aliases');
      const [{ data }] = await hostingEmailsRepo.getAliases(domainResourceId);
      commit('SET_LOADED_DATA', { type: 'aliases', data: data.aliases });
    },
    async fetchForwards({ commit }, { domain, reload }) {
      if (reload) commit('SET_EMAILS_DATA_LOADING', 'forwards');
      const [{ data }] = await hostingEmailsRepo.getForwards(domain);
      commit('SET_LOADED_DATA', { type: 'forwards', data });
    },
    async fetchAutoReply({ commit }, { domainResourceId, reload }) {
      if (reload) commit('SET_EMAILS_DATA_LOADING', 'autoReply');
      const [{ data }] = await hostingEmailsRepo.getAutoReply(domainResourceId);
      commit('SET_LOADED_DATA', { type: 'autoReply', data: data.autoreplies });
    },
    async deleteEmailAccount({ commit }, { email, resourceId }) {
      const response = await hostingEmailsRepo.deleteEmailAccount(resourceId);
      const [, error] = response;

      if (error) return response;

      commit('DELETE_EMAILS_ACCOUNT', email);
      commit('SET_ACCOUNT_COUNT', false);

      return response;
    },

    async fetchEmailLogs({ commit }, { params, domain, account }) {
      const [{ data }, err] = await hostingEmailsRepo.getEmailAccessLogs(
        params,
        domain,
        account,
      );
      if (!err) commit('SET_EMAIL_LOGS', { data, type: 'accessLogs' });

      return data;
    },

    async fetchEmailActionLogs({ commit }, { params, domain }) {
      const [{ data }, err] = await hostingEmailsRepo.getEmailActionLogs(
        params,
        domain,
      );

      if (!err) {
        commit('SET_EMAIL_LOGS', { data, type: 'actionLogs' });
      }

      return data;
    },
    async fetchEmailInboundLogs({ commit }, { params, domain }) {
      const [{ data }, err] = await hostingEmailsRepo.getEmailInboundLogs(
        params,
        domain,
      );

      if (!err) {
        commit('SET_EMAIL_LOGS', { data, type: 'deliveryLogs' });
      }

      return data;
    },
    async fetchEmailOutboundLogs({ commit }, { params, domain }) {
      const [{ data }, err] = await hostingEmailsRepo.getEmailOutboundLogs(
        params,
        domain,
      );

      if (!err) {
        commit('SET_EMAIL_LOGS', { data, type: 'deliveryLogs' });
      }

      return data;
    },
    async fetchEmailInfo({ commit }, domain) {
      const [{ data }, err] = await hMailIntegrationRepo.getAllServices({
        search: domain,
      });

      if (err) {
        return;
      }

      const encodedDomain = toASCII(domain);
      // select service with exact domain (exclude subdomains)
      const emailInfo = data.find(
        (service) => toASCII(service.domain) === encodedDomain,
      );

      commit('SET_EMAIL_INFO', {
        data: { data: emailInfo, loaded: true },
        domain: encodedDomain,
        type: 'emailInfo',
      });

      return data;
    },
  },
};
