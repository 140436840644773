import type { IHDomainResource } from '@/types';

export interface IDomainTableRow extends IHDomainResource {
  isSelected: boolean;
  isDisabled: boolean;
  message: string;
}
export enum SelectedDomainsList {
  ALL_DOMAINS = 'all-domains',
  MY_DOMAINS = 'my-domains',
  CLIENT_DOMAINS = 'client-domains',
}
