<script setup lang="ts">
import type { FormValues } from '@hostinger/hcomponents';
import { computed, ref } from 'vue';

import HRadio from '@/components/HRadio.vue';
import { useGlobals, useDomain } from '@/composables';
import type { RedirectType, IDomainForwardingValues } from '@/types';
import { FieldType, HIcon, HDomains } from '@/types';
import { processDomainName } from '@/utils/helpers/domainsHelper';

interface Emits {
  (eventName: 'form-submitted', payload: IDomainForwardingValues): void;
}

const emit = defineEmits<Emits>();

const { t } = useGlobals();
const { domain } = useDomain();

const isAdvancedSettingsVisible = ref(false);

const redirectTypes = ref([
  {
    label: t('Temporary (302)'),
    value: HDomains.RedirectType.TEMPORARY,
    active: true,
  },
  {
    label: t('Permanent (301)'),
    value: HDomains.RedirectType.PERMANENT,
    active: false,
  },
]);

const toggleIcon = computed(() =>
  isAdvancedSettingsVisible.value
    ? HIcon.ICON_EXPAND_LESS
    : HIcon.ICON_EXPAND_MORE,
);

const domainForwardSchema = computed(() => ({
  name: 'forwardWebsite',
  type: FieldType.text,
  label: 'Website URL',
  hint: 'Example: hostinger.com',
  prepend: [
    { label: 'http://', value: 'http://' },
    { label: 'https://', value: 'https://', selected: true },
  ],
  validation: {
    required: true,
    custom: (value: string) => {
      if (processDomainName(value) === domain.value) {
        return {
          text: 'You cannot forward your domain to itself',
        };
      }
    },
  },
  getSplitValue: true,
}));

const handleRedirectTypeChange = (indexToChange: number) => {
  redirectTypes.value.forEach((option, index) => {
    option.active = index === indexToChange;
  });
};

const toggleAdvancedOptions = () => {
  isAdvancedSettingsVisible.value = !isAdvancedSettingsVisible.value;
};

const onSubmit = (value: FormValues) => {
  const redirectType =
    redirectTypes.value.find((option) => option.active)?.value ||
    (HDomains.RedirectType.TEMPORARY as RedirectType);

  emit('form-submitted', {
    formValues: value,
    redirectType,
  });
};
</script>

<template>
  <HForm @on-submit="onSubmit($event)">
    <HFormField
      :schema="domainForwardSchema"
      :name="domainForwardSchema.name"
      class="h-mb-16"
    />
    <hp-hyper-link
      arrow-left
      :icon.prop="toggleIcon"
      bold
      @click="toggleAdvancedOptions"
    >
      {{ t('Advanced options') }}
    </hp-hyper-link>

    <template v-if="isAdvancedSettingsVisible">
      <h4 v-trans class="h-mb-4 h-mt-16">Redirect type</h4>
      <p v-trans class="h-mb-16">
        If you plan to temporarily redirect your traffic to a different site, we
        recommend using Temporary redirect
      </p>

      <HRadio
        v-for="(option, index) in redirectTypes"
        :id="option.value"
        :key="option.value"
        :is-active="option.active"
        :label="option.label"
        class="h-mb-8"
        @click="handleRedirectTypeChange(index)"
      />
    </template>

    <slot name="action" />
  </HForm>
</template>

<style lang="scss" scoped>
::part(hp-field__prepend) {
  @media (min-width: 576px) {
    max-width: 110px;
  }
}
</style>
