import { computed } from 'vue';

import { useResourceMapper } from '@/composables';
import { useProfileStore, useResourcesStore } from '@/stores';
import {
  CLOUD_PLANS,
  BUSINESS_AND_CLOUD_PLANS,
  HRESOURCES_TYPE,
  HRESOURCES_STATE,
} from '@/types';
import { isOlderThanUnitsInUtc } from '@/utils/helpers';

export const useResourceFilters = (domain?: string) => {
  const {
    activeResources,
    p2pResource,
    activeHostingResources,
    getResourcesByTypesAndStates,
    upgradableHostingResources,
    pendingResources,
  } = useResourceMapper();

  const resourcesStore = useResourcesStore();

  const profileStore = useProfileStore();

  const isDomainOnlyUser = computed(
    () =>
      !!activeResources.value.length &&
      activeResources.value.every(({ type }) => type === 'domain'),
  );

  const isVPSOnlyUser = computed(
    () =>
      !!activeResources.value.length &&
      activeResources.value.every(({ type }) => type === 'virtual_machine'),
  );

  const activeHostingResourcesCount = computed(
    () =>
      getResourcesByTypesAndStates({
        types: ['hosting', 'cpanel_hosting', 'cpanel_reseller_hosting'],
        states: ['active'],
      }).length,
  );

  const pendingHostingResourcesCount = computed(
    () =>
      getResourcesByTypesAndStates({
        types: ['hosting', 'cpanel_hosting', 'cpanel_reseller_hosting'],
        states: ['pending_setup'],
      }).length,
  );

  const activeDomainResourcesCount = computed(
    () =>
      getResourcesByTypesAndStates({
        types: ['domain'],
        states: ['active'],
      }).length,
  );

  const domainResourcesCount = computed(
    () =>
      getResourcesByTypesAndStates({
        types: ['domain'],
      }).length,
  );

  const hasActiveHosting = computed(() => !!activeHostingResourcesCount.value);

  const hasPendingHosting = computed(
    () => !!pendingHostingResourcesCount.value,
  );

  const hasActiveResources = computed(() => !!activeResources.value.length);

  const hasDomains = computed(() => domainResourcesCount.value > 0);

  const hasHosting = computed(
    () =>
      !!getResourcesByTypesAndStates({
        types: ['hosting'],
      }).length,
  );

  const hasManageableHosting = computed(
    () =>
      !!getResourcesByTypesAndStates({
        types: ['hosting', 'cpanel_hosting', 'cpanel_reseller_hosting'],
        states: ['active', 'pending_setup', 'updating'],
      }).length,
  );

  const hasEmailToSetup = computed(
    () =>
      !!getResourcesByTypesAndStates({
        types: ['email'],
        states: ['pending_setup'],
      }).length,
  );

  const hasBusinessOrCloudHosting = computed(() =>
    getResourcesByTypesAndStates({
      types: ['hosting'],
      states: ['active', 'pending_setup'],
    }).some((resource) =>
      BUSINESS_AND_CLOUD_PLANS.includes(
        resource.subscription.cfSubscriptionParams?.plan,
      ),
    ),
  );

  const hasCloudHosting = computed(
    () =>
      !!activeResources.value.length &&
      activeResources.value.some(
        ({ type, subscription }) =>
          type === 'hosting' &&
          CLOUD_PLANS.includes(subscription.cfSubscriptionParams?.plan),
      ),
  );

  const hasVps = computed(
    () =>
      !!activeResources.value.length &&
      activeResources.value.some(({ type }) => type === 'virtual_machine'),
  );

  const hasVpsOlderThan7Days = computed(
    () =>
      (!!activeResources.value.length &&
        activeResources.value.some(
          ({ type, createdAt }) =>
            type === 'virtual_machine' &&
            isOlderThanUnitsInUtc(createdAt, 'day', 7),
        )) ||
      (!!pendingResources.value.length &&
        pendingResources.value.some(
          ({ type, createdAt }) =>
            type === 'virtual_machine' &&
            isOlderThanUnitsInUtc(createdAt, 'day', 7),
        )),
  );

  const hasEmails = computed(() =>
    getResourcesByTypesAndStates({
      types: ['email'],
      states: ['active', 'pending_setup'],
    }),
  );

  const hasActivePrioritySupport = computed(
    () =>
      !!getResourcesByTypesAndStates({
        types: [HRESOURCES_TYPE.PRIORITY_SUPPORT],
        states: [HRESOURCES_STATE.ACTIVE],
      }).length,
  );

  const suggestedEmailUserName = computed(() => {
    if (!profileStore.contact) return '';

    const firstNameOrEmail =
      profileStore.contact?.firstName || profileStore.contact.email;

    if (!firstNameOrEmail) return '';

    const firstNameParse =
      firstNameOrEmail.toLocaleLowerCase().split('@').shift() ?? '';

    const firstName = firstNameParse.replace(/\s/g, '');

    return firstName;
  });

  const emailAddressSuggestion = computed(
    () => `${suggestedEmailUserName.value}@${domain}`,
  );

  const firstUpgradableHostingResource = computed(
    () => upgradableHostingResources.value?.[0],
  );

  const isP2PUser = computed(
    () => p2pResource.value && !activeHostingResources.value.length,
  );

  const isP2PAvailableForUser = computed(
    () =>
      Boolean(resourcesStore.hostingResources) &&
      !resourcesStore.hostingResources.length,
  );

  return {
    isDomainOnlyUser,
    isP2PAvailableForUser,
    isVPSOnlyUser,
    hasActiveHosting,
    hasPendingHosting,
    hasHosting,
    hasDomains,
    hasManageableHosting,
    hasEmailToSetup,
    hasBusinessOrCloudHosting,
    activeHostingResourcesCount,
    activeDomainResourcesCount,
    hasActiveResources,
    hasEmails,
    hasVps,
    hasVpsOlderThan7Days,
    hasCloudHosting,
    hasActivePrioritySupport,
    emailAddressSuggestion,
    firstUpgradableHostingResource,
    isP2PUser,
  };
};
