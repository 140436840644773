import { computed, ref } from 'vue';

import { FeatureFlag } from '@/types';
import experimentService from '@/utils/services/experimentService';

export const useFeatureFlag = (featureFlagId: FeatureFlag.ID) => {
  const featureFlag = ref<string | undefined>(
    experimentService.getAmplitudeFeatureFlagById(featureFlagId),
  );

  const isFeatureEnabled = computed(
    () => featureFlag.value === FeatureFlag.Value.ENABLED,
  );

  return {
    isFeatureEnabled,
  };
};
