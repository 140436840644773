import type { FieldSchema } from '@hostinger/hcomponents';

import type { IDomainBulkActionInputValues } from '@/types';
import { HDomains, FieldType } from '@/types';

interface ModalDetails {
  primaryTitle: string;
  secondaryTitle: string;
  subtitle: string;
  options: {
    id: HDomains.BulkActionSlug;
    label: string;
    isActive: boolean;
  }[];
  textOptions?: FieldSchema[];
  infoText?: string;
  confirmInfoText?: string;
}

const NAMESERVERS = [
  {
    label: 'Nameserver 1',
    name: 'nameserver1',
    required: true,
  },
  {
    label: 'Nameserver 2',
    name: 'nameserver2',
    required: true,
  },
  {
    label: 'Nameserver 3',
    name: 'nameserver3',
    required: false,
  },
  {
    label: 'Nameserver 4',
    name: 'nameserver4',
    required: false,
  },
];

export const getDomainLockModalDetails = (
  selectedActionSlug: HDomains.BulkActionSlug | '',
) => ({
  primaryTitle: 'Manage Domain Lock',
  secondaryTitle: 'Confirm Domain Lock',
  subtitle:
    'Choose if you want to turn on or turn off domain lock for your domains. Enabling domain lock will secure your domain and prevent transfers.',
  options: [
    {
      id: HDomains.BulkActionSlug.DOMAIN_LOCK,
      label: 'Turn on domain lock',
      isActive:
        selectedActionSlug === HDomains.BulkActionSlug.DOMAIN_LOCK ||
        !selectedActionSlug,
    },
    {
      id: HDomains.BulkActionSlug.DOMAIN_UNLOCK,
      label: 'Turn off domain lock',
      isActive: selectedActionSlug === HDomains.BulkActionSlug.DOMAIN_UNLOCK,
    },
  ],
});

export const getDomainForwardingModalDetails = (
  selectedActionSlug: HDomains.BulkActionSlug | '',
) => ({
  primaryTitle: 'Manage Domain Forwarding',
  secondaryTitle: 'Confirm Domain Forwarding',
  subtitle:
    'Choose if you want to create new forwarders or remove existing ones',
  options: [
    {
      id: HDomains.BulkActionSlug.DOMAIN_FORWARD_CREATE,
      label: 'Add domain forwarders',
      isActive:
        selectedActionSlug === HDomains.BulkActionSlug.DOMAIN_FORWARD_CREATE ||
        !selectedActionSlug,
    },
    {
      id: HDomains.BulkActionSlug.DOMAIN_FORWARD_DELETE,
      label: 'Remove domain forwarders',
      isActive:
        selectedActionSlug === HDomains.BulkActionSlug.DOMAIN_FORWARD_DELETE,
    },
  ],
});

export const getDomainAutoRenewModalDetails = (
  selectedActionSlug: HDomains.BulkActionSlug | '',
) => {
  const isEnableAutoRenewAction =
    selectedActionSlug === HDomains.BulkActionSlug.DOMAIN_AUTO_RENEW_ENABLE;
  const isDisableAutoRenewAction =
    selectedActionSlug === HDomains.BulkActionSlug.DOMAIN_AUTO_RENEW_DISABLE;

  return {
    primaryTitle: 'Manage Auto-renewal',
    secondaryTitle: 'Confirm Auto-renewal',
    subtitle:
      'Choose if you want to turn on or turn off Auto-renewal for your domains. Enabling auto-renewal will keep your domains active.',
    options: [
      {
        id: HDomains.BulkActionSlug.DOMAIN_AUTO_RENEW_ENABLE,
        label: 'Turn on auto-renewal',
        isActive: isEnableAutoRenewAction || !selectedActionSlug,
      },
      {
        id: HDomains.BulkActionSlug.DOMAIN_AUTO_RENEW_DISABLE,
        label: 'Turn off auto-renewal',
        isActive: isDisableAutoRenewAction,
      },
    ],
    infoText:
      'Turning off auto-renewal increases your chances of losing the domains',
    confirmInfoText: isEnableAutoRenewAction
      ? `You will be charged for all selected domains close to domain's expiration date`
      : '',
  };
};

export const getPrivacyProtectionModalDetails = (
  selectedActionSlug: HDomains.BulkActionSlug | '',
) => ({
  primaryTitle: 'Manage Privacy Protection',
  secondaryTitle: 'Confirm Privacy Protection',
  subtitle:
    'Choose if you want to turn on or turn off privacy protection for your domain. Enabling privacy protection will hide your personal contact details online.',
  options: [
    {
      id: HDomains.BulkActionSlug.DOMAIN_PRIVACY_PROTECTION_ENABLE,
      label: 'Turn on privacy protection',
      isActive:
        selectedActionSlug ===
          HDomains.BulkActionSlug.DOMAIN_PRIVACY_PROTECTION_ENABLE ||
        !selectedActionSlug,
    },
    {
      id: HDomains.BulkActionSlug.DOMAIN_PRIVACY_PROTECTION_DISABLE,
      label: 'Turn off privacy protection',
      isActive:
        selectedActionSlug ===
        HDomains.BulkActionSlug.DOMAIN_PRIVACY_PROTECTION_DISABLE,
    },
  ],
});

export const getChangeNameserversModalDetails = (
  selectedActionSlug: HDomains.BulkActionSlug | '',
  selectedActionInputValues: IDomainBulkActionInputValues,
) => ({
  primaryTitle: 'Change Nameservers',
  secondaryTitle: 'Confirm Nameservers Change',
  subtitle:
    'Choose if you want to set default Hostinger nameservers or enter different ones',
  options: [
    {
      id: HDomains.BulkActionSlug.DOMAIN_NAMESERVER_UPDATE_DEFAULT,
      label: 'Use Hostinger nameservers (recommended)',
      isActive:
        selectedActionSlug ===
          HDomains.BulkActionSlug.DOMAIN_NAMESERVER_UPDATE_DEFAULT ||
        !selectedActionSlug,
    },
    {
      id: HDomains.BulkActionSlug.DOMAIN_NAMESERVER_UPDATE_CUSTOM,
      label: 'Use different nameservers',
      isActive:
        selectedActionSlug ===
        HDomains.BulkActionSlug.DOMAIN_NAMESERVER_UPDATE_CUSTOM,
    },
  ],
  textOptions: NAMESERVERS.map((nameserver) => ({
    type: FieldType.text,
    label: nameserver.label,
    name: nameserver.name,
    value:
      selectedActionInputValues[
        nameserver.name as keyof typeof selectedActionInputValues
      ] ?? '',
    validation: {
      required: nameserver.required,
      nameserver: true,
    },
    custom: {
      hideOptionalLabel: true,
    },
  })),
});

export const getModalDetails = (
  bulkActionType: HDomains.BulkActionType | '',
  selectedActionSlug: HDomains.BulkActionSlug | '',
  selectedActionInputValues: IDomainBulkActionInputValues,
): ModalDetails => {
  if (bulkActionType === HDomains.BulkActionType.DOMAIN_LOCK) {
    return getDomainLockModalDetails(selectedActionSlug);
  }

  if (bulkActionType === HDomains.BulkActionType.PRIVACY_PROTECTION) {
    return getPrivacyProtectionModalDetails(selectedActionSlug);
  }

  if (bulkActionType === HDomains.BulkActionType.DOMAIN_FORWARDING) {
    return getDomainForwardingModalDetails(selectedActionSlug);
  }

  if (bulkActionType === HDomains.BulkActionType.AUTO_RENEW) {
    return getDomainAutoRenewModalDetails(selectedActionSlug);
  }

  return getChangeNameserversModalDetails(
    selectedActionSlug,
    selectedActionInputValues,
  );
};
