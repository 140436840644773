import type { FormValues } from '@hostinger/hcomponents';

import type { HDomains, HDomainResource } from '@/types';

export type RedirectType = '302' | '301';

export interface IForwardedDomain {
  domain: string;
  redirectType: RedirectType;
  redirectUrl: string;
}
export interface IHDomain {
  registrar: HDomains.Registrar;
  domain: string;
  status: string;
  message: string;
  tld: string;
  tldSettings: IHDomainTLDSettings;
  expandedDetails: IHDomainExpandedDetails;
  relatedDomainId: number;
  defaultNameServers: string[];
  '60_days_lock_expires_at': string;
  adminWhoisProfile: IHDomainWhoisProfile;
  ownerWhoisProfile: IHDomainWhoisProfile;
  billingWhoisProfile: IHDomainWhoisProfile;
  techWhoisProfile: IHDomainWhoisProfile;
  expiresAt: string;
  registeredAt: string;
  updatedAt: string;
  createdAt: string;
  forwardingDetails: Record<string, any> | null;
}

export const MESSAGE_SLUG = {
  TRANSFERRING: 'transferring',
  WAITING_FOR_APPROVAL: 'waiting_for_approval',
  UPDATE_IPS_TAG: 'update_ips_tag',
  VERIFY_EMAIL: 'verify_email',
  UPLOAD_DOCUMENT: 'upload_document',
  TRANSFER_UNSUCCESSFUL: 'transfer_unsuccessful',
  REJECTED: 'rejected',
  REGISTRO_BR_PROVIDER_AND_ORGANIZATION_MISMATCH:
    'registro_br_provider_and_organization_mismatch',
  REGISTRO_BR_ORGANIZATION_MISMATCH: 'registro_br_organization_mismatch',
  FAILED: 'failed',
  REGISTRO_BR_PROVIDER_MISMATCH: 'registro_br_provider_mismatch',
  NOT_AVAILABLE_AT_DREAMSCAPE: 'not_available_at_dreamscape',
  REJECTED_BY_REGISTRY: 'rejected_by_registry',
  EMPTY_NAMESERVERS: 'empty_nameservers',
  INVALID_ATTRIBUTE_VALUE: 'invalid_attribute_value',
  SPECIFYING_CONTACTS_NOT_ALLOWED: 'specifying_contacts_not_allowed',
  OBJECT_STATUS_PROHIBITS_OPERATION: 'object_status_prohibits_operation',
  WHOIS_FETCH_SUCCESSFUL: 'whois_fetch_successful',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  PROCESS_TIMED_OUT: 'process_timed_out',
  _60_DAYS_LOCK: '60_days_lock',
  AUTH_CODE_INCORRECT: 'auth_code_incorrect',
} as const;

export type MessageSlug = (typeof MESSAGE_SLUG)[keyof typeof MESSAGE_SLUG];
export interface IHDomainTransfer {
  customerCustomId: string;
  userRegistrar: string;
  domain: string;
  status: DomainTransferInfoStatus;
  message: string;
  adminWhoisId: number;
  ownerWhoisId: number;
  billingWhoisId: number;
  techWhoisId: number;
  additionalDetails: Record<string, any>;
  initiatedAt: string;
  completedAt: string | null;
  updatedAt: string;
  createdAt: string;
  isRetryable: boolean;
  messageSlug: MessageSlug;
}

export interface IHDomainWhoisProfile {
  id: number;
  tld: string;
  entityType: string;
  customerCustomId: string;
  country: string;
  whoisDetails: Record<string, IHDomainWhoisDetails>;
  tldDetails: IHDomainTLDDetails;
  updatedAt: string;
  createdAt: string;
}

export interface IHDomainTLDDetails {
  id: number;
  title: string;
  slug: string;
  constraints: string;
  regularExpression: string;
  type: string;
  possibleValues: string;
  prefill: string;
  example: string;
}

export interface IHDomainWhoisDetails {
  title: string;
  value: string;
  slug: string;
}

export interface IHDomainExpandedDetails {
  id: number;
  customerCustomId: string;
  userRegistrarId: number;
  domain: string;
  status: string;
  registrantEmailVerificationStatus: string;
  ns: string[];
  childNs: string[];
  dnsSec: IHDomainsDNSSECDetails[];
  domainSecret: string;
  isPrivacyProtectAllowed: boolean;
  isPrivacyProtected: boolean;
  isLocked: boolean;
  isLockable: boolean;
  adminContactId: number;
  ownerContactId: string;
  billingContactId: number;
  techContactId: number;
  expiresAt: string;
  registeredAt: string;
  updatedAt: string;
  createdAt: string;
  isPrivacyProtectionAllowed: boolean;
}

export interface IHDomainTLDSettings {
  id: number;
  registrar: string;
  tld: string;
  allowedCountries: string;
  isDnssecSupported: boolean;
  isIdnSupported: boolean;
  isPrivacyProtectSupported: boolean;
  isLockSupported: boolean;
  isResetSecretSupported: boolean;
  isModifyOwnerSupported: boolean;
  isPremiumDomainsSupported: boolean;
  maxRegistrationPeriod: number;
  quarantinePeriod: number;
  isEmailVerificationRequired: boolean;
  isLocalActivityRequired: boolean;
  isLocalPresenceRequired: boolean;
  localPresenceCountry: string;
  isCorrectNameServerRequired: boolean;
  minimumDomainLength: number;
  maximumDomainLength: number;
  isChildNameServerSupported: boolean;
  isTransferable: boolean;
  transferPeriod: number;
  is_60_day_transfer_lock: boolean;
  isEppSupported: boolean;
  isGtld: boolean;
  isMovable: boolean;
  isNsUpdateSupported: boolean;
  minRegistrationPeriod: number;
  renewalDateOffset: number;
  renewalDaysBeforeExpiration: number;
  gracePeriodDays: number;
  redemptionPeriodDays: number;
  isAdminContactSupported: boolean;
  adminEntityType: string;
  isAdminContactUpdateSupported: boolean;
  isOwnerContactSupported: boolean;
  ownerEntityType: string;
  isOwnerContactUpdateSupported: boolean;
  isBillingContactSupported: boolean;
  billingEntityType: string;
  isBillingContactUpdateSupported: boolean;
  isTechContactSupported: boolean;
  techEntityType: string;
  isTechContactUpdateSupported: boolean;
}

export interface IHDomainsRegistrationDomainContacts {
  owner?: number;
  admin?: number;
  billing?: number;
  tech?: number;
}

export interface IHDomainRegistrationData {
  tld: string;
  domain: string;
  domainContacts: IHDomainsRegistrationDomainContacts;
  additionalDetails: Record<string, any>;
  keepDns?: boolean;
  keepNs?: boolean;
}

export interface IHDomainsDNSSECDetails {
  dnsKeyTagOpenProvider: string;
  dnsAlgorithmOpenProvider: string;
  dnsDigestTypeOpenProvider: string;
  dnsDigestOpenProvider: string;
}

export interface ContactDetailsFormProps {
  isLoading: boolean;
  country: string;
  modelValue: WhoIsDetails;
  whoIsFields: IWhoIsField[];
  tld: string;
}

export interface WhoIsDetails {
  firstName?: string;
  lastName?: string;
  email?: string;
  city?: string;
  address?: string;
  phoneNumber?: string;
  phoneCc?: string;
  companyName?: string;
  stateLt?: string;
  zipLt?: string;
  apartmentLt?: string;
  addressLt?: string;
  countryCode?: string;
  country?: string;
}

export interface IWhoIsField {
  id: number;
  title: string;
  slug: string;
  constraints: string;
  regularExpression: string;
  type: string;
  possibleValues: any;
  prefill: string | null;
  example: string | null | number;
}

export interface WhoIsSelectOption {
  value: string;
  label: string;
  selected?: boolean;
}

export interface WhoIsFormSchema {
  prio?: number;
  label: string;
  name: string;
  value?: string | null;
  values: any[] | null;
  custom: {
    hideOptionalLabel: boolean;
    itemsPerList?: number;
    isItemsPerListStrict?: boolean;
  };
  prepend?: WhoIsSelectOption[];
  hint?: string;
  prefix?: string | null;
  getSplitValue?: boolean;
  disabled?: boolean;
  tooltip?: string;
  constraints?: string;
  validation: any;
}

interface FieldSplitValue {
  prepend: string;
  prefix: string;
  value: string;
  append: string;
}

export interface WhoIsInputEvent {
  value?: string;
  values?: WhoIsDetails & { phoneNumber: FieldSplitValue };
  isInitialLoad?: boolean;
  isFormValid: boolean;
}

export interface IHDomainSuggestedAddress {
  postCode: string;
  address: string;
  street: string;
  number: string;
  onlyNumber: string;
  housing: string;
  municipality: string;
  city: string;
  post: string;
  mailbox: string;
}

export interface IHDomainResourceAdditionalDetails {
  hresourceId?: string;
  registeredAt?: string | number;
  isGracePeriod?: boolean;
  isPrivacyProtected?: boolean;
  gracePeriodEndDate?: string | null;
  isRedemptionPeriod?: boolean;
  redemptionPeriodEndDate?: string | null;
  allowedTlds?: string[];
  isMovable: boolean;
  initiatedAt?: string;
  message?: string | null;
  isRestoring?: boolean | null;
}

export interface IHDomainResource {
  id: number;
  customerCustomId: string;
  resourceType: HDomainResource.ResourceType;
  status: HDomainResource.Status;
  title: string;
  expiresAt: string;
  additionalDetails?: IHDomainResourceAdditionalDetails;
  updatedAt: string;
  createdAt: string;
}

export interface ITransferIn {
  subscriptionId?: string;
  domain: string;
  initiatedOn: string;
  status: HDomainResource.Status;
}

export interface IHDomainActionValidation {
  processing: string[];
  skipped: string[];
  unprocessable: string[];
}

export interface IHDomainAction {
  userId?: number;
  customerCustomId: string;
  action: string;
  domain: string;
  status: HDomains.BulkActionStatus;
  updatedAt: string;
  createdAt: string;
  id: number;
  message?: string | null;
  attempts?: number;
}

export interface IHDomainMoveData {
  domain: string;
  status: string;
  clientData: {
    email: string;
  };
  createdAt: string;
  updatedAt: string;
}

export type WhoIsEntityType = 'individual' | 'organization';

export interface WhoIsPrimaryDetails {
  country: string;
  entityType: WhoIsEntityType;
  whoisProfile: number | null;
}

export interface WhoIsDetail {
  title: string;
  value: string;
  slug: string;
}

export interface ProfileWhoIsDetails {
  firstName: WhoIsDetail;
  lastName: WhoIsDetail;
  email: WhoIsDetail;
  companyName: WhoIsDetail;
  address: WhoIsDetail;
  city: WhoIsDetail;
  [key: `state${string}`]: WhoIsDetail;
  countryCode: WhoIsDetail;
  [key: `zip${string}`]: WhoIsDetail;
  phoneCc: WhoIsDetail;
  phoneNumber: WhoIsDetail;
}

export interface WhoIsProfile {
  id: number;
  tld: string;
  entityType: WhoIsEntityType;
  customerCustomId: string;
  country: string;
  whoisDetails: ProfileWhoIsDetails;
  tldDetails:
    | {
        [key: string]: WhoIsDetail;
      }
    | [];
  updatedAt: string;
  createdAt: string;
}

export interface WhoIsRegistrationErrors {
  vatPersonBr?: string;
  vatCompanyBr?: string;
}

export interface AdditionalModel {
  [key: string]: string;
}

export interface IOneClickSubmitData {
  details: WhoIsDetails;
  keepDnsOption: HDomains.DomainTransferDnsOption;
}

export interface IAiDomainAlternatives {
  generated: string[];
  originalTldReplaced?: string[];
  tldReplaced: string[];
}

export interface IDomainCheckerListItem {
  domain: string;
  restriction?: string | null;
  onOfferChosen?: () => void;
  onOfferShown?: () => void;
}

export interface IDomainAvailability {
  domain: string;
  available: number;
}

export interface IDomainLookupDetails {
  registrar: string;
  nameservers: string[];
  isLocked: boolean;
  is60DaysLock: boolean;
  expiresAt: string;
  expiresAtAfterTransfer: string;
  statuses: string[];
}

export interface IDomainForwardingValues {
  formValues: FormValues;
  redirectType: RedirectType;
}

export interface IDomainBulkActionInputValues {
  redirectType?: RedirectType;
  redirectUrl?: string;
  nameserver1?: string;
  nameserver2?: string;
  nameserver3?: string;
  nameserver4?: string;
}

export type IDomainBulkActionPayload =
  | {
      redirectType: RedirectType;
      redirectUrl: string;
    }
  | { domain: string; subscriptionId: string }[]
  | string[];

export interface IDomainBulkAction {
  domains: string[];
  action: string | HDomains.BulkActionSlug;
  payload?: IDomainBulkActionPayload;
}
export interface IDomainData {
  domain: string;
  message: string;
  isLocked: boolean;
  isLockable: boolean;
  isPrivacyProtected: boolean;
  domainSecret: string;
  isPrivacyProtectionAllowed: boolean;
  ownerContactId: string;
  ns: string[];
  childNs: Record<string, any> | [];
  dnsSec: IHDomainsDNSSECDetails[];
  forwardingDetails: Record<string, any> | null;
  defaultNameServers: string[];
  techWhoisProfile: any;
  ownerWhoisProfile: any;
  billingWhoisProfile: any;
  adminWhoisProfile: any;
  status: string;
  expiresAt: string;
  updatedAt: string;
  registeredAt: string;
  tld: string;
  registrar: HDomains.Registrar;
  isVerified: string;
  tldSettings: any;
  isOutdated: boolean;
}

export interface IDnsSecRequirement {
  id: number;
  title: string;
  slug: string;
  constraints: string;
  regularExpression: string;
  type: string;
  possibleValues: Record<string, string>;
  prefill: string | null;
  example: string | null;
}

export interface IDnsSecRequirements {
  [key: string]: IDnsSecRequirement;
}

export interface IEmailStatusState {
  domain: string;
  timeStatusChecked: number | null;
  timeCheckStatusClicked: number | null;
  timeBannerActivated: number | null;
  bannerTimePeriod: number | null;
  isBannerActivated: boolean;
}
export interface IDomainRenewInformation {
  domain: string;
  expiresAt: string;
  gracePeriodEndDate: string;
  isGracePeriod: boolean;
  isRedemptionPeriod: boolean;
  isRenewable: {
    isRenewable: boolean;
    reason: string;
  };
  isRestorable: boolean;
  isRestoring: boolean;
  redemptionPeriodEndDate: string;
}

export interface IAllEndings {
  listPaginated: string[][];
  listAvailable: string[];
  page: number;
  perPage: number;
}

export interface IDomainAvailabilityDetails {
  domain: string;
  isAvailable: boolean;
  restriction: string | null;
}

export const TLD_PURCHASE_PERIODS = {
  YEAR_1: 'year1',
  YEAR_2: 'year2',
} as const;

export type TldPurchasePeriodType =
  (typeof TLD_PURCHASE_PERIODS)[keyof typeof TLD_PURCHASE_PERIODS];

export interface IDomainRegistrationRequirement {
  id: number;
  title: string;
  slug: string;
  constraints: string;
  regularExpression: string;
  type: string;
  possibleValues: Record<string, string> | null;
  prefill: string | null;
  example: string | null;
}

export const HDOMAINS_ACTION_STATUS = {
  QUEUED: 'Queued',
  FAILED: 'Failed',
  RETRYING: 'Retrying',
  EXECUTING: 'Executing',
  IN_PROGRESS: 'In progress',
  FINISHED: 'Finished',
  PENDING: 'Pending',
} as const;

export type HDomainsActionStatus =
  (typeof HDOMAINS_ACTION_STATUS)[keyof typeof HDOMAINS_ACTION_STATUS];

export interface IHDomainsAction {
  id: number;
  action: string;
  domain: string;
  status: HDomainsActionStatus;
  message: string;
  attempts: number;
}
export interface ITldCategory {
  type: TldCategoryType;
  tlds: string[];
  title: string;
}

export interface IDomainPurchaseData {
  domain: string;
  available: number;
  restriction: string | null;
}

export const TLD_CATEGORY = {
  POPULAR: 'popular',
  BUSINESS: 'business',
  INTERNATIONAL: 'international',
  EDUCATION: 'education',
  MEDIA_AND_ENTERTAINMENT: 'media_entertainment',
  TECHNOLOGY: 'technology',
  SOCIAL_AND_PERSONAL: 'social_personal',
  PROFESSIONAL_AND_SERVICES: 'professional_services',
  MISCELLANEOUS: 'miscellaneous',
  ALL: 'all',
} as const;

export type TldCategoryType = (typeof TLD_CATEGORY)[keyof typeof TLD_CATEGORY];

export const DOMAIN_TRANSFER_INFO_STATUS = {
  COMPLETED: 'Completed',
  TRANSFERRING: 'Transferring',
  CANCELLED: 'Cancelled',
  ERROR: 'Error',
} as const;

export type DomainTransferInfoStatus =
  (typeof DOMAIN_TRANSFER_INFO_STATUS)[keyof typeof DOMAIN_TRANSFER_INFO_STATUS];

export type HVerticalStepperItem = {
  title: string;
  isCurrent: boolean;
  type: 'completed' | 'failed' | 'disabled' | 'default';
};

export const TRANSFER_OVERVIEW_BANNER_ACTION_TYPE = {
  BUTTON: 'HButton',
  LINK: 'HHyperlink',
  EPP_INPUT: 'EppInput',
} as const;

export type TransferOverviewBannerActionType =
  (typeof TRANSFER_OVERVIEW_BANNER_ACTION_TYPE)[keyof typeof TRANSFER_OVERVIEW_BANNER_ACTION_TYPE];

export type ValidateRegistrationReturnType<T extends boolean | undefined> =
  T extends true
    ? { domain: string; isAvailable: boolean; isSuspicious: boolean }
    : { [key: string]: number };
