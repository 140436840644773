export enum Sale {
  MONSOON_SALE = 'monsoon-sale',
  INDEPENDENCE_DAY = 'independence-day',
  SUMMER_SALE = 'summer-sale',
  SEPTEMBER_SALE = 'september-sale',
  DEFAULT_POST_SALE = 'default-post-sale',
  BLACK_FRIDAY = 'black-friday',
  CYBER_WEEK = 'cyber-week',
  NEW_YEAR = 'new-year',
}

export enum SaleTheme {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum BlackFridaySale {
  VPS_SALE = 'vps-sale',
  VPS_KVM_SALE = 'vps-kvm-sale',
  HOSTING_PURCHASE_SALE = 'hosting-purchase-sale',
  HOSTING_UPGRADE_SALE = 'hosting-upgrade-sale',
}

export const NewYearSaleOffer = {
  HOSTING_PURCHASE_SALE: 'hosting-purchase-sale',
  HOSTING_UPGRADE_SALE: 'hosting-upgrade-sale',
  VPS_KVM_SALE: 'vps-kvm-sale',
} as const;

export type NewYearSaleOfferKey =
  (typeof NewYearSaleOffer)[keyof typeof NewYearSaleOffer];
