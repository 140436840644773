export const DATA_CENTER_NAMES = {
  BALTNETA: 'baltneta',
  UKFAST: 'ukfast',
  ASCENTY: 'ascenty',
  INTERXION: 'interxion',
  NETRIPLEX: 'netriplex',
  BOSTON: 'boston',
  MUMBAI: 'mumbai',
  NEW_MEDIA_EXPRESS: 'newmediaexpress',
  PHOENIX: 'phoenix',
  SERVERIUS: 'serverius',
  IMMEDION: 'immedion',
  DIGITAL_REALITY: 'digitalreality',
} as const;

export type DataCenterName =
  (typeof DATA_CENTER_NAMES)[keyof typeof DATA_CENTER_NAMES];

export type DataCenterMap = {
  [key in DataCenterName]: string;
};

export type DataCenterCoordinatesMap = {
  [key in DataCenterName]: string;
};

export type SuggestedDataCenterMap = {
  [key in DataCenterName]: string;
};

export type PingedDataCenter = {
  datacenter: DataCenterName;
  latency: number;
  error: boolean;
};
