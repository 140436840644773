import type { RouteRecordRaw } from 'vue-router';

import { Route } from '@/types';

const sideMenu = {
  sideMenuExists: true,
  sideMenuComponent: 'SectionSideMenu',
};

export default [
  {
    path: 'hosting-security',
    name: Route.HostingSecurity.HOSTING_SECURITY,
    meta: {
      title: 'Security',
      breadcrumbs: [Route.Base.WEBSITES, Route.Websites.WEBSITE_DASHBOARD],
      ...sideMenu,
    },
    redirect: { name: Route.Websites.WEBSITE_DASHBOARD, hash: '#security' },
    component: () => import('@/views/Hosting/DashboardSection.vue'),
    children: [
      {
        path: 'malware-scanner',
        name: Route.HostingSecurity.MALWARE_SCANNER,
        meta: {
          title: 'Malware Scanner',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingSecurity.HOSTING_SECURITY,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Security/MalwareScanner.vue'),
      },
      {
        path: 'ssl',
        name: Route.HostingSecurity.SSL,
        meta: {
          title: 'SSL certificate',
          breadcrumbs: [
            Route.Base.WEBSITES,
            Route.Websites.WEBSITE_DASHBOARD,
            Route.HostingSecurity.HOSTING_SECURITY,
          ],
          ...sideMenu,
        },
        component: () => import('@/views/Hosting/Security/SSL/Ssl.vue'),
      },
    ],
  },
] as RouteRecordRaw[];
