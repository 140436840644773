import type {
  ProfileInformationValues,
  ResolveUSAddressResponse,
  GDPROrder,
  DeleteProfileReqParams,
  ChangePasswordReqParams,
  ChangeTmpPasswordReqParams,
  Order,
  SubmitGrantAccessParams,
  SubmitRequestAccessParams,
  PostIntercomMessageParams,
  TwoFaMethod,
  ReminderUpdateRequest,
  IProfileActivity,
  IProfile,
  IProfilePatchRequest,
  IProfileEmailPreferencesRequest,
  IProfileReminderPreferencesRequest,
  IHasJWTToken,
  RequestConfig,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_SSO}/v1/profile`,
  urlV2: `${process.env.VITE_API_SSO}/v2/profile`,
  urlV3: `${process.env.VITE_API_H_SSO}/v1/profile`,

  async getIsIntercomChatEnabled() {
    return await hAsync<boolean>(
      http.get(`${this.url}/intercom/help/live-chat`),
    );
  },
  async getProfileOrderByDomain(domain: string, type: string) {
    return await hAsync<Order>(
      http.get(`${this.url}/client/domain-order/${domain}`, {
        hideToastr: true,
        params: { type },
      }),
    );
  },
  async updateClientProfile(newInfo: ProfileInformationValues) {
    return await hAsync<boolean>(http.post(`${this.url}/client`, newInfo));
  },
  async downloadProfileInfo() {
    return await hAsync<boolean>(http.post(`${this.url}/gdpr/download`));
  },
  async getProfileOrders() {
    return await hAsync<GDPROrder[]>(http.get(`${this.url}/gdpr/orders`));
  },
  async deleteProfile(data: DeleteProfileReqParams) {
    return await hAsync<boolean>(http.delete(`${this.url}/gdpr`, { data }));
  },
  async deleteProfileHSSO() {
    return await hAsync<boolean>(
      http.patch(`${process.env.VITE_API_H_SSO}/v1/gdpr-delete/initiate`),
    );
  },
  async changeProfilePassword(request: ChangePasswordReqParams) {
    return await hAsync<boolean>(
      http.post(`${this.url}/client/change-password`, request),
    );
  },
  async changeTempProfilePassword(request: ChangeTmpPasswordReqParams) {
    return await hAsync<boolean>(
      http.post(`${this.url}/client/change-tmp-password`, request, {
        withTokenUpdate: true,
      }),
    );
  },
  async getClientAnalytics() {
    return await hAsync(
      http.get(`${this.url}/client/data-layer`, {
        hideNetworkError: true,
      }),
    );
  },
  async acknowledgeClientAnalytics(dataLayerId: string) {
    return await hAsync(
      http.post(`${this.url}/client/data-layer`, { dataLayerId }),
    );
  },
  async resendEmailConfirmation() {
    return await hAsync<boolean>(
      http.post(`${this.url}/client/resend-confirmation`),
    );
  },
  async seenUpsellOffer(offerName: string) {
    return await hAsync(
      http.post(`${this.url}/client/upsell-offer/seen`, { offerName }),
    );
  },
  async clickedUpsellOffer(offerName: string) {
    return await hAsync(
      http.post(`${this.url}/client/upsell-offer/clicked`, { offerName }),
    );
  },
  async getProfileAccess() {
    return await hAsync(http.get(`${this.url}/client/account-access`));
  },

  async submitGrantAccessForm(params: SubmitGrantAccessParams) {
    return await hAsync<IHasJWTToken>(
      http.post(`${this.url}/client/account-access/grant`, params, {
        withTokenUpdate: true,
      }),
    );
  },

  async submitRequestAccessForm(params: SubmitRequestAccessParams) {
    return await hAsync<boolean>(
      http.post(`${this.url}/client/account-access/request`, params, {
        hideToastr: true,
      }),
    );
  },

  async removeAccess(id: number) {
    return await hAsync<IHasJWTToken>(
      http.delete(`${this.url}/client/account-access/${id}`, {
        withTokenUpdate: true,
      }),
    );
  },

  async twoFactorDisable(code: string) {
    return await hAsync<boolean>(
      http.delete(`${this.urlV2}/client/two-factor`, {
        params: { ['2fa_code_confirm']: code },
        hideToastr: true,
        withTokenUpdate: true,
      }),
    );
  },

  async twoFactorClose() {
    return await hAsync<boolean>(
      http.delete(`${this.url}/client/two-factor/backup-codes/banner/close`),
    );
  },

  async confirmTwoFaSetup(params: { code: string }) {
    return await hAsync<boolean>(
      http.post(`${this.urlV2}/client/two-factor/confirm-google-setup`, params),
    );
  },

  async twoFactorVerify(data: { code: string; '2fa_method': TwoFaMethod }) {
    return await hAsync<boolean>(
      http.post(`${this.urlV2}/client/two-factor/verify`, data, {
        withTokenUpdate: true,
      }),
    );
  },

  async twoFactorEnable(data: { '2fa_method': TwoFaMethod }) {
    return await hAsync<boolean>(
      http.post(`${this.urlV2}/client/two-factor`, data, {
        withTokenUpdate: true,
      }),
    );
  },

  async twoFactorGet() {
    return await hAsync<{ method: string; backup: boolean }>(
      http.get(`${this.url}/client/two-factor`),
    );
  },

  async twoFactorBackupCodes(code: string) {
    const params = code ? { '2fa_code_confirm': code } : {};

    return await hAsync<string[]>(
      http.post(`${this.url}/client/two-factor/backup-codes`, params),
    );
  },

  async twoFactorVerifyBackup(code: string) {
    const removeCodeSpaces = code.replace(/\s/g, '');

    return await hAsync<{ isValid: boolean; attemptsLeft: boolean }>(
      http.post(
        `${this.url}/client/two-factor/backup-codes/verify`,
        {
          code: removeCodeSpaces,
        },
        { withTokenUpdate: true },
      ),
    );
  },

  async twoFactorSendEmail(method: TwoFaMethod) {
    return await hAsync<boolean>(
      http.post(`${this.url}/client/two-factor/send-code`, {
        '2fa_method': method,
      }),
    );
  },

  async getLiveChatAvailable(params: { teamLabel: string }) {
    return await hAsync<boolean>(
      http.get(`${this.url}/intercom/help/team/live-chat-enabled`, { params }),
    );
  },

  getProfileActivity() {
    return hAsync<IProfileActivity[]>(http.get(`${this.urlV3}/activity`));
  },

  getProfile() {
    return hAsync<IProfile>(http.get(this.urlV3));
  },

  patchProfileData(data: IProfilePatchRequest, config?: RequestConfig) {
    return hAsync(
      http.patch(`${this.urlV3}/data`, data, {
        whitelistedStatusCodes: [400],
        ...config,
      }),
    );
  },

  patchEmailPreferences(data: IProfileEmailPreferencesRequest) {
    return hAsync(http.patch(`${this.urlV3}/email-preferences`, data));
  },

  patchReminderPreferences(data: IProfileReminderPreferencesRequest) {
    return hAsync(http.patch(`${this.urlV3}/reminder-preferences`, data));
  },

  async getIntercomDropdownOptions(params: { topic: string }) {
    return await hAsync<string[]>(
      http.get(`${this.url}/intercom/help/topic/options`, { params }),
    );
  },

  async postIntercomMessage(params: PostIntercomMessageParams) {
    return await hAsync<boolean>(
      http.post(`${this.url}/intercom/help/send-message`, params),
    );
  },

  updateReminderSettings(request: ReminderUpdateRequest) {
    return hAsync<ResolveUSAddressResponse>(
      http.post(`${this.url}/client/update-reminder-settings`, request),
    );
  },
};
