import { defineStore } from 'pinia';
import { ref } from 'vue';

import { hDomainsRepo } from '@/repositories';
import type { IHDomainMoveData } from '@/types';
import { STORE_PERSISTENT_KEYS } from '@/types';

export const useDomainMoveStore = defineStore(
  'domainMoveStore',
  () => {
    const initiatedDomainMoveData = ref<Record<string, IHDomainMoveData>>({});
    const incomingDomainMoves = ref<IHDomainMoveData[]>([]);

    const setInitiatedDomainMoveData = (
      domain: string,
      data: IHDomainMoveData,
    ) => {
      initiatedDomainMoveData.value[domain] = data;
    };

    const setIncomingDomainMoves = (data: IHDomainMoveData[]) => {
      incomingDomainMoves.value = data;
    };

    const setIncomingDomainMoveStatus = (domain: string, status: string) => {
      const domainMove = incomingDomainMoves.value.find(
        (item) => item.domain === domain,
      );

      if (domainMove) {
        domainMove.status = status;
      }
    };

    const getInitiatedDomainMoveData = (domain: string) =>
      initiatedDomainMoveData.value[domain];

    const fetchInitiatedDomainMoveData = async (domain: string) => {
      const [{ data }, err] = await hDomainsRepo.getInitiatedDomainMove(domain);

      if (err) {
        return;
      }

      setInitiatedDomainMoveData(domain, data);
    };

    const fetchIncomingDomainMoves = async () => {
      const [{ data }, err] = await hDomainsRepo.getAllIncomingDomainMoves();

      if (err) {
        return;
      }

      setIncomingDomainMoves(data);
    };

    return {
      incomingDomainMoves,
      setIncomingDomainMoveStatus,
      getInitiatedDomainMoveData,
      fetchInitiatedDomainMoveData,
      fetchIncomingDomainMoves,
      setIncomingDomainMoves,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.DOMAIN_MOVE },
  },
);
