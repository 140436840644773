<script setup lang="ts">
import type { FormValues } from '@hostinger/hcomponents';
import { computed, ref } from 'vue';

import { useGlobals, useModal } from '@/composables';
import { authRepo } from '@/repositories';
import { useProfileStore, useTwoFactorStore } from '@/stores';

type Props = {
  data: {
    onSuccess?: () => void;
    isOnSuccessOpenOtherModal?: boolean;
    email: string;
  };
};

const props = defineProps<Props>();

const { closeModal, openModal } = useModal();
const { t, toastr } = useGlobals();
const profileStore = useProfileStore();
const twoFactorStore = useTwoFactorStore();
const isLoading = ref(false);
const formValues = ref<Record<string, string>>({});

const formSchema = computed(() => ({
  email: {
    type: 'text',
    label: 'Email',
    value: props.data.email,
    disabled: true,
  },
  newPassword: {
    type: 'password',
    label: 'Enter new password',
    password: {
      tags: true,
      tagsAlwaysVisible: true,
    },
    validation: {
      required: true,
      passwordNoSpaces: true,
      simplePassword: true,
    },
  },
  newPasswordConfirmation: {
    type: 'password',
    label: 'Enter new password again',
    value: formValues.value.newPasswordConfirmation,
    password: {
      confirmedPassword: formValues.value.newPassword,
    },
    validation: {
      required: true,
    },
  },
}));

const handleSubmit = async ({ values, isFormValid }: FormValues) => {
  if (!isFormValid) return;

  if (profileStore.hasTwoFactorAuth) {
    return twoFactorStore.openVerifyPasswordModal({
      submitData: values,
      customBack: async () => {
        openModal({
          component: {
            RequiredPasswordChangeModalV2: (
              await import(
                '@/components/Modals/HModal/RequiredPasswordChangeModalV2.vue'
              )
            ).default,
          },
        });
      },
    });
  }

  isLoading.value = true;
  const [, err] = await authRepo.patchPassword(values);
  await profileStore.fetchProfile();
  isLoading.value = false;

  if (err) {
    return;
  }

  toastr.s(t('Account Password was changed successfully'));

  if (props.data.isOnSuccessOpenOtherModal) {
    closeModal();
    props.data.onSuccess?.();

    return;
  }

  props.data.onSuccess?.();

  closeModal();
};

const handleChange = ({ values }: { values: Record<string, string> }) => {
  formValues.value = { ...formValues.value, ...values };
};
</script>

<template>
  <div>
    <h2 v-trans>Set New Password</h2>
    <p v-trans class="h-pb-16">
      To ensure maximum security for your account, set a new password for your
      Hostinger login
    </p>
    <HForm
      :loading="isLoading"
      @on-change="handleChange($event)"
      @on-submit="handleSubmit($event)"
    >
      <!-- @vue-ignore -->
      <HFormField
        v-for="(schema, key) in formSchema"
        :key="key"
        :name="key"
        :schema="(schema as any)"
      />

      <div class="d-flex justify-content-end h-pt-8">
        <HButton v-qa-generate h-form-submit :is-loading="isLoading">
          {{ t('Confirm') }}
        </HButton>
      </div>
    </HForm>
  </div>
</template>
