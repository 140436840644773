import type { FieldSchema } from '@hostinger/hcomponents';

import type { Email, HLabelTheme } from '@/types';

export interface DkimRecord {
  hostname: string;
  value: string;
  active: number;
  activeUntil: string | null;
  status: Email.DkimRecordStatus;
  verified: number;
}
export interface EmailMailbox {
  storageQuota: number;
  messageQuota: number;
  forwardQuota: number;
  aliasQuota: number;
  maxSentMessageSize: number;
  maxSentAttachmentSize: number;
  maxSentRecipientLimit: number;
  ratelimitInbound: string;
  ratelimitOutbound: string;
}
export interface EmailDetailsState {
  loaded: boolean;
  loading: boolean;
  data: {
    domain: {
      domain: string;
      planName: Email.Plan;
      planRevision: number;
      accountCount: number;
      forwardCount: number;
      forwardCopyCount: number;
      catchallCount: number;
      aliasCount: number;
      autoreplyCount: number;
      numberOfSeats: number;
      numberOfPaidSeats: number;
      status: string;
      statusReason: null;
      createdAt: string;
      updatedAt: string;
      showPremiumPlanNudge: number;
    };
    plan: {
      plan: Email.Plan;
      revision: string;
      title: string;
      domain: {
        forwardQuota: number;
        aliasQuota: number;
        mailboxQuota: number;
        catchallEnabled: number;
        imapEnabled: number;
        pop3Enabled: number;
      };
      mailbox: EmailMailbox;
    };
  };
}

export interface EmailFeature {
  available: boolean;
  text: string;
}

export interface IEmailLogsFilterSchema extends FieldSchema {
  name: string;
}

export const EMAIL_LOGS_STATUS = {
  ALL: '',
  SUCCESSFUL: 'Successful',
  FAILED: 'Failed',
  HAS_DELETIONS: 'HasDeletions',
} as const;

export type EmailLogsStatus =
  (typeof EMAIL_LOGS_STATUS)[keyof typeof EMAIL_LOGS_STATUS];

export const EMAIL_LOGS_TYPE = {
  INBOUND: 'Inbound',
  OUTBOUND: 'Outbound',
} as const;

export type EmailLogsType =
  (typeof EMAIL_LOGS_TYPE)[keyof typeof EMAIL_LOGS_TYPE];

export interface IEmailLogsFilters {
  status?: EmailLogsStatus;
  account?: string;
  protocol?: string;
  type?: EmailLogsType;
  fromDate?: string;
  toDate?: string;
  sender?: string;
  recipient?: string;
}

export interface IEmailLogsFilterRequestParams
  extends Omit<IEmailLogsFilters, 'status'> {
  status: Omit<IEmailLogsFilters['status'], 'HasDeletions'>;
  hasDeletions?: boolean;
}

export interface EmailAccessLog {
  account: string;
  domain: string;
  session: string;
  protocol: string;
  rip: string;
  timestamp: string;
  result: string;
  status: string;
  trashed: number;
  deleted: number;
  expunged: number;
  in: number;
  out: number;
  appName: string;
  loginTime: string;
  logoutTime: string;
  important: boolean;
}

export interface EmailActionLog {
  action: string;
  createdAt: string;
  actionContext: string;
  ipAddress: string;
  responseStatus: string;
  extra: { [key: string]: string };
  role: string;
}

export interface EmailDeliveryLog {
  addressTo: string;
  response: string;
  status: string;
  time: string;
  timestamp: string;
  relayEvent: string;
  spam: boolean;
  rcpt: string;
  nrcpt: string;
  from: string;
}

export interface EmailPlanPricing {
  discount: string;
  features: EmailFeature[];
  fullPrice: number;
  fullPriceWithTaxes: number;
  hostingplanId: string | null;
  isDiscounted: boolean;
  new: string;
  old: string;
  period: string;
  periodUnits: number;
  productId: Email.ProductId;
  productSlug: string;
  productTitle: string;
  taxes: number;
  trial: string;
}

export interface AutoReply {
  id: string;
  email: string;
  body: string;
  subject: string;
  startTime: string;
  endTime: string;
}

export interface EmailOrder {
  id: string;
  domain: string;
  domainResourceId: string;
  resellerOrderId: string;
  seats: number;
  storage: number;
  status: string;
  plan: Email.Plan;
  planTitle: string;
  showPremiumPlanNudge: boolean;
  pendingUpgrade: boolean;
  packageFeatures: EmailFeature[];
  createdAt: string;
  expiresAt: string;
}

export interface EmailListItem {
  id: string;
  title: string;
  service?: Email.Product;
  serviceName: Email.Product;
  status: Email.OrderStatus;
  emailPlan: string;
  serviceId: number | string;
  hresourceId: number | string;
  referenceId: number | string;
  orderId: string;
  ctaText: string;
  domain: string;
  domainRaw?: string;
  expiresAt: string;
  emailLimit: number;
  setupMessage: string;
  suspensionReason: string;
  titanChosen?: boolean | null;
  customerEmail: string;
  customerId: string;
  isOwnEmail: boolean;
  planKey: string;
}

export interface EmailAccount {
  account: string;
  resourceId: string;
  autoreplyCount: number;
  forwardCount: number;
  aliasCount: number;
  statusLogin: number;
  statusReceive: number;
  statusSend: number;
  statusMain: number;
  storageUsed: number;
  storageQuota: number;
  isCatchall: boolean;
  status: 'active' | 'suspended';
}

export interface EmailRecordType {
  autoconfig: EmailRecordContainer;
  mx: EmailRecordContainer;
  spf: EmailRecordContainer;
  dkim: EmailRecordContainer;
}

export interface EmailRecordContainer {
  defaultRecords: EmailRecord[];
  yourRecords: EmailRecord[];
  status: string;
}

export interface EmailRecord {
  type: string;
  name: string;
  content: string;
  prio: number;
  ttl: number;
  index?: number;
}

export interface EmailForwarders {
  catchall: EmailForwardersItem[];
  forward: EmailForwardersItem[];
  alias: EmailForwardersItem[];
}

export interface EmailAlias {
  id: string;
  addressFrom: string;
  addressTo: string;
  status: string;
}

export interface EmailForwardersDestination {
  destination: string;
  status: string;
}

export interface EmailForwardersAlias {
  alias: string;
  status: string;
}

export interface EmailForwardersItem {
  address: string;
  destinations: EmailForwardersDestination[];
  aliases: EmailForwardersAlias[];
  keepCopy?: boolean;
}
export interface GetAllEmailsParams {
  page: number;
  perPage: number;
}
export interface EmailItem {
  id: number;
  orderId: string;
  resourceId: string;
  reseller: string;
  customerId: string;
  customerEmail: string;
  provider: Email.EmailProvider;
  plan: { name: string; title: string };
  metadata?: { titanChosen?: boolean };
  domain: string;
  status: Email.OrderStatus;
  seats: number;
  createdAt: string;
  activatedAt: string;
  expiresAt: string;
  suspensionReason: string;
}

interface EmailPlan {
  name: Email.Plan;
  title: string;
}

export interface FreeEmailAvailableDomain {
  hostingId: number;
  domain: string;
}

export interface PusherEmailConfirmation {
  syncedAt: string | null;
  pricingData: EmailPlanPricing[];
  emailAccountsActive: number;
  emailAccountsBillable: number;
  syncFailed: string | null;
  userId: number;
  state: string;
  titanChosen: boolean;
  idempotencyKey: string;
  customerId: string;
  emailPlan: string;
  emailLimit: number;
  expiresAt: string;
  domain: string;
  metadata: {
    orderId: string;
    customerName: string;
    customerEmail: string;
    invoiceNr: string;
    source: string;
    locale: string;
    billingPeriod: string;
    amountCharged: {
      chargedAmount: number;
      discountAmount: number;
      taxAmount: number;
      currency: string;
    };
  };
  updatedAt: string;
  createdAt: string;
  id: number;
  titanOrderId: number;
}

export const EMAIL_SERVICE_STATUS = {
  INITIAL: 'initial',
  ACTIVE: 'active',
  PENDING_SETUP: 'pending_setup',
  FAILED: 'failed',
  SUSPENDED: 'suspended',
  ACTIVATING: 'activating',
  CANCELED: 'canceled',
  DELETED: 'deleted',
} as const;

export type EmailsServiceStatus =
  (typeof EMAIL_SERVICE_STATUS)[keyof typeof EMAIL_SERVICE_STATUS];

export interface EmailServiceListItem {
  id: string;
  orderId: string;
  resourceId: string;
  reseller: string;
  customerId: string;
  customerEmail: string;
  provider: Email.EmailProvider;
  plan: EmailPlan;
  domain: string | null;
  status: EmailsServiceStatus;
  seats: number;
  createdAt: string;
  activatedAt: string | null;
  expiresAt: string;
  metadata: {
    titanChosen?: boolean | null;
  };
}

interface EmailServiceListMetadata {
  currentPage: number;
  from: number;
  lastPage: number;
  path: string;
  perPage: number;
  to: number;
  total: number;
}

interface EmailServiceListLinks {
  first: string;
  prev: string | null;
  next: string | null;
  last: string;
}

export interface EmailServiceList {
  data: EmailServiceListItem[];
  links: EmailServiceListLinks;
  meta: EmailServiceListMetadata;
}

export interface EmailServiceListParams {
  page?: number;
  perPage?: number;
  search?: string;
  sortBy?: string;
  sortDir?: string;
}

export interface ClaimFreeTrialRequest {
  customerId: string;
  currency: string;
  coupons: string[];
  billingAddress: {
    firstName: string;
    lastName: string;
    company: string;
    address_1: string;
    address_2: string;
    city: string;
    zip: string;
    country: string;
    phone: string;
    email: string;
  };
  items: [
    {
      itemId: string;
      quantity: number;
      metadata: {
        domain: string;
      };
    },
  ];
  redirectReturn: string;
  redirectCancel: string;
}

export const EMAIL_DOMAIN_RECORD_STATUS = {
  CORRECT: 'Correct',
  MISSING: 'Missing',
  INCORRECT: 'Incorrect',
} as const;

export type EmailDomainRecordStatus =
  (typeof EMAIL_DOMAIN_RECORD_STATUS)[keyof typeof EMAIL_DOMAIN_RECORD_STATUS];

export interface IEmailDNSRecordOverview {
  domain: string;
  provider: string;
  mx: IEmailDNSRecordConfig;
  spf: IEmailDNSRecordConfig;
  dkim: IEmailDNSRecordConfig;
  dmarc: IEmailDNSRecordConfig;
  autoconfig: IEmailDNSRecordConfig;
}

export interface IEmailDNSRecordConfig {
  actual: IEmailDNSRecord[];
  suggested: IEmailDNSRecord[];
  status: EmailDomainRecordStatus;
}

export interface IEmailDNSRecord {
  hostname: string;
  ttl: number;
  priority?: number;
  value: string;
  type?: string;
}

export interface IEmailConnectDomainStatusCardProps {
  title: string;
  description: string;
  amplitudeEvent: string;
  label: {
    text: string;
    theme: HLabelTheme;
    recordStatus: EmailDomainRecordStatus;
  };
}

export interface IEmailDNSStatusOverview {
  domain: string;
  provider: string;
  isPointing: boolean;
  mx: IEmailDNSRecordConfig;
}

export interface IDefaultEmailRecord {
  ttl: number;
  value: string;
  priority?: number;
  hostname: string;
  type: string;
}

interface IEmailsResponseItem {
  id: number;
  title: string;
  serviceName: string;
  status: string;
  serviceId: number;
  referenceId: string;
  hresourceId: number;
  orderId: string;
  ctaText: string;
  ctaLink: string;
  domain: string | null;
  expiresAt: string;
  emailLimit: number;
  setupMessage: string;
  suspensionReason: string | null;
  titanChosen: boolean;
}

export interface IEmailsResponse {
  flock: IEmailsResponseItem[];
  flockSetup: IEmailsResponseItem[];
  googleGsuite: IEmailsResponseItem[];
  googleGsuiteSetup: IEmailsResponseItem[];
  hostinger: IEmailsResponseItem[];
  hostingerSetup: IEmailsResponseItem[];
}

export interface IDkimRequest {
  domainResourceId: string;
}

export interface IDkimChangeRequest extends IDkimRequest {
  hostname: string;
}

export interface IDomainCheckRequest {
  orderId: string;
  domain: string;
}

export interface IEmailOrderUpgradeRequest {
  plan: string;
  seats: number;
}

export interface IEmailOrderParams {
  format?: string;
}

export interface IEmailOutboundLogsParams {
  limit?: number;
  offset?: number;
  date?: string;
  status?: string;
}

export interface IEmailInboundLogsParams extends IEmailOutboundLogsParams {}

export interface IEmailImportTaskParams {
  clientDomain?: string;
  destinationHost?: string;
  destinationLogin?: string;
  destinationPassword?: string;
  sourceHost?: string;
  sourceLogin?: string;
  sourcePassword?: string;
}

export interface ICatchAllCreationRequest {
  domain: string;
  addressTo: string;
  brand: string;
}

export interface IV2EmailAccountCreationRequest {
  domainId: string;
  account: string;
  password: string;
}

export interface IEmailAccountStatusUpdateRequest {
  active: boolean;
  loginEnabled: boolean;
  smtpInEnabled: boolean;
  smtpOutEnabled: boolean;
}

export interface IEmailForwarderCreationRequest {
  addressFrom: string;
  addressTo: string;
  keepCopy?: boolean;
  brand: string;
}

export interface ITitanMailSetupRequest {
  domain: string;
  resourceId: string;
}

export interface IAutoReplyUpdateRequest {
  subject: string;
  body: string;
  startType?: string;
  endType?: string;
  startTime: string;
  endTime: string;
}

export interface IAutoReplyCreationRequest extends IAutoReplyUpdateRequest {
  domainId: string;
  email: string;
}

export interface IGoogleMXRecordRequest {
  zoneName: string;
}

export interface IAliasCreationRequest {
  domainId: string;
  email: string;
  alias: string;
}

export interface IGoogleWSSetupRequest {
  domain: string;
  orderId: string;
}

export interface IEmailActionLogsParams {
  limit: number;
  offset: number;
  date: string;
  status: string;
  account: string;
}

export interface IEmailAccessLogsParams {
  limit: number;
  offset: number;
  date: string;
  status: string;
  protocol: string;
}

export type LimitActionKey = 'get-more-accounts' | 'upgrade' | 'manage-dkim';

export interface IEmailPlanDetails {
  loading: boolean;
  title: string;
  plan: string;
  mailbox: {
    storageQuota: number;
    messageQuota: number;
    ratelimitInbound: string;
    ratelimitOutbound: string;
  };
}

export interface IEmailDomainDetails {
  domain: string;
  planName: string;
  accountCount: number;
  forwardCount: number;
  forwardCopyCount: number;
  catchallCount: number;
  aliasCount: number;
  autoreplyCount: number;
  status: string;
  statusReason: string | null;
  createdAt: string;
}

export const EMAIL_PLAN_SLUG = {
  HOSTINGER_FREE: 'hostinger_mail:free',
  HOSTINGER_PRO: 'hostinger_mail:pro',
  HOSTINGER_PREMIUM: 'hostinger_mail:premium',
  TITAN_FREE: 'flock_mail:free',
  TITAN_PRO: 'flock_mail:pro',
  TITAN_PREMIUM: 'flock_mail:premium',
  GOOGLE_BASIC: 'google_gsuite:basic',
} as const;

export type EmailPlanSlug =
  (typeof EMAIL_PLAN_SLUG)[keyof typeof EMAIL_PLAN_SLUG];

export interface IAutoreplyFormValues {
  email: string;
  subject: string;
  startDate: string;
  endDate: string;
  startImmediately: boolean;
  neverEnd: boolean;
}
