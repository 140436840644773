export namespace WebsiteDomainStatus {
  export const WEBSITE_DOMAIN_NEXT_BEST_ACTION = {
    OWNERSHIP_TRANSFER_PENDING: 'ownership_transfer_pending',
    OWNERSHIP_TRANSFER_REJECTED: 'ownership_transfer_rejected',
    OWNERSHIP_TRANSFER_IN_PROGRESS: 'ownership_transfer_in_progress',
    SUSPENDED_RENEW: 'suspended_renew',
    SUSPENDED_CONTACT_US: 'suspended_contact_us',
    EXPIRING_RENEW: 'expiring_renew',
    RESTORE_BEFORE_COUNTDOWN: 'restore_before_countdown',
    MIGRATION_CONNECTION_STATUS: 'migration_connection_status',
  };
}

export namespace EmailDomainStatus {
  export const EMAIL_DOMAIN_NEXT_BEST_ACTION = {
    EXPIRING_RENEW: 'expiring_renew',
    SUSPENDED_REVIEW: 'suspended_review',
  };
}
