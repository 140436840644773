export namespace Email {
  export enum Product {
    HOSTINGER_MAIL = 'hostinger_email',
    TITAN_MAIL = 'titan_email',
    HMAIL = 'hostinger_mail',
    FLOCK_MAIL = 'flock_mail',
    GOOGLE_WS = 'google_ws',
    GOOGLE_GSUITE = 'google_gsuite',
  }

  export enum Plan {
    HOSTINGER_BUSINESS_L = 'business_l',
    HOSTINGER_BUSINESS_S = 'business_s',
    HOSTINGER_BUSINESS_FREE = 'hostinger_free',
    HOSTINGER_STARTER = 'hostinger_starter',
    HOSTINGER_PREMIUM = 'hostinger_premium',
    HOSTINGER_TRIAL = 'business_s_trial',
    TITAN_BUSINESS_EMAIL = 'pro',
    TITAN_ENTERPRISE_EMAIL = 'premium',
    TITAN_ULTRA_EMAIL = 'ultra',
    TITAN_FREE = 'free',
    GOOGLE_WORKSPACE = 'google',
  }

  export enum ProductId {
    HOSTINGER_PREMIUM_CB = 'hostingermail-premium',
    HOSTINGER_PRO_CB = 'hostingermail-pro',
    HOSTINGER_PREMIUM = 'hostinger-mail-premium',
    HOSTINGER_PRO = 'hostinger-mail-pro',
    HOSTINGER_FREE = 'hostinger-mail-free',
    TITAN_PREMIUM = 'flock-mail-premium',
    TITAN_PRO = 'flock-mail-pro',
    TITAN_ULTRA = 'titanmail-ultra',
    TITAN_PREMIUM_CB = 'titanmail-premium',
    TITAN_PRO_CB = 'titanmail-pro',
    TITAN_FREE = 'titan-mail-free',
    GOOGLE_GSUITE_BASIC = 'googlegsuite-basic',
  }

  export enum Limit {
    MAILBOX_LIMIT = 1000,
  }

  export enum ImportStatus {
    IMPORTING = 'Importing',
    SCHEDULED = 'Scheduled',
    FAILED = 'Failed',
    CANCELED = 'Cancelled',
    COMPLETED = 'Completed',
  }

  export enum LogType {
    ACCESS = 'access',
    ACTION = 'action',
    DELIVERY = 'delivery',
  }

  export enum EmailProvider {
    HOSTINGER = 'hostinger',
    TITAN = 'titan',
    GOOGLE = 'google',
  }

  export enum DkimRecordStatus {
    VERIFYING = 'verifying',
    VERIFIED = 'verified',
    UNVERIFIED = 'unverified',
    VERIFICATION_FAILED = 'verification_failed',
  }

  export enum LegacyPlan {
    BUNDLE_100 = 'bundle_100x1',
    BUNDLE_1 = 'bundle_1x1',
    CLOUD_ECONOMY = 'cloud_economy',
    CLOUD_ENTERPRISE = 'cloud_enterprise',
    CLOUD_PROFESSIONAL = 'cloud_professional',
    HOSTINGER_BUSINESS = 'hostinger_business',
    HOSTINGER_PREMIUM = 'hostinger_premium',
    HOSTINGER_STARTER = 'hostinger_starter',
  }

  export enum PerkId {
    STORAGE_PER_ACCOUNT = 'storage_per_account',
    FORWARDERS = 'forwarders',
    ALIASES = 'aliases',
    ANTI_SPAM_VIRUS = 'anti_spam_virus',
    CUSTOM_DKIM = 'custom_dkim',
    ALLOW_LIST = 'allow-list',
    IMPORT = 'import',
    CLOUD_BASED = 'cloud_based',
    RICH_WEBMAIL = 'rich_webmail',
    IMAP_POP = 'imap_pop',
    STORAGE_PER_USER = 'storage_per_user',
    VIDEO_MEETINGS = 'video_meetings',
    SECURITY_MANAGEMENT = 'security_management',
    COLLABORATIVE_CONTENT_CREATION = 'collaborative_content_creation',
    SECURE_CLOUD_STORAGE = 'secure_cloud_storage',
    ADVANCED_PHISHING_MALWARE_PROTECTION = 'advanced_phishing_protection',
    INBUILT_CALENDAR_AND_CONTACTS = 'inbuilt_calendar_and_contacts',
    READ_RECEIPTS = 'read_receipts',
    EMAIL_TEMPLATES = 'email_templates',
    EXTERNAL_EMAIL_FORWARDERS = 'external_email_forwarders',
    CONTACT_GROUPS = 'contact_groups',
    FOLLOW_UP_REMINDERS = 'follow_up_reminders',
    SEND_LATER = 'send_later',
    PRIORITY_INBOX = 'priority_inbox',
    HTML_IN_COMPOSER = 'html_in_composer',
    SEND_AS_ALIAS = 'send_as_alias',
    UNDO_SEND = 'undo_send',
    ADVANCED_ANTI_SPAM = 'advanced_anti_spam',
    ADVANCED_ANTI_VIRUS = 'advanced_anti_virus',
    TWO_FACTOR_AUTHENTICATION = 'two_factor_authentication',
    BLOCK_SENDER = 'block_sender',
    MULTI_ACCOUNT_SUPPORT = 'multi_account_support',
    AUTO_REPLY = 'auto_reply',
    SECURE_WEB_APPLICATION = 'secure_web_application',
    ACCESS_WITH_ANY_DEVICE = 'access_with_any_device',
    PERSONALIZED_DOMAIN_NAME = 'personalized_domain_name',
    CONTACT_SERVICE = 'contact_service',
    FULL_ACCOUNT_MANAGEMENT = 'full_account_management',
    EASY_CONFIGURATION_WIZARD = 'easy_config',
    EMAIL_FORWARDING = 'email_forwarding',
    EMAIL_ALIASES = 'email_aliases',
    CATCH_ALL_ACCOUNT = 'catch_all_account',
    AUTOMATIC_REPLIES = 'automatic_replies',
    EMAIL_IMPORTING = 'importing_of_existing_emails',
    AUDIT_LOGS = 'audit_logs',
    WORKS_ON_MOBILE_AND_DESKTOP = 'works_on_mobile_and_desktop',
    EMAIL_FILTERS = 'email_filters',
    CONTACT_LISTS = 'contact_list',
    PERSONALIZED_SIGNATURES = 'personalized_signatures',
    ADVANCED_SEARCH = 'advanced_search',
    IMPORT_AND_EXPORT = 'import_and_export',
    LIGHT_AND_DARK_THEMES = 'light_and_dark_themes',
    AVAILABLE_IN_LANGUAGES = 'available_in_languages',
    ENTERPRISE_ANTI_SPAM = 'enterprise_anti_spam',
    ENTERPRISE_ANTI_VIRUS = 'enterprise_anti_virus',
    DKIM_AND_OTHER_SUPPORT = 'dkim_and_other_support',
    DATA_ENCRYPTION = 'data_encryption',
    MONEY_BACK_GUARANTEE = 'money_back_guarantee',
    SUPPORT = 'live_support',
    UPTIME = 'uptime_guarantee',
    CLOUD_BASE = 'cloud_based_infra',
    UNLIMITED_ALIAS = 'unlimited_alias',
    EMAIL_CAMPAIGNS = 'email_campaigns',
    SMART_WRITE_AI = 'smart_write_ai',
    APPOINTMENT_BOOKING = 'appointment_booking',
    ATTACHMENTS_AND_LINK_TRACKING = 'attachments_and_link_tracking',
    SIGNATURE_DESIGNER = 'signature_designer',
  }

  export enum RecordType {
    MX = 'mx',
    SPF = 'spf',
    DKIM = 'dkim',
    DMARC = 'dmarc',
  }

  export enum PerkStatus {
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
  }

  export enum RecordStatus {
    ACTIVE = 'active',
  }

  export enum OrderStatus {
    ACTIVE = 'active',
    COMPLETED = 'completed',
    PENDING_DATA_ENTRY = 'pending_data_entry',
    PENDING_SETUP = 'pending_setup',
    SETUP_NOT_FINISHED = 'setup_not_finished',
    UPGRADING = 'upgrade_is_being_processed',
    IN_PROGRESS = 'in_progress',
    CANCELED = 'canceled',
    DELETED = 'deleted',
    ACTIVATING = 'activating',
    FAILED = 'failed',
    SUSPENDED = 'suspended',
    EXPIRED = 'expired',
    INITIAL = 'initial',
  }

  export enum SubCategory {
    GOOGLE = 'google',
  }
  export enum ProEmailProvider {
    HOSTINGER = 'hostinger',
    TITAN = 'titan',
    GOOGLE = 'google',
  }
}
