import type {
  IVerifyCpanelMigrationCredentials,
  IVerifyWpMigrationCredentials,
  MigrationConnectionStatus,
  IMigration,
  RequestConfig,
} from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_REST}/v2/migration`,
  async getMigrations() {
    return await hAsync<IMigration[]>(http.get(`${this.url}`));
  },
  // @ts-ignore
  async createMigration(params, plain = true) {
    return await hAsync(
      http.post(`${this.url}`, params, {
        plain,
        whitelistedStatusCodes: [400],
      }),
    );
  },

  // @ts-ignore
  async editMigration(params, plain = true) {
    return await hAsync(
      http.patch(`${this.url}`, params, {
        plain,
        whitelistedStatusCodes: [400],
      }),
    );
  },
  async destroyMigration(id: string) {
    return await hAsync<boolean>(http.delete(`${this.url}/${id}`));
  },
  async verifyWpMigrationCredentials(
    payload: IVerifyWpMigrationCredentials,
    params?: RequestConfig,
  ) {
    return await hAsync<MigrationConnectionStatus>(
      http.post(`${this.url}/verify-credentials`, payload, { params }),
    );
  },
  async verifyCpanelMigrationCredentials(
    payload: IVerifyCpanelMigrationCredentials,
    params?: RequestConfig,
  ) {
    return await hAsync<MigrationConnectionStatus>(
      http.post(`${this.url}/verify-cpanel`, payload, { params }),
    );
  },
};
