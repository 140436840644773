import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export const usePaymentMethod = () => {
  const store = useStore();

  const paymentMethodsLoaded = computed(
    () => store.state.paymentMethods.loaded,
  );
  const paymentMethodsLoading = computed(
    () => store.state.paymentMethods.loading,
  );
  const defaultPaymentMethod = computed(
    () => store.getters['paymentMethods/getDefaultPaymentMethod'],
  );

  onMounted(() => {
    if (paymentMethodsLoaded.value) return;
    store.dispatch('paymentMethods/billingGetPaymentMethods');
  });

  return {
    paymentMethodsLoading,
    defaultPaymentMethod,
  };
};
