export namespace HVps {
  export enum ServerFeature {
    DAYLY_BACKUPS = 'dailyBackups',
  }

  export enum ServerHost {
    PREFIX = 'srv',
    SUFFIX = '.hstgr.cloud',
  }

  export enum OsLicenseRequirement {
    OPTIONAL = 'optional',
    REQUIRED = 'required',
    LITESPEED = 'Litespeed',
  }

  export enum OsTemplates {
    DIRECTADMIN = 252,
    LITESPEED = 243,
  }

  export enum OsGroup {
    PLAIN_OS = 'PLAIN_OS',
    WITH_PANEL = 'WITH_PANEL',
    GAME_SERVER = 'GAME_SERVER',
    WITH_APP = 'WITH_APP',
  }

  export enum OsType {
    GAME_SERVER = 'GAME_SERVER',
    DIRECT_ADMIN = 'DIRECTADMIN',
  }

  export enum PanelType {
    LITESPEED = 'litespeed',
  }

  export enum LicenseSlugs {
    DIRECTADMIN = 'da',
    CPANEL = 'cpanel',
    PLESK = 'plesk',
    LITESPEED = 'ls',
    WEBUZO = 'webuzo',
  }

  export enum NodeStates {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    LOCKED = 'locked',
    NOT_EXIST = 'not_exist',
  }

  export enum ServerState {
    RUNNING = 'running',
    STOPPED = 'stopped',
    STOPPING = 'stopping',
    STARTING = 'starting',
    RECREATING = 'recreating',
    MAINTENANCE = 'maintenance',
    INITIAL = 'initial',
    CREATING = 'creating',
    ERROR = 'error',
    RECOVERY = 'recovery',
    INSTALLING = 'installing',
    SUSPENDED = 'suspended',
    DESTROYED = 'destroyed',
    DESTROYING = 'destroying',
    DELETED = 'deleted',
    TRANSFERRING = 'transferring',
    RESTORING = 'restoring',
    UPDATING = 'updating',
  }

  export enum ServerLock {
    LOCKED = 'locked',
    UNLOCKED = 'unlocked',
  }

  export enum ServerProvider {
    OPENVZ = 'openvz',
    PROXMOX = 'proxmox',
  }

  export enum OrderType {
    VPS = 'vps',
    MINECRAFT = 'minecraft',
    CYBERPANEL = 'cyberpanel',
    DIRECTADMIN = 'directadmin',
  }

  export enum AssistantRole {
    USER = 'user',
    ASSISTANT = 'assistant',
  }

  export enum AssistantContentType {
    CODE = 'code',
    TEXT = 'text',
  }

  export const OrderTypes = [
    OrderType.MINECRAFT,
    OrderType.CYBERPANEL,
    OrderType.DIRECTADMIN,
  ] as const;

  export enum MalwareScannerState {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    UNLICENSED = 'unlicensed',
  }

  export enum License {
    MONARX = 'monarx',
  }

  export enum TransferOption {
    TRANSFER = 'transfer',
    REINSTALL = 'reinstall',
  }

  export enum MalwareScannerFileAction {
    CLEANED = 'cleaned',
    QUARANTINED = 'quarantined',
  }

  export enum MalwareType {
    MALICIOUS = 'malicious',
    COMPROMISED = 'compromised',
  }

  export enum SuspendReason {
    ABUSE = 'abuse',
    FRAUD = 'fraud',
    NON_PAYMENT = 'non_payment',
    SUSPENDED = 'suspended',
  }

  export enum DefaultTemplateUsername {
    ROOT = 'root',
    ADMIN = 'admin',
  }

  export enum VpsPurchaseTab {
    KVM = 'kvm',
    MINECRAFT = 'kvm_minecraft',
  }

  export enum PlanSlug {
    KVM_1 = 'vps:vps_kvm_1',
    KVM_2 = 'vps:vps_kvm_2',
    KVM_4 = 'vps:vps_kvm_4',
    KVM_8 = 'vps:vps_kvm_8',
    VPS_1 = 'vps:vps_1',
    VPS_2 = 'vps:vps_2',
    VPS_3 = 'vps:vps_3',
    VPS_4 = 'vps:vps_4',
    VPS_6 = 'vps:vps_6',
    VPS_8 = 'vps:vps_8',
    VPS_12 = 'vps:vps_12',
    VPS_16 = 'vps:vps_16',
    CYBERPANEL_1 = 'vps:vps_cyberpanel_1',
    CYBERPANEL_2 = 'vps:vps_cyberpanel_2',
    CYBERPANEL_3 = 'vps:vps_cyberpanel_3',
    CYBERPANEL_4 = 'vps:vps_cyberpanel_4',
    CYBERPANEL_6 = 'vps:vps_cyberpanel_6',
    CYBERPANEL_8 = 'vps:vps_cyberpanel_8',
    CYBERPANEL_12 = 'vps:vps_cyberpanel_12',
    CYBERPANEL_16 = 'vps:vps_cyberpanel_16',
    MINECRAFT_ALEX = 'vps:vps_kvm_minecraft_alex',
    MINECRAFT_CREEPER = 'vps:vps_kvm_minecraft_creeper',
    MINECRAFT_ENDERMAN = 'vps:vps_kvm_minecraft_enderman',
    MINECRAFT_WOLF = 'vps:vps_kvm_minecraft_wolf',
  }

  export enum Perks {
    CPU_CORE = '<strong>{count}</strong> vCPU Core',
    RAM = '<strong>{count} GB</strong> RAM',
    DISK_SPACE = '<strong>{count} GB</strong> NVMe Disk Space',
    BANDWIDTH = '<strong>{count} TB</strong> Bandwidth',
    GEEKBENCH_SCORE = '<strong>{count}</strong> Geekbench Score',
    SNAPSHOT = '<strong>{count}</strong> Snapshot',
    WEEKLY_BACKUPS = '<strong>Weekly</strong> Backups',
    DEDICATED_IP_ADDRESS = '<strong>Dedicated</strong> IP Address',
    FULL_ROOT_ACCESS = '<strong>Full</strong> Root Access',
    AI_ASSISTANT = '<strong>AI</strong> Assistant',
    MALWARE_SCANNER = '<strong>Malware</strong> Scanner',
    CPU_HARDWARE = '<strong>{count}</strong> vCPU Hardware',
    MOD_SUPPORT = '<strong>Mod</strong> Support',
    DDOS_PROTECTION = '<strong>DDoS</strong> Protection',
    AUTOMATIC_OFFSITE_BACKUPS = '<strong>Automatic</strong> Off-Site Backups',
  }

  export enum ActionName {
    CREATE_SNAPSHOT = 'create_snapshot',
    DAILY_BACKUPS_RENEW = 'daily_backups_renew',
  }
}
