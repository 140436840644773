import { hostingEmailsRepo, storeRepo } from '@/repositories';
import { toASCII } from '@/utils/helpers';

export default {
  state: {
    googleWorkspaceOrders: [],
    googleWorkspaceActions: [],
    loading: false,
  },
  mutations: {
    SET_GOOGLE_WORKSPACE_ORDER(state, data) {
      if (!data) return;

      state.googleWorkspaceOrders = state.googleWorkspaceOrders.filter(
        ({ domain }) => domain !== data?.domain,
      );

      state.googleWorkspaceOrders.push(data);
    },
    SET_GOOGLE_WORKSPACE_ACTIONS(state, { orderId, actions }) {
      state.googleWorkspaceActions = state.googleWorkspaceActions.filter(
        (actionsForOrder) => actionsForOrder.orderId !== orderId,
      );

      state.googleWorkspaceActions.push({
        orderId,
        actions,
        updatedAt: Date.now(),
      });
    },
    SET_GOOGLE_WORKSPACE_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  getters: {
    getGoogleWorkspaceDetails: (state) => (findDomain) =>
      state.googleWorkspaceOrders.find(
        ({ domain }) =>
          toASCII(domain?.toLowerCase()) === toASCII(findDomain?.toLowerCase()),
      ),
    getGoogleWorkspaceEmailAccounts: (state, getters) => (findDomain) => {
      const gsuiteOrder = getters.getGoogleWorkspaceDetails(findDomain);

      return (gsuiteOrder?.emailAccounts || []).map((user) => ({
        ...user,
        status: gsuiteOrder?.status?.service || 'active',
      }));
    },
    getGoogleWorkspaceBillingDetails: (state, getters) => (findDomain) => {
      const gsuiteOrder = getters.getGoogleWorkspaceDetails(findDomain);

      return gsuiteOrder?.billingDetails || {};
    },
    getGoogleWorkspaceOrderDetails: (state, getters) => (findDomain) => {
      const gsuiteOrder = getters.getGoogleWorkspaceDetails(findDomain);

      if (!gsuiteOrder) return {};

      return {
        planTitle: `Google Workspace ${gsuiteOrder?.billingDetails?.mailboxPlan?.toUpperCase()}`,
        expiryDate: gsuiteOrder?.expiresAt,
        orderId: gsuiteOrder?.orderId,
        isRenewable: gsuiteOrder?.isRenewable,
        status: gsuiteOrder?.status.order,
        serviceStatus: gsuiteOrder?.status.service,
        subscriptionId: gsuiteOrder?.subscriptionId,
      };
    },
    getGoogleWorkspaceActions: (state) => (orderIdValue) =>
      state.googleWorkspaceActions.find(
        ({ orderId }) => orderId === orderIdValue,
      ) || {},
  },
  actions: {
    async googleWorkspaceOrderIndex({ commit }, { orderId }) {
      commit('SET_GOOGLE_WORKSPACE_LOADING', true);

      const [{ data }] = await hostingEmailsRepo.getGoogleWorkspaceData(
        orderId,
      );

      commit('SET_GOOGLE_WORKSPACE_ACTIONS', {
        orderId,
        actions: [...(data.actions || [])],
      });

      delete data.actions;

      commit('SET_GOOGLE_WORKSPACE_ORDER', data);

      commit('SET_GOOGLE_WORKSPACE_LOADING', false);
    },
    async googleWorkspaceOrderUpgradeInstantStore(context, params) {
      const [data] = await storeRepo.googleWorkspaceInstaUpgrade(params);

      return data;
    },
    async googleWorkspaceOrderUpgradeInvoiceCreateStore(context, params) {
      return await storeRepo.googleWorkspaceUpgradeInvoice(params);
    },
    async googleWorkspaceOrderActionsIndex({ commit }, { orderId }) {
      const [{ data }] = await hostingEmailsRepo.getGoogleGsuiteActions(
        orderId,
      );

      commit('SET_GOOGLE_WORKSPACE_ACTIONS', { orderId, actions: data });
    },
    async googleWorkspaceOrderActionRetry({ dispatch }, { actionId, orderId }) {
      await hostingEmailsRepo.googleGsuiteRetry(actionId);

      await dispatch('emails/googleWorkspaceOrderActionsIndex', {
        orderId,
      });
    },
  },
};
