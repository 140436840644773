<script setup lang="ts">
import { computed, ref } from 'vue';

import BulkActionDomainsList from './BulkActionDomainsList.vue';

import HModalActions from '@/components/HModals/HModalActions.vue';
import { useGlobals, useModal, useDynamicDomainText } from '@/composables';
import type { HIconUnion } from '@/types';

type Props = {
  data: {
    title: string;
    subtitle: string;
    completedList: string[];
    availableList: string[];
    unavailableList: string[];
    confirmInfoText?: string;
    onConfirm: () => void;
    onGoBack: () => void;
  };
};

const { openModal } = useModal();
const { t } = useGlobals();
const {
  getCompletedBulkActionText,
  getAvailableBulkActionText,
  getUnavailableBulkActionText,
  getActionAlreadyCompletedText,
  getActionCanBeCompletedText,
  getActionCannotBeCompletedText,
} = useDynamicDomainText();

const props = defineProps<Props>();

const isSubmitted = ref(false);

interface Emits {
  (eventName: 'close'): void;
}

const emits = defineEmits<Emits>();

const showActionCompletedList = () => {
  openDomainBulkActionList(
    props.data.completedList,
    t('List of domains which have action completed'),
    getCompletedBulkActionText(props.data.completedList.length),
  );
};

const showActionAvailableList = () => {
  openDomainBulkActionList(
    props.data.availableList,
    t('List of domains available for this action'),
    getAvailableBulkActionText(props.data.availableList.length),
  );
};

const showActionUnavailableList = () => {
  openDomainBulkActionList(
    props.data.unavailableList,
    t('List of domains unavailable for this action'),
    getUnavailableBulkActionText(props.data.unavailableList.length),
  );
};

const openDomainBulkActionList = (
  domains: string[],
  title: string,
  subtitle: string,
) => {
  openModal({
    component: { BulkActionDomainsList },
    data: {
      title,
      subtitle,
      domains,
    },
    steps: [
      {
        hideX: true,
        goBackText: props.data.title,
        goBackCustom: props.data.onGoBack,
      },
    ],
  });
};

const actionLists = computed(() =>
  [
    {
      label: getActionAlreadyCompletedText(props.data.completedList.length),
      listCount: props.data.completedList.length,
      onShowList: showActionCompletedList,
      icon: 'icon-check-circle' as HIconUnion,
      color: { success: true },
    },
    {
      label: getActionCanBeCompletedText(availableItemsListCount.value),
      listCount: availableItemsListCount.value,
      onShowList: showActionAvailableList,
      icon: 'icon-info' as HIconUnion,
      color: { gray: true },
    },
    {
      label: getActionCannotBeCompletedText(props.data.unavailableList.length),
      listCount: props.data.unavailableList.length,
      onShowList: showActionUnavailableList,
      icon: 'icon-cancel' as HIconUnion,
      color: { danger: true },
    },
  ].filter((list) => !!list.listCount),
);

const availableItemsListCount = computed(() => props.data.availableList.length);

const isConfirmInfoTextVisible = computed(
  () => !!props.data.confirmInfoText && !!availableItemsListCount.value,
);

const handleConfirmButtonClick = () => {
  isSubmitted.value = true;
  props.data.onConfirm();
  emits('close');
};
</script>

<template>
  <h2 v-trans class="h-mb-8">{{ props.data.title }}</h2>
  <p v-trans class="h-mb-24">{{ props.data.subtitle }}</p>
  <div v-for="list in actionLists" :key="list.label" class="d-flex h-mb-12">
    <HpIcon :icon="list.icon" class="h-mr-8" v-bind="list.color" />
    <div class="h-mr-8">
      {{ list.label }}
    </div>
    <hp-hyper-link bold color.prop="primary" @click="list.onShowList">
      {{ t('See list') }}
    </hp-hyper-link>
  </div>
  <HSnackbar v-if="isConfirmInfoTextVisible" class="h-mt-24" variant="info">
    <Trans>{{ props.data.confirmInfoText }}</Trans>
  </HSnackbar>

  <HModalActions
    class="h-mt-24"
    no-padding
    mobile-reverse
    close-text="Cancel"
    @close="emits('close')"
  >
    <HButton
      v-if="availableItemsListCount"
      v-qa-generate
      class="h-mb-8 h-mb-0-md"
      :is-disabled="isSubmitted"
      @click="handleConfirmButtonClick"
    >
      {{ t('Confirm') }}
    </HButton>
  </HModalActions>
</template>

<style scoped lang="scss">
.action-info {
  display: flex;
  border-radius: 4px;
  margin: 24px 0;
  background-color: var(--gray-light);
  padding: 8px 16px;
}
</style>
