import { setHComponentsTFunction } from '@hostinger/hcomponents';
import { updateTranslations } from '@hostinger/hpanel-components/dist/index.esm';
import Cookies from 'js-cookie';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import type { TBrandId, TLanguageCode, TLocale } from '@/types';
import {
  AVAILABLE_LANGUAGE_CONFIG,
  BRAND_LANGUAGE_CODE_MAP,
  Cookie,
  STORE_PERSISTENT_KEYS,
  LANGUAGE_CODE,
} from '@/types';
import {
  $t,
  loadLanguageAsync,
  initialMessagesLoad,
} from '@/utils/services/i18nService';

export const useLanguageStore = defineStore(
  'languageStore',
  () => {
    const currentLanguage = ref<TLanguageCode>(LANGUAGE_CODE.en_GB);

    const currentLocale = computed(
      (): TLocale => currentLanguageConfig.value.locale,
    );

    const isValidLanguage = (lang?: TLanguageCode) =>
      lang && Object.values(LANGUAGE_CODE).includes(lang);

    const currentLocaleUppercase = computed(
      () => currentLanguageConfig.value.localeCapitalized,
    );

    const currentLanguageCode = computed(
      () => currentLanguageConfig.value.code,
    );

    const currentLanguageConfig = computed(
      () => AVAILABLE_LANGUAGE_CONFIG[currentLanguage.value],
    );

    /**
     * Sets the language based on the provided {@link Client.BrandId}.
     * If the language is already set in the cookies, this function does nothing.
     * Otherwise, it loads the language asynchronously based on the brand ID.
     *
     * @param {TBrandId} brandId - The ID of the brand.
     */
    const setLanguageByBrandId = async (brandId: TBrandId) => {
      if (!!Cookies.get(Cookie.LANGUAGE)) return;

      await loadLanguageAsync(
        BRAND_LANGUAGE_CODE_MAP[brandId] || LANGUAGE_CODE.en_GB,
      );
    };

    const changeLanguage = async (lang: TLanguageCode) => {
      await loadLanguageAsync(lang);

      currentLanguage.value = lang;

      setHComponentsTFunction($t);
      updateTranslations($t);
    };

    const initMessages = async () => {
      const languageFromCookies = Cookies.get(Cookie.LANGUAGE) as TLanguageCode;

      const language = isValidLanguage(languageFromCookies)
        ? languageFromCookies
        : LANGUAGE_CODE.en_GB;
      currentLanguage.value = language;

      await initialMessagesLoad(language);
    };

    return {
      currentLanguage,
      currentLocale,
      currentLanguageCode,
      currentLocaleUppercase,
      currentLanguageConfig,
      setLanguageByBrandId,
      changeLanguage,
      initMessages,
    };
  },
  { persist: { key: STORE_PERSISTENT_KEYS.LANGUAGE_STORE } },
);
