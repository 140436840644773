import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { useSubscriptions } from '@/composables';
import { hDomainsRepo } from '@/repositories';
import { useDomainManagementStore, useProPanelStore } from '@/stores';
import type { IHDomainAction, IDomainBulkAction } from '@/types';
import { HDomains } from '@/types';
import { toUnicode, mapKeyValue, timeout } from '@/utils/helpers';

export const useDomainBulkActionsStore = defineStore(
  'domainBulkActionsStore',
  () => {
    const { fetchSubscriptionsForceSilently } = useSubscriptions();
    const proPanelStore = useProPanelStore();
    const domainManagementStore = useDomainManagementStore();

    const bulkActions = ref<IHDomainAction[] | []>([]);
    const isActionDeleted = ref(false);

    const isBulkActionCompleted = computed(() => {
      if (!bulkActions.value.length) return false;

      return !bulkActions.value.find(({ status }) =>
        [
          HDomains.BulkActionStatus.QUEUED,
          HDomains.BulkActionStatus.EXECUTING,
        ].includes(status),
      );
    });

    const successfulBulkActions = computed(
      () =>
        bulkActions.value.filter(
          ({ status }) => status === HDomains.BulkActionStatus.FINISHED,
        ) || [],
    );

    const failedBulkActions = computed(
      () =>
        bulkActions.value.filter(
          ({ status }) => status === HDomains.BulkActionStatus.FAILED,
        ) || [],
    );

    const finishedBulkActions = computed(() => [
      ...successfulBulkActions.value,
      ...failedBulkActions.value,
    ]);

    const isBulkActionInProgress = computed(
      () => !!(bulkActions.value.length && !isBulkActionCompleted.value),
    );

    const bulkActionProgressList = computed(() => {
      if (!bulkActions.value.length) return [];

      return bulkActions.value.map(({ domain, status }) => ({
        domain: toUnicode(domain),
        status: mapKeyValue(
          {
            Executing: HDomains.BulkActionStatus.PROCESSING,
            Finished: HDomains.BulkActionStatus.SUCCESS,
            default: status,
          },
          status,
        ),
        icon: mapKeyValue(
          {
            [HDomains.BulkActionStatus.FAILED]: {
              name: 'icon-close',
              color: 'danger',
            },
            [HDomains.BulkActionStatus.FINISHED]: {
              name: 'icon-check',
              color: 'success',
            },
            [HDomains.BulkActionStatus.QUEUED]: {
              name: 'icon-timelapse',
              color: 'warning-dark',
            },
            default: {
              name: 'icon-add',
              color: 'success',
            },
          },
          status,
        ),
      }));
    });

    const fetchBulkActions = async () => {
      // webpro endpoint returns bulk actions for managed and owned domains
      const [{ data }, err] = await hDomainsRepo.getBulkActions(true);
      if (err) return;

      bulkActions.value = data;
    };

    const fetchBulkActionsUntilCompleted = async () => {
      let attempts = 0;
      const TIMEOUT_DURATION = 3000;

      while (isBulkActionInProgress.value && attempts < 50) {
        await fetchBulkActions();
        domainManagementStore.setDomainsDataOutdated();
        await timeout(TIMEOUT_DURATION);
        attempts++;
      }

      if (isBulkActionCompleted.value) {
        fetchSubscriptionsForceSilently();
      }
    };

    const createBulkAction = async (
      payload: IDomainBulkAction,
      isWebproAction?: boolean,
    ) => {
      const [{ data }, error] = await hDomainsRepo.postBulkAction(
        payload,
        proPanelStore.isInProPanel || isWebproAction,
      );

      if (!error && data) {
        isActionDeleted.value = false;
        bulkActions.value = data;
      }

      return [data, error];
    };

    const deleteBulkAction = async () => {
      if (isActionDeleted.value) return;

      const [, error] = await hDomainsRepo.deleteBulkActions(true);

      if (!error) {
        isActionDeleted.value = true;
      }
    };

    const resetBulkActions = () => {
      bulkActions.value = [];
    };

    return {
      isBulkActionCompleted,
      isBulkActionInProgress,
      bulkActionProgressList,
      finishedBulkActions,
      failedBulkActions,
      successfulBulkActions,
      deleteBulkAction,
      resetBulkActions,
      fetchBulkActionsUntilCompleted,
      createBulkAction,
      fetchBulkActions,
    };
  },
);
