import type { Oauth } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_H_SSO}/v1/oauth`,
  postUnlink(socialLogin: Oauth, params: { twoFactorAuthCode?: string } = {}) {
    return hAsync<boolean>(
      http.post(`${this.url}/${socialLogin}/unlink`, params),
    );
  },
};
