export namespace HBilling {
  export enum SubscriptionStatus {
    ACTIVE = 'active',
    EXPIRED = 'expired',
    NON_RENEWING = 'non_renewing',
  }

  export enum InvoiceStatus {
    PAYMENT_DUE = 'payment_due',
    NOT_PAID = 'not_paid',
    PAID = 'paid',
    POSTED = 'posted',
    VOIDED = 'voided',
    PENDING = 'pending',
  }

  export enum PricingType {
    RENEW = 'renewPricing',
    UPGRADE = 'upgradePricing',
  }

  export const CatalogCategory = {
    HOSTING: 'HOSTING',
    DOMAIN: 'DOMAIN',
    VPS: 'VPS',
    EMAIL: 'EMAIL',
  } as const;

  export enum CatalogSubcategory {
    DOMAIN_TRANSFER = 'domain_transfer',
    DOMAIN = 'domain',
    KVM = 'kvm',
    VPS = 'vps',
    VPS_MINECRAFT = 'vps_minecraft',
    CLOUD = 'cloud',
    TITAN_MAIL = 'titan_mail',
    GOOGLE = 'google',
    HOSTINGER_MAIL = 'hostinger_mail',
  }

  export const CatalogEmailGoogle =
    `${HBilling.CatalogCategory.EMAIL}_${HBilling.CatalogSubcategory.GOOGLE}` as const;
}
