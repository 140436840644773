import { Route, type IArticleCard } from '@/types';

export const VPS_ARTICLES: Record<string, IArticleCard[]> = {
  onboarding: [
    {
      title: 'How to Use the {templateName} on VPS',
      link: '',
    },
    {
      title: 'How to Point a Domain to Your VPS',
      link: 'https://support.hostinger.com/articles/1583227',
    },
    {
      title: 'How to Migrate Your Website From Shared Hosting to VPS',
      link: 'https://www.hostinger.com/tutorials/migrate-website-from-shared-hosting-to-vps',
    },
  ],
  onboardingGamePanel: [
    {
      title: 'Quick Start Guide for Game Panel',
      link: 'https://support.hostinger.com/articles/4541961',
    },
    {
      title: 'How to Install Mods in Minecraft Using Game Panel',
      link: 'https://support.hostinger.com/articles/5379405',
    },
    {
      title: 'What Game Servers Are Supported on Game Panel?',
      link: 'https://support.hostinger.com/articles/7877961',
    },
  ],
  settings: [
    {
      title: 'How to Change the DNS Resolvers on VPS',
      link: 'https://support.hostinger.com/articles/5726596',
    },
    {
      title: 'How to Solve the Most Common Errors in SSH',
      link: 'https://support.hostinger.com/articles/5634587',
    },
    {
      title: 'How to Change your VPS SSH Password',
      link: 'https://support.hostinger.com/articles/8942826',
    },
    {
      title: 'How to Change a Password in Linux for Root and Other Users',
      link: 'https://www.hostinger.com/tutorials/how-to-change-password-in-linux',
    },
    {
      title:
        'How to Fix the "Your Connection Is Not Private" Error on VPS Login',
      link: 'https://support.hostinger.com/articles/5602852',
    },
    {
      title:
        "Can't find an article that addresses your issue? Ask AI Assistant",
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  emergencyMode: [
    {
      title: 'How to Use Emergency Mode on Your VPS',
      link: 'https://support.hostinger.com/articles/5726577',
    },
    {
      title: 'VPS Emergency Mode: Where to Find Files',
      link: 'https://support.hostinger.com/articles/8600853',
    },
    {
      title: 'VPS Emergency Mode: How to Change Apparent Root on Your Disk',
      link: 'https://support.hostinger.com/articles/8611503',
    },
    {
      title: 'How to Recover MySQL Databases From MySQL Data Files in VPS',
      link: 'https://support.hostinger.com/articles/4635603',
    },
    {
      title: 'What to Do if Your VPS Is in Grub Rescue Mode',
      link: 'https://support.hostinger.com/articles/8624545',
    },
    {
      title:
        "Can't find an article that addresses your issue? Ask AI Assistant",
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  dnsManager: [
    {
      title: 'How to Point a Domain to Your VPS',
      link: 'https://support.hostinger.com/articles/1583227',
    },
    {
      title: 'How to Find Your VPS IP Address',
      link: 'https://support.hostinger.com/articles/5139756',
    },
    {
      title:
        "Can't find an article that addresses your issue? Ask AI Assistant",
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  licenses: [
    {
      title: 'Why Is the VPS License Blocked',
      link: 'https://support.hostinger.com/articles/7193948',
    },
    {
      title: 'How to Purchase a Control Panel License for VPS',
      link: 'https://support.hostinger.com/articles/7034536',
    },
    {
      title:
        "Can't find an article that addresses your issue? Ask AI Assistant",
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  snapshotsBackups: [
    {
      title: 'How to Back Up or Restore a VPS Server',
      link: 'https://support.hostinger.com/articles/1583232',
    },
    {
      title: 'How to Connect to VPS Using SFTP',
      link: 'https://support.hostinger.com/articles/8868611',
    },
    {
      title: 'How to Activate Daily Backups',
      link: 'https://support.hostinger.com/articles/1665153',
    },
    {
      title:
        "Can't find an article that addresses your issue? Ask AI Assistant",
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  serverUsage: [
    {
      title: 'What Is the CPU Use Limit for VPS?',
      link: 'https://support.hostinger.com/articles/6899741',
    },
    {
      title: 'How to Check Detailed RAM Usage on Your VPS',
      link: 'https://support.hostinger.com/articles/6867460',
    },
    {
      title: 'What Is the Difference Between PHP Memory Limit and RAM?',
      link: 'https://support.hostinger.com/articles/4466613',
    },
    {
      title: 'How to kill a process in Linux from the command line',
      link: 'https://www.hostinger.com/tutorials/how-to-kill-a-process-in-linux',
    },
    {
      title: 'How to Check VPS Resources Usage',
      link: 'https://support.hostinger.com/articles/4725768',
    },
    {
      title:
        "Can't find an article that addresses your issue? Ask AI Assistant",
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  malwareScanner: [
    {
      title: 'VPS Malware Scanner',
      link: 'https://support.hostinger.com/articles/8450363',
    },
    {
      title: 'How to Secure Your VPS From Abusive Activity',
      link: 'https://support.hostinger.com/articles/8224050',
    },
    {
      title: '16 VPS Security Tips to Prevent Attacks on Your Server',
      link: 'https://www.hostinger.com/tutorials/vps-security',
    },
    {
      title: 'How to Secure Your VPS in 2024: Maximize Your VPS Security',
      link: 'https://www.youtube.com/watch?v=K4YOTAI5IeI',
    },
    {
      title:
        "Can't find an article that addresses your issue? Ask AI Assistant",
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
  overview: [
    {
      title: 'v2.vps_articles.steps_to_follow',
      link: 'https://www.hostinger.com/tutorials/i-bought-a-vps-now-what',
    },
    {
      title: 'v2.vps_articles.how_to_use_ssh_keys',
      link: 'https://support.hostinger.com/articles/4792364',
    },
    {
      title: 'v2.vps_articles.how_to_point_a_domain',
      link: 'https://support.hostinger.com/articles/1583227',
    },
    {
      title: 'v2.vps_articles.how_to_change_ssh_port',
      link: 'https://www.hostinger.com/tutorials/how-to-change-ssh-port-vps',
    },
    {
      title: 'v2.vps_articles.how_to_use_managed_firewall',
      link: 'https://support.hostinger.com/articles/8172641',
    },
    {
      title: 'v2.vps_articles.ai_assistant',
      link: Route.Vps.AI_ASSISTANT,
      aiAssistant: true,
    },
  ],
};
