import type { IWidgetRequest, IWidgetResponse } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const URL = `${process.env.VITE_API_INTEGRATIONS}/api/direct/hpanel-widgets`;

export default {
  getWidgets() {
    return hAsync<IWidgetResponse[]>(http.get(URL));
  },
  postWidgets({ data }: { data: { widgets: IWidgetRequest[] } }) {
    return hAsync<IWidgetResponse>(http.post(URL, data));
  },
};
