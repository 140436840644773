<template>
  <div
    v-if="show"
    :id="`${modalId}-modal`"
    ref="modalContainer"
    class="h-modal__wrapper d-flex align-items-center justify-content-center"
    :class="isAboveIntercom ? 'is-above-intercom' : ''"
    tabindex="0"
    @click.self="clickOutsideClose()"
    @keydown.esc="escClose()"
  >
    <div
      class="h-modal__container h-modal__container--lg"
      :class="{
        ...classes,
        'h-modal__container--m': m,
        'h-modal__container--xl': xl,
        'h-modal__container--xxl': xxl,
        'h-modal__container--800': w800,
      }"
    >
      <div v-if="showStepsIndicator" class="d-flex h-modal__stepper">
        <div
          v-for="(_, index) of new Array(stepsCount)"
          :key="index"
          class="h-modal__stepper-indicator"
          :class="{
            'h-modal__stepper-indicator--active': index <= currentStep,
          }"
        />
      </div>
      <div class="h-modal" :class="{ 'h-modal--withPadding': withPadding }">
        <div v-if="goBackText" class="h-modal__go-back">
          <span
            v-if="goBackStart"
            class="cursor-pointer"
            @click="goToPreviousStep(0)"
          >
            <HpIcon icon="ic-arrow-left" gray class="align-middle" />
          </span>
          <span v-else class="cursor-pointer" @click="handleGoBack">
            <HpIcon icon="ic-arrow-left" gray class="align-middle" />
          </span>
          <div class="h-modal__go-back--divider" />
          <span v-if="goBackNoTrans">
            {{ goBackText }}
          </span>
          <span v-else v-trans>
            {{ goBackText }}
          </span>
        </div>
        <div
          v-if="modalTitle || modalSubtitle || !hideX"
          class="h-modal__header d-flex flex-wrap"
          :class="{
            'h-modal__header--no-margin': noHeaderMargin,
          }"
        >
          <div v-if="modalTitle" class="h-modal__header-title">
            <HpIcon
              v-if="modalTitleIcon"
              class="h-mr-12"
              :icon="modalTitleIcon.name"
              v-bind="{ [modalTitleIcon.color]: true }"
            />
            <h2 class="flex-grow-1 text-break-word h-m-0">
              {{ modalTitle }}
            </h2>
          </div>
          <HpIcon
            v-if="!hideX"
            icon="icon-close"
            class="h-modal__close-btn"
            :class="{ 'h-modal__close-btn--hide-mobile': hideXMobile }"
            :alt="$t('close modal')"
            color="gray-darker"
            @click="xIconClose()"
          />
          <p
            v-if="modalSubtitle"
            class="w-100 text-break-word h-mt-8"
            v-html="modalSubtitle"
          />
        </div>
        <div class="h-modal__body" @mousedown="setIsModalClicked(true)">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getClasses } from '@/utils/helpers';
import hModalMixin from '@/utils/mixins/hModalMixin';
import modalsMixin from '@/utils/mixins/modalsMixin';

export default {
  name: 'HModal',
  mixins: [hModalMixin, modalsMixin],
  props: {
    goBackText: String,
    goBackStart: Boolean,
    goBackNoTrans: Boolean,
    goBackCustom: [Function, Boolean],
    noHeaderMargin: Boolean,
    title: String,
    subtitle: String,
    modalTitleIcon: Object,
    hideX: Boolean,
    withPadding: Boolean,
    show: Boolean,
    noTrans: Boolean,
    persistent: Boolean,
    autoWidth: Boolean,
    m: Boolean,
    lg: Boolean,
    xl: Boolean,
    xxl: Boolean,
    w800: Boolean,
    isAboveIntercom: Boolean,
    customPadding: {
      type: String,
      default: '32px 40px 40px',
    },
    hideXMobile: Boolean,
  },
  data() {
    return {
      isModalClicked: false,
    };
  },
  emits: ['update:show', 'close', 'previous-step'],
  provide: {
    modalOpened: true,
  },
  mounted() {
    if (this.canBeEscClosed) {
      this.$refs.modalContainer?.focus();
    }

    document.addEventListener(
      'mouseup',
      () => {
        setTimeout(() => {
          this.setIsModalClicked(false);
        }, 0);
      },
      true,
    );
  },
  computed: {
    classes() {
      return getClasses('h-modal__container', this.$props);
    },
    modalSubtitle() {
      if (this.noTrans) return this.subtitle;

      return this.$t(this.subtitle);
    },
    modalId() {
      return (
        this.title
          ?.toLowerCase()
          .replace(/[^a-zA-Z ]/g, '')
          .replace(/\s+/g, '-') || ''
      );
    },
  },
  methods: {
    handleGoBack() {
      this.goBackCustom ? this.goBackCustom() : this.$emit('previous-step');
    },
    setIsModalClicked(status) {
      this.isModalClicked = status;
    },
    xIconClose() {
      if (!this.isModalClicked && !this.persistent) this.closeModal();
    },
    closeModal() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../sass/components/h-modal';

.h-modal {
  @media (min-width: 420px) {
    padding: v-bind(customPadding);
  }

  &__header-margin {
    margin: unset;
  }

  &__header {
    &--no-margin {
      margin: 0;
    }
  }
}
</style>
