import { ref, computed, onMounted, onUnmounted } from 'vue';

export const useTimer = (endDate: number) => {
  const setTimeInMs = () => Math.trunc(Date.now() / 1000);

  const now = ref(setTimeInMs());
  const endDateInMs = ref(Math.trunc(new Date(endDate).getTime() / 1000));

  const formatValue = (value: number) => {
    if (value < 0) return '00';

    return value.toString().padStart(2, '0');
  };

  const seconds = computed(() => {
    const seconds = (endDateInMs.value - now.value) % 60;

    return formatValue(seconds);
  });

  const minutes = computed(() => {
    const minutes = Math.trunc((endDateInMs.value - now.value) / 60) % 60;

    return formatValue(minutes);
  });

  const hours = computed(() => {
    const hours = Math.trunc((endDateInMs.value - now.value) / 60 / 60) % 24;

    return formatValue(hours);
  });

  const days = computed(() => {
    const days = Math.trunc((endDateInMs.value - now.value) / 60 / 60 / 24);

    return formatValue(days);
  });

  const intervalId = ref<number | null>(null);

  const startInterval = () => {
    if (intervalId.value !== null) return;

    intervalId.value = window.setInterval(() => {
      now.value = setTimeInMs();
    }, 1000);
  };

  onMounted(() => {
    startInterval();
  });

  onUnmounted(() => {
    if (intervalId.value !== null) {
      clearInterval(intervalId.value);
      intervalId.value = null;
    }
  });

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};
