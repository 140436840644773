import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import { NON_UPGRADABLE_HOSTING_PLANS } from '@/data/globalConstants';
import { ordersRepo } from '@/repositories';
import type { IManagedOrderWithLimits, OrderWithLimits } from '@/types';
import { Hosting, STORE_PERSISTENT_KEYS } from '@/types';

export const useOrdersStore = defineStore(
  'ordersStore',
  () => {
    const isLoadingOrderListWithLimits = ref(false);
    const orderListWithLimits = ref<OrderWithLimits[] | null>(null);
    const isManagedOrdersLoading = ref(false);
    const managedOrders = ref<IManagedOrderWithLimits[]>([]);
    const websitesAwaitingRemoval = ref<string[]>([]);
    const isOrderReloadRequired = ref(false);

    const hasOrders = computed(
      () => (orderListWithLimits.value || []).length > 0,
    );

    const nonRemovingOrders = computed(
      () =>
        orderListWithLimits.value?.filter(
          ({ status }) => status !== Hosting.OrderStatus.REMOVING,
        ) || [],
    );

    const nonRemovingManagedAndOwnedOrders = computed(
      () =>
        managedAndOwnedOrdersWithLimits.value.filter(
          ({ status }) => status !== Hosting.OrderStatus.REMOVING,
        ) || [],
    );

    const upgradableOrders = computed(() =>
      nonRemovingOrders.value.filter(
        (order) =>
          !NON_UPGRADABLE_HOSTING_PLANS.includes(order.plan) && !!order.domain,
      ),
    );

    const hasManagedOrders = computed(() => managedOrders.value.length > 0);

    const hasAnyOrders = computed(
      () => hasOrders.value || hasManagedOrders.value,
    );

    const hasMultipleOrders = computed(
      () => (orderListWithLimits.value || []).length > 1,
    );

    const managedAndOwnedOrdersWithLimits = computed(() => [
      ...managedOrders.value,
      ...(orderListWithLimits.value || []),
    ]);

    const $reset = () => {
      isLoadingOrderListWithLimits.value = false;
      orderListWithLimits.value = null;
      isManagedOrdersLoading.value = false;
      managedOrders.value = [];
    };

    const getHasMultipleOrders = ({ showManaged }: { showManaged: boolean }) =>
      showManaged
        ? managedAndOwnedOrdersWithLimits.value.length > 1
        : hasMultipleOrders.value;

    const getHasMultipleNonRemovingOrders = ({
      showManaged,
    }: {
      showManaged: boolean;
    }) =>
      showManaged
        ? nonRemovingManagedAndOwnedOrders.value.length > 1
        : nonRemovingOrders.value.length > 1;

    const getOrdersWithLimits = async (
      skipLoader?: boolean,
      includeSuspended?: boolean,
    ) => {
      if (!skipLoader) isLoadingOrderListWithLimits.value = true;
      const [{ data }, error] = await ordersRepo.getListWithLimits(
        includeSuspended,
      );
      isLoadingOrderListWithLimits.value = false;

      if (!error) {
        orderListWithLimits.value = data;
      }
    };

    const setWebsiteRemovalInitiated = (domainToRemove: string) => {
      websitesAwaitingRemoval.value = [
        ...websitesAwaitingRemoval.value,
        domainToRemove,
      ];
    };

    const checkIsOrderReloadRequired = (removedDomain: string) => {
      isOrderReloadRequired.value = false;
      if (websitesAwaitingRemoval.value.includes(removedDomain)) {
        websitesAwaitingRemoval.value = websitesAwaitingRemoval.value.filter(
          (item) => item !== removedDomain,
        );

        isOrderReloadRequired.value = true;
      }
    };

    const getManagedOrdersWithLimits = async (includeSuspended?: boolean) => {
      isManagedOrdersLoading.value = true;

      const [{ data }, error] = await ordersRepo.getManagedOrders(
        includeSuspended,
      );

      isManagedOrdersLoading.value = false;

      if (!error) managedOrders.value = data;
    };

    const fetchManagedAndOwnedOrdersWithLimits = async (
      includeSuspended?: boolean,
    ) => {
      await Promise.all([
        getOrdersWithLimits(false, includeSuspended),
        getManagedOrdersWithLimits(includeSuspended),
      ]);
    };

    return {
      isLoadingOrderListWithLimits,
      isManagedOrdersLoading,
      hasOrders,
      hasMultipleOrders,
      managedAndOwnedOrdersWithLimits,
      hasAnyOrders,
      orderListWithLimits,
      managedOrders,
      hasManagedOrders,
      nonRemovingOrders,
      nonRemovingManagedAndOwnedOrders,
      upgradableOrders,
      isOrderReloadRequired,
      $reset,
      getOrdersWithLimits,
      getManagedOrdersWithLimits,
      fetchManagedAndOwnedOrdersWithLimits,
      getHasMultipleOrders,
      getHasMultipleNonRemovingOrders,
      setWebsiteRemovalInitiated,
      checkIsOrderReloadRequired,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.ORDERS_STORE },
  },
);
