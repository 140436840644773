import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { useAccessList, useProPanelRedirect } from '@/composables';
import { accessRepo } from '@/repositories';
import {
  useAccessManagerStore,
  useAccessStore,
  useAppStore,
  useHostingerProStore,
  useProfileStore,
  useWebsiteTagsStore,
} from '@/stores';
import type { CpanelLinkParams, QueryObject, RouteObjectType } from '@/types';
import { Cookie } from '@/types';
import { convertObjectToQuery } from '@/utils/helpers';
import { getJwtToken } from '@/utils/helpers/authHelpers';
import { appForcedLogout, setAuthToken } from '@/utils/services/authService';
import { clearUserData } from '@/utils/services/dataClearService';
import { errorLogger } from '@/utils/services/errorLogging';

export const useImpersonation = () => {
  const store = useStore();
  const appStore = useAppStore();
  const route = useRoute();
  const router = useRouter();
  const { getAccessIdByClientId } = useAccessList();
  const accessStore = useAccessStore();
  const { managedByMe } = storeToRefs(accessStore);
  const profileStore = useProfileStore();
  const websiteTagsStore = useWebsiteTagsStore();

  const accessManagerStore = useAccessManagerStore();
  const hostingerProStore = useHostingerProStore();
  const { handleProRedirect } = useProPanelRedirect();

  const linkToProfile = computed(() => {
    const link = `${process.env.VITE_CPANEL_BRANDLESS_URI}.${profileStore.account?.brand.domain}/access/exit`;
    const routeObject = route.meta.backArrowPath as RouteObjectType;

    if (accessManagerStore.redirectParams?.name) {
      const queryObject = {
        name: accessManagerStore.redirectParams?.name,
        params: accessManagerStore.redirectParams?.params,
      };
      const query = `?${convertObjectToQuery({ queryObject })}`;

      return `${link}${query}`;
    }
    if (routeObject?.isAccessRedirect) {
      const queryObject = {
        name: routeObject.name,
        params: routeObject.params,
      };
      const query = `?${convertObjectToQuery({ queryObject })}`;

      return `${link}${query}`;
    }

    return link;
  });
  const generateLinkToCpanel = ({ id, queryObject }: CpanelLinkParams) => {
    const query = queryObject
      ? `?${convertObjectToQuery({ queryObject })}`
      : '';

    return `${process.env.VITE_CPANEL_BRANDLESS_URI}.${
      profileStore.account?.brand.domain || ''
    }/access/manage/id/${id}${query}`;
  };

  const enterImpersonationMode = async ({
    accessId,
    queryObject = {},
    isSameRedirectRoute,
  }: {
    accessId?: number;
    queryObject?: QueryObject;
    isNewTabOpened?: boolean;
    isSameRedirectRoute?: boolean;
  }) => {
    if (!accessId) return;

    if (isSameRedirectRoute || !queryObject.redirectParams) {
      const currentRoute = route.name ?? undefined;
      queryObject.redirectParams = { name: currentRoute };
    }

    const queryVariable = convertObjectToQuery({
      queryObject,
    });

    const currentToken = getJwtToken();
    const [{ data }, error] = await accessRepo.postImpersonate(accessId);
    if (!data || error) return;

    localStorage.setItem(Cookie.ACCESS_MANAGER_TOKEN, currentToken as string);

    setAuthToken(data.token);

    clearUserData({ keepToken: true });
    store.commit('SET_REDIRECT', { showGlobalAppLoader: true });

    websiteTagsStore.clearTags();
    await appStore.initApp();

    if (queryVariable) await handleProRedirect(queryVariable);

    store.commit('SET_REDIRECT', { showGlobalAppLoader: false });
  };

  const exitImpersonationMode = async () => {
    clearUserData();

    const accessManagerToken = localStorage.getItem(
      Cookie.ACCESS_MANAGER_TOKEN,
    );
    if (!accessManagerToken) {
      errorLogger.logError('Access manager token is missing');
      await appForcedLogout();

      return;
    }
    setAuthToken(accessManagerToken);

    localStorage.removeItem(Cookie.ACCESS_MANAGER_TOKEN);

    await router.push({ path: '/' });

    store.commit('SET_REDIRECT', { showGlobalAppLoader: true });
    hostingerProStore.resetAllData();
    websiteTagsStore.clearTags();
    await appStore.initApp();

    accessManagerStore.redirectParams?.name
      ? await router.push(accessManagerStore.redirectParams)
      : await router.push({ path: '/' });

    store.commit('SET_REDIRECT', { showGlobalAppLoader: false });

    return;
  };

  const handleClientRedirect = async (
    clientId: string,
    routeObject: QueryObject,
  ) => {
    const isOwnService = profileStore.getIsSameClientId(clientId);
    if (isOwnService || !clientId) {
      await router.push(routeObject);

      return;
    }

    if (!managedByMe.value?.length) {
      await accessStore.fetchAccess();
    }

    await enterImpersonationMode({
      accessId: getAccessIdByClientId(clientId),
      queryObject: routeObject,
    });
  };

  return {
    enterImpersonationMode,
    exitImpersonationMode,
    generateLinkToCpanel,
    linkToProfile,
    convertObjectToQuery,
    handleClientRedirect,
  };
};
