import type { NavigationGuard } from 'vue-router';

import { useHDomainResourceStore } from '@/stores/domain/hDomainResourceStore';
import type { IHDomainResource } from '@/types';
import { HDomainResource, Route } from '@/types';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

const RESOURCE_TYPE_DOMAIN = 'domain';
const RESOURCE_STATUS_PENDING_SETUP = 'pending_setup';

const isPendingSetup = (
  resources: Array<IHDomainResource>,
  currentDomain: string | string[],
) =>
  resources.some(
    (resource) =>
      resource.resourceType === RESOURCE_TYPE_DOMAIN &&
      resource.status === RESOURCE_STATUS_PENDING_SETUP &&
      resource.title === currentDomain,
  );

const isPendingVerification = (
  resources: Array<IHDomainResource>,
  currentDomain: string,
) =>
  resources.some(
    (resource) =>
      resource.resourceType === HDomainResource.ResourceType.DOMAIN &&
      resource.status === HDomainResource.Status.PENDING_VERIFICATION &&
      resource.title === currentDomain,
  );

const isFailedSetup = (
  resources: Array<IHDomainResource>,
  currentDomain: string,
) =>
  resources.some(
    (resource) =>
      resource.resourceType === HDomainResource.ResourceType.DOMAIN &&
      resource.status === HDomainResource.Status.FAILED &&
      resource.title === currentDomain,
  );

const domainResourceRegisterGuard: NavigationGuard = async (to, from, next) => {
  // don't check if client has domain if it's meant to be claimed
  const isClaimable = to.query.isClaimable === '1';

  const { fetchHDomainsResources } = useHDomainResourceStore();

  const [{ data }, error] = await fetchHDomainsResources();

  const domain = to.params.domain as string;

  if (isClaimable) {
    return next();
  }

  if (isPendingVerification(data, domain)) {
    toastr.e(i18n.t('Domain is being reviewed'));

    return next({ name: Route.Domain.MY_DOMAINS, query: { forceLeave: '1' } });
  }

  if (
    error ||
    !data ||
    (!isPendingSetup(data, domain) && !isFailedSetup(data, domain))
  ) {
    toastr.e(i18n.t('Order not found'));

    return next({ name: 'my-domains', query: { forceLeave: '1' } });
  }

  next();
};
export default domainResourceRegisterGuard;
