import type { IChangePasswordRequest, IHasJWTToken } from '@/types';
import { hAsync } from '@/utils/helpers';
import { authHttp } from '@/utils/services/http/authHttpService';
import http from '@/utils/services/http/httpService';

export default {
  url: `${process.env.VITE_API_SSO}/v1/auth`,
  urlV2: `${process.env.VITE_API_H_SSO}/v1/auth`,

  /**
   * Invalidates user session.
   */
  logout() {
    return hAsync<void>(authHttp.post(`${this.urlV2}/logout`));
  },

  refresh() {
    return hAsync<IHasJWTToken>(authHttp.post(`${this.urlV2}/refresh`, null));
  },

  generateToken(targetApplication: string) {
    return hAsync<IHasJWTToken>(
      http.post(`${this.urlV2}/generate-token`, { targetApplication }),
    );
  },

  hashLogin(token: string) {
    return hAsync<void>(authHttp.post(`${this.urlV2}/hash/${token}`));
  },

  patchPassword(data: IChangePasswordRequest) {
    return hAsync<IHasJWTToken>(
      http.patch(`${this.urlV2}/password/update`, data, {
        withTokenUpdate: true,
        whitelistedStatusCodes: [400],
      }),
    );
  },
};
