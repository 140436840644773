import { computed, nextTick, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import BillingAddPaymentMethodModal from '@/components/Modals/HModal/Billing/BillingAddPaymentMethodModal.vue';
import GeneralModal from '@/components/Modals/HModal/GeneralModal.vue';
import {
  useGlobals,
  useAddWebsite,
  useHDomainState,
  usePaymentMethod,
  useModal,
  useSubscriptions,
  useDomainPurchase,
} from '@/composables';
import { autoRenewalInstantPaymentModal } from '@/data/autoRenewalInstantPaymentData';
import {
  useWebsitesStore,
  useHDomainResourceStore,
  useSubscriptionsStore,
} from '@/stores';
import {
  Hosting,
  Route,
  AmplitudeLocation,
  Email,
  AmplitudeEvent,
  BillingOrder,
} from '@/types';
import {
  getSldTld,
  toLocalTime,
  toUnicode,
  retryAsyncFunction,
} from '@/utils/helpers';
import { showAutorenewalEnabledToastr } from '@/utils/helpers/domainsHelper';
import { openRenewNowModal } from '@/utils/services/domains/hDomains';

type ParkedDomain = {
  domain: string;
  parkedDomain: string;
  mainDomain: string;
};
type Account = { domain: string; parkedDomains: ParkedDomain[] };

const isLoadingConnectHosting = ref(false);
const isEnablingAutoRenewal = ref(false);
const isAutoRenewToggleEnabled = ref(false);
const isRenewInitiated = ref(false);
const isRenewInProgress = ref(false);

export const useDomainOverview = (domain: string) => {
  const store = useStore();
  const router = useRouter();
  const { amplitudeV2, permissions } = useGlobals();
  const { isWebsiteBeingRemoved } = useWebsitesStore();
  const { createOrMigrateWebsite, setPreselectedDomain } = useAddWebsite();
  const { isActive, isSuspended, isInRedemptionPeriod } = useHDomainState();
  const { defaultPaymentMethod } = usePaymentMethod();
  const { openRecreateDomainFromRedemptionModal } = useDomainPurchase();
  const { openModal } = useModal();
  const {
    isSubscriptionAutoRenewalEnabled,
    fetchSubscriptionsForceSilently,
    enableAutoRenew,
    openHBillingPayInvoiceModal,
  } = useSubscriptions();
  const { getHDomainResourceByDomain, fetchHDomainsResource } =
    useHDomainResourceStore();
  const {
    getDomainSubscriptionByProperty,
    hasSubscriptionPendingInvoice,
    fetchSubscriptionPaymentDueInvoices,
  } = useSubscriptionsStore();
  const route = useRoute();

  const domainEncoded = toUnicode(domain);

  const hDomainResource = computed(() => getHDomainResourceByDomain(domain)!);

  const subscription = computed(() =>
    getDomainSubscriptionByProperty(route.params),
  );

  const subscriptionId = computed(() => subscription.value?.id || '');

  const isDomainInRedemptionPeriod = computed(() =>
    isInRedemptionPeriod(hDomainResource.value!),
  );

  const hasDomainSubscriptionPendingInvoice = computed(() =>
    hasSubscriptionPendingInvoice(subscriptionId.value),
  );

  const connectedWebsite = computed(() => {
    if (isWebsiteBeingRemoved(domain)) {
      return null;
    }

    return (
      store.getters.getAllAccounts?.find(
        (account: Account) => account.domain === domain,
      ) ||
      store.getters.isDomainParked(domain) ||
      null
    );
  });

  const hasMailService = computed(
    () => !!hostingerMailService.value || !!titanMailService.value,
  );

  const hasBothEmailTypeServices = computed(
    () => !!hostingerMailService.value && !!titanMailService.value,
  );

  const hostingerMailService = computed(() =>
    getPremiumMail(Email.ProEmailProvider.HOSTINGER, domain),
  );

  const titanMailService = computed(() =>
    getPremiumMail(Email.ProEmailProvider.TITAN, domain),
  );

  const isAutoRenewalEnabled = computed(() => {
    if (!subscription.value) {
      return false;
    }

    return isSubscriptionAutoRenewalEnabled(subscription.value);
  });

  const expiresAt = computed(() => {
    const expiresAt = hDomainResource.value?.expiresAt ?? '';

    return toLocalTime(expiresAt);
  });

  const resetDomainOverviewState = () => {
    isLoadingConnectHosting.value = false;
    isEnablingAutoRenewal.value = false;
    isAutoRenewToggleEnabled.value = false;
    isRenewInitiated.value = false;
    isRenewInProgress.value = false;
  };

  const goToHostingManagement = () => {
    const isBuilderType =
      connectedWebsite.value?.type === Hosting.AccountType.BUILDER;

    router.push({
      name: isBuilderType
        ? Route.Websites.BUILDER_DASHBOARD
        : Route.Websites.WEBSITE_DASHBOARD,
      params: {
        domain: store.getters.getParkedMainDomain(domain) || domain,
      },
    });
  };

  const getPremiumMail = (provider: Email.ProEmailProvider, domain: string) =>
    store.getters['emails/getPremiumMail'](provider, domain);

  const goGetHosting = async () => {
    isLoadingConnectHosting.value = true;

    setPreselectedDomain(domain);
    await createOrMigrateWebsite({
      amplitudeLocation: AmplitudeLocation.Base.DASHBOARD_DOMAIN,
    });

    isLoadingConnectHosting.value = false;
  };

  const goToMailManagement = () => {
    if (hasBothEmailTypeServices.value) {
      router.push({ name: Route.Email.EMAILS });

      return;
    }

    const isHostingerEmail = !!hostingerMailService.value;

    amplitudeV2(AmplitudeEvent.Email.MANAGEMENT_ENTERED, {
      product: isHostingerEmail ? 'hostinger_email' : 'titan_email',
      location: AmplitudeLocation.Base.DASHBOARD_DOMAIN,
    });

    router.push({
      name: isHostingerEmail
        ? Route.Email.HMAIL_MANAGEMENT
        : Route.Email.TITAN_MANAGEMENT,
      params: {
        domain,
      },
      query: {
        source: Route.Domain.DOMAIN_MANAGEMENT,
      },
    });
  };

  const isAutoRenewAvailable = computed(() => {
    const hasPurchaseAccess = permissions.userHasPurchaseAccess();

    return (
      hasPurchaseAccess &&
      (isActive(hDomainResource.value) || isSuspended(hDomainResource.value))
    );
  });

  const handleAutoRenewClick = async (isAutoRenewOnClicked: boolean) => {
    isAutoRenewToggleEnabled.value = isAutoRenewOnClicked;

    if (!isAutoRenewOnClicked) {
      goToDisableAutoRenewal();

      return;
    }

    dispatchAutoRenewAmplitude();
    attemptToEnableAutoRenewal();
  };

  const attemptToEnableAutoRenewal = async () => {
    if (!defaultPaymentMethod.value) {
      openModal({
        component: { BillingAddPaymentMethodModal },
      });

      await nextTick();
      isAutoRenewToggleEnabled.value = false;

      return;
    }

    if (subscription.value?.chargedImmediatelyOnAutoRenew) {
      const { title, subtitle, confirmActionLabel } =
        autoRenewalInstantPaymentModal;

      openModal({
        component: { GeneralModal },
        data: {
          title,
          subtitle,
          confirmActionLabel,
          onConfirmAction: () => {
            enableAutoRenewal();
          },
        },
      });

      return;
    }

    await enableAutoRenewal();
  };

  const goToDisableAutoRenewal = () => {
    const data = {
      item: {
        order_id: subscriptionId.value,
      },
      service: BillingOrder.ServiceName.DOMAIN,
      serviceName: domainEncoded,
      expirationDate: expiresAt.value,
      redirectDetails: {
        name: Route.Domain.DOMAIN_MANAGEMENT,
        params: {
          domain,
          subscriptionId: subscriptionId.value,
        },
      },
      initializedFromRouteName: route.name,
      hideEnableAutorenewal: true,
    };
    store.commit('billing/SET_SELECTED_TO_DISABLE_RENEW', data);
    router.push({ name: Route.Billing.DISABLE_AUTO_RENEWAL });
  };

  const dispatchAutoRenewAmplitude = () => {
    const [sld, tld] = getSldTld(domainEncoded, { omitDot: true });
    amplitudeV2(AmplitudeEvent.Domain.AUTO_RENEW_TOGGLE_ON, {
      location: AmplitudeLocation.Base.DASHBOARD,
      sld,
      tld,
    });
  };

  const enableAutoRenewal = async () => {
    isEnablingAutoRenewal.value = true;
    const domainName = domainEncoded;

    const [, err] = await enableAutoRenew(subscriptionId.value);

    if (err) {
      isEnablingAutoRenewal.value = false;

      return;
    }

    isAutoRenewToggleEnabled.value = true;
    isEnablingAutoRenewal.value = false;
    showAutorenewalEnabledToastr(domainName);
    fetchSubscriptionsForceSilently();
  };

  const handleRenewNowClick = (
    amplitudeSource: string = AmplitudeLocation.Base.DASHBOARD_DOMAIN,
  ) => {
    if (isDomainInRedemptionPeriod.value) {
      openRecreateDomainFromRedemptionModal({
        domain: domainEncoded,
        subscriptionId: subscriptionId.value,
        amplitudeSource,
        onSuccess: onRenewSuccess,
      });

      return;
    }

    if (
      subscription.value &&
      (subscription.value.canReactivate ||
        !hasDomainSubscriptionPendingInvoice.value)
    ) {
      openRenewNowModal({
        domainName: domainEncoded,
        subscription: subscription.value,
        amplitudeSource,
        onSuccess: onRenewSuccess,
      });

      return;
    }

    openHBillingPayInvoiceModal(subscriptionId.value, () => {
      onRenewSuccess();
      fetchSubscriptionPaymentDueInvoices();
    });
  };

  const onRenewSuccess = () => {
    fetchHDomainResourceUntilUpdated();
    fetchSubscriptionsForceSilently();
    isRenewInitiated.value = true;
  };

  const fetchHDomainResourceUntilUpdated = () => {
    if (!hDomainResource.value) return;

    isRenewInProgress.value = true;

    const originalExpiresAt = hDomainResource.value.expiresAt;
    let isUpdated = false;

    retryAsyncFunction({
      triesCount: 20,
      timeoutInMilliseconds: 1000,
      getIsRetryNeeded: () => !isUpdated,
      functionToRetry: async () => {
        await fetchHDomainsResource(hDomainResource.value.id);
        isUpdated = originalExpiresAt !== hDomainResource.value?.expiresAt;
        isRenewInProgress.value = !isUpdated;
      },
    });
  };

  return {
    connectedWebsite,
    goToHostingManagement,
    goGetHosting,
    isLoadingConnectHosting,
    hasMailService,
    hostingerMailService,
    titanMailService,
    goToMailManagement,
    isAutoRenewAvailable,
    hDomainResource,
    handleAutoRenewClick,
    isAutoRenewalEnabled,
    subscriptionId,
    subscription,
    isEnablingAutoRenewal,
    attemptToEnableAutoRenewal,
    isAutoRenewToggleEnabled,
    handleRenewNowClick,
    isRenewInitiated,
    isRenewInProgress,
    resetDomainOverviewState,
  };
};
