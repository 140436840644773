import type { SaleConfiguration } from '@/types';
import { Client, Cookie, Route, Sale } from '@/types';

export const SALES_CONFIGURATION: SaleConfiguration[] = [
  {
    name: Sale.SEPTEMBER_SALE,
    activeResellers: [
      Client.BrandId.Hostinger.COM,
      Client.BrandId.Hostinger.IN,
      Client.BrandId.Hostinger.COM_BR,
      Client.BrandId.Hostinger.FR,
      Client.BrandId.Hostinger.ES,
      Client.BrandId.Hostinger.CO_UK,
      Client.BrandId.Hostinger.MX,
      Client.BrandId.Hostinger.CO,
    ],
    startsAt: '2023-09-06T21:00:00Z',
    endsAt: '2023-09-13T21:00:00Z',
    showTimer: true,
    timerStartsAt: '2023-09-06T21:00:00Z',
    timerEndsAt: '2023-09-13T21:00:00Z',
    offerRouteName: Route.Sale.SEPTEMBER_SALE,
  },
  {
    name: Sale.MONSOON_SALE,
    activeResellers: [Client.BrandId.Hostinger.IN],
    startsAt: '2023-07-03T07:00:00Z',
    endsAt: '2023-07-16T07:00:00Z',
    showTimer: true,
    timerStartsAt: '2023-07-03T07:00:00Z',
    timerEndsAt: '2023-07-16T07:00:00Z',
    offerRouteName: Route.Sale.MONSOON_SALE,
  },
  {
    name: Sale.INDEPENDENCE_DAY,
    activeResellers: [Client.BrandId.Hostinger.COM],
    startsAt: '2023-07-03T07:00:00Z',
    endsAt: '2023-07-16T07:00:00Z',
    showTimer: true,
    timerStartsAt: '2023-07-03T07:00:00Z',
    timerEndsAt: '2023-07-16T07:00:00Z',
    offerRouteName: Route.Sale.INDEPENDENCE_DAY,
  },
  {
    name: Sale.SUMMER_SALE,
    activeResellers: [
      Client.BrandId.Hostinger.COM,
      Client.BrandId.Hostinger.IN,
      Client.BrandId.Hostinger.COM_BR,
      Client.BrandId.Hostinger.FR,
      Client.BrandId.Hostinger.ES,
      Client.BrandId.Hostinger.CO_UK,
      Client.BrandId.Hostinger.MX,
      Client.BrandId.Hostinger.CO,
    ],
    startsAt: '2024-07-01T07:00:00Z',
    endsAt: '2024-07-14T07:00:00Z',
    showTimer: true,
    timerStartsAt: '2024-07-01T07:00:00Z',
    timerEndsAt: '2024-07-14T07:00:00Z',
    offerRouteName: Route.Sale.SUMMER_SALE,
    activationCookie: Cookie.SHOW_SUMMER_SALE_CAMPAIGN,
  },
  {
    name: Sale.BLACK_FRIDAY,
    activeResellers: [],
    startsAt: '2023-10-30T08:00:00Z',
    endsAt: '2023-11-26T22:00:00Z',
    showTimer: true,
    timerStartsAt: '2023-11-13T08:00:00Z',
    timerEndsAt: '2023-11-26T22:00:00Z',
    offerRouteName: Route.Sale.BLACK_FRIDAY,
    activationCookie: Cookie.SHOW_BLACK_FRIDAY_CAMPAIGN,
  },
  {
    name: Sale.CYBER_WEEK,
    activeResellers: [],
    startsAt: '2023-11-27T08:00:00Z',
    endsAt: '2023-12-03T22:00:00Z',
    showTimer: true,
    timerStartsAt: '2023-11-27T08:00:00Z',
    timerEndsAt: '2023-12-03T22:00:00Z',
    offerRouteName: Route.Sale.BLACK_FRIDAY,
    activationCookie: Cookie.SHOW_CYBER_WEEK_CAMPAIGN,
  },
  {
    name: Sale.NEW_YEAR,
    activeResellers: [],
    startsAt: '2024-01-02T08:00:00Z',
    endsAt: '2024-02-12T08:00:00Z',
    showTimer: true,
    timerStartsAt: '2024-01-29T08:00:00Z',
    timerEndsAt: '2024-02-12T08:00:00Z',
    offerRouteName: Route.Sale.NEW_YEAR,
    activationCookie: Cookie.SHOW_NEW_YEAR_CAMPAIGN,
  },
];
