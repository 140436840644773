import dayjs from 'dayjs';

import BillingUnableAutoRenewModal from '@/components/Modals/HModal/Billing/BillingUnableAutoRenewModal.vue';
import DomainNsFlowResetModal from '@/components/Modals/HModal/DomainNsFlowResetModal.vue';
import ChangeSldModal from '@/components/Modals/HModal/HDomains/ChangeSldModal.vue';
import SuspiciousDomainModal from '@/components/Modals/HModal/HDomains/SuspiciousDomainModal.vue';
import RenewModal from '@/components/Modals/HModal/Purchase/RenewModal.vue';
import { useGlobals, useModal, useResourceMapper } from '@/composables';
import { amplitudeV2 } from '@/plugins/amplitudeV2';
import { hDomainsRepo } from '@/repositories';
import { router } from '@/router';
import store from '@/store/index';
import {
  useDomainManagementStore,
  useResourcesStore,
  useHDomainResourceStore,
} from '@/stores';
import type {
  BillingSubscription,
  IHResource,
  MonolithResourceStatus,
  ProfileWhoIsDetails,
  WhoIsProfile,
} from '@/types';
import {
  MONOLITH_RESOURCE_STATUS,
  Route,
  SubscriptionName,
  SubscriptionResourceType,
  SubscriptionStatus,
  Service,
  HRESOURCES_TYPE,
  HDomains,
  HDomainResource,
  HRESOURCES_STATE,
} from '@/types';
import { toASCII, toUnicode, getDomainParts, getSldTld } from '@/utils/helpers';
import { splitDomainName } from '@/utils/helpers/domainsHelper';
import {
  dispatchChangeSldModalShownAmplitudeEvent,
  dispatchChangeSldModalSuccessAmplitudeEvent,
} from '@/utils/helpers/hDomains/hDomainsAmplitudeHelper';
import { hToastrService as toastr } from '@/utils/services/hToastrService';
import { i18n } from '@/utils/services/i18nService';

export const whoisProfileToString = (profile: WhoIsProfile) => {
  // Constant HDomains WHOIS Profile Slugs
  const slugs = [
    'firstName',
    'lastName',
    'email',
    'companyName',
    'address',
    'city',
    'countryCode',
    'phoneCc',
    'phoneNumber',
  ];

  const d = {} as ProfileWhoIsDetails;

  Object.values(profile.whoisDetails).forEach((info) => {
    Object.assign(d, { [info.slug]: info.value });
  });

  let whoisProfileString = `${d.firstName || ''} ${d.lastName || ''}, ${
    d.email || ''
  },
     +${d.phoneCc || ''}${d.phoneNumber || ''}, `;

  whoisProfileString +=
    profile.entityType === 'individual' ? '' : `${d.companyName || ''}, `;

  whoisProfileString += `${d.address || ''}, ${d.city || ''}, ${
    d.countryCode || ''
  }`;

  const hasMoreValues = (details: ProfileWhoIsDetails) =>
    Object.values(details).find(
      ({ slug, value }) => slugs.indexOf(slug) < 0 && !!value,
    );

  const addMoreValues = (details: ProfileWhoIsDetails) =>
    Object.values(details)
      .filter(({ slug, value }) => slugs.indexOf(slug) < 0 && !!value)
      .map(({ value }) => value)
      ?.join(', ');

  if (hasMoreValues(profile.whoisDetails)) whoisProfileString += ', ';

  whoisProfileString += addMoreValues(profile.whoisDetails);

  return whoisProfileString;
};

export const pendingOrderSetup = (data: {
  registrar: string;
  id: number;
  domainTld: string;
  domainSld: string;
  links: { name: string; link: string }[];
  allowRegister: boolean;
  allowTransfer: boolean;
  service: string;
  serviceName: string;
}) => {
  const params = {
    registrar: data.registrar,
    order_id: data.id,
    domain_tld: data.domainTld,
    domain_sld: data.domainSld,
    link: data.links?.find(({ name }) => name === 'setup')?.link || null,
  };
  if (data.allowRegister) {
    toastr.e(i18n.t('Setup for this domain cannot be started'));
  } else if (data.allowTransfer) {
    store.commit('SET_REDIRECT', {
      ...params,
    });

    router.push({
      name: 'transfer',
      params: {
        ...params,
        domain: `${data.domainSld}${data.domainTld}`,
        subscriptionId: params.order_id,
      },
    });
  } else {
    if (data.service === 'domain' || data.serviceName === 'domain') {
      openChangeSldModal(data as unknown as string);
    }
  }
};

const getDomainName = (orderOrResource: {
  domain?: string;
  hostname?: string;
  domainSld?: string;
  domainTld?: string;
  title?: string;
}) => {
  const domainWithSldTld =
    (orderOrResource?.domainSld || '') + (orderOrResource?.domainTld || '');

  return (
    orderOrResource.domain ||
    orderOrResource.hostname ||
    domainWithSldTld ||
    orderOrResource.title ||
    ''
  );
};
export const handleDomainResourceSetup = async (
  objectOrString: Record<string, any> | string,
  preventChangeSld?: boolean,
  redirectLink?: string,
) => {
  const domain =
    typeof objectOrString === 'string'
      ? objectOrString
      : getDomainName(objectOrString);
  const [sld, tld] = getSldTld(domain, { omitDot: true });

  const [{ data }, err] = await hDomainsRepo.validateRegistration({
    domain: sld,
    tld,
    shouldCheckSuspicious: true,
  });

  if (!data || err) return;

  const isDomainAvailable = data.isAvailable;
  const isDomainSuspicious = data.isSuspicious;

  if ((!isDomainAvailable || isDomainSuspicious) && preventChangeSld) return;

  if (!isDomainAvailable) {
    const { id: subscriptionId } = getAssociatedDomainMappedData(domain) || {};

    openChangeSldModal(domain, {
      onOpened: () =>
        dispatchChangeSldModalShownAmplitudeEvent(amplitudeV2, {
          domain,
          subscriptionId: subscriptionId as string,
        }),
      callback: (newDomain) =>
        dispatchChangeSldModalSuccessAmplitudeEvent(amplitudeV2, {
          newDomain,
          previousDomain: domain,
          subscriptionId: subscriptionId as string,
        }),
    });

    return;
  }

  if (isDomainSuspicious) {
    const { openModal } = useModal();

    openModal({
      component: { SuspiciousDomainModal },
      data: {
        domain,
      },
    });

    return;
  }

  if (redirectLink) {
    window.open(redirectLink, '_self');

    return;
  }
  router.push({
    name: Route.Domain.REGISTER_DOMAIN_RESOURCE,
    params: { domain },
  });
};

export const checkDomainAvailability = async (domain: string) => {
  const [sld, tld] = splitDomainName(domain);

  const [{ data }, err] = await hDomainsRepo.validateRegistration({
    domain: sld,
    tld,
  });

  const punycodeDomain = toASCII(domain);

  return [!!data[punycodeDomain as keyof typeof data], err];
};

export const convertResourceToOrder = (resource: IHResource) => {
  const domainParts = getDomainParts(resource.title || '');

  let ctaLink = `/domain/${resource.title}`;
  let ctaText = 'manage';
  let hostname = resource.title;
  let status = resource.state as MonolithResourceStatus;

  if (resource.state === SubscriptionStatus.PENDING_SETUP) {
    ctaLink = `/register-domain-resource/${resource.title}`;
    ctaText = 'setup';
    hostname = '';
    status = MONOLITH_RESOURCE_STATUS.PENDING_DATA_ENTRY;
  }

  return {
    isResource: true,
    allowRegister: true,
    registeredAt: resource?.createdAt,
    ctaLink,
    ctaText,
    domainSld: domainParts[0],
    domainTld: domainParts[1],
    expiration: resource.expiresAt,
    hostname,
    hresourceId: null,
    id: null,
    registrar: 'hdomains',
    serviceId: null,
    domain: resource.title,
    serviceName:
      resource.type === SubscriptionResourceType.DOMAIN_TRANSFER.toLowerCase()
        ? SubscriptionResourceType.DOMAIN_TRANSFER.toLowerCase()
        : SubscriptionResourceType.DOMAIN,
    setupMesage: 'Pending setup',
    status,
    title: `${
      resource.type === SubscriptionResourceType.DOMAIN_TRANSFER.toLowerCase()
        ? SubscriptionName.DOMAIN_TRANSFER
        : SubscriptionName.DOMAIN_REGISTRATION
    } - ${resource.title}`,
    isConvertedFromResource: true,
    allowTransfer: true,
    chargebeeSubscriptionId: resource.chargebeeSubscriptionId,
  };
};

const getAssociatedDomainMappedData = (domain: string) => {
  const { allActiveMonolithOrders, mappedHomepageOrders } = useResourceMapper();

  const mappedData = [
    ...mappedHomepageOrders.value.pendingOrders,
    ...allActiveMonolithOrders.value,
  ];

  return mappedData?.find(({ serviceName, hostname, status }) => {
    const isCorrectDomain = domain === hostname;
    const isDomainService = serviceName === HRESOURCES_TYPE.DOMAIN;
    const isEligibleStatus = (
      [
        MONOLITH_RESOURCE_STATUS.PENDING_DATA_ENTRY,
        MONOLITH_RESOURCE_STATUS.FAILED,
      ] as MonolithResourceStatus[]
    ).includes(status);

    return isCorrectDomain && isDomainService && isEligibleStatus;
  });
};

export const openChangeSldModal = (
  domain: string,
  config?: {
    headerText?: string;
    backButton?: {
      text: string;
      action: () => void;
    };
    onOpened?: () => void;
    callback?: (domain: string) => void;
  },
) => {
  const { getDomainResourceByDomainAndStates } = useResourcesStore();
  const { getHDomainResourceByDomainAndStatus } = useHDomainResourceStore();

  const hDomainResource = getHDomainResourceByDomainAndStatus(
    domain,
    HDomainResource.Status.PENDING_SETUP,
  );
  const domainHResource = getDomainResourceByDomainAndStates(domain, [
    HRESOURCES_STATE.PENDING,
    HRESOURCES_STATE.FAILED,
  ]);

  const hResourceId =
    hDomainResource?.additionalDetails?.hresourceId || domainHResource?.id;

  const { openModal } = useModal();

  if (!hResourceId) return;

  openModal({
    component: { ChangeSldModal },
    data: {
      domain,
      headerText: config?.headerText || '',
      hResourceId,
      onOpened: config?.onOpened,
      onSuccess: (domain: string) => {
        handleDomainResourceSetup(domain);
        config?.callback?.(domain);
      },
    },
    steps: [
      {
        hideX: true,
        goBackText: config?.backButton?.text,
        goBackCustom: config?.backButton?.action,
      },
    ],
  });
};

export const getAllowedDomainCharCount = (domain: string) =>
  domain?.endsWith('.br') ? 26 : 63;

export const doesDomainExpireInTwoDays = (timestamp: number) => {
  const DAY = 86400;
  const EXPIRES_AT = timestamp;
  const NOW = dayjs().unix();

  return EXPIRES_AT - NOW < DAY * 2;
};

export const canDomainAutoRenewOneClick = () =>
  store.getters['billing/getActivePaymentMethods'] ||
  store.getters['paymentMethods/getDefaultPaymentMethod'];

export const openUnableAutoRenewDomainModal = (
  domain: string,
  onSuccess: () => {},
) => {
  const { openModal } = useModal();

  openModal({
    component: { BillingUnableAutoRenewModal },
    data: {
      domain: toUnicode(domain),
      onSuccess,
    },
  });
};

export const openRenewNowModal = ({
  domainName,
  subscription,
  onSuccess,
  amplitudeSource,
}: {
  domainName: string;
  subscription: BillingSubscription;
  onSuccess: Function;
  amplitudeSource?: string;
}) => {
  const domain = toUnicode(domainName);
  const { openModal } = useModal();

  openModal({
    component: { RenewModal },
    data: {
      title: i18n
        .t('Renew your Domain Registration - {domain}', {
          domain,
        })
        .replace('{domain}', domain),
      subtitle: i18n.t('Review your selected invoice and proceed to checkout'),
      itemPriceId: subscription.items[0].itemPriceId,
      itemId: subscription.items[0].itemId,
      subscriptionId: subscription.id,
      redirect: `${window.origin}`,
      reactivate: subscription.canReactivate,
      quantity: 1,
      serviceType: Service.Type.DOMAIN,
      service: domain,
      onSuccess,
      amplitudeSource,
    },
    steps: [
      {
        hideX: true,
      },
    ],
  });
};

export const openAutoConnectModal = (
  domainName: string,
  onConfirmAction: () => Promise<void>,
) => {
  const domain = toUnicode(domainName);
  const { openModal, closeModal } = useModal();
  const { updateNameservers } = useDomainManagementStore();
  const { t } = useGlobals();

  const nameservers = [
    HDomains.HostingerNameservers.NS1,
    HDomains.HostingerNameservers.NS2,
  ];

  const changeDomainNameservers = async () => {
    closeModal();
    const [, error] = await updateNameservers({
      domain,
      nameservers,
    });
    if (!error) {
      toastr.s(t('Nameservers has changed successfully'), {
        html: t(
          'It might take up to 24 hours for the domain to start working properly',
        ),
      });
    }
    await onConfirmAction?.();
  };

  openModal({
    component: { DomainNsFlowResetModal },
    data: {
      hostingerNameservers: nameservers,
      onConfirmAction: changeDomainNameservers,
    },
  });
};
