import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { hVpsRepo } from '@/repositories';
import { useVpsServerStore } from '@/stores';
import type { ServerOS } from '@/types';
import { HVps, STORE_PERSISTENT_KEYS } from '@/types';

export const useVpsTemplateStore = defineStore(
  'vpsTemplateStore',
  () => {
    const serverStore = useVpsServerStore();

    const templates = ref<ServerOS[]>([]);

    const GROUP_OS_NAMES = {
      ALMA_LINUX: 'AlmaLinux',
      CENTOS: 'CentOS',
      CLOUD_LINUX: 'CloudLinux',
      DEBIAN: 'Debian',
      FEDORA: 'Fedora Cloud',
      OPENSUSE: 'openSUSE',
      ROCKY_LINUX: 'Rocky Linux',
      UBUNTU: 'Ubuntu',
    } as const;

    const setTemplates = (data: ServerOS[]) => {
      templates.value = data.sort((a, b) => a.label.localeCompare(b.label));
    };

    const getTemplate = (templateId: number) =>
      templates.value.find(({ id }) => id === templateId) || ({} as ServerOS);

    const currentServerTemplate = computed(
      () => serverStore.currentServer.template || {},
    );

    const isCurrentTemplateLicensed = computed(() => {
      const templateType =
        currentServerTemplate.value?.panelType?.toLowerCase();
      const licenseType =
        serverStore.currentServer.license?.panelType?.toLowerCase();

      return licenseType && licenseType === templateType;
    });

    const getTemplatesByType = (type: string): ServerOS[] =>
      templates.value.filter(
        ({ group, providerCode }) =>
          group === type &&
          providerCode === serverStore.currentServer.providerCode,
      );

    const getTemplatesByTypeAndKeyword = (type: string, keyword: string) =>
      templates.value.filter(
        ({ group, label, providerCode }) =>
          group === type &&
          providerCode === serverStore.currentServer.providerCode &&
          label.toLowerCase().includes(keyword.toLowerCase()),
      );

    const sortTemplatesByLabelDesc = (a: ServerOS, b: ServerOS) =>
      b.label.localeCompare(a.label);

    const getGroupedTemplates = (type: HVps.OsGroup, keyword?: string) => {
      const templates = getTemplatesByTypeAndKeyword(type, keyword || '');

      return templates.reduce((acc, os) => {
        if (type !== HVps.OsGroup.PLAIN_OS) {
          return {
            ...acc,
            [os.label]: [...(acc[os.label] || []), os],
          };
        }

        const osLabel = os.label;
        const osLabelKey = Object.values(GROUP_OS_NAMES).find((osName) =>
          osLabel.includes(osName),
        );

        if (osLabelKey) {
          return {
            ...acc,
            [osLabelKey]: [...(acc[osLabelKey] || []), os].sort(
              sortTemplatesByLabelDesc,
            ),
          };
        }

        return {
          ...acc,
          [osLabel]: [...(acc[osLabel] || []), os].sort(
            sortTemplatesByLabelDesc,
          ),
        };
      }, {} as Record<string, ServerOS[]>);
    };

    const fetchTemplates = async () => {
      const [{ data }, error] = await hVpsRepo.getTemplates();

      if (!error) setTemplates(data);

      return [{ data }, error];
    };

    return {
      templates,
      setTemplates,
      getTemplate,
      currentServerTemplate,
      isCurrentTemplateLicensed,
      getTemplatesByType,
      getTemplatesByTypeAndKeyword,
      getGroupedTemplates,
      fetchTemplates,
    };
  },
  {
    persist: { key: STORE_PERSISTENT_KEYS.VPS_TEMPLATE },
  },
);
