import psl, { type ParsedDomain } from 'psl';
import { computed } from 'vue';

import { useResourceMapper } from '@/composables';
import type { HResourceState } from '@/types';
import {
  HRESOURCES_STATE,
  HRESOURCE_ITEM_STATE,
  HRESOURCE_ITEM_TYPE,
} from '@/types';

const UNAVAILABLE_DOMAIN_STATUSES: HResourceState[] = [
  HRESOURCES_STATE.EXPIRED,
  HRESOURCES_STATE.FAILED,
];

export const useDomainResourceMapper = () => {
  const { getResourcesByTypesAndStates, activeHostingResourceItems } =
    useResourceMapper();

  const ownedDomains = computed(() => {
    const domainResources = getResourcesByTypesAndStates({
      types: ['domain'],
      states: ['active', 'pending_setup', 'suspended'],
    });
    const cpanelHostingResources = getResourcesByTypesAndStates({
      types: ['cpanel_hosting', 'cpanel_reseller_hosting'],
    });
    const hostingResources = getResourcesByTypesAndStates({
      types: ['hosting'],
    });
    const hostingDomains: string[] = [];

    const activeDomains = domainResources.filter(
      ({ state }) => !UNAVAILABLE_DOMAIN_STATUSES.includes(state),
    );
    const cpanelDomains = cpanelHostingResources.map(({ title }) => title);

    hostingResources.forEach((hosting) => {
      hosting.items.forEach(({ type, state, domain }) => {
        if (
          type === HRESOURCE_ITEM_TYPE.WEBSITE &&
          state === HRESOURCE_ITEM_STATE.ACTIVE
        ) {
          hostingDomains.push(domain);
        }
      });
    });

    return activeDomains.filter(
      ({ title }) => ![...hostingDomains, ...cpanelDomains].includes(title),
    );
  });

  const activeHostingDomains = computed(() => {
    const PREVIEW_DOMAIN_URLS = [
      process.env.VITE_BUILDER_PREVIEW_URL!,
      process.env.VITE_WORDPRESS_PREVIEW_URL!,
    ];

    const activeHostingWebsiteDomains = activeHostingResourceItems.value
      .map((resource) => resource.domain)
      .filter((domain) => {
        const parsedDomain = psl.parse(domain) as ParsedDomain;
        const hasSubdomain = !!parsedDomain.subdomain;

        return !hasSubdomain;
      })
      .filter(
        (domain) =>
          domain && !PREVIEW_DOMAIN_URLS.some((url) => domain.includes(url)),
      );

    return activeHostingWebsiteDomains;
  });

  const getDomainHasActiveSubdomain = (domain: string) =>
    activeHostingResourceItems.value.some(
      (resource) =>
        resource.domain.includes(domain) &&
        resource.state === 'active' &&
        resource.type === 'subdomain',
    );

  const latestActiveHostingDomain = computed(() => {
    const length = activeHostingDomains.value.length;
    const lastWebsiteDomain = activeHostingDomains.value[length - 1];

    return lastWebsiteDomain || '';
  });

  const freeDomains = computed(() =>
    getResourcesByTypesAndStates({
      types: ['free_domain'],
      states: ['pending_setup'],
    }),
  );

  const freeDomainTlds = computed(() =>
    freeDomains.value.flatMap(({ config }) =>
      (config?.tlds || []).map((tld) => tld),
    ),
  );

  const hasFreeDomainTlds = computed(() => freeDomainTlds.value.length);

  return {
    latestActiveHostingDomain,
    activeHostingDomains,
    ownedDomains,
    freeDomains,
    freeDomainTlds,
    hasFreeDomainTlds,
    getDomainHasActiveSubdomain,
  };
};
