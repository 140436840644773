import { NEXT_BEST_ACTION, hDomainsStatus } from '@hostinger/hdomains-status';
import type {
  IDomainStatusResult,
  IDomainStatusInput,
  NextBestAction,
} from '@hostinger/hdomains-status';
import { defineStore } from 'pinia';
import { parse, type ParsedDomain } from 'psl';
import { computed, ref } from 'vue';

import { useGlobals } from '@/composables';
import { ONE_MINUTE_IN_MS } from '@/data/globalConstants';
import { STORE_PERSISTENT_KEYS, type IDomainStatusInputQueue } from '@/types';

const DEFAULT_TIME_BEFORE_REFRESH_MINS = 5;
const SHORT_TIME_BEFORE_REFRESH_MINS = 1;
const TIME_TO_WAIT_UNTIL_NEXT_REQUEST = 3000;
const PERSISTED_DATA = ['domainStatusMap'];

export const useDomainsStatusStore = defineStore(
  'domainsStatusStore',
  () => {
    const isLoading = ref(false);
    const domainStatusMap = ref<
      Record<string, { status: IDomainStatusResult; updatedAt: string }>
    >({});
    const { t, toastr } = useGlobals();
    const domainStatusRequestQueue = ref<IDomainStatusInputQueue[]>([]);
    const isQueueRunning = ref(false);
    const domainLoadingMap = ref<Record<string, boolean>>({});
    const currentRateLimit = ref(0);

    const getRefreshTime = (domain: string) => {
      const domainStatusResult = getDomainStatus(domain);
      let refreshTime = DEFAULT_TIME_BEFORE_REFRESH_MINS;
      const shortTimeList: NextBestAction[] = [
        NEXT_BEST_ACTION.CONNECT_DOMAIN,
        NEXT_BEST_ACTION.CONNECT_DOMAIN_EXTERNAL,
      ];

      if (
        !domainStatusResult?.domainStatus?.nextBestAction ||
        shortTimeList.includes(domainStatusResult?.domainStatus?.nextBestAction)
      ) {
        refreshTime = SHORT_TIME_BEFORE_REFRESH_MINS;
      }

      return refreshTime * ONE_MINUTE_IN_MS;
    };

    const shouldUpdateDomainStatus = (domain: string) =>
      !domainStatusMap.value[domain] ||
      new Date().getTime() -
        new Date(domainStatusMap.value[domain].updatedAt).getTime() >
        getRefreshTime(domain);

    const fetchDomainStatusArray = (
      inputs: IDomainStatusInput[],
      force?: boolean,
    ) => {
      const requestInputs = inputs
        .map((input) => ({
          ...input,
          isTakingTooLong: false,
        }))
        .filter(
          (input) =>
            (shouldUpdateDomainStatus(input.domain) || force) &&
            !isDomainInLoadingState(input.domain),
        );

      domainStatusRequestQueue.value.unshift(...requestInputs);
    };

    const processNextDomain = async () => {
      if (!isQueueRunning.value) {
        return;
      }

      if (domainStatusRequestQueue.value.length === 0) {
        stopDomainStatusRequestQueue();

        return;
      }

      const input = domainStatusRequestQueue.value[0];

      if (
        !shouldUpdateDomainStatus(input.domain) ||
        isDomainRequestInProgress(input.domain)
      ) {
        removeDomainFromQueue(input.domain);
        if (domainStatusRequestQueue.value.length) {
          processNextDomain();
        }

        return;
      }

      domainLoadingMap.value[input.domain] = true;
      removeDomainFromQueue(input.domain);

      try {
        const domainStatusRequest = hDomainsStatus.getDomainStatus(input);
        let promiseResolved = false;

        setTimeout(() => {
          if (!promiseResolved) {
            input.isTakingTooLong = true;
            processNextDomain();
          }
        }, Math.max(TIME_TO_WAIT_UNTIL_NEXT_REQUEST, getTimeForNextRequest()));

        const [_, result] = await Promise.all([
          getWaitPromise(),
          domainStatusRequest,
        ]);

        promiseResolved = true;

        domainLoadingMap.value[input.domain] = false;
        setDomainStatus(result);
      } catch (error) {
        toastr.e(t((error as Error)?.message));
      }
      if (input.isTakingTooLong) {
        return;
      }
      processNextDomain();
    };

    const runDomainStatusRequestQueue = async () => {
      if (isQueueRunning.value) {
        return;
      }
      isLoading.value = true;
      isQueueRunning.value = true;

      await processNextDomain();
    };

    const getTimeForNextRequest = () => Math.pow(currentRateLimit.value, 2);

    const getWaitPromise = () =>
      new Promise((resolve) => setTimeout(resolve, getTimeForNextRequest()));

    const stopDomainStatusRequestQueue = () => {
      isQueueRunning.value = false;
      isLoading.value = false;
    };

    const getRateLimitAddition = (domainStatus: IDomainStatusResult) => {
      let limit = 0;
      const { additionalDetails } = domainStatus;
      const additionalDetailsKeys = [
        'domainResources',
        'isAvailable',
        'domainNameservers',
        'isDomainRegisteredAtHostinger',
      ] as (keyof typeof additionalDetails)[];
      additionalDetailsKeys.forEach((key) => {
        if (additionalDetails?.[key] !== null) {
          limit++;
        }
      });

      return limit;
    };

    const setRateLimitAddition = (domainStatus: IDomainStatusResult) => {
      const rateLimitFromRequest = getRateLimitAddition(domainStatus);
      currentRateLimit.value += rateLimitFromRequest;
      setTimeout(() => {
        currentRateLimit.value -= rateLimitFromRequest;
      }, 60000);
    };

    const setDomainStatus = (result: IDomainStatusResult) => {
      setRateLimitAddition(result);

      domainStatusMap.value[result.domain] = {
        status: result,
        updatedAt: new Date().toISOString(),
      };
    };

    const fetchDomainStatus = async (
      input: IDomainStatusInput,
      force?: boolean,
    ) => {
      isLoading.value = true;

      const parsedInput = {
        ...input,
        domain: input.domain,
      };

      try {
        if (!shouldUpdateDomainStatus(parsedInput.domain) && !force) {
          isLoading.value = false;

          return;
        }

        const result = await hDomainsStatus.getDomainStatus(parsedInput);

        setDomainStatus(result);
      } catch (error) {
        toastr.e(t((error as Error)?.message));
      }

      isLoading.value = false;
    };

    const removeDomainFromQueue = (domain: string) => {
      domainStatusRequestQueue.value = domainStatusRequestQueue.value.filter(
        (input) => input.domain !== domain,
      );
    };

    const resetDomainStatusRequestQueue = () => {
      stopDomainStatusRequestQueue();
      domainStatusRequestQueue.value = [];
      domainLoadingMap.value = {};
    };

    const getDomainStatus = (domain: string) =>
      domainStatusMap.value[domain]?.status ?? null;

    const getDomainsInQueue = () =>
      domainStatusRequestQueue.value.map((input) => input.domain);

    const isDomainInQueue = (domain: string) =>
      getDomainsInQueue().includes(domain);

    const isDomainRequestInProgress = (domain: string) =>
      domainLoadingMap.value?.[domain] ?? false;

    const isDomainInLoadingState = (domain: string) => {
      const parsedDomain = domain;

      return (
        isDomainInQueue(parsedDomain) || isDomainRequestInProgress(parsedDomain)
      );
    };

    const isQueueEmpty = computed(
      () => domainStatusRequestQueue.value.length === 0,
    );

    const getDomainWithoutSubdomain = (domain: string): string => {
      if (!domain) return '';

      const PREVIEW_DOMAIN_URLS = [
        process.env.VITE_BUILDER_PREVIEW_URL!,
        process.env.VITE_WORDPRESS_PREVIEW_URL!,
      ];

      const isPreview = PREVIEW_DOMAIN_URLS.some((previewDomain) =>
        domain.includes(previewDomain),
      );

      if (isPreview) {
        return domain;
      }

      const parsedDomain = parse(domain);

      if (!parsedDomain || !isParsedDomainType(parsedDomain)) {
        return domain;
      }

      return parsedDomain.domain ?? domain;
    };

    const isParsedDomainType = (domain: any): domain is ParsedDomain => domain;

    return {
      isLoading,
      domainStatusMap,
      isQueueRunning,
      domainStatusRequestQueue,
      isQueueEmpty,
      fetchDomainStatus,
      resetDomainStatusRequestQueue,
      fetchDomainStatusArray,
      getDomainStatus,
      stopDomainStatusRequestQueue,
      runDomainStatusRequestQueue,
      isDomainInLoadingState,
      getDomainWithoutSubdomain,
      removeDomainFromQueue,
    };
  },
  {
    persist: {
      storage: localStorage,
      key: STORE_PERSISTENT_KEYS.DOMAINS_STATUS_STORE,
      paths: PERSISTED_DATA,
    },
  },
);
