// @ts-nocheck

import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

import domainStore from './modules/domainStore';
import hostingEmailsModule from './modules/emails/hostingEmailsModule';
import premiumEmailsModule from './modules/emails/premiumEmailsModule';
import hostingMigrationsModule from './modules/hosting/hostingMigrationsModule';
import hostingSectionDetailsModule from './modules/hosting/sections/hostingSectionDetailsModule';
import hostingSectionsModule from './modules/hosting/sections/hostingSectionsModule';
import hostingStore from './modules/hostingStore';
import servicesModule from './modules/servicesModule';
import sslCertificatesModule from './modules/sslCertificatesModule';
import ssoStore from './modules/ssoStore';
import wpTemplatePreviewModule from './modules/wpTemplatePreviewModule';

import autoRefundModule from '@/store/modules/autoRefundModule';
import emailOnboardingModule from '@/store/modules/emails/emailOnboardingModule';
import hDnsModule from '@/store/modules/hDnsModule';
import hToastrStore from '@/store/modules/hToastrStore';
import billingItemsModule from '@/store/modules/hbilling/billingItemsModule';
import invoicesModule from '@/store/modules/hbilling/invoicesModule';
import ordersModule from '@/store/modules/hbilling/ordersModule';
import paymentMethodsModule from '@/store/modules/hbilling/paymentMethodsModule';
import subscriptionsModule from '@/store/modules/hbilling/subscriptionsModule';
import hostingDashboardModule from '@/store/modules/hosting/hostingDashboardModule';
import hostingDomainsCdnModule from '@/store/modules/hosting/sections/domains/hostingDomainsCdnModule';
import marketingPanelModule from '@/store/modules/marketingPanelModule';
import redirectModule from '@/store/modules/redirectModule';
import websitesModule from '@/store/modules/websitesModule';
import { getBrandConfigById } from '@/utils/services/brandConfigService';
import { clearUserData } from '@/utils/services/dataClearService';
import { errorLogger } from '@/utils/services/errorLogging';
import { getWindowQueryParams } from '@/utils/services/windowService';

const isAuthRequired = () => !!getWindowQueryParams()?.jwt;

const storageActionKeys = {
  GET: 'get',
  SET: 'set',
  CLEAR: 'clear',
  REMOVE: 'remove',
  KEY: 'key',
};

let isErrorGenerated = false;

const generateVuexPersistStorageError = (key, error) => {
  if (!isErrorGenerated) {
    errorLogger.logError(error as Error, `Vuex-persist-${key}`);
    isErrorGenerated = true;
  }
};

// ! This is a temporary solution to avoid hPanel from failing to load
// ! when vuex-persist can't load data from localStorage
// ! This should be removed once we move to pinia from vuex
const vuexPersistStorage: Storage = {
  setItem: (key, value) => {
    try {
      window.localStorage.setItem(key, value);
    } catch (error) {
      generateVuexPersistStorageError(storageActionKeys.SET, error);
    }
  },
  removeItem: (key) => {
    try {
      window.localStorage.removeItem(key);
    } catch (e) {
      generateVuexPersistStorageError(storageActionKeys.REMOVE, e);
    }
  },
  getItem: (key) => {
    try {
      return window.localStorage.getItem(key);
    } catch (e) {
      generateVuexPersistStorageError(storageActionKeys.GET, error);
    }
  },
  clear: () => {
    try {
      window.localStorage.clear();
    } catch (e) {
      generateVuexPersistStorageError(storageActionKeys.CLEAR, error);
    }
  },
  key: (key) => {
    try {
      return window.localStorage.key(key);
    } catch (e) {
      generateVuexPersistStorageError(storageActionKeys.KEY, error);
    }
  },
};

const vuexLocal = new VuexPersistence({
  storage: vuexPersistStorage,
  restoreState: (key, storage) => {
    if (isAuthRequired()) {
      clearUserData({ resetSessionStorage: false });
    } else {
      const value = storage.getItem(key);
      let objValue = {};

      if (typeof value === 'string') {
        objValue = JSON.parse(value || '{}');
      } else {
        objValue = value || {};
      }

      return objValue;
    }
  },
  reducer: (state) => ({
    hosting: state.hosting,
    sso: state.sso,
    hostingAdvancedSshAccess: state.hostingAdvancedSshAccess,
    hostingAccountsDetailsModule: state.hostingAccountsDetailsModule,
    hostingOtherActivityLog: state.hostingOtherActivityLog,
    hostingMigrations: state.hostingMigrations,
    hostingAdvancedPhpConfiguration: state.hostingAdvancedPhpConfiguration,
    theme: state.theme,
    domain: state.domain,
    domainPointing: state.domainPointing,
    hostingDomainsParkedDomains: state.hostingDomainsParkedDomains,
    hostingSection: state.hostingSection,
    help: state.help,
    intercom: state.intercom,
    openedFlock: state.openedFlock,
    server: state.server,
    hostingEmails: state.hostingEmails,
    maintenance: state.maintenance,
    referrals: state.referrals,
    autoRefund: state.autoRefund,
    emailOnboarding: state.emailOnboarding,
    hDns: state.hDns,
  }),
});

export default createStore({
  // strict: true,
  plugins: [vuexLocal.plugin],
  modules: {
    hToastr: hToastrStore,
    redirect: redirectModule,
    sso: ssoStore,
    hosting: hostingStore,
    domain: domainStore,
    hostingSection: hostingSectionsModule,
    hostingSectionDetails: hostingSectionDetailsModule,
    hostingMigrations: hostingMigrationsModule,
    hostingDashboard: hostingDashboardModule,
    cdn: hostingDomainsCdnModule,
    ssl: sslCertificatesModule,
    emails: premiumEmailsModule,
    hostingEmails: hostingEmailsModule,
    services: servicesModule,
    templatePreview: wpTemplatePreviewModule,
    invoices: invoicesModule,
    autoRefund: autoRefundModule,
    paymentMethods: paymentMethodsModule,
    subscriptions: subscriptionsModule,
    orders: ordersModule,
    billingItems: billingItemsModule,
    websites: websitesModule,
    marketingPanel: marketingPanelModule,
    emailOnboarding: emailOnboardingModule,
    hDns: hDnsModule,
  },
  state: {
    theme: false,
  },
  mutations: {
    mutateTheme(state, value) {
      const brandConfig = getBrandConfigById(value);
      const theme = brandConfig?.name.toLowerCase() || 'hostinger';

      return (state.theme = theme);
    },
  },
  actions: {
    resetSessionStores(state) {
      // need to reset states when exiting/entering impersonation mode as app is not reloaded
      state.commit('emails/RESET_STATE');
      state.commit('RESET_ACCOUNT_STATE');
    },
  },
}) as any;
