import type { NavigationGuard } from 'vue-router';

import store from '@/store/index';
import { useFrontendSettingsStore, useVpsServerStore } from '@/stores';

const hVpsManagementGuard: NavigationGuard = async (to, from, next) => {
  const serverStore = useVpsServerStore();
  const frontendSettingsStore = useFrontendSettingsStore();

  if (!to.params.id) {
    next();

    return;
  }
  const isServerLoaded = !!serverStore.servers.length;
  const vpsManagementRedirect = serverStore.vpsManagementRedirect;

  if (!isServerLoaded) {
    frontendSettingsStore.setState('pageLoading', true);
    await serverStore.fetchServers();
    frontendSettingsStore.setState('pageLoading', false);
  }

  serverStore.setManagedId(Number(to.params.id));

  if (!vpsManagementRedirect) {
    serverStore.setServerManagementRedirect('vps');
  }

  store.commit('SET_REDIRECT', {
    hostname: serverStore.currentServer.hostname,
  });

  return next();
};

export default hVpsManagementGuard;
