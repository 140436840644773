import { defineStore } from 'pinia';
import { ref } from 'vue';

import { accountRepo } from '@/repositories';
import { STORE_PERSISTENT_KEYS } from '@/types';

export const useOnboardingSourceStore = defineStore(
  'onboardingSourceStore',
  () => {
    const sourceList = ref<string[]>([]);
    const isSourceListLoading = ref(false);

    const selectedSource = ref('');

    const fetchSourceListWithRetry = async () => {
      isSourceListLoading.value = true;

      let [{ data }, error] = await accountRepo.webhostTransferList();

      if (!error && !data?.length) {
        [{ data }, error] = await accountRepo.webhostTransferList();
      }

      if (!error && data) {
        sourceList.value = data;
        setSelectedSource(data?.[0]);
      }

      isSourceListLoading.value = false;
    };

    const setSelectedSource = (source: string) => {
      if (!source) return;

      selectedSource.value = source;
    };

    const $reset = () => {
      sourceList.value = [];
      selectedSource.value = '';
      isSourceListLoading.value = false;
    };

    return {
      fetchSourceListWithRetry,
      isSourceListLoading,
      sourceList,
      selectedSource,
      setSelectedSource,
      $reset,
    };
  },
  { persist: { key: STORE_PERSISTENT_KEYS.ONBOARDING_SOURCE_STORE } },
);
