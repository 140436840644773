import type {
  HResourceState,
  HResourceType,
  IHResource,
  ProWebsiteResource,
  RequestConfig,
} from '@/types';
import { HRESOURCES_STATE } from '@/types';
import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const HRESOURCES_URL_V1 = `${process.env.VITE_API_HRESOURCES_V2}/api/v1/resources`;
const HRESOURCES_URL_V2 = `${process.env.VITE_API_HRESOURCES_V2}/api/v2/resources`;

export default {
  async getResources(
    request?: {
      type?: HResourceType[];
      state?: HResourceState[];
      subscriptionId?: string;
      referenceId?: string | number;
      status?: string;
    },
    config?: RequestConfig,
  ) {
    return await hAsync<IHResource[]>(
      http.get(HRESOURCES_URL_V2, {
        params: {
          state: [
            HRESOURCES_STATE.CREATED,
            HRESOURCES_STATE.PENDING,
            HRESOURCES_STATE.ACTIVATING,
            HRESOURCES_STATE.ACTIVE,
            HRESOURCES_STATE.UPDATING,
            HRESOURCES_STATE.SUSPENDING,
            HRESOURCES_STATE.SUSPENDED,
            HRESOURCES_STATE.UNSUSPENDING,
            HRESOURCES_STATE.DELETING,
            HRESOURCES_STATE.DELETED,
            HRESOURCES_STATE.COMPLETED,
            HRESOURCES_STATE.CANCELED,
            HRESOURCES_STATE.FAILED,
          ],
          ...request,
        },
        ...config,
      }),
    );
  },
  async getProPendingSetupResources(
    request: {
      type?: string[];
      status?: string;
    },
    config?: RequestConfig,
  ) {
    return await hAsync<ProWebsiteResource[]>(
      http.get(`${HRESOURCES_URL_V1}/managed`, {
        params: request,
        ...config,
      }),
    );
  },
};
