import jsCookie from 'js-cookie';

import store from '@/store';
import { Cookie, STORE_PERSISTENT_KEYS } from '@/types';
import httpCache from '@/utils/services/http/httpCacheService';
import hLocalStorage from '@/utils/services/localStorageService';

const clearPersistedStores = () => {
  const VUEX_PERSISTANCE_KEYS = ['vuex', 'vuex-shared'];

  const PINIA_PERSISTANCE_KEYS = Object.values(STORE_PERSISTENT_KEYS);

  [...PINIA_PERSISTANCE_KEYS, ...VUEX_PERSISTANCE_KEYS].forEach((item) => {
    localStorage.removeItem(item);
  });
};
/**
 *
 * @param keepToken if true, the JWT token will not be removed from cookies
 * @param resetSessionStorage if true, the stores in resetSessionStores function will be manually reset to their initial state
 * @description This function clears all user data from - VUEX in localStorage, sessionStorage, HTTP cache, and JWT cookie
 */
export const clearUserData = ({
  keepToken = false,
  resetSessionStorage = true,
} = {}) => {
  clearPersistedStores();
  httpCache.deleteDB();
  if (resetSessionStorage) store.dispatch('resetSessionStores');

  if (keepToken) return;

  hLocalStorage.cleanUpOldData();
  localStorage.removeItem(Cookie.JWT);
  jsCookie.remove(Cookie.REMEMBER_LOGIN_TOKEN);
};
