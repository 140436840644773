import type { FieldOption } from '@hostinger/hcomponents';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import ProPlanSelectionModal from '@/components/Modals/HModal/HostingerPro/ProPlanSelectionModal.vue';
import PlanSelectionModal from '@/components/Modals/HModal/PlanSelectionModal.vue';
import UpgradeModal from '@/components/Modals/HModal/Purchase/UpgradeModal.vue';
import { useImpersonation, useModal, useSubscriptions } from '@/composables';
import { useCatalogStore, useOrdersStore, useProfileStore } from '@/stores';
import type { OrderWithLimits, HostingOrderType } from '@/types';
import {
  AmplitudeContext,
  RedirectMutations,
  Route,
  ONBOARDING_SCENARIO,
  Onboarding,
} from '@/types';
import { timeout, mapKeyValue } from '@/utils/helpers';
import { errorLogger } from '@/utils/services/errorLogging';
import { i18n } from '@/utils/services/i18nService';

interface CreateOptions {
  amplitudeLocation?: string;
  isProPanelFlow?: boolean;
  redirectRouteName?: string;
  topSelectionOption?: { field: FieldOption; route?: RouteLocationNamedRaw };
  hostingOrders?: HostingOrderType[];
  quickInstallSelection?: Onboarding.QuickInstallSelections;
  canPurchaseNewHosting?: boolean;
  modalCloseCallback?: () => void;
}

const TIMEOUT_FOR_SUCCESS_ANIMATION = 1000;

const preselectedDomain = ref('');

export const useAddWebsite = ({
  showManaged = ref(false),
}: {
  showManaged?: Ref<boolean>;
} = {}) => {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const createOptions = ref<CreateOptions>();
  const catalogStore = useCatalogStore();
  const ordersStore = useOrdersStore();
  const {
    hasMultipleOrders,
    nonRemovingOrders,
    nonRemovingManagedAndOwnedOrders,
  } = storeToRefs(ordersStore);
  const { getSubscriptionByOrderId } = useSubscriptions();
  const profileStore = useProfileStore();
  const { isAccessManager } = storeToRefs(profileStore);
  const { handleClientRedirect } = useImpersonation();
  const { openModal, closeModal } = useModal();
  const isLoadingPlanSelection = ref(false);

  const orders = computed<HostingOrderType[]>(() => {
    if (isAccessManager.value) {
      return filteredLimitsReachedHostingOrders.value;
    }

    if (showManaged.value) return nonRemovingManagedAndOwnedOrders.value;

    return nonRemovingOrders.value || [];
  });

  const ordersWithExistingResource = computed(() =>
    orders.value.filter(
      (order) => !!getMappedOrderWithSubscription(order).orderId,
    ),
  );

  const filteredLimitsReachedHostingOrders = computed(() =>
    (nonRemovingOrders.value || []).filter(
      (orderWithLimits) => !getIsLimitReached(orderWithLimits),
    ),
  );

  const canAddWebsite = computed(
    () => !!ordersWithExistingResource.value.length,
  );

  const getIsManagedOrder = (order: HostingOrderType) => {
    if ('resource' in order) {
      return !profileStore.getIsSameClientId(order.resource.customerCustomId);
    }

    return false;
  };

  const getClientIdFromOrder = (order: HostingOrderType) => {
    if (getIsManagedOrder(order) && 'resource' in order) {
      return order.resource.customerCustomId;
    }

    return '';
  };

  const navigateToQuickInstallAddonOnboarding = async (
    order: HostingOrderType,
    quickInstallSelection: Onboarding.QuickInstallSelections,
  ) => {
    const SCENARIO_SELECTION_MAP = {
      [Onboarding.QUICK_INSTALL_SELECTIONS.WORDPRESS]:
        ONBOARDING_SCENARIO.QUICK_INSTALL_WORDPRESS_ADDON,
      [Onboarding.QUICK_INSTALL_SELECTIONS.BUILDER]:
        ONBOARDING_SCENARIO.QUICK_INSTALL_WEBSITE_BUILDER_ADDON,
      [Onboarding.QUICK_INSTALL_SELECTIONS.EMPTY_WEBSITE]:
        ONBOARDING_SCENARIO.QUICK_INSTALL_EMPTY_WEBSITE_ADDON,
      [Onboarding.QUICK_INSTALL_SELECTIONS.MIGRATE]:
        ONBOARDING_SCENARIO.QUICK_INSTALL_MIGRATION_ADDON,
      default: ONBOARDING_SCENARIO.ADDON,
    };

    const scenario = mapKeyValue(SCENARIO_SELECTION_MAP, quickInstallSelection);

    await handleClientRedirect(getClientIdFromOrder(order), {
      name: Route.OnboardingV2.BASE_PATH,
      params: {
        order_id: order.orderId,
        /**
         * username is required for the addon onboarding scenario
         * because old hosting logic accounts have the same order id
         * for different accounts
         */
        username: order.username,
        scenario,
      },
    });
  };

  const navigateToQuickInstallOnboarding = async (
    order: HostingOrderType,
    quickInstallSelection: Onboarding.QuickInstallSelections,
  ) => {
    const SCENARIO_SELECTION_MAP = {
      [Onboarding.QUICK_INSTALL_SELECTIONS.WORDPRESS]:
        ONBOARDING_SCENARIO.QUICK_INSTALL_WORDPRESS_SETUP,
      [Onboarding.QUICK_INSTALL_SELECTIONS.BUILDER]:
        ONBOARDING_SCENARIO.SETUP_WEBSITE_BUILDER_PRESELECTED,
      [Onboarding.QUICK_INSTALL_SELECTIONS.EMPTY_WEBSITE]:
        ONBOARDING_SCENARIO.QUICK_INSTALL_EMPTY_WEBSITE_SETUP,
      [Onboarding.QUICK_INSTALL_SELECTIONS.MIGRATE]:
        ONBOARDING_SCENARIO.QUICK_INSTALL_MIGRATION,
      default: '',
    };

    const scenario = mapKeyValue(SCENARIO_SELECTION_MAP, quickInstallSelection);

    await handleClientRedirect(getClientIdFromOrder(order), {
      name: Route.OnboardingV2.BASE_PATH,
      params: {
        order_id: order.orderId,
        ...(scenario ? { scenario } : {}),
      },
    });
  };

  const navigateToQuickInstallBuilder = async (order: HostingOrderType) => {
    await handleClientRedirect(getClientIdFromOrder(order), {
      name: Route.OnboardingV2.BASE_PATH,
      params: {
        order_id: order.orderId,
      },
      query: {
        preselect: '1',
        websiteBuilderQuickInstall: '1',
      },
    });
  };

  const navigateToOnboarding = async (orderWithLimits: HostingOrderType) => {
    const order = getMappedOrderWithSubscription(orderWithLimits);

    const routeQuery =
      createOptions.value?.topSelectionOption?.route?.query || route.query;
    const quickInstallSelection = createOptions.value?.quickInstallSelection;
    const isAddonOnboarding = order.hasAccount;
    const isQuickInstallBuilder =
      quickInstallSelection === Onboarding.QUICK_INSTALL_SELECTIONS.BUILDER;

    errorLogger.addBreadcrumb({ name: 'navigate to onboarding', data: order });

    if (isQuickInstallBuilder) {
      await navigateToQuickInstallBuilder(order);
    }

    if (quickInstallSelection) {
      return isAddonOnboarding
        ? navigateToQuickInstallAddonOnboarding(order, quickInstallSelection)
        : navigateToQuickInstallOnboarding(order, quickInstallSelection);
    }

    handleClientRedirect(
      getClientIdFromOrder(order),
      isAddonOnboarding
        ? {
            name: Route.OnboardingV2.BASE_PATH,
            params: {
              order_id: order.orderId,
              /**
               * username is required for the addon onboarding scenario
               * because old hosting logic accounts have the same order id
               * for different accounts
               */
              username: order.username,
              scenario: ONBOARDING_SCENARIO.ADDON,
            },
          }
        : {
            name: Route.Onboarding.START,
            params: { order_id: order.orderId },
            query: {
              ...routeQuery,
            },
          },
    );
  };

  const setPreselectedDomain = (domain: string) => {
    preselectedDomain.value = domain;
  };

  const openPlanSelectionModal = async () => {
    await store.dispatch('fetchHostingOrders');

    if (showManaged.value) {
      openModal({
        component: { ProPlanSelectionModal },
        data: {
          hostingOrders: ordersWithExistingResource.value,
          topSelectionOption: createOptions.value?.topSelectionOption,
          hasPurchaseOption: createOptions.value?.canPurchaseNewHosting,
          shouldLoadOnConfirmAction: true,
          onSuccess: async (order: HostingOrderType) => {
            isLoadingPlanSelection.value = true;

            await addWebsiteUpgradeCheck(order);

            isLoadingPlanSelection.value = false;
          },
          onClose: createOptions.value?.modalCloseCallback,
        },
      });

      return;
    }

    openModal({
      component: { PlanSelectionModal },
      data: {
        shouldLoadOnConfirmAction: true,
        hostingOrders: ordersWithExistingResource.value,
        topSelectionOption: createOptions.value?.topSelectionOption,
        quickInstallSelection: createOptions.value?.quickInstallSelection,
        hasPurchaseOption: createOptions.value?.canPurchaseNewHosting,
        onSuccess: async (order: OrderWithLimits) => {
          isLoadingPlanSelection.value = true;

          await addWebsiteUpgradeCheck(order);

          isLoadingPlanSelection.value = false;
        },
        onClose: createOptions.value?.modalCloseCallback,
      },
    });
  };

  const openUpgradeModal = (
    orderWithLimits: OrderWithLimits,
    overwriteUpgradeModalData?: any,
  ) => {
    const order = getMappedOrderWithSubscription(orderWithLimits);

    openModal({
      component: { UpgradeModal },
      data: {
        title: i18n.t('Upgrade to Premium hosting'),
        subtitle: hasMultipleOrders.value
          ? i18n.t(
              'The premium plan allows having 100 websites. Choose a billing period.',
            )
          : i18n.t(
              'It looks like this plan has reached the maximum number of websites it can host. The premium plan allows having 100 websites. Choose a billing period.',
            ),
        goBackText: hasMultipleOrders.value ? 'Select a hosting plan' : null,
        goBackCustom: async () => {
          closeModal();
          await openPlanSelectionModal();
        },
        upgradeItemId: order.productName?.includes('wpsingle')
          ? catalogStore.getCatalogItemId('hosting-wppremium')
          : catalogStore.getCatalogItemId('hosting-premium'),
        itemPriceId: order.productName,
        subscriptionId: order.orderId,
        redirect: `${window.origin}/websites`,
        amplitudeContext: AmplitudeContext.HOSTING,
        onSuccess: async () => {
          ordersStore.getOrdersWithLimits();

          await timeout(TIMEOUT_FOR_SUCCESS_ANIMATION);

          navigateToOnboarding(order);
        },
        ...overwriteUpgradeModalData,
      },
      steps: [
        {
          hideX: true,
        },
      ],
    });
  };

  const addWebsiteRedirect = async (orderId: string, username?: string) => {
    if (!orderId) {
      return;
    }

    if (!store.getters.getAccountsByOrder(orderId).length) {
      return router.push({
        name: Route.Onboarding.START,
        params: { order_id: orderId },
      });
    }

    await ordersStore.getOrdersWithLimits();

    const currentOrder = store.getters.getOrderById(orderId);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, no-param-reassign
    orderId = currentOrder.serviceId;

    if (username) {
      const order = ordersWithExistingResource.value.find(
        (orderWithLimit: any) =>
          orderWithLimit.orderId === Number(orderId) &&
          orderWithLimit.username === username,
      );

      return addWebsiteUpgradeCheck(
        order ?? ordersWithExistingResource.value[0],
      );
    }

    const order = ordersWithExistingResource.value.find(
      (orderWithLimit: any) => orderWithLimit.orderId === Number(orderId),
    );

    return addWebsiteUpgradeCheck(order ?? ordersWithExistingResource.value[0]);
  };

  const addWebsiteRedirectByCurrentOrderId = () => {
    const orderId = store.getters.getCurrentOrder?.id || route.params.id;
    const username = store.getters.getCurrentAccount?.username;

    addWebsiteRedirect(orderId, username);
  };

  const ordersWithoutLimits = computed(() =>
    ordersWithExistingResource.value.filter(
      (orderWithLimits: OrderWithLimits) => {
        const { count, limit } = orderWithLimits.limits.addons;

        return count < limit;
      },
    ),
  );
  const getIsLimitReached = (orderWithLimits?: OrderWithLimits) => {
    if (!orderWithLimits) return;

    const { count, limit } = orderWithLimits.limits.addons;

    return count >= limit;
  };

  const processWebsiteUpgradeData = (orderWithLimits: HostingOrderType) => {
    if (getIsManagedOrder(orderWithLimits) || isAccessManager.value) {
      return {
        isManagedOrder: true,
        isLimitReached: false,
        isAvailableToSetup: false,
      };
    }

    const isLimitReached = getIsLimitReached(orderWithLimits);

    return {
      isManagedOrder: false,
      isLimitReached,
      isAvailableToSetup: !isLimitReached,
    };
  };

  const addWebsiteUpgradeCheck = async (orderWithLimits: HostingOrderType) => {
    const { isManagedOrder, isLimitReached, isAvailableToSetup } =
      processWebsiteUpgradeData(orderWithLimits);

    if (isAvailableToSetup || isManagedOrder) {
      await navigateToOnboarding(orderWithLimits);

      return;
    }

    if (isLimitReached) openUpgradeModal(orderWithLimits);
  };

  const getMappedOrderWithSubscription = (
    orderWithLimits: HostingOrderType,
  ) => {
    const currentOrder = { ...orderWithLimits };
    const subscription = getSubscriptionByOrderId(String(currentOrder.orderId));
    const productName = subscription.items?.[0]?.itemPriceId ?? '';

    let managedOrderSubscriptionId = null;
    if (getIsManagedOrder(orderWithLimits) && 'resource' in orderWithLimits) {
      managedOrderSubscriptionId =
        orderWithLimits.resource.chargebeeSubscriptionId;
    }

    return {
      ...currentOrder,
      productName,
      orderId:
        subscription?.resource?.chargebeeSubscriptionId ||
        subscription.id ||
        managedOrderSubscriptionId ||
        '',
    };
  };

  const createOrMigrateWebsite = async (options?: CreateOptions) => {
    if (options) createOptions.value = options;

    await ordersStore.getOrdersWithLimits(true);

    const optionsOrders = createOptions.value?.hostingOrders || [];
    const hasOptionsOrders = !!optionsOrders.length;
    const hasMultipleOptionsOrders = optionsOrders.length > 1;

    const isMultipleOrdersFlow = hasOptionsOrders
      ? hasMultipleOptionsOrders
      : ordersStore.getHasMultipleNonRemovingOrders({
          showManaged: showManaged.value,
        });

    if (!ordersWithExistingResource.value.length && !hasOptionsOrders) {
      store.commit(RedirectMutations.SET_REDIRECT, {
        location: createOptions.value?.amplitudeLocation,
      });
      router.push({
        name: Route.Base.BUY_HOSTING,
      });

      return;
    }

    if (isMultipleOrdersFlow) {
      return await openPlanSelectionModal();
    }

    addWebsiteUpgradeCheck(
      optionsOrders[0] || ordersWithExistingResource.value[0],
    );
  };

  return {
    orders,
    ordersWithoutLimits,
    preselectedDomain,
    canAddWebsite,
    getIsLimitReached,
    setPreselectedDomain,
    addWebsiteRedirect,
    addWebsiteRedirectByCurrentOrderId,
    createOrMigrateWebsite,
    addWebsiteUpgradeCheck,
    getIsManagedOrder,
    processWebsiteUpgradeData,
    openUpgradeModal,
  };
};
