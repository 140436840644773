import type { Ref, ComputedRef } from 'vue';
import { computed, ref } from 'vue';
import type { RouteRecordName } from 'vue-router';
import { useRoute, useRouter } from 'vue-router';

import type { OnboardingTransition } from '@/types';

export const useOnboardingSteps = <T>(onboardingSteps: ComputedRef<T[]>) => {
  const route = useRoute();
  const router = useRouter();

  const currentStep = ref<T>(onboardingSteps.value[0]) as Ref<T>;
  const completedSteps = ref<T[]>([]) as Ref<T[]>;
  const transition = ref<OnboardingTransition>('slide-left'); // transition between onboarding steps

  const previousStep = computed(() => {
    const previousStepIndex = completedSteps.value.length - 1;

    if (!previousStepIndex) {
      return completedSteps.value[0];
    }

    return completedSteps.value[previousStepIndex];
  });

  const hasPreviousSteps = computed(() => !!completedSteps.value.length);

  const completionPercentage = computed(() => {
    const currentStepIndex = onboardingSteps.value.findIndex(
      (step) => step === currentStep.value,
    );

    return Math.round((currentStepIndex * 100) / onboardingSteps.value.length);
  });

  const setTransition = (newTransition: OnboardingTransition) => {
    transition.value = newTransition;
  };

  const setStep = (step: T) => {
    currentStep.value = step;
  };

  const addCompletedStep = (step: T) => {
    if (completedSteps.value.includes(step)) return;

    completedSteps.value.push(step);
  };

  const removeCompletedStep = (step: T) => {
    completedSteps.value = completedSteps.value.filter(
      (completedStep) => completedStep !== step,
    );
  };

  const removeAllCompletedSteps = () => {
    completedSteps.value = [];
  };

  const removePreviousStep = () => {
    completedSteps.value = completedSteps.value.slice(0, -1);
  };

  const goTo = async (step: T) => {
    setStep(step);

    await router.replace({
      params: route.params,
      name: step as RouteRecordName,
    });
  };

  const goToStep = async (step: T) => {
    setTransition('slide-left');
    addCompletedStep(currentStep.value);

    await goTo(step);
  };

  const goToPrevious = () => {
    setTransition('slide-right');

    goTo(previousStep.value);

    removePreviousStep();
  };

  const $reset = () => {
    currentStep.value = onboardingSteps.value[0];
    completedSteps.value = [];
    transition.value = 'slide-left';
  };

  return {
    currentStep,
    goToStep,
    goToPrevious,
    completedSteps,
    previousStep,
    hasPreviousSteps,
    completionPercentage,
    removeCompletedStep,
    removeAllCompletedSteps,
    setStep,
    transition,
    setTransition,
    addCompletedStep,
    removePreviousStep,
    $reset,
  };
};
