<script setup lang="ts">
//JSON-slugs-migrated
import { watch, computed, defineProps, ref } from 'vue';
import { useRoute } from 'vue-router';

import CountdownTimer from '@/components/CountdownTimer.vue';
import { useGlobals } from '@/composables';
import { wrapInCssVar } from '@/utils/helpers/cssHelpers';
import hLocalStorage from '@/utils/services/localStorageService';

const STORAGE_PROP_NAME = 'keep-full-width-sales-banner-collapsed';

type Props = {
  backgroundImage: string;
  backgroundColor: string;
  image: any;
  ctaText: string;
  ctaTo: string;
  headlineText: string;
  markColor: string;
  showTimer: boolean;
  subheadlineText: string;
  textColor: string;
  timerEndsAt: number;
  timerLabelColor: string;
  timerThemeColor: string;
  toggleBtnColor: string;
  price?: {
    new?: string;
    old?: string;
  };
  moneyBackBadge?: { color: string };
  nonToggable: boolean;
};

const props = defineProps<Props>();

const { t } = useGlobals();

const initialExpandedState = computed(
  () => hLocalStorage.getValue(STORAGE_PROP_NAME) !== 'true',
);

const isExpanded = ref(initialExpandedState.value);

const toggleBanner = () => {
  isExpanded.value = !isExpanded.value;

  initialExpandedState.value &&
    hLocalStorage.setValue(STORAGE_PROP_NAME, 'true');
};

const textColor = computed(() => wrapInCssVar(props.textColor));

const markColor = computed(() => wrapInCssVar(props.markColor));

const backgroundColor = computed(() => wrapInCssVar(props.backgroundColor));

const route = useRoute();

watch(
  () => route.name,
  (newVal, oldVal) => {
    if (!newVal || !oldVal || newVal === oldVal) return;
    else if (isExpanded.value) {
      toggleBanner();
    }
  },
);
</script>

<template>
  <div
    class="full-width-sales-banner"
    :class="{
      ['full-width-sales-banner--expanded']: isExpanded && !nonToggable,
      ['full-width-sales-banner--with-timer']: showTimer,
      ['full-width-sales-banner--non-toggable']: nonToggable,
    }"
    :style="{ backgroundImage: backgroundImage }"
  >
    <div class="full-width-sales-banner__inner h-container">
      <div class="full-width-sales-banner__content">
        <div class="full-width-sales-banner__headline">
          <Trans tag="h1">
            {{ headlineText }}
          </Trans>
          <Trans tag="h2">
            {{ subheadlineText }}
          </Trans>
          <div v-if="price?.new" class="full-width-sales-banner__price">
            <Trans
              tag="div"
              class="full-width-sales-banner__price--new"
              :translate-params="{ price: price.new }"
            >
              v2.price.mo
            </Trans>
            <Trans
              tag="div"
              class="full-width-sales-banner__price--old"
              :translate-params="{ price: price.old }"
            >
              v2.price.mo
            </Trans>
          </div>
        </div>
        <div class="full-width-sales-banner__cta-wrapper">
          <div
            v-if="!!showTimer"
            class="full-width-sales-banner__timer-wrapper"
          >
            <Trans tag="small">v2.deal.ends.in</Trans>
            <CountdownTimer
              class="full-width-sales-banner__timer"
              :date="timerEndsAt"
              :font-colors="{ theme: timerThemeColor, label: timerLabelColor }"
            />
          </div>
          <HButton
            v-if="!nonToggable"
            v-qa-generate
            full-width
            data-qa="full-width-sales-banner-cta"
            class="full-width-sales-banner__cta"
            :to="{ name: ctaTo }"
            color="danger"
          >
            <Trans>{{ ctaText }}</Trans>
          </HButton>
          <div
            v-if="moneyBackBadge"
            class="full-width-sales-banner__money-back-badge"
          >
            <HpIcon
              icon="icon-30-days-guarantee"
              :color="moneyBackBadge.color"
              :width="16"
              :height="20"
              viewBox="0 0 16 20"
            />
            <h4 class="text-body-2">{{ t('30-Day Money-Back Guarantee') }}</h4>
          </div>
        </div>
      </div>
      <div class="full-width-sales-banner__content-image">
        <HImage :src="image" alt="Sale hero" />
      </div>
    </div>
    <button
      v-if="!nonToggable"
      class="full-width-sales-banner__toggle-btn"
      :class="{
        ['full-width-sales-banner__toggle-btn__offset']: !!price?.new,
      }"
      data-qa="full-width-sales-banner-toggle-btn"
      @click="toggleBanner"
    >
      <HpIcon static-view-box :color="toggleBtnColor" icon="icon-chevron-up" />
    </button>
  </div>
</template>
<style scoped lang="scss">
.full-width-sales-banner {
  $this: &;
  align-items: center;
  background-color: v-bind(backgroundColor);
  color: v-bind(textColor);
  display: flex;
  flex-direction: column;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &__inner {
    align-items: center;
    padding-bottom: 48px;
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-height: calc(100vh - var(--header-and-sidemenu-height));

    @media (min-width: 768px) {
      padding-bottom: 32px;
      padding-top: 32px;
      flex-direction: row;
    }

    #{$this}--expanded & {
      gap: 28px;

      @media (min-width: 768px) {
        padding-bottom: 48px;
        padding-top: 48px;
      }
    }

    #{$this}--non-toggable & {
      padding-bottom: 24px;
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex: 1 1;
    flex-direction: row;
    gap: 16px;
    justify-content: space-between;

    #{$this}--expanded & {
      flex-direction: column;
      justify-content: center;
    }

    #{$this}--with-timer & {
      justify-content: space-between;
    }

    #{$this}--non-toggable & {
      justify-content: center;
    }

    @media (min-width: 768px) {
      align-items: unset;
    }

    @media (min-width: 992px) {
      gap: 32px;
    }
  }

  &__headline {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    width: 100%;

    @media (min-width: 768px) {
      align-items: unset;
    }

    #{$this}--expanded & {
      gap: 16px;
    }

    #{$this}--non-toggable & {
      width: unset;
    }
  }

  &__price {
    font-size: 24px;
    font-weight: 700;
    display: flex;

    &--old {
      font-weight: normal;
      text-decoration: line-through;
      margin-left: 8px;
      font-size: 14px;
    }

    #{$this}--expanded & {
      font-size: 32px;
      display: block;

      &--old {
        margin-left: 0;
        margin-top: 12px;
      }
    }

    #{$this}--non-toggable & {
      justify-content: center;
    }

    #{$this}--with-timer & {
      justify-content: left;
    }
  }

  h1 {
    font-size: 20px;
    line-height: 1.12;

    :deep(mark) {
      background-color: unset;
      color: v-bind(markColor);
      padding: unset;
    }

    :deep(b) {
      background-color: unset;
      color: v-bind(markColor);
      padding: unset;
    }

    @media (min-width: 768px) {
      font-size: 32px;
    }
  }

  h2 {
    display: none;
    font-size: 16px;
    line-height: 1.5;
  }

  &--expanded {
    h1 {
      font-size: 32px;

      @media (min-width: 992px) {
        font-size: 40px;
        line-height: 1.2;
      }
    }

    h2 {
      font-size: 16px;
      display: block;
    }
  }

  h1,
  h2 {
    margin: unset;
    text-align: center;

    @media (min-width: 768px) {
      display: block;
      text-align: unset;
    }
  }

  &--non-toggable {
    h1,
    h2 {
      text-align: center;

      @media (min-width: 768px) {
        text-align: center;
      }
    }
  }

  &--with-timer {
    h2 {
      text-align: left;
    }
  }

  &__cta-wrapper {
    --gap: 18px;

    align-items: center;
    display: none;
    flex-direction: row;
    width: 100%;
    max-width: 292px;
    gap: var(--gap);

    #{$this}--with-timer & {
      width: 264px;

      @media (min-width: 768px) {
        width: unset;
      }
    }

    #{$this}--expanded & {
      --gap: 24px;

      align-items: unset;
      display: flex;
      flex-direction: column;

      @media (min-width: 768px) {
        width: 264px;
      }
    }

    @media (min-width: 768px) {
      display: flex;
      width: unset;
      max-width: unset;
    }

    @media (min-width: 992px) {
      --gap: 32px;
    }
  }

  &__cta {
    width: 100%;
    min-width: 144px;

    @media (min-width: 992px) {
      min-width: 200px;
    }
  }

  &__timer-wrapper {
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: 4px;

    #{$this}--expanded & {
      align-items: unset;
    }
  }

  :deep(.timer) {
    justify-content: space-between;
  }

  :deep(.timer > .timer__separator) {
    margin: 0 0 0;
    padding: 16px 4px 0;
  }

  &__content-image {
    display: flex;
    flex: 0;
    min-height: 0;

    & > img {
      width: 100%;
      display: none;
      object-fit: contain;

      #{$this}--expanded & {
        display: block;
      }

      @media (min-width: 768px) {
        width: 100%;
      }
    }

    #{$this}--expanded & {
      @media (min-width: 768px) {
        flex: 0 1 635px;
      }
    }

    @media (min-width: 1240px) {
      transition: flex 0.2s cubic-bezier(0, 0, 0.2, 1);
    }
  }

  &__toggle-btn {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    display: flex;
    padding: 0;
    position: absolute;
    transform: rotate(-180deg) translateX(-50%);
    right: 50%;
    bottom: 12px;

    @media (min-width: 768px) {
      transform: rotate(-180deg);
      right: 32px;
    }

    @media (min-width: 1240px) {
      bottom: 54px;

      &__offset {
        bottom: 68px;
      }
    }

    #{$this}--with-timer & {
      @media (min-width: 1240px) {
        bottom: 72px;
      }
    }

    #{$this}--expanded & {
      transform: rotate(0) translateX(50%);

      @media (min-width: 768px) {
        transform: rotate(0);
      }

      @media (min-width: 1240px) {
        bottom: 24px;
      }
    }
  }

  &__money-back-badge {
    --gutter: 12px;

    align-items: center;
    display: none;
    gap: 12px;
    // compensate flex-gap in __cta-wrapper
    margin-top: calc(var(--gutter) - var(--gap));

    h4 {
      margin: unset;
      color: var(--textColor);
    }

    #{$this}--expanded & {
      display: flex;
      justify-content: center;

      @media (min-width: 768px) {
        justify-content: flex-start;
      }
    }
  }
}
</style>
