<template>
  <HPortlet no-background no-margin no-body-padding>
    <div v-trans class="credentials-title">Website credentials</div>
    <HFormDeprecated ref="form" v-model="websiteCredentials">
      <HFormFieldDeprecated ref="title" class="title" :schema="title" />
      <HFormFieldDeprecated ref="email" class="email" :schema="email" />
      <HFormFieldDeprecated
        ref="username"
        class="field field--half"
        :schema="username"
        :customError="errors?.username"
      />
      <HFormFieldDeprecated
        ref="password"
        class="field field--half"
        :schema="password"
        :customError="errors?.password"
      />
      <div class="advanced-button" @click="toggleAdvanced">
        <HpIcon
          class="arrow"
          :class="{ active: advanced }"
          icon="ic-arrow-down"
          primary
          view-box="-4 -2 20 12"
        />
        <span v-trans>Advanced</span>
      </div>
      <div v-show="advanced" class="advanced-section">
        <div class="section-title">
          <span v-trans>Installation path</span>
          <span v-tooltip="pathExplanationTooltip" class="explanation">
            <HpIcon gray icon="ic-info-contained" view-box="0 0 28 28" />
          </span>
        </div>
        <HFormFieldDeprecated
          id="hpanel_tracking-website-autoinstaller-website-credentials-domain"
          ref="domain"
          class="field field-path field--domain"
          :schema="domain"
          :customError="errors?.domain"
        />
        <HFormFieldDeprecated
          ref="separator"
          class="field field-path field--separator"
          :schema="separator"
        />
        <HFormFieldDeprecated
          id="hpanel_tracking-website-autoinstaller-website-credentials-directory"
          ref="directory"
          class="field field-path field--directory"
          :schema="directory"
          :customError="errors?.directory"
        />
      </div>
      <div v-show="advanced && !hideDatabaseSelection" class="advanced-section">
        <span v-trans class="section-title">Choose a database</span>
        <HFormFieldDeprecated
          id="hpanel_tracking-website-autoinstaller-website-credentials-db"
          ref="db"
          class="field field--half field--db-select"
          :schema="databaseSelect"
          :customError="errors?.database"
        />
        <HFormFieldDeprecated
          id="hpanel_tracking-website-autoinstaller-website-credentials-dbPass"
          ref="dbpsw"
          class="field field--half"
          :schema="databasePassword"
          :customError="errors?.databasePassword"
        />
      </div>
    </HFormDeprecated>
  </HPortlet>
</template>

<script>
import { mapGetters } from 'vuex';

import { useProfileStore } from '@/stores';
import { toUnicode } from '@/utils/helpers';

const EMAIL_DOMAIN = /@.+$/gim;

export default {
  name: 'WebsiteCredentials',
  props: {
    applicationDetails: Object,
    domainList: Array,
    hideDatabaseSelection: Boolean,
    savedForm: Object,
    errors: Object,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      websiteCredentials: {},
      advanced: false,
    };
  },
  setup() {
    const profileStore = useProfileStore();

    return {
      profileStore,
    };
  },
  computed: {
    title() {
      return {
        id: 'autoinstaller-sitetitle',
        type: 'input',
        label: 'Website title',
        inputType: 'text',
        name: 'sitetitle',
        validation: {
          noSpecialChar: true,
          required: true,
        },
        value: this.savedForm?.sitetitle,
        extra: false,
        skipDisplayReq: true,
      };
    },
    email() {
      return {
        id: 'autoinstaller-email',
        type: 'input',
        label: 'Administrator email',
        name: 'email',
        value: this.savedForm?.email || this.profileStore.contact?.email,
        extra: false,
        validation: {
          email: true,
          required: true,
        },
        maxlength: 60,
        skipDisplayReq: true,
      };
    },
    username() {
      return {
        id: 'autoinstaller-username',
        type: 'input',
        label: 'Administrator username',
        name: 'login',
        value:
          this.savedForm?.login ||
          this.profileStore.contact?.email?.replace(EMAIL_DOMAIN, ''),
        validation: {
          required: true,
        },
        maxlength: 60,
        skipDisplayReq: true,
        extra: false,
      };
    },
    password() {
      return {
        id: 'autoinstaller-password',
        type: 'password',
        label: 'Administrator password ',
        name: 'passwd',
        passwordTags: true,
        extra: false,
        validation: {
          complexPassword: true,
          required: true,
        },
        value: this.savedForm?.passwd,
        skipDisplayReq: true,
      };
    },
    domain() {
      const singleDomain = this.domainList.length <= 1;

      return {
        id: 'autoinstaller-domain',
        type: singleDomain ? 'text' : 'select',
        disabled: singleDomain,
        name: 'domain',
        value:
          this.savedForm?.domain ||
          toUnicode(
            this.$route.params.preselectedDomain || this.$route.params.domain,
          ),
        values: this.domainList,
      };
    },
    separator() {
      return {
        id: 'autoinstaller-separator',
        type: 'text',
        name: 'separator',
        value: '/',
        disabled: true,
      };
    },
    directory() {
      return {
        id: 'autoinstaller-directory',
        type: 'text',
        name: 'directory',
        placeholderNoTrans: true,
        label: 'Enter subdirectory',
        noTrim: true,
        validation: {
          directory: true,
          required: false,
        },
        value: this.savedForm?.directory,
      };
    },
    databaseSelect() {
      return {
        id: 'autoinstaller-dbname',
        type: 'select',
        name: 'dbName',
        values: this.databaseSelectValues,
        required: false,
        noTrans: true,
      };
    },
    databasePassword() {
      return {
        id: 'autoinstaller-dbpass',
        type: 'password',
        label: 'Database password',
        name: 'dbPass',
        passwordTags: true,
        skipDisplayReq: true,
        validation: {
          complexPassword: true,
          required: !!this.websiteCredentials?.values?.dbName,
        },
      };
    },
    databaseSelectValues() {
      return [
        {
          label: `${this.$t(
            'Create new database',
          )} <span class="text-gray">(${this.$t('Recommended')})</span>`,
          value: '',
        },
      ].concat(
        this.databasesList.map(({ dbName }) => ({
          label: dbName,
          value: dbName,
        })),
      );
    },
    pathExplanationTooltip() {
      return this.$t(
        'You can skip this step if you wish {application} to be installed on the default directory',
        {
          application: this.applicationDetails.name,
        },
      );
    },
    ...mapGetters([
      'currentOrderDetails',
      'domainSelections',
      'getCurrentAccountSubdomainsSelect',
    ]),
    ...mapGetters({ databasesList: 'getSqlDatabasesList' }),
  },
  methods: {
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    checkDatabaseLimit() {
      const limit = this.currentOrderDetails.usage.databases.limit;
      const value = this.currentOrderDetails.usage.databases.value;
      if (
        limit === null ||
        limit > value ||
        this.websiteCredentials.values.dbName
      ) {
        return true;
      } else if (limit <= value) {
        this.$toastr.e(this.$t('Maximum amount of databases reached'));
      }

      return false;
    },
    async validate() {
      this.$refs.form.validate();
      await this.$nextTick();
      if (!this.hideDatabaseSelection && !this.checkDatabaseLimit()) {
        return false;
      }

      return this.websiteCredentials.isValid;
    },
  },
  watch: {
    websiteCredentials: {
      deep: true,
      handler() {
        this.$emit('update:modelValue', {
          websiteCredentials: this.websiteCredentials.values,
        });
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.credentials-title {
  margin: 16px 0;
  font-weight: bold;
}
.advanced-button {
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  margin: 16px 0;
  cursor: pointer;
  width: 200px;
  span {
    vertical-align: middle;
    color: var(--primary);
  }

  .arrow {
    vertical-align: bottom;
    transform: rotate(0deg);
    transition: transform 0.3s;
  }
  .active {
    transition: transform 0.3s;
    transform: rotate(180deg);
  }
}

.section-title {
  font-size: 14px;
  line-height: 24px;
  margin: 16px 0 0 0;
  display: block;
  color: var(--gray);
  span {
    padding-right: 8px;
  }
}

.field--db-select .form-field {
  border: none !important;
}

.field {
  vertical-align: top;
  &--half {
    vertical-align: top;
    display: inline-block;
    width: 100%;

    @media (min-width: 992px) {
      flex: 0 0 50%;
      max-width: 50%;
      width: 50%;
      &:nth-of-type(odd) {
        padding-right: 8px;
      }
      &:nth-of-type(even) {
        padding-left: 8px;
      }
    }
  }
}

@media (min-width: 992px) {
  :deep(.advanced-section) .field {
    margin-top: 8px;
  }

  :deep(.field--db-select) {
    .h-select {
      height: 46px;
      &__selected-option {
        line-height: 48px !important;
      }
    }
  }
  :deep(.field-path).field {
    display: inline-block;
    flex: 0 0 80%;
    max-width: 80%;
    width: 80%;

    input {
      line-height: 18px;
    }

    &--domain {
      flex: 0 0 46%;
      max-width: 46%;
      width: 46%;
      .form-field {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 48px;
      }
    }
    &--separator {
      flex: 0 0 8%;
      max-width: 8%;
      width: 8%;
      .form-field {
        border-right: 0;
        border-radius: 0;
        height: 48px;
        input {
          text-align: center;
        }
      }
    }
    &--directory {
      position: relative;
      flex: 0 0 46%;
      max-width: 46%;
      width: 46%;

      .form-field {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        height: 48px;
      }
    }
  }
}
@media (max-width: 992px) {
  :deep(.field-path).field {
    &--separator {
      display: inline-block;
      flex: 0 0 12%;
      max-width: 12%;
      width: 12%;
      .form-field {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        height: 48px;
        input {
          padding: 12px 0;
          text-align: center;
        }
      }
    }
    &--directory {
      display: inline-block;
      position: relative;
      flex: 0 0 88%;
      max-width: 88%;
      width: 88%;

      .form-field {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        height: 48px;
      }
    }
  }
}
</style>
