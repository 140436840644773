import { DATA_CENTER_NAMES } from '@/types';

export const CONTINENTS = {
  EUROPE: 'Europe',
  ASIA: 'Asia',
  NORTH_AMERICA: 'North America',
  SOUTH_AMERICA: 'South America',
} as const;

export const COUNTRIES = {
  LITHUANIA: 'Lithuania',
  FRANCE: 'France',
  NETHERLANDS: 'Netherlands',
  UNITED_KINGDOM: 'United Kingdom',
  UNITED_STATES: 'United States',
  BRAZIL: 'Brazil',
  INDIA: 'India',
  SINGAPORE: 'Singapore',
} as const;

export const VPS_DATA_CENTERS = [
  {
    country: COUNTRIES.LITHUANIA,
    code: 'bnk',
    name: DATA_CENTER_NAMES.BALTNETA,
    isEcoEnergy: true,
    continent: CONTINENTS.EUROPE,
    coordinates: '55.57594550502763,24.024356122377917',
    city: null,
  },
  {
    country: COUNTRIES.FRANCE,
    code: 'int',
    name: DATA_CENTER_NAMES.INTERXION,
    isEcoEnergy: true,
    continent: CONTINENTS.EUROPE,
    coordinates: '46.50547765116202,2.208672155459716',
    city: null,
  },
  {
    country: COUNTRIES.NETHERLANDS,
    code: 'srv',
    name: DATA_CENTER_NAMES.SERVERIUS,
    isEcoEnergy: false,
    continent: CONTINENTS.EUROPE,
    coordinates: '52.17007877167873,6.112269919997615',
    city: null,
  },
  {
    country: COUNTRIES.UNITED_KINGDOM,
    code: 'fast',
    name: DATA_CENTER_NAMES.UKFAST,
    isEcoEnergy: true,
    continent: CONTINENTS.EUROPE,
    coordinates: '55.219427246520745,-2.516336801811168',
    city: null,
  },
  {
    country: COUNTRIES.UNITED_STATES,
    code: 'phx',
    name: DATA_CENTER_NAMES.PHOENIX,
    isEcoEnergy: true,
    continent: CONTINENTS.NORTH_AMERICA,
    coordinates: '37.57972348658329, -112.40587738270018',
    city: 'Phoenix',
  },
  {
    country: COUNTRIES.UNITED_STATES,
    code: 'imm',
    name: DATA_CENTER_NAMES.IMMEDION,
    continent: CONTINENTS.NORTH_AMERICA,
    isEcoEnergy: true,
    coordinates: '38.90243581512788, -92.37244771848646',
    city: 'Asheville',
  },
  {
    country: COUNTRIES.UNITED_STATES,
    code: 'bos',
    name: DATA_CENTER_NAMES.DIGITAL_REALITY,
    continent: CONTINENTS.NORTH_AMERICA,
    isEcoEnergy: true,
    coordinates: '42.360927105567455, -85.06025021770576',
    city: 'Boston',
  },
  {
    country: COUNTRIES.BRAZIL,
    code: 'asc',
    name: DATA_CENTER_NAMES.ASCENTY,
    isEcoEnergy: true,
    continent: CONTINENTS.SOUTH_AMERICA,
    coordinates: '-8.84511203163033,-54.34406769660683',
    city: null,
  },
  {
    country: COUNTRIES.INDIA,
    code: 'mum',
    name: DATA_CENTER_NAMES.MUMBAI,
    isEcoEnergy: false,
    continent: CONTINENTS.ASIA,
    coordinates: '22.97864078104664,79.21960223762875',
    city: null,
  },
  {
    country: COUNTRIES.SINGAPORE,
    code: 'nme',
    name: DATA_CENTER_NAMES.NEW_MEDIA_EXPRESS,
    isEcoEnergy: false,
    continent: CONTINENTS.ASIA,
    coordinates: '1.2796711351800814,103.84972469604368',
    city: null,
  },
] as const;

export const PING_ENDPOINT = 'https://ping.{code}.hstgr.cloud/ping';
