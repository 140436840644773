import type { RouteRecordRaw } from 'vue-router';

import { MARKETPLACE_NAVIGATION } from '@/data/marketplaceData';
import googleAdsGuard from '@/guards/googleAdsGuard';
import pageNotFoundGuard from '@/guards/pageNotFoundGuard';
import paidInvoicesGuard from '@/guards/paidInvoicesGuard';
import { Route } from '@/types';
import { appForcedLogout } from '@/utils/services/authService';

export default [
  {
    path: '/',
    name: Route.Base.HOME,
    meta: {
      title: 'Home',
      sideMenuExists: false,
      showSubheader: false,
      menu: ['horizontal'],
    },
    alias: '/home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/hosting-offer/:domain?',
    name: Route.Base.HOSTING_OFFER,
    meta: {
      hideTitle: true,
      title: 'Offer',
    },
    component: () => import('@/views/HostingOffer.vue'),
  },
  {
    path: '/buy-vps',
    name: Route.Base.BUY_VPS,
    meta: {
      title: 'Purchase VPS',
      hideTitle: true,
      breadcrumbs: false,
    },
    component: () => import('@/views/Servers/ServersBuyVPS.vue'),
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      title: 'Authentication',
      menu: [],
      sideMenuExists: false,
      showSubheader: false,
      header: false,
    },
    component: () => import('@/views/Authentication.vue'),
  },
  {
    path: '/login',
    name: Route.Base.LOGIN,
    meta: {
      title: 'Login',
    },
    beforeEnter() {
      // performs cleanups and navigates to the login page
      appForcedLogout();
    },
  },
  {
    path: '/marketing-tools/:domain/:id',
    name: 'marketing-tools',
    meta: {
      title: 'Marketing panel',
      breadcrumbsTitle: { title: '{domain}', translate: false },
      subHeaderTitle: { title: '{domain}', translate: false },
      menu: ['horizontal'],
      icon: 'ic-help',
      subheaderImage: () =>
        import('@/components/RankingCoach/RankingCoachHeader.vue'),
    },
    component: () => import('@/views/MarketingPanel.vue'),
  },
  {
    path: '/ssl',
    name: 'ssl',
    meta: {
      title: 'SSL certificates',
      menu: ['horizontal'],
    },
    component: () => import('@/views/SslCertificates.vue'),
  },
  {
    path: '/paid-invoices',
    name: 'paid_invoices',
    meta: {
      title: 'List of Paid Invoices in the Last 30 days',
      sideMenuExists: 'hidden',
      hideBackButton: true,
      breadcrumbs: false,
    },
    beforeEnter: (to, from, next) => paidInvoicesGuard(to, from, next),
    component: () => import('@/views/AutoRefund/PaidInvoices.vue'),
  },
  {
    path: '/help',
    name: 'help',
    meta: {
      title: 'Help',
      showSubheader: false,
      menu: ['horizontal'],
      icon: 'ic-help',
    },
    component: () => import('@/views/Help.vue'),
  },
  {
    path: '/google-ads',
    name: 'google_voucher',
    meta: {
      title: 'Google Ads',
      showSubheader: false,
      fullscreen: true,
    },
    beforeEnter: (to, from, next) => googleAdsGuard(to, from, next),
    component: () => import('@/views/GoogleVoucher.vue'),
  },
  {
    path: '/redirect/:domain',
    name: 'redirect-single',
    meta: {
      title: 'Redirecting...',
      sideMenuExists: false,
      showSubheader: false,
      header: 'simple-header',
      setFullscreen: true,
    },
    component: () => import('@/views/Redirect.vue'),
  },
  {
    path: '/redirect',
    name: 'redirect',
    meta: {
      title: 'Redirecting...',
      sideMenuExists: false,
      showSubheader: false,
      header: 'simple-header',
      setFullscreen: true,
    },
    component: () => import('@/views/Redirect.vue'),
  },
  {
    path: '/redirect-to',
    name: 'redirect_to',
    meta: {
      title: 'Redirecting...',
      setFullscreen: true,
      showSubheader: false,
      sideMenuExists: false,
      header: false,
    },
    component: () => import('@/views/RedirectTo.vue'),
  },
  {
    path: '/404',
    name: Route.Base.NOT_FOUND,
    meta: {
      title: 'Page Not Found',
      sideMenuExists: false,
      showSubheader: false,
    },
    beforeEnter: pageNotFoundGuard,
    component: () => import('@/views/404.vue'),
  },
  {
    path: '/error',
    name: Route.Base.SERVER_DOWN,
    meta: {
      title: 'Something went wrong',
      sideMenuExists: false,
      showSubheader: false,
    },
    component: () => import('@/views/ServerDown.vue'),
  },
  {
    path: '/ping',
    meta: {
      header: false,
      showSubheader: false,
      fullscreen: true,
      intercom: false,
    },
    component: () => import('@/views/Ping.vue'),
  },
  {
    path: '/marketplace',
    name: Route.Base.MARKETPLACE,
    meta: {
      title: 'Marketplace',
      usePageWithSideMenu: true,
    },
    component: () => import('@/views/Marketplace.vue'),
    children: MARKETPLACE_NAVIGATION.map((item) => ({
      path: item.path,
      name: item.name,
      meta: {
        categoryTitle: item.title,
      },
    })),
  },
  { path: '/:catchAll(.*)', redirect: '/404' },
] as RouteRecordRaw[];
