import { useExperiment } from './useExperiment';

import { Experiment } from '@/types';

export const useHpanelHostingTabV2Experiment = () => {
  const { isExperimentActive } = useExperiment(
    Experiment.ID.HPANEL_HOSTING_TAB_V2,
  );

  return {
    isHpanelHostingTabV2ExperimentActive: isExperimentActive,
  };
};
