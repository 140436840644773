import type { Hosting } from '@/types';

export interface IWebsiteOwnershipResponse {
  id: number;
  state: string;
  token: string;
}

export interface IWebsiteOwnershipRequestData {
  email: string;
  databases: string[];
}

export interface IWebsiteTransferAcceptRequestData {
  token: string;
}

export interface IOwnershipTransferEligibilityResponse {
  eligible: boolean;
}

export interface IOwnershipTransferExtended extends IOwnershipTransfer {
  temporaryDomain: string;
}
export interface IOwnershipTransfer {
  databases: string[];
  expiresAt: string;
  fromClientId: number | string;
  fromOrderId: number;
  fromDomain: string;
  fromUsername: string;
  toClientId: number | string;
  transferRequestState: Hosting.OwnershipTransferRequestState;
  transferState?: Hosting.OwnershipTransferState;
  transferToken: string;
  toClientEmail: string;
  createdAt: string;
  updatedAt: string;
}

export const LISTING_MODE_PARAMETER = {
  ALL: 'all',
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
} as const;

export type OwnershipTransferListingParam =
  (typeof LISTING_MODE_PARAMETER)[keyof typeof LISTING_MODE_PARAMETER];
