<script setup lang="ts">
import dayjs from 'dayjs';
import { computed, ref, watch } from 'vue';

import { useGlobals } from '@/composables';
import { useLanguages } from '@/composables/useLanguages';
import { useLanguageStore } from '@/stores';
import {
  AVAILABLE_LANGUAGE_CONFIGURATIONS,
  AmplitudeEvent,
  LANGUAGE_CODE,
  type TLanguageCode,
} from '@/types';

type Props = {
  searchTerm?: string;
};

type Emits = {
  close: [];
};

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const { amplitudeV2 } = useGlobals();
const languageStore = useLanguageStore();
const { getCurrentLanguageCode, getLanguageByCode, getLanguageNameByCode } =
  useLanguages();

const handleCurrentLanguageChange = () => {
  const currentLanguageCode = getCurrentLanguageCode();

  return currentLanguageCode === 'en_US' ? 'en_GB' : currentLanguageCode;
};

const currentLanguageCode = ref(handleCurrentLanguageChange());

const handleLanguageClick = async (language: TLanguageCode) => {
  emits('close');

  amplitudeV2(AmplitudeEvent.LanguageSelector.LANGUAGE_SELECTED, {
    language,
  });

  await languageStore.changeLanguage(language);

  const localeLanguage =
    language === LANGUAGE_CODE.ar_AR ? LANGUAGE_CODE.en_GB : language;

  dayjs.locale(localeLanguage);
};

const filteredLanguages = computed(() => {
  if (!props.searchTerm) {
    return AVAILABLE_LANGUAGE_CONFIGURATIONS;
  }
  const searchTerm = props.searchTerm?.toLowerCase();

  return AVAILABLE_LANGUAGE_CONFIGURATIONS.filter(
    ({ name, nativeName }) =>
      nativeName.toLowerCase().includes(searchTerm) ||
      name.toLowerCase().includes(searchTerm),
  );
});

const columns = computed(() => {
  const columnCount = 3;
  const itemsPerColumn = Math.ceil(
    filteredLanguages.value.length / columnCount,
  );

  return Array.from({ length: columnCount }, (_, index) =>
    filteredLanguages.value.slice(
      index * itemsPerColumn,
      (index + 1) * itemsPerColumn,
    ),
  );
});

watch(
  () => languageStore.currentLanguage,
  () => {
    // Update when language changes in store. This is needed because of the cookie language
    currentLanguageCode.value = handleCurrentLanguageChange();
  },
);
</script>

<template>
  <div class="languages">
    <div v-for="(column, index) in columns" :key="index">
      <div
        v-for="{ code } in column"
        :id="`hpanel_tracking-h_header-languages_selector-${code}`"
        :key="code"
        :data-qa="`hpanel_tracking-h_header-languages_selector-${code}`"
        class="languages__column"
      >
        <button
          class="language"
          :class="{
            'language--active': currentLanguageCode === code,
          }"
          @click="handleLanguageClick(code)"
        >
          <span class="language__title text-body-2">
            {{ getLanguageByCode(code)?.nativeName }}
          </span>
          <span class="language__subtitle text-body-3">
            {{ getLanguageNameByCode(code) }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.languages {
  @import '@/sass/_global-mixins.scss';
  height: 100%;
  overflow: auto;
  padding: 16px 16px 220px 16px;
  margin: 0;
  @include customScroll;

  @media (min-width: 577px) {
    grid-column-gap: 8px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }
}

.language {
  background-color: unset;
  border: none;
  outline: none;

  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  padding: 12px;
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-dark-hover);
  }
  &--active {
    background-color: var(--primary-hover);
    border-radius: 8px;
  }

  &--active :is(&__title, &__subtitle) {
    color: var(--primary-dark);
  }

  &__title {
    color: var(--dark);
  }
}
</style>
