import { Client, Currency } from '@/types';

export const resellerCurrencyData = {
  [Client.BrandId.Hostinger.RU]: {
    code: Currency.Code.RUB,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price}  руб.',
  },
  [Client.BrandId.Hostinger.LT]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '€{price}',
  },
  [Client.BrandId.Hostinger.COM_UA]: {
    code: Currency.Code.UAH,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} грн.',
  },
  [Client.BrandId.Hostinger.CO_ID]: {
    code: Currency.Code.IDR,
    decimalCount: 0,
    decimalPoint: '',
    thousandsSep: '.',
    format: 'Rp{price}',
  },
  [Client.BrandId.Hostinger.COM]: {
    code: Currency.Code.USD,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: '${price}',
  },
  [Client.BrandId.Hostinger.ES]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} €',
  },
  [Client.BrandId.Hostinger.COM_AR]: {
    code: Currency.Code.ARS,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: '{price} AR$',
  },
  [Client.BrandId.Hostinger.MX]: {
    code: Currency.Code.MXN,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: '{price} MX$',
  },
  [Client.BrandId.Hostinger.CO]: {
    code: Currency.Code.COP,
    decimalCount: 0,
    decimalPoint: '',
    thousandsSep: ',',
    format: '{price} CO$',
  },
  [Client.BrandId.Hostinger.COM_BR]: {
    code: Currency.Code.BRL,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: 'R${price}',
  },
  [Client.BrandId.Hostinger.RO]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} €',
  },
  [Client.BrandId.Hostinger.FR]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} €',
  },
  [Client.BrandId.Hostinger.IT]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} €',
  },
  [Client.BrandId.Hostinger.PL]: {
    code: Currency.Code.PLN,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} zł',
  },
  [Client.BrandId.Hostinger.PH]: {
    code: Currency.Code.PHP,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: 'PHP {price}',
  },
  [Client.BrandId.Hostinger.AE]: {
    code: Currency.Code.USD,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: '${price}',
  },
  [Client.BrandId.Hostinger.MY]: {
    code: Currency.Code.MYR,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: 'RM {price}',
  },
  [Client.BrandId.Hostinger.KR]: {
    code: Currency.Code.KRW,
    decimalCount: 0,
    decimalPoint: '',
    thousandsSep: ',',
    format: '{price}원',
  },
  [Client.BrandId.Hostinger.VN]: {
    code: Currency.Code.VND,
    decimalCount: 0,
    decimalPoint: '',
    thousandsSep: '.',
    format: '{price} VNĐ',
  },
  [Client.BrandId.Hostinger.IN_TH]: {
    code: Currency.Code.THB,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: '{price}฿',
  },
  [Client.BrandId.Hostinger.COM_TR]: {
    code: Currency.Code.TRY,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} TL',
  },
  [Client.BrandId.Hostinger.PT]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price}€',
  },
  [Client.BrandId.Hostinger.DE]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price}€',
  },
  [Client.BrandId.Hostinger.IN]: {
    code: Currency.Code.INR,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: '₹ {price}',
  },
  [Client.BrandId.Hostinger.JP]: {
    code: Currency.Code.JPY,
    decimalCount: 0,
    decimalPoint: '',
    thousandsSep: ',',
    format: '{price} 円',
  },
  [Client.BrandId.Hostinger.NL]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '€{price}',
  },
  [Client.BrandId.Hostinger.CO_UK]: {
    code: Currency.Code.GBP,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: '£{price}',
  },
  [Client.BrandId.Hostinger.GR]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: '{price} €',
  },
  [Client.BrandId.Hostinger.CZ]: {
    code: Currency.Code.CZK,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} Kč',
  },
  [Client.BrandId.Hostinger.HU]: {
    code: Currency.Code.HUF,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} Ft',
  },
  [Client.BrandId.Hostinger.SE]: {
    code: Currency.Code.SEK,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: '{price} kr',
  },
  [Client.BrandId.Hostinger.DK]: {
    code: Currency.Code.DKK,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: 'DKK. {price}',
  },
  [Client.BrandId.Hostinger.FI]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price}€',
  },
  [Client.BrandId.Hostinger.SK]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '{price} €',
  },
  [Client.BrandId.Hostinger.NO]: {
    code: Currency.Code.NOK,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: 'Kr {price}',
  },
  [Client.BrandId.Hostinger.HR]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '€{price}',
  },
  [Client.BrandId.Hostinger.COM_HK]: {
    code: Currency.Code.CNY,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: '{price}元',
  },
  [Client.BrandId.Hostinger.CO_IL]: {
    code: Currency.Code.ILS,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: 'ש"ח {price}',
  },
  [Client.BrandId.Hostinger.LV]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: ',',
    thousandsSep: '.',
    format: '€{price}',
  },
  [Client.BrandId.Hostinger.EE]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: '{price}€',
  },
  [Client.BrandId.Other.WEBLINK]: {
    code: Currency.Code.BRL,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: 'R${price}',
  },
  [Client.BrandId.Other.HOSTMANIA]: {
    code: Currency.Code.EUR,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: '€{price}',
  },
  [Client.BrandId.Other.HOSTING24]: {
    code: Currency.Code.USD,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: '',
    format: '${price}',
  },
  [Client.BrandId.Other.NG_CO_ID]: {
    code: Currency.Code.IDR,
    decimalCount: 0,
    decimalPoint: '',
    thousandsSep: '.',
    format: 'Rp{price}',
  },
  [Client.BrandId.Hostinger.PK]: {
    code: Currency.Code.PKR,
    decimalCount: 2,
    decimalPoint: '.',
    thousandsSep: ',',
    format: 'Rs. {price}',
  },
};
