import { ref, computed } from 'vue';

import WebsiteOwnershipTransferModal from '@/components/Modals/HModal/WebsiteOwnershipTransferModal.vue';
import {
  useCookies,
  useFeatureFlag,
  useHostingStatus,
  useModal,
  useWebsite,
} from '@/composables';
import type { ProWebsiteType } from '@/types';
import { Cookie, FeatureFlag, Hosting } from '@/types';
import { getDaysUntil } from '@/utils/helpers';

export const useWebsiteOwnershipTransfer = (websiteObject: ProWebsiteType) => {
  const { isSuspended, getIsExpiryWithinDays } =
    useHostingStatus(websiteObject);
  const { isWordPressType, isOwnWebsite, isBeingDeleted } =
    useWebsite(websiteObject);
  const { openModal } = useModal();

  const { isFeatureEnabled: isWebsiteOwnershipFeatureEnabled } = useFeatureFlag(
    FeatureFlag.ID.WEBSITE_OWNERSHIP_TRANSFER,
  );

  const { cookie: hasAllowToTransferNewCookie } = useCookies(
    Cookie.WEBSITES_OWNERSHIP_ALLOW_TO_TRANSFER_NEW,
  );

  const website = ref(websiteObject);

  const isPendingOrActiveTransferRequest = computed(
    () =>
      website.value.ownershipTransferState ===
      Hosting.OwnershipTransferWebsiteState.PENDING,
  );

  const isWebsiteOlderThan1Day = computed(
    () =>
      !!(
        Math.abs(getDaysUntil(website.value.createdAt)) >= 1 ||
        hasAllowToTransferNewCookie.value
      ),
  );

  const canTransferOwnership = computed(
    () =>
      isWebsiteOlderThan1Day.value &&
      isOwnWebsite.value &&
      isWordPressType.value &&
      !isSuspended.value &&
      !isPendingOrActiveTransferRequest.value &&
      !getIsExpiryWithinDays(7) &&
      !isBeingDeleted.value &&
      websiteObject.vhostType !== Hosting.Vhost.SUBDOMAIN,
  );

  const transferDenialReason = computed(() => {
    if (canTransferOwnership.value) {
      return '';
    }
    if (!isWebsiteOlderThan1Day.value) {
      return 'Website is not older than 1 day';
    }

    if (isSuspended.value) {
      return 'Website is suspended';
    }

    if (isPendingOrActiveTransferRequest.value) {
      return 'There is an active or pending transfer request';
    }

    if (getIsExpiryWithinDays(7)) {
      return 'Website is expiring within 7 days';
    }

    if (isBeingDeleted.value) {
      return 'Website is being deleted';
    }

    return 'Website is not eligible for ownership transfer';
  });

  const openOwnershipTransferModal = (additionalData = {}) => {
    openModal({
      component: { WebsiteOwnershipTransferModal },
      data: {
        website: website.value,
        ...additionalData,
      },
    });
  };

  return {
    canTransferOwnership,
    transferDenialReason,
    openOwnershipTransferModal,
    isWebsiteOwnershipFeatureEnabled,
  };
};
