import type { SurveyReqParams, OnboardingCompleted } from '@/types';
import { toASCII, hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

const ONBOARDING_API_URL = `${process.env.VITE_API_SSO}/v1/onboarding`;

export default {
  surveysUrl: `${process.env.VITE_API_SURVEYS}/api/jwt/v1/survey`,

  async storeSurveyResults(params: SurveyReqParams, orderId: string) {
    return await hAsync<boolean>(
      http.post(`${ONBOARDING_API_URL}/survey`, {
        ...params,
        orderId,
      }),
    );
  },

  async getOnboardingCompleted(orderId: string, domain: string) {
    return await hAsync<OnboardingCompleted>(
      http.get(`${ONBOARDING_API_URL}/completed`, {
        params: { orderId, domain: toASCII(domain) },
      }),
    );
  },

  async getOnboardingAutoInstaller(orderId: string) {
    return await hAsync(
      http.get(`${ONBOARDING_API_URL}/auto-installer`, {
        params: { orderId },
        hideToastr: true,
      }),
    );
  },
};
