import type { HostingPlanPerks } from '@/types';
import { Email } from '@/types';

export enum Perks {
  STORAGE_PER_ACCOUNT = '<b>{count} GB</b> storage per account',
  STORAGE_PER_MAILBOX = '{count} Storage per Mailbox',
  FORWARDERS = '{count} Forwarding Rules',
  ANTI_SPAM_VIRUS = 'Advanced anti-spam and anti-virus',
  CUSTOM_DKIM = 'Custom DKIM',
  CLOUD_BASED = 'Cloud-based infrastructure',
  STORAGE_PER_USER = '{count} GB storage per user',
  VIDEO_MEETINGS = '{count} participant video meetings',
  SECURITY_MANAGEMENT = 'Security and management controls',
  COLLABORATIVE_CONTENT_CREATION = 'Collaborative content creation',
  SECURE_CLOUD_STORAGE = 'Secure cloud storage',
  ALLOW_LIST = 'Allowlist',
  ADVANCED_PHISHING_MALWARE_PROTECTION = 'Advanced phishing and malware protection',
  INBUILT_CALENDAR_AND_CONTACTS = 'Inbuilt Calendar and Contacts',
  BUILTIN_CALENDAR_AND_CONTACTS = 'Built in Calendar & Contacts',
  READ_RECEIPTS = '{count} Read receipts',
  UNLIMITED_READ_RECEIPTS = 'Unlimited Read receipts',
  EMAIL_TEMPLATES = '{count} Email Templates',
  UNLIMITED_EMAIL_TEMPLATES = 'Unlimited Email Templates',
  UNLIMITED_EXTERNAL_EMAIL_FORWARDERS = 'Unlimited External Email Forwarders',
  EXTERNAL_EMAIL_FORWARDERS = '{count} External Email Forwarders',
  ONE_EMAIL_FORWARDER = '1 External Email Forwarder',
  CONTACT_GROUPS = '{count} Contact Group',
  UNLIMITED_CONTACT_GROUPS = 'Unlimited Contact Groups',
  FOLLOW_UP_REMINDERS = 'Follow-up Reminders',
  SEND_LATER = 'Send Later',
  PRIORITY_INBOX = 'Priority Inbox',
  HTML_IN_COMPOSER = 'HTML in Composer',
  SEND_AS_ALIAS = 'Send as Alias',
  UNDO_SEND = 'Undo Send',
  ADVANCED_ANTI_SPAM = 'Advanced Anti-spam',
  ADVANCED_ANTI_VIRUS = 'Advanced Anti-virus',
  TWO_FACTOR_AUTHENTICATION = 'Two-factor Authentication',
  BLOCK_SENDER = 'Block Sender',
  MULTI_ACCOUNT_SUPPORT = 'Multi-account Support',
  BASE_CONTACT_GROUPS = 'Contact Groups',
  ONE_EXTERNAL_EMAIL_FORWARDER = '1 External Email Forwarder',
  AUTO_REPLY = 'Auto-reply',
  SECURE_WEB_APPLICATION = 'Secure Web Application',
  ACCESS_WITH_ANY_DEVICE = 'Access with any App or Device',
  PERSONALIZED_DOMAIN_NAME = 'Personalized domain name',
  CONTACT_SERVICE = 'Contacts Service',
  FULL_ACCOUNT_MANAGEMENT = 'Full Account Management',
  EASY_CONFIGURATION_WIZARD = 'Easy Configuration Wizard',
  EMAIL_FORWARDING = 'Email Forwarding',
  EMAIL_ALIASES = 'Email Aliases',
  CATCH_ALL_ACCOUNT = 'Catch-all Account',
  AUTOMATIC_REPLIES = 'Automatic Replies',
  EMAIL_IMPORTING = 'Importing of Existing Emails',
  AUDIT_LOGS = 'Audit Logs',
  WORKS_ON_MOBILE_AND_DESKTOP = 'Works on Mobile and Desktop',
  EMAIL_FILTERS = 'Email Filters and Forwarding',
  CONTACT_LISTS = 'Contact lists and groups',
  PERSONALIZED_SIGNATURES = 'Personalized Signatures',
  ADVANCED_SEARCH = 'Advanced Search',
  IMPORT_AND_EXPORT = 'Import and Export functions',
  LIGHT_AND_DARK_THEMES = 'Light and Dark Themes',
  AVAILABLE_IN_LANGUAGES = 'Available in over 80 Languages',
  ENTERPRISE_ANTI_SPAM = 'Enterprise Grade Anti-spam',
  ENTERPRISE_ANTI_VIRUS = 'Enterprise Grade Anti-virus',
  DKIM_AND_OTHER_SUPPORT = 'DKIM, DMARC, SPF, ARC Support',
  DATA_ENCRYPTION = 'Data Encryption',
  MONEY_BACK_GUARANTEE = '30 Days Money Back Guarantee',
  SUPPORT = '24/7 Support from Hostinger Experts',
  UPTIME = '99.90% Uptime Guarantee',
  CLOUD_BASE = 'Cloud Based Infrastructure',
  UNLIMITED_ALIAS = 'Unlimited Email Aliases',
  ADVANCED_PHISHING_SPAM_MALWARE_PROTECTION = 'Advanced phishing, spam and malware protection',
}

export const STORAGE_PER_ACCOUNT_TOOLTIP =
  'Specific storage space is assigned for each created account';

export const FORWARDERS_TOOLTIP =
  'Forward emails sent to your account to any other email address (even from a different domain)';

export const ALIASES_TOOLTIP =
  'Receive emails to your chosen domain-based account by using multiple alternative email addresses. Alias limit: {count}';

export const ANTI_SPAM_VIRUS_TOOLTIP =
  'Top-notch security measures to keep your accounts safe from spam, malware, phishing attacks, etc.';

const CUSTOM_DKIM_TOOLTIP =
  'Custom DKIM (DomainKeys Identified Mail) authenticates the email sender’s domain. Generate a domain-matched DKIM key to prove that you are the actual sender of your emails.';

const STORAGE_PER_ACCOUNT_TITAN_TOOLTIP =
  'A specific storage space will be allotted for each mailbox that is created';

const INBUILT_CALENDAR_AND_CONTACTS_TOOLTIP =
  'View your calendar by day, week or month, and share it with peers within and outside Titan. Store relevant details about your contacts - like phone number, company details, location - so you’re not scrambling for information when you need it.';

const READ_RECEIPTS_TOOLTIP = `Don't lose sleep wondering if your prospect read that crucial quote. With Read Receipts, get notified the moment your recipient opens your email.`;

const EMAIL_TEMPLATES_TOOLTIP =
  'Save time by saving your frequently sent responses as templates and ensure that you always send a message that resonates with your customers';

const EXTERNAL_EMAIL_FORWARDERS_TOOLTIP =
  'Forward incoming emails to up to 3 email addresses outside of Titan';

const ALLOW_LIST_TOOLTIP =
  'Add specific senders to your Allowlist and ensure all emails from them come right through to your Inbox and don’t end up in Spam.';

const CONTACT_GROUPS_TOOLTIP =
  'Group your frequently emailed recipients together to reach out to them simultaneously, so you no longer have to add each email address when composing a new message';

const MULTI_ACCOUNT_SUPPORT_TOOLTIP = `Set up essential mailboxes like sales{'@'}yourdomain.com and info{'@'}yourdomain.com, and switch between them in one interface`;

const FOLLOW_UP_REMINDERS_TOOLTIP = `Don't settle for a non-reply to your important emails. Get nudges to circle back on the thread at the perfect time and keep your conversations going.`;

const SEND_LATER_TOOLTIP =
  'Whether you’ve identified the optimal send time for a customer or you need to draft a message during off-hours, schedule your emails to send when they’re most likely to be read';

const PRIORITY_INBOX_TOOLTIP =
  'Automatically prioritize your most important messages based on your mailbox behavior and view them in a separate tab';

const HTML_IN_COMPOSER_TOOLTIP =
  'Generate a higher customer response rate by making your emails more visually engaging. Use HTML codes to include forms, CTA buttons, and other interactive elements in your emails.';

const SEND_AS_ALIAS_TOOLTIP =
  'Send emails using your alias identity to keep your primary email address hidden';

const UNDO_SEND_TOOLTIP =
  'Mistakes happen - and you can fix them. Undo your sent email to make a grammatical correction or add that attachment you missed.';

const ADVANCED_ANTI_SPAM_TOOLTIP =
  'Enhanced protection of your inbox to ensure incoming attachments are free from viruses, malware, phishing attacks, and more';

const ADVANCED_ANTI_VIRUS_TOOLTIP =
  'Top-notch security measures to keep unwanted spam out of your inbox';

const CUSTOM_DKIM_TITAN_TOOLTIP =
  'Get an additional layer of security that creates a digital signature for your domain and informs your recipient that you are a legitimate and verified sender';

const TWO_FACTOR_AUTHENTICATION_TOOLTIP =
  'Add an additional layer of security to your email account to ensure your emails are accessible to only you';

const BLOCK_SENDER_TOOLTIP =
  'Keep emails from unwanted senders out of your inbox. Block specific email addresses, or all emails from a certain domain, and enjoy a clutter-free inbox.';

const AUTO_REPLY_TOOLTIP =
  'Automatically reply to incoming emails while you are away to avoid keeping your senders waiting';

export const ALIAS_PERK = {
  id: Email.PerkId.ALIASES,
  text: Perks.EMAIL_ALIASES,
  tooltip: ALIASES_TOOLTIP,
};

const TITAN_ULTRA_STORAGE = {
  id: Email.PerkId.STORAGE_PER_ACCOUNT,
  text: Perks.STORAGE_PER_ACCOUNT,
  textValues: { count: 100 },
  state: Email.PerkStatus.SUCCESS,
  tooltip: STORAGE_PER_ACCOUNT_TITAN_TOOLTIP,
};

const EMAIL_CAMPAIGN = {
  id: Email.PerkId.EMAIL_CAMPAIGNS,
  text: 'Email Campaigns',
  tooltip:
    'Send good-looking personalized emails to multiple recipients at once, and keep the recipient list to yourself. You can also track who opened your email.',
  state: Email.PerkStatus.SUCCESS,
};

const SMART_AI_WRITE = {
  id: Email.PerkId.SMART_WRITE_AI,
  text: 'Smart Write AI',
  tooltip:
    'Get ready-to-use email drafts in seconds for any requirement, powered by Titan AI.',
  state: Email.PerkStatus.SUCCESS,
};

const APPOINTMENT_BOOKING = {
  id: Email.PerkId.APPOINTMENT_BOOKING,
  text: 'Titan Appointment Bookings',
  tooltip:
    'Create a unique meeting link and enable clients, customers, and team members to find time on your calendar and seamlessly schedule meetings with you.',
  state: Email.PerkStatus.SUCCESS,
};

const ATTACHMENTS_AND_LINK_TRACKING = {
  id: Email.PerkId.ATTACHMENTS_AND_LINK_TRACKING,
  text: 'Attachment and Link Tracking',
  tooltip:
    'Know when recipients download attachments or click on links in your sent emails.',
  state: Email.PerkStatus.SUCCESS,
};

const SIGNATURE_DESIGNER = {
  id: Email.PerkId.SIGNATURE_DESIGNER,
  text: 'Signature Designer',
  tooltip:
    'Use our beautiful templates to stand out with your email signature, and add your contact details, links, and a picture.',
  state: Email.PerkStatus.SUCCESS,
};

const GROUP_TITLE = 'Everything in {plan} and...';

export const featureList: HostingPlanPerks = {
  [Email.ProductId.HOSTINGER_FREE]: [
    {
      id: Email.PerkId.STORAGE_PER_ACCOUNT,
      text: Perks.STORAGE_PER_ACCOUNT,
      textValues: { count: 1 },
      state: Email.PerkStatus.WARNING,
      tooltip: STORAGE_PER_ACCOUNT_TOOLTIP,
    },
    {
      id: Email.PerkId.FORWARDERS,
      text: Perks.FORWARDERS,
      textValues: { count: 1 },
      state: Email.PerkStatus.WARNING,
      tooltip: FORWARDERS_TOOLTIP,
    },
    {
      ...ALIAS_PERK,
      tooltipValues: { count: 5 },
      state: Email.PerkStatus.WARNING,
    },
    {
      id: Email.PerkId.ADVANCED_PHISHING_MALWARE_PROTECTION,
      text: Perks.ADVANCED_PHISHING_SPAM_MALWARE_PROTECTION,
      state: Email.PerkStatus.DANGER,
    },
    {
      id: Email.PerkId.DKIM_AND_OTHER_SUPPORT,
      text: Perks.DKIM_AND_OTHER_SUPPORT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.SECURE_WEB_APPLICATION,
      text: Perks.SECURE_WEB_APPLICATION,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ACCESS_WITH_ANY_DEVICE,
      text: Perks.ACCESS_WITH_ANY_DEVICE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CONTACT_SERVICE,
      text: Perks.CONTACT_SERVICE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EASY_CONFIGURATION_WIZARD,
      text: Perks.EASY_CONFIGURATION_WIZARD,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_FORWARDING,
      text: Perks.EMAIL_FORWARDING,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CATCH_ALL_ACCOUNT,
      text: Perks.CATCH_ALL_ACCOUNT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.AUTOMATIC_REPLIES,
      text: Perks.AUTOMATIC_REPLIES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_IMPORTING,
      text: Perks.EMAIL_IMPORTING,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.SUPPORT,
      text: Perks.SUPPORT,
      state: Email.PerkStatus.SUCCESS,
    },
  ],
  [Email.ProductId.HOSTINGER_PRO_CB]: [
    {
      id: Email.PerkId.STORAGE_PER_ACCOUNT,
      text: Perks.STORAGE_PER_ACCOUNT,
      textValues: { count: 10 },
      state: Email.PerkStatus.WARNING,
      tooltip: STORAGE_PER_ACCOUNT_TOOLTIP,
    },
    {
      id: Email.PerkId.FORWARDERS,
      text: Perks.FORWARDERS,
      textValues: { count: 10 },
      state: Email.PerkStatus.WARNING,
      tooltip: FORWARDERS_TOOLTIP,
    },
    {
      ...ALIAS_PERK,
      tooltipValues: { count: 50 },
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ADVANCED_PHISHING_MALWARE_PROTECTION,
      text: Perks.ADVANCED_PHISHING_SPAM_MALWARE_PROTECTION,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.DKIM_AND_OTHER_SUPPORT,
      text: Perks.DKIM_AND_OTHER_SUPPORT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.SECURE_WEB_APPLICATION,
      text: Perks.SECURE_WEB_APPLICATION,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ACCESS_WITH_ANY_DEVICE,
      text: Perks.ACCESS_WITH_ANY_DEVICE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CONTACT_SERVICE,
      text: Perks.CONTACT_SERVICE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EASY_CONFIGURATION_WIZARD,
      text: Perks.EASY_CONFIGURATION_WIZARD,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_FORWARDING,
      text: Perks.EMAIL_FORWARDING,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CATCH_ALL_ACCOUNT,
      text: Perks.CATCH_ALL_ACCOUNT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.AUTOMATIC_REPLIES,
      text: Perks.AUTOMATIC_REPLIES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_IMPORTING,
      text: Perks.EMAIL_IMPORTING,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.SUPPORT,
      text: Perks.SUPPORT,
      state: Email.PerkStatus.SUCCESS,
    },
  ],
  [Email.ProductId.HOSTINGER_PREMIUM_CB]: [
    {
      id: Email.PerkId.STORAGE_PER_ACCOUNT,
      text: Perks.STORAGE_PER_ACCOUNT,
      textValues: { count: 50 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: STORAGE_PER_ACCOUNT_TOOLTIP,
    },
    {
      id: Email.PerkId.FORWARDERS,
      text: Perks.FORWARDERS,
      textValues: { count: 50 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: FORWARDERS_TOOLTIP,
    },
    {
      ...ALIAS_PERK,
      tooltipValues: { count: 50 },
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ADVANCED_PHISHING_MALWARE_PROTECTION,
      text: Perks.ADVANCED_PHISHING_SPAM_MALWARE_PROTECTION,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.DKIM_AND_OTHER_SUPPORT,
      text: Perks.DKIM_AND_OTHER_SUPPORT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.SECURE_WEB_APPLICATION,
      text: Perks.SECURE_WEB_APPLICATION,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ACCESS_WITH_ANY_DEVICE,
      text: Perks.ACCESS_WITH_ANY_DEVICE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CONTACT_SERVICE,
      text: Perks.CONTACT_SERVICE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EASY_CONFIGURATION_WIZARD,
      text: Perks.EASY_CONFIGURATION_WIZARD,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_FORWARDING,
      text: Perks.EMAIL_FORWARDING,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CATCH_ALL_ACCOUNT,
      text: Perks.CATCH_ALL_ACCOUNT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.AUTOMATIC_REPLIES,
      text: Perks.AUTOMATIC_REPLIES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_IMPORTING,
      text: Perks.EMAIL_IMPORTING,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.SUPPORT,
      text: Perks.SUPPORT,
      state: Email.PerkStatus.SUCCESS,
    },
  ],
  [Email.ProductId.GOOGLE_GSUITE_BASIC]: [
    {
      id: Email.PerkId.STORAGE_PER_USER,
      text: Perks.STORAGE_PER_USER,
      textValues: { count: 30 },
      state: Email.PerkStatus.SUCCESS,
    },
    {
      text: 'Email Matching your Domain Name',
      state: Email.PerkStatus.SUCCESS,
    },
    {
      text: 'Gmail',
      state: Email.PerkStatus.SUCCESS,
    },
    {
      text: 'Calendar',
      state: Email.PerkStatus.SUCCESS,
    },
    {
      text: 'Docs, Sheets, Slides',
      state: Email.PerkStatus.SUCCESS,
    },
    {
      text: 'Chat Team Messaging',
      state: Email.PerkStatus.SUCCESS,
    },
    {
      text: 'AppSheet',
      state: Email.PerkStatus.SUCCESS,
    },
    {
      text: 'Meet Video Conferencing',
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.SECURITY_MANAGEMENT,
      text: Perks.SECURITY_MANAGEMENT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.COLLABORATIVE_CONTENT_CREATION,
      text: Perks.COLLABORATIVE_CONTENT_CREATION,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.SECURE_CLOUD_STORAGE,
      text: Perks.SECURE_CLOUD_STORAGE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ADVANCED_PHISHING_MALWARE_PROTECTION,
      text: Perks.ADVANCED_PHISHING_MALWARE_PROTECTION,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      text: '24/7 support from Google Workspace Experts',
      state: Email.PerkStatus.SUCCESS,
    },
  ],
  [Email.ProductId.TITAN_FREE]: [
    {
      id: Email.PerkId.STORAGE_PER_ACCOUNT,
      text: Perks.STORAGE_PER_ACCOUNT,
      textValues: { count: 1 },
      state: Email.PerkStatus.WARNING,
      tooltip: STORAGE_PER_ACCOUNT_TITAN_TOOLTIP,
    },
    {
      id: Email.PerkId.INBUILT_CALENDAR_AND_CONTACTS,
      text: Perks.INBUILT_CALENDAR_AND_CONTACTS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: INBUILT_CALENDAR_AND_CONTACTS_TOOLTIP,
    },
    {
      id: Email.PerkId.READ_RECEIPTS,
      text: Perks.READ_RECEIPTS,
      textValues: { count: '' },
      state: Email.PerkStatus.DANGER,
      tooltip: READ_RECEIPTS_TOOLTIP,
    },
    {
      id: Email.PerkId.EMAIL_TEMPLATES,
      text: Perks.EMAIL_TEMPLATES,
      textValues: { count: '' },
      state: Email.PerkStatus.DANGER,
      tooltip: EMAIL_TEMPLATES_TOOLTIP,
    },
    {
      id: Email.PerkId.EXTERNAL_EMAIL_FORWARDERS,
      text: Perks.ONE_EXTERNAL_EMAIL_FORWARDER,
      state: Email.PerkStatus.WARNING,
      tooltip: EXTERNAL_EMAIL_FORWARDERS_TOOLTIP,
    },
    {
      id: Email.PerkId.CONTACT_GROUPS,
      text: Perks.BASE_CONTACT_GROUPS,
      state: Email.PerkStatus.DANGER,
      tooltip: CONTACT_GROUPS_TOOLTIP,
    },
    {
      id: Email.PerkId.MULTI_ACCOUNT_SUPPORT,
      text: Perks.MULTI_ACCOUNT_SUPPORT,
      state: Email.PerkStatus.SUCCESS,
      tooltip: MULTI_ACCOUNT_SUPPORT_TOOLTIP,
    },
    {
      id: Email.PerkId.SEND_AS_ALIAS,
      text: Perks.SEND_AS_ALIAS,
      state: Email.PerkStatus.DANGER,
      tooltip: SEND_AS_ALIAS_TOOLTIP,
    },
    {
      id: Email.PerkId.UNDO_SEND,
      text: Perks.UNDO_SEND,
      state: Email.PerkStatus.DANGER,
      tooltip: UNDO_SEND_TOOLTIP,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_SPAM,
      text: Perks.ADVANCED_ANTI_SPAM,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ADVANCED_ANTI_SPAM_TOOLTIP,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_VIRUS,
      text: Perks.ADVANCED_ANTI_VIRUS,
      state: Email.PerkStatus.DANGER,
      tooltip: ADVANCED_ANTI_VIRUS_TOOLTIP,
    },
    {
      id: Email.PerkId.CUSTOM_DKIM,
      text: Perks.CUSTOM_DKIM,
      state: Email.PerkStatus.SUCCESS,
      tooltip: CUSTOM_DKIM_TITAN_TOOLTIP,
    },
    {
      id: Email.PerkId.TWO_FACTOR_AUTHENTICATION,
      text: Perks.TWO_FACTOR_AUTHENTICATION,
      state: Email.PerkStatus.DANGER,
      tooltip: TWO_FACTOR_AUTHENTICATION_TOOLTIP,
    },
  ],
  [Email.ProductId.TITAN_PRO_CB]: [
    {
      id: Email.PerkId.STORAGE_PER_ACCOUNT,
      text: Perks.STORAGE_PER_ACCOUNT,
      textValues: { count: 10 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: STORAGE_PER_ACCOUNT_TITAN_TOOLTIP,
    },
    {
      id: Email.PerkId.READ_RECEIPTS,
      text: Perks.READ_RECEIPTS,
      textValues: { count: 50 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: READ_RECEIPTS_TOOLTIP,
    },
    {
      id: Email.PerkId.EMAIL_TEMPLATES,
      text: Perks.EMAIL_TEMPLATES,
      textValues: { count: 3 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: EMAIL_TEMPLATES_TOOLTIP,
    },
    {
      id: Email.PerkId.EXTERNAL_EMAIL_FORWARDERS,
      text: Perks.EXTERNAL_EMAIL_FORWARDERS,
      textValues: { count: 3 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: EXTERNAL_EMAIL_FORWARDERS_TOOLTIP,
    },
    {
      id: Email.PerkId.CONTACT_GROUPS,
      text: Perks.CONTACT_GROUPS,
      textValues: { count: 1 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: CONTACT_GROUPS_TOOLTIP,
    },
    {
      id: Email.PerkId.INBUILT_CALENDAR_AND_CONTACTS,
      text: Perks.BUILTIN_CALENDAR_AND_CONTACTS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: INBUILT_CALENDAR_AND_CONTACTS_TOOLTIP,
    },
    {
      id: Email.PerkId.MULTI_ACCOUNT_SUPPORT,
      text: Perks.MULTI_ACCOUNT_SUPPORT,
      state: Email.PerkStatus.SUCCESS,
      tooltip: MULTI_ACCOUNT_SUPPORT_TOOLTIP,
    },
    {
      id: Email.PerkId.AUTO_REPLY,
      text: Perks.AUTO_REPLY,
      state: Email.PerkStatus.SUCCESS,
      tooltip: AUTO_REPLY_TOOLTIP,
    },
    {
      id: Email.PerkId.BLOCK_SENDER,
      text: Perks.BLOCK_SENDER,
      state: Email.PerkStatus.SUCCESS,
      tooltip: BLOCK_SENDER_TOOLTIP,
    },
    {
      id: Email.PerkId.ALLOW_LIST,
      text: Perks.ALLOW_LIST,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ALLOW_LIST_TOOLTIP,
    },
    {
      id: Email.PerkId.UNDO_SEND,
      text: Perks.UNDO_SEND,
      state: Email.PerkStatus.SUCCESS,
      tooltip: UNDO_SEND_TOOLTIP,
    },
    {
      id: Email.PerkId.SEND_AS_ALIAS,
      text: Perks.SEND_AS_ALIAS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: SEND_AS_ALIAS_TOOLTIP,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_SPAM,
      text: Perks.ADVANCED_ANTI_SPAM,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ADVANCED_ANTI_SPAM_TOOLTIP,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_VIRUS,
      text: Perks.ADVANCED_ANTI_VIRUS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ADVANCED_ANTI_VIRUS_TOOLTIP,
    },
    {
      id: Email.PerkId.TWO_FACTOR_AUTHENTICATION,
      text: Perks.TWO_FACTOR_AUTHENTICATION,
      state: Email.PerkStatus.SUCCESS,
      tooltip: TWO_FACTOR_AUTHENTICATION_TOOLTIP,
    },
    {
      id: Email.PerkId.CUSTOM_DKIM,
      text: Perks.CUSTOM_DKIM,
      state: Email.PerkStatus.SUCCESS,
      tooltip: CUSTOM_DKIM_TITAN_TOOLTIP,
    },
  ],
  [Email.ProductId.TITAN_PREMIUM_CB]: [
    {
      id: Email.PerkId.STORAGE_PER_ACCOUNT,
      text: Perks.STORAGE_PER_ACCOUNT,
      textValues: { count: 50 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: STORAGE_PER_ACCOUNT_TITAN_TOOLTIP,
    },
    {
      title: GROUP_TITLE,
    },
    {
      id: Email.PerkId.SEND_LATER,
      text: Perks.SEND_LATER,
      state: Email.PerkStatus.SUCCESS,
      tooltip: SEND_LATER_TOOLTIP,
    },
    {
      id: Email.PerkId.PRIORITY_INBOX,
      text: Perks.PRIORITY_INBOX,
      state: Email.PerkStatus.SUCCESS,
      tooltip: PRIORITY_INBOX_TOOLTIP,
    },
    {
      id: Email.PerkId.FOLLOW_UP_REMINDERS,
      text: Perks.FOLLOW_UP_REMINDERS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: FOLLOW_UP_REMINDERS_TOOLTIP,
    },
    {
      id: Email.PerkId.READ_RECEIPTS,
      text: Perks.UNLIMITED_READ_RECEIPTS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: READ_RECEIPTS_TOOLTIP,
    },
    {
      id: Email.PerkId.EMAIL_TEMPLATES,
      text: Perks.UNLIMITED_EMAIL_TEMPLATES,
      state: Email.PerkStatus.SUCCESS,
      tooltip: EMAIL_TEMPLATES_TOOLTIP,
    },
    {
      id: Email.PerkId.EXTERNAL_EMAIL_FORWARDERS,
      text: Perks.EXTERNAL_EMAIL_FORWARDERS,
      textValues: { count: 3 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: EXTERNAL_EMAIL_FORWARDERS_TOOLTIP,
    },
    {
      id: Email.PerkId.CONTACT_GROUPS,
      text: Perks.UNLIMITED_CONTACT_GROUPS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: CONTACT_GROUPS_TOOLTIP,
    },
    {
      id: Email.PerkId.HTML_IN_COMPOSER,
      text: Perks.HTML_IN_COMPOSER,
      state: Email.PerkStatus.SUCCESS,
      tooltip: HTML_IN_COMPOSER_TOOLTIP,
    },
  ],
  [Email.ProductId.TITAN_ULTRA]: [
    TITAN_ULTRA_STORAGE,
    {
      title: GROUP_TITLE,
    },
    EMAIL_CAMPAIGN,
    SMART_AI_WRITE,
    APPOINTMENT_BOOKING,
    ATTACHMENTS_AND_LINK_TRACKING,
    SIGNATURE_DESIGNER,
  ],
};

export const upgradeFeatureList: HostingPlanPerks = {
  [Email.ProductId.HOSTINGER_FREE]: [
    {
      id: Email.PerkId.STORAGE_PER_ACCOUNT,
      text: Perks.STORAGE_PER_ACCOUNT,
      textValues: { count: 1 },
      state: Email.PerkStatus.WARNING,
      tooltip: STORAGE_PER_ACCOUNT_TOOLTIP,
    },
    {
      id: Email.PerkId.FORWARDERS,
      text: Perks.FORWARDERS,
      textValues: { count: 1 },
      state: Email.PerkStatus.WARNING,
      tooltip: FORWARDERS_TOOLTIP,
    },
    {
      ...ALIAS_PERK,
      tooltipValues: { count: 5 },
      state: Email.PerkStatus.WARNING,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_SPAM,
      text: Perks.ADVANCED_ANTI_SPAM,
      state: Email.PerkStatus.DANGER,
      tooltip: ANTI_SPAM_VIRUS_TOOLTIP,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_VIRUS,
      text: Perks.ADVANCED_ANTI_VIRUS,
      state: Email.PerkStatus.DANGER,
      tooltip: ADVANCED_ANTI_VIRUS_TOOLTIP,
    },
    {
      id: Email.PerkId.CUSTOM_DKIM,
      text: Perks.CUSTOM_DKIM,
      state: Email.PerkStatus.DANGER,
      tooltip: CUSTOM_DKIM_TOOLTIP,
    },
    {
      id: Email.PerkId.SECURE_WEB_APPLICATION,
      text: Perks.SECURE_WEB_APPLICATION,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ACCESS_WITH_ANY_DEVICE,
      text: Perks.ACCESS_WITH_ANY_DEVICE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CONTACT_SERVICE,
      text: Perks.CONTACT_SERVICE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.FULL_ACCOUNT_MANAGEMENT,
      text: Perks.FULL_ACCOUNT_MANAGEMENT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EASY_CONFIGURATION_WIZARD,
      text: Perks.EASY_CONFIGURATION_WIZARD,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_FORWARDING,
      text: Perks.EMAIL_FORWARDING,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CATCH_ALL_ACCOUNT,
      text: Perks.CATCH_ALL_ACCOUNT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.AUTOMATIC_REPLIES,
      text: Perks.AUTOMATIC_REPLIES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_IMPORTING,
      text: Perks.EMAIL_IMPORTING,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.WORKS_ON_MOBILE_AND_DESKTOP,
      text: Perks.WORKS_ON_MOBILE_AND_DESKTOP,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_FILTERS,
      text: Perks.EMAIL_FILTERS,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CONTACT_LISTS,
      text: Perks.CONTACT_LISTS,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.PERSONALIZED_SIGNATURES,
      text: Perks.PERSONALIZED_SIGNATURES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ADVANCED_SEARCH,
      text: Perks.ADVANCED_SEARCH,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.IMPORT_AND_EXPORT,
      text: Perks.IMPORT_AND_EXPORT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.LIGHT_AND_DARK_THEMES,
      text: Perks.LIGHT_AND_DARK_THEMES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.AVAILABLE_IN_LANGUAGES,
      text: Perks.AVAILABLE_IN_LANGUAGES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.DKIM_AND_OTHER_SUPPORT,
      text: Perks.DKIM_AND_OTHER_SUPPORT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.MONEY_BACK_GUARANTEE,
      text: Perks.MONEY_BACK_GUARANTEE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.SUPPORT,
      text: Perks.SUPPORT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.UPTIME,
      text: Perks.UPTIME,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CLOUD_BASED,
      text: Perks.CLOUD_BASED,
      state: Email.PerkStatus.SUCCESS,
    },
  ],
  [Email.ProductId.HOSTINGER_PRO_CB]: [
    {
      id: Email.PerkId.STORAGE_PER_ACCOUNT,
      text: Perks.STORAGE_PER_ACCOUNT,
      textValues: { count: 10 },
      state: Email.PerkStatus.WARNING,
      tooltip: STORAGE_PER_ACCOUNT_TOOLTIP,
    },
    {
      id: Email.PerkId.FORWARDERS,
      text: Perks.FORWARDERS,
      textValues: { count: 10 },
      state: Email.PerkStatus.WARNING,
      tooltip: FORWARDERS_TOOLTIP,
    },
    {
      ...ALIAS_PERK,
      tooltipValues: { count: 50 },
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_SPAM,
      text: Perks.ADVANCED_ANTI_SPAM,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ANTI_SPAM_VIRUS_TOOLTIP,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_VIRUS,
      text: Perks.ADVANCED_ANTI_VIRUS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ADVANCED_ANTI_VIRUS_TOOLTIP,
    },
    {
      id: Email.PerkId.CUSTOM_DKIM,
      text: Perks.CUSTOM_DKIM,
      state: Email.PerkStatus.SUCCESS,
      tooltip: CUSTOM_DKIM_TOOLTIP,
    },
    {
      id: Email.PerkId.SECURE_WEB_APPLICATION,
      text: Perks.SECURE_WEB_APPLICATION,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ACCESS_WITH_ANY_DEVICE,
      text: Perks.ACCESS_WITH_ANY_DEVICE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CONTACT_SERVICE,
      text: Perks.CONTACT_SERVICE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.FULL_ACCOUNT_MANAGEMENT,
      text: Perks.FULL_ACCOUNT_MANAGEMENT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EASY_CONFIGURATION_WIZARD,
      text: Perks.EASY_CONFIGURATION_WIZARD,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_FORWARDING,
      text: Perks.EMAIL_FORWARDING,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CATCH_ALL_ACCOUNT,
      text: Perks.CATCH_ALL_ACCOUNT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.AUTOMATIC_REPLIES,
      text: Perks.AUTOMATIC_REPLIES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_IMPORTING,
      text: Perks.EMAIL_IMPORTING,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.WORKS_ON_MOBILE_AND_DESKTOP,
      text: Perks.WORKS_ON_MOBILE_AND_DESKTOP,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_FILTERS,
      text: Perks.EMAIL_FILTERS,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CONTACT_LISTS,
      text: Perks.CONTACT_LISTS,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.PERSONALIZED_SIGNATURES,
      text: Perks.PERSONALIZED_SIGNATURES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ADVANCED_SEARCH,
      text: Perks.ADVANCED_SEARCH,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.IMPORT_AND_EXPORT,
      text: Perks.IMPORT_AND_EXPORT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.LIGHT_AND_DARK_THEMES,
      text: Perks.LIGHT_AND_DARK_THEMES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.AVAILABLE_IN_LANGUAGES,
      text: Perks.AVAILABLE_IN_LANGUAGES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.DKIM_AND_OTHER_SUPPORT,
      text: Perks.DKIM_AND_OTHER_SUPPORT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.MONEY_BACK_GUARANTEE,
      text: Perks.MONEY_BACK_GUARANTEE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.SUPPORT,
      text: Perks.SUPPORT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.UPTIME,
      text: Perks.UPTIME,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CLOUD_BASED,
      text: Perks.CLOUD_BASED,
      state: Email.PerkStatus.SUCCESS,
    },
  ],
  [Email.ProductId.HOSTINGER_PREMIUM_CB]: [
    {
      id: Email.PerkId.STORAGE_PER_ACCOUNT,
      text: Perks.STORAGE_PER_ACCOUNT,
      textValues: { count: 50 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: STORAGE_PER_ACCOUNT_TOOLTIP,
    },
    {
      id: Email.PerkId.FORWARDERS,
      text: Perks.FORWARDERS,
      textValues: { count: 50 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: FORWARDERS_TOOLTIP,
    },
    {
      ...ALIAS_PERK,
      tooltipValues: { count: 50 },
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_SPAM,
      text: Perks.ADVANCED_ANTI_SPAM,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ANTI_SPAM_VIRUS_TOOLTIP,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_VIRUS,
      text: Perks.ADVANCED_ANTI_VIRUS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ADVANCED_ANTI_VIRUS_TOOLTIP,
    },
    {
      id: Email.PerkId.CUSTOM_DKIM,
      text: Perks.CUSTOM_DKIM,
      state: Email.PerkStatus.SUCCESS,
      tooltip: CUSTOM_DKIM_TOOLTIP,
    },
    {
      id: Email.PerkId.SECURE_WEB_APPLICATION,
      text: Perks.SECURE_WEB_APPLICATION,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ACCESS_WITH_ANY_DEVICE,
      text: Perks.ACCESS_WITH_ANY_DEVICE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CONTACT_SERVICE,
      text: Perks.CONTACT_SERVICE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.FULL_ACCOUNT_MANAGEMENT,
      text: Perks.FULL_ACCOUNT_MANAGEMENT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EASY_CONFIGURATION_WIZARD,
      text: Perks.EASY_CONFIGURATION_WIZARD,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_FORWARDING,
      text: Perks.EMAIL_FORWARDING,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CATCH_ALL_ACCOUNT,
      text: Perks.CATCH_ALL_ACCOUNT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.AUTOMATIC_REPLIES,
      text: Perks.AUTOMATIC_REPLIES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_IMPORTING,
      text: Perks.EMAIL_IMPORTING,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.WORKS_ON_MOBILE_AND_DESKTOP,
      text: Perks.WORKS_ON_MOBILE_AND_DESKTOP,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.EMAIL_FILTERS,
      text: Perks.EMAIL_FILTERS,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CONTACT_LISTS,
      text: Perks.CONTACT_LISTS,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.PERSONALIZED_SIGNATURES,
      text: Perks.PERSONALIZED_SIGNATURES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ADVANCED_SEARCH,
      text: Perks.ADVANCED_SEARCH,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.IMPORT_AND_EXPORT,
      text: Perks.IMPORT_AND_EXPORT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.LIGHT_AND_DARK_THEMES,
      text: Perks.LIGHT_AND_DARK_THEMES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.AVAILABLE_IN_LANGUAGES,
      text: Perks.AVAILABLE_IN_LANGUAGES,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.DKIM_AND_OTHER_SUPPORT,
      text: Perks.DKIM_AND_OTHER_SUPPORT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.MONEY_BACK_GUARANTEE,
      text: Perks.MONEY_BACK_GUARANTEE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.SUPPORT,
      text: Perks.SUPPORT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.UPTIME,
      text: Perks.UPTIME,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.CLOUD_BASED,
      text: Perks.CLOUD_BASED,
      state: Email.PerkStatus.SUCCESS,
    },
  ],
  [Email.ProductId.GOOGLE_GSUITE_BASIC]: [
    {
      id: Email.PerkId.STORAGE_PER_USER,
      text: Perks.STORAGE_PER_USER,
      textValues: { count: 30 },
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.VIDEO_MEETINGS,
      text: Perks.VIDEO_MEETINGS,
      textValues: { count: 100 },
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.SECURITY_MANAGEMENT,
      text: Perks.SECURITY_MANAGEMENT,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.COLLABORATIVE_CONTENT_CREATION,
      text: Perks.COLLABORATIVE_CONTENT_CREATION,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.SECURE_CLOUD_STORAGE,
      text: Perks.SECURE_CLOUD_STORAGE,
      state: Email.PerkStatus.SUCCESS,
    },
    {
      id: Email.PerkId.ADVANCED_PHISHING_MALWARE_PROTECTION,
      text: Perks.ADVANCED_PHISHING_MALWARE_PROTECTION,
      state: Email.PerkStatus.SUCCESS,
    },
  ],
  [Email.ProductId.TITAN_FREE]: [
    {
      id: Email.PerkId.STORAGE_PER_ACCOUNT,
      text: Perks.STORAGE_PER_ACCOUNT,
      textValues: { count: 1 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: STORAGE_PER_ACCOUNT_TITAN_TOOLTIP,
    },
    {
      id: Email.PerkId.INBUILT_CALENDAR_AND_CONTACTS,
      text: Perks.BUILTIN_CALENDAR_AND_CONTACTS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: INBUILT_CALENDAR_AND_CONTACTS_TOOLTIP,
    },
    {
      id: Email.PerkId.MULTI_ACCOUNT_SUPPORT,
      text: Perks.MULTI_ACCOUNT_SUPPORT,
      state: Email.PerkStatus.SUCCESS,
      tooltip: MULTI_ACCOUNT_SUPPORT_TOOLTIP,
    },
    {
      id: Email.PerkId.READ_RECEIPTS,
      text: Perks.READ_RECEIPTS,
      textValues: { count: '' },
      state: Email.PerkStatus.DANGER,
      tooltip: READ_RECEIPTS_TOOLTIP,
    },
    {
      id: Email.PerkId.EMAIL_TEMPLATES,
      text: Perks.EMAIL_TEMPLATES,
      textValues: { count: '' },
      state: Email.PerkStatus.DANGER,
      tooltip: EMAIL_TEMPLATES_TOOLTIP,
    },
    {
      id: Email.PerkId.EXTERNAL_EMAIL_FORWARDERS,
      text: Perks.ONE_EMAIL_FORWARDER,
      textValues: { count: 1 },
      state: Email.PerkStatus.WARNING,
      tooltip: EXTERNAL_EMAIL_FORWARDERS_TOOLTIP,
    },
    {
      id: Email.PerkId.CONTACT_GROUPS,
      text: Perks.CONTACT_GROUPS,
      textValues: { count: '' },

      state: Email.PerkStatus.DANGER,
      tooltip: CONTACT_GROUPS_TOOLTIP,
    },
    {
      id: Email.PerkId.SEND_LATER,
      text: Perks.SEND_LATER,
      state: Email.PerkStatus.DANGER,
      tooltip: SEND_LATER_TOOLTIP,
    },
    {
      id: Email.PerkId.PRIORITY_INBOX,
      text: Perks.PRIORITY_INBOX,
      state: Email.PerkStatus.DANGER,
      tooltip: PRIORITY_INBOX_TOOLTIP,
    },
    {
      id: Email.PerkId.FOLLOW_UP_REMINDERS,
      text: Perks.FOLLOW_UP_REMINDERS,
      state: Email.PerkStatus.DANGER,
      tooltip: FOLLOW_UP_REMINDERS_TOOLTIP,
    },
    {
      id: Email.PerkId.HTML_IN_COMPOSER,
      text: Perks.HTML_IN_COMPOSER,
      state: Email.PerkStatus.DANGER,
      tooltip: HTML_IN_COMPOSER_TOOLTIP,
    },
    {
      id: Email.PerkId.AUTO_REPLY,
      text: Perks.AUTO_REPLY,
      state: Email.PerkStatus.DANGER,
      tooltip: AUTO_REPLY_TOOLTIP,
    },
    {
      id: Email.PerkId.BLOCK_SENDER,
      text: Perks.BLOCK_SENDER,
      state: Email.PerkStatus.DANGER,
      tooltip: BLOCK_SENDER_TOOLTIP,
    },
    {
      id: Email.PerkId.ALLOW_LIST,
      text: Perks.ALLOW_LIST,
      state: Email.PerkStatus.DANGER,
      tooltip: ALLOW_LIST_TOOLTIP,
    },
    {
      id: Email.PerkId.UNDO_SEND,
      text: Perks.UNDO_SEND,
      state: Email.PerkStatus.DANGER,
      tooltip: UNDO_SEND_TOOLTIP,
    },
    {
      id: Email.PerkId.SEND_AS_ALIAS,
      text: Perks.SEND_AS_ALIAS,
      state: Email.PerkStatus.DANGER,
      tooltip: SEND_AS_ALIAS_TOOLTIP,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_SPAM,
      text: Perks.ADVANCED_ANTI_SPAM,
      state: Email.PerkStatus.DANGER,
      tooltip: ADVANCED_ANTI_SPAM_TOOLTIP,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_VIRUS,
      text: Perks.ADVANCED_ANTI_VIRUS,
      state: Email.PerkStatus.DANGER,
      tooltip: ADVANCED_ANTI_VIRUS_TOOLTIP,
    },
    {
      id: Email.PerkId.TWO_FACTOR_AUTHENTICATION,
      text: Perks.TWO_FACTOR_AUTHENTICATION,
      state: Email.PerkStatus.DANGER,
      tooltip: TWO_FACTOR_AUTHENTICATION_TOOLTIP,
    },
    {
      id: Email.PerkId.CUSTOM_DKIM,
      text: Perks.CUSTOM_DKIM,
      state: Email.PerkStatus.SUCCESS,
      tooltip: CUSTOM_DKIM_TITAN_TOOLTIP,
    },
  ],
  [Email.ProductId.TITAN_PRO_CB]: [
    {
      id: Email.PerkId.STORAGE_PER_ACCOUNT,
      text: Perks.STORAGE_PER_ACCOUNT,
      textValues: { count: 10 },
      state: Email.PerkStatus.WARNING,
      tooltip: STORAGE_PER_ACCOUNT_TITAN_TOOLTIP,
    },
    {
      id: Email.PerkId.INBUILT_CALENDAR_AND_CONTACTS,
      text: Perks.BUILTIN_CALENDAR_AND_CONTACTS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: INBUILT_CALENDAR_AND_CONTACTS_TOOLTIP,
    },
    {
      id: Email.PerkId.MULTI_ACCOUNT_SUPPORT,
      text: Perks.MULTI_ACCOUNT_SUPPORT,
      state: Email.PerkStatus.SUCCESS,
      tooltip: MULTI_ACCOUNT_SUPPORT_TOOLTIP,
    },
    {
      id: Email.PerkId.READ_RECEIPTS,
      text: Perks.READ_RECEIPTS,
      textValues: { count: 50 },
      state: Email.PerkStatus.WARNING,
      tooltip: READ_RECEIPTS_TOOLTIP,
    },
    {
      id: Email.PerkId.EMAIL_TEMPLATES,
      text: Perks.EMAIL_TEMPLATES,
      textValues: { count: 3 },
      state: Email.PerkStatus.WARNING,
      tooltip: EMAIL_TEMPLATES_TOOLTIP,
    },
    {
      id: Email.PerkId.EXTERNAL_EMAIL_FORWARDERS,
      text: Perks.EXTERNAL_EMAIL_FORWARDERS,
      textValues: { count: 3 },
      state: Email.PerkStatus.WARNING,
      tooltip: EXTERNAL_EMAIL_FORWARDERS_TOOLTIP,
    },
    {
      id: Email.PerkId.CONTACT_GROUPS,
      text: Perks.CONTACT_GROUPS,
      textValues: { count: 1 },
      state: Email.PerkStatus.WARNING,
      tooltip: CONTACT_GROUPS_TOOLTIP,
    },
    {
      id: Email.PerkId.SEND_LATER,
      text: Perks.SEND_LATER,
      state: Email.PerkStatus.DANGER,
      tooltip: SEND_LATER_TOOLTIP,
    },
    {
      id: Email.PerkId.PRIORITY_INBOX,
      text: Perks.PRIORITY_INBOX,
      state: Email.PerkStatus.DANGER,
      tooltip: PRIORITY_INBOX_TOOLTIP,
    },
    {
      id: Email.PerkId.FOLLOW_UP_REMINDERS,
      text: Perks.FOLLOW_UP_REMINDERS,
      state: Email.PerkStatus.DANGER,
      tooltip: FOLLOW_UP_REMINDERS_TOOLTIP,
    },
    {
      id: Email.PerkId.HTML_IN_COMPOSER,
      text: Perks.HTML_IN_COMPOSER,
      state: Email.PerkStatus.DANGER,
      tooltip: HTML_IN_COMPOSER_TOOLTIP,
    },
    {
      id: Email.PerkId.AUTO_REPLY,
      text: Perks.AUTO_REPLY,
      state: Email.PerkStatus.SUCCESS,
      tooltip: AUTO_REPLY_TOOLTIP,
    },
    {
      id: Email.PerkId.BLOCK_SENDER,
      text: Perks.BLOCK_SENDER,
      state: Email.PerkStatus.SUCCESS,
      tooltip: BLOCK_SENDER_TOOLTIP,
    },
    {
      id: Email.PerkId.ALLOW_LIST,
      text: Perks.ALLOW_LIST,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ALLOW_LIST_TOOLTIP,
    },
    {
      id: Email.PerkId.UNDO_SEND,
      text: Perks.UNDO_SEND,
      state: Email.PerkStatus.SUCCESS,
      tooltip: UNDO_SEND_TOOLTIP,
    },
    {
      id: Email.PerkId.SEND_AS_ALIAS,
      text: Perks.SEND_AS_ALIAS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: SEND_AS_ALIAS_TOOLTIP,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_SPAM,
      text: Perks.ADVANCED_ANTI_SPAM,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ADVANCED_ANTI_SPAM_TOOLTIP,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_VIRUS,
      text: Perks.ADVANCED_ANTI_VIRUS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ADVANCED_ANTI_VIRUS_TOOLTIP,
    },
    {
      id: Email.PerkId.TWO_FACTOR_AUTHENTICATION,
      text: Perks.TWO_FACTOR_AUTHENTICATION,
      state: Email.PerkStatus.SUCCESS,
      tooltip: TWO_FACTOR_AUTHENTICATION_TOOLTIP,
    },
    {
      id: Email.PerkId.CUSTOM_DKIM,
      text: Perks.CUSTOM_DKIM,
      state: Email.PerkStatus.SUCCESS,
      tooltip: CUSTOM_DKIM_TITAN_TOOLTIP,
    },
  ],
  [Email.ProductId.TITAN_PREMIUM_CB]: [
    {
      id: Email.PerkId.STORAGE_PER_ACCOUNT,
      text: Perks.STORAGE_PER_ACCOUNT,
      textValues: { count: 50 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: STORAGE_PER_ACCOUNT_TITAN_TOOLTIP,
    },
    {
      id: Email.PerkId.INBUILT_CALENDAR_AND_CONTACTS,
      text: Perks.BUILTIN_CALENDAR_AND_CONTACTS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: INBUILT_CALENDAR_AND_CONTACTS_TOOLTIP,
    },
    {
      id: Email.PerkId.MULTI_ACCOUNT_SUPPORT,
      text: Perks.MULTI_ACCOUNT_SUPPORT,
      state: Email.PerkStatus.SUCCESS,
      tooltip: MULTI_ACCOUNT_SUPPORT_TOOLTIP,
    },
    {
      id: Email.PerkId.READ_RECEIPTS,
      text: Perks.UNLIMITED_READ_RECEIPTS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: READ_RECEIPTS_TOOLTIP,
    },
    {
      id: Email.PerkId.EMAIL_TEMPLATES,
      text: Perks.UNLIMITED_EMAIL_TEMPLATES,
      state: Email.PerkStatus.SUCCESS,
      tooltip: EMAIL_TEMPLATES_TOOLTIP,
    },
    {
      id: Email.PerkId.EXTERNAL_EMAIL_FORWARDERS,
      text: Perks.EXTERNAL_EMAIL_FORWARDERS,
      textValues: { count: 3 },
      state: Email.PerkStatus.SUCCESS,
      tooltip: EXTERNAL_EMAIL_FORWARDERS_TOOLTIP,
    },
    {
      id: Email.PerkId.CONTACT_GROUPS,
      text: Perks.UNLIMITED_CONTACT_GROUPS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: CONTACT_GROUPS_TOOLTIP,
    },
    {
      id: Email.PerkId.SEND_LATER,
      text: Perks.SEND_LATER,
      state: Email.PerkStatus.SUCCESS,
      tooltip: SEND_LATER_TOOLTIP,
    },
    {
      id: Email.PerkId.PRIORITY_INBOX,
      text: Perks.PRIORITY_INBOX,
      state: Email.PerkStatus.SUCCESS,
      tooltip: PRIORITY_INBOX_TOOLTIP,
    },
    {
      id: Email.PerkId.FOLLOW_UP_REMINDERS,
      text: Perks.FOLLOW_UP_REMINDERS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: FOLLOW_UP_REMINDERS_TOOLTIP,
    },
    {
      id: Email.PerkId.HTML_IN_COMPOSER,
      text: Perks.HTML_IN_COMPOSER,
      state: Email.PerkStatus.SUCCESS,
      tooltip: HTML_IN_COMPOSER_TOOLTIP,
    },
    {
      id: Email.PerkId.AUTO_REPLY,
      text: Perks.AUTO_REPLY,
      state: Email.PerkStatus.SUCCESS,
      tooltip: AUTO_REPLY_TOOLTIP,
    },
    {
      id: Email.PerkId.BLOCK_SENDER,
      text: Perks.BLOCK_SENDER,
      state: Email.PerkStatus.SUCCESS,
      tooltip: BLOCK_SENDER_TOOLTIP,
    },
    {
      id: Email.PerkId.ALLOW_LIST,
      text: Perks.ALLOW_LIST,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ALLOW_LIST_TOOLTIP,
    },
    {
      id: Email.PerkId.UNDO_SEND,
      text: Perks.UNDO_SEND,
      state: Email.PerkStatus.SUCCESS,
      tooltip: UNDO_SEND_TOOLTIP,
    },
    {
      id: Email.PerkId.SEND_AS_ALIAS,
      text: Perks.SEND_AS_ALIAS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: SEND_AS_ALIAS_TOOLTIP,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_SPAM,
      text: Perks.ADVANCED_ANTI_SPAM,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ADVANCED_ANTI_SPAM_TOOLTIP,
    },
    {
      id: Email.PerkId.ADVANCED_ANTI_VIRUS,
      text: Perks.ADVANCED_ANTI_VIRUS,
      state: Email.PerkStatus.SUCCESS,
      tooltip: ADVANCED_ANTI_VIRUS_TOOLTIP,
    },
    {
      id: Email.PerkId.TWO_FACTOR_AUTHENTICATION,
      text: Perks.TWO_FACTOR_AUTHENTICATION,
      state: Email.PerkStatus.SUCCESS,
      tooltip: TWO_FACTOR_AUTHENTICATION_TOOLTIP,
    },
    {
      id: Email.PerkId.CUSTOM_DKIM,
      text: Perks.CUSTOM_DKIM,
      state: Email.PerkStatus.SUCCESS,
      tooltip: CUSTOM_DKIM_TITAN_TOOLTIP,
    },
  ],
};
