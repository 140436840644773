import { defineStore } from 'pinia';
import { ref } from 'vue';

import { hVpsRepo } from '@/repositories';
import { useVpsServerStore } from '@/stores';
import type {
  IMalwareScannerFileResponse,
  IMalwareScannerMetrics,
  MalwareScannerTimeline,
} from '@/types';
import { HVps } from '@/types';

export const useVpsMalwareScannerStore = defineStore(
  'vpsMalwareScanner',
  () => {
    const serverStore = useVpsServerStore();

    const scannerMetrics = ref<IMalwareScannerMetrics>();

    const scannerTimeline = ref<MalwareScannerTimeline>();

    const malwareFiles = ref<IMalwareScannerFileResponse>();

    const setScannerMetrics = (metrics: IMalwareScannerMetrics) => {
      scannerMetrics.value = metrics;
    };

    const fetchMalwareScannerMetrics = async () => {
      const serverId = serverStore.managedId;
      if (!serverId) return;

      const [{ data }, error] = await hVpsRepo.getMalwareScannerMetrics(
        serverId,
      );

      if (error) return;

      setScannerMetrics(data);
    };

    const installMalwareScanner = async () => {
      const serverId = serverStore.managedId;
      if (!serverId) return;

      serverStore.setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.installMalwareScanner(serverId);

      if (error) {
        serverStore.setServerLock(serverId, false);

        return;
      }

      serverStore.setServerState(serverId, HVps.ServerState.INSTALLING);
      serverStore.setUpdatingServer({ serverId });

      serverStore.fetchServerById({ serverId });
    };

    const setMalwareAutoProtection = async (isEnabled: boolean) => {
      const serverId = serverStore.managedId;
      if (!serverId) return;

      serverStore.setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.activateMalwareAutoProtection(
        serverId,
        {
          enabled: isEnabled,
          malicious: 'quarantine',
          compromised: 'clean',
        },
      );

      if (error) {
        serverStore.setServerLock(serverId, false);

        return;
      }

      serverStore.setServerState(serverId, HVps.ServerState.INSTALLING);
      serverStore.setUpdatingServer({ serverId });

      serverStore.fetchServerById({ serverId });
    };

    const uninstallMalwareScanner = async () => {
      const serverId = serverStore.managedId;
      if (!serverId) return;

      serverStore.setServerLock(serverId, true);
      const [_, error] = await hVpsRepo.uninstallMalwareScanner(serverId);

      if (error) {
        serverStore.setServerLock(serverId, false);

        return;
      }

      serverStore.setUpdatingServer({ serverId });
      serverStore.fetchServerById({ serverId });
    };

    const fetchMalwareScannerTimeline = async () => {
      const serverId = serverStore.managedId;
      if (!serverId) return;

      const [{ data }, error] = await hVpsRepo.getMalwareScannerTimeline(
        serverId,
      );

      if (error) {
        return;
      }

      scannerTimeline.value = data;
    };

    const fetchMalwareFiles = async (params: any) => {
      const serverId = serverStore.managedId;
      if (!serverId) return;

      const [{ data }, error] = await hVpsRepo.getMalwareScannerFiles(
        serverId,
        params,
      );

      if (error) {
        return;
      }

      malwareFiles.value = data;
    };

    return {
      scannerMetrics,
      scannerTimeline,
      malwareFiles,
      setScannerMetrics,
      fetchMalwareScannerMetrics,
      installMalwareScanner,
      uninstallMalwareScanner,
      setMalwareAutoProtection,
      fetchMalwareScannerTimeline,
      fetchMalwareFiles,
    };
  },
);
