export namespace Payment {
  export enum Method {
    APPLEPAY = 'applepay',
    UPI = 'upi',
    CARD = 'card',
    RAZORPAY = 'razorpay',
    PAYPAL = 'paypal',
    GOOGLEPAY = 'googlepay',
  }
  export enum MerchantAccount {
    RAZORPAY = 'razorpay',
  }

  export enum PaymentMethod {
    CARD = 'card',
  }

  export enum Type {
    PURCHASE_ADDON = 'purchase-addon',
    PURCHASE = 'purchase',
    UPGRADE = 'upgrade',
    RENEW = 'renew',
    REACTIVE = 'reactivate',
    INVOICE = 'invoice',
    REACTIVATE = 'reactivate',
  }

  export enum Status {
    COMPLETED = 'completed',
  }
}
