import type { NavigationGuard } from 'vue-router';

import { useHDomainResourceStore } from '@/stores';
import type { IHDomainResource } from '@/types';
import { Route } from '@/types';
import { toASCII } from '@/utils/helpers';

const RESOURCE_TYPE_DOMAIN = 'domain';
const RESOURCE_STATUS_PENDING_SETUP = 'pending_setup';

const isPendingSetup = (
  resources: Array<IHDomainResource>,
  currentDomain: string | string[],
) =>
  resources?.some(
    (resource) =>
      resource.resourceType === RESOURCE_TYPE_DOMAIN &&
      resource.status === RESOURCE_STATUS_PENDING_SETUP &&
      resource.title === currentDomain,
  );

const emailOnboardingDomainSetupGuard: NavigationGuard = async (
  to,
  _from,
  next,
) => {
  if (!to.query.domainTld || !to.query.domainSld) {
    return next({ name: Route.Base.HOME });
  }

  const { fetchHDomainsResources } = useHDomainResourceStore();
  const [{ data }] = await fetchHDomainsResources();

  const currentDomain = `${to.query.domainSld}${to.query.domainTld}`;

  if (!isPendingSetup(data, toASCII(currentDomain))) {
    return next({
      name: Route.Email.EMAIL_ONBOARDING_HELLO,
      params: { orderId: to.params.orderId },
    });
  }

  next();
};

export default emailOnboardingDomainSetupGuard;
