import { hAsync } from '@/utils/helpers';
import http from '@/utils/services/http/httpService';

export default {
  urlSso: `${process.env.VITE_API_SSO}/v1/ssl`,
  urlRest: (v) => `${process.env.VITE_API_REST}/${v}/ssl`,
  async getIsUnlimitedEnabled() {
    return await hAsync(http.get(`${this.urlRest('v2')}/is-unlimited-enabled`));
  },
  async getDomainsToSetupSsl(data, settings) {
    return await hAsync(
      http.get(
        `${this.urlRest('v3')}/setup-domains`,
        { params: data },
        settings,
      ),
    );
  },
  async activateSsl(data) {
    return await hAsync(
      http.post(
        `${this.urlRest('v3')}/install`,
        { domain: data.domain },
        {
          headers: {
            'X-Hpanel-Username': data.username,
            'X-Hpanel-Order-ID': data.accountOrderId,
            'X-Hpanel-Domain': data.domain,
          },
        },
        { noHostingHeaders: true },
      ),
    );
  },
  async resendApproval(data) {
    return await hAsync(http.post(`${this.urlSso}/resend-approval`, data));
  },
  async getSsls(params) {
    return await hAsync(http.get(`${this.urlRest('v3')}`, { params }));
  },
  async installSsl(data) {
    return await hAsync(http.post(`${this.urlRest('v2')}/comodo`, data));
  },

  async uninstallSsl(data) {
    return await hAsync(http.post(`${this.urlRest('v3')}/uninstall`, data));
  },
  async getForceHttpsStatus(params) {
    return await hAsync(http.get(`${this.urlRest('v2')}/forces`, { params }));
  },
  async setForceHttps(data) {
    return await hAsync(http.post(`${this.urlRest('v2')}/forces`, data));
  },
  async unsetForceHttps(data) {
    return await hAsync(
      http.delete(`${this.urlRest('v2')}/forces`, { params: data }),
    );
  },
  async unsetLifetimeSsl(data) {
    return await hAsync(
      http.delete(`${this.urlRest('v2')}/forces`, { params: data }),
    );
  },
  async reinstallSsl(data) {
    return await hAsync(http.post(`${this.urlRest('v3')}/reinstall`, data));
  },
  async reinstallComodoSsl(data) {
    return await hAsync(http.post(`${this.urlRest('v2')}/comodo`, data));
  },
  async setAdvancedCustomSsl(params) {
    return await hAsync(
      http.post(`${this.urlRest('v2')}/custom`, { ...params }),
    );
  },
};
