import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useGlobals } from '@/composables';
import type { HotspotId, HotspotConfig } from '@/types';

export const useHotspotStore = defineStore(
  'hotspotStoreV2',
  () => {
    const { t } = useGlobals();

    const hotspots = ref<Record<HotspotId, HotspotConfig>>({
      websitesFavouritesHotspot: {
        id: 'websitesFavouritesHotspot',
        isClickable: false,
        priority: 110,
        indicatorPosition: {
          left: -7,
          top: -8,
        },
      },
      navigationHotspot: {
        id: 'navigationHotspot',
        title: t('New menu: get things done faster'),
        description: t(
          'Easily find what you’re looking for, from editing your websites to managing all of your services. Let us know what you think!',
        ),
        actionTitle: t('Got it'),
        videoPath: '@/videos/navigation-intro.mp4',
        videoPosterPath: '@/images/navigation-video-poster.png',
        isImmediate: true,
        isIndicator: false,
        placement: 'right',
        priority: 200,
        indicatorPosition: {
          left: -7,
          top: -8,
        },
      },
      accessListProPanel: {
        id: 'accessListProPanel',
        title: t('Manage your clients with Pro Panel'),
        priority: 100,
      },
      domainFromDescriptionHotspot: {
        id: 'domainFromDescriptionHotspot',
        title: t('Try out our AI domain generator!'),
        description: t(
          'Enter a description about your project and get suggested multiple AI generated domain names',
        ),
        priority: 110,
        indicatorPosition: {
          left: 0,
          top: -8,
        },
      },
      proWebsitesListFilter: {
        indicatorPosition: { top: 8, left: 8 },
        id: 'proWebsitesListFilter',
        title: t('Edit your websites list'),
        description: t(
          'Now you can manage both your personal and client projects in one place. Just add the websites you need to the list.',
        ),
        priority: 100,
      },
      twoFactorHotspot: {
        id: 'twoFactorHotspot',
        title: t('Secure Account: Enable 2FA'),
        description: t(
          'Ensure seamless integration of the mobile authentication program with your Hostinger account. ',
        ),
        priority: 100,
      },
      twoFactorLeadingHotspot: {
        id: 'twoFactorHotspot',
        isClickable: false,
        priority: 100,
        indicatorPosition: {
          left: 0,
          top: -14,
        },
      },
      marketplaceHotspot: {
        id: 'marketplaceHotspot',
        title: t('Explore New Marketplace'),
        description: t(
          'Our updated marketplace interface simplifies product exploration, creating a more efficient shopping experience',
        ),
        priority: 90,
      },
      vcTerminalHotspot: {
        id: 'vcTerminalHotspot',
        title: t('Access your VPS through browser'),
        description: t(
          'Introducing browser terminal access for your VPS! Say goodbye to clunky external terminals' +
            ' and hello to a smooth, streamlined experience.',
        ),
        priority: 80,
      },
      noCacheHotspot: {
        id: 'noCacheHotspot',
        title: t('Disable cache for your website'),
        description: t(
          "This may be useful if website has frequently updated information. However, this can also slow down website's loading time and increase server load, so it's important to weigh the pros and cons before making a decision.",
        ),
        placement: 'right',
        priority: 70,
      },
    });

    const closedHotspots = computed(() =>
      Object.values(hotspots.value).filter(({ isClosed }) => isClosed),
    );

    const unclosedHotspots = computed(() =>
      Object.values(hotspots.value)
        .filter(({ isClosed, isRegistered }) => !isClosed && isRegistered)
        .sort((a, b) => (b?.priority || 0) - (a?.priority || 0)),
    );

    const currentlyVisibleHotspots = computed(() => {
      const [topHotspot] = unclosedHotspots.value;

      if (!topHotspot) {
        return [];
      }

      return unclosedHotspots.value.filter(({ id }) => id === topHotspot.id);
    });

    const isHotspotClosed = (id: HotspotId) =>
      closedHotspots.value.some((hotspot) => hotspot.id === id);

    const closeHotspot = (id: HotspotId) => {
      hotspots.value[id].isClosed = true;
    };

    const registerHotspot = (id: HotspotId) => {
      if (!hotspots.value[id]) {
        return;
      }

      hotspots.value[id].isRegistered = true;
    };

    const unregisterHotspot = (id: HotspotId) => {
      hotspots.value[id].isRegistered = false;
    };

    return {
      hotspots,
      closedHotspots,
      unclosedHotspots,
      currentlyVisibleHotspots,
      isHotspotClosed,
      closeHotspot,
      registerHotspot,
      unregisterHotspot,
    };
  },
  {
    persist: true,
  },
);
