<script setup lang="ts">
import DomainForwardingForm from '@/components/HDomains/DomainForwardingForm.vue';
import HModalActions from '@/components/HModals/HModalActions.vue';
import { useGlobals } from '@/composables';
import type {
  IDomainForwardingValues,
  IDomainBulkActionInputValues,
} from '@/types';

type Props = {
  data: {
    onSubmit?: (data: IDomainBulkActionInputValues) => void;
  };
};

const props = defineProps<Props>();

interface Emits {
  (eventName: 'close'): void;
}

const emits = defineEmits<Emits>();

const { t } = useGlobals();

const handleAddDomainForwardSubmit = ({
  formValues,
  redirectType,
}: IDomainForwardingValues) => {
  const httpProtocol = formValues.values.forwardWebsite.prepend;
  const url = formValues.values.forwardWebsite.value;
  props.data.onSubmit?.({
    redirectType,
    redirectUrl: `${httpProtocol}${url}`,
  });
};
</script>

<template>
  <h2 v-trans class="h-mb-8">Manage Domain Forwarding</h2>
  <p v-trans class="h-mb-16">
    If you have existing forwarder on the domains, it will overwrite them and it
    can take up to a couple of hours for it to take effect.
  </p>
  <DomainForwardingForm @form-submitted="handleAddDomainForwardSubmit">
    <template #action>
      <HSnackbar variant="info" class="h-mt-16 h-mb-24">
        {{
          t(
            'Connecting this domain to a hosting plan later will remove the forwarder',
          )
        }}
      </HSnackbar>

      <HModalActions
        class="h-mt-16"
        close-text="Cancel"
        mobile-reverse
        no-padding
        @close="emits('close')"
      >
        <HButton v-qa-generate class="h-mb-8 h-mb-0-md" h-form-submit>
          {{ t('Continue') }}
        </HButton>
      </HModalActions>
    </template>
  </DomainForwardingForm>
</template>
